import { theme } from 'common/theme';
import { BrandColorMapType } from 'common/types/common';
import { FilterNames } from 'common/types/filters';

export const getColorForData = (index: number): string => {
  const colorPalette = theme.v2.chart.colorPalette;
  return colorPalette[index % colorPalette.length];
};

export const addOpacityToColor = (color: string, opacity: number): string => {
  const colorValues = color.match(/\((.*?)\)/); //e.g. (255,225,255)
  return colorValues ? `rgba(${colorValues[1]}, ${opacity})` : color;
};

export const getAverageColor = (): string => {
  return theme.chart.average.toString();
};

export const getItemColor = (
  itemLabel: string,
  currentItemIndex: number,
  secondDimension: string,
  brandsColorMap: BrandColorMapType | null,
  getColorForData: (index: number) => string,
) => {
  if (secondDimension === FilterNames.BRAND && brandsColorMap?.[itemLabel]) {
    return brandsColorMap[itemLabel];
  }

  return getColorForData(currentItemIndex);
};
