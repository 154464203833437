import styled from '@emotion/styled';
import { useTracking } from 'common/hooks/tracking';
import { Text } from 'common/styledComponents/typography';
import { theme } from 'common/theme';
import { WaveReport } from 'common/types/common';
import { TRACKING_EVENTS } from 'common/types/tracking';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { displayDate } from 'utils/dates';

const Container = styled.li`
  display: flex;
  padding: ${theme.space(3, 2.5)};
  align-items: baseline;
  border-bottom: 1px solid ${theme.v2.colours.gray100};
  &:last-child {
    border-bottom: none;
  }
`;

const DetialsContainer = styled.div`
  display: flex;
  padding-left: ${theme.space(4)};
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 2;
`;

const Date = styled(Text)`
  flex: 2;
`;

const Badge = styled(Text)`
  display: flex;
  border-radius: 4px;
  padding: ${theme.space(0, 0.5)};
  align-self: baseline;
  background: ${theme.colors.turqoise.toString()};
  margin: ${theme.space(0, 0, 0, 1)};
`;

const ReportItem: React.FC<{ report: WaveReport }> = ({ report: { title, link_to_pdf, period, is_new: isNewReport } }) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();

  return (
    <Container>
      <TitleContainer>
        <Text variant='body2'>{title}</Text>
        {isNewReport ? (
          <Badge color='white' variant='body2'>
            {t(`common.new`)}
          </Badge>
        ) : null}
      </TitleContainer>
      <DetialsContainer>
        <Date variant='body2' color='black25'>
          {displayDate(period, 'MMMM YYYY')}
        </Date>
        <a
          href={link_to_pdf}
          target='_blank'
          rel='noopener noreferrer'
          download
          onClick={() => trackEvent(TRACKING_EVENTS.DOWNLOAD_WAVE_SUMMARY_SETTINGS)}
        >
          <Text variant='body2' color='lightPurple'>
            {t('reports.download')}
          </Text>
        </a>
      </DetialsContainer>
    </Container>
  );
};

export default React.memo(ReportItem);
