import { ChartFormData, ChartType } from 'common/types/chart';
import { KPI } from 'common/types/common';
import { FilterNames, FiltersState, IFilter } from 'common/types/filters';
import { ChartDetails, DYNAMIC_WAVES_DEFAULT, FilterTabs, MATRIX_CHART_DEFAULT_WAVES } from 'settings/constants';
import { KPIFilterService } from 'modules/charts/components/Filters/services/kpi';
import { WaveFilterService } from 'modules/charts/components/Filters/services/wave';
import { ChartValue } from 'common/types/charts';
import { BrandFilterService } from './brand';
import { SegmentFilterService } from './segment';

interface BaseFilterServiceModel {
  // Global Filters index definitions
  allFilters?: FiltersState;
  kpis?: KPI[];
  activeFilter?: FilterNames;
  values?: ChartFormData;
  chartType?: string;
  setChartType: (chartType: string) => void;
  setValues: (values: ChartFormData) => void;
  setAllFilters: (allFilters: FiltersState) => void;
  setKpis: (kpis: KPI[]) => void;
  setChartValue?: (field: string, values: ChartValue) => void;
  setChartValueCallback: (setValueCallback: (field: string, values: ChartValue) => void) => void;
  setActiveFilter: (activeFilter: FilterNames) => void;
  isFilterType: (expectedFilter?: FilterNames) => boolean;
  onFilterApply: () => void;
  onNextFilterState: () => void;
  onNextFilterShow: () => void;
  onShowFilter: (filter: IFilter) => void;
  shouldShowWarningModal: (isChartPresentInDashboard: boolean, isInternalUser: boolean, isOwnerLatana: boolean) => boolean;
  getNextFilterTabIndex: () => number;
  isFilterDisabled: (filter: IFilter) => boolean;
  isPercentageAbsoluteSwitchEnabled?: boolean;
  setPercentageAbsoluteSwitchState: (isEnabled: boolean) => void;

  // FilterModal Definitions
  filterModal: {
    getAvailableOptions: (filter: IFilter) => IFilter;
  };
}

const getServiceByFilterId = (activeFilter: FilterNames) => {
  switch (activeFilter) {
    case FilterNames.KPI:
      return KPIFilterService;
    case FilterNames.WAVE:
      return WaveFilterService;
    case FilterNames.BRAND:
      return BrandFilterService;
    case FilterNames.SEGMENT:
      return SegmentFilterService;
    default:
      break;
  }
};

export const BaseFilterService: BaseFilterServiceModel = {
  setChartType(chartType: string) {
    BaseFilterService.chartType = chartType;
  },
  setValues(values: ChartFormData) {
    BaseFilterService.values = values;
  },
  setAllFilters(allFilters: FiltersState) {
    BaseFilterService.allFilters = allFilters;
  },

  setKpis(kpis: KPI[]) {
    BaseFilterService.kpis = kpis;
  },

  setActiveFilter(activeFilter: FilterNames) {
    BaseFilterService.activeFilter = activeFilter;
  },

  isFilterType(expectedFilter?: string) {
    return BaseFilterService.activeFilter === expectedFilter;
  },

  setChartValueCallback(setValueCallback: (field: string, values: ChartValue) => void) {
    BaseFilterService.setChartValue = setValueCallback;
  },

  onFilterApply: () => {
    if (
      BaseFilterService.activeFilter &&
      BaseFilterService.kpis &&
      BaseFilterService.allFilters &&
      BaseFilterService.setChartValue &&
      BaseFilterService.values
    ) {
      const { kpi, brand } = BaseFilterService.values;
      const service = getServiceByFilterId(BaseFilterService.activeFilter);

      // Safe Check if this method exist in this service
      service &&
        'onFilterApply' in service &&
        service?.onFilterApply?.(BaseFilterService.allFilters, BaseFilterService.kpis, kpi, BaseFilterService.setChartValue, brand);
    }
  },

  onNextFilterState: () => {
    BaseFilterService.onFilterApply();

    // temporary select [Most Recent] wave by default for growth performance AND brand perception charts.
    if (BaseFilterService.chartType === ChartType.GROWTH_PERFORMANCE && WaveFilterService.lastWaves) {
      BaseFilterService?.setChartValue?.(ChartDetails.WAVE, WaveFilterService.lastWaves);
      BaseFilterService?.setChartValue?.(ChartDetails.DYNAMIC_WAVES, DYNAMIC_WAVES_DEFAULT);
    } else if (
      (BaseFilterService.chartType === ChartType.MARKET_FUNNEL || BaseFilterService.chartType === ChartType.BRAND_PERCEPTION) &&
      WaveFilterService.lastWaves
    ) {
      BaseFilterService?.setChartValue?.(ChartDetails.WAVE, WaveFilterService.lastWaves);
      BaseFilterService?.setChartValue?.(ChartDetails.DYNAMIC_WAVES, 0);
    } else if (BaseFilterService.chartType === ChartType.MATRIX && WaveFilterService.lastWaves) {
      BaseFilterService?.setChartValue?.(ChartDetails.WAVE, WaveFilterService.lastWaves);
      BaseFilterService?.setChartValue?.(ChartDetails.DYNAMIC_WAVES, MATRIX_CHART_DEFAULT_WAVES);
    }
  },
  onNextFilterShow: () => {
    BaseFilterService.onFilterApply();
  },
  onShowFilter: (filter: IFilter) => {
    const service = getServiceByFilterId(filter.id);

    // Safe Check if this method exist in this service
    service && 'onShowFilter' in service && service?.onShowFilter?.(filter.id);

    BaseFilterService.onFilterApply();
  },
  shouldShowWarningModal: (isChartInMultipleDashboards, isInternalUser, isOwnerLatana) => {
    return isChartInMultipleDashboards || (!isInternalUser && isOwnerLatana);
  },
  getNextFilterTabIndex: () => {
    const isWaveFilter = BaseFilterService.activeFilter === FilterNames.WAVE;
    const isCountryFilter = BaseFilterService.activeFilter === FilterNames.COUNTRY;
    const isFunnelChart = BaseFilterService.chartType === ChartType.FUNNEL;
    const isRadarChart = BaseFilterService.chartType === ChartType.RADAR;
    const isBpChart = BaseFilterService.chartType === ChartType.BRAND_PERCEPTION;
    const isMarketFunnelChart = BaseFilterService.chartType === ChartType.MARKET_FUNNEL;
    const isGpChart = BaseFilterService.chartType === ChartType.GROWTH_PERFORMANCE;
    const isMarketSize = BaseFilterService.chartType === ChartType.MARKET_SIZE;
    const isMatrix = BaseFilterService.chartType === ChartType.MATRIX;

    if (((isFunnelChart || isRadarChart) && isWaveFilter) || ((isGpChart || isMatrix) && isCountryFilter)) {
      // Funnel or Radar - skip KPI filter
      // Growth Performance - skip Wave filter
      return FilterTabs.SECOND;
    } else if ((isMarketFunnelChart || isBpChart) && isCountryFilter) {
      // Brand Perception & Market funnel - skip Wave & KPI filter
      return FilterTabs.THIRD;
    } else if ((isBpChart || isMarketFunnelChart) && isWaveFilter) {
      return FilterTabs.SECOND;
    } else if (isWaveFilter && isMarketSize) {
      // Skip Brand selection since Market size chart have preselected No Brand
      return FilterTabs.THIRD;
    } else {
      // Default - don't skip any filter
      return FilterTabs.FIRST;
    }
  },
  isFilterDisabled: (filter: IFilter) => {
    const service = getServiceByFilterId(filter.id);
    const isFilterDisabled = service?.isFilterDisabled(filter.id, BaseFilterService.isPercentageAbsoluteSwitchEnabled);

    // Safe check if this method exist in this service
    return !!(service && 'onShowFilter' in service && isFilterDisabled);
  },
  // FilterModal METHODS
  filterModal: {
    getAvailableOptions(filter) {
      if (filter.id !== FilterNames.KPI) return filter;

      return KPIFilterService.filterModal.getAvailableOptions(filter) ?? filter;
    },
  },
  setPercentageAbsoluteSwitchState: (isEnabled: boolean) => {
    BaseFilterService.isPercentageAbsoluteSwitchEnabled = isEnabled;
  },
};
