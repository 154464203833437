import styled from '@emotion/styled';
import { scrollbarStyles } from './mixins';

interface MenuItemProps {
  isStudies?: boolean;
  isNestedItem?: boolean;
}

interface ListMenuProps {
  collapsed?: boolean;
  isStudies?: boolean;
}

export const ListMenu = styled.div<ListMenuProps>`
  max-height: calc(100% - ${({ theme }) => `${theme.spacing.padding * 4}px`});
  overflow: auto;
  min-height: 40px;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  width: 100%;
  max-width: 100%;

  // Apply the scrollbar styles
  ${scrollbarStyles()}

  &[data-no-scroll='true'] {
    overflow: initial;
  }
`;

export const MenuItem = styled.div<MenuItemProps>`
  font-style: normal;
  display: flex;
  align-items: center;
  min-height: 34px;
  > a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    width: 85%;
    padding-left: ${({ theme, isNestedItem }) => (isNestedItem ? theme.space(3) : theme.space(0))};
    &:hover {
      border-radius: ${({ isStudies }) => (isStudies ? `3px` : `0px`)};
      padding: ${({ theme, isStudies, isNestedItem }) =>
        isStudies ? theme.space(0, 0.5, 0, 0) : theme.space(0, 0, 0, isNestedItem ? 3 : 0)};
      margin-left: ${({ theme, isStudies }) => (isStudies ? theme.space(2) : theme.space(0))};
      width: ${({ isStudies }) => (isStudies ? '90%' : '100%')};
    }
  }

  > div {
    cursor: pointer;
  }

  h6 {
    cursor: pointer;
    display: inline;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: ${({ theme }) => theme.space(1, 1, 1, 0)};
  }
  &[data-selected='true'] {
    a {
      background: ${({ theme }) => theme.colors.darkPurple1.toString()};
      border-radius: ${({ isStudies }) => (isStudies ? `4px` : `0px`)};
      padding: ${({ theme, isStudies, isNestedItem }) =>
        isStudies ? theme.space(0, 0.5, 0, 0) : theme.space(0, 0, 0, isNestedItem ? 3 : 0)};
      margin-left: ${({ theme, isStudies }) => (isStudies ? theme.space(2) : theme.space(0))};
      width: ${({ isStudies }) => (isStudies ? '90%' : '100%')};
      pointer-events: none;
      position: relative;
      cursor: default;
      h6 {
        color: ${({ theme }) => theme.colors.lavender.toString()};
        padding: ${({ theme, isStudies }) => theme.space(1, 2, 1, isStudies ? 1 : 2.5)};
      }
    }
  }
  &:last-child {
    margin-bottom: ${({ theme }) => theme.space(2)};
  }
`;

export const MenuItemBtn = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;
