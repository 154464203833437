import styled from '@emotion/styled';
import { textVariantsTypes } from 'common/styledComponents/typography';
import { Theme } from '@emotion/react';

export interface InlineTextFieldProps {
  name: string;
  className?: string;
  disabled?: boolean;
  marginTop?: boolean;
  autoComplete?: string;
  marginBottom?: boolean;
  variant: textVariantsTypes;
  color?: keyof Theme['colors'];
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InlineTextField = styled.input<InlineTextFieldProps>`
  border: none;
  display: block;
  background: none;
  min-width: 60px;
  min-height: 20px;
  &.segments-input {
    font-size: 24px;
    line-height: 32px;
    flex: 1;
    margin-right: ${({ theme }) => theme.v2.space(1)};
  }
  &:focus {
    outline: none;
  }
  ${({ theme, variant, color = 'black', marginBottom = false, marginTop = false }) => `
    font-family: ${theme.text.fontFamily};
    line-height: ${theme.text.lineHeight};
    font-weight: ${theme.text.variants[variant].fontWeight};
    font-size: ${theme.text.variants[variant].fontSize};
    margin: ${theme.text.variants[variant].margin};
    color: ${theme.colors[color].toString()};
    ${!marginTop ? 'margin-top: 0px;' : ''}
    ${!marginBottom ? 'margin-bottom: 0px;' : ''}
    &:focus {
        color: ${theme.colors.black.toString()};
      }
  `}
  &:empty:before {
    content: 'text goes here';
  }
`;
