import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { ConesSortingKeys, RawChartData, RawChartSchema, SortOption, SortType } from 'common/types/chart';
import { useGetAllBrands } from 'common/queries/brands';
import {
  ArrowDown,
  CurrentDataLabel,
  FunnelAxleContainer,
  FunnelAxleTooltipWrapper,
  FunnelChartContainer,
  FunnelDirectionContainer,
  MarketFunnelChartContainer,
  Table,
} from 'views/MarketFunnel/styled';
import MarketFunnelBody from 'modules/charts/components/MarketFunnel/MarketFunnelBody';
import MarketFunnelHeader from 'modules/charts/components/MarketFunnel/MarketFunnelHeader';
import { MFChartData, getChartData, getLatestWaveDate } from 'modules/charts/components/MarketFunnel/getMarketFunnelData';
import { brandsColorMapAtom } from 'common/atoms/brands';
import { MARKET_FUNNEL_COLORS } from 'modules/charts/components/MarketFunnel/colors';
import { coloursPalette } from 'theme/colours';
import { LatanaText } from 'common/components/LatanaText';
import { displayDate } from 'utils/dates';
import FunnelElement from 'modules/charts/components/MarketFunnel/FunnelElement';
import { getColorForData } from 'modules/charts/utils/colors';
import { useTranslation } from 'react-i18next';
import { DataTestId } from 'settings/constants';
import { useGetCountryIdMapByStudyId } from 'common/hooks/countries';
import { useGetWavesMap } from 'common/hooks/waves';
import { useRawSegments } from 'common/queries/segments';
import Icon from 'common/components/Icon';
import { FilterNames } from 'common/types/filters';
import { withTooltip } from 'common/components/Tooltip/withTooltip';
import { segmentsColorsMapAtom } from 'common/atoms/segments';

interface MarketFunnelProps {
  chartConf: RawChartSchema;
  chartData?: RawChartData[];
  inPage: boolean;
  conesSortingData: SortOption;
}

const MarketFunnel: React.FC<MarketFunnelProps> = ({ chartConf, chartData: marketFunnelData, inPage, conesSortingData }) => {
  const { t } = useTranslation();
  const waves = useGetWavesMap();
  const getCountryIdMap = useGetCountryIdMapByStudyId();
  const { data: brands, isLoading: isBrandsLoading } = useGetAllBrands();
  const { data: segments, isLoading: isSegmentsLoading } = useRawSegments();
  const brandsColorMap = useRecoilValue(brandsColorMapAtom);
  const segmentsColorsMap = useRecoilValue(segmentsColorsMapAtom);
  const [tableSorting, setTableSorting] = React.useState<SortOption>({ key: null, type: SortType.DESC });
  const isGroupedBySegment = chartConf.configuration.first_dimension === FilterNames.SEGMENT;

  const onSetSorting = React.useCallback(
    (key: string | null) => {
      let type = SortType.DESC;

      if (key === null) {
        return;
      }

      switch (tableSorting.type) {
        case SortType.DEFAULT:
          type = SortType.DEFAULT;
          break;
        case SortType.DESC:
          type = SortType.ASC;
          break;
        default:
          type = SortType.DESC;
      }

      setTableSorting({ key, type });
    },
    [tableSorting, setTableSorting],
  );

  const sortChartData = React.useCallback((chartData: MFChartData[], columnKey: string, sortingOrder: SortType) => {
    return chartData.sort((a: MFChartData, b: MFChartData) => {
      // Find the first occurrence of the key in each item's values
      const aValue = a.values.find(value => value.pastWave[columnKey] || value.previousWave[columnKey]);
      const bValue = b.values.find(value => value.pastWave[columnKey] || value.previousWave[columnKey]);

      // Handle cases where the key might not exist
      if (!aValue && !bValue) return 0;
      if (!aValue) return sortingOrder === SortType.ASC ? -1 : 1;
      if (!bValue) return sortingOrder === SortType.ASC ? 1 : -1;

      // Compare the values
      const aKeyVal = (aValue.pastWave[columnKey] ?? aValue.previousWave[columnKey] ?? 0) as number;
      const bKeyVal = (bValue.pastWave[columnKey] ?? bValue.previousWave[columnKey] ?? 0) as number;

      return sortingOrder === SortType.ASC ? aKeyVal - bKeyVal : bKeyVal - aKeyVal;
    });
  }, []);

  const getSortingValue = (chartData: MFChartData, key: string): number | string => {
    switch (key) {
      case ConesSortingKeys.INDEX:
        return String(chartData.index);
      case ConesSortingKeys.CONSIDERATION:
        return chartData.values[2].previousWave.latestValue as number;
      case ConesSortingKeys.AWARENESS:
        return chartData.values[0].previousWave.latestValue as number;
      default:
        return chartData.values[0].previousWave[key] as number;
    }
  };

  const sortChartCones = React.useCallback((chartData: MFChartData[], conesSortingData) => {
    return chartData.sort((a, b) => {
      const aValue = getSortingValue(a, conesSortingData.key);
      const bValue = getSortingValue(b, conesSortingData.key);

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      }

      return (bValue as number) - (aValue as number);
    });
  }, []);

  const { chartHeaders, chartData } = React.useMemo(() => {
    const marketFunnelChart = getChartData({
      chartData: marketFunnelData || [],
      groupedBy: chartConf.configuration.first_dimension,
      waveMap: waves,
      countryMap: getCountryIdMap(chartConf.study_uuid),
      brandList: brands,
      // TODO: Think about how to re-organize name -> id mapping
      segmentList: segments?.flatMap(({ segments }) => segments.map(({ id, name }) => ({ [id]: name }))),
      translator: t,
    });

    let sortedData = [...marketFunnelChart.chartData];

    if (tableSorting.key && marketFunnelChart && tableSorting.type) {
      sortedData = sortChartData(sortedData, tableSorting.key, tableSorting.type);
    }

    if (conesSortingData) {
      sortedData = sortChartCones(sortedData, conesSortingData);
    }

    return { ...marketFunnelChart, chartData: sortedData };
  }, [
    brands,
    waves,
    marketFunnelData,
    sortChartData,
    sortChartCones,
    tableSorting,
    conesSortingData,
    chartConf.configuration.first_dimension,
    chartConf.study_uuid,
    getCountryIdMap,
    segments,
    t,
  ]);

  const latestWaveDate = getLatestWaveDate(marketFunnelData || [], waves);

  if (isBrandsLoading || isSegmentsLoading) return null;

  return (
    <MarketFunnelChartContainer>
      <CurrentDataLabel inPage={inPage}>
        <LatanaText color='gray900' variant='T3'>
          {`Current data: ${displayDate(latestWaveDate, 'MMMM YYYY')}`}
        </LatanaText>
      </CurrentDataLabel>
      <FunnelChartContainer inPage={inPage} data-testid={DataTestId.MARKET_FUNNEL_CHART}>
        <FunnelDirectionContainer inPage={inPage}>
          <FunnelAxleContainer>
            <LatanaText color='gray500' variant='L2'>
              {chartData[0]?.segment}
            </LatanaText>
            {isGroupedBySegment && (
              <FunnelAxleTooltipWrapper>
                {withTooltip(<Icon icon='info' color='gray600' />, t('marketFunnel.axleTooltipText'))}
              </FunnelAxleTooltipWrapper>
            )}
          </FunnelAxleContainer>
          <ArrowDown />
          <LatanaText color='gray500' variant='L2'>
            {t('common.awareness')}
          </LatanaText>
          <ArrowDown />
          <LatanaText color='gray500' variant='L2'>
            {t('common.consideration')}
          </LatanaText>
        </FunnelDirectionContainer>
        {chartData?.map((item, index) => {
          const getItemColor = () => {
            if (isGroupedBySegment) {
              return segmentsColorsMap?.[item.index] || getColorForData(index);
            }
            return brandsColorMap?.[item.index] || getColorForData(index) || coloursPalette.blue500;
          };
          return (
            <FunnelElement
              key={`Funnel-${item.index}`}
              label={`${item.index}`}
              segment={{
                percentage: 100,
                color: MARKET_FUNNEL_COLORS[getItemColor()]?.light,
                population: item.values[0].previousWave.population,
              }}
              awareness={{
                percentage: item.values[0].previousWave.latestValue || 0,
                color: getItemColor(),
              }}
              consideration={{
                percentage: item.values[2].previousWave.latestValue || 0,
                color: MARKET_FUNNEL_COLORS[getItemColor()]?.dark,
              }}
            />
          );
        })}
      </FunnelChartContainer>
      {!inPage && (
        <Table data-testid={DataTestId.MARKET_FUNNEL_TABLE}>
          <MarketFunnelHeader headers={chartHeaders} onSort={onSetSorting} sorting={tableSorting} />
          <MarketFunnelBody data={chartData} />
        </Table>
      )}
    </MarketFunnelChartContainer>
  );
};

export default MarketFunnel;
