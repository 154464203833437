import Rollbar, { LogArgument } from 'rollbar';

// The user agent is defined based on the article
// https://support.detectify.com/support/solutions/articles/48001049001-how-to-allow-detectify-access-to-my-assets
const DETECTIFY_USER_AGENT_HEADER = 'Mozilla/5.0 (compatible; Detectify';

const ignoreList = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'Invite user error',
  'This user has already been invited',
  'This chart cannot be deleted because it belongs to the following dashboard(s): Essential Overview',
  'null: item per minute limit reached, ignoring errors until timeout',
  '(unknown): ResizeObserver loop completed with undelivered notifications.',
];

interface ErrorObject {
  error?: string[];
  errors?: string[];
}

const getRollbarConfig = (): Rollbar => {
  const { REACT_APP_ROLLBAR_ID, REACT_APP_ROLLBAR_ENV, REACT_APP_GIT_SHA } = process.env;
  return new Rollbar({
    enabled: REACT_APP_ROLLBAR_ENV !== 'development',
    accessToken: REACT_APP_ROLLBAR_ID,
    autoInstrument: true,
    addErrorContext: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: REACT_APP_ROLLBAR_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: REACT_APP_GIT_SHA,
          guess_uncaught_frames: true,
        },
      },
    },
    checkIgnore: (_isUncaught: boolean, args: LogArgument[]) => {
      if (window?.navigator?.userAgent.includes(DETECTIFY_USER_AGENT_HEADER)) return true;
      if (!args?.[0]) return false;
      const error = args[0] as string;
      const errorObject = args[1] as string & ErrorObject;
      const errorMessage = (errorObject?.error?.[0] || errorObject?.errors?.[0]) as string;
      return ignoreList.includes(error) || ignoreList.includes(errorMessage);
    },
  });
};

export default getRollbarConfig;
