import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

const EditInputField = styled.input`
  color: ${({ theme }) => theme.v2.colours.gray900};
  padding: 0;
  padding-left: ${({ theme }) => theme.v2.space(1)};
  font-size: ${({ theme }) => theme.v2.text.variants.H2.fontSize};
  font-weight: ${({ theme }) => theme.v2.text.variants.H2.fontWeight};
  line-height: ${({ theme }) => theme.v2.text.variants.H2.lineHeight};
  letter-spacing: ${({ theme }) => theme.v2.text.variants.H2.letterSpacing};
  border: none;
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  max-width: 50%;

  &:hover {
    background: ${({ theme }) => theme.v2.colours.gray100};
  }

  &:focus {
    background: ${({ theme }) => theme.v2.colours.gray100};
    outline: none;
  }

  &[readonly] {
    cursor: default;
    &:hover {
      background: none;
    }

    &:focus {
      background: none;
      outline: none;
    }
  }
`;

interface Props {
  value: string;
  setValue: (value: string) => void;
  readOnly?: boolean;
}

const InlineEditInput = React.forwardRef(({ value, setValue, readOnly }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
  const [editingValue, setEditingValue] = useState(value);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setEditingValue(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      (event.target as HTMLElement).blur();
    }
  };

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === '') {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  return (
    <EditInputField
      ref={ref}
      type='text'
      aria-label='Field name'
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      size={editingValue.length}
      readOnly={readOnly}
      maxLength={50}
    />
  );
});

InlineEditInput.displayName = 'InlineEditInput';

export default React.memo(InlineEditInput);
