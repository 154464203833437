import React from 'react';
import SegmentsContent from 'modules/segments/components/SegmentsContent';
import { Segments } from 'common/types/segments';
import { useRawSegments } from 'common/queries/segments';
import { useGetAllStudies } from 'common/queries/studies';
import { Fallback } from 'common/styledComponents/loading';

const CustomSegmentsTab = () => {
  const { data: studies, isLoading: isStudiesLoading } = useGetAllStudies();
  const { data: segments, isLoading: isSegmentsLoading } = useRawSegments();

  const customSegments = React.useMemo(() => {
    return (segments || []).reduce((acc, { study_uuid, segments }) => {
      acc?.push({
        study_uuid,
        segments: segments.filter(({ readable_only }) => !readable_only),
      });
      return acc;
    }, [] as Segments[]);
  }, [segments]);

  if (isSegmentsLoading || isStudiesLoading || !segments?.length || !studies?.length) {
    return <Fallback />;
  }

  return <SegmentsContent tabSegments={customSegments} studies={studies} />;
};

export default CustomSegmentsTab;
