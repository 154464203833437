import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/styledComponents/typography';
import { Button } from 'common/components/Form/Button';
import { IFilter } from 'common/types/filters';
import { KPIType } from 'common/types/common';
import { NewCloseIcon } from 'icons';
import {
  ModalHeader,
  KPITypeSwitcherWrapper,
  KPITypeSwitcherLeft,
  KPITypeSwitcherRight,
} from 'modules/charts/components/Filters/FilterModal/styled';
import { InfoBtn } from 'common/styledComponents/buttons';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { DataTestId } from 'settings/constants';
import { normalizeTitle } from 'utils/helpers';
import { KPIFilterService } from '../services/kpi';

interface Props {
  isCreate: boolean;
  activeFilterDetails?: IFilter;
  onHandleFiltersClose: () => void;
  faqURL?: string;
}

const FilterModalHeader: React.FC<Props> = ({ isCreate, activeFilterDetails, onHandleFiltersClose, faqURL }) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();

  const onHandleTooltipClick = () => {
    trackEvent(TRACKING_EVENTS.FAQ_KPI_LINK);
  };

  // Render function for KPI Switcher
  const renderKPITypeSwitcher = () => {
    return (
      <>
        {KPIFilterService.filterModal.showKpiSwitcher(activeFilterDetails) && (
          <KPITypeSwitcherWrapper>
            <KPITypeSwitcherLeft
              data-active={KPIFilterService.filterModal.isPercentageKPIType()}
              onClick={() => KPIFilterService.filterModal.setKpiSwitchType(KPIType.PERCENTAGE)}
            >
              <Text variant='body2' marginBottom={false} marginTop={false} color='inherit'>
                {t(`filters.percentage`)}
              </Text>
            </KPITypeSwitcherLeft>
            <KPITypeSwitcherRight
              data-active={KPIFilterService.filterModal.isAbsoluteKPIType()}
              onClick={() => KPIFilterService.filterModal.setKpiSwitchType(KPIType.ABSOLUTE)}
            >
              <Text variant='body2' marginBottom={false} marginTop={false} color='inherit'>
                {t(`filters.numeric`)}
              </Text>
            </KPITypeSwitcherRight>
          </KPITypeSwitcherWrapper>
        )}
      </>
    );
  };

  return (
    <ModalHeader data-testid={DataTestId[`MODAL_${normalizeTitle(activeFilterDetails?.label || '').toUpperCase()}` as 'MODAL_GEOGRAPHY']}>
      {activeFilterDetails ? (
        <React.Fragment>
          <Text variant='h5' fontWeight='800' marginBottom={false} marginTop={false}>
            {activeFilterDetails.label}
            {faqURL && (
              <a href={faqURL} target='_blank' rel='noopener noreferrer' onClick={onHandleTooltipClick}>
                <InfoBtn />
              </a>
            )}
          </Text>
          {renderKPITypeSwitcher()}
        </React.Fragment>
      ) : null}
      {isCreate && (
        <Button type='button' onClick={onHandleFiltersClose} pseudo bubble color='black05'>
          <img alt={t(`common.cancel`)} src={NewCloseIcon} />
        </Button>
      )}
    </ModalHeader>
  );
};

export default FilterModalHeader;
