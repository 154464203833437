import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import ToggleSwitch from 'common/components/ToggleSwitch';
import Tooltip from 'common/components/Tooltip';
import { InfoIndicatorContainer } from 'common/components/InfoIndicator';
import { theme } from 'common/theme';

const ControlContainer = styled.div`
  border: none;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
`;

const PopperContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  max-width: 201px;
  z-index: 2;
`;

const ItemsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const PopperItem = styled.div<{ isEditor: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
  &:nth-of-type(3) {
    border-top: 1px solid rgba(226, 226, 226, 0.5);
    padding-top: ${theme.spacing.unit * 2}px;
  }
  &:last-child {
    margin-bottom: 0;
    ${({ isEditor, theme }) =>
      isEditor
        ? `
    padding-top: ${theme.spacing.unit * 2}px;
    border-top: 1px solid rgba(226, 226, 226, 0.5);
    p {
      color: rgba(226, 21, 74, 1);
    }
    `
        : ``};
  }
  &:hover {
    ${InfoIndicatorContainer} {
      [data-state='normal'] {
        display: none;
      }
      [data-state='hover'] {
        display: initial;
      }
    }
  }
`;

const PopperItemTooltipContainer = styled.div`
  margin: ${theme.space(0, -2)};
`;

const PopperItemTooltipInner = styled.div`
  padding: ${theme.space(0, 2)};
`;

const ItemText = styled(Text)`
  cursor: pointer;
  white-space: nowrap;
  color: rgba(20, 20, 20, 1);
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  margin-left: ${({ theme }) => theme.spacing.unit * 4}px;
`;

const PopperTooltip = styled(Tooltip)`
  max-width: 100%;
`;

export const PopperTooltipContentWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing.unit * 2}px;
  a {
    text-decoration: none;
  }
`;

export {
  ControlContainer,
  PopperContainer,
  ItemsContainer,
  PopperItem,
  PopperItemTooltipContainer,
  PopperItemTooltipInner,
  ItemText,
  StyledToggleSwitch,
  PopperTooltip,
};
