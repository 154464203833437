import React, { useState } from 'react';
import AxisLabelWrapped from './AxisLabelWrapped';
import styled from '@emotion/styled';
import { coloursPalette } from 'theme/colours';
import { TextVariants } from 'theme/typography';
import { measureTextWidth, trimString } from 'utils/helpers';

const AxisLabel = styled.text`
  font-size: ${({ theme }) => theme.v2.text.variants[TextVariants.L2].fontSize};
  font-weight: ${({ theme }) => theme.v2.text.variants[TextVariants.L2].fontWeight};
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  fill: ${coloursPalette.gray500};
`;

interface AxisProps {
  minLabel: string;
  maxLabel: string;
  label: string;
  chartHeight: number;
  chartWidth: number;
  orientation: MatrixAxisOrientation;
  leftMargin: number;
}

export enum MatrixAxisOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export const LINE_HEIGHT = 15;
export const LABEL_MAX_CHARTS = 60;

const MatrixAxis: React.FC<AxisProps> = ({ minLabel, maxLabel, label, chartHeight, chartWidth, orientation, leftMargin }) => {
  const [yAxisLabelHeight, setYAxisLabelHeight] = useState<number>(LINE_HEIGHT);
  const axisArrowSize = 5;

  /* Render Y Axis */
  if (orientation === MatrixAxisOrientation.VERTICAL) {
    const paddingFromEdge = 30; // Assuming a fixed padding from left edge of SVG
    const middleY = chartHeight / 2;
    const minMaxLabelHeight = 24;

    return (
      <g>
        {/* Render max label text */}
        <AxisLabel x={paddingFromEdge} y={0} textAnchor='middle' dominantBaseline={'hanging'}>
          {maxLabel}
        </AxisLabel>

        {/* Render arrow at the end of the line */}
        <polygon
          points={`${paddingFromEdge},${minMaxLabelHeight} ${paddingFromEdge - axisArrowSize / 2},${minMaxLabelHeight + axisArrowSize} ${
            paddingFromEdge + axisArrowSize / 2
          },${minMaxLabelHeight + axisArrowSize}`}
          fill={coloursPalette.gray400}
        />

        {/* Render first half of the line from top */}
        <line
          x1={paddingFromEdge}
          y1={minMaxLabelHeight} // the height of the max label
          x2={paddingFromEdge}
          y2={middleY - yAxisLabelHeight / 2}
          stroke={coloursPalette.gray400}
          strokeWidth='1'
        />

        {/* Render label text with wrapped lines */}
        <title>{label}</title>
        <AxisLabelWrapped
          x={paddingFromEdge}
          y={chartHeight / 2}
          maxWidth={60}
          text={`${label}`}
          setYAxisLabelHeight={setYAxisLabelHeight}
        />

        {/* Render second half of the line from top */}
        <line
          x1={paddingFromEdge}
          y1={middleY + yAxisLabelHeight / 2}
          x2={paddingFromEdge}
          y2={chartHeight - minMaxLabelHeight} // reduce the height of max label
          stroke={coloursPalette.gray400}
          strokeWidth='1'
        />

        {/* Render min label text */}
        <AxisLabel x={paddingFromEdge} y={chartHeight} textAnchor='middle' dominantBaseline={'auto'}>
          {trimString(minLabel, LABEL_MAX_CHARTS)}
        </AxisLabel>
      </g>
    );
  } else {
    /* Render X Axis */

    const paddingFromEdge = 28; // Assuming a fixed padding from bottom edge of SVG
    const middleX = chartWidth / 2 + leftMargin;
    const xAxisEndXPosition = chartWidth + leftMargin - 36; // End of line for x axis with deducted 36 pixels for max label
    const xAxisYPosition = chartHeight + paddingFromEdge; // Y coord position of X axis
    const minMaxLabelWidth = 36; // The maximum width of min or max label on axis
    const sizeOfAxisLabelOnMiddle = measureTextWidth(label, '12px Mulish') + 16; // Calculated size of label + 16px gap

    return (
      <g>
        {/* Render min label text */}
        <AxisLabel x={leftMargin} y={xAxisYPosition} textAnchor='start' dominantBaseline={'middle'}>
          {minLabel}
        </AxisLabel>

        {/* Render first half of the line */}
        <line
          x1={leftMargin + minMaxLabelWidth} // Add max width for minLabel
          y1={xAxisYPosition}
          x2={middleX - sizeOfAxisLabelOnMiddle / 2}
          y2={xAxisYPosition}
          stroke={coloursPalette.gray400}
          strokeWidth='1'
        />

        {/* Render label text */}
        <title>{label}</title>
        <AxisLabel x={middleX} y={xAxisYPosition} textAnchor='middle' dominantBaseline={'middle'}>
          {trimString(label, LABEL_MAX_CHARTS)}
        </AxisLabel>

        {/* Render second half of the line */}
        <line
          x1={middleX + sizeOfAxisLabelOnMiddle / 2}
          y1={xAxisYPosition}
          x2={xAxisEndXPosition}
          y2={xAxisYPosition}
          stroke={coloursPalette.gray400}
          strokeWidth='1'
        />

        {/* Render arrow at the end of the line */}
        <polygon
          points={`${xAxisEndXPosition},${xAxisYPosition} ${xAxisEndXPosition - axisArrowSize},${xAxisYPosition - axisArrowSize / 2} ${
            xAxisEndXPosition - axisArrowSize
          },${xAxisYPosition + axisArrowSize / 2}`}
          fill={coloursPalette.gray400}
        />

        {/* Render max label text */}
        <AxisLabel x={chartWidth + leftMargin} y={xAxisYPosition} textAnchor='end' dominantBaseline={'middle'}>
          {maxLabel}
        </AxisLabel>
      </g>
    );
  }
};

export default MatrixAxis;
