import { useCallback, useMemo } from 'react';

export const useIsChartType = (
  chartType: string,
): {
  isChartType: (desiredChartType: string) => boolean;
} => {
  const isChartType = useCallback(
    (desiredChartType: string) => {
      return chartType === desiredChartType;
    },
    [chartType],
  );

  return useMemo(
    () => ({
      isChartType,
    }),
    [isChartType],
  );
};
