import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { Text } from 'common/styledComponents/typography';
import React from 'react';
import { useTracking } from 'common/hooks/tracking';
import { LinkIcon as LinkIconBase } from 'icons';
import { LatanaText } from 'common/components/LatanaText';
import { css } from '@emotion/react';

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.space(2, 3)};
`;

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.cascadingWhite.toString()};
  padding: ${theme.space(2, 4)};
  span {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  text-align: center;
  min-height: 100px;
  padding: ${theme.space(4, 0, 0)};
`;

export const ButtonWrapper = styled.div<{ width: string; justify: string }>`
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  ${props => `width: ${props.width}`};
  ${props => `justify-content: ${props.justify}`};
  padding: ${({ theme }) => theme.space(4, 0)};
`;

const TooltipContentWrapper = styled.div<{ width: string }>`
  background: ${({ theme }) => theme.v2.colours.gray800};
  padding: ${({ theme }) => theme.v2.space(1)};
  width: ${({ width }) => width};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
`;

const TooltipLink = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
  cursor: pointer;
  display: inline-block;
`;

const BlurbTitleText = styled(LatanaText)`
  margin-bottom: ${({ theme }) => theme.spacing.unit}px;
`;

const LinkIcon = styled.i`
  background: url('${LinkIconBase}') no-repeat center center;
  width: 16px;
  height: 16px;
  background-size: cover;
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.unit}px;
`;

interface BlurbPopupProps {
  title?: string;
  content?: string;
  href?: string;
  linkText?: string;
  type?: string;
  width?: string;
}

export const BlurbPopup = ({ title, content, href, linkText, type, width = '360px' }: BlurbPopupProps): JSX.Element => {
  const { trackEvent } = useTracking();
  const onHandleTooltipClick = React.useCallback((value: string) => trackEvent(value), [trackEvent]);

  return (
    <TooltipContentWrapper width={width}>
      {title && (
        <BlurbTitleText variant='L1' color='gray0'>
          {title}
        </BlurbTitleText>
      )}
      <LatanaText variant='L2' color='gray0'>
        {content}
      </LatanaText>
      {href && linkText && (
        <a href={href} target='_blank' rel='noopener noreferrer' onClick={() => onHandleTooltipClick(type as string)}>
          <LinkIcon />
          <TooltipLink variant='body2' color='regularGrey'>
            {linkText}
          </TooltipLink>
        </a>
      )}
    </TooltipContentWrapper>
  );
};

export const tooltipOnHover = (position?: 'bottom' | 'top' | 'left' | 'right') => css`
  &::before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: ${theme.v2.colours.gray800};
    color: ${theme.v2.colours.gray0};
    text-align: center;
    border-radius: 4px;
    padding: ${theme.v2.space(0.5, 1)};
    transition: opacity 0.4s ease-in-out;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 4px);

    ${position === 'bottom' &&
    `
      top: calc(100% + 8px);
      transform: translateX(-50%);
      left: 50%;
      max-width: 150px;
    `}
  }
  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
`;

export const ConfirmationTextWrapper = styled.div`
  margin: ${({ theme }) => theme.v2.space(2.75, 0)};
  text-align: center;
`;
