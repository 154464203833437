import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { Characteristic, CharacteristicOption, Segment, SegmentTabNames, ActionTypes, FormStates } from 'common/types/segments';
import SegmentCharacteristicsSection from 'modules/segments/components/SegmentCharacteristicsSection';
import { permissionsSelector } from 'common/atoms/account';
import { useRecoilValue } from 'recoil';
import { useDeleteSegment, useSaveSegment } from 'modules/segments/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import SegmentViewHeader from 'modules/segments/components/SegmentViewHeader';
import Icon from 'common/components/Icon';
import { LatanaText } from 'common/components/LatanaText';
import { useTranslation } from 'react-i18next';
import { serializeCharacteristics } from 'utils/segments';
import ConfirmationModal from 'common/components/Modal/ConfirmationModal';
import { ConfirmationTextWrapper } from 'common/styledComponents/modals';
import { useShowToast } from 'common/hooks/notification';
import { useBlocker, useNavigate } from 'react-router-dom';
import { SEGMENTS_ROUTES } from 'settings/routes';
import { useGetCharacteristics } from 'common/queries/characteristics';
import { Fallback } from 'common/styledComponents/loading';

const SegmentViewContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.v2.space(2.5, 4)};
`;

const WarningContainer = styled.div`
  > svg {
    margin-right: ${({ theme }) => theme.v2.space(1)};
  }
  display: flex;
  padding: ${({ theme }) => theme.v2.space(3, 0, 0, 3)};
`;

enum CharacteristicFieldTypes {
  STANDARD = 'standard_characteristics',
  EXTRA = 'extra_characteristics',
}

interface SegmentFormData {
  name: string;
  study_uuid: string;
  standard_characteristics: Record<string, string[]>;
  extra_characteristics: Record<string, string[]>;
}

interface SegmentViewProps {
  tabName?: string;
  segment: Segment | null;
  study: string | null;
  formState: FormStates;
  defaultSegmentName?: string;
  formStateDispatch: React.Dispatch<{ type: ActionTypes }>;
}

const SegmentView: React.FC<SegmentViewProps> = ({
  segment,
  tabName,
  formState,
  formStateDispatch,
  study,
  defaultSegmentName,
}): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { saveSegment } = useSaveSegment();
  const { deleteSegment, loading: deleteSegmentLoading } = useDeleteSegment();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showLeaveModal, setShowLeaveModal] = React.useState(false);
  const showToast = useShowToast();
  const navigate = useNavigate();

  const { data: characteristics, isLoading: isFetchingCharacteristics } = useGetCharacteristics(study ?? '');

  const { fetchPost, fetchPut } = useRecoilValue(fetchSelector);
  const { segment_edit: isEditor } = useRecoilValue(permissionsSelector);

  const isInViewMode = formState === FormStates.VIEW;
  const isLatanaSegmentsTab = tabName === SegmentTabNames.LATANA;

  const blockPageLeave = useBlocker(formState === FormStates.EDIT);

  const initialSegmentState = React.useMemo(
    () => ({
      name: defaultSegmentName,
      extra_characteristics: {},
      standard_characteristics: {},
    }),
    [defaultSegmentName],
  );

  const segmentInitValues = React.useMemo(() => {
    if (!segment) {
      return initialSegmentState;
    }

    const serializeInitialCharacteristics = <T extends Characteristic>(characteristics: T[]): { [key: string]: T['values'] } => {
      return characteristics?.reduce<{ [key: string]: T['values'] }>((acc, curr) => {
        const { name, values } = curr;
        acc[name] = values;
        return acc;
      }, {});
    };

    return {
      name: segment?.name,
      extra_characteristics: serializeInitialCharacteristics(segment?.extra_characteristics),
      standard_characteristics: serializeInitialCharacteristics(segment?.standard_characteristics),
    };
  }, [segment, initialSegmentState]);

  const {
    register,
    getValues,
    setValue,
    formState: { isDirty },
    reset,
    trigger,
    watch,
    handleSubmit,
  } = useForm({ defaultValues: segmentInitValues });

  React.useEffect(() => {
    register({
      name: 'study_uuid',
      value: segment?.study_uuid ?? study,
    });
  }, [register, segment, study]);

  React.useEffect(() => {
    if (blockPageLeave.state === 'blocked') {
      setShowLeaveModal(true);
    }
  }, [blockPageLeave, setShowLeaveModal]);

  React.useEffect(() => {
    if (formState === FormStates.CREATE) {
      reset(initialSegmentState);
    } else {
      reset(segmentInitValues);
    }
  }, [reset, formState, segmentInitValues, initialSegmentState]);

  const characteristicsLookupMap = React.useMemo(
    () => ({
      standard_characteristics: (characteristics?.standard_characteristics || []).reduce((acc, { key, options }) => {
        acc[key] = options;
        return acc;
      }, {} as Record<string, CharacteristicOption[]>),
      extra_characteristics: (characteristics?.extra_characteristics || []).reduce((acc, { key, options }) => {
        acc[key] = options;
        return acc;
      }, {} as Record<string, CharacteristicOption[]>),
    }),
    [characteristics],
  );

  const onHandleOptionChange = React.useCallback(
    (field: string, payload: { name: string; value: string | string[] }): void => {
      const { name, value } = payload;

      if (Array.isArray(value)) {
        setValue(field, value, { shouldDirty: true });
        return;
      }

      const fieldState = (getValues(field) as string[]) || [];
      const newFieldValue = getNewFieldValue(fieldState, value);

      // Extract the characteristic type from the field name.
      // [characteristicType] can be either standardCharacteristics or extraCharacteristics.
      const characteristicType = field.split('.')[0] as keyof typeof characteristicsLookupMap;
      const characteristicValues = characteristicsLookupMap?.[characteristicType]?.[name];

      // Reset the field if all options are selected to allow users to deselect all.
      if (newFieldValue?.length === characteristicValues?.length) {
        setValue(field, [], { shouldDirty: true });
        return;
      }

      setValue(field, newFieldValue, { shouldDirty: true });
      trigger();
    },
    [characteristicsLookupMap, setValue, trigger, getValues],
  );

  const getNewFieldValue = (fieldState: string[], value: string): string[] => {
    return fieldState.includes(value) ? fieldState.filter(option => option !== value) : [...fieldState, value];
  };

  const handleSegmentNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setValue('name', event.target.value, { shouldDirty: true });
    },
    [setValue],
  );

  const getRequestParams = React.useCallback(
    (formState: FormStates) => {
      return formState === FormStates.EDIT && segment?.id
        ? { fetchMethod: fetchPut, url: API_URLS.SEGMENT_UPDATE(segment.id) }
        : { fetchMethod: fetchPost, url: API_URLS.SEGMENTS };
    },
    [fetchPost, fetchPut, segment?.id],
  );

  const handleFormSubmit = React.useCallback(
    async (formData: SegmentFormData) => {
      const segmentPayload = {
        segment: {
          ...formData,
          standard_characteristics: serializeCharacteristics(formData.standard_characteristics),
          extra_characteristics: serializeCharacteristics(formData.extra_characteristics || []),
        },
      };

      const requestParams = getRequestParams(formState);

      const segment = await saveSegment(segmentPayload, requestParams);
      await queryClient.invalidateQueries(['segments']);

      showToast({
        message: segment?.success ? t('segmentBuilder.toastMessages.save', { title: segment?.data?.name || '' }) : segment?.error || '',
        status: segment?.success ? 'success' : 'error',
      });

      navigate(`/segments/${SEGMENTS_ROUTES.CUSTOM}/${segment?.data?.id ?? ''}`);
    },
    [queryClient, saveSegment, formState, getRequestParams, showToast, t, navigate],
  );

  const handleSegmentUpdate = React.useCallback(() => {
    isDirty && handleSubmit(handleFormSubmit)();
    formStateDispatch({ type: ActionTypes.SET_READ });
  }, [formStateDispatch, handleFormSubmit, handleSubmit, isDirty]);

  const toggleDeleteModal = React.useCallback(() => {
    setShowDeleteModal(!showDeleteModal);
  }, [showDeleteModal]);

  const toggleLeaveModal = React.useCallback(() => {
    setShowLeaveModal(!showLeaveModal);
  }, [showLeaveModal]);

  const handleSegmentDelete = React.useCallback(async () => {
    if (!segment) return;

    await deleteSegment(segment.id);
    await queryClient.invalidateQueries(['segments']);
    formStateDispatch({ type: ActionTypes.SET_READ });
    toggleDeleteModal();
    navigate(`/segments/${SEGMENTS_ROUTES.CUSTOM}`, { state: { studyId: study } });
  }, [deleteSegment, segment, formStateDispatch, queryClient, toggleDeleteModal, navigate, study]);

  const handleCancelEdit = React.useCallback(() => {
    reset(segmentInitValues);
    formStateDispatch({ type: ActionTypes.SET_READ });
  }, [reset, segmentInitValues, formStateDispatch]);

  const handleEdit = React.useCallback(() => {
    formStateDispatch({ type: ActionTypes.SET_WRITE });
    showToast({
      message: t('segmentBuilder.toastMessages.edit', { user: segment?.last_edited_by_name || '' }),
      status: 'warning',
    });
  }, [formStateDispatch, showToast, t, segment]);

  const handleDontSaveOnLeave = React.useCallback(() => {
    toggleLeaveModal();
    blockPageLeave.proceed?.();
  }, [toggleLeaveModal, blockPageLeave]);

  const handleSaveOnLeave = React.useCallback(() => {
    toggleLeaveModal();
    handleSegmentUpdate();
  }, [toggleLeaveModal, handleSegmentUpdate]);

  const renderDeleteModal = React.useMemo(() => {
    return (
      <ConfirmationModal
        isLoading={deleteSegmentLoading}
        title={t('segmentBuilder.deleteModal.title')}
        open={showDeleteModal}
        onCancel={toggleDeleteModal}
        onConfirm={handleSegmentDelete}
      >
        <ConfirmationTextWrapper>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.headingv2', { title: segment?.name || '' })}
          </LatanaText>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.subheadingv2')}
          </LatanaText>
        </ConfirmationTextWrapper>
      </ConfirmationModal>
    );
  }, [handleSegmentDelete, showDeleteModal, toggleDeleteModal, t, segment, deleteSegmentLoading]);

  const renderLeaveModal = React.useMemo(() => {
    return (
      <ConfirmationModal
        title={t('segmentBuilder.unsavedModal.title')}
        open={showLeaveModal}
        onCancel={handleDontSaveOnLeave}
        onConfirm={handleSaveOnLeave}
        confirmButtonTitle={t('segmentBuilder.unsavedModal.confirm')}
        type='confirm'
        cancelButtonTitle={t('segmentBuilder.unsavedModal.cancel')}
      >
        <ConfirmationTextWrapper>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.unsavedModal.heading')}
          </LatanaText>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.unsavedModal.headingv2')}
          </LatanaText>
          <br></br>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.unsavedModal.subheading')}
          </LatanaText>
        </ConfirmationTextWrapper>
      </ConfirmationModal>
    );
  }, [showLeaveModal, t, handleSaveOnLeave, handleDontSaveOnLeave]);

  // Helper function to render characteristics sections
  const renderCharacteristicsSection = (
    characteristics: Characteristic[],
    fieldType: CharacteristicFieldTypes,
    formState: FormStates,
    watch: ReturnType<typeof useForm>['watch'],
    isLatanaSegmentsTab: boolean,
    register: ReturnType<typeof useForm>['register'],
    onSegmentOptionChange: (
      field: string,
      characteristicValues: {
        name: string;
        value: string | [];
      },
    ) => void,
  ) => {
    return characteristics.map(({ key: characteristicKey, name: characteristicName, options: characteristicOptions }) => {
      const characteristicFieldName = `${fieldType}.${characteristicKey}`;
      const selectedOptionsAmount = ((watch(characteristicFieldName) || []) as string[])?.length;
      const isAllResponsesSelected = selectedOptionsAmount === 0 || selectedOptionsAmount === characteristicOptions.length;

      return (
        <SegmentCharacteristicsSection
          fieldWatch={watch}
          formState={formState}
          key={characteristicKey}
          isAllSelected={isAllResponsesSelected}
          characteristicName={characteristicName}
          isLatanaSegmentsTab={isLatanaSegmentsTab}
          register={register(characteristicFieldName)}
          onSegmentOptionChange={onSegmentOptionChange}
          characteristicOptions={characteristicOptions}
          characteristicFieldName={characteristicFieldName}
        />
      );
    });
  };

  const renderWarningMessage = (text: string) => {
    return (
      <WarningContainer>
        <Icon icon='info' color='gray500' />
        <LatanaText color='gray500' variant='B3' fontWeight='500'>
          {text}
        </LatanaText>
      </WarningContainer>
    );
  };

  if (!segment && formState !== FormStates.CREATE) {
    return study
      ? renderWarningMessage(t('segmentBuilder.noSegmentsInStudy'))
      : renderWarningMessage(t('segmentBuilder.selectStudyFirstMessage'));
  }

  if (!characteristics || isFetchingCharacteristics) {
    return <Fallback />;
  }

  return (
    <SegmentViewContainer>
      <form autoComplete='off' onSubmit={handleSubmit(handleFormSubmit)}>
        <SegmentViewHeader
          segment={segment}
          isEditor={isEditor}
          formRegister={register}
          isInViewMode={isInViewMode}
          handleCancelEdit={handleCancelEdit}
          handleSegmentEdit={handleEdit}
          isLatanaSegmentsTab={isLatanaSegmentsTab}
          handleSegmentDelete={toggleDeleteModal}
          handleSegmentUpdate={handleSegmentUpdate}
          handleSegmentNameChange={handleSegmentNameChange}
        />
        <React.Fragment>
          <React.Fragment>
            {characteristics?.standard_characteristics &&
              renderCharacteristicsSection(
                characteristics.standard_characteristics,
                CharacteristicFieldTypes.STANDARD,
                formState,
                watch,
                isLatanaSegmentsTab,
                register,
                onHandleOptionChange,
              )}
          </React.Fragment>
          {!isLatanaSegmentsTab && (
            <React.Fragment>
              {characteristics?.extra_characteristics &&
                renderCharacteristicsSection(
                  characteristics.extra_characteristics,
                  CharacteristicFieldTypes.EXTRA,
                  formState,
                  watch,
                  isLatanaSegmentsTab,
                  register,
                  onHandleOptionChange,
                )}
            </React.Fragment>
          )}
        </React.Fragment>
      </form>
      {renderDeleteModal}
      {renderLeaveModal}
    </SegmentViewContainer>
  );
};

export default SegmentView;
