import { useRecoilCallback } from 'recoil';
import { API_URLS } from 'settings/api';
import { fetchSelector } from 'common/atoms/common';
import { UserDetailsResponse } from 'common/types/account';
import { useRefetchAccountDetails } from 'common/hooks/account';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';

export const usePreferences = (): ((data: unknown) => Promise<void>) => {
  const rollbar = useRollbar();
  const { refetchAccountDetails } = useRefetchAccountDetails();
  return useRecoilCallback(
    ({ snapshot }) =>
      async data => {
        const { fetchPatch } = await snapshot.getPromise(fetchSelector);
        try {
          const userDetails = await fetchPatch<UserDetailsResponse>(API_URLS.PROFILE, {
            ui_preferences: data,
          });
          refetchAccountDetails(userDetails);
        } catch (e) {
          rollbar.error('usePreferences error', e as LogArgument);
        }
      },
    [refetchAccountDetails],
  );
};
