import { Wave, WavePayload } from 'common/types/common';
import { getColorForData } from 'modules/charts/utils/colors';

export const mapWave = (wave: WavePayload, index: number): Wave => {
  return {
    ...wave,
    date: new Date(wave.date),
    color: getColorForData(index),
  };
};

export const getLastWaves = (waves: Wave[]): number[] => {
  const newWaves = [...waves];
  const wavesSortedByDate = newWaves?.sort((a: Wave, b: Wave) => new Date(b.date).getTime() - new Date(a.date).getTime());
  return wavesSortedByDate.slice(0, 4).map(({ id }) => id);
};
