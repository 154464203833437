import { KPI, KPIGroups, KPIType } from 'common/types/common';
import { FilterNames, FiltersState, IFilter } from 'common/types/filters';
import { filterKpisByType } from 'utils/kpi';
import { BaseFilterService } from '.';
import { handleGlobalKPISelection, isGlobalKPI } from '../utils/globalKpi';
import { ChartType } from 'common/types/chart';

interface KPIFilterService {
  isGlobalKPI: boolean;
  setIsGlobalKpi: (isGlobalKpi: boolean) => void;
  onFilterApply: (
    allFilters: FiltersState,
    kpis: KPI[],
    kpi: (string | number)[],
    setChartValue: (field: string, value: (string | number)[]) => void,
    brand: (string | number)[],
  ) => void;
  onNextFilterState: (
    allFilters: FiltersState,
    kpis: KPI[],
    kpi: (string | number)[],
    setChartValue: (field: string, value: (string | number)[]) => void,
    brand: (string | number)[],
  ) => void;
  onNextFilterShow: (
    allFilters: FiltersState,
    kpis: KPI[],
    kpi: (string | number)[],
    setChartValue: (field: string, value: (string | number)[]) => void,
    brand: (string | number)[],
  ) => void;
  onShowFilter: (filterId: FilterNames) => void;
  isFilterDisabled: (filterId: FilterNames) => boolean;

  // FilterModal definitions
  filterModal: {
    kpiSwitchType: KPIType;
    kpisTypeGroups?: KPIGroups;
    setKpiSwitchType: (kpiSwitchType: KPIType) => void;
    setKpisTypeGroups: (kpisTypeGroups: KPIGroups) => void;
    getAvailableOptions: (filter: IFilter) => IFilter | undefined;
    showKpiSwitcher: (activeFilterDetails?: IFilter) => boolean;
    isPercentageKPIType: () => boolean;
    isAbsoluteKPIType: () => boolean;
  };
}

export const KPIFilterService: KPIFilterService = {
  isGlobalKPI: false,

  onShowFilter(filterId) {
    if (
      (BaseFilterService.chartType === ChartType.MARKET_FUNNEL || BaseFilterService.chartType === ChartType.BRAND_PERCEPTION) &&
      filterId === FilterNames.KPI
    ) {
      return;
    }
  },

  setIsGlobalKpi(isGlobalKpi: boolean) {
    KPIFilterService.isGlobalKPI = isGlobalKpi;
  },
  onFilterApply: (
    allFilters: FiltersState,
    kpis: KPI[],
    kpi: (string | number)[],
    setChartValue: (field: string, value: (string | number)[]) => void,
    brand: (string | number)[],
  ) => {
    const globalKPIExist = isGlobalKPI(kpis, kpi);
    const globalMetaBrand = allFilters.brands.find(brand => brand.global === true);

    handleGlobalKPISelection(globalMetaBrand, kpi, globalKPIExist, setChartValue, brand);
  },

  onNextFilterState: (
    allFilters: FiltersState,
    kpis: KPI[],
    kpi: (string | number)[],
    setChartValue: (field: string, value: (string | number)[]) => void,
    brand: (string | number)[],
  ) => {
    KPIFilterService?.onFilterApply(allFilters, kpis, kpi, setChartValue, brand);
  },
  onNextFilterShow: (
    allFilters: FiltersState,
    kpis: KPI[],
    kpi: (string | number)[],
    setChartValue: (field: string, value: (string | number)[]) => void,
    brand: (string | number)[],
  ) => {
    KPIFilterService?.onFilterApply(allFilters, kpis, kpi, setChartValue, brand);
  },
  isFilterDisabled(filterId) {
    if (
      (BaseFilterService.chartType === ChartType.MARKET_FUNNEL ||
        BaseFilterService.chartType === ChartType.BRAND_PERCEPTION ||
        BaseFilterService.chartType === ChartType.MARKET_SIZE) &&
      (filterId === FilterNames.KPI || filterId === FilterNames.WAVE)
    ) {
      return true;
    }
    return false;
  },

  filterModal: {
    kpiSwitchType: KPIType.PERCENTAGE,
    setKpisTypeGroups(kpisTypeGroups) {
      KPIFilterService.filterModal.kpisTypeGroups = kpisTypeGroups;
    },
    setKpiSwitchType(kpiSwitchType) {
      KPIFilterService.filterModal.kpiSwitchType = kpiSwitchType;
    },
    getAvailableOptions(filter) {
      if (KPIFilterService.filterModal.kpisTypeGroups) {
        const availableKpisForType = filterKpisByType(
          filter.options,
          KPIFilterService.filterModal.kpiSwitchType,
          KPIFilterService.filterModal.kpisTypeGroups,
        );
        return { ...filter, options: availableKpisForType } as IFilter;
      }
    },
    showKpiSwitcher(activeFilterDetails) {
      if (!activeFilterDetails) return false;

      if (BaseFilterService.chartType === ChartType.HEATMAP || BaseFilterService.chartType === ChartType.FUNNEL) {
        return false;
      }

      if (activeFilterDetails.id !== FilterNames.KPI || !KPIFilterService.filterModal.kpisTypeGroups) {
        return false;
      }

      const kpiOptions = activeFilterDetails?.options;
      return kpiOptions ? !!filterKpisByType(kpiOptions, KPIType.ABSOLUTE, KPIFilterService.filterModal.kpisTypeGroups)?.length : false;
    },
    isPercentageKPIType() {
      return KPIFilterService.filterModal.kpiSwitchType === KPIType.PERCENTAGE;
    },
    isAbsoluteKPIType() {
      return KPIFilterService.filterModal.kpiSwitchType === KPIType.ABSOLUTE;
    },
  },
};
