import styled from '@emotion/styled';

export const hexToRGBA = (hex: string, opacity: number) => {
  if (!hex) return 'rgba(0, 0, 0, 0)';

  const r = parseInt(hex?.slice(1, 3), 16);
  const g = parseInt(hex?.slice(3, 5), 16);
  const b = parseInt(hex?.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const BpContainer = styled.div`
  border-radius: ${({ theme }) => theme.v2.borderRadius * 2}px;
  border: 1px solid ${({ theme }) => theme.v2.colours.gray100};
  position: relative;
  z-index: 0;
`;

const BpHead = styled.thead``;

const BpHeadItem = styled.td`
  cursor: pointer;
  padding: ${({ theme }) => theme.v2.space(2, 0)};
  &:nth-last-of-type(2),
  &:last-of-type {
    border-left: 1px solid ${({ theme }) => theme.v2.colours.gray100};
  }
`;

const BpCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    margin: ${({ theme }) => theme.v2.space(0.5, 1, 0, 0)};
  }
`;

const BpDateContainer = styled.div`
  padding: ${({ theme }) => theme.v2.space(0, 0, 1, 0)};
`;

const BpLegendContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.v2.space(2, 0, 1, 3)};
`;

const BpLegendIndicator = styled.div<{ color: string }>`
  border-radius: 50%;
  width: ${({ theme }) => theme.v2.space(2)};
  height: ${({ theme }) => theme.v2.space(2)};
  background-color: ${({ color, theme }) => hexToRGBA((theme.v2.colours as Record<string, string>)[`${color}`], 0.5)};
  margin-right: ${({ theme }) => theme.v2.space(1)};
`;

const BpLegendItem = styled.div`
  display: flex;
  :not(:first-of-type) {
    margin: ${({ theme }) => theme.v2.space(0, 0, 0, 3)};
  }
`;

const BpBodyItem = styled.td`
  cursor: pointer;
  padding: ${({ theme }) => theme.v2.space(2, 0)};
  border-top: 1px solid ${({ theme }) => theme.v2.colours.gray100};
  &:nth-last-of-type(2),
  &:last-of-type {
    border-left: 1px solid ${({ theme }) => theme.v2.colours.gray100};
  }
  &:first-of-type {
    padding-left: ${({ theme }) => theme.v2.space(3)};
  }
  > .tooltip-content {
    width: 100%;
    .tooltip-children {
      width: 100%;
    }
  }
`;

const BpBodyRow = styled.tr<{ isAverage: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.v2.colours.gray100};
  background-color: ${({ theme, isAverage }) => (isAverage ? theme.v2.colours.gray50 : 'transparent')};
`;

const BpSentimentContainer = styled.div<{ color: string | number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.v2.space(0, 0.5)};
  background: ${({ color, theme }) => hexToRGBA((theme.v2.colours as Record<string, string>)[`${color}`], 0.2)};
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
`;

const HeaderLabel = styled.span`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: ${({ theme }) => theme.v2.space(1.2)};
    border-left: ${({ theme }) => `${theme.space(0.25)} dotted ${theme.v2.colours.gray400}`};
    right: ${({ theme }) => theme.v2.space(1.5)};
  }
`;

const HeaderLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SVGWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.v2.space(0, 1.5, 0, 1)};
  overflow: hidden;
`;

const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.sooty.toString()};
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  min-width: 114px;
  min-height: 80px;
  padding: ${({ theme }) => theme.v2.space(1.5)};
  > div {
    margin: 0 !important;
    > div:first-of-type,
    div:nth-of-type(2) {
      margin-right: ${({ theme }) => theme.v2.space(1)};
    }
  }
`;

const LegendInfoContainer = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.v2.space(3)};
  > svg {
    margin-right: ${({ theme }) => theme.v2.space(1)};
  }
`;

export {
  BpContainer,
  BpHead,
  BpHeadItem,
  BpCell,
  BpDateContainer,
  BpLegendContainer,
  BpLegendIndicator,
  BpLegendItem,
  BpBodyRow,
  BpBodyItem,
  BpSentimentContainer,
  HeaderLabelContainer,
  HeaderLabel,
  SVGWrapper,
  TooltipContentWrapper,
  LegendInfoContainer,
};
