import Color from 'common/theme/color';
import { FilterOption } from 'common/types/filters';

interface MappedOption {
  label: string;
  value: string;
  color?: Color;
}

export const sortDisplayOptions = (displayOptions: MappedOption[], filterOptions: FilterOption[]): MappedOption[] => {
  const sortedLabels = filterOptions.map(option => option.label);
  displayOptions.sort((a, b) => {
    return sortedLabels.indexOf(a.label) - sortedLabels.indexOf(b.label);
  });
  return displayOptions;
};
