import * as React from 'react';
import { ActionButtonContainer } from './styled';
import { useGetPaginatedCharts } from 'common/queries/charts';
import TableList from 'common/components/TableList';
import { ListItemType } from 'common/types/charts';
import { TableListItemProps } from 'common/types/common';
import TableListItem from 'common/components/TableList/TableListItem';
import Icon from 'common/components/Icon';
import { CheckboxInput } from 'common/components/SelectPopup/styled';
import { withTooltip } from 'common/components/Tooltip/withTooltip';
import { useTranslation } from 'react-i18next';
import { ChartType } from 'common/types/chart';
import { SelectedItemStateProps } from 'common/contexts/DashboardContext';
import { MODAL_LIST_HEIGHT } from 'settings/constants';

interface ActionButtonProps {
  children: JSX.Element;
  setWrapperHovered: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  setSelfHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  isHovered?: boolean;
}

export const ActionButton: React.FC<ActionButtonProps> = ({ children, setSelfHovered, setWrapperHovered, onClick, isHovered }) => {
  const handleMouseLeave = () => {
    setSelfHovered && setSelfHovered(false);
    setWrapperHovered && setWrapperHovered(true);
  };

  const handleMouseOver = () => {
    setSelfHovered && setSelfHovered(true);
    setWrapperHovered && setWrapperHovered(false);
  };
  return (
    <ActionButtonContainer isHovered={isHovered} onMouseLeave={handleMouseLeave} onMouseOver={handleMouseOver} onClick={onClick}>
      {children}
    </ActionButtonContainer>
  );
};

export const useDashboardModalContent = () => {
  const { t } = useTranslation();
  const { paginatedChartList, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } = useGetPaginatedCharts();

  const renderModalContent = React.useCallback(
    ({
      onHandleChange,
      handleOpenChart,
      modalState,
    }: {
      onHandleChange: (chartUuid: string, title: string, chartType?: string | undefined) => void;
      handleOpenChart: (uuid: string) => void;
      modalState: SelectedItemStateProps[];
    }) => (
      <TableList
        type={ListItemType.CHARTS}
        items={paginatedChartList as Array<TableListItemProps>}
        isDataLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextpage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        viewportOffset={MODAL_LIST_HEIGHT}
        inModal
        renderListItem={({ listItemData, ...otherProps }) => (
          <TableListItem
            {...otherProps}
            {...listItemData}
            onChange={() =>
              onHandleChange(
                listItemData.uuid,
                listItemData.name,
                listItemData.chart_type === ChartType.COMPARISON ? listItemData.current_view : listItemData.chart_type,
              )
            }
            renderActionMenu={({ isHovered, setSelfHovered, setWrapperHovered }) =>
              withTooltip(
                <ActionButton
                  onClick={() => handleOpenChart(listItemData.uuid)}
                  isHovered={isHovered}
                  setSelfHovered={setSelfHovered}
                  setWrapperHovered={setWrapperHovered}
                >
                  <Icon icon='external-link' />
                </ActionButton>,
                t('dashboard.openInNewTab'),
              )
            }
          >
            <CheckboxInput
              type='checkbox'
              name={listItemData.uuid}
              checked={!!modalState?.find(x => x.chartUuid === listItemData.uuid)}
              readOnly
            />
          </TableListItem>
        )}
      />
    ),
    [paginatedChartList, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading, t],
  );

  return renderModalContent;
};
