import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const fadeIn = keyframes`
	from {
		visibility: hidden;
		opacity: 0;
	}
	to {
		visibility: visible;
		opacity: 1;
	}
`;

export const TooltipHoverContent = styled.div<{ top: number | undefined; left: number | false | undefined; visible: boolean }>`
  display: inline-block;
  white-space: normal;
  position: absolute;
  text-align: left;
  z-index: 102;
  max-width: 90vw;
  width: max-content;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100000px);
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &[data-orientation='vertical'] {
    &[data-v-anchor='top'] {
      top: 100%;
    }
    &[data-v-anchor='bottom'] {
      bottom: 100%;
    }
    &[data-h-anchor='left'] {
      left: 0;
    }
    &[data-h-anchor='right'] {
      right: 0;
    }
  }
  &[data-orientation='horizontal'] {
    &[data-v-anchor='top'] {
      top: 0;
    }
    &[data-v-anchor='bottom'] {
      bottom: 0;
    }
    &[data-h-anchor='left'] {
      left: 100%;
    }
    &[data-h-anchor='right'] {
      right: 100%;
    }
  }
  &[data-h-anchor],
  &[data-v-anchor] {
    transform: none;
  }
  &[data-arrow='true'] {
    &[data-orientation='vertical'] {
      &[data-v-top='left'] {
        padding-bottom: 16px;
      }
      &[data-v-anchor='bottom'] {
        padding-top: 16px;
      }
    }
    &[data-orientation='horizontal'] {
      &[data-h-anchor='left'] {
        padding-left: 16px;
      }
      &[data-h-anchor='right'] {
        padding-right: 16px;
      }
    }
  }

  ${({ left, top }) =>
    left &&
    top &&
    `
		left: ${left}px !important;
		top: ${top}px !important;

	`};

  ${({ visible }) =>
    visible &&
    `
			visibility: visible;
			opacity: 1;
	`};
`;

export const TooltipChildrenContainer = styled.div`
  display: flex;
`;

export const TooltipContainer = styled.div<{
  delay: number;
}>`
  position: relative;
  display: inline-block;
  z-index: 1;

  &:hover {
    > ${TooltipHoverContent}, > ${TooltipChildrenContainer}:before {
      animation: ${fadeIn} ${({ delay }) => delay}s forwards;
      animation-delay: 0.2s;
      pointer-events: initial;
    }
  }
`;

export const IconButtonTooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space(1)};
  height: 24px;
  background: ${({ theme }) => theme.colors.sooty.toString()};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
`;
