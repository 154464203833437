import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartType } from 'common/types/chart';
import { LatanaText as Text } from 'common/components/LatanaText';
import Icon from 'common/components/Icon';
import { ChartTypeItem, ChartTypeIcon, ChartTypeLabel } from './styled';
import { snakeToCamelCase } from 'utils/helpers';
import { ICONS } from 'icons/iconsMap';
import { useNavigate } from 'react-router-dom';
import {
  useIsBrandPerceptionChartsEnabled,
  useIsMarketFunnelChartsEnabled,
  useIsMarketSizeChartsEnabled,
  useIsMatrixChartsEnabled,
  useIsRadarChartsEnabled,
} from 'common/hooks/features';
import { useNavigationContext } from 'common/contexts/HistoryContext';

interface ChartTypeItemProps {
  chartType: ChartType;
  isVisible: boolean;
  icon?: keyof typeof ICONS;
}

const useAvailableChartTypes = (): ((studyId: string) => JSX.Element) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isRadarChartsEnabled = useIsRadarChartsEnabled();
  const isMarketFunnelChartsEnabled = useIsMarketFunnelChartsEnabled();
  const isMarketSizeChartEnabled = useIsMarketSizeChartsEnabled();
  const isBrandPerceptionChartsEnabled = useIsBrandPerceptionChartsEnabled();
  const isMatrixChartsEnabled = useIsMatrixChartsEnabled();
  const { pushToHistory } = useNavigationContext();

  const handleOnClick = useCallback(
    (studyId: string, chartType: ChartType) => {
      return () => {
        pushToHistory(null);
        navigate(`/chart/create?type=${chartType}`, { state: { studyId: studyId } });
      };
    },
    [navigate, pushToHistory],
  );

  return useCallback(
    (studyId: string): JSX.Element => {
      const allChartTypes: Array<ChartTypeItemProps> = [
        {
          chartType: ChartType.GROWTH_PERFORMANCE,
          isVisible: true,
          icon: 'spreadsheet',
        },
        {
          chartType: ChartType.COMPARISON,
          isVisible: true,
          icon: 'line-chart',
        },
        {
          chartType: ChartType.MATRIX,
          isVisible: isMatrixChartsEnabled(studyId),
          icon: 'scatter-chart',
        },
        {
          chartType: ChartType.RADAR,
          isVisible: isRadarChartsEnabled(studyId),
          icon: 'new',
        },
        {
          chartType: ChartType.GAUGE,
          isVisible: true,
          icon: 'score-chart',
        },
        {
          chartType: ChartType.MARKET_SIZE,
          isVisible: isMarketSizeChartEnabled(studyId),
          icon: 'market-size',
        },
        {
          chartType: ChartType.HEATMAP,
          isVisible: true,
          icon: 'heatmap',
        },
        {
          chartType: ChartType.RANKING,
          isVisible: true,
          icon: 'ranking',
        },
        {
          chartType: ChartType.BRAND_PERCEPTION,
          isVisible: isBrandPerceptionChartsEnabled(studyId),
          icon: 'spreadsheet',
        },
        {
          chartType: ChartType.MARKET_FUNNEL,
          isVisible: isMarketFunnelChartsEnabled(studyId),
          icon: 'funnel',
        },
      ];

      return (
        <>
          {allChartTypes
            .filter(({ isVisible }) => isVisible)
            .map(({ chartType, icon }) => (
              <ChartTypeItem key={chartType} data-testid={chartType} onClick={handleOnClick(studyId, chartType)}>
                <ChartTypeIcon>
                  <Icon icon={icon || 'question-circle'} />
                </ChartTypeIcon>
                <ChartTypeLabel>
                  <Text variant='L1' color='gray900'>
                    {t(`${snakeToCamelCase(chartType)}.title`)}
                  </Text>
                  <Text variant='L2' color='gray500'>
                    {t(`${snakeToCamelCase(chartType)}.subtitle`)}
                  </Text>
                </ChartTypeLabel>
              </ChartTypeItem>
            ))}
        </>
      );
    },
    [
      isBrandPerceptionChartsEnabled,
      isMarketFunnelChartsEnabled,
      isMarketSizeChartEnabled,
      isRadarChartsEnabled,
      isMatrixChartsEnabled,
      handleOnClick,
      t,
    ],
  );
};

export default useAvailableChartTypes;
