import * as React from 'react';
import ModalV2 from 'common/components/Modal/ModalV2';
import { LoadingButton } from 'common/components/Form/Button';
import { useTranslation } from 'react-i18next';
import { SelectedItemStateProps, useDashboardContext } from 'common/contexts/DashboardContext';
import { DashboardChartsModalContent, DashboardChartsModalFooter } from 'common/components/Modal/styled';
import { useEffect } from 'react';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';

type RenderContent = ({
  onHandleChange,
  modalState,
}: {
  onHandleChange: (chartUuid: string, title: string, chartType?: string) => void;
  modalState: Array<SelectedItemStateProps>;
}) => JSX.Element;

interface DashboardChartsModalProps {
  isOpen: boolean;
  toggleDashboardModal: () => void;
  renderContent: RenderContent;
}

const DashboardChartsModal: React.FC<DashboardChartsModalProps> = ({ isOpen, toggleDashboardModal, renderContent }) => {
  const { t } = useTranslation();
  const { updateDashboardState, dashboardState } = useDashboardContext();
  const [modalState, setModalState] = React.useState<SelectedItemStateProps[]>([]);
  const { trackEvent } = useTracking();

  const toggleChartInModalState = React.useCallback((item: SelectedItemStateProps, prevState: Array<SelectedItemStateProps>) => {
    return prevState.some(x => x.chartUuid === item.chartUuid)
      ? prevState.filter(prevItem => prevItem.chartUuid !== item.chartUuid)
      : [...prevState, item];
  }, []);

  const onHandleChange = React.useCallback(
    (chartUuid: string, title: string, chartType?: string) => {
      setModalState(prevState => toggleChartInModalState({ chartUuid: chartUuid, title: title, chartType: chartType }, prevState));
    },
    [setModalState, toggleChartInModalState],
  );

  useEffect(() => {
    setModalState(dashboardState);
  }, [dashboardState]);

  const onHandleAddCharts = () => {
    updateDashboardState(modalState);
    toggleDashboardModal();
    trackEvent(TRACKING_EVENTS.ADD_CHARTS_TO_DASHBOARD_STEP_2_ADD);
  };

  const onHandleModalClose = () => {
    setModalState([]);
    toggleDashboardModal();
    trackEvent(TRACKING_EVENTS.ADD_CHARTS_TO_DASHBOARD_STEP_2_CANCEL);
  };

  return (
    <ModalV2
      isOpen={isOpen}
      modalId='dashboard-charts-modal'
      onClose={onHandleModalClose}
      title={t('dashboard.addChartModalTitle')}
      customWidth={800}
    >
      <DashboardChartsModalContent>{renderContent({ onHandleChange, modalState })}</DashboardChartsModalContent>
      <DashboardChartsModalFooter>
        <LoadingButton disabled={false} isButtonV2={true} type='button' color='indigo50' onClick={onHandleModalClose}>
          {t('dashboard.cancelChart')}
        </LoadingButton>
        <LoadingButton disabled={false} isButtonV2={true} loading={false} type='button' color='lightPurple' onClick={onHandleAddCharts}>
          {t('dashboard.addChart')}
        </LoadingButton>
      </DashboardChartsModalFooter>
    </ModalV2>
  );
};

export default React.memo(DashboardChartsModal);
