import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { permissionsSelector } from 'common/atoms/account';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { useTracking } from 'common/hooks/tracking';
import { PlusIcon } from 'icons';
import { ModalFooter, AddSegmentButton, ReferenceToggleContainer } from 'modules/charts/components/Filters/FilterModal/styled';
import ToggleSwitch from 'common/components/ToggleSwitch';
import { Text } from 'common/styledComponents/typography';
import { ActionButton, CancelButton } from 'common/styledComponents/buttons';
import { DataTestId } from 'settings/constants';
import { saveRedirectPath } from 'utils/auth';
import { useChartViewForm } from 'common/contexts/ChartViewContext';
import { useNavigate } from 'react-router-dom';
import { SEGMENTS_ROUTES } from 'settings/routes';

interface Props {
  isCreate: boolean;
  canSubmit: boolean;
  lastFilter: boolean;
  onNext?: React.MouseEventHandler<HTMLButtonElement> | void;
  nextEnabled?: boolean;
  onFilterApply: () => void;
  isChartValuesChanged: boolean;
  onFilterCancel: () => void;
  displayAddSegment: boolean;
  isShowAverageToggle: boolean;
  isAverageToggleEnabled: boolean;
  onReferenceValueToggle: () => void;
}

const FilterModalFooter: React.FC<Props> = ({
  isCreate,
  canSubmit,
  lastFilter,
  onNext,
  nextEnabled,
  onFilterApply,
  isChartValuesChanged,
  onFilterCancel,
  displayAddSegment,
  isShowAverageToggle = false,
  isAverageToggleEnabled,
  onReferenceValueToggle,
}) => {
  const { t } = useTranslation();
  const { chart_edit: isEditor } = useRecoilValue(permissionsSelector);
  const { trackEvent } = useTracking();
  const { chart } = useChartViewForm();
  const navigate = useNavigate();

  const handleAddNewSegment = React.useCallback(() => {
    trackEvent(TRACKING_EVENTS.ADD_SEGMENT);
    saveRedirectPath(window.location.pathname);
    navigate(`/segments/${SEGMENTS_ROUTES.CUSTOM}/new`, {
      state: { studyId: chart?.chart.study_uuid, prevUrl: `${window.location.pathname}${window.location.search}` },
    });
  }, [trackEvent, chart?.chart.study_uuid, navigate]);

  return (
    <ModalFooter>
      <div>
        {displayAddSegment && isEditor && (
          <AddSegmentButton onClick={handleAddNewSegment} pseudo type='button'>
            <img alt='plus-symbol' src={PlusIcon} />
            {t('common.addSegment')}
          </AddSegmentButton>
        )}
        {isShowAverageToggle && isEditor && (
          <ReferenceToggleContainer data-testid={DataTestId.TOGGLE_REFERENCE}>
            <ToggleSwitch enabled={isAverageToggleEnabled} onToggle={onReferenceValueToggle} />
            <Text color='lightPurple' variant='body2'>
              {t('filters.showReference')}
            </Text>
          </ReferenceToggleContainer>
        )}
      </div>
      {isCreate ? (
        <React.Fragment>
          {canSubmit || lastFilter ? (
            <div>
              <CancelButton onClick={onFilterCancel} pseudo color='black05' type='button'>
                {t('common.cancel')}
              </CancelButton>
              <ActionButton disabled={!canSubmit} color='lightPurple' type='submit' id={DataTestId.CHART_CREATE}>
                {t('filters.buildChart')}
              </ActionButton>
            </div>
          ) : onNext ? (
            <div>
              <CancelButton onClick={onFilterCancel} pseudo color='black05' type='button'>
                {t('common.cancel')}
              </CancelButton>
              <ActionButton
                data-testid={DataTestId.APPLY_BUTTON}
                disabled={!nextEnabled}
                color='lightPurple'
                type='button'
                onClick={onNext}
              >
                {t('common.apply')}
              </ActionButton>
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <div>
          <CancelButton onClick={onFilterCancel} pseudo color='black05' type='button'>
            {t('common.cancel')}
          </CancelButton>
          <ActionButton
            data-testid={DataTestId.APPLY_BUTTON}
            onClick={onFilterApply}
            disabled={!isEditor || !isChartValuesChanged || !nextEnabled}
            color='lightPurple'
            type='button'
          >
            {t('common.apply')}
          </ActionButton>
        </div>
      )}
    </ModalFooter>
  );
};

export default React.memo(FilterModalFooter);
