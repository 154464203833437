import styled from '@emotion/styled';
import { theme } from 'common/theme';

export const AppContainer = styled.div`
  background: ${({ theme }) => theme.colors.white.toString()};
  min-height: 100vh;
  height: 100vh;
  position: relative;
  display: flex;

  a {
    color: inherit;
    text-decoration: none;
  }

  &[data-has-alert='true'] {
    padding-top: ${({ theme }) => `${theme.spacing.padding * 6}px`};
  }
`;

export const BottomContainer = styled.div`
  position: relative;
  padding: ${theme.space(0, 3)};
  margin-left: ${theme.space(-3)};
  margin-right: ${theme.space(-3)};
  &:before {
    content: '';
    width: ${({ theme }) => theme.sidebar.width}px;
    height: ${({ theme }) => theme.space(2)};
    position: absolute;
    top: ${({ theme }) => theme.space(-2)};
    background: ${({ theme }) => `-webkit-linear-gradient(top, rgba(52, 13, 112, 0), ${theme.colors.darkPurple.toString()})`};
  }
`;

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;
  max-height: 100%;
  background: ${({ theme }) => theme.colors.white.toString()};
  padding: ${theme.space(4, 5, 3)};
  position: relative;
`;

export const ActionButtonContainer = styled.div<{ isHovered?: boolean }>`
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  transition: background-color 0.3s ease;
  padding: 4px;
  &:hover {
    background-color: ${({ theme, isHovered }) => theme.v2.colours[isHovered ? 'gray200' : 'gray0']};
  }
`;
