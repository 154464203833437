import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ChartListFilter from 'views/Charts/ChartListFilter';
import TableList from 'common/components/TableList';
import { TableListItemProps, TableRowItemProps } from 'common/types/common';
import { LatanaText } from 'common/components/LatanaText';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { RawChart } from 'common/types/chart';
import { useDeleteChart, useDuplicateChart } from 'common/hooks/charts';
import ConfirmationModal from 'common/components/Modal/ConfirmationModal';
import { generateChartFormData } from 'utils/chart';
import { isGuestSelector, permissionsSelector } from 'common/atoms/account';
import { useRecoilValue } from 'recoil';
import NoResult from 'common/components/NoResult';
import { DataTestId, ActionItems } from 'settings/constants';
import { ListItemType, TableListHeaderProps } from 'common/types/charts';
import TableListHeader from 'common/components/TableList/TableListHeader';
import TableListItem from 'common/components/TableList/TableListItem';
import ActionMenu, { ActionMenuProps } from 'common/components/ActionMenu';
import { useChartDashboardsContext } from 'common/contexts/ChartsContext';
import { useActionMenu } from 'common/hooks/useActionMenu';
import { useUserPermissions } from 'common/hooks/useUserPermissions';
import { useEffect } from 'react';
import { useTracking } from 'common/hooks/tracking';
import { SCREENS, TRACKING_EVENTS } from 'common/types/tracking';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.v2.colours.gray0};
`;

const ConfirmationTextWrapper = styled.div`
  margin: ${({ theme }) => theme.v2.space(2.75, 0)};
  text-align: center;
`;

const Charts = () => {
  const { t } = useTranslation();
  const [showDeleteModal, toggleDeleteModal] = React.useState(false);
  const [selectedChart, setSelectedChart] = React.useState<TableListItemProps | null>(null);
  const { duplicateChart, loading: duplicateActionLoader } = useDuplicateChart();
  const { deleteChart, loading: deleteActionLoader } = useDeleteChart();
  const isGuest = useRecoilValue(isGuestSelector);
  const { internal: isInternalUser } = useRecoilValue(permissionsSelector);
  const { trackEvent } = useTracking();

  const { setInitialModalState } = useChartDashboardsContext();
  const updatedActionMenu = useActionMenu(isInternalUser);

  const handleDuplicateChart = React.useCallback(
    async (chartData: TableListItemProps) => {
      const chartFormData = generateChartFormData({
        chart: { ...chartData, name: `Copy ${chartData.name}` },
      } as RawChart);
      await duplicateChart(chartFormData, chartData.study_uuid);
    },
    [duplicateChart],
  );

  const handleDeleteChart = React.useCallback(
    (chartData: TableListItemProps) => {
      setSelectedChart(chartData);
      toggleDeleteModal(true);
    },
    [toggleDeleteModal, setSelectedChart],
  );

  const handleConfirmDelete = React.useCallback(async () => {
    await deleteChart(selectedChart?.uuid as string, {
      inIndexPage: true,
      chartName: selectedChart?.name,
      chartStudyId: selectedChart?.study_uuid as string,
    });
    toggleDeleteModal(false);
  }, [selectedChart, deleteChart, toggleDeleteModal]);

  const canAddChart = useUserPermissions();

  const chartListActions = [
    { label: t('actionMenu.open', { title: 'chart' }), value: ActionItems.OPEN, icon: 'expand' },
    { label: t('actionMenu.duplicate'), value: ActionItems.DUPLICATE, icon: 'duplicate' },
    ...(canAddChart ? [{ label: t('actionMenu.addToDashboard'), value: ActionItems.ADD_TO_DASHBOARD, icon: 'apps' }] : []),
    { label: '', value: 'separator' },
    {
      label: { name: t('actionMenu.delete'), color: { active: 'red500', disabled: 'red200' } },
      value: ActionItems.DELETE,
      icon: { name: 'trash-can', color: { active: 'red400', disabled: 'red200' } },
      blurb: t('actionMenu.disabledDeleteBlurb', { title: 'Chart' }),
    },
  ] as SelectPopupItemProps[];

  React.useEffect(() => {
    setInitialModalState();
  }, [setInitialModalState]);

  useEffect(() => {
    trackEvent(TRACKING_EVENTS.VIEW_PAGE, {
      screen: SCREENS.CHARTS_INDEX,
    });
  }, [trackEvent]);

  return (
    <Container>
      <LatanaText color='gray900' variant='H2'>
        {t('views.charts')}
      </LatanaText>
      <ChartListFilter>
        {(
          chartList: Array<TableListItemProps> | undefined,
          isChartsLoading,
          onResetFilters,
          onHandleListSort,
          hasNextPage,
          isFetchingNextPage,
          fetchNextPage,
        ) => (
          <>
            <TableList
              type={ListItemType.CHARTS}
              items={chartList}
              onItemDelete={handleDeleteChart}
              isDataLoading={isChartsLoading || duplicateActionLoader}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextpage={fetchNextPage}
              onHandleListSort={onHandleListSort}
              onItemDuplicate={handleDuplicateChart}
              renderHeader={(headerProps: TableListHeaderProps) => <TableListHeader {...headerProps} />}
              renderListItem={({ listItemData, onSelect, ...otherProps }: TableRowItemProps) => (
                <TableListItem
                  {...otherProps}
                  {...listItemData}
                  renderActionMenu={
                    !isGuest
                      ? (props: ActionMenuProps) => (
                          <ActionMenu
                            {...props}
                            id={DataTestId.LIST_ACTION}
                            icon='more'
                            stopPropagation
                            items={updatedActionMenu(chartListActions, listItemData?.owner)}
                            onSelect={options => onSelect(options, listItemData)}
                          />
                        )
                      : undefined
                  }
                />
              )}
            />
            {!isChartsLoading && !chartList?.length && (
              <NoResult actions={[{ label: t('tableList.resetAllFilters'), handleClick: onResetFilters }]} />
            )}
          </>
        )}
      </ChartListFilter>
      {/* TODO: Move confirmation modal to <Layout /> */}
      <ConfirmationModal
        isLoading={deleteActionLoader}
        title={t('chart.deleteChart')}
        open={showDeleteModal}
        onCancel={() => toggleDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      >
        <ConfirmationTextWrapper>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.headingv2', { title: selectedChart?.name || '' })}
          </LatanaText>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.subheadingv2')}
          </LatanaText>
        </ConfirmationTextWrapper>
      </ConfirmationModal>
    </Container>
  );
};

export default React.memo(Charts);
