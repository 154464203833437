import { NetSentiment, RawChartData, Sentiments } from 'common/types/chart';
import { Country, Wave } from 'common/types/common';
import { DefaultValues, NON_DERIVED_KPIS, PERCENTAGE_COEF, SentimentColors } from 'settings/constants';
import { roundValue } from 'utils/chart';
import { sortChartValuesByWaveDate } from 'utils/charts';
import { chartValueInMsOrKs } from 'utils/helpers';
import { findBrandAwarenessDataByValues } from 'modules/charts/components/GrowthPerformance/getChartData';
import { getMonthsAgoCount } from 'utils/dates';

const getSentimentColorCode = (sentimentValue: number): SentimentColors => {
  const SENTIMENT_THRESHOLD = 5;

  if (sentimentValue > SENTIMENT_THRESHOLD) {
    return SentimentColors.POSITIVE;
  } else if (sentimentValue < -SENTIMENT_THRESHOLD) {
    return SentimentColors.NEGATIVE;
  } else {
    return SentimentColors.NEUTRAL;
  }
};

const createSentimentObject = (
  value: number | null,
  population: string,
  positive?: number | null,
  neutral?: number | null,
  negative?: number | null,
): NetSentiment => {
  if (value === null) return { value: null, population: undefined };

  return {
    value,
    state: getSentimentColorCode(value),
    population,
    positive,
    neutral,
    negative,
  };
};

const calcPercentageValue = (value: number | undefined | null, decimalsEnabled: boolean): number | null => {
  if (!value) return null;
  return roundValue(value * PERCENTAGE_COEF, decimalsEnabled);
};

const getSentimentDiffs = (curr: number | undefined | null, prev: number | undefined | null) => {
  if (!curr || !prev) return null;
  const diff = curr - prev;
  return calcPercentageValue(diff, true);
};

const calcNetSentiment = (wave: RawChartData) => {
  if (!wave.positive || !wave.negative) return null;
  return wave.positive - wave.negative;
};

const calcPopulationWithWeight = (value: number | undefined | null, population: number, weight: number) => {
  return chartValueInMsOrKs((value || 0) * population * weight, null);
};

export const calculateSentiments = (
  chartValues: Array<RawChartData>,
  waveMap: Record<number, Wave>,
  countryMap: Record<number, Country>,
  brandAwarenessData: RawChartData[],
): Sentiments => {
  const sortedValues = sortChartValuesByWaveDate(chartValues, waveMap);

  const currWave = sortedValues[0] ?? {};
  const prevWave = sortedValues[1] ?? {};
  const pastWave = sortedValues[sortedValues.length - 1] ?? {};

  const currToPrevDiffPositive = getSentimentDiffs(currWave?.positive, prevWave?.positive);
  const currToPrevDiffNeutral = getSentimentDiffs(currWave?.neutral, prevWave?.neutral);
  const currToPrevDiffNegative = getSentimentDiffs(currWave?.negative, prevWave?.negative);

  const prevToPastDiffPositive = getSentimentDiffs(prevWave?.positive, pastWave?.positive);
  const prevToPastDiffNeutral = getSentimentDiffs(prevWave?.neutral, pastWave?.neutral);
  const prevToPastDiffNegative = getSentimentDiffs(prevWave?.negative, pastWave?.negative);

  const currWaveAwareness = findBrandAwarenessDataByValues(currWave, brandAwarenessData);
  const currWaveDerivedFromBa = currWaveAwareness && !NON_DERIVED_KPIS.includes(currWave.kpi);

  const currWavePopulation = countryMap?.[currWave.country]?.population || 0;
  const currWaveWeight = currWave?.segment_population_weight || 1;
  const prevWavePopulation = countryMap?.[prevWave.country]?.population || 0;
  const prevWaveWeight = prevWave?.segment_population_weight || 1;
  const pastWavePopulation = countryMap?.[pastWave.country]?.population || 0;
  const pastWaveWeight = pastWave?.segment_population_weight || 1;

  const currentNetSentiment = calcNetSentiment(currWave);
  const prevMonthSentiment = sortedValues.length > 1 ? calcNetSentiment(prevWave) : null;
  const pastMonthSentiment = sortedValues.length > 2 ? calcNetSentiment(pastWave) : null;

  const prevMonthSentimentChange = getSentimentDiffs(currentNetSentiment, prevMonthSentiment);
  const pastMonthSentimentChange = getSentimentDiffs(currentNetSentiment, pastMonthSentiment);

  return {
    positive: createSentimentObject(
      calcPercentageValue(currWave.positive, true),
      calcPopulationWithWeight(
        currWaveDerivedFromBa ? (currWave.positive || 0) * (currWaveAwareness?.mean || 0) : currWave.positive,
        currWavePopulation,
        currWaveWeight,
      ),
    ),
    neutral: createSentimentObject(
      calcPercentageValue(currWave.neutral, true),
      calcPopulationWithWeight(
        currWaveDerivedFromBa ? (currWave.neutral || 0) * (currWaveAwareness?.mean || 0) : currWave.neutral,
        currWavePopulation,
        currWaveWeight,
      ),
    ),
    negative: createSentimentObject(
      calcPercentageValue(currWave.negative, true),
      calcPopulationWithWeight(
        currWaveDerivedFromBa ? (currWave.negative || 0) * (currWaveAwareness?.mean || 0) : currWave.negative,
        currWavePopulation,
        currWaveWeight,
      ),
    ),
    netSentiment: createSentimentObject(
      calcPercentageValue(currentNetSentiment, true),
      calcPopulationWithWeight(currentNetSentiment, currWavePopulation, currWaveWeight),
    ),
    from1MonthAgo: createSentimentObject(
      prevMonthSentimentChange,
      calcPopulationWithWeight(prevMonthSentimentChange, prevWavePopulation, prevWaveWeight),
      currToPrevDiffPositive,
      currToPrevDiffNeutral,
      currToPrevDiffNegative,
    ),
    fromNMonthAgo: createSentimentObject(
      pastMonthSentimentChange,
      calcPopulationWithWeight(pastMonthSentimentChange, pastWavePopulation, pastWaveWeight),
      prevToPastDiffPositive,
      prevToPastDiffNeutral,
      prevToPastDiffNegative,
    ),
  };
};

interface Headers {
  title: string;
  key: string;
  tooltip: string;
}

export const getChartHeaders = (
  chartData: RawChartData[] | undefined,
  waveMap: Record<number, Wave>,
  isShowPopNumbers: boolean,
): Array<Headers> => {
  if (!chartData) return [];

  const createHeader = (title: string, key: string, tooltip: string) => ({ title, key, tooltip });

  const headerDetails = [
    { title: 'Positive', key: 'positive', tooltip: 'Positive sentiment' },
    { title: 'Neutral', key: 'neutral', tooltip: 'Neutral sentiment' },
    { title: 'Negative', key: 'negative', tooltip: 'Negative sentiment' },
    {
      title: 'Net sentiment',
      key: 'netSentiment',
      tooltip: 'Overall perception towards the brand, calculated as the difference between positive and negative sentiment',
    },
    { title: 'From 1 month ago', key: 'from1MonthAgo', tooltip: 'From 1 month ago sentiment' },
    {
      title: `From ${getMonthsAgoCount(waveMap, sortChartValuesByWaveDate(chartData, waveMap))} months ago`,
      key: 'fromNMonthAgo',
      tooltip: 'From N month ago sentiment',
    },
  ];

  // Remove the first 3 headers if percentage numbers are shown
  const headers = isShowPopNumbers ? headerDetails : headerDetails.slice(3);
  return headers.map(({ title, key, tooltip }) => createHeader(title, key, tooltip));
};

export const parseSentiment = ({ value, population }: NetSentiment, showPopulationNumber: boolean): string => {
  if (value === null || value === undefined) return DefaultValues.UPCOMING;
  if (showPopulationNumber) return population ?? DefaultValues.ZERO;
  if (value === 0) return DefaultValues.ZERO_PERCENT;

  return `${value?.toFixed(1)}%`;
};
