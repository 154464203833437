import { theme } from 'common/theme';
import { AverageBrand } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';

export const UNDEFINED_TYPE = 'undefined';

export const DEFAULT_DASHBOARD = 0;
export const MIN_REQ_WAVE_MSG = 'wave field must have at least 2 items';

export const PopoverMenuAction = {
  Rename: 'Rename',
  Delete: 'Delete',
};

export const MIN_THRESHOLD = {
  StudyModal: 10, // Show study modal select if there is more than 10 studies and selected study exists.
  StudyList: 1, // Do not show study list if number of studies is less than 2. [CX-581]
  Wave: 2, // Minimum number of waves required to continue or build chart.
};

export const MIN_REQ_WAVE = 2; // Minimum number of waves required to continue or build chart

export const MIN_ITEMS_AMOUNT = 3; // Minimum number of second dimensions needed to build Radar chart.

export enum FilterTabs {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export const enum Identifier {
  KPI = 'kpi_identifier',
  OPTION = 'option_identifier',
  ID = 'id',
}

export const FILTER_NAMES = [FilterNames.WAVE, FilterNames.BRAND, FilterNames.KPI, FilterNames.COUNTRY, FilterNames.SEGMENT];

export const ChartDetails = {
  DESCRIPTION: 'description',
  FIRST_DIMENSION: 'firstDimension',
  SECOND_DIMENSION: 'secondDimension',
  CHART_TYPE: 'chart_type',
  UUID: 'uuid',
  CURRENT_VIEW: 'current_view',
  DISPLAY_REFERENCE_AVERAGE: 'display_reference_average',
  DISPLAY_ADDITIONAL_BRAND_AWARENESS: 'display_additional_brand_awareness',
  DYNAMIC_WAVES: 'dynamic_waves',
  NAME: 'name',
  WAVE: 'wave',
  BRAND: 'brand',
  KPI: 'kpi',
  SEGMENT: 'segment',
  COUNTRY: 'country',
  STUDY_UUID: 'study_uuid',
};

export const RADAR_MINMAX_ANGLE = -90;

export enum FilterLabels {
  Geography = 'Geography',
  TimePeriod = 'Time Period',
  KPI = 'KPI',
  Brand = 'Brand',
  Segment = 'Segment',
}

export const NO_BRAND = 'No Brand';
export const AVERAGE: AverageBrand = 'Average';
export const AVERAGE_VALUE = 0;
export const GEN_POP = 'general population';

export const DEFAULT_ROWS = 6;
export const COLUMN_GAP = 32;
export const COLUMN_WIDTH = 240;
export const DEFAULT_CONTAINER_WIDTH = 1064;

export const GAUGE_COLORS = [theme.colors.portage.toString(), theme.colors.atomicTangerine.toString(), theme.colors.ocean.toString()];

export const SEGMENT_BUILDER_HASH = '#segment_builder';
export const STUDY_SELECTOR_HASH = '#study_selector';
export const CREATE_CHART_HASH = '#create_chart';
export const CREATE_SEGMENT_HASH = '#create_segment';

export enum DynamicWaves {
  SELECT_ALL_BUTTON = 'SELECT_ALL_BUTTON',
  MOST_RECENT = 'MOST_RECENT',
}

export enum DataTestId {
  SAVE_BUTTON = 'SAVE_BUTTON',
  APPLY_BUTTON = 'APPLY_BUTTON',
  CREATE_NEW_BUTTON = 'CREATE_NEW_BUTTON',
  MODAL_GEOGRAPHY = 'FILTER_MODAL_GEOGRAPHY',
  MODAL_TIME_PERIOD = 'FILTER_MODAL_TIME_PERIOD',
  MODAL_KPI = 'FILTER_MODAL_KPI',
  MODAL_BRAND = 'FILTER_MODAL_BRAND',
  MODAL_SEGMENT = 'FILTER_MODAL_SEGMENT',
  MODAL_INNER = 'MODAL_INNER',
  FILTER_GEOGRAPHY = 'FILTER_GEOGRAPHY',
  FILTER_TIME_PERIOD = 'FILTER_TIME_PERIOD',
  FILTER_KPI = 'FILTER_KPI',
  FILTER_BRAND = 'FILTER_BRAND',
  FILTER_SEGMENT = 'FILTER_SEGMENT',
  TOGGLE_REFERENCE = 'TOGGLE_REFERENCE',
  CHART_CREATE = 'CHART_CREATE',
  ADD_CHART = 'ADD_CHART',
  ADD_CHART_ICON = 'ADD_CHART_ICON',
  COMPARISON_CHART = 'COMPARISON_CHART',
  ASSOCIATIONS_CHART = 'ASSOCIATIONS_CHART',
  SCORE_CARD_CHART = 'SCORE_CARD_CHART',
  MARKET_SIZE_CHART = 'MARKET_SIZE_CHART',
  HEATMAP_CHART = 'HEATMAP_CHART',
  FUNNEL_CHART = 'FUNNEL_CHART',
  CHANGE_OVER_TIME_CHART = 'CHANGE_OVER_TIME_CHART',
  FOLDER = 'FOLDER',
  GROWTH_PERFORMANCE = 'GROWTH_PERFORMANCE',
  GP_TABLE = 'GP_TABLE',
  GP_TABLE_HEADER = 'GP_TABLE_HEADER',
  RANKING = 'RANKING',
  RANKING_TABLE_HEADER = 'RANKING_TABLE_HEADER',
  CREATE_FOLDER_ICON = 'CREATE_FOLDER_ICON',
  DATA_TABLE = 'DATA_TABLE',
  CHART_HEADER_ACTION = 'CHART_HEADER_ACTION',
  SIDEBAR = 'SIDEBAR',
  SIDEBAR_V2 = 'SIDEBAR_V2',
  SIDEBAR_STUDIES = 'SIDEBAR_STUDIES',
  LIST_ITEM = 'LIST_ITEM',
  LIST_ACTION = 'LIST_ACTION',
  EMPTY_STATE = 'EMPTY_STATE',
  DASHBOARDS_LIST = 'DASHBOARDS_LIST',
  SEGMENT_SIDEBAR = 'SEGMENT_SIDEBAR',
  CHANGE_VIEW_MENU = 'CHANGE_VIEW_MENU',
  SEGMENT_NAME = 'SEGMENT_NAME',
  HOME_PAGE = 'HOME_PAGE',
  CHARTS_PAGE = 'CHARTS_PAGE',
  DASHBOARDS_PAGE = 'DASHBOARDS_PAGE',
  SEGMENTS_PAGE = 'SEGMENTS_PAGE',
  SETTINGS_PAGE = 'SETTINGS_PAGE',
  CREATE_NEW_POPUP = 'CREATE_NEW_POPUP',
  BAR_CHART = 'BAR_CHART',
  LINE_CHART = 'LINE_CHART',
  RANKING_TABLE = 'RANKING_TABLE',
  FILTERS_CONTAINER = 'FILTERS_CONTAINER',
  CHART_SORT_MENU = 'CHART_SORT_MENU',
  CHART_GROUPBY_MENU = 'CHART_GROUPBY_MENU',
  ADMIN_BUTTON = 'ADMIN_BUTTON',
  WAVE_REPORTS = 'WAVE_REPORTS',
  CHART_NAME_CONTAINER = 'CHART_NAME_CONTAINER',
  CHART_HEADER = 'CHART_HEADER',
  DASHBOARD_HEADER_ADD_CHART = 'DASHBOARD_HEADER_ADD_CHART',
  DASHBOARD_EDIT_SAVE_BUTTON = 'DASHBOARD_EDIT_SAVE_BUTTON',
  NO_CONTENT_ACTION_BUTTON = 'NO_CONTENT_ACTION_BUTTON',
  BRAND_PERCEPTION_TABLE = 'BRAND_PERCEPTION_TABLE',
  MARKET_FUNNEL_TABLE = 'MARKET_FUNNEL_TABLE',
  MARKET_FUNNEL_CHART = 'MARKET_FUNNEL_CHART',
  SELECT_ALL_OPTIONS = 'SELECT_ALL_OPTIONS',
  CHART_NUMBERS_CHANGE = 'CHART_NUMBERS_CHANGE',
  SEGMENT_DUPLICATION_MODAL = 'SEGMENT_DUPLICATION_MODAL',
  SEGMENT_SIZE_COLUMN = 'SEGMENT_SIZE_COLUMN',
  RANKING_TABLE_HEADER_CELL = 'RANKING_TABLE_HEADER_CELL',
  CUSTOM_SEGMENTS_TAB = 'CUSTOM_SEGMENTS_TAB',
  STUDY_DROPDOWN = 'STUDY_DROPDOWN',
  ADD_SEGMENT_BUTTON = 'ADD_SEGMENT_BUTTON',
  EDIT_SEGMENT_BUTTON = 'EDIT_SEGMENT_BUTTON',
  DELETE_SEGMENT_BUTTON = 'DELETE_SEGMENT_BUTTON',
  SAVE_SEGMENT_BUTTON = 'SAVE_SEGMENT_BUTTON',
  SEGMENT_INFO_HOVER = 'SEGMENT_INFO_HOVER',
  TOAST_MESSAGE = 'TOAST_MESSAGE',
}

export const POPULATION_COEF = 1000000;
export const PERCENTAGE_COEF = 100;
export const INNER_PADDING = 4;
export const MODAL_LIST_HEIGHT = 444;

export const GUEST = 'guest';

export enum ActionItems {
  OPEN = 'open',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
  RENAME = 'rename',
  CHANGE_DESCRIPTION = 'changeDescription',
  ADD_DESCRIPTION = 'addDescription',
  CREATE_CHART_IMAGE = 'createChartImage',
  DOWNLOAD_CSV = 'downloadCSV',
  SEPARATOR = 'separator',
  ADD_TO_DASHBOARD = 'addToDashboard',
}

export const NEW = 'new';
export const CREATE_NEW = 'create_new';
export const LATANA_NAME = 'latana';

export enum SentimentColors {
  POSITIVE = 'green700',
  NEUTRAL = 'gray200',
  NEGATIVE = 'red600',
}

export const AIDED_BRAND_AWARENESS = 'aided-brand-awareness';
export const PURCHASE_DRIVERS = 'purchase-drivers';
export const UNAIDED_BRAND_AWARENESS = 'unaided-brand-awareness';
export const BRAND_CONSIDERATION = 'brand-consideration';
export const BRAND_PERCEPTION_KPI = 'brand-perception';
export const MARKET_SIZE_KPI = 'market-sizing';
export const BRAND_PREFERENCE = 'brand-preference';
export const BRAND_USAGE = 'brand-usage';
export const BRAND_PERCEPTION_BAD = 'brand-perception_bad';
export const BRAND_PERCEPTION_GOOD = 'brand-perception_good';

export const MARKET_FUNNEL_KPIS = [AIDED_BRAND_AWARENESS, BRAND_CONSIDERATION];

export const FUNNEL_DEFAULT_KPI = [UNAIDED_BRAND_AWARENESS, AIDED_BRAND_AWARENESS, BRAND_CONSIDERATION, BRAND_PREFERENCE, BRAND_USAGE];

// KPIs that are not derived from Brand Awareness
export const NON_DERIVED_KPIS = [AIDED_BRAND_AWARENESS, PURCHASE_DRIVERS, MARKET_SIZE_KPI];

export enum DefaultValues {
  UPCOMING = 'Upcoming',
  NA = 'N/A',
  ZERO = '~0',
  ZERO_PERCENT = '~0%',
}

export const BRAND_PERCEPTION_KPI_LABEL = 'Brand perception';
export const SORT_KEY_SEGMENT_SIZE = 'segmentSize';
export const MATRIX_CHART_DEFAULT_WAVES = 6;
export const DYNAMIC_WAVES_DEFAULT = 5;
