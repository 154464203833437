import { RawChartData } from 'common/types/chart';
import { Wave } from 'common/types/common';

export type DisplayDateFormat = 'MMM/YY' | 'DD.MM.YYYY' | 'MMMM YYYY' | 'YYYY-MM-DD' | 'MMMM';

const locale = 'en-US';

const padNumber = (number: number, length: number): string => number.toString().padStart(length, '0');

export const displayDate = (date: Date | string, format: DisplayDateFormat): string => {
  if (!(date instanceof Date)) return date;
  date = new Date(date);

  switch (format) {
    case 'MMMM YYYY': {
      return `${date.toLocaleString('default', { month: 'long' })} ${date.getUTCFullYear()}`;
    }
    case 'MMM/YY': {
      const month = date.toLocaleDateString(locale, {
        month: 'short',
      });

      return `${month}/${date.getFullYear().toString().slice(-2)}`;
    }
    case 'DD.MM.YYYY': {
      return date.toLocaleString(locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
    }
    case 'YYYY-MM-DD': {
      return `${date.getFullYear()}-${padNumber(date.getMonth() + 1, 2)}-${padNumber(date.getDate(), 2)}`;
    }
    case 'MMMM': {
      return date.toLocaleString(locale, {
        month: 'long',
      });
    }
  }
};

export const dateWithoutTimezone = (date: Date): Date => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const getLatestWaveDate = (waveIds: number[] | undefined, wavesMap: Record<number, Wave>): Date | undefined => {
  if (!waveIds || !wavesMap) return undefined;

  let latestDate: Date | undefined = undefined;

  for (const id of waveIds) {
    const wave = wavesMap[id];
    if (wave) {
      if (!latestDate || new Date(wave.date) > new Date(latestDate)) {
        latestDate = wave.date;
      }
    }
  }

  return latestDate;
};

export const extractWavesDates = (chartValues: RawChartData[], waveMap: Record<number, Wave>) => {
  const latestWaveId = chartValues[0]?.wave;
  const latestWaveDate = new Date(waveMap[latestWaveId]?.date);

  const pastWaveId = chartValues[chartValues.length - 1]?.wave;
  const pastWaveDate = new Date(waveMap[pastWaveId]?.date);

  return [latestWaveDate, pastWaveDate];
};

export const getMonthDifference = (startDate: Date, endDate: Date): number => {
  // Ensure startDate is before endDate
  if (startDate > endDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months += endDate.getMonth() - startDate.getMonth();

  // Adjust if endDate's day is less than startDate's day
  if (endDate.getDate() < startDate.getDate()) {
    months--;
  }

  return months;
};

export const getMonthsAgoCount = (waveMap: Record<number, Wave>, sortedWaveList: RawChartData[]): number => {
  const [latestWaveDate, pastWaveDate] = extractWavesDates(sortedWaveList, waveMap);
  const monthsGap = getMonthDifference(latestWaveDate, pastWaveDate);

  // If latest wave is within the last 2 months, show "2 months ago"
  if (monthsGap <= 1) {
    return 2;
  }

  // Otherwise, return the number of months between the latest and past waves
  return monthsGap;
};
