import styled from '@emotion/styled';
import { theme } from 'common/theme';
import PopoverMenu from 'common/components/PopoverMenu';
import GrowingTextField from 'common/components/Form/GrowingTextField';
import { CardDescription } from 'modules/dashboards/styledComponents/dashboard';

const MenuContainer = styled.div`
  padding-top: ${theme.space(1)};
`;

const FilterContainer = styled.ul`
  list-style-type: none;
  display: flex;
  padding: initial;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: ${({ theme }) => theme.space(1.5, 0, 5)};
  color: ${({ theme }) => theme.colors.black.toString()};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  padding: ${theme.space(0, 5)};
  margin: ${theme.space(0, -5, 0)};
`;

const ChartHeader = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  margin-left: ${theme.space(-1.5)};
  button {
    margin-left: ${({ theme }) => theme.spacing.padding * 2}px;
  }
`;

const ChartHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const ChartNameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DropdownMenu = styled(PopoverMenu)``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${theme.space(0, 5)};
  margin: ${theme.space(0, -5, -5)};
  min-height: 0;
  transition: 0.4s min-height ease-in;
  &[aria-expanded='true'] {
    min-height: calc(100vh - ${theme.space(5)});
    transition: 0.4s min-height ease-in;
    height: calc(100% + ${theme.space(5)});
    max-height: calc(100% + ${theme.space(5)});
  }
`;

const ButtonContainer = styled.div`
  white-space: nowrap;
`;

const NameInput = styled(GrowingTextField)`
  height: 4.8rem;
  line-height: 3.2rem;
  font-size: ${({ theme }) => theme.text.variants.h4.fontSize};
`;

const DescriptionInput = styled.textarea`
  font-family: ${({ theme }) => theme.text.fontFamily};
  padding: ${theme.space(0, 1.5)};
  font-size: ${({ theme }) => theme.text.variants.subheading2.fontSize};
  color: ${({ theme }) => theme.colors.liquoriceGrey.toString()};
  line-height: ${({ theme }) => theme.text.lineHeight};
  border: none;
  font-weight: 500;
  resize: none;
  overflow: hidden;
  opacity: 0;
`;

const DescriptionOutputText = styled.div`
  font-family: ${({ theme }) => theme.text.fontFamily};
  padding: ${theme.space(0, 1.5)};
  font-size: ${({ theme }) => theme.text.variants.subheading2.fontSize};
  color: ${({ theme }) => theme.colors.liquoriceGrey.toString()};
  line-height: ${({ theme }) => theme.text.lineHeight};
  border: none;
  font-weight: 500;
  resize: none;
  overflow: hidden;
  position: absolute;
  left: 0;
  margin-top: ${({ theme }) => theme.v2.space(-2)};
`;

const Description = styled(CardDescription)`
  margin-top: ${theme.space(0.5)};
`;

export {
  FilterContainer,
  Container,
  ChartHeader,
  ChartHeaderContainer,
  ChartNameContainer,
  ButtonContainer,
  DropdownMenu,
  Form,
  NameInput,
  DescriptionInput,
  Description,
  MenuContainer,
  DescriptionOutputText,
};
