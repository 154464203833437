import styled from '@emotion/styled';
import { CheckmarkIconWhite } from 'icons';

/** SELECT CONTAINER STYLES */
const SelectPopupContainer = styled.div`
  display: flex;
  min-width: 240px;
  max-height: 500px;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space(1, 0.5, 1, 0.5)};
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.v2.colours.gray200};
  background: ${({ theme }) => theme.v2.colours.gray0};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  z-index: 99;
`;

const SelectItemSeparator = styled.div`
  height: 1px;
  margin: 4px 0;
  width: 100%;
  background-color: ${({ theme }) => theme.v2.colours.gray100};
`;

/** SELECT ITEM STYLES */
const SelectItemContainer = styled.div<{ isLastItem?: boolean }>`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  margin-bottom: ${({ theme, isLastItem }) => theme.space(isLastItem ? 0 : 0.5)};
  align-items: center;
  padding: ${({ theme }) => theme.space(0.5, 1, 0.5, 1)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.v2.colours.gray100};
  }

  &[data-is-disabled='true'] {
    cursor: default;
    opacity: 0.7;
  }
`;

const CheckboxInput = styled.input<{ checked?: boolean }>`
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 2px;

  border: 1px solid ${({ theme }) => theme.v2.colours.gray500};
  outline: none;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.v2.space(1)};

  &:checked {
    transition: background 0.2s ease-in-out;
    background: ${({ theme }) => theme.v2.colours.latana};
    color: ${({ theme }) => theme.v2.colours.gray0};
    border: none;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(${CheckmarkIconWhite});
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.v2.space(1)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  place-content: center;
`;

export { SelectPopupContainer, SelectItemContainer, CheckboxInput, IconWrapper, SelectItemSeparator, LoadingWrapper };
