import { selector } from 'recoil';
import { accountDetailsSelector } from 'common/atoms/account';

export const kpiFormulasSelector = selector({
  key: 'accountDetails.KPIFormulas',
  get: ({ get }) => {
    const {
      preferences: { kpis },
    } = get(accountDetailsSelector);

    return kpis;
  },
});
