import { SelectedDashboards } from 'common/contexts/ChartsContext';
import { ChartType } from 'common/types/chart';
import { Owner } from 'common/types/common';

export type UseChartInDashboard = (modalState: SelectedDashboards[], dashboardId: string, chartId: string) => boolean;

export enum CardTypes {
  CHART = 'chart',
  TEXT = 'text',
}

export interface CardBodyItem {
  type: string;
  uuid?: string;
  text?: string;
}

export interface CardHeaderItem {
  type: string;
  text: string;
  action: VoidFunction | null;
}

export interface CardComponent {
  header: CardHeaderItem[];
  body: CardBodyItem[];
  footer: CardHeaderItem[];
}

export interface CardGridPosition {
  width?: number;
  x?: number;
  y?: number;
  height?: number;
  minWidth?: number;
}
export interface Card {
  uuid?: string;
  sort_order: number;
  display_size: string | null;
  components: CardComponent;
  grid_position: CardGridPosition | null;
}

export interface DashboardChart {
  name: string;
  uuid: string;
  chart_type: ChartType;
  sort_order: number;
  display_size: string | null;
}

export interface DashboardDetails {
  name: string;
  uuid: string;
  updated_at: string;
  owner?: Owner;
  cards: Card[];
}

export interface DashboardPayload {
  name: string;
  description: string;
  cards: { sort_order: number; display_size: string; type: string; content_uuid: string; card_uuid?: string }[];
}
