import * as React from 'react';
import ModalV2 from 'common/components/Modal/ModalV2';
import { LoadingButton } from 'common/components/Form/Button';
import { useTranslation } from 'react-i18next';
import { DashboardChartsModalContent, DashboardChartsModalFooter } from 'common/components/Modal/styled';
import { DashboardMutationFnOptions, useUpdateDashboard } from 'common/queries/dashboards';
import { SelectedDashboards, useChartDashboardsContext } from 'common/contexts/ChartsContext';

interface DashboardsModalProps {
  isOpen: boolean;
  toggleDashboardModal: React.Dispatch<React.SetStateAction<boolean>>;
  renderContent: JSX.Element;
}

const DashboardsModal: React.FC<DashboardsModalProps> = ({ isOpen, toggleDashboardModal, renderContent }) => {
  const { t } = useTranslation();
  const { mutate: updateDashboard } = useUpdateDashboard();
  const { chartsModalState, changedDashboardsId, setInitialModalState } = useChartDashboardsContext();

  const componseDashboardsPayload = React.useCallback(
    (dashboard: SelectedDashboards) => ({
      payload: {
        name: dashboard?.name || '',
        description: dashboard?.description || '',
        cards: (dashboard?.cards || []).map(card => ({
          card_uuid: card.uuid || undefined,
          grid_position: card.grid_position || {},
          sort_order: card.sort_order,
          display_size: card.display_size || 'M',
          content_uuid: card.components.body[0]?.uuid || '',
          type: card.components.body[0]?.type,
        })),
      },
      options: {
        toast: { message: `Dashboard ${name} has been saved successfully.`, status: 'success' },
        shouldNavigate: true,
      } as DashboardMutationFnOptions,
    }),
    [],
  );

  const onHandleClose = React.useCallback(() => {
    setInitialModalState();
    toggleDashboardModal(false);
  }, [setInitialModalState, toggleDashboardModal]);

  const onHandleUpdateDashboard = React.useCallback(() => {
    const changedDashboards = chartsModalState?.filter(({ uuid }) => changedDashboardsId?.includes(uuid));

    changedDashboards?.forEach(dashboard => {
      updateDashboard({
        ...componseDashboardsPayload(dashboard),
        dashboardId: dashboard.uuid,
      });
    });

    onHandleClose();
  }, [chartsModalState, changedDashboardsId, componseDashboardsPayload, updateDashboard, onHandleClose]);

  return (
    <ModalV2 isOpen={isOpen} modalId='dashboards-modal' onClose={onHandleClose} title={'Select your dashboard'} customWidth={800}>
      <DashboardChartsModalContent>{renderContent}</DashboardChartsModalContent>
      <DashboardChartsModalFooter>
        <LoadingButton disabled={false} isButtonV2={true} type='button' color='indigo50' onClick={onHandleClose}>
          {t('dashboard.cancelChart')}
        </LoadingButton>
        <LoadingButton
          disabled={false}
          isButtonV2={true}
          loading={false}
          type='button'
          color='lightPurple'
          onClick={onHandleUpdateDashboard}
        >
          {t('dashboard.addChart')}
        </LoadingButton>
      </DashboardChartsModalFooter>
    </ModalV2>
  );
};

export default DashboardsModal;
