import { useMemo } from 'react';
import { KPI } from 'common/types/common';
import { BRAND_PERCEPTION_KPI, FUNNEL_DEFAULT_KPI, MARKET_FUNNEL_KPIS, MARKET_SIZE_KPI } from 'settings/constants';
import { RADAR_CHART_KPIS } from 'common/atoms/constants';
import { ChartType } from 'common/types/chart';

export const useVisibleKPIs = (chartType: ChartType, kpis: KPI[]): Array<KPI> => {
  return useMemo(() => {
    switch (chartType) {
      case ChartType.RADAR:
        return kpis.filter(kpi => [RADAR_CHART_KPIS.BRAND_ASSOCIATIONS, RADAR_CHART_KPIS.PURCHASE_DRIVER].includes(kpi.kpi_identifier));
      case ChartType.FUNNEL:
        return kpis.filter(kpi => FUNNEL_DEFAULT_KPI.includes(kpi.kpi_identifier));
      case ChartType.BRAND_PERCEPTION:
        return kpis.filter(kpi => kpi.kpi_identifier === BRAND_PERCEPTION_KPI);
      case ChartType.MARKET_FUNNEL:
        return kpis.filter(kpi => MARKET_FUNNEL_KPIS.includes(kpi.kpi_identifier));
      case ChartType.MATRIX:
        return kpis.filter(kpi => kpi.kpi_identifier !== MARKET_SIZE_KPI);
      default:
        return kpis;
    }
  }, [chartType, kpis]);
};
