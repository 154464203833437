import { useStudyContext } from 'common/contexts/StudyContext';
import { useTracking } from 'common/hooks/tracking';
import React, { useCallback, useMemo } from 'react';
import { CREATE_SEGMENT_HASH, DataTestId, GUEST } from 'settings/constants';
import { MAIN_ROUTES } from 'settings/routes';
import Icon from 'common/components/Icon';
import AddButton from 'common/components/SidebarNav/AddButton';
import BottomNav from 'common/components/SidebarNav/BottomNav';
import { IconButtonContainer, SidebarContainer, TopNavContainer, Logo, TopActionsGroupContainer } from './styled';
import { NavButtonProps } from 'common/types/common';
import { useTranslation } from 'react-i18next';
import { accountDetailsSelector, permissionsSelector, roleSelector } from 'common/atoms/account';
import { useRecoilValue } from 'recoil';
import { useDashboardContext } from 'common/contexts/DashboardContext';
import { saveRedirectPath } from 'utils/auth';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { useLocation, useNavigate } from 'react-router-dom';

const SidebarNav = () => {
  const { pathname } = useLocation();
  const { trackEvent } = useTracking();
  const { toggleStudySelectionModal } = useStudyContext();
  const { onAddNewDashboard } = useDashboardContext();
  const { t } = useTranslation();
  const { name: roleName } = useRecoilValue(roleSelector);
  const { user } = useRecoilValue(accountDetailsSelector);
  const { internal: isInternalUser } = useRecoilValue(permissionsSelector);
  const navigate = useNavigate();

  const isNavButtonActive = React.useCallback(
    (path?: string) => {
      return path && pathname.includes(path);
    },
    [pathname],
  );

  const handleTrackEvent = useCallback(
    (eventName: string, screen?: string) => {
      trackEvent(eventName, {
        internal: isInternalUser,
        account: user.name,
        userId: user.uuid,
        screen,
      });
    },
    [isInternalUser, user, trackEvent],
  );

  const topNavItems: NavButtonProps[] = useMemo(
    () => [
      {
        icon: 'home',
        label: t('nav.home'),
        disabled: true,
        testId: DataTestId.HOME_PAGE,
      },
      {
        icon: 'apps',
        label: t('nav.dashboards'),
        action: () => {
          navigate(MAIN_ROUTES.DASHBOARDS);
        },
        url: MAIN_ROUTES.DASHBOARDS,
        testId: DataTestId.DASHBOARDS_PAGE,
      },
      {
        icon: 'column-chart',
        label: t('nav.charts'),
        action: () => {
          navigate(MAIN_ROUTES.CHARTS);
        },
        url: MAIN_ROUTES.CHARTS,
        testId: DataTestId.CHARTS_PAGE,
      },
      {
        icon: 'user-group',
        label: t('nav.segments'),
        action: () => {
          navigate(`/segments/latana`);
        },
        testId: DataTestId.SEGMENTS_PAGE,
      },
    ],
    [t, navigate],
  );

  enum NavOptions {
    CHART = 'chart',
    DASHBOARD = 'dashboard',
    SEGMENT = 'segment',
  }

  const addButtonSelectHandle = useCallback(
    selectedOption => {
      // Save previous url path in case user performs some action that changes the url
      saveRedirectPath(window.location.pathname);
      handleTrackEvent(`${TRACKING_EVENTS.NAV_BAR_ADD_ITEM_CLICK}${selectedOption[0]}`);
      switch (selectedOption[0]) {
        case NavOptions.CHART:
          toggleStudySelectionModal(true);
          break;
        case NavOptions.DASHBOARD:
          onAddNewDashboard();
          break;
        case NavOptions.SEGMENT:
          toggleStudySelectionModal(true, CREATE_SEGMENT_HASH);
          break;
        default:
          break;
      }
    },
    [toggleStudySelectionModal, onAddNewDashboard, NavOptions, handleTrackEvent],
  );

  const renderTopNavigation = useCallback(() => {
    return (
      <TopNavContainer>
        <Logo src='/logo.svg' alt='Logo' />
        <TopActionsGroupContainer>
          {roleName.toLowerCase() !== GUEST && <AddButton onSelect={addButtonSelectHandle} />}
          {topNavItems.map(
            item =>
              !item.disabled && (
                <IconButtonContainer
                  key={item.label}
                  data-active={isNavButtonActive(item?.url)}
                  data-hover={item.label}
                  onClick={item?.action}
                  data-testid={item.testId}
                >
                  <Icon icon={item.icon} color='gray600' size={20} />
                </IconButtonContainer>
              ),
          )}
        </TopActionsGroupContainer>
      </TopNavContainer>
    );
  }, [addButtonSelectHandle, isNavButtonActive, topNavItems, roleName]);

  return (
    <SidebarContainer data-testid={DataTestId.SIDEBAR_V2}>
      {renderTopNavigation()}
      <BottomNav />
    </SidebarContainer>
  );
};

export default React.memo(SidebarNav);
