import React, { FC, useMemo } from 'react';
import Modal from 'common/components/Modal';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/styledComponents/typography';
import { useRecoilValue } from 'recoil';
import { AllFiltersData, FilterNames } from 'common/types/filters';
import { getNameById } from 'modules/charts/utils/chartData';
import { MoeConfidenceLevelsAtom } from 'common/atoms/moe';
import { calculateMoeConfidence } from 'utils/moe';
import { displayDate } from 'utils/dates';
import { FilterLabels } from 'settings/constants';
import { useSegments } from 'common/hooks/segments';
import { uiPreferencesSelector } from 'common/atoms/account';
import { roundValue } from 'utils/chart';
import { MoeModalDataProps } from 'common/types/common';
import {
  ModalContainer,
  TopContainer,
  CertanityBox,
  MoeValueBox,
  MoeValuesBox,
  MoeIndicatorBox,
  CertainityText,
  BottomContainer,
  BottomFieldsWrapper,
  FieldItem,
  ValueItem,
  Title,
} from './styled';
import { useGetStudySpecificRestrictions } from 'common/hooks/filters';
import { useAllKpisMap } from 'common/hooks/kpis';
import { useChartViewForm } from 'common/contexts/ChartViewContext';
import { chartValueInMsOrKs } from 'utils/helpers';

interface ModalProps {
  showPanel: boolean;
  handleClose: () => void;
  moeModalData: MoeModalDataProps | null;
  studyId: string;
  showPopulationNumbers: boolean;
}

const MoeModal: FC<ModalProps> = ({ showPanel, handleClose, moeModalData, studyId, showPopulationNumbers }) => {
  const { t } = useTranslation();
  const { chart } = useChartViewForm();

  const { getSegmentsByStudyId } = useSegments();
  const kpisMap = useAllKpisMap(studyId);
  const allFilters = useGetStudySpecificRestrictions(studyId);
  const moeConfidenceLevels = useRecoilValue(MoeConfidenceLevelsAtom);
  const { decimalsEnabled } = useRecoilValue(uiPreferencesSelector);

  const segments = useMemo(() => {
    if (!studyId) {
      return [];
    }

    return getSegmentsByStudyId(studyId);
  }, [studyId, getSegmentsByStudyId]);

  const filtersData = React.useMemo(() => {
    return {
      ...allFilters,
      kpis: kpisMap || {},
      segments: segments || [],
    } as AllFiltersData;
  }, [allFilters, kpisMap, segments]);

  const chartDataItem = React.useMemo(() => {
    const { firstDimensionId, secondDimensionId } = moeModalData || {};
    const firstDimension = chart?.chart.configuration.first_dimension;
    const secondDimension = chart?.chart.configuration.second_dimension;

    if (!firstDimension || !secondDimension || !firstDimensionId || !secondDimensionId) return;

    return chart?.data.find(item => {
      return (
        (item[firstDimension] === firstDimensionId && item[secondDimension] === secondDimensionId) ||
        (item[secondDimension] === firstDimensionId && item[firstDimension] === secondDimensionId)
      );
    });
  }, [chart, moeModalData]);

  const populationValue = showPopulationNumbers ? moeModalData?.populationValue : 0;

  const items = React.useMemo(() => {
    return [
      {
        label: FilterLabels.Geography,
        id: FilterNames.COUNTRY,
        value: chartDataItem?.country ? getNameById(FilterNames.COUNTRY, chartDataItem?.country, filtersData) : '',
      },
      {
        label: FilterLabels.TimePeriod,
        id: FilterNames.WAVE,
        value: chartDataItem?.wave ? getNameById(FilterNames.WAVE, chartDataItem?.wave, filtersData) : '',
      },
      {
        label: FilterLabels.KPI,
        id: FilterNames.KPI,
        value: chartDataItem?.kpi ? getNameById(FilterNames.KPI, chartDataItem?.kpi, filtersData) : '',
      },
      {
        label: FilterLabels.Brand,
        id: FilterNames.BRAND,
        value: chartDataItem?.brand ? getNameById(FilterNames.BRAND, chartDataItem?.brand, filtersData) : '',
      },
      {
        label: FilterLabels.Segment,
        id: FilterNames.SEGMENT,
        value: chartDataItem?.segment ? getNameById(FilterNames.SEGMENT, chartDataItem?.segment, filtersData) : '',
      },
    ];
  }, [chartDataItem, filtersData]);

  const moeDiff = React.useMemo(() => {
    return (populationValue || 0) * (chartDataItem?.margin_of_error || 0);
  }, [populationValue, chartDataItem?.margin_of_error]);

  const renderItemValues = React.useMemo(() => {
    return (
      <>
        {chartDataItem?.margin_of_error && (
          <BottomFieldsWrapper>
            <FieldItem>
              <span>Margin of error</span>
            </FieldItem>
            <ValueItem>
              &plusmn;
              {showPopulationNumbers
                ? chartValueInMsOrKs(moeDiff, null)
                : roundValue(chartDataItem?.margin_of_error * 100, decimalsEnabled)}
            </ValueItem>
          </BottomFieldsWrapper>
        )}
        {items.map(({ id, label, value }) => {
          const displayValue = value instanceof Date ? displayDate(value, 'MMMM YYYY') : value;
          return (
            <BottomFieldsWrapper key={`moeDataItem-${id}`}>
              <FieldItem>
                <span>{label}</span>
              </FieldItem>
              <ValueItem>{displayValue}</ValueItem>
            </BottomFieldsWrapper>
          );
        })}
      </>
    );
  }, [items, chartDataItem, decimalsEnabled, moeDiff, showPopulationNumbers]);

  const lowestMOE = React.useMemo(() => {
    if (chartDataItem?.low) {
      if (showPopulationNumbers) {
        return (populationValue || 0) - moeDiff;
      }
      return roundValue((chartDataItem?.low || 0) * 100, decimalsEnabled);
    }
    return null;
  }, [chartDataItem, decimalsEnabled, showPopulationNumbers, populationValue, moeDiff]);

  const highestMOE = React.useMemo(() => {
    if (showPopulationNumbers) {
      return (populationValue || 0) + moeDiff;
    }
    if (chartDataItem?.low) return roundValue((chartDataItem?.high || 0) * 100, decimalsEnabled);
    return null;
  }, [chartDataItem, decimalsEnabled, showPopulationNumbers, populationValue, moeDiff]);

  const value = React.useMemo(() => {
    if (showPopulationNumbers) {
      return populationValue || 0;
    }
    return roundValue((chartDataItem?.mean || 0) * 100, decimalsEnabled);
  }, [chartDataItem, decimalsEnabled, showPopulationNumbers, populationValue]);

  const moe = React.useMemo(() => {
    if (showPopulationNumbers) {
      return (populationValue || 0) * (chartDataItem?.margin_of_error || 0);
    }
    return roundValue((chartDataItem?.margin_of_error || 0) * 100, decimalsEnabled);
  }, [chartDataItem, decimalsEnabled, showPopulationNumbers, populationValue]);

  const confidence = React.useMemo(() => {
    return calculateMoeConfidence(
      roundValue((chartDataItem?.margin_of_error || 0) * 100, decimalsEnabled),
      moeConfidenceLevels?.high,
      moeConfidenceLevels?.medium,
    );
  }, [chartDataItem, moeConfidenceLevels, decimalsEnabled]);

  const lowValue = React.useMemo(() => {
    if (lowestMOE) {
      if (showPopulationNumbers) {
        return chartValueInMsOrKs(lowestMOE, null);
      } else {
        return `${lowestMOE}%`;
      }
    } else {
      return `${roundValue(value - moe, decimalsEnabled)}%`;
    }
  }, [showPopulationNumbers, lowestMOE, value, moe, decimalsEnabled]);

  const highValue = React.useMemo(() => {
    if (highestMOE) {
      if (showPopulationNumbers) {
        return chartValueInMsOrKs(highestMOE, null);
      } else {
        return `${highestMOE}%`;
      }
    } else {
      return `${roundValue(value + moe, decimalsEnabled)}%`;
    }
  }, [showPopulationNumbers, highestMOE, value, moe, decimalsEnabled]);

  return (
    <Modal clickOutsideClose isOpen={showPanel} customWidth={564} handleClose={handleClose} showCloseButton={false}>
      <ModalContainer>
        <TopContainer>
          <Title>
            <Text variant='h4'>{t('chart.moeTooltip.title')}</Text>
          </Title>
          <CertanityBox>
            <MoeValuesBox>
              <MoeValueBox>
                <Text variant='body2'>{lowValue}</Text>
                <Text variant='body2' color='black50'>
                  {t('moe.lowest')}
                </Text>
              </MoeValueBox>
              <MoeIndicatorBox color={confidence?.color}>
                <Text variant='h4' color='white'>
                  {showPopulationNumbers ? chartValueInMsOrKs(value, null) : value}
                  {showPopulationNumbers ? '' : '%'}
                </Text>
              </MoeIndicatorBox>
              <MoeValueBox>
                <Text variant='body2'>{highValue}</Text>
                <Text variant='body2' color='black50'>
                  {t('moe.highest')}
                </Text>
              </MoeValueBox>
            </MoeValuesBox>
            {confidence && <CertainityText variant='body2'>{t(`moe.levels.${confidence?.confidenceLevel}`)}</CertainityText>}
          </CertanityBox>
        </TopContainer>
        <BottomContainer>{renderItemValues}</BottomContainer>
      </ModalContainer>
    </Modal>
  );
};

export default MoeModal;
