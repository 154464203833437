import { LoginResponse } from 'common/types/account';
import { OAUTH_KEY, API_URLS } from 'settings/api';
import * as jwt from 'jsonwebtoken';
import { GrantTypes } from 'common/types/login';
import { LogArgument } from 'rollbar';
import { UNDEFINED_TYPE } from 'settings/constants';

const TokenKey = 'latana.authToken';
const ExpireKey = 'latana.authTokenExpire';
const RedirectPathKey = 'latana.redirect';
export const RefreshTokenKey = 'latana.refreshToken';

export const saveRefreshToken = (refreshToken: string): void => {
  sessionStorage.setItem(RefreshTokenKey, refreshToken);
  localStorage.setItem(RefreshTokenKey, refreshToken);
};

export const getRefreshToken = (): string | null => {
  return sessionStorage.getItem(RefreshTokenKey) || localStorage.getItem(RefreshTokenKey);
};

export const getAuthToken = () => {
  // in case use enters by default on the password reset page
  // the existing token should be removed
  const isReset = window.location.pathname.includes('password_resets');
  if (isReset) {
    clearToken();
  }
  // try to get acess tokens from sessionStorage
  const sessionToken = sessionStorage.getItem(TokenKey);
  if (sessionToken && sessionToken !== UNDEFINED_TYPE) {
    return JSON.parse(sessionToken);
  }
  // try to get acess tokens from localStorage
  const expires = localStorage.getItem(ExpireKey);
  const token = localStorage.getItem(TokenKey);
  // return tokens if those are not expired or there is a refresh token to recreate tokens
  if (token && token !== UNDEFINED_TYPE && expires && new Date(expires) > new Date()) {
    return JSON.parse(token);
  } else {
    return undefined;
  }
};

export const saveAuthToken = (token: string | null, expires: Date): void => {
  const tokenString = JSON.stringify(token);
  // save to sessionStorage
  sessionStorage.setItem(TokenKey, tokenString);
  // save to localStorage
  localStorage.setItem(TokenKey, tokenString);
  localStorage.setItem(ExpireKey, expires.toString());
};

export const clearToken = (): void => {
  // clear sessionStorage
  sessionStorage.removeItem(TokenKey);
  sessionStorage.removeItem(RefreshTokenKey);
  // clear localStorage
  localStorage.removeItem(TokenKey);
  localStorage.removeItem(ExpireKey);
  localStorage.removeItem(RefreshTokenKey);
};

export const saveRedirectPath = (url: string): void => {
  sessionStorage.setItem(RedirectPathKey, url);
};

export const getRedirectPath = (): string => {
  return sessionStorage.getItem(RedirectPathKey) || '/';
};

export const clearRedirectPath = (): void => {
  sessionStorage.removeItem(RedirectPathKey);
};

export const getRandomKey = (length: number): string => {
  const byteArray = new Uint8Array(length / 2);
  crypto.getRandomValues(byteArray);
  return Array.from(byteArray, dec => dec.toString(16).padStart(2, '0')).join('');
};

export const generateJWT = (email: string, password: string): string => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return jwt.sign({ email, password, exp: tomorrow.getTime() }, OAUTH_KEY);
};

// base request to get new access tokens
export const fetchRefreshToken = (refreshToken: string): Promise<void> => {
  return fetch(API_URLS.TOKEN, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      grant_type: GrantTypes.REFRESH_TOKEN,
      refresh_token: refreshToken,
    }),
  })
    .then(resp => {
      if (resp.ok) {
        return resp.json() as Promise<LoginResponse>;
      } else {
        return Promise.reject();
      }
    })
    .then(({ access_token, refresh_token: newRefreshToken, created_at, expires_in }) => {
      const expires = new Date(created_at * 1000);
      expires.setSeconds(expires.getSeconds() + expires_in);

      saveAuthToken(access_token, expires);
      saveRefreshToken(newRefreshToken);
      clearRedirectPath(); // Clear session storage
    })
    .catch((e: unknown) => {
      console.error('Fetch refresh token error', e as LogArgument);
      saveAuthToken(null, new Date());
    });
};

const getAuthTokenState = (): boolean => {
  const now = Date.now();
  const expires = Date.parse(localStorage.getItem(ExpireKey) as string);
  const difference = now > expires;

  return difference;
};

const getAccessToken = () => {
  const token = getAuthToken();
  return token;
};

export const updateRequestHeaders = async (headers: Record<string, string>): Promise<Record<string, string>> => {
  const tokenExpired = getAuthTokenState();
  const refreshToken = getRefreshToken();
  let accessToken = getAccessToken();

  if (accessToken && !tokenExpired) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  } else {
    await fetchRefreshToken(refreshToken as string);
    accessToken = getAccessToken();
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return headers;
};
