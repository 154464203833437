import { selector } from 'recoil';
import { fetchMethods } from 'utils/fetch';
import { sessionKeyAtom } from 'common/atoms/account';

export const fetchSelector = selector({
  key: 'FetchSelector',
  get: ({ get }) => {
    get(sessionKeyAtom);

    return fetchMethods();
  },
});
