import { RawChartData } from 'common/types/chart';
import { FilterProps, TransformedFilterProps } from 'common/types/charts';
import { KPI, Wave } from 'common/types/common';
import { FilterNames } from 'common/types/filters';

export const mapFilterOptions = <T extends FilterProps>(filter: T[] | undefined): TransformedFilterProps[] => {
  if (!filter || !filter.length) return [];
  return filter.map(object => ({
    label: object.kpi_label || object.name || '',
    value: object.kpi_identifier || object.id || object.uuid || '',
  }));
};

export const restructureKPIList = (kpiList?: KPI[]): Array<FilterProps> => {
  if (!kpiList?.length) return [];

  const flattenedKpiFormulas = kpiList.flatMap(
    ({ kpi_label, kpi_formulas }) =>
      kpi_formulas?.map(({ kpi_label: formulaLabel, kpi_identifier }) => ({
        kpi_label: `${kpi_label} ${formulaLabel}`,
        kpi_identifier,
      })) || [],
  );

  return [...kpiList, ...flattenedKpiFormulas];
};

export const sortChartValuesByWaveDate = (chartValues: Array<RawChartData>, waveMap: Record<number, Wave>) => {
  return [...chartValues].sort((a, b) => {
    const dateA = waveMap[a.wave]?.date?.getTime() ?? 0;
    const dateB = waveMap[b.wave]?.date?.getTime() ?? 0;
    return dateB - dateA;
  });
};

export const getRelevantFilters = (filterToExclude: string) => {
  return Object.values(FilterNames).filter(name => name !== filterToExclude) as FilterNames[];
};

export const updateDisabledGroups = <T>(disabledGroups: T[], itemId: T): T[] => {
  return !disabledGroups.includes(itemId) ? [...disabledGroups, itemId] : disabledGroups.filter(item => item !== itemId);
};
