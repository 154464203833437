import { KPI, KPIType, KPIOptionType, KPIGroups } from 'common/types/common';
import { FilterOption } from 'common/types/filters';
import { UNDEFINED_TYPE } from 'settings/constants';

export const createKpiMap = (kpis: KPI[]): Record<string, string> => {
  const KPI_DISPLAY_FORMAT_LIST = 'list';
  const kpisMap: Record<string, string> = {};

  kpis.forEach(kpi => {
    const { display_format, kpi_identifier, kpi_label, question_options, kpi_formulas } = kpi;

    if (display_format === KPI_DISPLAY_FORMAT_LIST && (question_options || kpi_formulas)) {
      question_options?.forEach(option => {
        kpisMap[option.option_identifier] = `${kpi_label}: ${option.option_label}`;
      });

      kpi_formulas?.forEach(formula => {
        kpisMap[formula.kpi_identifier] = `${kpi_label}: ${formula.kpi_label}`;
      });
    } else {
      kpisMap[kpi_identifier] = kpi_label;
    }
  });

  return kpisMap;
};

const KPIsWeight: Record<string, number> = {
  'unaided-brand-awareness': 0,
  'aided-brand-awareness': 1,
  'brand-consideration': 2,
  'brand-preference': 3,
  'brand-usage': 4,
  'ad-awareness': 5,
  'brand-associations': 6,
};

export const sortKPIs = (kpis: KPI[]): KPI[] => {
  // remove brand score: https://trello.com/c/RXBi5WUA/86-hide-brandscore-kpi-from-the-chart-form
  kpis = kpis.filter(kpi => kpi.kpi_identifier !== 'brand-score');
  // will be sorted by hardcoded order
  const defaultKPIs = kpis
    .filter(({ kpi_identifier }) => typeof KPIsWeight[kpi_identifier] !== UNDEFINED_TYPE)
    .sort((kpiA, kpiB) => {
      const weightA = KPIsWeight[kpiA.kpi_identifier] ?? kpis.length;
      const weightB = KPIsWeight[kpiB.kpi_identifier] ?? kpis.length;
      return weightA - weightB;
    });

  // will be sorted alphabetically
  const extraKPIs = kpis
    .filter(({ kpi_identifier }) => typeof KPIsWeight[kpi_identifier] === UNDEFINED_TYPE)
    .sort((kpiA, kpiB) => {
      return kpiA.kpi_label < kpiB.kpi_label ? -1 : 1;
    });

  return defaultKPIs.concat(extraKPIs).map(kpi => {
    if (kpi.question_options) {
      return {
        ...kpi,
        question_options: [...kpi.question_options].sort((optA, optB) => {
          const useAlphabetical = optA.option_order === null;

          if (useAlphabetical) {
            return optA.option_label < optB.option_label ? -1 : 1;
          } else {
            const weightA = optA.option_order ?? 0;
            const weightB = optB.option_order ?? 0;

            return weightA - weightB;
          }
        }),
      };
    } else {
      return kpi;
    }
  });
};

export const groupKpisByType = (kpis: KPI[]): KPIGroups => {
  const kpisGroups: KPIGroups = { percentage: [], absolute: [] };

  kpis.forEach(kpi => {
    if (kpi.display_format === 'list') {
      kpi.question_options?.forEach(option => {
        kpisGroups.percentage.push(option.option_identifier);
      });

      kpi.kpi_formulas?.forEach(formula => {
        if (formula.value_type === 'percentage') {
          kpisGroups.percentage.push(formula.kpi_identifier);
        } else {
          kpisGroups.absolute.push(formula.kpi_identifier);
        }
      });
    } else {
      kpisGroups.percentage.push(kpi.kpi_identifier);
    }
  });

  return kpisGroups;
};

export const filterKpisByType = (
  kpisList: FilterOption[] | undefined,
  switchType: KPIType,
  kpisTypeGroups: Record<KPIType, Array<string | number>>,
): FilterOption[] => {
  if (switchType === KPIType.ABSOLUTE) {
    const filtered = [] as FilterOption[];
    kpisList?.forEach(kpiOption => {
      if (kpiOption.type === KPIOptionType.GROUP) {
        const availableOptions = kpiOption.options.filter(({ value }) => kpisTypeGroups.absolute.includes(value));
        if (availableOptions.length) {
          filtered.push({ ...kpiOption, options: availableOptions });
        }
      }
    });

    return filtered;
  } else {
    const filtered = [] as FilterOption[];
    kpisList?.forEach(kpiOption => {
      if (kpiOption.type === KPIOptionType.OPTION) {
        filtered.push(kpiOption);
      } else if (kpiOption.type === KPIOptionType.GROUP) {
        const availableOptions = kpiOption.options.filter(({ value }) => !kpisTypeGroups.absolute.includes(value));
        if (availableOptions.length) {
          filtered.push({ ...kpiOption, options: availableOptions });
        }
      }
    });

    return filtered;
  }
};
