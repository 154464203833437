import styled from '@emotion/styled';
import { theme } from 'common/theme';
import React from 'react';

export const SwitchContainer = styled.div`
  width: 36px;
  height: 20px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  background-color: ${theme.colors.lightGrey.toString()};
`;

export const SwitchCircle = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${theme.colors.white.toString()};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.15s;
  z-index: 2;
`;

const HiddenInput = styled.input`
  display: none;
  &:checked + ${SwitchContainer} {
    background-color: ${theme.colors.lightPurple.toString()};
    ${SwitchCircle} {
      left: 16px;
    }
  }
`;

const Wrapper = styled.label`
  display: inline-block;
`;

interface Props {
  className?: string;
  ref?: React.ForwardedRef<unknown>;
  name: string;
  onClick?: () => void;
}

// eslint-disable-next-line react/display-name
const Toggle: React.FC<Props> = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <Wrapper>
      <HiddenInput type='checkbox' {...props} ref={ref as React.LegacyRef<HTMLInputElement>} />
      <SwitchContainer className={className}>
        <SwitchCircle />
      </SwitchContainer>
    </Wrapper>
  );
});

export default React.memo(Toggle);
