import { ChartFormData } from 'common/types/chart';
import { useMemo } from 'react';
import { deepEquals } from 'utils/helpers';

const isDescriptionBeenChanged = (newDesc: string | null, oldDesc: string | null) => {
  if (typeof newDesc === 'string' && typeof oldDesc === 'string') return newDesc !== oldDesc;
  return Boolean(newDesc) !== Boolean(oldDesc);
};

// dirty - something changed
// changed - filters changed, ignores name change
// Note: dirty will be used for show the save buttons
export const useDirty = (
  computedChartFormData: ChartFormData,
  intermediateValues: ChartFormData,
  newName: string | unknown,
  newDescription: string | null | unknown,
): boolean[] => {
  return useMemo(() => {
    const { name: oldName, description: oldDescription, ...oldRest } = computedChartFormData;
    const { name: _newName, description: _newDescription, ...newRest } = intermediateValues;
    const dirty = newName !== oldName || isDescriptionBeenChanged(oldDescription, newDescription as string);
    const isFilterChanged = !deepEquals(oldRest, newRest);
    return [dirty, isFilterChanged];
  }, [intermediateValues, computedChartFormData, newName, newDescription]);
};
