import * as React from 'react';
import Filters from 'modules/charts/components/Filters';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { generateChartFormData } from 'utils/chart';
import { useDeleteChart, useSaveChart } from 'common/hooks/charts';
import ChartRenderer from 'modules/charts/components/ChartRenderer';
import { ChartViewContext } from 'common/contexts/ChartViewContext';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import Notification from 'common/components/Notification';
import { NotificationAtom } from 'common/atoms/notifications';
import { ChartFormData, RawChart } from 'common/types/chart';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import ChartModal from 'modules/charts/components/ChartModal';
import { FilterNames } from 'common/types/filters';
import { LoadingContainer } from 'common/styledComponents/containers';
import Spinner from 'common/components/Spinner';
import ConfirmationModal from 'common/components/Modal/ConfirmationModal';
import { ConfirmationTextWrapper } from 'common/styledComponents/modals';
import { LatanaText } from 'common/components/LatanaText';
import { useNavigate } from 'react-router-dom';
import { currentChartAtom } from 'common/atoms/charts';
import { UNDEFINED_TYPE } from 'settings/constants';

interface ChartViewProps {
  chartId: string | undefined;
  chart: RawChart;
}

const ChartView: React.FC<ChartViewProps> = ({ chartId, chart }) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const submitRef = React.useRef<HTMLButtonElement>();
  const exportChartCSVRef = React.useRef<(() => void) | null>(null); // Use a ref to store the export chart csv function

  // Register the function from Child One in the ref
  const registerExportChartCSV = (chartExportFunc: () => void) => {
    exportChartCSVRef.current = chartExportFunc; // Store the function in the ref
  };

  const isChartCreate = typeof chartId === UNDEFINED_TYPE;

  const [notification] = useRecoilState(NotificationAtom);
  const { fetchPut, fetchPost } = useRecoilValue(fetchSelector);

  const [filterChanged, setFilterChanged] = React.useState(false);
  const [isSaveAs, setSaveAs] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [warningModalState, setWarningModalState] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState<FilterNames | undefined>();
  const navigate = useNavigate();
  const { loading: deleteLoading, deleteChart } = useDeleteChart();
  const { loading: saveLoading, saveChart } = useSaveChart();
  const setChart = useSetRecoilState(currentChartAtom);

  React.useEffect(() => {
    setActiveFilter(undefined);
  }, [chartId]);

  // open country filter by default on create
  React.useEffect(() => {
    if (isChartCreate) setActiveFilter(FilterNames.COUNTRY);
  }, [isChartCreate, chart.chart.name]);

  React.useEffect(() => {
    setSaveAs(false);
  }, [chartId]);

  const chartFormData = React.useMemo(() => {
    if (!chart) return undefined;
    return generateChartFormData(chart);
  }, [chart]);

  /** Track event for view page */
  React.useEffect(() => {
    if (chart && !isChartCreate) {
      trackEvent(TRACKING_EVENTS.VIEW_PAGE, {
        chartType: chart?.chart.chart_type,
        chartName: chart?.chart.name,
      });
    }
  }, [trackEvent, chart, isChartCreate]);

  /** Track event for add chart */
  React.useEffect(() => {
    if (isChartCreate) {
      trackEvent(TRACKING_EVENTS.ADD_CHART, {
        chartType: chart?.chart.chart_type,
      });
    }
  }, [trackEvent, isChartCreate, chart]);

  const getRequestShape = React.useCallback(
    (chartID?: string) =>
      chartID ? { url: API_URLS.CHART(chartID), fetchMethod: fetchPut } : { url: API_URLS.CHARTS, fetchMethod: fetchPost },
    [fetchPost, fetchPut],
  );

  const onNewChartRedirect = React.useCallback(
    (chartId: string) => {
      navigate(`/chart/${chartId}`);
    },
    [navigate],
  );

  const handleChartSubmit = React.useCallback(
    async (chartData: ChartFormData) => {
      if (!chartData.description) chartData.description = null;

      const newChart = await saveChart(chartData, getRequestShape(chartId));
      trackEvent(TRACKING_EVENTS.SAVE_CHART, { chartType: chartData.chart_type });

      setActiveFilter(undefined);
      setChart(undefined);
      if (isChartCreate && newChart?.uuid) {
        onNewChartRedirect(newChart.uuid);
      }
    },
    [saveChart, chartId, trackEvent, getRequestShape, isChartCreate, setActiveFilter, onNewChartRedirect, setChart],
  );

  const handleChartDuplicate = React.useCallback(
    async (chartData: ChartFormData) => {
      if (!chartData.description) chartData.description = null;

      chartData.name = `Copy ${chartData.name}`;

      const newChart = await saveChart(chartData, getRequestShape());

      if (newChart?.uuid) {
        onNewChartRedirect(newChart.uuid);
      }
    },
    [saveChart, getRequestShape, onNewChartRedirect],
  );

  const toggleDeleteModal = React.useCallback(() => {
    setShowDeleteModal(!showDeleteModal);
  }, [showDeleteModal]);

  const handleDeleteChart = React.useCallback(() => {
    deleteChart(chartId as string);
    setShowDeleteModal(false);
  }, [deleteChart, setShowDeleteModal, chartId]);

  const toggleWarningModal = React.useCallback(() => {
    setWarningModalState(!warningModalState);
    const chartNameInput = document.getElementById('chartName') as HTMLInputElement;
    const chartDescriptionInput = document.getElementById('chartDescription') as HTMLInputElement;

    if (chartNameInput) chartNameInput.disabled = false;
    if (chartDescriptionInput) chartDescriptionInput.disabled = false;
  }, [warningModalState]);

  const renderDeleteModal = React.useMemo(() => {
    return (
      <ConfirmationModal
        isLoading={deleteLoading}
        title={t('chart.deleteChart')}
        open={showDeleteModal}
        onCancel={toggleDeleteModal}
        onConfirm={handleDeleteChart}
      >
        <ConfirmationTextWrapper>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.headingv2', { title: chart?.chart.name || '' })}
          </LatanaText>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.subheadingv2')}
          </LatanaText>
        </ConfirmationTextWrapper>
      </ConfirmationModal>
    );
  }, [handleDeleteChart, showDeleteModal, toggleDeleteModal, t, chart?.chart.name, deleteLoading]);

  const renderWarningModal = React.useMemo(() => {
    return (
      <ChartModal open={warningModalState} onCancel={toggleWarningModal} onConfirm={handleChartDuplicate} chartFormData={chartFormData} />
    );
  }, [warningModalState, toggleWarningModal, handleChartDuplicate, chartFormData]);

  const handleChartDataCSVExport = React.useCallback(() => {
    exportChartCSVRef.current && exportChartCSVRef.current();
  }, []);

  if (saveLoading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  return (
    <ChartViewContext.Provider value={{ submitRef, chart }}>
      {chartFormData?.study_uuid && !notification.show && (
        <React.Fragment>
          <Filters
            onFilterChanged={setFilterChanged}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            chartFormData={chartFormData}
            chartUpdateLoading={saveLoading && !isSaveAs}
            chartSaveAsLoading={saveLoading && isSaveAs}
            onChartUpdate={handleChartSubmit}
            onChartDuplicate={handleChartDuplicate}
            toggleDeleteModal={toggleDeleteModal}
            onShowWarningModal={toggleWarningModal}
            handleChartDataCSVExport={handleChartDataCSVExport}
          />
          <ChartRenderer
            studyId={chartFormData.study_uuid}
            loadedChartData={chart}
            hidden={Boolean(activeFilter)}
            isFilterChanged={filterChanged}
            isChartCreate={isChartCreate}
            registerChartExportCSVFunction={registerExportChartCSV}
          />
        </React.Fragment>
      )}
      {notification.show && <Notification type={notification.type} />}
      {renderDeleteModal}
      {renderWarningModal}
    </ChartViewContext.Provider>
  );
};

export default React.memo(ChartView);
