import * as React from 'react';
import { ChartHeader, ChartHeaderContainer, ChartNameContainer } from 'modules/charts/components/Filters/styled';
import ChartName from 'modules/charts/components/Filters/ChartName';
import ChartDescription from 'modules/charts/components/Filters/ChartDescription';
import CancelChartModal from 'modules/charts/components/Filters/CancelChartModal';
import ChartHeaderButtons from 'modules/charts/components/Filters/ChartHeaderButtons';
import { ActionItems, DataTestId, LATANA_NAME } from 'settings/constants';
import ActionMenu from 'common/components/ActionMenu';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { IconButtonContainer } from 'common/styledComponents/buttons';
import Icon from 'common/components/Icon';
import { ICONS } from 'icons/iconsMap';
import { SCREENS, TRACKING_EVENTS } from 'common/types/tracking';
import { useTracking } from 'common/hooks/tracking';

interface ChartViewHeaderProps {
  dirty: boolean;
  reg: () => void;
  isEditor: boolean;
  isCreate: boolean;
  chartName: string;
  chartDesc: string | null;
  menuItems: SelectPopupItemProps[];
  showCancelModal: boolean;
  handleCancelMoldalClose: () => void;
  handleCancelChart: () => void;
  canSubmit: boolean;
  chartUpdateLoading: boolean;
  handleReset: () => void;
  isChartValuesChanged: boolean;
  onHandleInputFocus: () => void;
  onSubmit: () => void;
  chartOwner?: string;
  isInternalUser: boolean;
  chartType: string;
  isFilterChanged: boolean;
}

type TrackEventsTable = {
  [key: string]: string;
};

const ChartViewHeader: React.FC<ChartViewHeaderProps> = ({
  reg,
  dirty,
  isEditor,
  isCreate,
  chartName,
  chartDesc,
  menuItems,
  showCancelModal,
  handleCancelMoldalClose,
  handleCancelChart,
  canSubmit,
  chartUpdateLoading,
  handleReset,
  isChartValuesChanged,
  onHandleInputFocus,
  onSubmit,
  chartOwner,
  isInternalUser,
  chartType,
  isFilterChanged,
}) => {
  const { trackEvent } = useTracking();
  const isSubmittable = dirty && !isCreate;
  const isEditable = isInternalUser || (isEditor && chartOwner !== LATANA_NAME);
  const isShowMenu = menuItems?.length && !isChartValuesChanged && !isCreate;

  const complementaryMenuItems = menuItems.filter(item =>
    ([ActionItems.CREATE_CHART_IMAGE, ActionItems.DUPLICATE, ActionItems.DOWNLOAD_CSV] as string[]).includes(item.value as string),
  );

  const trackEventsTable: TrackEventsTable = {
    ['createChartImage']: TRACKING_EVENTS.CLICK_SHORTCUT_PNG,
    ['downloadCSV']: TRACKING_EVENTS.CLICK_SHORTCUT_CSV,
    ['duplicate']: TRACKING_EVENTS.CLICK_SHORTCUT_DUPLICATE,
  };

  const handleMenuClick = () => {
    trackEvent(TRACKING_EVENTS.ACTION_MENU_CLICK, {
      chartType,
      chartName,
      screen: SCREENS.CHART_DETAILS,
    });
  };

  return (
    <ChartHeader data-testid={DataTestId.CHART_HEADER}>
      <ChartHeaderContainer>
        <ChartNameContainer data-testid={DataTestId.CHART_NAME_CONTAINER}>
          <ChartName
            reg={reg}
            isEditable={isEditable}
            chartName={chartName}
            onHandleFocus={onHandleInputFocus}
            onSubmit={isSubmittable ? onSubmit : undefined}
          />
          {isShowMenu && (
            <>
              {complementaryMenuItems.map(item => (
                <IconButtonContainer
                  key={item.value}
                  data-hover={item.label}
                  onClick={() => item?.action && item?.action(trackEventsTable[item.value])}
                  data-testid={`${item.value}-action`}
                >
                  <Icon icon={item.icon as keyof typeof ICONS} color='gray600' />
                </IconButtonContainer>
              ))}
              <ActionMenu id={DataTestId.LIST_ACTION} icon='more' items={menuItems} onClick={handleMenuClick} />
            </>
          )}
        </ChartNameContainer>
        <ChartDescription
          reg={reg}
          isEditable={isEditable}
          chartDesc={chartDesc}
          onHandleFocus={onHandleInputFocus}
          onSubmit={isSubmittable ? onSubmit : undefined}
        />
      </ChartHeaderContainer>
      <CancelChartModal
        isCreate={isCreate}
        showPanel={showCancelModal}
        handleCancel={handleCancelMoldalClose}
        handleConfirm={handleCancelChart}
      />
      <ChartHeaderButtons
        canSubmit={canSubmit}
        onHandleReset={handleReset}
        isFilterChanged={isFilterChanged}
        chartUpdateLoading={chartUpdateLoading}
        isChartValuesChanged={isChartValuesChanged}
      />
    </ChartHeader>
  );
};

export default React.memo(ChartViewHeader);
