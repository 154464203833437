import { useMemo } from 'react';
import { IFilter, FilterNames, IFilterOptionGroup } from 'common/types/filters';
import { AVERAGE, AVERAGE_VALUE } from 'settings/constants';
import { getAverageColor } from 'modules/charts/utils/colors';
import { ChartType } from 'common/types/chart';

const averageOption = { value: AVERAGE_VALUE, label: AVERAGE, type: 'option', color: getAverageColor() };

const isFilterWithAverage = (chartType: ChartType, filter: FilterNames) => {
  return filter === FilterNames.BRAND && (chartType === ChartType.HEATMAP || chartType === ChartType.RADAR);
};

export const useFiltersList = (allFilters: Array<IFilter>, chartType: ChartType): Array<IFilter> => {
  return useMemo(() => {
    const newFilters = allFilters.map(filter => {
      if (isFilterWithAverage(chartType, filter.id)) {
        const filterBody = filter?.options?.[0] as IFilterOptionGroup;
        const oldFilterOptions = filterBody?.options;
        const newFilterOptions = [{ ...filterBody, options: [...oldFilterOptions, averageOption] }];
        return { ...filter, options: newFilterOptions } as IFilter;
      }
      return filter;
    });

    return newFilters;
  }, [allFilters, chartType]);
};
