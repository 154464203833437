import React from 'react';
import { Text } from 'common/styledComponents/typography';
import styled from '@emotion/styled';

const InsightText = styled(Text)`
  color: ${({ theme }) => theme.colors.sooty.toString()};
  letter-spacing: 0.01em;
  line-height: ${({ theme }) => theme.text.lineHeight3};
`;

const InsightRenderer = ({ insights }: { insights: string[] }): JSX.Element => {
  return (
    <React.Fragment>
      {insights.map(insight => (
        <InsightText key={insight} fontWeight='600' variant='body2' marginBottom title={insight}>
          {insight}
        </InsightText>
      ))}
    </React.Fragment>
  );
};

export default InsightRenderer;
