import React from 'react';
import { useRecoilValue } from 'recoil';
import { BarItemProps, Layer, ResponsiveBar } from '@nivo/bar';
import { chartTheme } from 'modules/charts/theme';
import AxisBottom from 'modules/charts/components/AxisBottom';
import {
  ChartDataPoint,
  ChartLayout,
  ChartType,
  ComparisonBar,
  AverageLayerProps,
  TAverageLayerGroup,
  TAverageLayerSingleLine,
  ChartNumbersType,
  ChartDataKey,
} from 'common/types/chart';
import { DefaultAxisLeft } from 'modules/charts/components/AxisLeft';
import { BarChartContainer } from 'common/styledComponents/containers';
import { uiPreferencesSelector } from 'common/atoms/account';
import { FilterNames } from 'common/types/filters';
import ReferenceLayer from 'modules/charts/components/BarChart/ReferenceLayer';
import { referenceAxesLocation } from 'modules/charts/utils/chartData';
import useFullBar from 'modules/charts/hooks/useFullBar';
import { DataTestId, INNER_PADDING } from 'settings/constants';
import { useRotateChartLabel } from 'modules/charts/components/BarChart/useRotateChartLabel';
import { useGetWavesMap } from 'common/hooks/waves';
import { getCurrentBarId } from 'modules/charts/components/CustomBar/utils';
import CustomBarWithLabelRotation from '../CustomBarWithLabelRotation';

interface Props {
  data: ChartDataPoint[];
  keys: string[];
  indexBy?: string;
  chartType: ChartType;
  groups: number;
  width?: number;
  secondDimension?: string;
  maxValue: number;
  isDirty: boolean;
  inPage?: boolean;
  showAverage?: boolean;
  chartLayout?: string;
  maxMoE?: number;
  moeStatus?: boolean;
  maxSegmentPopulationSize: number;
  chartNumbersType: ChartNumbersType;
}

const EMPTY_CHART_MAX_VALUE = 35;
const CHART_MAX_VALUE_FACTOR = 100;
const MAX_Y_AXIS_MARGIN = 148;
const BAR_VALUE_LABEL_OFFSET = 10;

const BarChart: React.FC<Props> = ({
  data,
  keys,
  indexBy,
  chartType,
  groups,
  width,
  secondDimension,
  maxValue,
  isDirty,
  inPage = false,
  showAverage = false,
  chartLayout,
  maxMoE,
  moeStatus,
  maxSegmentPopulationSize,
  chartNumbersType,
}) => {
  const containerRef = React.useRef<HTMLDivElement>();
  const isComparison = chartType === ChartType.COMPARISON;
  const isBarLayout = chartLayout === ChartLayout.HORIZONTAL && isComparison;
  const [yLabelMargin, setYLabelMargin] = React.useState(MAX_Y_AXIS_MARGIN);
  const [rotateXLabels, setRotateXlabels] = React.useState(false);
  const { showBarValue } = useRecoilValue(uiPreferencesSelector);
  const [groupWidth, setGroupWidth] = React.useState<number>();
  const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;

  const availableChartItems = React.useMemo(() => {
    return data.filter(item => !item.disabled);
  }, [data]);

  const visibleGroups = React.useMemo(() => {
    return availableChartItems.map(data => data.groupId);
  }, [availableChartItems]);

  const chartEmpty = !availableChartItems.length;

  // To persist desirable bar order in horizontal view
  // we need to reverse chart items order before
  // propagate it to <ResponsiveBar /> nivo component.
  const orderedChartData = React.useMemo(() => {
    return isBarLayout ? availableChartItems.reverse() : availableChartItems;
  }, [isBarLayout, availableChartItems]);

  const numericChart = React.useMemo(() => {
    return data?.[0]?.['value_0_numeric'];
  }, [data]);

  const maxAverage = React.useMemo(() => {
    const AVERAGE_VALUE_KEY = 'value_0_averageValue';

    const getValueOrDefault = (item: ChartDataPoint, key = AVERAGE_VALUE_KEY, defaultValue = 0): number => {
      return (item[key as ChartDataKey] || defaultValue) as number;
    };

    return data.reduce((max, item) => {
      const averageValue = getValueOrDefault(item);
      return Math.max(max, averageValue);
    }, 0);
  }, [data]);

  const axisMinValue = React.useMemo(() => {
    const AUTO_MIN_VALUE = 'auto';

    if (showPopulationNumbers || chartEmpty) {
      return 0;
    }

    return AUTO_MIN_VALUE;
  }, [chartEmpty, showPopulationNumbers]);

  const applyMaxValueMultiplier = (value: number) => value * CHART_MAX_VALUE_FACTOR;
  const isMaxValueGreaterThanAverage = (maxValue: number, maxAverage: number): boolean => maxValue > maxAverage;

  const maxChartAxisValue = React.useMemo(() => {
    if (chartEmpty) return EMPTY_CHART_MAX_VALUE;
    if (showPopulationNumbers) return maxSegmentPopulationSize;

    const scaledMaxValue = applyMaxValueMultiplier(maxValue);
    const isMaxGreaterThanAverage = isMaxValueGreaterThanAverage(scaledMaxValue, maxAverage);

    const baseMaxAxisValue = showAverage && !isMaxGreaterThanAverage ? maxAverage : scaledMaxValue;
    const adjustedMaxAxisValue = moeStatus && maxMoE ? baseMaxAxisValue + maxMoE * CHART_MAX_VALUE_FACTOR : baseMaxAxisValue;
    const finalMaxAxisValue = showBarValue ? adjustedMaxAxisValue + BAR_VALUE_LABEL_OFFSET : adjustedMaxAxisValue;

    return Math.ceil(finalMaxAxisValue);
  }, [maxValue, chartEmpty, maxAverage, showAverage, maxMoE, moeStatus, showPopulationNumbers, maxSegmentPopulationSize, showBarValue]);

  const renderCustomChartBar: React.FC<BarItemProps> = React.useCallback(
    barProps => (
      <CustomBarWithLabelRotation
        barProps={barProps}
        chartType={chartType}
        moeStatus={moeStatus}
        isBarLayout={isBarLayout}
        isNumericChart={numericChart}
        isShowBarValue={showBarValue}
        visibleGroups={visibleGroups}
        chartNumbersType={chartNumbersType}
      />
    ),
    [chartType, isBarLayout, visibleGroups, moeStatus, numericChart, showBarValue, chartNumbersType],
  );

  useRotateChartLabel(keys, setGroupWidth, setRotateXlabels, containerRef);

  React.useEffect(() => {
    const ro = new ResizeObserver(entries => {
      let margin = MAX_Y_AXIS_MARGIN;
      const yLabels = entries[0]?.target?.querySelectorAll('svg > g > g:nth-child(n+5) > g');
      if (yLabels) {
        const labelWidths = Array.from(yLabels).map(label => label?.getBoundingClientRect()?.width);
        if (labelWidths?.length) margin = Math.max(...labelWidths);
      }
      setYLabelMargin(margin || MAX_Y_AXIS_MARGIN);
    });
    ro.observe(containerRef?.current as Element);
    return () => ro.disconnect();
  }, [containerRef, chartLayout]);

  const barCount = React.useMemo(() => {
    const firstElement: ChartDataPoint = data[0];
    return data.length ? firstElement.count * data.length : 0;
  }, [data]);

  const showFullBar = useFullBar(width, barCount, document);
  const waveMap = useGetWavesMap();

  const groupedBarData = React.useCallback((bars: Array<ComparisonBar>) => {
    return bars.reduce((acc: Record<string, Array<ComparisonBar>>, item: ComparisonBar) => {
      acc[item.data.index] = [...(acc[item.data.index] || []), item];
      return acc;
    }, {});
  }, []);

  const groupAverageLayer: TAverageLayerGroup = React.useCallback(
    ({ bars, yScale, xScale }: AverageLayerProps) => {
      const group = groupedBarData(bars);
      return Object.keys(group).map((_, groupIndex: number) => (
        <ReferenceLayer
          key={groupIndex}
          bar={group[groupIndex][0]}
          groupItems={group[groupIndex]}
          groupIndex={groupIndex}
          moeEnabled={moeStatus}
          barId={getCurrentBarId(group[groupIndex][0]?.data?.id)}
          locationOnAxes={referenceAxesLocation({ bar: group[groupIndex][0], yScale, xScale, chartLayout, groupItems: group[groupIndex] })}
          chartNumbersType={chartNumbersType}
        />
      ));
    },
    [moeStatus, chartLayout, groupedBarData, chartNumbersType],
  );

  const barAverageLayer: TAverageLayerGroup = React.useCallback(
    ({ bars, yScale, xScale }: AverageLayerProps) => {
      return bars.map((bar: ComparisonBar) => (
        <ReferenceLayer
          key={bar.key}
          bar={bar}
          moeEnabled={moeStatus}
          locationOnAxes={referenceAxesLocation({ bar, yScale, xScale, chartLayout })}
          barId={getCurrentBarId(bar?.data?.id)}
          chartNumbersType={chartNumbersType}
        />
      ));
    },
    [moeStatus, chartLayout, chartNumbersType],
  );

  const sortBars = React.useCallback((bars: ComparisonBar[], layout: string | undefined) => {
    // Bars order aren't always the same, so based on layout, sort bars by X or Y value so we can render single line properly
    return bars.sort((a, b) => (layout === ChartLayout.VERTICAL ? a.x - b.x : b.y - a.y));
  }, []);

  const singleLineAverageLayer: TAverageLayerSingleLine = React.useCallback(
    ({ bars, yScale, xScale }: AverageLayerProps) => {
      const sortedBars = sortBars(bars, chartLayout);
      return (
        <ReferenceLayer
          key={sortedBars[0].key}
          bar={sortedBars[0]}
          moeEnabled={moeStatus}
          locationOnAxes={referenceAxesLocation({
            bar: sortedBars[0],
            yScale,
            xScale,
            chartLayout,
            groupItems: [sortedBars[sortedBars.length - 1]],
            singleLine: true,
          })}
          barId={getCurrentBarId(sortedBars[0]?.data?.id)}
          chartNumbersType={chartNumbersType}
        />
      );
    },
    [moeStatus, chartLayout, sortBars, chartNumbersType],
  );

  const isSingleLineAverageLayer: boolean = React.useMemo(() => {
    const BRAND = FilterNames.BRAND;
    return (indexBy === BRAND && keys.length === 1) || (secondDimension === BRAND && groups === 1);
  }, [indexBy, keys, secondDimension, groups]);

  const layersList = React.useMemo(() => {
    const layers = ['grid', 'markers', 'bars', 'axes', 'points', 'mesh'] as Layer[];
    if (!showAverage) return layers;

    return isSingleLineAverageLayer
      ? [...layers, singleLineAverageLayer]
      : secondDimension === FilterNames.BRAND
      ? [...layers, groupAverageLayer]
      : [...layers, barAverageLayer];
  }, [groupAverageLayer, barAverageLayer, singleLineAverageLayer, showAverage, isSingleLineAverageLayer, secondDimension]);

  const LeftAxis = React.useMemo(() => {
    return DefaultAxisLeft(numericChart, waveMap, showPopulationNumbers, isBarLayout);
  }, [numericChart, waveMap, showPopulationNumbers, isBarLayout]);

  const BottomAxis = React.useMemo(() => {
    const hasGrouping = keys.length > 1;
    const singleBarWidth = (groupWidth ?? 0) / keys.length;
    const groupedBarWidth = hasGrouping ? groupWidth : singleBarWidth;
    return AxisBottom({
      maxWidth: groupedBarWidth ?? 0,
      waveMap,
      isBarLayout,
      hasGrouping,
      rotateXLabels,
      showPopulationNumbers,
    });
  }, [groupWidth, waveMap, keys, isBarLayout, rotateXLabels, showPopulationNumbers]);

  const RightAxis = {
    renderTick: () => null as any,
  };

  const chartKeys = showPopulationNumbers ? keys.map(str => str + '_populationNumberRaw') : keys;

  return (
    <BarChartContainer
      isDirty={isDirty}
      barCount={barCount}
      showFullBar={showFullBar}
      aria-busy={!groupWidth}
      inPage={inPage}
      ref={containerRef as React.MutableRefObject<HTMLDivElement>}
      id='bar-chart-container'
      data-testid={`${DataTestId.BAR_CHART}-${isBarLayout ? 'HORIZONTAL' : 'VERTICAL'}`}
      data-rotated-labels={rotateXLabels}
    >
      <ResponsiveBar
        data={orderedChartData}
        keys={chartKeys}
        indexBy={indexBy}
        margin={{ top: 16, right: 0, bottom: 48, left: isBarLayout ? yLabelMargin : 48 }}
        padding={0.15}
        innerPadding={INNER_PADDING}
        minValue={axisMinValue}
        maxValue={maxChartAxisValue}
        theme={chartTheme}
        groupMode='grouped'
        colors={bar => bar.data.color}
        enableLabel={true}
        labelTextColor='white'
        barComponent={renderCustomChartBar}
        axisTop={null}
        axisRight={RightAxis}
        axisBottom={BottomAxis}
        axisLeft={LeftAxis}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        layers={layersList}
        layout={chartLayout as ChartLayout}
        enableGridX={isBarLayout}
        enableGridY={!isBarLayout}
      />
    </BarChartContainer>
  );
};

export default BarChart;
