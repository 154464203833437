import { API_URLS } from 'settings/api';
import { MembersAtom, RolesAtom } from 'common/atoms/roles';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { fetchSelector } from 'common/atoms/common';
import { useState } from 'react';
import { Member, RawRole } from 'common/types/roles';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';

export const useMembers = (): {
  fetchMembers: () => Promise<void>;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);
  const rollbar = useRollbar();
  const fetchMembers = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        try {
          setLoading(true);
          const { fetchGet } = await snapshot.getPromise(fetchSelector);
          const members = await fetchGet<Member[]>(`${API_URLS.USERS}`);
          await set(MembersAtom, members);
          setLoading(false);
        } catch (e: unknown) {
          rollbar.error('Fetch members error', e as LogArgument);
        }
      },
    [],
  );

  return { fetchMembers, loading };
};

export const useInviteUser = (): ((invitedUserData: { email: string; role_id: string }) => Promise<unknown>) => {
  const rollbar = useRollbar();
  const { fetchPost } = useRecoilValue(fetchSelector);
  const inviteUser = async (invitedUserData: { email: string; role_id: string }) => {
    try {
      return fetchPost(API_URLS.ASSIGN_ROLE_TO_USER, {
        ...invitedUserData,
      });
    } catch (e: unknown) {
      rollbar.error('useInviteUser error', e as LogArgument);
    }
  };

  return inviteUser;
};

export const useUpdateUserRole = (): (({ id, role_id }: { id: number; role_id: string }) => Promise<void>) => {
  const rollbar = useRollbar();
  const { fetchMembers } = useMembers();
  const { fetchPatch } = useRecoilValue(fetchSelector);

  const updateUserRole = async ({ id, role_id }: { id: number; role_id: string }) => {
    try {
      await fetchPatch(API_URLS.UPDATE_USER_ROLE(id), {
        role_id: Number(role_id),
      });
      fetchMembers();
    } catch (e: unknown) {
      rollbar.error('useUpdateUserRole error', e as LogArgument);
    }
  };

  return updateUserRole;
};

export const useRetrieveUserRole = (): ((userId: number) => Promise<void>) => {
  const rollbar = useRollbar();
  const { fetchMembers } = useMembers();
  const { fetchDelete } = useRecoilValue(fetchSelector);

  const retrieveUserRole = async (userId: number) => {
    try {
      await fetchDelete(API_URLS.UPDATE_USER_ROLE(userId));
      fetchMembers();
    } catch (e: unknown) {
      rollbar.error('useRetrieveUserRole error', e as LogArgument);
    }
  };

  return retrieveUserRole;
};

export const useRoles = (): {
  fetchRoles: () => Promise<void>;
  loading: boolean;
} => {
  const rollbar = useRollbar();
  const [loading, setLoading] = useState(false);

  const capitalize = (str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const fetchRoles = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        try {
          setLoading(true);
          const { fetchGet } = await snapshot.getPromise(fetchSelector);
          const rawRoles = await fetchGet<RawRole[]>(`${API_URLS.ROLES}`);
          const roles = rawRoles.map(({ id, name, description }) => ({
            label: capitalize(name),
            value: String(id),
            roleDescription: description,
          }));
          set(RolesAtom, roles);
          setLoading(false);
        } catch (e: unknown) {
          rollbar.error('Fetch roles error', e as LogArgument);
        }
      },
    [],
  );

  return { fetchRoles, loading };
};
