import React from 'react';
import styled from '@emotion/styled';
import { getTargetAudience } from 'views/Gauge/utils';

export const PercentageText = styled.text`
  font-size: ${({ theme }) => theme.text.variants.h3.fontSize};
  font-weight: ${({ theme }) => theme.text.variants.h4.fontWeight};
`;

export const InnerText = styled.p<{ width: number }>`
  font-size: ${({ theme }) => theme.text.variants.subheading1.fontSize};
  font-weight: ${({ theme }) => theme.text.variants.subheading1.fontWeight};
  color: ${({ theme }) => theme.colors.mortarGrey.toString()};
  text-align: center;
  margin: 0 auto;
  width: ${({ width }) => `${width - 30}px`}; // 30 unit so that it should not collapse with the rings
`;

export const TextContainer = styled.foreignObject`
  overflow: visible;
`;

interface Props {
  text: string;
  percentage: number;
  sqSize: number;
  population?: number;
}

const GaugeText = ({ percentage, sqSize, text, population }: Props): JSX.Element => {
  return (
    <>
      <PercentageText x='50%' y='44%' dy='.3em' textAnchor='middle'>
        {population ? getTargetAudience(population, percentage) : `${percentage}%`}
      </PercentageText>
      <TextContainer x='0%' y='52%' dy='.3em' width={sqSize} height='50' textAnchor='middle'>
        <InnerText width={sqSize}>{text}</InnerText>
      </TextContainer>
    </>
  );
};

export default GaugeText;
