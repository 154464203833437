import { ChartFormData, ChartType } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';
import { useEffect } from 'react';
import { BaseFilterService } from '../services';
import { KPIFilterService } from '../services/kpi';
import { WaveFilterService } from '../services/wave';
import { isGlobalKPI } from '../utils/globalKpi';
import { ChartValue } from 'common/types/charts';
import { useGetLastWave, useGetLastWaves } from 'common/hooks/waves';
import { useGetStudySpecificRestrictions } from 'common/hooks/filters';
import { useKpiTypeGroups, useKpis } from 'common/hooks/kpis';
import { useIsPopulationNumbersEnabled } from 'common/hooks/features';
import { DYNAMIC_WAVES_DEFAULT, MATRIX_CHART_DEFAULT_WAVES } from 'settings/constants';

export const useInitFilterServices = (
  setChartValue: (field: string, value: ChartValue) => void,
  values: ChartFormData,
  chartType: string,
  studyId: string,
  activeFilter?: FilterNames,
): void => {
  const { kpi } = values;
  const allFilters = useGetStudySpecificRestrictions(studyId);
  const kpis = useKpis(studyId);
  const isGlobalKPIExist = isGlobalKPI(kpis, kpi);
  const lastWave = useGetLastWave(studyId);
  const getLastWaves = useGetLastWaves(studyId);
  const kpisTypeGroups = useKpiTypeGroups(studyId);
  const isPercentageAbsoluteSwitchEnabled = useIsPopulationNumbersEnabled(studyId);

  useEffect(() => {
    BaseFilterService.setChartType(chartType ?? ChartType.COMPARISON);
  }, [chartType]);

  useEffect(() => {
    BaseFilterService.setAllFilters(allFilters);
  }, [allFilters]);

  useEffect(() => {
    BaseFilterService.setKpis(kpis);
  }, [kpis]);

  useEffect(() => {
    if (activeFilter) {
      BaseFilterService.setActiveFilter(activeFilter);
    }
  }, [activeFilter]);

  useEffect(() => {
    BaseFilterService.setChartValueCallback(setChartValue);
  }, [setChartValue]);

  useEffect(() => {
    BaseFilterService.setValues(values);
  }, [values]);

  // KPI Filter Initialization
  useEffect(() => {
    KPIFilterService.setIsGlobalKpi(isGlobalKPIExist);
  }, [isGlobalKPIExist]);

  useEffect(() => {
    KPIFilterService.filterModal.setKpisTypeGroups(kpisTypeGroups);
  }, [kpisTypeGroups]);

  // Waves Filter initialization
  useEffect(() => {
    WaveFilterService.setLastWave(lastWave);
    WaveFilterService.setLastWaves(getLastWaves(chartType === ChartType.MATRIX ? MATRIX_CHART_DEFAULT_WAVES : DYNAMIC_WAVES_DEFAULT));
  }, [lastWave, chartType, getLastWaves]);

  useEffect(() => {
    BaseFilterService.setPercentageAbsoluteSwitchState(isPercentageAbsoluteSwitchEnabled);
  }, [isPercentageAbsoluteSwitchEnabled]);
};
