import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '@emotion/react';
import { TextVariants } from 'theme/typography';
import { HTMLTag } from 'common/types/common';

interface TypographyProps {
  variant: keyof typeof TextVariants;
  children: React.ReactNode;
  as: string;
  textColor?: keyof Theme['v2']['colours'] | 'inherit';
  fontWeight?: string;
  title?: string;
}

const Typography = styled.div<TypographyProps>`
  ${({ theme, variant = TextVariants.B1, fontWeight }) => `
    font-family: ${theme.v2.text.fontFamily};
    line-height: ${theme.v2.text.variants[variant].lineHeight};
    font-weight: ${fontWeight ? fontWeight : theme.v2.text.variants[variant].fontWeight};
    font-size: ${theme.v2.text.variants[variant].fontSize};
    letter-spacing: ${theme.v2.text.variants[variant].letterSpacing};
  `}

  font-style: normal;
  color: ${({ theme, textColor }) =>
    textColor ? theme.v2.colours[textColor as keyof Theme['v2']['colours']] || textColor : theme.v2.colours.gray500};
`;

export interface TextProps {
  variant: keyof typeof TextVariants;
  children: React.ReactNode;
  color?: keyof Theme['v2']['colours'] | 'inherit';
  className?: string;
  fontWeight?: string;
  title?: string;
  htmlTag?: HTMLTag;
}

export const LatanaText: React.FC<TextProps> = ({
  variant,
  children,
  color,
  className,
  fontWeight,
  title,
  htmlTag = 'div',
  ...otherProps
}) => {
  return (
    <Typography
      as={htmlTag}
      variant={variant}
      textColor={color}
      className={className}
      fontWeight={fontWeight}
      title={title}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};
