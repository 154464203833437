import { atom, selectorFamily } from 'recoil';
import { RawChart } from 'common/types/chart';

import { ChartPayload } from 'common/types/charts';

export const defaultChartPaginationPayload: ChartPayload = {
  country_ids: [],
  brand_ids: [],
  kpi_option_identifiers: [],
  tags: [],
  study_uuids: [],
  chart_name: '',
  page: 1,
  per_page: 50,
  sort_column: 'updated_at',
  sort_direction: 'desc',
};

export const chartPaginationPayload = atom({
  key: 'chartPaginationPayload',
  default: defaultChartPaginationPayload,
});

export const currentChartAtom = atom<RawChart | undefined>({
  key: 'currentChart',
  default: undefined,
});

export const labelWidthsState = atom<number[]>({
  key: 'labelWidthsState',
  default: [],
});

export const shouldRotateState = selectorFamily<boolean, number>({
  key: 'shouldRotateState',
  get:
    (barWidth: number) =>
    ({ get }) => {
      const widths = get(labelWidthsState);
      return widths.some(width => width >= barWidth);
    },
});
