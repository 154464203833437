export interface IGoogleAuthProps {
  grant_type: string;
  provider?: string;
  assertion: string;
}

export interface ILoginFormData {
  email: string;
  password: string;
}

export interface AcceptInvitationResponse {
  email: string;
}

export enum GrantTypes {
  ASSERTION = 'assertion',
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}

export enum Providers {
  LATANA = 'admin_internal',
  GOOGLE = 'google',
}

export enum ScreenType {
  LOGIN = 'login',
  RESET = 'reset',
}
