import React from 'react';
import { DashboardDetails } from 'common/types/dashboard';

export const useChartInDashboards = (dashboardsList: DashboardDetails[] | undefined, chartUuid: string | undefined): boolean => {
  const dashboardChartsUuid = React.useMemo(() => {
    if (!dashboardsList) return [];

    // Extract all chart uuids from all dashboards
    return dashboardsList.reduce((acc, { cards }) => {
      cards.forEach(({ components }) => {
        components.body.forEach(({ uuid }) => {
          if (uuid) {
            acc.push(uuid);
          }
        });
      });
      return acc;
    }, [] as string[]);
  }, [dashboardsList]);

  return React.useMemo(() => {
    if (!chartUuid || !dashboardsList) return false;
    return dashboardChartsUuid.filter(uuid => uuid === chartUuid).length > 1;
  }, [dashboardsList, chartUuid, dashboardChartsUuid]);
};
