import React from 'react';
import { useRawSegments } from 'common/queries/segments';
import SegmentsContent from 'modules/segments/components/SegmentsContent';
import { Fallback } from 'common/styledComponents/loading';
import { Segments } from 'common/types/segments';

const LatanaSegmentsTab = () => {
  const { data: segments, isLoading } = useRawSegments();

  // Assuming that core segments are the same for all studies.
  const coreSegments = React.useMemo(() => {
    /**
      This is a temporary solution to extract only the default segments,
      and it's not fully working becaues non-default segments marked as readable_only.
      We need to find a better way to handle this on the backend as well as on the frontend.
     */
    return segments?.reduce((acc, curr) => {
      acc.push({
        ...curr,
        segments: curr.segments.filter(({ readable_only, extra_characteristics }) => readable_only && !extra_characteristics.length),
      });
      return acc;
    }, [] as Segments[]);
  }, [segments]);

  if (isLoading || !segments?.length) {
    return <Fallback />;
  }

  return <SegmentsContent tabSegments={coreSegments} />;
};

export default LatanaSegmentsTab;
