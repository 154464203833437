import { Wave } from 'common/types/common';
import { useCallback, useMemo } from 'react';
import { useGetAllFilters, useGetStudySpecificRestrictions } from './filters';

export const useGetLastWave = (studyId: string): Wave => {
  const { waves } = useGetStudySpecificRestrictions(studyId);

  return useMemo(() => {
    return waves?.reduce((prev: Wave, curr: Wave) => {
      return Date.parse(prev.date as unknown as string) > Date.parse(curr.date as unknown as string) ? prev : curr;
    }, [] as unknown as Wave);
  }, [waves]);
};

// Get last N of waves
export const useGetLastWaves = (studyId: string) => {
  const { waves } = useGetStudySpecificRestrictions(studyId);

  // Memoize the sorted waves array
  const sortedWaves = useMemo(() => {
    const newWaves = [...waves];
    return newWaves?.sort((a: Wave, b: Wave) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }, [waves]);

  // Create a callback function that returns the IDs of the most recent waves
  const getWaveIds = useCallback(
    (numWaves: number): number[] => {
      if (!sortedWaves) return [];
      return sortedWaves.slice(0, numWaves).map(({ id }) => id);
    },
    [sortedWaves],
  );

  return getWaveIds;
};

export const useGetWavesMap = (): Record<number, Wave> => {
  const { waves } = useGetAllFilters();

  return useMemo(() => {
    return waves.reduce((acc, wave) => {
      acc[wave.id] = wave;
      return acc;
    }, {} as Record<number, Wave>);
  }, [waves]);
};

// Return wave IDs ordered by date, with the oldest first
export const useGetWavesOrderedByDate = (studyId: string, numWaves?: number) => {
  const { waves } = useGetStudySpecificRestrictions(studyId);

  // Memoize the sorted wave IDs, with most recent waves last
  const waveIds = useMemo(() => {
    // Sort waves by date in descending order (most recent first)
    const sortedWaves = [...waves].sort((a: Wave, b: Wave) => new Date(b.date).getTime() - new Date(a.date).getTime());

    // Slice to get the most recent `numWaves` waves and reverse the slice to have most recent last
    return sortedWaves
      .slice(0, numWaves ? numWaves : sortedWaves.length)
      .reverse()
      .map(({ id }) => id);
  }, [waves, numWaves]);

  return waveIds;
};
