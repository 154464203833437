import React from 'react';
import { Theme } from '@emotion/react';
import Icon from 'common/components/Icon';
import { ICONS } from 'icons/iconsMap';
import { CloseButton, ToastBody, ToastContainer, ToastContent, ToastContentText, ToastContentIcon } from './styled';
import { ToastStatus } from 'common/types/notification';
import { DataTestId } from 'settings/constants';

interface Props {
  status: ToastStatus;
  onClose: () => void;
  message: string;
}

interface ToastStatusTheme {
  icon: keyof typeof ICONS;
  color: keyof Theme['v2']['colours'];
}

const Toast = ({ status, message, onClose }: Props): JSX.Element => {
  const toastMode = {
    success: { icon: 'check-circle-fill', color: 'green500' },
    error: { icon: 'x-token-fill', color: 'red500' },
    warning: { icon: 'alert-fill', color: 'orange400' },
    info: { icon: 'info-fill', color: 'blue500' },
  } as Record<ToastStatus, ToastStatusTheme>;

  return (
    <ToastContainer>
      <ToastBody>
        <ToastContent>
          <ToastContentIcon>
            <Icon icon={toastMode[status].icon} color={toastMode[status].color} />
          </ToastContentIcon>
          <ToastContentText data-testid={DataTestId.TOAST_MESSAGE} variant='B2'>
            {message}
          </ToastContentText>
        </ToastContent>
        <CloseButton onClick={onClose}>
          <Icon icon='x' />
        </CloseButton>
      </ToastBody>
    </ToastContainer>
  );
};

export default Toast;
