import React from 'react';
import amplitude from 'amplitude-js';
import { AccountDetails } from 'common/types/account';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { UNDEFINED_TYPE } from 'settings/constants';

type Tracking = {
  init: (entranceSource?: string) => void;
  updateUserProperties: (userDetails: AccountDetails) => void;
  trackEvent: (eventName: string, params?: unknown) => void;
  clearUserProperties: () => void;
};

export const useTracking = (): Tracking => {
  const init = React.useCallback((entranceSource?: string) => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY ?? '');

    //If the user come from email link or other source, store it in user properties
    if (entranceSource) {
      const sourceProperties = new amplitude.Identify().set(TRACKING_EVENTS.ENTRANCE_SOURCE, entranceSource);
      amplitude.getInstance().identify(sourceProperties);
    }
  }, []);

  const updateUserProperties = React.useCallback((userDetails: AccountDetails) => {
    amplitude.getInstance().setUserProperties({
      accountId: userDetails.active_study_uuid,
      userId: userDetails.user.uuid,
      account: userDetails.customer.name,
      internal: userDetails.user.permissions.internal,
      tier: userDetails.customer.tier_name,
    });
  }, []);

  const trackEvent = React.useCallback((eventName: string, { screen, ...otherParams } = {}) => {
    const getScreen = (path: string) => {
      if (!path) return '';
      const segments = path.split('/').filter(segment => segment);
      const [secondLastSegment, lastSegment] = segments.slice(-2);
      return segments.length >= 1 ? secondLastSegment : lastSegment;
    };

    const derivedScreen: string = screen || (typeof window !== UNDEFINED_TYPE ? getScreen(window.location.pathname) : '');

    const eventParams = {
      ...otherParams,
      screen: derivedScreen,
    };

    try {
      amplitude.getInstance().logEvent(eventName, eventParams);
      window.Appcues.track(eventName, eventParams);
    } catch (error) {
      console.error(`Error tracking event "${eventName}":`, error);
    }
  }, []);

  const clearUserProperties = React.useCallback(() => {
    amplitude.getInstance().clearUserProperties();
  }, []);

  return React.useMemo(
    () => ({
      init,
      updateUserProperties,
      trackEvent,
      clearUserProperties,
    }),
    [init, updateUserProperties, trackEvent, clearUserProperties],
  );
};
