import { ChartType } from 'common/types/chart';
import { getAllChartTypes } from 'utils/chart';

export const getChartCreateType = (urlParams: string): ChartType => {
  const allChartTypes = getAllChartTypes();
  const searchParams = new URLSearchParams(urlParams);
  const chartCreateType = searchParams.get('type') as ChartType;
  const isChartTypeValid = allChartTypes.includes(chartCreateType);

  return isChartTypeValid ? chartCreateType : ChartType.COMPARISON;
};
