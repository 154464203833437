import React from 'react';
import { GPHeader, SortOption } from 'common/types/chart';
import { SortController, TableHead, TableHeaderCell, TableHeaderText, TableHeadItem, TableHeadRow } from './styled';
import SortingControler from 'common/components/SortingControler';
import { DataTestId } from 'settings/constants';

interface Props {
  headers: GPHeader[];
  sorting: SortOption[];
  onSortHandle: (key: string) => void;
}

const TableHeader = ({ headers, sorting, onSortHandle }: Props) => {
  return (
    <TableHead>
      <TableHeadRow data-testid={DataTestId.RANKING_TABLE_HEADER}>
        {headers?.map(header => {
          return (
            <TableHeadItem key={`${header.key}-HeaderItem`} align={header.align} width={header.width ? header.width : 'initial'}>
              <TableHeaderCell>
                <TableHeaderText variant='subheading2' color='liquoriceGrey' fontWeight='400'>
                  <span
                    onClick={() => header.key && onSortHandle(header.key)}
                    role='button'
                    data-testid={`${DataTestId.RANKING_TABLE_HEADER_CELL}-${header.key}`}
                  >
                    {header.title}
                  </span>
                </TableHeaderText>
                {header.key && (
                  <SortController onClick={() => header.key && onSortHandle(header.key)}>
                    <SortingControler sorting={sorting} sortKey={header.key} />
                  </SortController>
                )}
              </TableHeaderCell>
            </TableHeadItem>
          );
        })}
      </TableHeadRow>
    </TableHead>
  );
};

export default React.memo(TableHeader);
