import styled from '@emotion/styled';

export const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  gap: ${({ theme }) => theme.v2.space(3)};
  width: 441px;
  margin: 0 auto;
  height: 80vh;
  position: absolute;
  left: calc(50% - 220px);
`;

export const NoResultText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: ${({ theme }) => theme.v2.space(1.5)};
`;

export const NoResultBottom = styled.div`
  display: flex;
  place-content: center;
`;
