import * as React from 'react';
import { Text } from 'common/styledComponents/typography';
import styled from '@emotion/styled';
import TextInput from 'common/components/TextInput';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components/Form/Button';
import { Link } from 'react-router-dom';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.unit * 3}px 0;
  width: 320px;
  &[aria-busy='true'] {
    opacity: 0.92;
    pointer-events: none;
  }
`;

const ResetBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.unit * -2}px 0 ${({ theme }) => theme.spacing.unit * 2}px;
`;

export interface NewPasswordFormData {
  password: string;
}

interface Props {
  onSubmit: (data: NewPasswordFormData) => void;
  message?: string;
  error?: string;
  busy?: boolean;
}

const NewPasswordFom: React.FC<Props> = ({ onSubmit, error, message, busy }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch } = useForm<NewPasswordFormData>({});

  const password = watch('password') || '';
  const valid = password.length > 2;

  return (
    <React.Fragment>
      <Text color='white' variant='h3'>
        {t('passwordReset.title')}
      </Text>
      <br />
      <Text color='white' variant='h5'>
        {t('passwordReset.subtitle')}
      </Text>
      <Form aria-busy={busy} onSubmit={handleSubmit(onSubmit)}>
        {!message ? (
          <TextInput autoFocus label={t('login.newPassword.placeholder')} name='password' type='password' ref={register} required />
        ) : null}
        <ErrorContainer>
          {error ? (
            <Text color='wine' variant='body1'>
              {error}
            </Text>
          ) : null}
          {message ? (
            <Text color='turqoise' variant='body1'>
              {message}
            </Text>
          ) : null}
        </ErrorContainer>
        <FormActions>
          {message ? (
            <>
              <Link to='/login'>
                <Button type='button' color='turqoise'>
                  {t('passwordReset.back')}
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Button disabled={!valid} type='submit' color='turqoise'>
                {t('passwordReset.submit')}
              </Button>
              <Link to='/login'>
                <ResetBtn type='button'>
                  <Text color='white' variant='body1'>
                    {t('passwordReset.back')}
                  </Text>
                </ResetBtn>
              </Link>
            </>
          )}
        </FormActions>
      </Form>
    </React.Fragment>
  );
};

export default React.memo(NewPasswordFom);
