import React, { memo, FC } from 'react';
import { useRecoilState } from 'recoil';
import { theme } from 'common/theme';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import Select from 'common/components/Select';
import { RolesAtom } from 'common/atoms/roles';
import { useTranslation } from 'react-i18next';
import { RoleOption } from 'common/types/roles';
import { MembersListItemProps } from 'common/types/roles';

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.space(2, 0, 2, 3)};
  border: 1px solid ${theme.colors.lightGrey.toString()};
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const Label = styled.span<{ currentUser?: boolean }>`
  border: 1px solid ${({ currentUser }) => (currentUser ? theme.colors.lemon.toString() : theme.colors.lightPurple.toString())};
  padding: ${theme.space(0.25, 0.5)};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  color: ${theme.colors.lightPurple.toString()};
  margin-left: ${theme.space(1)};
`;

const ListItemTitle = styled.div`
  display: flex;
  width: calc(100% - 158px);

  h6 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RemoveUserItem = styled.div`
  color: ${theme.colors.wine.toString()};
  font-size: 14px;
  padding: ${theme.space(2)};
  font-weight: 600;
  cursor: pointer;
`;

const MembersListItem: FC<MembersListItemProps> = ({ email, role_id, id, invitation_pending, currentUser, onHandleUpdate }) => {
  const roles = useRecoilState(RolesAtom);
  const { t } = useTranslation();

  const onHandleChange = (newRole: string) => {
    onHandleUpdate({ id, role_id: newRole, email });
  };

  return (
    <ListItem>
      <ListItemTitle>
        <Text variant='h6' fontWeight='600'>
          {email}
        </Text>
        {invitation_pending && <Label>{t('members.pending')}</Label>}
        {currentUser && <Label currentUser={currentUser}>{t('members.you')}</Label>}
      </ListItemTitle>
      {!currentUser && (
        <Select name='role_id' options={roles[0] as RoleOption[]} value={String(role_id)} onSelect={onHandleChange}>
          <RemoveUserItem onClick={() => onHandleChange('')}>{t('members.removeFromTeam')}</RemoveUserItem>
        </Select>
      )}
    </ListItem>
  );
};

export default memo(MembersListItem);
