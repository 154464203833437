import { DefaultValues, PERCENTAGE_COEF, UNDEFINED_TYPE } from 'settings/constants';
import { roundValue } from 'utils/chart';
import { ChartConfiguration, ChartType } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';
import crypto from 'crypto';
import { LogArgument, LogResult } from 'rollbar';

export const normalizeTitle = (title: string): string => {
  if (!title) return '';
  return title.split(' ').join('_');
};

export const deepValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, pathPart) => {
    return acc[pathPart];
  }, obj);
};

const isObj = (obj: Record<string, string>): boolean => typeof obj === 'object' && obj !== null;

export const deepEquals = (objA: any, objB: any): boolean => {
  const sameType = typeof objA === typeof objB;

  if (!sameType) {
    return false;
  }

  if (Array.isArray(objA) && Array.isArray(objB)) {
    return arrayEquals(objA, objB);
  }
  if (isObj(objA) && isObj(objB)) {
    const check =
      Object.entries(objA).every(([key, value]) => {
        return deepEquals(value, objB[key]);
      }) &&
      Object.entries(objB).every(([key, value]) => {
        return deepEquals(value, objA[key]);
      });
    return check;
  }
  return objA === objB;
};

export const arrayEquals = (arrA: Array<string | number>, arrB: Array<string | number>): boolean => {
  return arrA?.toString() === arrB?.toString();
};

export const getTextSize = (text: string, styles: Record<string, string>): Array<number> => {
  const span = document.createElement('span');
  span.style.display = 'inline-block';
  span.textContent = text;

  Object.entries(styles).forEach(([propName, propValue]) => {
    span.style[propName as unknown as number] = propValue;
  });

  span.style.setProperty('visibility', 'hidden');
  span.style.setProperty('position', 'absolute');

  document.body.append(span);

  const { width, height } = span.getBoundingClientRect();

  span.remove();

  return [width, height];
};

export const isValidEmail = (email: string): boolean => {
  const mailformat =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return email.match(mailformat) ? true : false;
};

export const groupBy =
  <T>(keys: (keyof T)[]) =>
  (array: T[]): Record<string, T[]> =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = keys.map(key => obj[key]).join('-');
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);

type FileHandle = {
  [key: string]: () => Promise<{
    [key: string]: (value?: unknown) => Promise<void>;
  }>;
};

export const writeFileToDisk = async (
  csvFile: unknown,
  fileHandle: { [key: string]: () => Promise<{ [key: string]: (value?: unknown) => Promise<void> }> },
): Promise<FileHandle> => {
  const writable = await fileHandle.createWritable();
  await writable.write(csvFile);
  await writable.close();

  return fileHandle;
};

export const uniqueListItemsByIdentifier = <T>(list: T[], key: string): T[] => {
  if (!Array.isArray(list)) return [];
  return [...new Map(list.map(item => [(item as Record<string, T>)[key], item])).values()];
};

export const capitalize = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 *
 * @param str
 * @param charLimit default value is 16 characters. It may change in future.
 * @returns A string with ellipsis at the last.
 */
export const concatEllipsis = (str = '', charLimit = 16): string => (str.length > charLimit ? str.substring(0, charLimit) + '...' : str);

export const getNumericValue = (value: number, decimalEnabled = false, fallback = 'n/a'): string | number => {
  return !isNaN(value) ? roundValue(value, decimalEnabled) : fallback;
};

export const isNotUndefined = <T>(arg: T): T | false => {
  if (arg === UNDEFINED_TYPE || typeof arg === UNDEFINED_TYPE || !arg) {
    return false;
  }
  return arg;
};

export const normalizeBrandFilters = (brand: number[], chartType: ChartType, isShowAverage: boolean | undefined): Array<number> => {
  if ((chartType === ChartType.RADAR || chartType === ChartType.HEATMAP) && isShowAverage && !brand.length) {
    return [0];
  } else if ((chartType === ChartType.RADAR || chartType === ChartType.HEATMAP) && isShowAverage) {
    return [...brand, 0];
  } else {
    return brand;
  }
};

export const getSecondDimension = (filters: Array<Array<number | string>>, configuration: ChartConfiguration): FilterNames | null => {
  return filters.filter((item: Array<number | string>) => item.length > 1).length > 1 ? configuration?.second_dimension : null;
};

export const allSettledPromise = <T>(
  promises: Promise<T>[],
): Promise<({ status: string; value: T } | { status: string; error: Error })[]> => {
  const promisesWithStatus = promises.map(p =>
    p.then(value => ({ status: 'fulfilled', value })).catch((error: Error) => ({ status: 'rejected', error })),
  );
  return Promise.all(promisesWithStatus);
};

export const openNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const snakeToCamelCase = (chart: string): string => {
  return chart
    .split('_')
    .map((token, i) => {
      if (i === 0) return token.toLowerCase();
      return capitalize(token);
    })
    .join('');
};

export const formatDate = (timestamp: string, withTime = false): string => {
  const date = new Date(timestamp);

  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  if (withTime) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return `${day} ${month} ${year}`;
};

export const uuidGenerator = (length = 12) => {
  return crypto.randomBytes(length).toString('hex');
};

export const calcPercentageValue = (value: number, decimalsEnabled: boolean): number =>
  roundValue(value * PERCENTAGE_COEF, decimalsEnabled);

export const calcAbsoluteValue = (value: number, population: number): number => Math.abs(value) * population;

export const chartValueInPercents = (value: number, isDynamicPositive: boolean | null): string => {
  if (value === 0) return DefaultValues.ZERO_PERCENT;
  if (!value) return DefaultValues.NA;

  const result = `${value}%`;

  if (isDynamicPositive && value > 0) {
    return `+${result}`;
  }

  return result;
};

export const chartValueInMsOrKs = (value: number, isDynamicPositive: boolean | null): string => {
  if (value === 0) return DefaultValues.ZERO;
  if (!value) return DefaultValues.NA;

  const result =
    Math.abs(value) > 999999
      ? `${Math.sign(value) * ((Math.abs(value) / 1000000).toFixed(1) as unknown as number)}m`
      : `${Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1) as unknown as number)}k`;

  if (isDynamicPositive && value > 0) {
    return `+${result}`;
  }

  return result;
};

export const isNumber = (num: unknown) => {
  return num !== undefined && num !== null && typeof num === 'number';
};

/**
 * Measures the width of a text string given a specific font.
 * @param text - The text string to measure.
 * @param font - The CSS font property (e.g., '16px Arial').
 * @returns The width of the text in pixels.
 */
export const measureTextWidth = (text: string, font: string): number => {
  // Create a canvas element (not appended to DOM)
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Failed to get canvas context');
  }

  // Set the font properties
  context.font = font;

  // Measure the text width
  const metrics = context.measureText(text);
  return metrics.width;
};

export const trimString = (str: string, maxLength: number): string => {
  if (!str || str.length === 0) return '';

  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const isNumberOrString = <T>(valueId: T): boolean => typeof valueId === 'string' || typeof valueId === 'number';

export const processError = (e: unknown, logger: (...args: LogArgument[]) => LogResult, warningText: string): void | [] => {
  const error = e as Error & { errors?: string[]; status?: number };
  const isAuthError = error?.status === 401 || error?.status === 422;

  if (isAuthError) {
    console.warn(`${warningText}`, error.errors);
    return [];
  }

  logger(`${warningText}`, e as LogArgument);
};
