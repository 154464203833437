import * as React from 'react';

export interface TooltipContextProps {
  container: HTMLDivElement | null;
  tooltipGroupId: React.MutableRefObject<number | string | undefined> | null;
  tooltipScrollOffset: React.MutableRefObject<number> | null;
}

export const TooltipContext = React.createContext<TooltipContextProps>({
  container: null,
  tooltipGroupId: null,
  tooltipScrollOffset: null,
});

export const useTooltipContext = (): TooltipContextProps => {
  return React.useContext(TooltipContext);
};
