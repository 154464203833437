export const coloursPalette = {
  red900: '#4C120E',
  red800: '#801E18',
  red700: '#B32922',
  red600: '#E6352B',
  red500: '#FF3B30',
  red400: '#FF6259',
  red300: '#FF8983',
  red200: '#FFB1AC',
  red100: '#FFD8D6',
  red50: '#FFEBEA',
  orange900: '#4C2D00',
  orange800: '#804B00',
  orange700: '#B36800',
  orange600: '#F17800',
  orange500: '#FF9500',
  orange400: '#FFAA33',
  orange300: '#FFBF66',
  orange200: '#FFD599',
  orange100: '#FFEACC',
  orange50: '#FFF4E6',
  yellow900: '#4C3D00',
  yellow800: '#806600',
  yellow700: '#B38F00',
  yellow600: '#E6B800',
  yellow500: '#FFCC00',
  yellow400: '#FFD633',
  yellow300: '#FFE066',
  yellow200: '#FFEB99',
  yellow100: '#FFF5CC',
  yellow50: '#FFFAE6',
  green900: '#103C1B',
  green800: '#1A642D',
  green700: '#248B3E',
  green600: '#2FB350',
  green500: '#34C759',
  green400: '#5DD27A',
  green300: '#85DD9B',
  green200: '#AEE9BD',
  green100: '#D6F4DE',
  green50: '#EBF9EE',
  mint900: '#003C39',
  mint800: '#00645F',
  mint700: '#008B85',
  mint600: '#00B3AB',
  mint500: '#00C7BE',
  mint400: '#33D2CB',
  mint300: '#66DDD8',
  mint200: '#99E9E5',
  mint100: '#CCF4F2',
  mint50: '#E6F9F9',
  teal900: '#0E353C',
  teal800: '#185864',
  teal700: '#227B8B',
  teal600: '#2B9EB3',
  teal500: '#30B0C7',
  teal400: '#59C0D2',
  teal300: '#83D0DD',
  teal200: '#ACDFE9',
  teal100: '#D6EFF4',
  teal50: '#EAF7F9',
  cyan900: '#0F3445',
  cyan800: '#195773',
  cyan700: '#2379A1',
  cyan600: '#2D9CCF',
  cyan500: '#32ADE6',
  cyan400: '#5BBDEB',
  cyan300: '#84CEF0',
  cyan200: '#ADDEF5',
  cyan100: '#D6EFFA',
  cyan50: '#EBF7FD',
  blue900: '#00254C',
  blue800: '#003D80',
  blue700: '#0055B3',
  blue600: '#006EE6',
  blue500: '#007AFF',
  blue400: '#3395FF',
  blue300: '#66AFFF',
  blue200: '#99CAFF',
  blue100: '#CCE4FF',
  blue50: '#E6F2FF',
  indigo900: '#1A1A40',
  indigo800: '#2C2B6B',
  indigo700: '#3E3C96',
  indigo600: '#4F4DC1',
  indigo500: '#5856D6',
  indigo400: '#7978DE',
  indigo300: '#9B9AE6',
  indigo200: '#BCBBEF',
  indigo100: '#DEDDF7',
  indigo50: '#EEEEFB',
  indigo25: '#F7F7FF',
  purple900: '#341943',
  purple800: '#58296F',
  purple700: '#7A399B',
  purple600: '#9E4AC8',
  purple500: '#AF52DE',
  purple400: '#BF75E5',
  purple300: '#CF97EB',
  purple200: '#DFBAF2',
  purple100: '#EFDCF8',
  purple50: '#F7EEFC',
  pink900: '#4C0D19',
  pink800: '#80172B',
  pink700: '#B31F3B',
  pink600: '#E6294D',
  pink500: '#FF2D55',
  pink400: '#FF5777',
  pink300: '#FF8199',
  pink200: '#FFABBB',
  pink100: '#FFD5DD',
  pink50: '#FFEAEE',
  gold900: '#31281C',
  gold800: '#51422F',
  gold700: '#715C42',
  gold600: '#927755',
  gold500: '#A2845E',
  gold400: '#B59D7E',
  gold300: '#C7B59E',
  gold200: '#DACEBF',
  gold100: '#ECE6DF',
  gold50: '#F6F3EF',
  gray900: '#141414',
  gray800: '#1F1F1F',
  gray700: '#333333',
  gray600: '#545454',
  gray500: '#666666',
  gray400: '#AFAFAF',
  gray300: '#CBCBCB',
  gray200: '#E2E2E2',
  gray100: '#EEEEEE',
  gray50: '#F6F6F6',
  latanaHover: '#58388D',
  latanaDark: '#340D70',
  latana: '#8F50FF',
  latanaBright: '#E6E4FF',
  latanaBackground: '#F5F5F7',
  latana1000: '#340D70',
  latana900: '#482880',
  latana800: '#563099',
  latana700: '#6438B3',
  latana600: '#8148E6',
  latana500: '#8F50FF',
  latana400: '#A573FF',
  latana300: '#BC96FF',
  latana200: '#D2B9FF',
  latana100: '#E9DCFF',
  latana50: '#F4EEFF',
};

export const greyPalette = {
  gray0: '#FFFFFF',
  gray50: '#F6F6F6',
  gray100: '#EEEEEE',
  gray200: '#E2E2E2',
  gray300: '#CBCBCB',
  gray400: '#AFAFAF',
  gray500: '#666666',
  gray600: '#545454',
  gray700: '#333333',
  gray800: '#1F1F1F',
  gray900: '#141414',
};

export const chartPalette = [
  coloursPalette.blue500,
  coloursPalette.orange400,
  coloursPalette.mint400,
  coloursPalette.indigo500,
  coloursPalette.pink400,
  coloursPalette.purple500,
  coloursPalette.green400,
  coloursPalette.yellow500,
  coloursPalette.gold500,
  coloursPalette.purple700,
  coloursPalette.cyan500,
  coloursPalette.mint700,
  coloursPalette.pink700,
  coloursPalette.red400,
  coloursPalette.pink800,
  coloursPalette.purple300,
  coloursPalette.indigo700,
  coloursPalette.green700,
  coloursPalette.orange600,
  coloursPalette.purple800,
  coloursPalette.blue700,
  coloursPalette.orange200,
  coloursPalette.mint200,
  coloursPalette.indigo200,
  coloursPalette.pink200,
  coloursPalette.purple200,
  coloursPalette.green200,
  coloursPalette.yellow200,
  coloursPalette.gold200,
  coloursPalette.blue200,
];
