export const ACCOUNT_ROUTES = [
  {
    id: 'settings/profile',
    name: 'Settings',
  },
];

export const SETTINGS_ITEMS = [
  {
    id: 'competitors',
    name: 'Competitors',
  },
  {
    id: 'profile',
    name: 'Profile',
  },
  {
    id: 'appearance',
    name: 'Appearance',
  },
  {
    id: 'wave-reports',
    name: 'Wave reports',
  },
  {
    id: 'members',
    name: 'Members',
  },
];

export const SETTINGS_ROUTES = {
  COMPETITORS: 'competitors',
  PROFILE: 'profile',
  KPI_PREFERENCES: 'kpi-preferences',
  WAVE_REPORTS: 'wave-reports',
  APPEARANCE: 'appearance',
  MEMBERS: 'members',
};

export const SEGMENTS_ROUTES = {
  LATANA: 'latana',
  LATANA_WITH_PARAM: 'latana/:segmentId?',
  CUSTOM: 'custom',
  CUSTOM_WITH_PARAM: 'custom/:segmentId?',
};

export const MAIN_ROUTES = {
  ROOT: '/',
  MAIN: '*',
  LOGIN: '/login',
  CHARTS: '/charts',
  DASHBOARDS: '/dashboards',
  INVITE: '/invite/:token',
  SETTINGS: 'settings/*',
  SEGMENTS: 'segments/*',
  DASHBOARD: 'dashboard/:dashboardId',
  CHART_VIEW: '/chart/:chartId',
  CHART_CREATE: '/chart/create',
  NEW_PASSWORD: '/password_resets/:token/edit',
  STUDY_REDIRECT: '/chart/:chartId',
  INTERNAL_LOGIN: 'internal_login',
  OLD_CHART_VIEW: '/study/:studyId/chart/:chartId',
};
