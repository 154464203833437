import styled from '@emotion/styled';
import { ColorCircle } from 'modules/charts/styledComponents';
import { theme } from 'common/theme';
import { Text } from 'common/styledComponents/typography';
import { scrollbarStyles } from 'common/styledComponents/mixins';

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.black.toString()};
  color: ${({ theme }) => theme.colors.white.toString()};
  padding: ${({ theme }) => theme.space(2, 2, 2, 1)};
  border-radius: 8px;
  min-width: 360px;
  max-width: 380px;
  max-height: 100%;
  overflow: hidden;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: ${({ theme }) => theme.space(2)};
  margin-left: ${({ theme }) => theme.space(1)};
  span {
    margin: ${theme.space(0, 1, 0, 0)};
  }
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.regularGrey.toString()};
  font-size: 1.2rem;
  margin-bottom: ${({ theme }) => theme.space(2)};
  margin-top: ${({ theme }) => theme.space(-2)};
  margin-left: ${({ theme }) => theme.space(1)};
`;

export const TooltipItemsContainer = styled.div`
  display: inline-block;
  overflow: auto;

  // Apply the scrollbar styles
  ${scrollbarStyles()}
`;

export const TooltipItem = styled.div<{ highlight?: boolean }>`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: ${({ theme, highlight }) => (highlight ? theme.chart.colorPalette[0].toString() : 'initial')};
  padding: ${theme.space(0.5, 1)};
  border-radius: 3px;

  &:last-child {
    margin: 0;
  }
`;

export const ColorCircleStyled = styled(ColorCircle)`
  margin-right: ${({ theme }) => theme.spacing.unit}px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: ${theme.space(0.5)};
  font-size: 1.2rem;
  svg {
    min-width: 14px;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  span {
    opacity: 0.25;
  }
`;

export const MoeContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.unit * 2}px;
  color: ${({ theme }) => theme.colors.white50.toString()};
  min-width: 50px;
`;

export const Percentage = styled.span`
  margin: ${theme.space(0, 0, 0, 0.5)};
`;

export const EvolutionContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.unit * 2}px;
  min-width: 60px;
  display: flex;
  align-items: center;
  svg {
    margin-right: ${theme.space(1)};
  }

  ${Percentage} {
    opacity: 1;
  }
`;

export const SVGContainer = styled.div`
  padding-top: 2px;
`;

export const TextPills = styled(Text)`
  font-size: ${({ theme }) => theme.text.variants.small.fontSize};
  padding: ${({ theme }) => theme.space(0.5, 1)};
  border-radius: ${({ theme }) => theme.space(2)};
  margin-top: ${({ theme }) => theme.space(0.5)};
  border: ${({ theme }) => `1px solid ${theme.colors.white50}`};
  width: fit-content;
  text-align: center;
  background: ${({ theme }) => theme.colors.white01.toString()};
`;

export const ReferenceTitle = styled.div`
  margin: ${({ theme }) => theme.space(1.5, 0, 1)};
`;
