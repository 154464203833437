import React from 'react';
import styled from '@emotion/styled';
import HeatmapChart from 'modules/charts/components/HeatmapChart';
import { HeatmapData } from 'common/types/heatmap';
import { MIN_CELL_HEIGHT } from 'modules/charts/components/HeatmapChart/Cell';
import { DataTestId } from 'settings/constants';
import { ChartNumbersType } from 'common/types/chart';

const Wrapper30 = styled.div<{ height: number; inPage: boolean; needMorePadding: boolean }>`
  height: ${({ height }) => `${height}px`};
  width: 100%;
  padding: ${({ theme, inPage, needMorePadding }) => theme.space(0, 0, inPage ? (needMorePadding ? 9 : 4) : 9)};
  & svg {
    & g {
      cursor: initial !important;
    }
  }
`;

const ChartContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black.toString()};
  -ms-overflow-style: none;
`;

const WRAPPER_BOTTOM_PADDING = 72;
const MIN_CHART_HEIGHT_IN_PAGE = 300;
const MIN_CHART_HEIGHT = 400;

interface Props {
  data: HeatmapData;
  inPage?: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | undefined>;
  chartNumbersType: ChartNumbersType;
}

const Heatmap: React.FC<Props> = ({ data, inPage = false, containerRef, chartNumbersType = ChartNumbersType.PERCENTAGE }) => {
  const { data: itemsData, keys, xLabel, yLabel } = data;
  const [hasRotatedLabels, setHasBottomPadding] = React.useState(false);
  /* If heatmap data has some missing data, it breaks heatmap color scheme,
		instead, add 0 where value is missing */
  const heatmapData = React.useMemo(() => {
    return itemsData.map(item => {
      if (Object.keys(item).length - 1 < keys.length) {
        const dataItem = { ...item };
        keys.forEach(key => {
          if (!dataItem[key]) {
            dataItem[key] = 0;
          }
        });

        return dataItem;
      } else {
        return item;
      }
    });
  }, [itemsData, keys]);

  const heatmapHeight = React.useMemo(() => {
    const minHeatmapHeight = heatmapData.length * MIN_CELL_HEIGHT + WRAPPER_BOTTOM_PADDING;
    if (inPage) {
      return minHeatmapHeight > MIN_CHART_HEIGHT_IN_PAGE ? minHeatmapHeight : MIN_CHART_HEIGHT_IN_PAGE;
    } else {
      return minHeatmapHeight > MIN_CHART_HEIGHT ? minHeatmapHeight : MIN_CHART_HEIGHT;
    }
  }, [heatmapData, inPage]);
  // If x-axis labels are rotated then chart needs more padding otherwise nominal padding
  const setBottomPadding = React.useCallback(
    (hasRotatedLabels: boolean) => {
      setHasBottomPadding(hasRotatedLabels);
    },
    [setHasBottomPadding],
  );

  const generateHeatMaps = React.useMemo(() => {
    return (
      <Wrapper30 inPage={inPage} height={heatmapHeight} needMorePadding={hasRotatedLabels} data-testid={DataTestId.HEATMAP_CHART}>
        <HeatmapChart
          data={heatmapData}
          keys={keys}
          xLabel={xLabel}
          yLabel={yLabel}
          inPage={inPage}
          containerRef={containerRef}
          setBottomPadding={setBottomPadding}
          chartNumbersType={chartNumbersType}
        />
      </Wrapper30>
    );
  }, [heatmapData, keys, xLabel, yLabel, inPage, containerRef, heatmapHeight, setBottomPadding, hasRotatedLabels, chartNumbersType]);

  return <ChartContainerStyled>{generateHeatMaps}</ChartContainerStyled>;
};

export default React.memo(Heatmap);
