import { LoadingContainer, LoadingLogo } from 'common/styledComponents/containers';
import * as React from 'react';

const LoadingFallback: React.FC = () => {
  return (
    <LoadingContainer>
      <LoadingLogo />
    </LoadingContainer>
  );
};

export default React.memo(LoadingFallback);
