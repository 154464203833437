import React, { Ref } from 'react';
import { theme } from 'common/theme';
import { BarItemProps, GraphicsContainer } from '@nivo/bar';
import { roundedRectangle } from 'modules/charts/utils/svg';
import ChartTooltip from 'modules/charts/components/ChartTooltip';
import { ChartDataPoint, ChartNumbersType, ChartType, TBarTooltip } from 'common/types/chart';
import TooltipRenderer from 'modules/charts/components/ChartTooltip/TooltipRenderer';
import { useAverage } from 'modules/charts/hooks/useAverage';
import RoundedRectangle from 'modules/charts/components/RoundedRectangle';

const MOE_WIDTH = 3;
const DEFAULT_PLACEHOLDER_SIZE = 15;
const TOOLTIP_Y_OFFSET = 290;
const MIN_FILL_HEIGHT = 2;
const DEFAULT_BAR_HEIGHT = 5;
const TOOLTIP_X_OFFSET = 200;
const BAR_VALUE_Y_OFFSET = -8;

interface Props extends BarItemProps {
  barId: number;
  chartType: ChartType;
  moeEnabled?: boolean;
  numericChart: boolean;
  showBarValue: boolean;
  isBarLayout: boolean;
  chartNumbersType: ChartNumbersType;
  visibleGroups: Array<string | number>;
  ref: Ref<SVGTextElement> | undefined;
  shouldRotateLabel: boolean;
}

const CustomBar: React.FC<Props> = React.forwardRef(
  (
    {
      onMouseEnter,
      onMouseLeave,
      showTooltip,
      hideTooltip,
      data,
      x,
      y,
      width,
      height,
      chartType,
      barId,
      visibleGroups,
      moeEnabled,
      numericChart,
      showBarValue,
      isBarLayout,
      chartNumbersType,
      shouldRotateLabel,
    },
    ref,
  ) => {
    const isFunnel = chartType === ChartType.FUNNEL;
    const dataPoint = data.data as unknown as ChartDataPoint;
    const averageValue = data?.data?.[`value_${barId}_averageValue` as 'value_0_averageValue'] || 0;
    const averagePopulationValue = data?.data?.[`value_${barId}_averagePopulationValue` as 'value_0_averagePopulationValue'] || null;
    const referenceValue = data?.data?.referenceValue;
    const averageCalculatedFrom = useAverage(barId, data?.data?.tooltip as unknown as TBarTooltip);
    const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;

    const barHeight = React.useMemo(() => {
      const barFill = dataPoint[`value_${barId}_bar_fill` as 'value_0_bar_fill'];
      return Math.max(barFill * height, DEFAULT_BAR_HEIGHT);
    }, [dataPoint, height, barId]);

    const isGroupVisible = React.useMemo(() => {
      return visibleGroups.includes(data.data.groupId);
    }, [data.data, visibleGroups]);

    const timeoutIdRef = React.useRef<NodeJS.Timeout>();

    const handleTooltipEnter = React.useCallback(() => {
      clearTimeout(timeoutIdRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, timeoutIdRef]);

    const handleTooltipLeave = React.useCallback(() => {
      timeoutIdRef.current = setTimeout(() => {
        hideTooltip();
      }, 200);
    }, [hideTooltip]);

    React.useEffect(() => {
      return () => {
        clearTimeout(timeoutIdRef.current);
      };
    }, []);

    const customChartTooltip = React.useMemo(() => {
      return (
        <TooltipRenderer
          onMouseLeave={handleTooltipLeave}
          onMouseEnter={handleTooltipEnter}
          x={x + TOOLTIP_X_OFFSET}
          y={isFunnel ? TOOLTIP_Y_OFFSET - barHeight : y}
        >
          <ChartTooltip
            funnel={(data?.data as unknown as ChartDataPoint)?.[`funnel_${barId}_tooltip` as 'funnel_0_tooltip']}
            tooltip={(data.data as unknown as ChartDataPoint).tooltip}
            moeEnabled={moeEnabled}
            averageValue={averageValue as number | undefined}
            averageCalculatedFrom={averageCalculatedFrom}
            averagePopulationValue={averagePopulationValue as string}
            chartNumbersType={chartNumbersType}
          />
        </TooltipRenderer>
      );
    }, [
      data.data,
      moeEnabled,
      barId,
      x,
      y,
      handleTooltipEnter,
      handleTooltipLeave,
      isFunnel,
      barHeight,
      averageCalculatedFrom,
      averageValue,
      chartNumbersType,
      averagePopulationValue,
    ]);

    const handleMouseEnter = React.useCallback(
      (e: React.MouseEvent<GraphicsContainer, MouseEvent>) => {
        onMouseEnter(data, e);
        showTooltip(customChartTooltip, e);
        clearTimeout(timeoutIdRef.current);
      },
      [data, customChartTooltip, onMouseEnter, showTooltip],
    );

    const handleMouseLeave = React.useCallback(
      (e: React.MouseEvent<GraphicsContainer, MouseEvent>) => {
        onMouseLeave(data, e);
        timeoutIdRef.current = setTimeout(() => {
          hideTooltip();
        }, 0);
      },
      [onMouseLeave, data, hideTooltip],
    );

    const handleTooltip = React.useCallback(
      e => {
        showTooltip(customChartTooltip, e);
      },
      [showTooltip, customChartTooltip],
    );

    const moeHeight = React.useMemo(() => {
      const moeValue = data.data[`value_${barId}_moe_height` as 'value_0_moe_height'] as number;
      return Number(moeEnabled) * moeValue * height;
    }, [height, moeEnabled, data.data, barId]);

    const fillHeight = React.useMemo(() => {
      return dataPoint[`value_${barId}_funnel_fill` as 'value_0_funnel_fill'] * height - (moeHeight | 0);
    }, [height, barId, dataPoint, moeHeight]);

    const funnelFillRect = React.useMemo(() => {
      const funnelStart = height - moeHeight - barHeight - fillHeight;
      return isGroupVisible && fillHeight > MIN_FILL_HEIGHT ? (
        <>
          <path
            className='funnel-bar'
            d={roundedRectangle(0, funnelStart, width, (fillHeight || 0) + (moeHeight - 1), 4, 4, 0, 0)}
            fill={`url(#${data.data.funnelPattern})`}
            opacity='0.65'
          />
        </>
      ) : null;
    }, [width, fillHeight, data.data, isGroupVisible, barHeight, height, moeHeight]);

    const renderRoundedRect = React.useMemo(() => {
      const barValue = data.data[`value_${barId}` as 'value_0'] as number;
      const unavailable = data.data[`value_${barId}_unavailable` as 'value_0_unavailable'];

      if (unavailable || !barValue) return null;

      const calculatedHeight = height - barHeight;
      const barCornerRadius = funnelFillRect ? 0 : 4;
      const barColor = data.data[`value_${barId}_color` as 'value_0_color'] as string;
      const barPopulationValue = data.data[`value_${barId}_populationNumberValue`] as string;
      const yAxisValue = calculatedHeight + BAR_VALUE_Y_OFFSET;
      const xAxisValue = width / 2;

      const isShowBarValue = showBarValue && !isBarLayout && isGroupVisible;

      return (
        <RoundedRectangle
          ref={ref}
          barWidth={width}
          barColor={barColor}
          barHeight={barHeight}
          moeHeight={moeHeight}
          moeStatus={moeEnabled}
          xAxisValue={xAxisValue}
          yAxisValue={yAxisValue}
          isNumericChart={numericChart}
          cornerRadius={barCornerRadius}
          population={barPopulationValue}
          isShowBarValue={isShowBarValue}
          dataGroupId={data.data.groupId}
          barValue={barValue || data?.value}
          calculatedHeight={calculatedHeight}
          shouldRotateLabel={shouldRotateLabel}
          isShowPopulationNumbers={showPopulationNumbers}
        />
      );
    }, [
      ref,
      width,
      height,
      data.data,
      barId,
      barHeight,
      shouldRotateLabel,
      funnelFillRect,
      data.value,
      isGroupVisible,
      numericChart,
      showPopulationNumbers,
      isBarLayout,
      showBarValue,
      moeEnabled,
      moeHeight,
    ]);

    const MoeGraph = React.useMemo(() => {
      const moeValue = data.data[`value_${barId}_moe_height` as 'value_0_moe_height'] as number;

      if (!moeValue) return;

      const moeHeight = 2 * moeValue * (isBarLayout ? width : height);
      const moeStart = height - barHeight - moeHeight / 2;
      const moeEnd = moeStart + moeHeight;
      const moeXpos = width / 2;
      const getGroupStyle = isBarLayout ? { transform: `translate(${width}px, ${(height - width) / 2}px) rotate(90deg)` } : {};

      return (
        <g style={getGroupStyle}>
          <line x1={moeXpos - MOE_WIDTH} y1={moeStart} x2={moeXpos + MOE_WIDTH} y2={moeStart} stroke={theme.colors.black50.toString()} />
          <line x1={moeXpos} y1={moeStart} x2={moeXpos} y2={moeEnd} stroke={theme.colors.black50.toString()} />
          <line x1={moeXpos - MOE_WIDTH} y1={moeEnd} x2={moeXpos + MOE_WIDTH} y2={moeEnd} stroke={theme.colors.black50.toString()} />
        </g>
      );
    }, [barId, data, height, width, isBarLayout, barHeight]);

    const placeholderSize = height < DEFAULT_PLACEHOLDER_SIZE ? DEFAULT_PLACEHOLDER_SIZE : 0;

    return (
      <>
        <g
          className='bar'
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleTooltip}
          onMouseLeave={handleMouseLeave}
          transform={`translate(${x},${y})`}
        >
          {placeholderSize ? <rect fill='transparent' x={0} y={-placeholderSize} width={width} height={placeholderSize} /> : null}
          {referenceValue && referenceValue !== data?.value && funnelFillRect}
          {isGroupVisible && renderRoundedRect}
          {moeEnabled && MoeGraph}
        </g>
      </>
    );
  },
);

CustomBar.displayName = 'CustomBar';

export default React.memo(CustomBar);
