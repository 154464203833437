import * as React from 'react';
import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { textVariantsTypes } from 'common/styledComponents/typography';
import { useFormContext } from 'react-hook-form';
import { markdownParser } from 'utils/markdown';
/* eslint-disable  @typescript-eslint/no-non-null-assertion */

const textStyles = (variant: textVariantsTypes) => `
font-family: ${theme.text.fontFamily};
line-height: ${theme.text.lineHeight};
font-weight: ${theme.text.variants[variant].fontWeight};
font-size: ${theme.text.variants[variant].fontSize};
`;

const Container = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

const HiddenText = styled.span<{
  variant: textVariantsTypes;
}>`
  position: absolute;
  left: -9999px;
  top: -9999px;
  min-width: 42px;
  ${({ variant }) => textStyles(variant)}
  padding: ${theme.space(0, 1.5)}
`;

const OutputText = styled.span<{
  variant: textVariantsTypes;
}>`
  min-width: 42px;
  ${({ variant }) => textStyles(variant)}
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  min-width: 42px;
  display: inline-block;
  white-space: nowrap;
  width: 300px;
  background: transparent;
  padding: ${theme.space(1, 1.5)};
  border-radius: 8px;
  transition: background 0.1s ease-in;
  width: auto;
  min-width: 8px;
  outline: none;
  border: none;
  &:hover,
  &:focus {
    transition: background 0.1s ease-in;
    background: ${theme.colors.black05.toString()};
  }
`;

const TextField = styled.input<{
  variant: textVariantsTypes;
}>`
  ${({ variant }) => `
  max-width: 100%;
  overflow: hidden;
  min-width: 42px;
  display: inline-block;
  opacity: 0;
  white-space: nowrap;
  width: 300px;
  background: transparent;
  padding: ${theme.space(1, 1.5)};
  border-radius: 8px;
  transition: background 0.1s ease-in;
  width: auto;
  min-width: 8px;
  outline: none;
  border: none;
  ${textStyles(variant)}
  &:hover,
  &:focus {
    transition: background 0.1s ease-in;
    background: ${theme.colors.black05.toString()};
  }
`}
`;

interface Props {
  name: string;
  variant: textVariantsTypes;
  required?: boolean | undefined;
  ref?: React.ForwardedRef<unknown>;
  placeholder?: string;
  onFocus?: () => void;
  onEnter?: () => void;
  onBlur?: () => void;
}

const MinWidth = 240;

// eslint-disable-next-line react/display-name
const GrowingTextField: React.FC<Props> = React.forwardRef(({ variant, onBlur, onEnter, onFocus = null, ...other }, ref) => {
  // Note: depends on React HookForm Context
  const { watch } = useFormContext();
  const { name } = other;

  const fieldValue = watch(name);
  const hiddenTextRef = React.useRef<HTMLSpanElement>();
  const hiddenTextCloneRef = React.useRef<HTMLSpanElement>();
  const containerRef = React.useRef<HTMLDivElement>();
  const inputRef = React.useRef<HTMLInputElement>();
  const outputTextRef = React.useRef<HTMLSpanElement>();

  // set input ref manually, since the ref passed to input comes from hook form
  React.useEffect(() => {
    inputRef.current = containerRef.current!.querySelector('input')!;
  }, []);

  // clone the hidden text span and append the clone to the body
  React.useEffect(() => {
    const clone = hiddenTextRef.current!.cloneNode() as HTMLSpanElement;
    hiddenTextCloneRef.current = clone;
    document.body.appendChild(clone);
    return () => {
      clone.remove();
    };
  }, []);

  // update the hidden text elemnt with input text
  React.useEffect(() => {
    const textRef = hiddenTextCloneRef.current!;
    textRef.innerHTML = markdownParser(fieldValue?.replace(/\s/g, '&nbsp;'));
    inputRef.current!.style.width = Math.max(textRef.offsetWidth, MinWidth) + 'px';
    outputTextRef.current!.innerHTML = markdownParser(fieldValue?.replace(/\s/g, '&nbsp;'));
  }, [fieldValue]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        (document.activeElement as HTMLElement).blur();
        if (onEnter) onEnter();
      }
    },
    [onEnter],
  );

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) return onFocus();
      e.currentTarget.select();
    },
    [onFocus],
  );

  /** Rewrite this logic for common input */
  const onBlurHandle = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) return onBlur();
      e.currentTarget.style.opacity = '0';
      outputTextRef.current!.style.visibility = 'visible';
    },
    [onBlur],
  );

  const onOutputClick = React.useCallback(() => {
    // Toggle visibility
    outputTextRef.current!.style.visibility = 'hidden';
    inputRef.current!.style.opacity = '1';

    // Focus on the input
    inputRef.current!.focus();
  }, []);

  return (
    <Container ref={containerRef as React.MutableRefObject<HTMLDivElement>}>
      <HiddenText variant={variant} ref={hiddenTextRef as React.MutableRefObject<HTMLSpanElement>}></HiddenText>
      <TextField
        id='chartName'
        onBlur={onBlurHandle}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        variant={variant}
        {...other}
        type='text'
        ref={ref as React.LegacyRef<HTMLInputElement> | undefined}
      />
      <OutputText variant={variant} onClick={onOutputClick} ref={outputTextRef as React.MutableRefObject<HTMLSpanElement>}></OutputText>
    </Container>
  );
});

export default React.memo(GrowingTextField);
