import { createContext, useContext } from 'react';

interface Props {
  isSelectionModalOpen: boolean;
  toggleStudySelectionModal: (state: boolean, hash?: string) => void;
}

export const StudyContext = createContext<Props>({
  isSelectionModalOpen: false,
  toggleStudySelectionModal: () => null,
});

export const useStudyContext = (): Props => {
  return useContext(StudyContext);
};
