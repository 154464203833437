export enum TextVariants {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  L1 = 'L1',
  L2 = 'L2',
}

/** TYPOGRAPHY
 * FONT-WEIGHT: BOLD = 700, SEMI-BOLD = 600, MEDIUM = 500
 */
export const typography = {
  /** HEADLINES */
  [TextVariants.H1]: {
    fontSize: '3.2rem',
    fontWeight: 700,
    lineHeight: '4rem',
    letterSpacing: 0,
  },
  [TextVariants.H2]: {
    fontSize: '2.4rem',
    fontWeight: 700,
    lineHeight: '3.2rem',
    letterSpacing: '0',
  },
  [TextVariants.H3]: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.8rem',
    letterSpacing: '0.01rem',
  },
  /** TITLES */
  [TextVariants.T1]: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.8rem',
    letterSpacing: '0',
  },
  [TextVariants.T2]: {
    fontSize: '1.6rem',
    fontWeight: 700,
    lineHeight: '2rem',
    letterSpacing: '0.01rem',
  },
  [TextVariants.T3]: {
    fontSize: '1.4rem',
    fontWeight: 700,
    lineHeight: '2rem',
    letterSpacing: '0.01rem',
  },
  /** BODY */
  [TextVariants.B1]: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '2.4rem',
    letterSpacing: '0',
  },
  [TextVariants.B2]: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '2rem',
    letterSpacing: '0.01rem',
  },
  [TextVariants.B3]: {
    fontSize: '1.2rem',
    fontWeight: 500,
    lineHeight: '1.6rem',
    letterSpacing: '0.01rem',
  },
  /** LABELS */
  [TextVariants.L1]: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '2rem',
    letterSpacing: '0.02rem',
  },
  [TextVariants.L2]: {
    fontSize: '1.2rem',
    fontWeight: 600,
    lineHeight: '1.6rem',
    letterSpacing: '0.02rem',
  },
};
