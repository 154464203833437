import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { RawChartSchema } from 'common/types/chart';
import { SortingKey } from 'common/types/common';
import { DynamicFilters } from 'common/types/filters';

export interface SearchCriteria {
  country_ids: Array<number>;
  brand_ids: Array<number>;
  kpi_option_identifiers: Array<string>;
  study_uuids: Array<string>;
  tags: Array<string>;
}

export interface TransformedFilterProps {
  label: string;
  value: number | string;
}

export interface FilterProps {
  id?: number;
  uuid?: string;
  name?: string;
  kpi_identifier?: string;
  kpi_label?: string;
}

export interface FilterOptionsMap {
  country_ids: TransformedFilterProps[];
  brand_ids: TransformedFilterProps[];
  kpi_option_identifiers: TransformedFilterProps[];
  study_uuids: TransformedFilterProps[];
  tags: TransformedFilterProps[];
}

export enum FilterType {
  STUDIES = 'study_uuids',
  MARKETS = 'country_ids',
  BRANDS = 'brand_ids',
  KPI = 'kpi_option_identifiers',
  FOLDER = 'tags',
}

interface ChartsPagination {
  current_page: number;
  next_page: number;
  prev_page: null | number;
  total_count: number;
  total_pages: number;
}

interface ChartsSorting {
  sort_column: string;
  sort_direction: string;
}

export interface PaginatedCharts {
  records: RawChartSchema[];
  pagination: ChartsPagination;
  sorting: ChartsSorting;
}

export type FetchNextPage = (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<PaginatedCharts, unknown>>;

export interface ChartPayload extends SearchCriteria {
  chart_name?: string;
  page?: number;
  per_page?: number;
  sort_column: string;
  sort_direction: string;
}

export enum ListItemType {
  CHARTS = 'charts',
  DASHBOARDS = 'dashboards',
}

export interface TableListHeaderProps {
  onColumnSort: (columnName: SortingKey) => void;
  sortingKey: string;
  sortingType: string;
}

export type ChartValue = Array<number | string> | DynamicFilters | null | boolean | number;
