import { RawChartSchema, SortType } from 'common/types/chart';
import { Card } from 'common/types/dashboard';
import { ICONS } from 'icons/iconsMap';
import { DataTestId } from 'settings/constants';

// BE data, need to convert date from string to date object
export interface WavePayload {
  id: number;
  name: string;
  date: string | Date;
}

export interface Wave {
  id: number;
  name: string;
  date: Date;
  color?: string;
}

interface WaveAvailability {
  available_for_all_waves: boolean;
  wave_ids: number[];
}

export interface Brand {
  id: number;
  name: string;
  logo: string;
  global?: boolean;
  wave_availability: WaveAvailability;
}

export interface BrandPayload {
  id: number;
  name: string;
  global: boolean;
  countries: string[];
  primary: boolean;
}

export interface Country {
  id: number;
  code: string;
  name: string;
  color?: string;
  population?: number;
}

interface KPIOption {
  option_identifier: string;
  option_label: string;
  option_order: number | null;
}

export enum KPILabel {
  AIDED_BRAND_AWARENESS = 'Aided Brand Awareness',
  CODECADEMY_STUDIES = 'Codecademy Studies',
  AMORELIE = 'Amorelie',
}

export enum KPIType {
  PERCENTAGE = 'percentage',
  ABSOLUTE = 'absolute',
}

interface KPIFormula {
  kpi_identifier: string;
  kpi_label: string;
  max_value: number;
  min_value: number;
  value_type: KPIType;
}
export interface KPI {
  [x: string]: unknown;
  display_format: string;
  kpi_identifier: string;
  kpi_label: string;
  question_options: null | KPIOption[];
  global: boolean;
  question_text: string;
  kpi_formulas: KPIFormula[] | null;
}

export interface KPIS {
  study_uuid: string;
  kpis: KPI[];
}

export interface WaveReport {
  id: number;
  title: string;
  link_to_pdf: string;
  created_at: string;
  is_new: boolean;
  period: Date | string;
}

export interface WaveSummary {
  study_uuid: string;
  wave_summaries: WaveReport[];
}

export interface PopoverItemProps {
  name: string;
  action?: () => void;
  showToggle?: boolean;
  toggleEnabled?: boolean;
  toggleAction?: (enabled: boolean) => void;
  tooltip?: React.ReactNode;
}

export interface FetchOptions {
  method: string;
  body?: Record<string, unknown> | RawChartSchema;
  query?: Record<string, any>;
  headers?: Record<string, string>;
}

export interface ChartFields {
  country: (string | number)[];
  wave: (string | number)[];
  kpi: (string | number)[];
  brand: (string | number)[];
  segment: (string | number)[];
}

export enum KPIOptionType {
  OPTION = 'option',
  GROUP = 'group',
}

export type IFetchMethod = <T>(url: string, query?: FetchOptions['query']) => Promise<T>;
export type IFetchMethods = Record<string, IFetchMethod>;

export enum CSVExportStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  PENDING = 'pending',
}

export interface KPIGroups {
  percentage: Array<string>;
  absolute: Array<string>;
}

export type MoeModalDataProps = { firstDimensionId: number; secondDimensionId: string; populationValue: number };

export type BrandColorMapType = Record<string, string>;
export type SegmentColorMapType = Record<string, string>;

type BrandByCountries = Record<string, Array<number>>;
export interface BrandGroups {
  id: number | string;
  name: string;
  group_type: BrandGroupType;
  brand_ids_by_country: BrandByCountries;
}

export enum BrandGroupType {
  PRIMARY = 'primary',
  COMPETITORS = 'competitors',
}

export type CountryPayload = {
  study_uuid: string;
  countries: Array<Country>;
};

export type Owner = {
  name: string;
  type: string;
};

export interface TableListItemProps {
  name: string;
  uuid: string;
  updated_at: string;
  owner?: Owner;
  current_view?: string;
  chart_type?: string;
  // Only if items are chart list items
  study_uuid?: string;
  cards?: Card[];
}

export enum SortingKey {
  NAME = 'name',
  OWNER = 'owner',
  UPDATED_AT = 'updated_at',
}

export type SortingCriteria = {
  key: SortingKey;
  type: SortType;
};

export interface TableRowItemProps {
  listItemData: TableListItemProps;
  style: React.CSSProperties;
  onClick: (chartId: string, studyId: string) => void;
  onSelect: (actions: string[], item: TableListItemProps) => void;
}

export type HTMLTag = 'div' | 'span' | 'p' | 'h1' | 'h2'; // Add more tags if needed

export interface NavButtonProps {
  icon: keyof typeof ICONS;
  label: string;
  disabled?: boolean;
  action?: () => void;
  url?: string;
  divider?: boolean;
  testId?: DataTestId;
}

export enum LegendItemStatus {
  DEFAULT = 'DEFAULT',
  CHECKED = 'CHECKED',
  UNCHECKED = 'UNCHECKED',
}
