import styled from '@emotion/styled';
import { ArrowUp, CheckmarkIconPurple, LinkIcon as LinkIconBase } from 'icons';
import { Text } from 'common/styledComponents/typography';

export const OptionLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropDownItemIconCheck = styled.div`
  background: url('${CheckmarkIconPurple}') no-repeat center center;
  border: none;
  width: 10px;
  height: 8px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.unit}px;
`;

export const OptionsHeader = styled.div`
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  padding: 16px;
`;

export const Option = styled.option``;

export const SelectWrapper = styled.select`
  border: 0px;
  outline: 0px;
  color: ${({ theme }) => theme.colors.black.toString()};
  font-size: ${({ theme }) => theme.text.variants.body2.fontSize};
  font-family: ${({ theme }) => theme.text.fontFamily};
  padding-right: ${({ theme }) => theme.spacing.padding}px;
`;

export const SubLabel = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 0.25);
  margin-top: 4px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Label = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  width: 150px;

  .html-select {
    display: none;
  }
  .custom-select-wrapper {
    position: relative;
    user-select: none;
    width: 100%;
  }
  .custom-select {
    padding: 8px 32px 8px 12px;
  }
  .custom-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  .custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    background: #ffffff;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
    overflow-y: auto;
    width: 212px;
  }
  .custom-select.open .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    margin-top: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
  .custom-option {
    position: relative;
    display: block;
    cursor: pointer;
    transition: all 0.5s;
    padding: 8px 16px;
  }
  .option-container:hover {
    .custom-option {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .arrow {
    background: url('${ArrowUp}') no-repeat center center;
    border: none;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    cursor: pointer;
    display: inline-block;
    margin-left: ${({ theme }) => theme.spacing.unit}px;
    transform: rotate(180deg);
    z-index: 1;
  }
  .open .arrow {
    transform: rotate(360deg);
  }
`;

export const FaqLinkWrapper = styled.div`
  padding: ${({ theme }) => theme.space(1, 2)};

  a {
    display: block;
  }
`;

export const FaqLink = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
  cursor: pointer;
  display: inline-block;
`;

export const LinkIcon = styled.i`
  background: url('${LinkIconBase}') no-repeat center center;
  width: 16px;
  height: 16px;
  background-size: cover;
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.unit}px;
`;
