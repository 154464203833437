import styled from '@emotion/styled';
import { tooltipOnHover } from 'common/styledComponents/modals';
import { DataTestId } from 'settings/constants';

const SegmentsAsteriskWrapper = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.v2.space(1)};
`;
const YellowSquare = styled.span`
  background: ${({ theme }) => theme.colors.lemon.toString()};
  width: 5px;
  display: inline-block;
  height: 5px;
  margin-bottom: 3px;
`;

const Label = styled.label`
  margin: 4px;
  width: -moz-fit-content;
  width: fit-content;

  position: relative;

  &[data-hover] {
    ${tooltipOnHover('bottom')}
  }

  #${DataTestId.SEGMENT_INFO_HOVER} {
    display: block;
  }
`;

const Button = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black.toString()};
  justify-content: center;
  padding: ${({ theme }) => theme.v2.space(1.25, 2, 1.25, 2)};
  height: 36px;
  font-size: 1.4rem;
  white-space: nowrap;
  border-radius: 30px;
  border: 1px solid rgba(226, 226, 226, 1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.01s ease-in-out;
    color: ${({ theme }) => theme.colors.purpleIllusionist.toString()};
    border-color: ${({ theme }) => theme.colors.purpleIllusionist.toString()};
  }

  &[data-all='true'] {
    color: ${({ theme }) => theme.colors.lightPurple.toString()};
    border-color: ${({ theme }) => theme.colors.lightPurple1.alpha(0.5).toString()};
  }
`;

const CustomInput = styled.input<{ opaqueWhenDisabled?: boolean }>`
  display: none;
  &:disabled + ${Button} {
    opacity: 0.4;
    pointer-events: none;
  }

  &:checked + ${Button} {
    transition: background 0.2s ease-in-out;
    background: rgba(52, 13, 112, 1);
    color: ${({ theme }) => theme.colors.white.toString()};
  }

  &:checked:disabled + ${Button} {
    opacity: ${({ opaqueWhenDisabled }) => (opaqueWhenDisabled ? 1 : 0.2)};
  }

  &:checked + .tooltip-content {
    > .tooltip-children {
      > ${Button} {
        transition: background 0.2s ease-in-out;
        background: ${({ theme }) => theme.colors.black.toString()};
        color: ${({ theme }) => theme.colors.white.toString()};
      }
    }
  }
  }
`;

const SVGWrapper = styled.div`
  display: flex;
  align-items: center;
  svg,
  img {
    width: 16px;
    height: 16px;
  }
`;

export { YellowSquare, SegmentsAsteriskWrapper, Label, CustomInput, Button, SVGWrapper };
