import { useMemo } from 'react';
import { FilterProps, FilterOptionsMap, FilterType } from 'common/types/charts';
import { mapFilterOptions, restructureKPIList } from 'utils/charts';
import { uniqueListItemsByIdentifier } from 'utils/helpers';
import { useGetAllBrands } from 'common/queries/brands';
import { useGetAllCountries } from 'common/queries/countries';
import { useGetAllKpis } from 'common/queries/kpis';
import { useGetAllStudies } from 'common/queries/studies';
import { useTags } from 'common/queries/tags';

const useFilterOptions = (): FilterOptionsMap => {
  const { data: studies } = useGetAllStudies();
  const { data: kpi } = useGetAllKpis();

  const { data: tagsList } = useTags();
  const { data: brandsList } = useGetAllBrands();
  const { data: countriesList } = useGetAllCountries();
  const filterOptionsMap = useMemo(() => {
    const filterOptions = {} as FilterOptionsMap;
    Object.values(FilterType).forEach(filterName => {
      let filter: FilterProps[] = [];

      const sortFiltersAlphabetically = (filterOptions: FilterProps[]): FilterProps[] => {
        return filterOptions.slice().sort((a, b) => {
          return (a?.name as string).localeCompare(b?.name as string);
        });
      };

      const notGlobalBrands = brandsList?.filter(({ global }) => !global) || [];
      const flattenedCountries = countriesList?.flatMap(country => [...country.countries]) as FilterProps[];
      const uniqueCountriesList = uniqueListItemsByIdentifier(flattenedCountries, 'id');
      const flattenedKpis = restructureKPIList(kpi?.flatMap(item => [...item.kpis]));
      const uniqueKpisList = uniqueListItemsByIdentifier(flattenedKpis, 'kpi_identifier');
      // Create a map of tags to be used in the filter dropdown. Root folder should be shown as 'No Folder' in the dropdown.
      const tagsListToMap = (tagsList || []).map(tag => ({
        name: tag === 'root' ? 'No Folder' : tag,
        id: tag,
      })) as unknown as FilterProps[];

      switch (filterName) {
        case FilterType.MARKETS:
          filter = sortFiltersAlphabetically(uniqueCountriesList);
          break;
        case FilterType.BRANDS:
          filter = notGlobalBrands;
          break;
        case FilterType.KPI:
          filter = uniqueKpisList;
          break;
        case FilterType.STUDIES:
          filter = sortFiltersAlphabetically(studies || []);
          break;
        case FilterType.FOLDER:
          filter = tagsListToMap;
          break;
        default:
          break;
      }

      filterOptions[filterName] = mapFilterOptions(filter);
    });
    return filterOptions;
  }, [countriesList, brandsList, kpi, studies, tagsList]);

  return filterOptionsMap;
};

export default useFilterOptions;
