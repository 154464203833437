import { ToastAtom } from 'common/atoms/notifications';
import { ToastContent } from 'common/types/notification';
import { useSetRecoilState } from 'recoil';
import { uuidGenerator } from 'utils/helpers';

export const useShowToast = () => {
  const setToast = useSetRecoilState(ToastAtom);
  return (toast?: ToastContent) => {
    if (toast) {
      setToast(toasts => [...toasts, { id: uuidGenerator(), message: toast.message, status: toast.status }]);
    }
  };
};
