import * as React from 'react';
import { FilterNames } from 'common/types/filters';
import { TBarTooltip } from 'common/types/chart';

type Average = {
  segment: string;
  kpi: string;
};

export const useAverage = (barIndex: number, barTooltip: TBarTooltip, groupIndex?: number): Average => {
  return React.useMemo(() => {
    if (!barIndex && !barTooltip) return { segment: '', kpi: '' };

    const { groupBy, title, segments, kpis } = barTooltip;
    const index = groupIndex || barIndex;

    const segment = groupBy === FilterNames.SEGMENT ? title : segments?.[index] || segments?.[0];
    const kpi = groupBy === FilterNames.KPI ? title : kpis?.[index] || kpis?.[0];

    return { segment, kpi };
  }, [barTooltip, barIndex, groupIndex]);
};
