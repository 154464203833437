import * as React from 'react';
import { createPortal } from 'react-dom';
import { TooltipChildrenContainer, TooltipContainer, TooltipHoverContent } from './styled';

interface Props {
  children: React.ReactNode;
  content: React.ReactNode | (() => React.ReactNode);
  testId?: string;
  enabled?: boolean;
  className?: string;
  delay?: number;
  orientation?: 'vertical' | 'horizontal';
  arrow?: boolean;
  arrowColor?: string;
  renderInBody?: boolean;
  leftOffset?: number;
}

const Tooltip: React.FC<Props> = ({
  children,
  content,
  testId = 'hover-tooltip',
  enabled = true,
  delay = 200,
  className,
  renderInBody,
  leftOffset = 0,
  orientation = 'vertical', // orientation vertical - will position tooltip above/below hover content
  // horizontal will position it on the left/right side of the content
}) => {
  const [verticalAnchor, setVerticalAnchor] = React.useState<'top' | 'bottom'>();
  const [horizontalAnchor, setHorizontalAnchor] = React.useState<'left' | 'right'>();
  const [anchorOrientation, setAnchorOrientation] = React.useState(orientation);
  const [modalInPortalVisible, setModalInPortalVisible] = React.useState(false);

  const renderContent = typeof content === 'function' ? content() : content;

  // disable if there is no content
  enabled = enabled && renderContent;

  // convert to seconds
  delay = delay / 1000;

  const hoverContainerRef = React.useRef<HTMLDivElement>();
  const containerRef = React.useRef<HTMLDivElement>();

  const calculateOffset = React.useCallback(() => {
    const hoverContainer = hoverContainerRef.current;
    const container = containerRef.current;
    if (hoverContainer && container) {
      const { top: containerTop, left: containerLeft, height: containerHeight, width: containerWidth } = container.getBoundingClientRect();

      const { height: hoverHeight, width: hoverWidth } = hoverContainer.getBoundingClientRect();

      const viewPortYStart = window.scrollY;
      const viewPortYEnd = viewPortYStart + window.innerHeight;
      const viewPortXStart = window.scrollX;
      const viewPortXEnd = viewPortXStart + window.innerWidth;

      const bottomVisible = viewPortYEnd > containerTop + containerHeight + hoverHeight;
      const topVisible = containerTop - hoverHeight > viewPortYStart;

      const leftVisible = containerLeft - hoverWidth > viewPortXStart;
      const rightVisible = viewPortXEnd > containerLeft + containerWidth + hoverWidth;

      const containerXCenter = containerLeft + containerWidth / 2;
      const containerYCenter = containerTop + containerHeight / 2;

      const viewPortXCenter = viewPortXStart + window.innerWidth / 2;
      const viewPortYCenter = viewPortYEnd + window.innerHeight / 2;

      if (orientation === 'horizontal') {
        if (!leftVisible && !rightVisible) {
          setAnchorOrientation('vertical');
        }
      }

      // trying to keep the hover content to center
      const preferedHorizontalAnchor = containerXCenter > viewPortXCenter ? 'right' : 'left';
      const preferedVerticalAnchor = containerYCenter > viewPortYCenter ? 'top' : 'bottom';

      if (preferedHorizontalAnchor === 'right') {
        setHorizontalAnchor(leftVisible ? 'right' : 'left');
      } else {
        setHorizontalAnchor(rightVisible ? 'left' : 'right');
      }

      if (preferedVerticalAnchor === 'top') {
        setVerticalAnchor(topVisible ? 'bottom' : 'top');
      } else {
        setVerticalAnchor(bottomVisible ? 'top' : 'bottom');
      }
    }
  }, [orientation]);

  const handleResize = React.useCallback(() => {
    setVerticalAnchor(undefined);
    setHorizontalAnchor(undefined);
    setAnchorOrientation(orientation);
  }, [orientation]);

  const handleMouseOver = React.useCallback(() => {
    setModalInPortalVisible(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setModalInPortalVisible(false);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const renderHoverContent = React.useMemo(() => {
    const topPosition = (renderInBody && containerRef.current?.getBoundingClientRect().top) ?? undefined;
    const leftPosition = (renderInBody && containerRef.current?.getBoundingClientRect().left) ?? undefined;
    const height = (renderInBody && containerRef.current?.getBoundingClientRect().height) || 0;
    const top = topPosition ? topPosition + height : undefined;

    return (
      <>
        {enabled ? (
          <TooltipHoverContent
            data-orientation={anchorOrientation}
            className={className}
            data-testid={testId}
            data-v-anchor={verticalAnchor}
            data-h-anchor={horizontalAnchor}
            top={top}
            left={leftOffset && renderInBody ? Number(leftPosition) - leftOffset : leftPosition}
            ref={hoverContainerRef as React.MutableRefObject<HTMLDivElement>}
            visible={modalInPortalVisible}
          >
            {renderContent}
          </TooltipHoverContent>
        ) : null}
      </>
    );
  }, [
    anchorOrientation,
    className,
    enabled,
    horizontalAnchor,
    renderContent,
    testId,
    verticalAnchor,
    renderInBody,
    modalInPortalVisible,
    leftOffset,
  ]);

  const renderModalContentPortal = React.useMemo(() => {
    return createPortal(renderHoverContent, document.getElementById('root') ?? document.body);
  }, [renderHoverContent]);

  const handleMouseEnter = () => {
    calculateOffset();
    if (renderInBody) {
      handleMouseOver();
    }
  };

  return (
    <TooltipContainer
      delay={delay}
      className='tooltip-content'
      onMouseEnter={enabled ? handleMouseEnter : undefined}
      onMouseLeave={renderInBody ? handleMouseLeave : undefined}
      ref={containerRef as React.MutableRefObject<HTMLDivElement>}
    >
      <TooltipChildrenContainer className='tooltip-children'>{children}</TooltipChildrenContainer>
      {renderInBody ? renderModalContentPortal : renderHoverContent}
    </TooltipContainer>
  );
};

export default React.memo(Tooltip);
