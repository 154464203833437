import { theme } from 'common/theme';

export const MOE_LEVELS = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const MOE_COLORS = {
  [MOE_LEVELS.HIGH]: theme.colors.grass.toString(),
  [MOE_LEVELS.MEDIUM]: theme.colors.lemon.toString(),
  [MOE_LEVELS.LOW]: theme.colors.orange.toString(),
};

interface IMoeConfidence {
  confidenceLevel: string;
  color: string;
}

export const calculateMoeConfidence = (value?: number, high?: number, medium?: number): IMoeConfidence | null => {
  const confidenceLevels = [
    { threshold: high || 0, level: MOE_LEVELS.HIGH, color: MOE_COLORS.HIGH },
    { threshold: medium || 0, level: MOE_LEVELS.MEDIUM, color: MOE_COLORS.MEDIUM },
    { threshold: 0, level: MOE_LEVELS.LOW, color: MOE_COLORS.LOW },
  ];

  if (!value || !high || !medium) {
    return null;
  }

  const valueRounded = Number(value.toFixed(2));

  const { level, color } = confidenceLevels.find(({ threshold }) => valueRounded <= threshold) ?? confidenceLevels[2];

  return { confidenceLevel: level, color };
};
