const { REACT_APP_API_URL: API_URL = '' } = process.env;
export const { REACT_APP_OAUTH_KEY: OAUTH_KEY = '' } = process.env;

// remove BETA_ prefix when old endpoints are removed
export const API_URLS = {
  PROFILE: `${API_URL}/api/v2/profile`,
  TOKEN: `${API_URL}/oauth/token`,
  PASSWORD_RESET: `${API_URL}/password_resets`,
  PASSWORD_UPDATE: (token: string | undefined): string => `${API_URL}/password_resets/${token}`,
  COUNTRIES: `${API_URL}/api/v2/beta_countries`,
  KPIS: `${API_URL}/api/v2/kpis`,
  RESTRICTIONS: `${API_URL}/api/v2/restrictions`,
  SEGMENTS_CHARACTERISTICS: (studyid: string): string => `${API_URL}/api/v2/studies/${studyid}/characteristics`,
  CHARTS: `${API_URL}/api/v2/charts`,
  CHART: (chartId?: string): string => `${API_URL}/api/v2/charts/${chartId}`,
  BETA_CHARTS: `${API_URL}/api/v2/charts/index_post`,
  SEGMENTS: `${API_URL}/api/v2/segments`,
  SEGMENT_UPDATE: (segmendId: number): string => `${API_URL}/api/v2/segments/${segmendId}`,
  EXPORT_CSV: `${API_URL}/api/v2/csv_exports`,
  EXPORT_CHART_CSV: (chartId: string): string => `${API_URL}/api/v2/charts/${chartId}/export_csv`,
  STUDIES: `${API_URL}/api/v2/studies`,
  WAVE_SUMMARIES: `${API_URL}/api/v2/wave_summaries`,
  USERS: `${API_URL}/api/v2/users`,
  ASSIGN_ROLE_TO_USER: `${API_URL}/api/v2/users/invite`,
  UPDATE_USER_ROLE: (userId: unknown): string => `${API_URL}/api/v2/users/${userId}`,
  ACCEPT_INVITE: `${API_URL}/api/v2/users/accept_invite`,
  ROLES: `${API_URL}/api/v2/roles`,
  DASHBOARDS: `${API_URL}/api/v2/dashboards`,
  DASHBOARD: (dashboardId?: string): string => `${API_URL}/api/v2/dashboards/${dashboardId}`,
  DASHBOARD_DUPLICATE: (dashboardId?: string): string => `${API_URL}/api/v2/dashboards/${dashboardId}/duplicate`,
  BRAND_GROUPS: `${API_URL}/api/v2/brand_groups`,
  UPDATE_BRAND_GROUPS: (brandGroupId: string): string => `${API_URL}/api/v2/brand_groups/${brandGroupId}`,
  BRANDS: `${API_URL}/api/v2/brands`,
  FEATURES: `${API_URL}/api/v2/features`,
  TAGS: `${API_URL}/api/v2/tags`,
};
