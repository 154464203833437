import { useEffect } from 'react';
import { AccountDetails } from 'common/types/account';

export const useAppcues = ({ user, customer }: AccountDetails, currentStudy: string | undefined): void => {
  useEffect(() => {
    window?.Appcues?.identify(user?.uuid, {
      email: user?.email,
      name: user?.name,
      role: user?.role.name,
      studyId: currentStudy || '',
      customer: customer?.name,
      customerId: customer?.id,
      internalUser: user?.permissions.internal || 'false',
    });
  }, [currentStudy, customer, user]);
};
