import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { ReferenceType } from '@floating-ui/react';
import { LatanaText } from 'common/components/LatanaText';
import Icon from 'common/components/Icon';
import { ICONS } from 'icons/iconsMap';

const DropdownPillContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.v2.space(1, 1, 1, 1)};
  cursor: pointer;
`;

const StyledText = styled(LatanaText)`
  margin: ${({ theme }) => theme.v2.space(0, 1, 0, 1)};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  id: string;
  label?: string;
  removable?: boolean;
  showArrow?: boolean;
  icon?: keyof typeof ICONS;
  onRemove?: (id: string) => void;
  onClick?: () => void;
  getReferenceProps?: (userProps?: React.HTMLProps<Element> | undefined) => Record<string, unknown>;
  setReference?: ((node: ReferenceType | null) => void) & ((node: ReferenceType | null) => void);
  stopPropagation?: boolean;
}

const PillButton = ({
  id,
  label,
  showArrow,
  removable,
  icon,
  onRemove,
  getReferenceProps,
  setReference,
  onClick,
  stopPropagation = false,
}: Props) => {
  const onRemoveHandle = useCallback(() => {
    onRemove && onRemove(id as string);
  }, [onRemove, id]);

  const onClickHandle = (e: React.SyntheticEvent) => {
    // Stop event bubling if needed
    stopPropagation && e.stopPropagation();
  };

  return (
    <DropdownPillContainer
      ref={setReference}
      onClick={onClick}
      {...(getReferenceProps && { ...getReferenceProps({ onClick: onClickHandle }) })}
    >
      {!removable ? (
        icon && <Icon icon={icon} color='gray400' />
      ) : (
        <IconContainer onClick={onRemoveHandle}>
          <Icon icon='x' color='gray400' />
        </IconContainer>
      )}
      {label && (
        <StyledText variant='L1' color='gray400'>
          {label}
        </StyledText>
      )}
      {showArrow && <Icon icon='arrow-chevron-down' color='gray400' />}
    </DropdownPillContainer>
  );
};

export default React.memo(PillButton);
