import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'common/components/Modal';
import { Content, PillButtonWrapper, ContentItem, NoChartText } from './styled';
import { Study } from 'common/types/account';
import PillButton from 'common/components/PillButton';
import useAvailableChartTypes from 'common/components/StudySelectionModal/useAvailableChartTypes';
import { LatanaText as Text } from 'common/components/LatanaText';
import { useStudyContext } from 'common/contexts/StudyContext';
import { CREATE_CHART_HASH, CREATE_SEGMENT_HASH } from 'settings/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAllStudies } from 'common/queries/studies';

const MODAL_WIDTH = 584;

interface Props {
  isOpen: boolean;
}

const Fallback = () => {
  const { t } = useTranslation();
  return (
    <NoChartText>
      <Text variant='L1' color='gray500'>
        {t('notifications.errorMessages.generic')}
      </Text>
    </NoChartText>
  );
};

const StudySelectionModal = ({ isOpen }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const { data: studiesList } = useGetAllStudies();
  const { toggleStudySelectionModal } = useStudyContext();
  const [currentStudy, setCurrentStudy] = React.useState<Study | null>(null);
  const availableChartTypes = useAvailableChartTypes();
  const navigate = useNavigate();

  const handleHash = (study: Study) => {
    switch (hash) {
      case CREATE_CHART_HASH:
        setCurrentStudy(study);
        break;
      case CREATE_SEGMENT_HASH:
        navigate('/segments/custom/new', { state: { studyId: study.uuid } });
        break;
      default:
        break;
    }
  };

  const handleClose = React.useCallback(() => {
    setCurrentStudy(null);
    toggleStudySelectionModal(false);
  }, [toggleStudySelectionModal]);

  // Open chart or segment selection modal as per chosen hash
  const handleStudySelection = async (study: Study) => {
    handleHash(study);
  };

  const hasChartView = !!currentStudy;

  return (
    <Modal
      clickOutsideClose
      title={t('chart.creationModal.title', { view: hasChartView ? 'chart view' : 'study' })}
      isOpen={isOpen}
      handleClose={handleClose}
      showCloseButton
      customWidth={MODAL_WIDTH}
      isModalV2 // this flag enforces new modal design
    >
      <Content data-show-studies={!hasChartView}>
        {!hasChartView ? (
          <>
            {studiesList?.map(study => (
              <ContentItem key={study.uuid} onClick={() => handleStudySelection(study)}>
                <Text variant='L1' color='gray900'>
                  {study.name}
                </Text>
              </ContentItem>
            ))}
          </>
        ) : (
          <>
            <PillButtonWrapper>
              <PillButton id='arrow-back' label={currentStudy.name} icon='arrow-back' onClick={() => setCurrentStudy(null)} />
            </PillButtonWrapper>
            {currentStudy ? availableChartTypes(currentStudy.uuid) : <Fallback />}
          </>
        )}
      </Content>
    </Modal>
  );
};

export default React.memo(StudySelectionModal);
