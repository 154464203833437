import React from 'react';
import { useRecoilCallback } from 'recoil';
import { useRollbar } from '@rollbar/react';
import { API_URLS } from 'settings/api';
import { fetchSelector } from 'common/atoms/common';
import { useGetWaveReports } from 'common/queries/waveReports';

export const useViewReports = (): { viewReports: () => Promise<void> } => {
  const { data: reports } = useGetWaveReports();
  const rollbar = useRollbar();
  const viewReports = useRecoilCallback(({ snapshot }) => async () => {
    const { fetchPatch } = await snapshot.getPromise(fetchSelector);

    reports?.forEach(({ id, is_new }) => {
      if (is_new) {
        fetchPatch(`${API_URLS.WAVE_SUMMARIES}/${id}`, {
          is_new: false,
        }).catch(e => rollbar.error('useViewReports: PATCH wave_summaries failed', e));
      }
    });
  });

  return React.useMemo(() => {
    return {
      viewReports,
    };
  }, [viewReports]);
};
