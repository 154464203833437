import React, { useState } from 'react';
import Icon from 'common/components/Icon';
import SelectPopup from 'common/components/SelectPopup';
import { useFloating, useDismiss, useInteractions, useClick } from '@floating-ui/react';
import { IconButtonContainer } from './styled';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { useTranslation } from 'react-i18next';
import { DataTestId } from 'settings/constants';

interface Props {
  onSelect: (options: Array<string>) => void;
}

const AddButton = ({ onSelect }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const { refs, floatingStyles, context } = useFloating({
    placement: 'right-start',
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const addMenuItems = [
    { label: t('common.chart'), value: 'chart', icon: 'column-chart' },
    { label: 'Dashboard', value: 'dashboard', icon: 'apps' },
    { label: t('common.segment'), value: 'segment', icon: 'user-group' },
  ].filter(item => !!item) as SelectPopupItemProps[];

  return (
    <>
      <IconButtonContainer
        data-testid={DataTestId.CREATE_NEW_BUTTON}
        key={t('nav.createNew')}
        data-add-button={true}
        data-hover={t('nav.createNew')}
        ref={refs.setReference}
        {...(getReferenceProps && { ...getReferenceProps() })}
      >
        <Icon icon='plus' color='indigo25' size={20} />
      </IconButtonContainer>
      <SelectPopup
        items={addMenuItems}
        onSelect={onSelect}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFloating={refs.setFloating}
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        data-testid={DataTestId.CREATE_NEW_POPUP}
      />
    </>
  );
};

export default React.memo(AddButton);
