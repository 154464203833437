import React, { Fragment, useEffect, useRef, useState } from 'react';
import CircleElement from './CircleElement';
import BackgroundRectangle, { MATRIX_BG_RECTANGLE_POSITION } from './BackgroundRectangle';
import MatrixChartTooltip from './MatrixChartTooltip';
import MatrixAxis, { MatrixAxisOrientation } from './MatrixAxis';
import { coloursPalette } from 'theme/colours';
import { convertToSvgCoordinates } from './utils';
import { LatanaText } from 'common/components/LatanaText';
import { roundValue } from 'utils/chart';
import styled from '@emotion/styled';
import ChangeOverTime from './ChangeOverTime';

const TooltipTitle = styled(LatanaText)`
  margin-bottom: ${({ theme }) => theme.space(1)};
`;

export interface MatrixAxisProps {
  min: number;
  max: number;
  label: string;
  percentage: boolean;
}

export interface MatrixDatapoint {
  xValue: number;
  yValue: number;
  xPastValue: number;
  yPastValue: number;
  color: string;
  label: string;
  id: number;
  hidden: boolean;
}

interface MatrixCirclePoint {
  x: number;
  y: number;
  xPast: number | null;
  yPast: number | null;
  innerFill: string;
}

interface Props {
  yAxis: MatrixAxisProps;
  xAxis: MatrixAxisProps;
  datapoints: MatrixDatapoint[];
  showMatrixChangeOverTime: boolean;
  chartUuid?: string;
}

interface Coords {
  x: number;
  y: number;
}

// Margins for left and bottom Axis
export const MATRIX_CHART_LEFT_MARGIN = 76;
export const MATRIX_CHART_BOTTOM_MARGIN = 52;

const MatrixChartComponent = ({ yAxis, xAxis, datapoints, showMatrixChangeOverTime, chartUuid }: Props) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [parentDimensions, setParentDimensions] = useState({ width: 0, height: 0 });
  const svgWidth = parentDimensions.width;
  const svgHeight = parentDimensions.height;

  const chartWidth = svgWidth - MATRIX_CHART_LEFT_MARGIN;
  const chartHeight = svgHeight - MATRIX_CHART_BOTTOM_MARGIN;

  const [circleDatapoints, setCircleDatapoints] = useState<MatrixCirclePoint[]>([]);

  useEffect(() => {
    setCircleDatapoints(
      datapoints.map(point => {
        const coords = convertToSvgCoordinates(chartWidth, chartHeight, yAxis, xAxis, point.xValue, point.yValue);
        const pastCoords = convertToSvgCoordinates(chartWidth, chartHeight, yAxis, xAxis, point.xPastValue, point.yPastValue);
        return {
          x: coords.x,
          y: coords.y,
          xPast: point.xPastValue != null ? pastCoords.x : null,
          yPast: point.yPastValue != null ? pastCoords.y : null,
          innerFill: point.color,
        };
      }),
    );
  }, [chartWidth, chartHeight, datapoints, xAxis, yAxis]);

  const [hoverCoords, setHoverCoords] = useState<Coords | null>(null);
  const [closestCircle, setClosestCircle] = useState(null);

  const findClosestCircle = (mouseX: number, mouseY: number, circleDatapoints: MatrixCirclePoint[]) => {
    let closestIndex = null;
    let closestDistance = Infinity;

    circleDatapoints.forEach((circle, index) => {
      const distance = Math.hypot(mouseX - circle.x, mouseY - circle.y);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = index;
      }
    });

    return closestIndex;
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    // Find the closest circle
    const closestIndex = findClosestCircle(mouseX, mouseY, circleDatapoints);

    setHoverCoords({ x: mouseX, y: mouseY });
    setClosestCircle(closestIndex);
  };

  const handleMouseLeave = () => {
    setHoverCoords(null);
    setClosestCircle(null);
  };

  const renderBackgroundRectangles = () => {
    return (
      <g transform={`translate(${MATRIX_CHART_LEFT_MARGIN}, ${0})`}>
        <BackgroundRectangle
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          position={MATRIX_BG_RECTANGLE_POSITION.TOP_LEFT}
          label={`Improve your ${xAxis.label}`}
        />
        <BackgroundRectangle
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          position={MATRIX_BG_RECTANGLE_POSITION.TOP_RIGHT}
          label='You want to be here'
        />
        <BackgroundRectangle
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          position={MATRIX_BG_RECTANGLE_POSITION.BOTTOM_LEFT}
          label='You want to avoid this'
        />
        <BackgroundRectangle
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          position={MATRIX_BG_RECTANGLE_POSITION.BOTTOM_RIGHT}
          label={`Improve your ${yAxis.label}`}
        />
      </g>
    );
  };

  const renderDottedLinesOnHover = () => {
    if (closestCircle === null) return null;

    return (
      <>
        <line
          x1={circleDatapoints[closestCircle].x}
          y1={circleDatapoints[closestCircle].y}
          x2={circleDatapoints[closestCircle].x}
          y2={chartHeight}
          stroke={coloursPalette.gray500}
          strokeWidth='1'
          strokeDasharray='4'
        />
        <line
          x1={circleDatapoints[closestCircle].x}
          y1={circleDatapoints[closestCircle].y}
          x2={MATRIX_CHART_LEFT_MARGIN}
          y2={circleDatapoints[closestCircle].y}
          stroke={coloursPalette.gray500}
          strokeWidth='1'
          strokeDasharray='4'
        />
      </>
    );
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(event => {
      const height = event[0].contentRect.height;
      const width = event[0].contentRect.width;
      setParentDimensions({ width: height * 2 <= width ? height * 2 : width, height: event[0].contentRect.height });
    });

    resizeObserver.observe(svgRef.current?.parentElement as Element);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      <svg
        width='100%'
        height='100%'
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        ref={svgRef}
        preserveAspectRatio='xMinYMin meet'
      >
        <MatrixAxis
          minLabel={`${yAxis.min}%`}
          maxLabel={`${yAxis.max}%`}
          label={yAxis.label}
          chartHeight={chartHeight}
          chartWidth={chartWidth}
          leftMargin={MATRIX_CHART_LEFT_MARGIN}
          orientation={MatrixAxisOrientation.VERTICAL}
        />
        <MatrixAxis
          minLabel={`${xAxis.min}%`}
          maxLabel={`${xAxis.max}%`}
          label={xAxis.label}
          chartHeight={chartHeight}
          chartWidth={chartWidth}
          leftMargin={MATRIX_CHART_LEFT_MARGIN}
          orientation={MatrixAxisOrientation.HORIZONTAL}
        />

        {/* Render background rectangles */}
        {renderBackgroundRectangles()}

        {/* Render dotted lines */}
        {renderDottedLinesOnHover()}

        {/* Render circles */}
        {circleDatapoints.map((circle, index) => (
          <Fragment key={`MatrixDataPoint-${index}`}>
            {showMatrixChangeOverTime && circle.xPast && circle.yPast && (
              <ChangeOverTime
                index={index}
                innerFill={circle.innerFill}
                startX={circle.xPast}
                startY={circle.yPast}
                endX={circle.x}
                endY={circle.y}
                key={`ChangeOverTime-${index}`}
                isHovered={index === closestCircle}
              />
            )}

            <CircleElement
              key={`MatrixCircle-${index}`}
              cx={circle.x}
              cy={circle.y}
              innerFill={circle.innerFill}
              isHovered={index === closestCircle}
              index={index}
              chartUuid={chartUuid}
            />
          </Fragment>
        ))}

        {/* We use this to bring to front hovered circle, since SVG doesn't have z-index */}
        <use id='use' xlinkHref={`#MatrixCircle-${chartUuid}-${closestCircle || 0}`} />
      </svg>
      {/* Render tooltip */}
      {closestCircle !== null && hoverCoords && (
        <MatrixChartTooltip x={circleDatapoints[closestCircle].x} y={circleDatapoints[closestCircle].y - 16}>
          <TooltipTitle color='gray50' variant='L2'>
            {datapoints[closestCircle].label}
          </TooltipTitle>
          <LatanaText color='gray50' variant='B3'>
            {yAxis.label}:{' '}
            {showMatrixChangeOverTime && datapoints?.[closestCircle]?.yPastValue && (
              <>{roundValue(datapoints[closestCircle].yPastValue, true)}% &rarr;&nbsp;</>
            )}
            {roundValue(datapoints[closestCircle].yValue, true)}%
          </LatanaText>
          <LatanaText color='gray50' variant='B3'>
            {xAxis.label}:{' '}
            {showMatrixChangeOverTime && datapoints?.[closestCircle]?.xPastValue && (
              <>{roundValue(datapoints[closestCircle].xPastValue, true)}% &rarr;&nbsp;</>
            )}
            {roundValue(datapoints[closestCircle].xValue, true)}%
          </LatanaText>
        </MatrixChartTooltip>
      )}
    </>
  );
};

export default React.memo(MatrixChartComponent);
