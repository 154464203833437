import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { QuestionWhiteComponent, QuestionBlackComponent } from 'icons';
import * as React from 'react';

export const InfoIndicatorContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  margin: ${theme.space(0, 0, 0, 1)};
  [data-state='hover'] {
    display: none;
  }
  &:hover {
    [data-state='normal'] {
      display: none;
    }
    [data-state='hover'] {
      display: initial;
    }
  }
`;

const InfoIndicator: React.FC = () => {
  return (
    <InfoIndicatorContainer>
      <QuestionWhiteComponent data-state='normal' />
      <QuestionBlackComponent data-state='hover' />
    </InfoIndicatorContainer>
  );
};

export default React.memo(InfoIndicator);
