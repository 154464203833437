import { Brand, KPI, Wave } from 'common/types/common';
import { Segment } from 'common/types/segments';
import { FilterNames } from 'common/types/filters';
import {
  ChartConfiguration,
  ChartFields,
  ChartFormData,
  RawChart,
  ChartViews,
  ChartType,
  RawChartData,
  RawChartSchema,
  BpChartData,
  BpColumnKey,
  SortOption,
  SortType,
  ChartNumbersType,
} from 'common/types/chart';
import { RADAR_CHART_KPIS } from 'common/atoms/constants';
import { normalizeBrandFilters, getSecondDimension } from 'utils/helpers';
import { sortDataByPrimaryBrands } from 'utils/brands';
import { getLastWaves } from 'utils/wave';
import { BRAND_PERCEPTION_KPI, FUNNEL_DEFAULT_KPI, MARKET_FUNNEL_KPIS, MARKET_SIZE_KPI } from 'settings/constants';

export const mapChartPayload = ({ chart, data, aggregate_data, brand_awareness_data }: RawChart): RawChart => {
  if (!chart?.configuration) {
    return {
      data,
      chart,
      aggregate_data,
      brand_awareness_data,
    };
  }

  const filtersNamesMap = {
    wave: chart.wave_ids,
    country: chart.country_ids,
    brand: chart.brand_ids,
    segment: chart.segment_ids,
    kpi: chart.kpi_option_identifiers,
  } as { [key: string]: Array<string | number> };

  const firstDimensionValues = filtersNamesMap[chart.configuration.first_dimension] || [];
  const secondDimensionValues = filtersNamesMap[chart.configuration.second_dimension] || [];

  const sortedData = data.sort((dataA, dataB) => {
    const firstDimA = firstDimensionValues.indexOf(dataA[chart.configuration.first_dimension]);
    const firstDimB = firstDimensionValues.indexOf(dataB[chart.configuration.first_dimension]);

    if (firstDimA > firstDimB) return 1;
    if (firstDimA < firstDimB) return -1;

    const secondDimA = secondDimensionValues.indexOf(dataA[chart.configuration.second_dimension]);
    const secondDimB = secondDimensionValues.indexOf(dataB[chart.configuration.second_dimension]);

    if (secondDimA > secondDimB) return 1;
    if (secondDimA < secondDimB) return -1;

    return 0;
  });

  return {
    data: sortedData,
    chart,
    aggregate_data,
    brand_awareness_data,
  };
};

interface ChartParams {
  defaultWaves: number[];
  defaultKpis: string[];
  defaultBrands: number[];
  defaultRefAverage: boolean;
  defaultSegments: number[];
}

interface DefaultChartParams {
  wave_ids: number[];
  brand_ids: number[];
  segment_ids: number[];
  country_ids: number[];
  kpi_option_identifiers: string[];
  display_reference_average: boolean;
}

const remapChartParams = ({
  defaultWaves,
  defaultKpis,
  defaultBrands,
  defaultRefAverage,
  defaultSegments,
}: ChartParams): DefaultChartParams => ({
  wave_ids: defaultWaves,
  brand_ids: defaultBrands,
  segment_ids: defaultSegments,
  country_ids: [],
  kpi_option_identifiers: defaultKpis,
  display_reference_average: defaultRefAverage,
});

const getMarketSizeDefaultParams = (
  brands: Brand[],
  segments: Segment[],
  kpi: KPI[],
): { defaultBrands: number[]; defaultSegments: number[]; defaultKpis: string[] } => {
  const marketSizeKpi = kpi.find(x => x.kpi_identifier === MARKET_SIZE_KPI)?.kpi_identifier;
  const globalBrandId = brands.find(x => x.global)?.id;
  const genPopSegmentId = segments.find(({ general_population }) => general_population === true)?.id;
  return {
    defaultBrands: globalBrandId ? [globalBrandId] : [],
    defaultSegments: genPopSegmentId ? [genPopSegmentId] : [],
    defaultKpis: marketSizeKpi ? [marketSizeKpi] : [],
  };
};

export const generateNewChart = (
  studyId: string,
  chartType: ChartType,
  kpis: KPI[],
  waves: Wave[],
  brands: Brand[],
  segments: Segment[],
  oldSurveyBrandAwarenessEnabled: boolean,
  isPercentageAbsoluteSwitchEnabled: boolean,
): RawChart => {
  const defaultKpis: string[] = [];
  const defaultBrands: number[] = [];
  const defaultSegments: number[] = [];
  let first_dimension = null as unknown as FilterNames;
  let defaultWaves: number[] = [];
  let defaultDynamicWaves = null;
  let defaultRefAverage = false;

  const isMarketSize = chartType === ChartType.MARKET_SIZE;
  const isMarketFunnel = chartType === ChartType.MARKET_FUNNEL;
  const isDisplayAdditionalBrandAwareness = !isMarketFunnel && !isMarketSize && !oldSurveyBrandAwarenessEnabled;

  if (chartType === ChartType.FUNNEL) {
    first_dimension = FilterNames.KPI;

    const defaultFunnelKPIs = kpis.reduce((acc: string[], { kpi_identifier }) => {
      if (FUNNEL_DEFAULT_KPI.includes(kpi_identifier)) acc = [...acc, kpi_identifier];
      return acc;
    }, []);

    defaultKpis.splice(0, 0, ...defaultFunnelKPIs);
  }

  if (chartType === ChartType.RADAR) {
    first_dimension = FilterNames.KPI;

    const defaultRadarKPIs = kpis.reduce((acc: string[], { kpi_identifier, question_options }) => {
      if (kpi_identifier === RADAR_CHART_KPIS.BRAND_ASSOCIATIONS || kpi_identifier === RADAR_CHART_KPIS.PURCHASE_DRIVER) {
        acc = question_options?.map(({ option_identifier }) => option_identifier) as string[];
      }
      return acc;
    }, []);

    defaultKpis.splice(0, 0, ...defaultRadarKPIs);
  }

  if (chartType === ChartType.GROWTH_PERFORMANCE) {
    defaultWaves = getLastWaves(waves);
    defaultDynamicWaves = 4;
  }

  if (chartType === ChartType.BRAND_PERCEPTION) {
    defaultWaves = getLastWaves(waves);
    defaultDynamicWaves = 0;
    defaultRefAverage = true;

    const brandPerceptionKpis = kpis.flatMap(({ kpi_identifier, question_options }) => {
      if (kpi_identifier !== BRAND_PERCEPTION_KPI) return [];
      return (question_options || []).map(({ option_identifier }) => option_identifier);
    });

    defaultKpis.splice(0, 0, ...brandPerceptionKpis);
  }

  if (isMarketFunnel) {
    defaultWaves = getLastWaves(waves);
    defaultDynamicWaves = 0;

    const marketFunnelKPIs = kpis.reduce((acc: string[], { kpi_identifier }) => {
      if (MARKET_FUNNEL_KPIS.includes(kpi_identifier)) acc = [...acc, kpi_identifier];
      return acc;
    }, []);

    defaultKpis.splice(0, 0, ...marketFunnelKPIs);

    // When [isPercentageAbsoluteSwitchEnabled] is disabled then add General Population segment is the only segment
    if (!isPercentageAbsoluteSwitchEnabled) {
      const genPop = segments.find(({ general_population }) => general_population === true);
      defaultSegments.splice(0, 0, genPop?.id as number);
    }
  }

  if (isMarketSize) {
    const marketSizeDefaultValues = getMarketSizeDefaultParams(brands, segments, kpis);
    defaultBrands.splice(0, 0, ...marketSizeDefaultValues.defaultBrands);
    defaultSegments.splice(0, 0, ...marketSizeDefaultValues.defaultSegments);
    defaultKpis.splice(0, 0, ...marketSizeDefaultValues.defaultKpis);
  }

  const defaultChartParams = remapChartParams({ defaultWaves, defaultKpis, defaultBrands, defaultRefAverage, defaultSegments });

  return {
    chart: {
      updated_at: '',
      owner: null,
      study_uuid: studyId,
      name: 'Untitled chart',
      uuid: undefined,
      description: '',
      chart_type: chartType,
      current_view: ChartViews.BAR,
      dynamic_waves: defaultDynamicWaves,
      configuration: {
        first_dimension,
        second_dimension: null as unknown as FilterNames,
        x_label: ChartFields.WAVE,
        y_label: ChartFields.MEAN,
        chart_value_representation: ChartNumbersType.PERCENTAGE,
      },
      tags: [],
      display_additional_brand_awareness: isDisplayAdditionalBrandAwareness,
      ...defaultChartParams,
    },
    data: [],
  };
};

export const generateChartFormData = ({
  chart: {
    uuid,
    name,
    description,
    chart_type,
    wave_ids,
    brand_ids,
    segment_ids,
    country_ids,
    kpi_option_identifiers,
    configuration,
    current_view,
    display_reference_average,
    dynamic_waves,
    study_uuid,
    owner,
    display_additional_brand_awareness,
  },
}: RawChart): ChartFormData => {
  return {
    uuid,
    name: name as string,
    description: description as string,
    chart_type,
    current_view: current_view || ChartViews.BAR,
    country: country_ids,
    wave: wave_ids,
    kpi: kpi_option_identifiers,
    brand: normalizeBrandFilters(brand_ids, chart_type, display_reference_average),
    segment: segment_ids,
    firstDimension: configuration?.first_dimension,
    secondDimension: getSecondDimension([wave_ids, brand_ids, segment_ids, country_ids, kpi_option_identifiers], configuration),
    display_reference_average: !!display_reference_average,
    display_additional_brand_awareness: !!display_additional_brand_awareness,
    chartNumbersType: configuration?.chart_value_representation,
    dynamic_waves,
    study_uuid,
    owner,
  };
};

export const getAllChartTypes = (): Array<string> => Object.values(ChartType);

export const adjustChartConfig = (chart: RawChartSchema): ChartConfiguration => {
  if (!chart.configuration) return chart.configuration;

  const configuration = { ...chart.configuration };
  configuration.second_dimension = getDimension(chart);

  return configuration;
};

const getDimension = (chart: RawChartSchema) => {
  const firstDim = chart.configuration?.first_dimension;
  const secondDim = chart.configuration?.second_dimension;

  if (firstDim === secondDim) {
    return resolveDuplicateDimensions(firstDim);
  }

  return secondDim;
};

const resolveDuplicateDimensions = (firstDimension: FilterNames) =>
  firstDimension === FilterNames.KPI ? FilterNames.BRAND : FilterNames.KPI;

// adjust waves in the chart in case some wave was deleted
export const adjustWaves = (chart: RawChart, waves: Wave[]): RawChart => {
  const waveIds = waves.map(({ id }) => id);
  chart.chart.wave_ids = chart.chart.wave_ids.filter(waveID => waveIds.includes(waveID));

  return chart;
};

// add missing First dim/ Second dim combination in chart data
export const prefillChartData = (chart: RawChart): RawChart => {
  if (chart.chart.chart_type === ChartType.MATRIX) {
    return chart;
  }

  if (chart.data.length && chart.chart?.configuration) {
    const firstDimension = chart.chart?.configuration.first_dimension;
    const secondDimension = chart.chart?.configuration.second_dimension;

    const { wave: defaultWave, country: defaultCountry, segment: defaultSegment, kpi: defaultKpi, brand: defaultBrand } = chart.data[0];

    const filtersNamesMap = {
      wave: chart.chart.wave_ids,
      country: chart.chart.country_ids,
      brand: chart.chart.brand_ids,
      segment: chart.chart.segment_ids,
      kpi: chart.chart.kpi_option_identifiers,
    } as { [key: string]: Array<string | number> };

    const firstDimensionValues = filtersNamesMap[firstDimension] || [];
    const secondDimensionValues = filtersNamesMap[secondDimension] || [];

    const existingMap = chart.data.reduce((acc, { [firstDimension]: firstDimensionValue, [secondDimension]: secondDimensionValue }) => {
      acc[`${firstDimensionValue}_${secondDimensionValue}`] = true;
      return acc;
    }, {} as Record<string, boolean>);

    firstDimensionValues.forEach(firstDimVal => {
      secondDimensionValues.forEach(secondDimVal => {
        const key = `${firstDimVal}_${secondDimVal}`;
        if (!existingMap[key]) {
          chart.data.push({
            wave: defaultWave,
            country: defaultCountry,
            segment: defaultSegment,
            kpi: defaultKpi,
            brand: defaultBrand,
            [firstDimension]: firstDimVal,
            [secondDimension]: secondDimVal,
            mean: 0,
            margin_of_error: 0,
            unavailable: true,
          });
        }
      });
    });
  }

  return chart;
};

export const roundValue = (value: number, decimalsEnabled: boolean): number => {
  const booleanAsNumber = Number(decimalsEnabled);
  return Number(value?.toFixed(booleanAsNumber));
};

export const displayValue = (value: number, decimalsEnabled: boolean): string => value.toFixed(Number(decimalsEnabled));

export const setCurrentView = (currentView: string | null, chartDimensions: Array<FilterNames | null | undefined>): string => {
  const isDimensionNotWave = !chartDimensions.includes(FilterNames.WAVE);
  return currentView === ChartViews.LINE && isDimensionNotWave ? ChartViews.BAR : currentView || ChartViews.BAR;
};

export const isChartWithConfig = (chartType: ChartType): boolean => {
  return ![ChartType.GAUGE, ChartType.MARKET_SIZE].includes(chartType);
};

// Group data by active "group by" filter
const groupElementsByFilter = (data: RawChartData[], groupBy: FilterNames): RawChartData[] => {
  data.sort((a, b) => {
    if (groupBy === FilterNames.KPI) {
      const valueA = String(a?.[groupBy] || '').toUpperCase();
      const valueB = String(b?.[groupBy] || '').toUpperCase();
      return valueA === valueB ? 0 : valueA > valueB ? 1 : -1;
    }
    return Number(a[groupBy]) - Number(b[groupBy]) || 0;
  });
  return data;
};

// Sort a group of brands as per their availability in "primaryBrands"
const sortGroupDataByBrand = (
  groupedData: RawChartData[],
  filterPack: Record<string | number, string | Date>,
  primaryBrands: string[],
): RawChartData[] => {
  groupedData.sort((a: RawChartData, b: RawChartData) => {
    const brandAName = String(filterPack[a.brand]) || '';
    const brandBName = String(filterPack[b.brand]) || '';

    // Check if brands are part of "primaryBrands"
    return sortDataByPrimaryBrands(
      { id: a.brand.toString(), name: brandAName },
      { id: b.brand.toString(), name: brandBName },
      primaryBrands,
    );
  });
  return groupedData;
};

const sortGroupDataByWave = (groupedData: RawChartData[], waves: Wave[]): RawChartData[] => {
  groupedData.sort((a: RawChartData, b: RawChartData) => {
    const waveA = waves.find(wave => wave.id === a.wave);
    const waveB = waves.find(wave => wave.id === b.wave);

    if (waveA && waveB) {
      const dateA = new Date(waveA.date);
      const dateB = new Date(waveB.date);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      }
    }

    return 0;
  });
  return groupedData;
};

export const sortChartData = ({
  data,
  filterPack,
  primaryBrands,
  groupBy,
  secondDimension,
  waves,
}: {
  data: RawChartData[];
  filterPack: Record<string | number, string | Date>;
  primaryBrands: string[] | null;
  groupBy: FilterNames;
  secondDimension: FilterNames;
  waves: Wave[];
}): RawChartData[] => {
  let dataChunk: RawChartData[] = [];
  let sortedData: RawChartData[] = [];
  const copiedData = [...data];
  const elementsGroupedByFilter = groupElementsByFilter(copiedData, groupBy);
  if (groupBy === FilterNames.WAVE) {
    sortedData = sortGroupDataByWave(elementsGroupedByFilter, waves);
  } else if (secondDimension === FilterNames.BRAND) {
    elementsGroupedByFilter.forEach((element, i) => {
      // Check if there are no more elemets present in the list
      const hasNoMoreElements = i === elementsGroupedByFilter.length - 1;
      // Check if groupping has been changed or not
      const hasGroupChanged = elementsGroupedByFilter[i + 1]?.[groupBy] !== element[groupBy];

      if (hasNoMoreElements || hasGroupChanged) {
        // push last element of the group
        dataChunk.push(element);
        // after sorting the elements present in dataChunk append them in sortedData
        sortedData = [...sortedData, ...sortGroupDataByBrand(dataChunk, filterPack, primaryBrands || [])];
        // clear the temporary array so that we use it again for new group elements
        dataChunk = [];
      } else {
        dataChunk.push(element); // continue pusthing elements of same group in the temporary array
      }
    });
  } else if (groupBy === FilterNames.BRAND) {
    sortedData = sortGroupDataByBrand(elementsGroupedByFilter, filterPack, primaryBrands || []);
  } else {
    sortedData = elementsGroupedByFilter;
  }

  return sortedData;
};

export const isAllBrandsAreCompetitors = (
  chartBrandIds: Array<number>,
  primaryBrands: Array<string>,
  competitors: Array<number>,
): boolean => {
  if (!chartBrandIds?.length) return false;
  const chartBrandsWithoutPrimary = chartBrandIds?.filter(id => !primaryBrands.includes(id.toString()));
  return chartBrandsWithoutPrimary.length === 0 || chartBrandsWithoutPrimary.every(id => competitors.includes(id));
};

// TODO: reuse it in other table charts
export const sortTableChart = (chartData: Array<BpChartData>, sorting: SortOption) => {
  const { key, type } = sorting;
  return chartData.sort((a, b) => {
    const aValue = a[key as BpColumnKey].value as number;
    const bValue = b[key as BpColumnKey].value as number;

    if (!key || type === SortType.DEFAULT) {
      return 0;
    } else if (type === SortType.DESC) {
      return bValue - aValue;
    } else {
      return aValue - bValue;
    }
  });
};
