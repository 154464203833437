import * as React from 'react';
import { TableListItemProps } from 'common/types/common';
import { Card } from 'common/types/dashboard';
import { useChartInDashboard } from 'common/hooks/dashboards';
import { getDashboardWithoutChart, addNewChartToDashboard } from 'utils/dashboards';
import { useGetDashboards } from 'common/queries/dashboards';

export interface SelectedDashboards {
  description?: string;
  uuid: string;
  cards?: Card[];
  name?: string;
}

interface Context {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  chartsModalState: SelectedDashboards[];
  updateModalState: (newState: TableListItemProps) => void;
  selectedChart: SelectedChart | null;
  setSelectedChart: (itemId: string, chartType: string) => void;
  changedDashboardsId: string[];
  setInitialModalState: () => void;
}
interface ChartDashboardContextProvider {
  children: JSX.Element;
  context?: Context;
}

interface SelectedChart {
  selectedChartId: string;
  chartType: string;
}

export const ChartDashboardsContext = React.createContext<Context>({
  selectedChart: null,
  isModalOpen: false,
  chartsModalState: [],
  changedDashboardsId: [],
  setModalOpen: () => null,
  updateModalState: () => null,
  setSelectedChart: () => null,
  setInitialModalState: () => null,
});

export const ChartDashboardsContextProvider: React.FC<ChartDashboardContextProvider> = ({ children }) => {
  const { data: dashboardList } = useGetDashboards();

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [selectedChart, setSelectedChartState] = React.useState<SelectedChart | null>(null);
  const [chartsModalState, setChartsModalState] = React.useState<SelectedDashboards[]>([]);
  const [changedDashboardsId, setChangedDashboardsId] = React.useState<string[]>([]);

  const isDashboardIncludesChart = useChartInDashboard();

  const setInitialModalState = React.useCallback(() => {
    if (dashboardList && setChartsModalState) {
      setChangedDashboardsId([]);
      setChartsModalState(dashboardList);
    }
  }, [dashboardList, setChartsModalState, setChangedDashboardsId]);

  const getNewModalState = React.useCallback(
    (prevState: SelectedDashboards[], newDashboard: TableListItemProps, selectedChartId: string) => {
      const isChartInDashboard = isDashboardIncludesChart(chartsModalState, newDashboard?.uuid, selectedChartId);
      const otherDashboards = prevState.filter(({ uuid }) => uuid !== newDashboard.uuid);

      if (isChartInDashboard) {
        const currentDashboard = getDashboardWithoutChart(prevState, newDashboard, selectedChartId);
        return [...otherDashboards, currentDashboard];
      } else {
        return [...otherDashboards, newDashboard];
      }
    },
    [isDashboardIncludesChart, chartsModalState],
  );

  const getChangedDashboardIds = (prevState: string[], dashboardId: string) => {
    return prevState.includes(dashboardId) ? prevState.filter(uuid => uuid !== dashboardId) : [...prevState, dashboardId];
  };

  const updateModalState = React.useCallback(
    (dashboard: TableListItemProps) => {
      const { selectedChartId = '', chartType = '' } = selectedChart || {};
      const newDashboard = addNewChartToDashboard(dashboard, selectedChartId, chartType);
      setChangedDashboardsId(prevState => getChangedDashboardIds(prevState, dashboard?.uuid));
      setChartsModalState(prevState => getNewModalState(prevState, newDashboard, selectedChartId));
    },
    [setChartsModalState, selectedChart, getNewModalState],
  );

  const setSelectedChart = React.useCallback(
    (selectedChartId: string, chartType: string) => {
      setSelectedChartState({ selectedChartId, chartType });
    },
    [setSelectedChartState],
  );

  return (
    <ChartDashboardsContext.Provider
      value={{
        isModalOpen,
        setModalOpen,
        chartsModalState,
        selectedChart,
        setSelectedChart,
        updateModalState,
        changedDashboardsId,
        setInitialModalState,
      }}
    >
      {children}
    </ChartDashboardsContext.Provider>
  );
};

export const useChartDashboardsContext = () => {
  return React.useContext(ChartDashboardsContext);
};
