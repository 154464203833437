import * as React from 'react';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';

const Container = styled.div`
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.black.toString()}`};
  padding: ${({ theme }) => `${theme.space(4.5, 0, 3)}`};
  margin: ${({ theme }) => `${theme.space(0, 0, 4)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: -2px;
  background: white;
  z-index: 100;
`;

const FormHeaderActions = styled.div`
  display: flex;
  min-height: 48px;
  max-height: 48px;
`;

interface Props {
  title: string;
  children?: React.ReactNode;
}

const SettingsHeader: React.FC<Props> = ({ children, title }) => {
  return (
    <Container>
      <Text variant='h4' color='black'>
        {title}
      </Text>
      <FormHeaderActions>{children}</FormHeaderActions>
    </Container>
  );
};

export default React.memo(SettingsHeader);
