import * as React from 'react';
import styled from '@emotion/styled';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useGetAllBrands } from 'common/queries/brands';
import { useGetAllCountries } from 'common/queries/countries';
import DropdownPillMenu from 'common/components/DropdownPillMenu';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { ICONS } from 'icons/iconsMap';
import { FetchNextPage, FilterOptionsMap, FilterType, ChartPayload, SearchCriteria } from 'common/types/charts';
import { useGetPaginatedCharts } from 'common/queries/charts';
import { SortType } from 'common/types/chart';
import { useTags } from 'common/queries/tags';
import useFilterOptions from 'views/Charts/useFilterOptionsMap';
import { chartPaginationPayload } from 'common/atoms/charts';
import { TableListItemProps } from 'common/types/common';
import { useGetAllKpis } from 'common/queries/kpis';
import { useGetAllStudies } from 'common/queries/studies';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white.toString()};
`;

const ResetButton = styled.span`
  display: flex;
  padding: ${({ theme }) => theme.v2.space(1.5, 1)};
  align-items: center;
  cursor: pointer;
  width: 52px;
  text-align: center;
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  color: ${({ theme }) => theme.v2.colours.gray500};
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  font-size: ${({ theme }) => theme.v2.text.variants.L1.fontSize};
  font-weight: ${({ theme }) => theme.v2.text.variants.L1.fontWeight};
  line-height: ${({ theme }) => theme.v2.text.variants.L1.lineHeight};
  letter-spacing: ${({ theme }) => theme.v2.text.variants.L1.letterSpacing};
  &:hover {
    background: ${({ theme }) => theme.v2.colours.gray100};
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  align-self: stretch;
  padding: ${({ theme }) => theme.v2.space(0.5, 3, 0.5, 0)};
  border-top: 1px solid ${({ theme }) => theme.v2.colours.gray100};
`;

const FilterContainerSection = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
`;

const FilterSectionDivider = styled.div`
  width: 1px;
  margin: ${({ theme }) => theme.v2.space(0, 1)};
  background: ${({ theme }) => theme.v2.colours.gray100};
`;

const FilterDropdownContainer = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.v2.space(1)};
`;

interface ChartListFilterProps {
  children: (
    sortedData: Array<TableListItemProps> | undefined,
    isChartsLoading: boolean,
    onResetFilters: () => void,
    onHandleListSort: ({ key, type }: SortingCriteria) => void,
    hasNextPage: boolean | undefined,
    isFetchingNextPage: boolean,
    fetchNextpage: FetchNextPage,
  ) => JSX.Element;
}

const iconsMap: Record<string, keyof typeof ICONS> = {
  ['markets']: 'globe',
  ['brands']: 'rocket',
  ['kpi']: 'new',
  ['studies']: 'user-group',
  ['folder']: 'folder',
};

const dropdownLabelsMap: Record<string, string> = {
  ['country_ids']: 'Markets',
  ['brand_ids']: 'Brands',
  ['kpi_option_identifiers']: 'KPI',
  ['study_uuids']: 'Studies',
  ['tags']: 'Folder',
};

const initialSearchState: SearchCriteria = { country_ids: [], brand_ids: [], kpi_option_identifiers: [], study_uuids: [], tags: [] };

const TempSortKeyMap = {
  ['name']: 'chart_name',
  ['owner']: 'owner_name',
  ['updated_at']: 'updated_at',
};

const TempSortTypeMap = {
  ['ascending']: 'asc',
  ['descending']: 'desc',
  ['default']: '',
};

enum SortingKey {
  NAME = 'name',
  OWNER = 'owner',
  UPDATED_AT = 'updated_at',
}

type SortingCriteria = {
  key: SortingKey;
  type: SortType;
};

type ChartSortCriteria = {
  sort_column: string;
  sort_direction: string;
};

const ChartListFilter: React.FC<ChartListFilterProps> = ({ children }) => {
  const { t } = useTranslation();
  const [activeFilters, updateActiveFilters] = React.useState<Array<string>>([]);
  const [searchCriteria, setSearchCriteria] = React.useState<SearchCriteria>(initialSearchState);
  const [sortingCriteria, setSortingCriteria] = React.useState<ChartSortCriteria>({
    sort_column: 'updated_at',
    sort_direction: 'desc',
  });
  const setFilterCriteria = useSetRecoilState(chartPaginationPayload);

  const showAddFilterButton = activeFilters.length !== Object.values(FilterType).length;
  const showSectionDivider = !!activeFilters.length && showAddFilterButton;

  const { data: studies } = useGetAllStudies();
  const { data: kpi } = useGetAllKpis();

  const { data: tagsList } = useTags();
  const { isLoading: isBrandsLoading } = useGetAllBrands();
  const { isLoading: isCountriesLoading } = useGetAllCountries();
  const { paginatedChartList, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } = useGetPaginatedCharts();

  const filterDataLoaded = studies && kpi && !isBrandsLoading && !isCountriesLoading;

  const filterOptionsMap = useFilterOptions();

  const onHandleChartListSort = React.useCallback(
    ({ key, type }: SortingCriteria) => {
      setSortingCriteria({ sort_column: TempSortKeyMap[key], sort_direction: TempSortTypeMap[type] });
    },
    [setSortingCriteria],
  );

  const onHandleUpdateFilter = React.useCallback(
    (nextFilter: Array<string>) => {
      updateActiveFilters(prevFilters => prevFilters.concat(nextFilter));
    },
    [updateActiveFilters],
  );

  const onHandleResetAllFilters = () => {
    updateActiveFilters([]);
    setSearchCriteria(initialSearchState);
  };

  const onHandleClearFilter = React.useCallback(
    (filterId: string) => {
      updateActiveFilters(prevActiveFilters => prevActiveFilters.filter(item => item !== filterId));
      setSearchCriteria(prevCriteria => ({ ...prevCriteria, [filterId]: [] }));
    },
    [setSearchCriteria, updateActiveFilters],
  );

  const onHandleUpdateSearchCriteria = React.useCallback(
    async (filter: FilterType, newCriteria: Array<string | number>) => {
      setSearchCriteria(prevCriteria => ({
        ...prevCriteria,
        [filter]: [...newCriteria],
      }));
    },
    [setSearchCriteria],
  );

  const showFolderOption = React.useMemo(() => {
    const hasTags = !!tagsList?.length;
    const hasOnlyRootTag = tagsList?.length === 1 && tagsList.includes('root');
    return hasTags && !hasOnlyRootTag;
  }, [tagsList]);

  const addFilterOptions = React.useMemo(() => {
    const optionsList = [
      { label: 'Markets', value: 'country_ids', icon: iconsMap['markets'] },
      { label: 'Brands', value: 'brand_ids', icon: iconsMap['brands'] },
      { label: 'KPI', value: 'kpi_option_identifiers', icon: iconsMap['kpi'] },
      { label: 'Studies', value: 'study_uuids', icon: iconsMap['studies'] },
      { label: 'Folder', value: 'tags', icon: iconsMap['folder'] },
    ];

    const inactiveOptions = optionsList.filter(({ value }) => !activeFilters.includes(value));

    if (!showFolderOption) {
      inactiveOptions.pop();
    }

    return inactiveOptions;
  }, [activeFilters, showFolderOption]) as SelectPopupItemProps[];

  React.useEffect(() => {
    setFilterCriteria({ ...searchCriteria, ...sortingCriteria } as ChartPayload);
  }, [searchCriteria, sortingCriteria, setFilterCriteria]);

  return (
    <Container>
      <FilterContainer>
        <FilterContainerSection>
          {activeFilters.map(filter => (
            <FilterDropdownContainer key={`active-filter-${filter}`}>
              <DropdownPillMenu
                id={filter}
                icon={iconsMap?.[filter] || 'globe'}
                label={dropdownLabelsMap[filter]}
                items={filterOptionsMap[filter as keyof FilterOptionsMap]}
                onSelect={options => onHandleUpdateSearchCriteria(filter as FilterType, options)}
                multiselect
                removable={true}
                isInitialyOpen={true}
                onRemove={onHandleClearFilter}
              />
            </FilterDropdownContainer>
          ))}
          {showSectionDivider && <FilterSectionDivider />}
          {showAddFilterButton && (
            <DropdownPillMenu
              id='add-filter'
              label={t('common.addFilter')}
              items={addFilterOptions}
              onSelect={onHandleUpdateFilter}
              showArrow={false}
              removeSelectedItem={true}
              isLoading={!filterDataLoaded}
            />
          )}
        </FilterContainerSection>
        {!!activeFilters.length && <ResetButton onClick={onHandleResetAllFilters}>{t('common.clear')}</ResetButton>}
      </FilterContainer>
      {children(
        paginatedChartList as TableListItemProps[],
        isLoading,
        onHandleResetAllFilters,
        onHandleChartListSort,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
      )}
    </Container>
  );
};

export default React.memo(ChartListFilter);
