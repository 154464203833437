import React from 'react';
import Icon from 'common/components/Icon';
import Portal from 'common/portal';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import {
  BottomContainer,
  ModalOverlay,
  ModalSubtitle,
  ModalContainer,
  ModalHeading,
  ModalContent,
  ModalHeader,
  CloseButtonContainer,
} from 'common/components/Modal/styled';

export interface ModalV2Props {
  isOpen: boolean;
  clickOutsideClose?: boolean;
  title?: string;
  subtitle?: string;
  modalId: string;
  customWidth?: number;
  children: React.ReactNode;
  bottomContent?: React.ReactNode;
  customSpacing?: number[];
  onClose: () => void;
}

const ModalV2 = ({
  isOpen,
  clickOutsideClose = true,
  title,
  subtitle,
  modalId,
  children,
  bottomContent,
  customSpacing = [1],
  customWidth = 524,
  onClose,
}: ModalV2Props): JSX.Element => {
  const [ready, setReady] = React.useState(false);
  const innerRef = React.useRef<HTMLDivElement | undefined>();

  React.useEffect(() => {
    if (isOpen) setReady(true);
  }, [isOpen]);

  useOnClickOutside(innerRef as React.RefObject<HTMLElement>, () => {
    isOpen && clickOutsideClose && onClose();
  });

  return (
    <Portal wrapperId={modalId}>
      {isOpen && (
        <ModalOverlay data-ready={ready} aria-hidden={!isOpen}>
          <ModalContainer ref={innerRef as React.RefObject<HTMLDivElement>} spacing={customSpacing} width={customWidth}>
            <ModalHeader>
              {title && (
                <ModalHeading data-testid='modal-title'>
                  {title}
                  {subtitle && <ModalSubtitle data-testid='modal-subtitle'>{subtitle}</ModalSubtitle>}
                </ModalHeading>
              )}
              {clickOutsideClose && (
                <CloseButtonContainer onClick={onClose}>
                  <Icon icon='x' />
                </CloseButtonContainer>
              )}
            </ModalHeader>
            <ModalContent>{children}</ModalContent>
            {bottomContent && <BottomContainer>{bottomContent}</BottomContainer>}
          </ModalContainer>
        </ModalOverlay>
      )}
    </Portal>
  );
};

export default ModalV2;
