import { BrandColorMapType, Brand, BrandGroups, Country } from 'common/types/common';
import { getColorForData } from 'modules/charts/utils/colors';

export const sortDataByPrimaryBrands = (
  a: { id: string; name: string },
  b: { id: string; name: string },
  primaryBrands: string[],
): number => {
  const primaryBrandsAreLabelsNotIds = isNaN(parseInt(primaryBrands?.[0])); //Remove this after brand_groups is done on BE

  // check if either a or b is in the primaryBrands array
  const customerHasBrandA = primaryBrands.includes(primaryBrandsAreLabelsNotIds ? a.name : a.id);
  const customerHasBrandB = primaryBrands.includes(primaryBrandsAreLabelsNotIds ? b.name : b.id);
  if (customerHasBrandA && !customerHasBrandB) {
    return -1; // a will come before b
  } else if (!customerHasBrandA && customerHasBrandB) {
    return 1; // b will come before a
  } else {
    // If neither of them are in primaryBrands, sort them alphabetically
    return a && b ? (a?.name || '').localeCompare(b?.name || '') : 0;
  }
};

export const sortBrandsListByPrimaryBrands = (brands: Brand[], primaryBrands: string[]): Brand[] => {
  const sortedData = [...brands]; // create a copy of the original array to avoid modifying it directly
  sortedData.sort((a, b) =>
    sortDataByPrimaryBrands({ id: a.id.toString(), name: a.name }, { id: b.id.toString(), name: b.name }, primaryBrands),
  );
  return sortedData;
};

export const sortBrandsArrayByOtherBrandsOrder = (newBrands: Brand[], sortByBrands: Brand[]): Brand[] => {
  // Create a dictionary of sortByBrands, mapping their names to their index
  const sortedBrandsDict = {} as Record<string, number>;
  sortByBrands.forEach((brand, index) => {
    sortedBrandsDict[brand.name] = index;
  });

  // Sort the newBrands array based on the index of each brand's name in sortedBrandsDict
  newBrands.sort((a, b) => {
    const aIndex = sortedBrandsDict[a.name];
    const bIndex = sortedBrandsDict[b.name];
    if (aIndex !== undefined && bIndex !== undefined) {
      return aIndex - bIndex;
    } else if (aIndex !== undefined) {
      return -1;
    } else if (bIndex !== undefined) {
      return 1;
    } else {
      // If both brands are not in sortByBrands, sort them alphabetically by name
      return a.name.localeCompare(b.name);
    }
  });

  return newBrands;
};

export const getFilteredChartCountryCodes = (countriesMap: Record<string, Country[]> | undefined, countryIds: number[]): string[] => {
  const filteredCodes = Object.values(countriesMap || {})
    .flatMap(arr => arr)
    .filter(obj => countryIds?.includes(obj.id))
    .map(obj => obj.code);

  return Array.from(new Set(filteredCodes));
};

export const getBrandIds = (competitorGroup: BrandGroups | undefined, chartCountryCodes: string[]): number[] => {
  if (!competitorGroup) return [];
  return chartCountryCodes.flatMap(countryCode => competitorGroup.brand_ids_by_country[countryCode] || []);
};

export const generateBrandsColorMap = (brands: Array<{ id: number; name: string; countries: string[] }>): BrandColorMapType => {
  const brandColorMap: BrandColorMapType = {};
  const countryToBrandsMap: Record<string, string[]> = {};

  // Step 1: Group brands by countries
  brands.forEach(brand => {
    brand.countries.forEach(country => {
      if (!countryToBrandsMap[country]) {
        countryToBrandsMap[country] = [];
      }
      // Avoid duplicates within the country group
      if (!countryToBrandsMap[country].includes(brand.name)) {
        countryToBrandsMap[country].push(brand.name);
      }
    });
  });

  // Step 2: Global brand color assignments
  const brandColorAssignments: Record<string, string> = {};
  let globalIndex = 0; // Start global index from 0

  // Step 3: Assign colors globally across all brands
  brands.forEach(brand => {
    // If the brand doesn't already have an assigned color, assign it
    if (!brandColorAssignments[brand.name]) {
      const color = getColorForData(globalIndex); // Use the global index
      brandColorAssignments[brand.name] = color;
      globalIndex++; // Increment global index for the next brand
    }
  });

  // Step 4: Build the final brand color map based on the global color assignments
  brands.forEach(brand => {
    brandColorMap[brand.name] = brandColorAssignments[brand.name];
  });

  return brandColorMap;
};
