import { useEffect } from 'react';
import { INNER_PADDING } from 'settings/constants';

const compareWidths = (labels: NodeListOf<SVGGElement>, columnGroupWidth: number) =>
  Array.from(labels).some(label => {
    const { width } = label.getBoundingClientRect();
    const rotated = Boolean(label.style.transform);
    // If rotated then calculate diagonal, otherwise take the width
    const labelWidth = rotated ? width * Math.sqrt(2) : width;
    return labelWidth >= columnGroupWidth;
  });

const calculateColumnGroupWidth = (columnsPerGroup: number, columnWidth: number) => {
  const totalInnerPadding = INNER_PADDING * (columnsPerGroup - 1);
  const totalColumnWidth = columnWidth * columnsPerGroup;
  const totalColumnGroupWidth = totalColumnWidth + totalInnerPadding;
  return columnsPerGroup > 1 ? totalColumnGroupWidth : columnWidth;
};

const calculateSingleBarWidth = (chartContainerRef: HTMLDivElement | undefined, totalLabelsLength: number | undefined) => {
  const TOTAL_BAR_WIDTH_THRESHOLD = 500;
  const singleBarElement = chartContainerRef?.querySelector('svg > g > g:nth-child(n+2) > g.bar');
  const singleBarWidth = singleBarElement?.getBoundingClientRect()?.width;
  // [getBoundingClientRect().width] returns wrong bar width value after chart view switch,
  // since it's approximately equal to the sum of the widths of all bars of the chart
  // following tweak been added.
  if (totalLabelsLength && singleBarWidth && singleBarWidth > TOTAL_BAR_WIDTH_THRESHOLD) {
    return singleBarElement.getBoundingClientRect()?.width / totalLabelsLength;
  }

  return singleBarWidth;
};

const getChartLabels = (chartContainerRef: HTMLDivElement | undefined) => {
  // It has all of the X-Axis labels
  const bottomLabelGroup = chartContainerRef?.querySelector('svg > g > g:nth-child(n+4)');
  return bottomLabelGroup?.querySelectorAll('g');
};

export const useRotateChartLabel = (
  chartKeys: string[],
  setGroupWidth: React.Dispatch<React.SetStateAction<number | undefined>>,
  setRotateXlabels: React.Dispatch<React.SetStateAction<boolean>>,
  containerRef: React.MutableRefObject<HTMLDivElement | undefined>,
): void => {
  useEffect(() => {
    if (containerRef?.current) {
      const checkLabelsOverlap = () => {
        const chartLabelList = getChartLabels(containerRef?.current);
        const singleBarWidth = calculateSingleBarWidth(containerRef?.current, chartLabelList?.length);

        if (chartLabelList?.length && singleBarWidth) {
          const columnGroupWidth = calculateColumnGroupWidth(chartKeys.length, singleBarWidth);
          const hasOverlapped = compareWidths(chartLabelList, columnGroupWidth);
          setRotateXlabels(hasOverlapped);
          setGroupWidth(columnGroupWidth);
        } else {
          setTimeout(checkLabelsOverlap);
        }
      };

      /**
       * Purpose - check if two adjacent column labels are overlapping. If it does then rotate the labels.
       * how? - By comparing
       *        1. the total width of either `group of columns` or single column in case there is no groupping and
       *        2. width of the label present on X-axis for that group or column.
       */

      checkLabelsOverlap();

      let timeout: NodeJS.Timeout;
      const scheduleCheck = () => {
        clearTimeout(timeout);
        timeout = setTimeout(checkLabelsOverlap, 300);
      };

      window.addEventListener('resize', scheduleCheck);
      return () => {
        clearTimeout(timeout);
        window.removeEventListener('resize', scheduleCheck);
      };
    }
  }, [chartKeys, setGroupWidth, setRotateXlabels, containerRef]);
};
