import { FilterOption, FilterColumnOption, IFilterColumnOption } from 'common/types/filters';
import { FilterLabels } from 'settings/constants';

interface KPIColumn {
  items: FilterColumnOption[];
  size: number;
}

export class KPIColumns {
  columns: KPIColumn[];
  maxRows: number;
  maxColumns: number;
  columnIndex: number;
  showAll: boolean;
  maxedOut: boolean;
  filterLabel: string;
  isTimePeriodFilter: boolean;
  isKpiFilter: boolean;
  constructor(maxColumns: number, maxRows: number, items: FilterOption[] = [], showAll: boolean, filterLabel = '') {
    this.isTimePeriodFilter = filterLabel === FilterLabels.TimePeriod;
    this.isKpiFilter = filterLabel === FilterLabels.KPI;
    // No calculative columns for [Wave] and thats why Infinity
    this.maxColumns = this.isTimePeriodFilter || this.isKpiFilter ? Infinity : maxColumns;
    this.maxRows = maxRows;
    this.columns = [{ items: [], size: 0 }];
    this.columnIndex = 0;
    this.showAll = showAll;
    this.maxedOut = false;
    this.filterLabel = filterLabel;
    items.slice().forEach((item, index, arr) => {
      // stop early if maxed out
      if (this.maxedOut) {
        arr.length = index + 1;
      } else {
        // add items to columns
        this.push(item);
      }
    });
  }

  get computedMaxRows(): number {
    return this.maxRows - 1 * Number(this.isLastColumn);
  }

  get activeColumn(): KPIColumn {
    return this.columns[this.columnIndex];
  }

  get isLastColumn(): boolean {
    return this.columnIndex === this.maxColumns - 1;
  }

  get columnSizeLeft(): number {
    // in case of showAll last column doesn't have a size limit
    if (this.showAll && this.isLastColumn) {
      return Infinity;
    }

    return this.activeColumn ? this.maxRows - this.activeColumn.size : 0;
  }

  // create a new column if fits
  incColumn(): boolean {
    if (this.columnIndex < this.maxColumns - 1) {
      this.activeColumn.items.push({
        type: 'space',
        size: this.maxRows - this.activeColumn.size,
      });
      this.columns.push({
        items: [],
        size: 0,
      });
      this.columnIndex += 1;
      return true;
    } else {
      return false;
    }
  }

  // check if item fits in column
  fits(item: FilterColumnOption): boolean {
    // in case of showAll, all elements must be show
    // the size checking will be ignored on the last column
    if (this.showAll && this.columnIndex === this.maxColumns - 1) {
      return true;
    }
    const column = this.activeColumn;
    const itemSize = this.getItemSize(item);

    if (this.isKpiFilter && item.type === 'option') {
      return true;
    }

    return column && itemSize <= this.computedMaxRows - column.size;
  }
  isFull(): boolean {
    return !this.showAll && this.columnIndex === this.maxColumns - 1 && this.columns[this.columnIndex].size > this.maxRows - 1;
  }

  // get rows size of element
  getItemSize(item: FilterColumnOption): number {
    const prevElement = this.activeColumn && this.activeColumn.items[this.activeColumn.items.length - 1];
    switch (item.type) {
      case 'group':
        return 1 + item.options.length;
      case 'option': {
        // if previous element is part of another group
        // there must be an offset of 0.5 row size
        const prevGroup = prevElement && (prevElement as IFilterColumnOption).group;
        return prevGroup && prevGroup !== item.group ? 1.5 : 1;
      }
      case 'space':
        return item.size;
    }
  }

  pushToColumn(item: FilterColumnOption): void {
    switch (item.type) {
      case 'group': {
        return this.pushGroupToColumn(item);
      }
      case 'option': {
        return this.pushOptionToColumn(item);
      }
      default:
        return;
    }
  }
  pushOptionToColumn(item: any): void {
    // check if columns are full
    if (this.isFull()) {
      this.maxedOut = true;
      return;
    }
    if (this.fits(item)) {
      // in case options is from a different group
      // add a space
      const prevElement = this.activeColumn && this.activeColumn.items[this.activeColumn.items.length - 1];
      const prevGroup = prevElement && (prevElement as IFilterColumnOption).group;
      const separate = prevGroup && prevGroup !== item.group;
      // add empty space if first element of column is not a group label
      if (this.activeColumn.size === 0 && this.isTimePeriodFilter) {
        this.activeColumn.items.push({
          type: 'space',
          size: 1,
        });
        this.activeColumn.size += 1;
      }
      // add space
      if (separate) {
        this.activeColumn.items.push({
          type: 'space',
          size: 0.5,
        });
      }

      this.activeColumn.items.push({
        ...item,
        label: `${item.label}`,
      });
      // add space to column size
      this.activeColumn.size += separate ? 1.5 : 1;
    } else {
      // if there are available columns push item in a new column
      if (this.incColumn()) {
        this.pushOptionToColumn(item);
      } else {
        // in item doesn't have available rows and columns
        // set maxedOut flage
        this.maxedOut = true;
      }
    }
  }
  pushGroupToColumn(item: any): void {
    // check if columns are full
    if (this.isFull()) {
      this.maxedOut = true;
      return;
    }
    // column requires at least 2.5 rows to fit
    if (this.columnSizeLeft >= 2.5) {
      // if group is not the first element add a space
      if (this.activeColumn.size !== 0) {
        this.activeColumn.items.push({
          type: 'space',
          size: 0.5,
        });
        this.activeColumn.size += 0.5;
      }
      // push group title
      this.activeColumn.items.push({
        ...item,
        label: `${item.label}`,
        options: [],
      });
      this.activeColumn.size += 1;

      // push group options
      item.options.forEach((option: any) =>
        this.push({
          ...option,
          group: item, // add a reference to group
        }),
      );
      if (this.isTimePeriodFilter || this.isKpiFilter) {
        this.incColumn();
      }
    } else {
      // if there are available columns push item in a new column
      if (this.incColumn()) {
        this.pushGroupToColumn(item);
      } else {
        // in item doesn't have available rows and columns
        // set maxedOut flage
        this.maxedOut = true;
      }
    }
  }

  // add item to column
  push(item: FilterOption): void {
    this.pushToColumn(item);
  }
}
