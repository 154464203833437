import { DefaultValues } from 'settings/constants';

export const representValuesInKsOrMs = (value: number): string => {
  if (!value) return DefaultValues.NA;
  return Math.abs(value) >= 999999
    ? `${(Math.abs(value) / 1000000).toFixed() as unknown as number}m`
    : `${(Math.abs(value) / 1000).toFixed() as unknown as number}k`;
};

export const getTargetAudience = (population: number, percentage: number): string => {
  if (!population) return DefaultValues.NA;
  const targetAudience = population * (percentage / 100);
  return representValuesInKsOrMs(targetAudience);
};
