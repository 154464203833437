import { ChartFields, RawChart, RawChartData, RawChartSchema } from 'common/types/chart';
import { MATRIX_CHART_LEFT_MARGIN, MatrixAxisProps, MatrixDatapoint } from '.';
import { getColorForData } from 'modules/charts/utils/colors';
import { BrandColorMapType } from 'common/types/common';
import { DataIndexMap } from './hooks/useIndexMap';
import { FilterNames } from 'common/types/filters';
import { BRAND_PERCEPTION_BAD, BRAND_PERCEPTION_GOOD, BRAND_PERCEPTION_KPI } from 'settings/constants';

export const PERCENTAGE_MULTIPLIER = 100;

/**
 * Converts data coordinates to SVG coordinates
 * @param {number} x - Data x value
 * @param {number} y - Data y value
 * @returns {object} - { x: svgX, y: svgY } SVG coordinates
 */
export const convertToSvgCoordinates = (
  chartWidth: number,
  chartHeight: number,
  yAxis: MatrixAxisProps,
  xAxis: MatrixAxisProps,
  x: number,
  y: number,
) => {
  // Calculate the scaling factors
  const xScale = chartWidth / (xAxis.max - xAxis.min);
  const yScale = chartHeight / (yAxis.max - yAxis.min);

  // Convert data coordinates to SVG coordinates
  const svgX = MATRIX_CHART_LEFT_MARGIN + (x - xAxis.min) * xScale;
  const svgY = chartHeight - (y - yAxis.min) * yScale;

  return { x: svgX, y: svgY };
};

/**
 * Combines objects with the same wave, brand, segment, and country
 * but different KPIs, and returns an array of datapoints with x and y values
 * based on the specified xAxisKpi and yAxisKpi.
 *
 * @param {DataPoint[]} data - The array of data points.
 * @param {string} xAxisKpi - The KPI to be used for xValue.
 * @param {string} yAxisKpi - The KPI to be used for yValue.
 * @returns {MatrixDatapoint[]} - The array of combined datapoints.
 */
export const combineKpiData = (
  data: RawChartData[] | undefined,
  xAxisKpi: string,
  yAxisKpi: string,
  secondDimension: string,
  indexDataMap: DataIndexMap,
  brandsColorMap: BrandColorMapType | null,
  disabledChartDataIds: (string | number)[],
  orderedWaves: number[],
): MatrixDatapoint[] => {
  if (orderedWaves.length === 0) {
    return [];
  }

  const currentWave = orderedWaves.slice(-1)[0];
  const pastWave = orderedWaves[0];

  // Create a map to group data by brand, segment, and country
  const groupedData = new Map<
    string,
    {
      xValue: number | null;
      yValue: number | null;
      xPastValue: number | null;
      yPastValue: number | null;
      label: string;
      id: string | number;
    }
  >();

  data?.forEach(point => {
    // Only process data for the current and past wave
    if (point.wave !== currentWave && point.wave !== pastWave) {
      return;
    }

    const key = `${point.brand}-${point.segment}-${point.country}`;
    const secondDimensionId = point?.[secondDimension as ChartFields];
    const indexData = indexDataMap?.[secondDimension as FilterNames.COUNTRY | FilterNames.BRAND | FilterNames.SEGMENT];
    const label = secondDimensionId ? indexData?.[secondDimensionId as number] : '';

    // Initialize values if not already set
    let entry = groupedData.get(key);
    if (!entry) {
      entry = { xValue: null, yValue: null, xPastValue: null, yPastValue: null, label: label || '', id: secondDimensionId || 0 };
      groupedData.set(key, entry);
    }

    // Update values based on KPI and wave
    if (point.wave === currentWave) {
      if (point.kpi === xAxisKpi) {
        entry.xValue = point.mean * PERCENTAGE_MULTIPLIER;
      } else if (point.kpi === yAxisKpi) {
        entry.yValue = point.mean * PERCENTAGE_MULTIPLIER;
      }
    } else if (point.wave === pastWave) {
      if (point.kpi === xAxisKpi) {
        entry.xPastValue = point.mean * PERCENTAGE_MULTIPLIER;
      } else if (point.kpi === yAxisKpi) {
        entry.yPastValue = point.mean * PERCENTAGE_MULTIPLIER;
      }
    }
  });

  // Convert grouped data to the desired format
  return Array.from(groupedData.values())
    .map((values, currentIndex) => {
      if (values.xValue !== null || values.xPastValue !== null) {
        return {
          xValue: values.xValue ?? null,
          yValue: values.yValue ?? null,
          xPastValue: values.xPastValue ?? null,
          yPastValue: values.yPastValue ?? null,
          color:
            secondDimension === FilterNames.BRAND && brandsColorMap?.[values.label]
              ? brandsColorMap?.[values.label]
              : getColorForData(currentIndex),
          label: values.label,
          id: values.id,
          hidden: isIdDisabled(values.id, disabledChartDataIds),
        };
      }
      return null;
    })
    .filter((datapoint): datapoint is MatrixDatapoint => datapoint !== null);
};

const isIdDisabled = (id: string | number, disabledChartDataIds: (string | number)[]): boolean => disabledChartDataIds.includes(id);

export const calculateMatrixAxisBounds = (data: MatrixDatapoint[]): { xMin: number; xMax: number; yMin: number; yMax: number } => {
  if (data.length === 0) {
    return {
      xMin: 0,
      xMax: 100,
      yMin: 0,
      yMax: 100,
    };
  }

  // Initialize min and max values
  let xMin = Infinity;
  let xMax = -Infinity;
  let yMin = Infinity;
  let yMax = -Infinity;

  // Find min and max values for xValue and yValue
  data.forEach(point => {
    xMin = Math.min(xMin, point.xValue ?? Infinity, point.xPastValue ?? Infinity);
    xMax = Math.max(xMax, point.xValue ?? -Infinity, point.xPastValue ?? -Infinity);
    yMin = Math.min(yMin, point.yValue ?? Infinity, point.yPastValue ?? Infinity);
    yMax = Math.max(yMax, point.yValue ?? -Infinity, point.yPastValue ?? -Infinity);
  });

  // Brand perception e.g. can support negative values
  const hasNegative = [xMin, xMax, yMin, yMax].some(value => value < 0);

  // Calculate range and apply formula
  const xRange = xMax - xMin;
  const yRange = yMax - yMin;

  // Add 1/10 of range
  const xMinAdjusted = xMin - xRange / 10;
  const xMaxAdjusted = xMax + xRange / 10;
  const yMinAdjusted = yMin - yRange / 10;
  const yMaxAdjusted = yMax + yRange / 10;

  // Round values
  const xMinRounded = Math.floor(xMinAdjusted);
  const xMaxRounded = Math.ceil(xMaxAdjusted);
  const yMinRounded = Math.floor(yMinAdjusted);
  const yMaxRounded = Math.ceil(yMaxAdjusted);

  return {
    xMin: !hasNegative && xMinRounded < 0 ? 0 : xMinRounded,
    xMax: xMaxRounded > 100 ? 100 : xMaxRounded,
    yMin: !hasNegative && yMinRounded < 0 ? 0 : yMinRounded,
    yMax: yMaxRounded > 100 ? 100 : yMaxRounded,
  };
};

// Helper function to group and aggregate brand perception data
const groupBrandPerceptionData = (data: RawChartData[]): Map<string, { goodMean?: number; badMean?: number }> => {
  const grouped = new Map<string, { goodMean?: number; badMean?: number }>();

  for (const item of data) {
    if (item.kpi === BRAND_PERCEPTION_GOOD || item.kpi === BRAND_PERCEPTION_BAD) {
      const key = `${item.wave}-${item.brand}-${item.segment}-${item.country}`;
      let group = grouped.get(key);
      if (!group) {
        group = {};
        grouped.set(key, group);
      }

      if (item.kpi === BRAND_PERCEPTION_GOOD) {
        group.goodMean = item.mean;
      } else if (item.kpi === BRAND_PERCEPTION_BAD) {
        group.badMean = item.mean;
      }
    }
  }

  return grouped;
};

// Helper function to create merged KPI data from grouped data
const createMergedKpiData = (groupedData: Map<string, { goodMean?: number; badMean?: number }>): RawChartData[] => {
  const mergedKpiData: RawChartData[] = [];

  for (const [key, { goodMean, badMean }] of groupedData.entries()) {
    if (goodMean !== undefined && badMean !== undefined) {
      const [wave, brand, segment, country] = key.split('-').map(Number);
      mergedKpiData.push({
        wave,
        brand,
        segment,
        country,
        kpi: BRAND_PERCEPTION_KPI,
        mean: goodMean - badMean,
        margin_of_error: 0, // Placeholder
        high: 0, // Placeholder
        low: 0, // Placeholder
        segment_population_weight: 1, // Placeholder
      });
    }
  }

  return mergedKpiData;
};

// Helper function to filter out non-merged KPIs
const filterNonMergedData = (data: RawChartData[]): RawChartData[] => {
  return data.filter(item => item.kpi !== BRAND_PERCEPTION_GOOD && item.kpi !== BRAND_PERCEPTION_BAD);
};

/* 
  In the Matrix chart we can receive two KPIs (brand-perception_good and brand-perception_bad) and we want to merge it into one that is actually net sentiment.
  This function is merging two objects into single brand-perception kpi object
*/
export const mergeBrandPerceptionData = (chart: RawChart): RawChartData[] => {
  const groupedData = groupBrandPerceptionData(chart.data);
  const mergedKpiData = createMergedKpiData(groupedData);
  const nonMergedData = filterNonMergedData(chart.data);

  return [...nonMergedData, ...mergedKpiData];
};

const addSelectedSecondDimensionItemsAsSelectedFilters = (chartSchema: RawChartSchema) => {
  const secondDimension = chartSchema?.configuration?.second_dimension;
  const secondDimensionItems = chartSchema?.configuration?.second_dimension_items || [];

  const filtersNamesMap = {
    wave: 'wave_ids',
    country: 'country_ids',
    brand: 'brand_ids',
    segment: 'segment_ids',
    kpi: 'kpi_option_identifiers',
  } as { [key: string]: string };

  const fieldName = filtersNamesMap[secondDimension] as keyof RawChartSchema;

  return { ...chartSchema, [fieldName]: secondDimensionItems.length > 0 ? secondDimensionItems : filtersNamesMap[secondDimension] };
};

// adjust Matrix chart data
export const adjustMatrixData = (chart: RawChart): RawChart => {
  const chartKpis = chart.chart.kpi_option_identifiers;

  // Check if both 'brand-perception_good' and 'brand-perception_bad' are present
  const hasBoth = chartKpis.includes(BRAND_PERCEPTION_BAD) && chartKpis.includes(BRAND_PERCEPTION_GOOD);

  const firstIndex = Math.min(chartKpis.indexOf(BRAND_PERCEPTION_BAD), chartKpis.indexOf(BRAND_PERCEPTION_GOOD));

  const updatedKpis = hasBoth
    ? [
        ...chartKpis.slice(0, firstIndex), // Items before the first occurrence
        BRAND_PERCEPTION_KPI, // Replacement item
        ...chartKpis.slice(firstIndex + 1).filter(item => item !== BRAND_PERCEPTION_BAD && item !== BRAND_PERCEPTION_GOOD), // Items after the first occurrence, excluding the removed items
      ]
    : chartKpis;
  chart.chart.kpi_option_identifiers = updatedKpis;

  if (hasBoth) {
    chart.data = mergeBrandPerceptionData(chart);
  }

  return { ...chart, chart: addSelectedSecondDimensionItemsAsSelectedFilters(chart.chart) };
};
