import { coloursPalette } from 'theme/colours';

export const MARKET_FUNNEL_COLORS = {
  [coloursPalette.blue500]: { dark: coloursPalette.blue800, light: coloursPalette.blue200 },
  [coloursPalette.orange400]: { dark: coloursPalette.orange700, light: coloursPalette.orange100 },
  [coloursPalette.mint400]: { dark: coloursPalette.mint700, light: coloursPalette.mint100 },
  [coloursPalette.indigo500]: { dark: coloursPalette.indigo800, light: coloursPalette.indigo200 },
  [coloursPalette.pink400]: { dark: coloursPalette.pink700, light: coloursPalette.pink100 },
  [coloursPalette.purple500]: { dark: coloursPalette.purple800, light: coloursPalette.purple200 },
  [coloursPalette.green400]: { dark: coloursPalette.green700, light: coloursPalette.green100 },
  [coloursPalette.yellow500]: { dark: coloursPalette.yellow700, light: coloursPalette.yellow200 },
  [coloursPalette.gold500]: { dark: coloursPalette.gold700, light: coloursPalette.gold200 },
  [coloursPalette.purple700]: { dark: coloursPalette.purple800, light: coloursPalette.purple300 },
  [coloursPalette.cyan500]: { dark: coloursPalette.cyan800, light: coloursPalette.cyan200 },
  [coloursPalette.mint700]: { dark: coloursPalette.mint800, light: coloursPalette.mint300 },
  [coloursPalette.pink700]: { dark: coloursPalette.pink800, light: coloursPalette.pink300 },
  [coloursPalette.red400]: { dark: coloursPalette.red700, light: coloursPalette.red100 },
  [coloursPalette.pink800]: { dark: coloursPalette.pink900, light: coloursPalette.pink300 },
  [coloursPalette.purple300]: { dark: coloursPalette.purple600, light: coloursPalette.purple100 },
  [coloursPalette.indigo700]: { dark: coloursPalette.indigo800, light: coloursPalette.indigo200 },
  [coloursPalette.green700]: { dark: coloursPalette.green800, light: coloursPalette.green200 },
  [coloursPalette.orange600]: { dark: coloursPalette.orange700, light: coloursPalette.orange200 },
  [coloursPalette.purple800]: { dark: coloursPalette.purple900, light: coloursPalette.purple300 },
  [coloursPalette.blue700]: { dark: coloursPalette.blue900, light: coloursPalette.blue300 },
  [coloursPalette.orange200]: { dark: coloursPalette.orange500, light: coloursPalette.orange50 },
  [coloursPalette.mint200]: { dark: coloursPalette.mint500, light: coloursPalette.mint50 },
  [coloursPalette.indigo200]: { dark: coloursPalette.indigo500, light: coloursPalette.indigo50 },
  [coloursPalette.pink200]: { dark: coloursPalette.pink500, light: coloursPalette.pink50 },
  [coloursPalette.purple200]: { dark: coloursPalette.purple500, light: coloursPalette.purple50 },
  [coloursPalette.green200]: { dark: coloursPalette.green500, light: coloursPalette.green50 },
  [coloursPalette.yellow200]: { dark: coloursPalette.yellow500, light: coloursPalette.yellow50 },
  [coloursPalette.gold200]: { dark: coloursPalette.gold500, light: coloursPalette.gold50 },
  [coloursPalette.blue200]: { dark: coloursPalette.blue500, light: coloursPalette.blue50 },
};
