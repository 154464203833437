import React from 'react';
import styled from '@emotion/styled';
import { MOEIconComponent } from 'icons';

const Wrapper = styled.label``;

const TumblerContainer = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.space(0.5)};
  &:hover {
    background: ${({ theme }) => theme.colors.lightPurple.alpha(0.1).toString()}
`;

const HiddenInput = styled.input`
  display: none;
  &:checked + ${TumblerContainer} {
    background-color: ${({ theme }) => theme.colors.lightPurple.alpha(0.1).toString()};
  }
`;

const Tumbler: React.FC<{ onChange: () => void; checked: boolean }> = ({ ...props }) => (
  <Wrapper>
    <HiddenInput type='checkbox' {...props} />
    <TumblerContainer>
      <MOEIconComponent />
    </TumblerContainer>
  </Wrapper>
);

export default Tumbler;
