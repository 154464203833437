import { ChartFields, ChartFormData, ChartType } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';
import { useMemo } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

export const useEnabledFilters = (
  errors: DeepMap<ChartFormData, FieldError>,
  activeFilter: string | undefined,
  chartType: ChartType,
): [boolean, Record<FilterNames, boolean>] => {
  return useMemo(() => {
    const canSubmit = !Object.keys(errors).length;
    const { wave, country, kpi, brand } = errors;
    const isGrowthPerformanceChart = chartType === ChartType.GROWTH_PERFORMANCE;
    const isHeatmapChart = chartType === ChartType.HEATMAP;
    const isBrandPerception = chartType === ChartType.BRAND_PERCEPTION;
    const isMarketFunnel = chartType === ChartType.MARKET_FUNNEL;
    const disableWave = isGrowthPerformanceChart || isBrandPerception || isMarketFunnel;
    const disableKpi = isBrandPerception || isMarketFunnel;
    const enableBrand = isHeatmapChart || isBrandPerception || isMarketFunnel;

    const enabledFilters: Record<FilterNames, boolean> = {
      country: true,
      wave: disableWave ? false : !country, // Disable filter for specific chart types
      kpi: disableKpi ? false : !isGrowthPerformanceChart ? !wave && activeFilter !== ChartFields.COUNTRY : true,
      brand: enableBrand ? true : !kpi && activeFilter !== ChartFields.COUNTRY,
      segment: !brand,
    };

    return [canSubmit, enabledFilters];
    // eslint-disable-next-line
  }, [errors.wave, errors.country, errors.kpi, errors.brand, errors.segment, activeFilter]);
};
