import React from 'react';
import styled from '@emotion/styled';

interface TooltipProps {
  x: number;
  y: number;
  children: React.ReactNode;
}

const StyledTooltip = styled.div<{ x: number; y: number }>`
  position: absolute;
  left: ${props => `${props.x}px`};
  top: ${props => `${props.y}px`};
  padding: ${({ theme }) => theme.space(1, 2)};
  background-color: ${({ theme }) => theme.v2.colours.gray900};
  color: ${({ theme }) => theme.v2.colours.gray0};
  border-radius: ${({ theme }) => theme.space(0.5)};
  pointer-events: none;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  z-index: 10;
`;

const MatrixChartTooltip: React.FC<TooltipProps> = ({ x, y, children }) => {
  return (
    <StyledTooltip x={x} y={y}>
      {children}
    </StyledTooltip>
  );
};

export default React.memo(MatrixChartTooltip);
