import * as React from 'react';
import {
  ChartType,
  ChartViews,
  GeneratedChartData,
  MOEStatus,
  ChartNumbersType,
  RawChartSchema,
  SortOption,
  SortType,
  ConesSortingKeys,
} from 'common/types/chart';
import {
  ArrowDownLongBlack,
  ArrowUpLongBlack,
  ExpandIcon,
  NumbersIcon,
  PercentIcon,
  ArrowsMergeIcon,
  RankIcon,
  SortIcon,
  SwitchBarsIcon,
  SwitchChartView,
  SwitchDimension,
  SwitchLineIcon,
  ArrowsLeftRight,
  TrendUpIcon,
} from 'icons';
import {
  useUpdateChartSort,
  useUpdateChartValueRepresentation,
  useUpdateChartView,
  useUpdateMatrixChartChangeOverTime,
  useUpdateMoeStatus,
} from 'common/hooks/charts';
import { FilterNames } from 'common/types/filters';
import DropDownWithIcon, { DropDownItemProps, DropdownAlign, IconItem, SelectedItem } from 'common/components/DropDownWithIcon';
import { useTranslation } from 'react-i18next';
import { DataTestId } from 'settings/constants';
import { ChartToolbarContainer, ToolbarItemContainer, ToolbarExpand } from './styled';
import Tumbler from 'common/components/Form/Tumbler';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { useTracking } from 'common/hooks/tracking';
import { withTooltip } from 'common/components/Tooltip/withTooltip';
import { useIsPopulationNumbersEnabled } from 'common/hooks/features';

interface ChartToolbar {
  ariaHidden: boolean;
  inPage: boolean;
  rawChart: RawChartSchema;
  finalChartData: GeneratedChartData;
  chartView: ChartViews | null;
  chartNumbersType: ChartNumbersType;
  selectedFirstDimension: FilterNames;
  setSelectedFirstDimension: React.Dispatch<React.SetStateAction<FilterNames>>;
  setChartView: React.Dispatch<React.SetStateAction<ChartViews | null>>;
  setChartNumbersType: React.Dispatch<React.SetStateAction<ChartNumbersType>>;
  isChartScrolled: boolean;
  setShowRightArrow: React.Dispatch<React.SetStateAction<boolean>>;
  setMoeStatus: React.Dispatch<React.SetStateAction<boolean>>;
  moeStatus: boolean;
  toggleAxisSwitch: () => void;
  chartSortingProp: { chartSorting: SortOption; setChartSorting: React.Dispatch<React.SetStateAction<SortOption>> };
  onMarketFunnelSort: <T>(sortingType: T) => void;
  marketFunnelSorting: SortOption;
  showMatrixTrendOverTime: boolean;
  setShowMatrixTrendOverTime: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChartToolbar: React.FC<ChartToolbar> = (
  {
    ariaHidden,
    inPage,
    rawChart,
    finalChartData,
    chartView,
    chartNumbersType = ChartNumbersType.PERCENTAGE,
    selectedFirstDimension,
    setSelectedFirstDimension,
    setChartView,
    setChartNumbersType,
    isChartScrolled,
    setShowRightArrow,
    setMoeStatus,
    moeStatus,
    toggleAxisSwitch,
    chartSortingProp,
    onMarketFunnelSort,
    marketFunnelSorting,
    showMatrixTrendOverTime,
    setShowMatrixTrendOverTime,
  },
  ref,
) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { chartSorting, setChartSorting } = chartSortingProp;
  const {
    study_uuid: currentStudyId,
    configuration: { first_dimension, second_dimension },
    chart_type: chartType,
    uuid: chartUuid,
    margin_of_error_status: moeStatusEnabled,
    wave_ids,
  } = rawChart;
  const { keys: chartKeysList } = finalChartData;
  const isGroupedBySegment = first_dimension === FilterNames.SEGMENT;
  const isGroupedByWave = first_dimension === FilterNames.WAVE || second_dimension === FilterNames.WAVE;

  const { updateChartCurrentSort } = useUpdateChartSort();
  const { updateChartCurrentView } = useUpdateChartView();
  const { updateChartMoeStatus } = useUpdateMoeStatus();
  const { updateChartValueRepresentation } = useUpdateChartValueRepresentation();
  const { updateMatrixChangeOverTime } = useUpdateMatrixChartChangeOverTime();
  const isPercentageAbsoluteSwitchEnabled = useIsPopulationNumbersEnabled(currentStudyId);
  const hasMultipleWaves = wave_ids?.length > 1;

  const chartUrl = React.useMemo(() => {
    return `/chart/${chartUuid}`;
  }, [chartUuid]);

  const isMarketFunnel = React.useMemo(() => {
    return chartType === ChartType.MARKET_FUNNEL;
  }, [chartType]);

  const isFunnel = React.useMemo(() => {
    return chartType === ChartType.FUNNEL;
  }, [chartType]);

  const isComparisonChart = React.useMemo(() => {
    return chartType === ChartType.COMPARISON;
  }, [chartType]);

  const isMarketSize = React.useMemo(() => {
    return chartType === ChartType.MARKET_SIZE;
  }, [chartType]);

  const isGaugeChart = React.useMemo(() => {
    return chartType === ChartType.GAUGE;
  }, [chartType]);

  const isGpChart = React.useMemo(() => {
    return chartType === ChartType.GROWTH_PERFORMANCE;
  }, [chartType]);

  const isMatrixChart = React.useMemo(() => {
    return chartType === ChartType.MATRIX;
  }, [chartType]);

  const createDropdownItem = <T,>(value: T, label: string, icon?: string): DropDownItemProps<T> => ({
    value,
    label,
    icon,
  });

  const comparisonChartSortingOptions: Array<DropDownItemProps<string>> = React.useMemo(() => {
    return [
      createDropdownItem(SortType.ASC, t('chart.chartOptions.ascending'), ArrowUpLongBlack),
      createDropdownItem(SortType.DESC, t('chart.chartOptions.descending'), ArrowDownLongBlack),
      createDropdownItem(SortType.DEFAULT, t('chart.chartOptions.default'), SortIcon),
    ];
  }, [t]);

  const marketFunnelSortingOptions: Array<DropDownItemProps<SortOption>> = React.useMemo(() => {
    return [
      createDropdownItem({ key: ConesSortingKeys.INDEX, type: SortType.DESC }, t('chart.chartOptions.label')),
      createDropdownItem({ key: ConesSortingKeys.POPULATION, type: SortType.DESC }, t('chart.chartOptions.segmentSize')),
      createDropdownItem({ key: ConesSortingKeys.AWARENESS, type: SortType.DESC }, t('chart.chartOptions.brandAwareness')),
      createDropdownItem({ key: ConesSortingKeys.CONSIDERATION, type: SortType.DESC }, t('chart.chartOptions.brandConsideration')),
    ];
  }, [t]);

  const dimensionOptions = React.useMemo(() => {
    if (!first_dimension || !second_dimension) return [];

    const createOption = (value: FilterNames): { value: FilterNames; label: string } => ({
      value,
      label: t(`filterLabels.${value}`),
    });

    return [createOption(first_dimension), createOption(second_dimension)];
  }, [first_dimension, second_dimension, t]);

  const isLineChartEnabled = React.useMemo(() => {
    return isComparisonChart && isGroupedByWave;
  }, [isGroupedByWave, isComparisonChart]);

  const isSingleGrouping = React.useCallback(chart => {
    const filterId = ['brand_ids', 'country_ids', 'kpi_option_identifiers', 'segment_ids', 'wave_ids'];
    const multipleValues = filterId.filter(name => chart && chart[name]?.length > 1);
    return multipleValues.length <= 1;
  }, []);

  const chartViewOptions = React.useMemo(() => {
    const createViewOption = (value: ChartViews, label: string, icon: string, disabled?: boolean) => ({
      value,
      label: t(`chart.chartOptions.${label}`),
      icon,
      disabled,
    });

    return [
      createViewOption(ChartViews.BAR, 'bars', SwitchBarsIcon),
      createViewOption(ChartViews.LINE, 'lines', SwitchLineIcon, !isLineChartEnabled),
      createViewOption(ChartViews.HORIZONTAL, 'rank', RankIcon, !isSingleGrouping(rawChart)),
    ];
  }, [isLineChartEnabled, isSingleGrouping, rawChart, t]);

  const percentageAbsoluteSwitchOptions = React.useMemo(() => {
    const options = [
      {
        value: ChartNumbersType.PERCENTAGE,
        label: t('chart.chartOptions.percentage'),
        icon: PercentIcon,
      },
      {
        value: ChartNumbersType.VALUES,
        label: t('chart.chartOptions.values'),
        icon: NumbersIcon,
      },
    ];

    if (isGpChart) {
      options.push({
        value: ChartNumbersType.BOTH,
        label: t('chart.chartOptions.both'),
        icon: ArrowsMergeIcon,
      });
    }

    return options;
  }, [t, isGpChart]);

  const getChartDimension = React.useCallback(
    (option: ChartViews) => {
      return option === ChartViews.LINE && selectedFirstDimension === FilterNames.WAVE ? second_dimension : selectedFirstDimension;
    },
    [second_dimension, selectedFirstDimension],
  );

  const onDimensionChange = React.useCallback(
    <T,>(value: T): void => {
      if (chartView) {
        setSelectedFirstDimension(value as FilterNames);
        updateChartCurrentView(rawChart, chartView, value as FilterNames, currentStudyId);
      }
    },
    [setSelectedFirstDimension, updateChartCurrentView, rawChart, chartView, currentStudyId],
  );

  const onChartViewChange = React.useCallback(
    <T,>(option: T) => {
      setChartView(option as ChartViews);
      const showRightArrow = option === ChartViews.BAR && !isChartScrolled;

      if (ref) {
        setShowRightArrow(showRightArrow);
      }

      if (chartUuid) {
        updateChartCurrentView(rawChart, option as ChartViews, getChartDimension(option as ChartViews), currentStudyId);
      }
    },
    [rawChart, updateChartCurrentView, isChartScrolled, currentStudyId, getChartDimension, ref, setChartView, setShowRightArrow, chartUuid],
  );

  const onPercentageAbsoluteChange = React.useCallback(
    <T,>(option: T) => {
      setChartNumbersType(option as ChartNumbersType);
      updateChartValueRepresentation(rawChart, option as string);
    },
    [setChartNumbersType, rawChart, updateChartValueRepresentation],
  );

  const onChartSortingChange = React.useCallback(
    <T,>(sortingType: T) => {
      setChartSorting({ key: 'value_0', type: sortingType as SortType });
      updateChartCurrentSort(rawChart, { key: 'value_0', type: sortingType as SortType });
    },
    [updateChartCurrentSort, setChartSorting, rawChart],
  );

  const onToggleMoe = (): void => {
    trackEvent(TRACKING_EVENTS.APPEARANCE_MOE_TOGGLE);
    const newMoeStatus = moeStatus ? MOEStatus.DISABLED : MOEStatus.ENABLED;
    setMoeStatus(prevState => !prevState);
    updateChartMoeStatus(rawChart, newMoeStatus);
  };

  const onMatrixChangeOverTimeToggle = React.useCallback(() => {
    setShowMatrixTrendOverTime(!showMatrixTrendOverTime);
    updateMatrixChangeOverTime(rawChart, !showMatrixTrendOverTime);
  }, [setShowMatrixTrendOverTime, rawChart, updateMatrixChangeOverTime, showMatrixTrendOverTime]);

  const iShowMoeToggle = !!moeStatusEnabled && isComparisonChart;
  const isShowChartViewToggle = (isFunnel || isComparisonChart) && chartView;
  const isShowDimensionToggle = isComparisonChart && chartView !== ChartViews.LINE;
  const isShowMarketFunnelSorting = isMarketFunnel && isPercentageAbsoluteSwitchEnabled && isGroupedBySegment;

  const isShowChartOptionsToggle =
    isPercentageAbsoluteSwitchEnabled && !inPage && !isMarketSize && !isGaugeChart && !isMatrixChart && !isMarketFunnel;
  const isShowComparisonChartSorting =
    isComparisonChart && chartView && [ChartViews.BAR, ChartViews.HORIZONTAL].includes(chartView) && chartKeysList.length === 1;

  return (
    <ChartToolbarContainer aria-hidden={ariaHidden} data-indashboard={inPage}>
      {isShowChartOptionsToggle &&
        withTooltip(
          <ToolbarItemContainer>
            <DropDownWithIcon
              options={percentageAbsoluteSwitchOptions}
              onSelect={onPercentageAbsoluteChange}
              selectedValue={chartNumbersType}
              toolbarIcon={SwitchChartView}
              label={t('chart.chartOptions.displayNumbers')}
              inDashboard={inPage}
              align={DropdownAlign.RIGHT}
              testId={DataTestId.CHART_NUMBERS_CHANGE}
            />
          </ToolbarItemContainer>,
          t('chart.tooltip.displayNumbers'),
        )}
      {iShowMoeToggle &&
        withTooltip(
          <ToolbarItemContainer>
            <Tumbler onChange={onToggleMoe} checked={moeStatus} />
          </ToolbarItemContainer>,
          t('chart.chartOptions.moe'),
        )}
      {isShowComparisonChartSorting &&
        withTooltip(
          <ToolbarItemContainer>
            <DropDownWithIcon
              inDashboard={inPage}
              toolbarIcon={SortIcon}
              align={DropdownAlign.RIGHT}
              onSelect={onChartSortingChange}
              testId={DataTestId.CHART_SORT_MENU}
              showIconAndLabel={isComparisonChart}
              label={t('chart.chartOptions.sortBy')}
              options={comparisonChartSortingOptions}
              defaultText={t('chart.chartOptions.sort')}
              selectedValue={chartSorting.type ?? SortType.DEFAULT}
            />
          </ToolbarItemContainer>,
          t('chart.tooltip.sortBy'),
        )}
      {isShowMarketFunnelSorting &&
        withTooltip(
          <ToolbarItemContainer>
            <DropDownWithIcon
              inDashboard={inPage}
              toolbarIcon={SortIcon}
              align={DropdownAlign.RIGHT}
              onSelect={onMarketFunnelSort}
              showDefaultText={isMarketFunnel}
              selectedValue={marketFunnelSorting}
              options={marketFunnelSortingOptions}
              label={t('chart.chartOptions.sortBy')}
              defaultText={t('chart.chartOptions.sort')}
              testId={DataTestId.CHART_SORT_MENU}
            />
          </ToolbarItemContainer>,
          t('chart.tooltip.sortBy'),
        )}
      {isShowDimensionToggle &&
        withTooltip(
          <ToolbarItemContainer>
            <DropDownWithIcon
              options={dimensionOptions}
              onSelect={onDimensionChange}
              selectedValue={selectedFirstDimension}
              toolbarIcon={SwitchDimension}
              label={t('chart.chartOptions.groupBy')}
              align={DropdownAlign.RIGHT}
              renderInBody
              inDashboard={inPage}
              testId={DataTestId.CHART_GROUPBY_MENU}
            />
          </ToolbarItemContainer>,
          t('chart.tooltip.groupBy'),
        )}
      {isShowChartViewToggle &&
        withTooltip(
          <ToolbarItemContainer>
            <DropDownWithIcon
              options={chartViewOptions}
              onSelect={onChartViewChange}
              selectedValue={chartView}
              toolbarIcon={SwitchChartView}
              label={t('chart.chartOptions.chartView')}
              align={DropdownAlign.RIGHT}
              inDashboard={inPage}
              testId={DataTestId.CHANGE_VIEW_MENU}
            />
          </ToolbarItemContainer>,
          t('chart.tooltip.changeView'),
        )}
      {isMatrixChart &&
        hasMultipleWaves &&
        withTooltip(
          <ToolbarItemContainer>
            <SelectedItem onClick={() => onMatrixChangeOverTimeToggle()} active={true}>
              <IconItem icon={TrendUpIcon} active={showMatrixTrendOverTime} />
            </SelectedItem>
          </ToolbarItemContainer>,
          t('chart.chartOptions.trendOverTime'),
        )}
      {isMatrixChart &&
        withTooltip(
          <ToolbarItemContainer>
            <SelectedItem onClick={() => toggleAxisSwitch()}>
              <IconItem icon={ArrowsLeftRight} />
            </SelectedItem>
          </ToolbarItemContainer>,
          t('chart.chartOptions.switchAxis'),
        )}

      {inPage &&
        withTooltip(
          <ToolbarExpand to={chartUrl}>
            <SelectedItem>
              <IconItem icon={ExpandIcon} />
            </SelectedItem>
          </ToolbarExpand>,
          t('chart.tooltip.openAsFullPage'),
        )}
    </ChartToolbarContainer>
  );
};

export default ChartToolbar;
