import { chartPalette, coloursPalette, greyPalette } from 'theme/colours';
import { space } from 'theme/spacing';
import { typography } from 'theme/typography';

export const theme = {
  colours: {
    ...coloursPalette,
    ...greyPalette,
  },
  space,
  text: {
    fontFamily: 'Mulish',
    fontWeight: 400,
    variants: {
      ...typography,
    },
  },
  chart: {
    colorPalette: chartPalette,
  },
  borderRadius: 4,
};
