import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { theme } from 'common/theme';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PillCheckbox from 'common/components/PillCheckbox';
import { Button, LoadingButton } from 'common/components/Form/Button';
import SettingsHeader from 'modules/settings/components/SettingsHeader';
import { Text } from 'common/styledComponents/typography';
import { useGetAllBrands } from 'common/queries/brands';
import { LoadingContainer } from 'common/styledComponents/containers';
import Spinner from 'common/components/Spinner';
import { useGetCompetitorsGroup, useUpdateCreateBrandGroup } from 'common/hooks/brandGroups';
import { useGetPrimaryBrands } from 'common/hooks/brands';
import { getCompetitorsList } from 'modules/settings/components/Competitors/getCompetitorsList';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS, SCREENS } from 'common/types/tracking';
import { useGetAllCountriesUniqueList } from 'common/hooks/countries';

const BlockDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.space(0, 0, 4, 0)}`};
  > p {
    margin: ${({ theme }) => `${theme.space(0, 0, 1, 0)}`};
  }
`;

const CheckboxSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  > label {
    margin: ${({ theme }) => `${theme.space(0, 1, 1, 0)}`};
  }
`;

const CountrySection = styled.div`
  margin: ${({ theme }) => `${theme.space(0, 0, 4, 0)}`};
  > h6 {
    margin: ${({ theme }) => `${theme.space(0, 0, 1.5, 0)}`};
  }
`;

type CompetitorsForm = Record<string, Array<number>>;

const Fallback: React.FC = () => (
  <LoadingContainer>
    <Spinner />
  </LoadingContainer>
);

const Competitors = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();

  const { data: brandsList } = useGetAllBrands();
  const { updateCreateBrandGroup, requestState: isRequestOngoing } = useUpdateCreateBrandGroup();
  const competitors = useGetCompetitorsGroup();
  const primaryBrands = useGetPrimaryBrands();
  const allCountries = useGetAllCountriesUniqueList();

  useEffect(() => {
    trackEvent(TRACKING_EVENTS.VIEW_PAGE, {
      screen: SCREENS.COMPETITORS,
    });
  }, [trackEvent]);

  const competitorSectionsList = React.useMemo(() => {
    return getCompetitorsList(brandsList, primaryBrands, allCountries);
  }, [brandsList, primaryBrands, allCountries]);

  const { register, handleSubmit, formState, getValues, setValue, trigger, reset } = useForm<CompetitorsForm>();

  useEffect(() => {
    reset(competitors?.brand_ids_by_country);
  }, [reset, competitors?.brand_ids_by_country]);

  const onHandleOptionChange = React.useCallback(
    (fieldName: string, brandId: number) => {
      const fieldState = getValues(fieldName);
      const newValue = fieldState ? toggleOption(fieldState, brandId) : [brandId];
      setValue(fieldName, newValue, { shouldDirty: true });
      trigger();
    },
    [getValues, trigger, setValue],
  );

  const toggleOption = (fieldState: Array<number>, value: number): Array<number> => {
    return fieldState.includes(value) ? fieldState.filter(option => option !== value) : [...fieldState, value];
  };

  const isOptionSelected = React.useCallback(
    (field: string, brandId: number) => {
      const fieldState = getValues(field);
      return fieldState && fieldState.includes(brandId);
    },
    [getValues],
  );

  const onSubmit = React.useCallback(
    (formData: CompetitorsForm) => {
      trackEvent(TRACKING_EVENTS.SAVE_COMPETITORS);
      updateCreateBrandGroup(formData, competitors?.id.toString());
    },
    [competitors, updateCreateBrandGroup, trackEvent],
  );

  const onCancelUpdate = React.useCallback(() => {
    reset({ ...competitors?.brand_ids_by_country });
  }, [reset, competitors?.brand_ids_by_country]);

  if (!competitorSectionsList) return <Fallback />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SettingsHeader title={t('settings.orgProfile.title')}>
        {formState?.isDirty && (
          <LoadingButton type='submit' color='turqoise' loading={isRequestOngoing}>
            {t('common.save')}
          </LoadingButton>
        )}
        {formState?.isDirty && (
          <Button type='button' onClick={onCancelUpdate} color='black05' hoverColor='rgb(0,0,0,0.05)' pseudo>
            {t('common.cancel')}
          </Button>
        )}
      </SettingsHeader>
      <BlockDescriptionWrapper>
        <Text variant='body2' color='black'>
          {t('settings.orgProfile.subTitle')}
        </Text>
        <Text fontWeight='500' variant='body2' color={theme.colors.moreThanAWeek.toString() as 'inherit'}>
          {t('settings.orgProfile.sectionDescription')}
        </Text>
      </BlockDescriptionWrapper>
      {competitorSectionsList?.map(({ label, countryCode, brands }) => (
        <CountrySection key={label}>
          <Text variant='subheading1' color='black'>
            {label}
          </Text>
          <CheckboxSection>
            {brands.map(brand => (
              <PillCheckbox
                key={brand.id}
                name={countryCode}
                value={brand.id}
                label={brand.name}
                ref={register(countryCode) as unknown as React.Ref<HTMLInputElement>}
                selected={isOptionSelected(countryCode, brand.id) || false}
                onChange={() => onHandleOptionChange(countryCode, brand.id)}
              />
            ))}
          </CheckboxSection>
        </CountrySection>
      ))}
    </form>
  );
};

export default Competitors;
