const p = (x: number, y: number) => {
  return x + ' ' + y + ' ';
};

/**
 *
 * @param x - x position
 * @param y - y position
 * @param width - width
 * @param height - height
 * @param r1 - upper left corner
 * @param r2 - upper right corner
 * @param r3 - lower right corner
 * @param r4 - lower left corner
 */

export const roundedRectangle = (
  x: number,
  y: number,
  width: number,
  height: number,
  r1: number,
  r2: number,
  r3: number,
  r4: number,
): string => {
  let strPath = 'M' + p(x + r1, y); //A
  strPath += 'L' + p(x + width - r2, y) + 'Q' + p(x + width, y) + p(x + width, y + r2); //B
  strPath += 'L' + p(x + width, y + height - r3) + 'Q' + p(x + width, y + height) + p(x + width - r3, y + height); //C
  strPath += 'L' + p(x + r4, y + height) + 'Q' + p(x, y + height) + p(x, y + height - r4); //D
  strPath += 'L' + p(x, y + r1) + 'Q' + p(x, y) + p(x + r1, y); //A
  strPath += 'Z';
  return strPath;
};
