import { useRollbar } from '@rollbar/react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { Study } from 'common/types/account';
import { useRecoilValue } from 'recoil';
import { LogArgument } from 'rollbar';
import { API_URLS } from 'settings/api';

export const useGetAllStudies = (): UseQueryResult<Array<Study>, unknown> => {
  const { fetchGet } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();
  return useQuery(
    ['allStudies'],
    async () => {
      try {
        return await fetchGet<Study[]>(API_URLS.STUDIES);
      } catch (e: unknown) {
        rollbar.info('useGetAllStudies error', e as LogArgument);
      }
    },
    {
      staleTime: Infinity,
      placeholderData: [],
    },
  );
};
