import * as React from 'react';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import { useTranslation } from 'react-i18next';
import { PageNotificationTypes } from 'common/types/notification';
import { useNavigate } from 'react-router-dom';

export const NotificationContainer = styled.div`
  width: 460px;
  height: auto;
  padding: ${({ theme }) => theme.space(6)};
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px solid ${({ theme }) => theme.colors.borderGrey.toString()};
  border-radius: 8px;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const NotificationText = styled(Text)`
  margin: ${({ theme }) => theme.space(0, 0, 3)};
`;

interface Props {
  type?: PageNotificationTypes;
  defaultUrl?: string;
}

const Notification: React.FC<Props> = ({ type, defaultUrl }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!type) {
    return null;
  }

  // redirect non existing page to first chart or chart create
  if (type === 'missingPage' && defaultUrl) {
    setTimeout(() => {
      navigate(defaultUrl);
    });
    return null;
  }

  return (
    <NotificationContainer>
      <NotificationText variant='h5'>{t(`chart.notifications.${type}.title`)}</NotificationText>
      <NotificationText variant='body1'>{t(`chart.notifications.${type}.description`)}</NotificationText>
      <NotificationText variant='h4'>{t(`chart.notifications.${type}.subtext`)}</NotificationText>
    </NotificationContainer>
  );
};

export default React.memo(Notification);
