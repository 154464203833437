import * as React from 'react';
import { theme } from 'common/theme';
import { AxisProps, AxisValue } from '@nivo/axes';
import { chartTheme } from 'modules/charts/theme';
import styled from '@emotion/styled';
import { displayDate } from 'utils/dates';
import { Wave } from 'common/types/common';
import { chartValueInMsOrKs } from 'utils/helpers';

export const LineAxisRight = {
  tickSize: theme.spacing.unit * 8,
  format: (): string => '',
};

export const LineAxisLeft = (numeric: boolean, showPopulationNumbers: boolean): AxisProps<AxisValue> =>
  ({
    tickSize: 0,
    tickPadding: theme.spacing.unit,
    renderTick: ({ value, textAnchor, rotate, x, y, textBaseline }) => {
      return (
        <g transform={`translate(${x},${y})`}>
          <line x1='0' x2='-64' y1='0' y2='0' style={{ strokeWidth: 1, stroke: theme.colors.mercury.toString() }} />
          <text
            textAnchor={textAnchor}
            dominantBaseline={textBaseline}
            transform={`translate(-72, 0) rotate(${rotate})`}
            style={{
              fontSize: theme.text.variants.p.fontSize,
              fontWeight: theme.text.variants.p.fontWeight,
              fill: theme.colors.moreThanAWeek.toString(),
            }}
          >
            <title>{showPopulationNumbers ? chartValueInMsOrKs(value, null) : value}</title>
            {`${showPopulationNumbers ? chartValueInMsOrKs(value, null) : value}${!numeric ? '%' : ''}`}
          </text>
        </g>
      );
    },
  } as AxisProps);

const BottomLabel = styled.text`
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

// eslint-disable-next-line react/display-name
const BottomTick: (waveMap: Record<number, Wave>) => React.FC<any> =
  (waveMap: Record<number, Wave>) =>
  // eslint-disable-next-line react/display-name
  ({ value, x, y, textX, textY, textBaseline, textAnchor }) => {
    const wave = waveMap[value];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const labelRef = React.useRef<SVGTextElement>() as React.MutableRefObject<SVGTextElement>;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dateLabel = React.useMemo(() => {
      return displayDate(wave.date, 'MMMM YYYY');
    }, [wave]);

    return (
      <g transform={`translate(${x},${y})`}>
        <BottomLabel
          ref={labelRef}
          textAnchor={textAnchor}
          dominantBaseline={textBaseline}
          transform={`translate(${textX},${textY})`}
          style={{ fontFamily: 'Mulish', fontSize: '12px', fontWeight: 600 }}
        >
          {dateLabel}
        </BottomLabel>
      </g>
    );
  };

export const LineAxisBottom = (waveMap: Record<number, Wave>, diagonal: boolean): AxisProps<AxisValue> => {
  const tickRotation = diagonal ? -45 : 0;
  return {
    tickSize: 0,
    tickRotation,
    tickPadding: theme.spacing.unit * 2,
    // eslint-disable-next-line react/display-name
    renderTick: BottomTick(waveMap),
  } as AxisProps;
};

export const lineChartTheme = {
  ...chartTheme,
  axis: {
    ...chartTheme.axis,
    domain: {
      ...chartTheme.axis.domain,
      line: {
        ...chartTheme.axis.domain.line,
        strokeWidth: 0,
      },
    },
  },
};
