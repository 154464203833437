import { Button } from 'common/components/Form/Button';
import styled from '@emotion/styled';
import { RefreshIcon as RefreshIconBase } from 'icons';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  position: relative;

  &[aria-hidden='true'] {
    display: none;
  }

  /* If this chart wrapper is inside dashboard, put flex: 1, so it can take all available height under the chart header */
  &[data-inside-page='true'] {
    flex: 1;
    min-height: 0;
  }
`;

const RefreshButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: ${({ theme }) => theme.space(-6, 0, 0, -3)};
  z-index: 1;
`;

const ChartContainerWrapper = styled.div`
  position: relative;

  /* Make this wrapper take 100%, but restrict his max height to 334, which is dashboard container(400px) without header and spacing */
  &[data-inside-page='true'] {
    height: 100%;
    min-width: 0;
  }

  &[data-expand-content='true'] {
    height: 100%;
    max-height: initial;
    min-width: 0;
  }
`;

const RefreshIcon = styled.i`
  width: 16px;
  height: 16px;
  background: url('${RefreshIconBase}') no-repeat center center;
  background-size: cover;
  display: inline-block;
`;

const ArrowContainer = styled.div<{ previous: boolean }>`
  height: ${({ theme }) => theme.spacing.unit * 37}px;
  width: ${props => (props.previous ? '20px' : '30px')};
  margin-top: ${props => (props.previous ? '0px' : '30px')};
  position: absolute;
  right: 0px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 12%);
  z-index: 1;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  margin-left: ${props => (props.previous ? '-5px' : '0')};
  margin-right: ${props => (props.previous ? '0' : '5px')};
  background-color: ${({ theme }) => theme.colors.white.toString()};
`;

const ArrowIcon = styled.div<{ previous: boolean; chartView: string; onClick: () => void }>`
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  background-image: url(/icons/${({ previous }) => (previous ? 'arrow_left.svg' : 'arrow_right.svg')});
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  position: absolute;
  right: 0px;

  border-radius: 8px;
  margin-top: ${({ theme }) => theme.spacing.unit * 14}px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 12%);
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white.toString()};
`;

const DropDownListWrapper = styled.div`
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
`;

const DropDownListItem = styled.div`
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
`;

const TooltipContainer = styled.div``;

const ToolbarItemContainer = styled.div`
  margin: 0 4px;
`;

const ToolbarExpand = styled(Link)`
  display: block;
  margin-left: ${({ theme }) => theme.space(2)};
`;

const ChartToolbarContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  &[aria-hidden='true'] {
    display: none;
  }
  &[data-indashboard='true'] {
    position: absolute;
    top: 12px;
    z-index: 2;
    right: ${({ theme }) => theme.space(3)};
    transition: opacity 0.5s;
    opacity: 0;
  }
`;

const LegendsContainer = styled.div<{ hide?: boolean }>`
  display: flex;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  flex-wrap: wrap;
  margin-left: ${({ theme }) => theme.space(1)};
  margin-top: ${({ theme }) => theme.space(2)};
`;

const ChartToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space(3)};
  margin-bottom: ${({ theme }) => theme.space(1)};

  &[aria-hidden='true'] {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
  }

  &[data-inpage='true'] {
    margin-top: 0;
    margin-bottom: 0;
  }

  &[data-right='true'] {
    justify-content: flex-end;
  }
`;

const SegmentSizeContainer = styled.div`
  display: flex;
`;

export {
  Wrapper,
  RefreshButton,
  ChartContainerWrapper,
  RefreshIcon,
  ArrowContainer,
  ArrowIcon,
  DropDownListWrapper,
  DropDownListItem,
  TooltipContainer,
  ChartToolbarContainer,
  ToolbarItemContainer,
  ToolbarExpand,
  LegendsContainer,
  ChartToolbarWrapper,
  SegmentSizeContainer,
};
