import { BrandPayload, Country } from 'common/types/common';

type CompetitorsBrandsByCountries = {
  label: string;
  countryCode: string;
  brands: BrandPayload[];
};

const getCountryMap = (allCountries: Country[]): Record<string, string> =>
  allCountries.reduce((acc: Record<string, string>, curr: Country) => {
    acc = { ...acc, [`${curr.code}`]: curr.name };
    return acc;
  }, {});

const getCountryName = (countryCode: string, allCountries: Country[]): string => {
  const countryMap = getCountryMap(allCountries);
  return countryMap?.[countryCode] || countryCode;
};

type BrandsByCountries = Array<{
  label: string;
  countryCode: string;
  brands: BrandPayload[];
}>;

const getBrandsByCountries = (
  brandsList: BrandPayload[] | undefined,
  primaryBrands: string[],
  allCountries: Country[],
): BrandsByCountries | undefined => {
  return brandsList
    ?.filter(({ global, id }) => !global && !primaryBrands?.includes(id.toString())) // Do not include primary brands
    .flatMap(brand =>
      brand.countries.map(countryCode => ({
        label: getCountryName(countryCode, allCountries),
        countryCode: countryCode,
        brands: [brand],
      })),
    );
};

export const getCompetitorsList = (
  brandsList: BrandPayload[] | undefined,
  primaryBrands: string[],
  allCountries: Country[],
): CompetitorsBrandsByCountries[] | undefined => {
  if (!brandsList?.length || !primaryBrands?.length || !allCountries?.length) return [];
  const brandsByCountries: BrandsByCountries | undefined = getBrandsByCountries(brandsList, primaryBrands, allCountries);
  return brandsByCountries?.reduce((acc: Array<CompetitorsBrandsByCountries>, currentObject) => {
    const existingObject = acc.find(obj => obj.countryCode === currentObject.countryCode);
    if (existingObject) {
      existingObject.brands.push(...currentObject.brands);
    } else {
      acc.push(currentObject);
    }
    return acc;
  }, []);
};
