import React from 'react';
import { ChartDataPoint, RawChartSchema, GPHeader, SortOption, SortType, ChartNumbersType, ChartDataKey } from 'common/types/chart';
import { Text } from 'common/styledComponents/typography';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableBodyRow, TableChartContainer, BrandGroupPipe } from 'modules/charts/components/TableChart/styled';
import TableHeader from 'modules/charts/components/TableChart/TableHeader';
import TableDataCell from 'modules/charts/components/TableChart/TableDataCell';
import ProgressBar from 'common/components/ProgressBar';
import { useUpdateChartSort } from 'common/hooks/charts';
import { FilterNames } from 'common/types/filters';
import { useGetPrimaryBrands } from 'common/hooks/brands';
import { useBrandCompetitorsByCountry } from 'common/hooks/brandGroups';
import { isAllBrandsAreCompetitors } from 'utils/chart';
import { AIDED_BRAND_AWARENESS, DataTestId } from 'settings/constants';
import { useAllKpisMap } from 'common/hooks/kpis';
import { chartValueInMsOrKs } from 'utils/helpers';
import { useIsPopulationNumbersEnabled } from 'common/hooks/features';

interface Props {
  data: ChartDataPoint[];
  chart: RawChartSchema;
  indexBy: FilterNames;
  chartNumbersType: ChartNumbersType;
}

enum ChartColumns {
  RANK = 'column_0',
  SEGMENT_SIZE = 'column_1',
  MAIN_COLUMN = 'column_2',
}

const CELL_ALIGN_LEFT = 'left';
const CELL_WIDTH_ZERO = 0;
const CELL_WIDTH_FIFTY = 50;

const RankingChart = ({ data, chart, indexBy, chartNumbersType }: Props) => {
  const {
    study_uuid: studyUuid,
    current_sort: currentSort,
    configuration: { first_dimension: chartGroupedBy },
    kpi_option_identifiers: chartKpis,
  } = chart;
  const { updateChartCurrentSort } = useUpdateChartSort();
  const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;
  const [rankingSort, setRankingSort] = React.useState<SortOption>(currentSort ?? { key: null, type: SortType.DESC });
  const isSegmentPopNumbersEnabled = useIsPopulationNumbersEnabled(studyUuid);

  const isGroupedBySegment = chartGroupedBy === FilterNames.SEGMENT;
  const isShowSegmentSize = isSegmentPopNumbersEnabled && isGroupedBySegment;

  const kpisMap = useAllKpisMap(studyUuid);
  const { t } = useTranslation();

  const primaryBrands = useGetPrimaryBrands();
  const brandCompetitors = useBrandCompetitorsByCountry(chart.country_ids);

  const onSortChange = React.useCallback(
    (key: string) => {
      let type = SortType.DESC;

      switch (rankingSort.type) {
        case SortType.DEFAULT:
          type = SortType.DEFAULT;
          break;
        case SortType.DESC:
          type = SortType.ASC;
          break;
        default:
          type = SortType.DESC;
      }

      setRankingSort({ key, type });
      updateChartCurrentSort(chart, { key, type });
    },
    [rankingSort, setRankingSort, chart, updateChartCurrentSort],
  );

  const getChartItemValue = (item: ChartDataPoint, key: ChartColumns, showPopNumbers: boolean): number => {
    if (key === ChartColumns.SEGMENT_SIZE) {
      return item.value_0_segmentSize;
    }
    return showPopNumbers ? item.value_0_populationNumberRaw : item.value_0_raw;
  };

  const compareValues = (valueA: number, valueB: number, sortType: SortType | null): number => {
    return sortType === SortType.ASC ? valueA - valueB : valueB - valueA;
  };

  const sortedData = React.useMemo(() => {
    return data.sort((a, b) => {
      const valueA = getChartItemValue(a, rankingSort.key as ChartColumns, showPopulationNumbers);
      const valueB = getChartItemValue(b, rankingSort.key as ChartColumns, showPopulationNumbers);
      return compareValues(valueA, valueB, rankingSort.type);
    });
  }, [data, rankingSort, showPopulationNumbers]);

  const chartHeaders = React.useMemo(() => {
    const createHeaderCell = (title: string, key: string | null, align: string, width: number) => ({
      title,
      key,
      align,
      width,
    });

    const mainColumnTitle = chartKpis[0] === AIDED_BRAND_AWARENESS ? `${t('rankingChart.awarenessLevel')}` : kpisMap[chartKpis[0]];

    return [
      createHeaderCell('#', ChartColumns.RANK, CELL_ALIGN_LEFT, CELL_WIDTH_FIFTY),
      createHeaderCell(`${indexBy}`, null, CELL_ALIGN_LEFT, CELL_WIDTH_ZERO),
      ...(isShowSegmentSize
        ? [createHeaderCell(`${t('rankingChart.segmentSize')}`, ChartColumns.SEGMENT_SIZE, CELL_ALIGN_LEFT, CELL_WIDTH_ZERO)]
        : []),
      createHeaderCell(mainColumnTitle, ChartColumns.MAIN_COLUMN, CELL_ALIGN_LEFT, CELL_WIDTH_ZERO),
    ] as GPHeader[];
  }, [chartKpis, indexBy, t, kpisMap, isShowSegmentSize]);

  const renderTableRow = React.useCallback(
    (rank: string, fieldName: string, value: number, populationValue: string, groupId: string, segmentSize: number) => {
      const isPrimaryBrand = primaryBrands?.includes(groupId);
      const isCompetitorBrand = brandCompetitors.includes(+groupId);
      const isAllBrandsCompetitors = isAllBrandsAreCompetitors(chart.brand_ids, primaryBrands, brandCompetitors);
      const isShowCompetitor = !isAllBrandsCompetitors && isCompetitorBrand;
      return (
        <TableBodyRow isPrimaryBrand={isPrimaryBrand} isCompetitorBrand={isShowCompetitor}>
          <TableDataCell align='left'>
            {isPrimaryBrand && <BrandGroupPipe isPrimary={isPrimaryBrand} />}
            {isShowCompetitor && <BrandGroupPipe />}
            <Text variant='body2' color='sooty' fontWeight='600'>
              {rank}
            </Text>
          </TableDataCell>
          <TableDataCell align='left'>
            <Text variant='body2' color='sooty' fontWeight='400'>
              {fieldName}
            </Text>
          </TableDataCell>
          {isShowSegmentSize && (
            <TableDataCell align='left'>
              <Text variant='body2' color='sooty' fontWeight='400'>
                {chartValueInMsOrKs(segmentSize, null)}
              </Text>
            </TableDataCell>
          )}
          <TableDataCell align='left'>
            <ProgressBar percentage={value} />
            <Text variant='body2' color='sooty' fontWeight='400'>
              {showPopulationNumbers ? populationValue : `${value}%`}
            </Text>
          </TableDataCell>
        </TableBodyRow>
      );
    },
    [primaryBrands, brandCompetitors, chart.brand_ids, showPopulationNumbers, isShowSegmentSize],
  );

  const renderTableBody = React.useMemo(() => {
    return (
      <TableBody data-testid={DataTestId.RANKING_TABLE}>
        {sortedData.map((item, index) => (
          <React.Fragment key={`${item.value_0}-${item?.groupId}`}>
            {renderTableRow(
              rankingSort.type === SortType.ASC ? `${sortedData.length - index}` : `${index + 1}`,
              item[indexBy as unknown as ChartDataKey] as string,
              item.value_0,
              item.value_0_populationNumberValue,
              item?.groupId?.toString() || '',
              item?.value_0_segmentSize,
            )}
          </React.Fragment>
        ))}
      </TableBody>
    );
  }, [sortedData, indexBy, rankingSort.type, renderTableRow]);

  return (
    <TableChartContainer>
      <Table>
        <TableHeader headers={chartHeaders} sorting={[rankingSort]} onSortHandle={onSortChange} />
        {renderTableBody}
      </Table>
    </TableChartContainer>
  );
};

export default React.memo(RankingChart);
