import styled from '@emotion/styled';

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  border-spacing: 0;
  margin-top: ${({ theme }) => theme.space(10)};
`;

const TableHead = styled.thead``;

const TableHeadRow = styled.tr``;

const TableBodyHeader = styled.th``;

const TableHeadItem = styled.td`
  border: 1px solid #eeeeee;
  :first-of-type {
    width: 15%;
  }
  :last-of-type {
    background-color: ${({ theme }) => theme.v2.colours.gray50};
  }
`;

const TableDataItem = styled.td`
  border-left: 1px solid #eeeeee;
  :last-of-type {
    background-color: ${({ theme }) => theme.v2.colours.gray50};
  }
`;

const TableBody = styled.tbody`
  border: 1px solid #eeeeee;
`;

const TableBodyRow = styled.tr`
  :not(:last-of-type) {
    border-bottom: 1px solid #eeeeee;
  }
`;

const TableBodyCellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;

  > div:nth-of-type(2) {
    margin-right: ${({ theme }) => theme.space(0.5)};
  }
`;

const TableArrowContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space(0.5)};
  margin-right: ${({ theme }) => theme.space(1)};
`;

const ValueNameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableHeaderCell = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.space(2, 0)};
  > div:first-of-type {
    margin-bottom: ${({ theme }) => theme.space(1.5)};
  }
`;

const TableHeaderSubtitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const TableHeaderSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

const TableCell = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${({ theme }) => theme.space(2, 0)};
`;

const FunnelChartContainer = styled.div<{ inPage: boolean }>`
  display: flex;
  position: relative;
  height: ${({ inPage }) => (inPage ? 'calc(100% - 60px)' : 'initial')};
`;

const CurrentDataLabel = styled.div<{ inPage: boolean }>`
  margin-top: ${({ theme, inPage }) => theme.space(inPage ? 0 : 5)};
  margin-bottom: ${({ theme }) => theme.space(5)};
`;

const ArrowDown = styled.div`
  position: relative;
  width: 60px;
  margin: 0 auto;
  height: 0;
  border-bottom: 2px solid ${({ theme }) => theme.v2.colours.gray400};
  transform: rotate(90deg);

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid ${({ theme }) => theme.v2.colours.gray400};
    position: absolute;
    right: -4px;
    top: -2px;
  }
`;

const FunnelDirectionContainer = styled.div<{ inPage: boolean }>`
  width: 80px;
  height: ${({ inPage }) => (inPage ? 'calc(100% - 40px)' : '240px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const MarketFunnelChartContainer = styled.div`
  height: 100%;
`;

const FunnelAxleContainer = styled.div`
  position: relative;
`;

const FunnelAxleTooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -16px;
  .tooltip-content div:nth-of-type(2) {
    > div {
      width: 244px;
      height: inherit;
    }
  }
`;

export {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadItem,
  TableBody,
  TableBodyHeader,
  TableDataItem,
  TableBodyRow,
  TableBodyCellContainer,
  ValueNameContainer,
  TableHeaderCell,
  TableArrowContainer,
  TableHeaderSubtitle,
  TableCell,
  TableHeaderSubtitleContainer,
  FunnelChartContainer,
  CurrentDataLabel,
  ArrowDown,
  FunnelDirectionContainer,
  MarketFunnelChartContainer,
  FunnelAxleContainer,
  FunnelAxleTooltipWrapper,
};
