import * as React from 'react';
import { ChartNameContainer, DescriptionInput, Description, DescriptionOutputText } from 'modules/charts/components/Filters/styled';
import { markdownParser } from 'utils/markdown';

/* eslint-disable  @typescript-eslint/no-non-null-assertion */

interface ChartDescriptionProps {
  isEditable: boolean;
  chartDesc: string | null;
  reg: () => void;
  onHandleFocus: () => void;
  onSubmit?: () => void;
}

const ChartDescription: React.FC<ChartDescriptionProps> = ({ isEditable, chartDesc, reg, onSubmit, onHandleFocus }) => {
  const outputTextRef = React.useRef<HTMLDivElement>();
  const inputRef = React.useRef<HTMLTextAreaElement>();
  const containerRef = React.useRef<HTMLDivElement>();

  /** Rewrite this logic for common input */
  const onOutputClick = React.useCallback(() => {
    // Toggle visibility
    outputTextRef.current!.style.visibility = 'hidden';
    inputRef.current!.style.opacity = '1';

    // Focus on the input
    inputRef.current!.focus();
  }, []);

  const onBlurHandle = React.useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      if (onSubmit) return onSubmit();
      e.currentTarget.style.opacity = '0';
      outputTextRef.current!.style.visibility = 'visible';
    },
    [onSubmit],
  );

  // set input ref manually, since the ref passed to input comes from hook form
  React.useEffect(() => {
    inputRef.current = containerRef.current!.querySelector('textarea')!;
  }, []);

  return (
    <ChartNameContainer ref={containerRef as React.MutableRefObject<HTMLDivElement>}>
      {isEditable ? (
        <>
          <DescriptionInput
            ref={reg}
            id='chartDescription'
            name='description'
            placeholder='Chart description'
            rows={2}
            cols={90}
            maxLength={120}
            onFocus={onHandleFocus}
            onBlur={onBlurHandle}
          />
          <DescriptionOutputText
            onClick={onOutputClick}
            ref={outputTextRef as React.MutableRefObject<HTMLDivElement>}
            dangerouslySetInnerHTML={{ __html: markdownParser(chartDesc || 'Chart description') }}
          ></DescriptionOutputText>
        </>
      ) : (
        <Description>{chartDesc}</Description>
      )}
    </ChartNameContainer>
  );
};

export default React.memo(ChartDescription);
