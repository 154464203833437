import * as React from 'react';
import Tooltip from 'common/components/Tooltip';
import { IconButtonTooltipWrapper } from './styled';
import { LatanaText } from '../LatanaText';

export const withTooltip = (Component: JSX.Element, tooltipText: string): JSX.Element => {
  return (
    <Tooltip
      delay={100}
      content={
        <IconButtonTooltipWrapper>
          <LatanaText variant='B3' color='gray0' htmlTag='span'>
            {tooltipText}
          </LatanaText>
        </IconButtonTooltipWrapper>
      }
    >
      {Component}
    </Tooltip>
  );
};
