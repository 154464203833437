import React from 'react';
import { LatanaText } from 'common/components/LatanaText';
import { sortTableChart } from 'utils/chart';
import Spinner from 'common/components/Spinner';
import { LoadingContainer } from 'common/styledComponents/containers';
import BrandPerceptionRow from 'modules/charts/components/BrandPerception/BrandPerceptionRow';
import BpTableHeadItem from 'modules/charts/components/BrandPerception/BpTableHeadItem';
import { useIndexMap } from 'modules/charts/components/BrandPerception/hooks/useIndexMap';
import { getChartHeaders } from 'modules/charts/components/BrandPerception/utils/calculateSentiments';
import { AVERAGE, AVERAGE_VALUE, DataTestId, SentimentColors } from 'settings/constants';
import {
  BpChartData,
  BpColumnKey,
  ChartNumbersType,
  RawChartData,
  RawChartSchema,
  RawReferenceAverageData,
  SortOption,
  SortType,
} from 'common/types/chart';
import {
  BpContainer,
  BpHead,
  BpDateContainer,
  BpLegendContainer,
  BpLegendIndicator,
  BpLegendItem,
  BpHeadItem,
  HeaderLabel,
  HeaderLabelContainer,
  LegendInfoContainer,
} from 'modules/charts/components/BrandPerception/styled';
import { TableHeadRow, Table, TableBody, TableChartContainer } from 'modules/charts/components/TableChart/styled';
import { useUpdateChartSort } from 'common/hooks/charts';
import { calculateAverageMeans, composeChartData } from 'modules/charts/components/BrandPerception/utils/generateChartData';
import { FilterNames } from 'common/types/filters';
import { displayDate, getLatestWaveDate } from 'utils/dates';
import { useGetWavesMap } from 'common/hooks/waves';
import { useGetCountryIdMapByStudyId } from 'common/hooks/countries';
import Icon from 'common/components/Icon';
import { useTranslation } from 'react-i18next';

const PERCENTAGE_HEADERS = ['0%', '25%', '50%', '75%', '100%'];

export const legendData = [
  { key: 'Positive', color: SentimentColors.POSITIVE },
  { key: 'Negative', color: SentimentColors.NEGATIVE },
  { key: 'Neutral', color: SentimentColors.NEUTRAL },
];

interface BrandPerceptionProps {
  chartConf: RawChartSchema;
  chartData?: RawChartData[];
  averageChartData?: RawReferenceAverageData[];
  brandAwarenessData: RawChartData[];
  chartNumbersType: ChartNumbersType;
}

const Fallback: React.FC = () => (
  <LoadingContainer>
    <Spinner />
  </LoadingContainer>
);

const BrandPerception: React.FC<BrandPerceptionProps> = ({
  chartConf,
  chartData,
  averageChartData,
  brandAwarenessData,
  chartNumbersType,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = React.useState<SortOption>(chartConf?.current_sort ?? { key: null, type: SortType.DESC });
  const indexMap = useIndexMap();
  const waveMap = useGetWavesMap();
  const { updateChartCurrentSort } = useUpdateChartSort();
  const getCountryIdMap = useGetCountryIdMapByStudyId();

  const isShowPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;

  // Can be of country, brand or segment
  const mainIndex = chartConf?.configuration?.first_dimension || FilterNames.BRAND;

  const mainIndexIdList = React.useMemo(() => {
    return [...new Set((chartData || []).map(item => item[mainIndex]))];
  }, [chartData, mainIndex]) as number[];

  const dataGroupedByIndex = mainIndexIdList.map(index =>
    chartData?.filter(item => item[mainIndex] === index),
  ) as unknown as RawChartData[];

  const getAverageData = React.useCallback(
    (averageChartData: RawReferenceAverageData[]) => {
      const averageData = averageChartData.map(item => ({ ...item, [mainIndex]: AVERAGE_VALUE }));
      // We need to calculate the average of the means if main index is country or segment
      // Because response structure is different for country/segment and brand
      return mainIndex === FilterNames.BRAND ? averageData : calculateAverageMeans(averageData);
    },
    [mainIndex],
  );

  const countryMap = getCountryIdMap(chartConf.study_uuid);

  const composedChartData = React.useMemo(() => {
    const averageData = getAverageData(averageChartData || []);
    const chartData = [...dataGroupedByIndex, averageData] as RawChartData[];
    return composeChartData(chartData, mainIndex, mainIndexIdList, indexMap, waveMap, countryMap, brandAwarenessData);
  }, [mainIndexIdList, waveMap, dataGroupedByIndex, mainIndex, indexMap, averageChartData, getAverageData, countryMap, brandAwarenessData]);

  const setChartSortingType = (columnKey: BpColumnKey | null) => {
    if (columnKey == null) return;

    let sortOrder;

    switch (sorting.type) {
      case SortType.DEFAULT:
        sortOrder = SortType.ASC;
        break;
      case SortType.DESC:
        sortOrder = SortType.DEFAULT;
        break;
      default:
        sortOrder = SortType.DESC;
    }

    const newSorting = { key: columnKey, type: sortOrder };
    setSorting(newSorting);

    updateChartCurrentSort(chartConf, newSorting);
  };

  const getSortingOrder = (key: BpColumnKey, sorting: SortOption) => (sorting.key === key ? sorting.type : SortType.DEFAULT);

  const brandPerceptionData = React.useMemo(() => {
    const average = composedChartData.find(item => item.index === AVERAGE) as BpChartData;
    let chartDataCopy = [...composedChartData].filter(item => item.index !== AVERAGE) as BpChartData[];

    if (sorting.key && chartDataCopy && sorting.type) {
      chartDataCopy = sortTableChart(chartDataCopy, sorting);
    }

    // Always put average value on top row of the table
    chartDataCopy.unshift(average);

    return chartDataCopy;
  }, [sorting, composedChartData]);

  const brandPerceptionHeaders = React.useMemo(() => {
    return getChartHeaders(chartData, waveMap, isShowPopulationNumbers);
  }, [chartData, waveMap, isShowPopulationNumbers]);

  const latestWaveDate = getLatestWaveDate(chartConf?.wave_ids, waveMap);

  if (indexMap.isLoading) return <Fallback />;

  return (
    <TableChartContainer>
      <BpDateContainer>
        <LatanaText color='gray900' variant='T3'>
          {`Current data: ${displayDate(latestWaveDate || '', 'MMMM YYYY')}`}
        </LatanaText>
      </BpDateContainer>
      <BpContainer>
        <Table>
          <BpHead>
            <TableHeadRow>
              <td />
              {!isShowPopulationNumbers && (
                <BpHeadItem>
                  <HeaderLabelContainer>
                    {PERCENTAGE_HEADERS.map((header, index) => (
                      <HeaderLabel key={`header-label-${index}`}>
                        <LatanaText color='gray500' variant='B2'>
                          {header}
                        </LatanaText>
                      </HeaderLabel>
                    ))}
                  </HeaderLabelContainer>
                </BpHeadItem>
              )}
              {brandPerceptionHeaders.map(({ key, title, tooltip }) => (
                <BpTableHeadItem
                  title={title}
                  columnKey={key as BpColumnKey}
                  tooltip={tooltip}
                  key={`chart-header-${key}`}
                  setSortType={setChartSortingType}
                  sortType={getSortingOrder(key as BpColumnKey, sorting)}
                />
              ))}
            </TableHeadRow>
          </BpHead>
          <TableBody data-testid={DataTestId.BRAND_PERCEPTION_TABLE}>
            {brandPerceptionData.map((row, index) => (
              <BrandPerceptionRow key={`chart-row-${index}`} {...row} isShowPopulationNumbers={isShowPopulationNumbers} />
            ))}
          </TableBody>
        </Table>
      </BpContainer>
      <BpLegendContainer>
        {legendData.map(({ color, key }) => (
          <BpLegendItem key={`legend-item-${key}`}>
            <BpLegendIndicator key={color} color={color} />
            <LatanaText color='gray900' variant='B3'>
              {key}
            </LatanaText>
          </BpLegendItem>
        ))}
        <LegendInfoContainer>
          <Icon icon='info' color='gray500' />
          <LatanaText color='gray500' variant='B3'>
            {t('brandPerception.legendInfo')}
          </LatanaText>
        </LegendInfoContainer>
      </BpLegendContainer>
    </TableChartContainer>
  );
};

export default BrandPerception;
