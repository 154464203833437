import React from 'react';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import { useTranslation } from 'react-i18next';
import TextInput from 'common/components/TextInput';
import { useForm } from 'react-hook-form';
import { Button, LoadingButton } from 'common/components/Form/Button';
import { usePasswordReset, useUpdateAccountDetails, useUpdateEmailNotificationSettings } from 'common/hooks/account';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { accountDetailsSelector } from 'common/atoms/account';
import { useRecoilValue } from 'recoil';
import SettingsHeader from 'modules/settings/components/SettingsHeader';
import Tooltip from 'common/components/Tooltip';
import { BlurbPopup } from 'common/styledComponents/modals';
import Toggle from 'common/components/Form/Toggle';
import { useTracking } from 'common/hooks/tracking';

const PasswordResetButton = styled.div`
  white-space: nowrap;
  cursor: pointer;
  display: inline;

  p {
    display: inline;

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.lightPurple.toString()};
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.spacing.unit * 5}px;

  > p {
    margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;
    height: 36px;
    line-height: 36px;
  }
`;

const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px !important;
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EmailToggleWrapper = styled.div`
  height: 36px;
  margin-bottom: ${({ theme }) => theme.space(4)};
  display: flex;
  align-items: center;
`;

type ProfileFormData = {
  name: string;
  account: string;
  email: string;
  password: string;
  role: string;
  emailNotification: boolean;
};

const ProfilePage = () => {
  const { t } = useTranslation();
  const passwordReset = usePasswordReset();
  const accountDetails = useRecoilValue(accountDetailsSelector);
  const { loading: saveLoading, updateAccountDetails } = useUpdateAccountDetails();

  const { updateEmailNotification } = useUpdateEmailNotificationSettings();
  const { trackEvent } = useTracking();

  const defaultValues = React.useMemo(
    () => ({
      name: accountDetails?.user.name,
      account: accountDetails?.customer.name,
      email: accountDetails?.user.email,
      password: '1234567890',
      role: `${(accountDetails?.user?.role?.name?.[0] || '').toUpperCase()}${accountDetails?.user?.role?.name?.slice(1) || ''}`,
      emailNotification: accountDetails?.user?.notify_about_data_emails,
    }),
    [accountDetails],
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { dirtyFields },
    getValues,
  } = useForm<ProfileFormData>({
    defaultValues,
  });

  React.useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const isDirty = dirtyFields?.name || false;

  const onSubmit = handleSubmit(({ name, email }) => {
    if (isDirty) {
      updateAccountDetails({
        name: name,
        email: email,
      });
    }
  });

  const cancelUpdate = React.useCallback(() => {
    reset({
      name: accountDetails?.user.name,
      account: accountDetails?.customer.name,
      email: accountDetails?.user.email,
      password: '12345678',
      role: `${(accountDetails?.user?.role?.name?.[0] || '').toUpperCase()}${accountDetails?.user?.role?.name?.slice(1) || ''}`,
    });
  }, [reset, accountDetails]);

  const handleEmailNotificationToggle = () => {
    const currentEmailNotification = getValues('emailNotification');
    updateEmailNotification(currentEmailNotification);
    trackEvent(TRACKING_EVENTS.EMAIL_NOTIFICATION_TOGGLE, {
      event_kind: 'new-data-by-email',
      new_value: currentEmailNotification,
      previous_value: !currentEmailNotification,
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <SettingsHeader title={t('profile.profile')}>
          {isDirty && (
            <LoadingButton type='submit' color='turqoise' loading={saveLoading}>
              {t('common.save')}
            </LoadingButton>
          )}
          {isDirty && (
            <Button type='button' onClick={cancelUpdate} color='black05' hoverColor='rgb(0,0,0,0.05)' pseudo>
              {t('common.cancel')}
            </Button>
          )}
        </SettingsHeader>
        <ContentWrapper>
          <TitlesWrapper>
            <Text variant='body2' color='black'>
              {t('profile.name')}
            </Text>
            <Text variant='body2' color='black'>
              {t('profile.account')}
            </Text>
            <Text variant='body2' color='black'>
              {t('profile.email')}
            </Text>
            <Text variant='body2' color='black'>
              {t('profile.password')}
            </Text>
            <Text variant='body2' color='black'>
              {t('profile.role')}
            </Text>
            <EmailToggleWrapper>
              <Toggle name='emailNotification' ref={register} onClick={handleEmailNotificationToggle} />
            </EmailToggleWrapper>
          </TitlesWrapper>
          <ValuesWrapper>
            <TextInput label={t('profile.name')} name='name' ref={register} variant='clear' placeholder={t('profile.pleaseEnterName')} />
            <TextInput label={t('profile.account')} name='account' ref={register} disabled variant='clear' />
            <TextInput label={t('profile.email')} name='email' ref={register} disabled variant='clear' />
            <PasswordWrapper>
              <TextInput
                label={t('profile.password')}
                name='password'
                ref={register}
                type='password'
                marginBottom={false}
                disabled
                variant='clear'
              />
              <PasswordResetButton onClick={() => passwordReset(accountDetails?.user?.email)}>
                <Text variant='body2' color='lightPurple' marginBottom>
                  {t('profile.sendPassword')}
                </Text>
              </PasswordResetButton>
            </PasswordWrapper>
            <Tooltip
              content={
                <BlurbPopup
                  title={t(`roles.${defaultValues.role.toLowerCase()}`)}
                  content={t(`faq.${defaultValues.role.toLowerCase()}`)}
                  href={t('faq.permissionLevelLink')}
                  linkText={t('common.learnMore')}
                  type={TRACKING_EVENTS.SETTINGS_USER_PERMISSION_FAQ_LINK}
                />
              }
            >
              <TextInput label={t('profile.email')} name='role' ref={register} disabled variant='clear' />
            </Tooltip>
            <TextInput value={t('profile.notifyByEmail')} name='notifyEmail' disabled variant='clear' />
          </ValuesWrapper>
        </ContentWrapper>
      </form>
    </div>
  );
};

export default React.memo(ProfilePage);
