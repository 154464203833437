import { GeneratedChartData } from 'common/types/chart';

interface ChartExportData {
  brand: string;
  country: string;
  kpi: string;
  segment: string;
  wave: string;
  value: number;
  populationNumberRaw: number;
  moe: number;
}

export const exportChartDataToCSV = (chartData: GeneratedChartData, chartName: string, showPopulationNumbers: boolean) => {
  exportChartToCSV(transformChartDataToExportFormat(chartData), chartName, showPopulationNumbers);
};

const transformChartDataToExportFormat = (chartData: GeneratedChartData) => {
  const result: ChartExportData[] = [];

  // Iterate through each data item
  chartData.data.forEach(item => {
    // Iterate through each key from the keys array
    chartData.keys.forEach(key => {
      const baseKey = key; // e.g., "value_0"
      const filterLabels = item[`${baseKey}_filterLabels` as 'value_0_filterLabels'];

      // Create transformed object with flattened structure
      const chartDataExportItem: ChartExportData = {
        brand: filterLabels.brand,
        country: filterLabels.country,
        kpi: filterLabels.kpi,
        segment: filterLabels.segment,
        wave: filterLabels.wave,
        value: item[baseKey as 'value_0'],
        populationNumberRaw: item[`${baseKey}_populationNumberRaw` as 'value_0_populationNumberRaw'],
        moe: item[`${baseKey}_moe` as 'value_0_moe'],
      };

      result.push(chartDataExportItem);
    });
  });

  return result;
};

const exportChartToCSV = (data: ChartExportData[], filename = 'chart_export.csv', showPopulationNumbers: boolean) => {
  const headers = ['Brand', 'Segment', 'KPI', 'Country', 'Wave', 'Value (%)', 'Population', 'Margin of Error (%)'];
  // Create CSV rows
  const csvRows = [
    // Add headers
    headers.join(','),

    // Add data rows
    ...data.map(row => {
      return [
        `"${row.brand}"`, // Brand
        `"${row.segment}"`, // Segment
        `"${row.kpi}"`, // KPI
        `"${row.country}"`, // Country
        `"${row.wave}"`, // Wave
        row.value, // Value (%) - as number
        showPopulationNumbers && row.populationNumberRaw ? Math.round(row.populationNumberRaw) : '', // Population number as number if exist
        row.moe, // Margin of Error (%) - as number
      ].join(',');
    }),
  ];

  // Join rows with newlines
  const csvContent = csvRows.join('\n');

  // Create blob and download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
