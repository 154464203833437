import React from 'react';
import { ResponsiveHeatMap, HeatMapDatum } from '@nivo/heatmap';
import Cell from './Cell';
import { heatmapTheme } from 'modules/charts/theme';
import ChartAxisTicks from './ChartAxisTicks';
import { ChartNumbersType } from 'common/types/chart';

interface Props {
  data: HeatMapDatum[];
  keys: string[];
  xLabel: string;
  yLabel: string;
  setBottomPadding: (value: boolean) => void;
  inPage?: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement | undefined>;
  chartNumbersType: ChartNumbersType;
}

const MIN_Y_AXIS_MARGIN = 0;
const TICK_PADDING = 8;
const CELL_HORIZONTAL_PADDING = 32;

const HeatmapChart: React.FC<Props> = ({ data, keys, xLabel, yLabel, inPage, setBottomPadding, containerRef, chartNumbersType }) => {
  const [labelsCollide, setLabelsCollide] = React.useState<boolean>();
  const [textOverflow, setTextOverflow] = React.useState<boolean>();
  const [yAxisMargin, setYAxisMargin] = React.useState<number>(MIN_Y_AXIS_MARGIN);

  const checkLabelCollision = React.useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const labels = container?.querySelectorAll('svg > g > g:nth-child(1) > g > text');
      const cell = container?.querySelector('svg > g > g:nth-child(3) > rect');
      const collides = Array.from(labels).some(elem => {
        const { width } = elem.getBoundingClientRect();
        if (cell) {
          return width > Number(cell.getAttribute('width'));
        }
        return false;
      });
      setLabelsCollide(collides);
      setBottomPadding(collides);
    }
  }, [containerRef, setBottomPadding]);

  React.useEffect(() => {
    const ro = new ResizeObserver(entries => {
      const allTexts = entries[0]?.target?.querySelectorAll('svg > g > g:nth-child(n+3) > text');
      const cell = entries[0]?.target?.querySelector('svg > g > g:nth-child(3) > rect');
      if (allTexts) {
        const collides = Array.from(allTexts).some(text => {
          const { width: textWidth } = text.getBoundingClientRect();
          return cell ? textWidth + CELL_HORIZONTAL_PADDING >= Number(cell.getAttribute('width')) : false;
        });
        setTextOverflow(collides);
      }
    });
    if (containerRef?.current) {
      ro.observe(containerRef?.current);
      return () => ro.disconnect();
    }
  }, [containerRef]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    const scheduleCalculate = () => {
      clearTimeout(timeout);
      timeout = setTimeout(checkLabelCollision, 300);
    };
    window.addEventListener('resize', scheduleCalculate);
    const initialTimer = setTimeout(checkLabelCollision, 300);
    return () => {
      window.removeEventListener('resize', scheduleCalculate);
      clearTimeout(initialTimer);
    };
  }, [checkLabelCollision, data]);

  React.useEffect(() => {
    const ro = new ResizeObserver(entries => {
      let margin = MIN_Y_AXIS_MARGIN;
      const yLabels = entries[0]?.target?.querySelectorAll('svg > g > g:nth-child(n+2) > g');
      if (yLabels) {
        const labelWidths = Array.from(yLabels).map(label => label?.getBoundingClientRect()?.width);
        if (yLabels?.length) margin = Math.max(...labelWidths);
      }
      setYAxisMargin(margin === MIN_Y_AXIS_MARGIN ? margin : margin + TICK_PADDING * 2);
    });
    if (containerRef?.current) {
      ro.observe(containerRef?.current as Element);
      return () => ro.disconnect();
    }
  }, [containerRef, setYAxisMargin]);

  const getPopulationNumberForCell = (yKey: string, xKey: string) => {
    // Find the object with the specified label value
    const item = data.find(obj => obj[yLabel] === yKey);
    return item?.[`${xKey}_population`] || null;
  };

  return (
    <ResponsiveHeatMap
      data={data}
      keys={keys}
      indexBy={yLabel}
      margin={{ top: inPage ? 8 : 48, left: yAxisMargin }}
      colors={heatmapTheme.colorScale}
      forceSquare={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickPadding: TICK_PADDING,
        tickRotation: labelsCollide ? -45 : 0,
        renderTick: (props: any) => <ChartAxisTicks {...props} />,
      }}
      axisLeft={{
        tickPadding: TICK_PADDING,
        renderTick: (props: any) => <ChartAxisTicks {...props} />,
      }}
      cellBorderColor='#ffff'
      theme={heatmapTheme}
      cellOpacity={0.7}
      cellBorderWidth={1}
      labelTextColor='#ffffff'
      animate={true}
      motionStiffness={80}
      motionDamping={9}
      hoverTarget='cell'
      cellShape={props => {
        return (
          <Cell
            {...props}
            isTextOverflow={textOverflow}
            xLabel={xLabel}
            yLabel={yLabel}
            populationValue={getPopulationNumberForCell(props.data.yKey, props.data.xKey)}
            chartNumbersType={chartNumbersType}
          />
        );
      }}
    />
  );
};

export default React.memo(HeatmapChart);
