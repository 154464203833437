import React from 'react';

interface Props {
  index: number;
  innerFill: string;
  isHovered?: boolean;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

const ChangeOverTime = ({ startX, startY, endX, endY, innerFill, isHovered, index }: Props) => {
  const startHeight = isHovered ? 10 * 1.2 : 10; // Scale start height if hovered
  const endHeight = isHovered ? 24 * 1.2 : 24; // Scale end height if hovered

  // Calculate the width of the rectangle
  const width = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);

  // Calculate the center of the rectangle
  const centerX = (startX + endX) / 2;
  const centerY = (startY + endY) / 2;

  // Calculate the angle of rotation
  const dx = endX - startX;
  const dy = endY - startY;
  const angle = Math.atan2(dy, dx) * (180 / Math.PI);

  // Calculate the rectangle coordinates based on the given heights
  const halfWidth = width / 2;
  const startHalfHeight = startHeight / 2;
  const endHalfHeight = endHeight / 2;

  const topLeftX = centerX - halfWidth;
  const topLeftY = centerY - startHalfHeight;
  const topRightX = centerX + halfWidth;
  const topRightY = centerY - endHalfHeight;
  const bottomRightX = centerX + halfWidth;
  const bottomRightY = centerY + endHalfHeight;
  const bottomLeftX = centerX - halfWidth;
  const bottomLeftY = centerY + startHalfHeight;

  // Rectangle path data
  const pathData = `
    M ${topLeftX} ${topLeftY}
    L ${topRightX} ${topRightY}
    L ${bottomRightX} ${bottomRightY}
    L ${bottomLeftX} ${bottomLeftY}
    Z
  `;

  // Define a unique gradient ID
  const gradientId = `ChangeOverTime-gradient-${index}`;

  return (
    <g transform={`rotate(${angle}, ${centerX}, ${centerY})`}>
      {/* Define the gradient */}
      <defs>
        <linearGradient id={gradientId} x1='0%' y1='0%' x2='100%' y2='0%'>
          <stop offset='0%' style={{ stopColor: innerFill, stopOpacity: 0.12 }} />
          <stop offset='100%' style={{ stopColor: innerFill, stopOpacity: 1 }} />
        </linearGradient>
      </defs>

      {/* Render the rectangle with gradient */}
      <path d={pathData} fill={`url(#${gradientId})`} />
    </g>
  );
};

export default React.memo(ChangeOverTime);
