import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { BrandPayload } from 'common/types/common';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';

export const useGetAllBrands = (): UseQueryResult<BrandPayload[], unknown> => {
  const { fetchGet } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();
  return useQuery(
    ['allBrands'],
    async () => {
      try {
        return await fetchGet<BrandPayload[]>(API_URLS.BRANDS);
      } catch (e: unknown) {
        rollbar.info('Brands API is failing', e as LogArgument);
      }
    },
    {
      staleTime: Infinity,
      placeholderData: [],
    },
  );
};
