import React, { useState, FC, useMemo, useEffect, useCallback } from 'react';
import './App.css';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { ErrorBoundary, Provider } from '@rollbar/react';
import getRollbarConfig from 'rollbarConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clearRedirectPath, clearToken, getRandomKey, saveAuthToken, saveRedirectPath, saveRefreshToken } from 'utils/auth';
import AuthContext from 'common/contexts/AuthContext';
import { sessionKeyAtom } from 'common/atoms/account';
import { useLogin } from 'common/hooks/login';
import { useTracking } from 'common/hooks/tracking';
import { useValidateToken } from 'common/hooks/account';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { GrantTypes, Providers } from 'common/types/login';
import { MAIN_ROUTES } from 'settings/routes';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AuthContextOutletType } from 'common/types/auth';
import { useQueryClient } from '@tanstack/react-query';

const { REACT_APP_GOOGLE_CLIENT_ID: CLIENT_ID = '' } = process.env;

const App: FC = () => {
  const { init, trackEvent, clearUserProperties } = useTracking();
  const navigate = useNavigate();

  const rollbarConfig = getRollbarConfig();

  const url = React.useMemo(() => {
    return new URL(window.location.href);
  }, []);

  const initialLoggedIn = useValidateToken();
  const initialSessionKey = useMemo(() => getRandomKey(20), []);

  const redirectUrl = useMemo(() => {
    const { pathname, search, hash } = url;
    return pathname.concat(search, hash);
  }, [url]);

  const [, setError] = React.useState<string>();
  const [loggedIn, setLoggedIn] = useState<boolean>(initialLoggedIn);
  const [sessionKey, setSessionKey] = useState(initialSessionKey);
  const [redirectPath] = useState(redirectUrl);
  const [assertionToken, setAssertionToken] = useState<string | null>(url.searchParams.get('assertion_token'));
  const queryClient = useQueryClient();

  const onAuth = useCallback(
    (authToken: string, expires: Date, refreshToken: string) => {
      queryClient.invalidateQueries();
      saveAuthToken(authToken, expires);
      saveRefreshToken(refreshToken);
      setLoggedIn(true);
      navigate('/dashboards');
    },
    [navigate, queryClient],
  );

  const signIn = useLogin(onAuth, setError);

  const resetAppCues = () => window.Appcues.reset();

  const logout = useCallback(() => {
    setLoggedIn(false);
    clearToken();
    setSessionKey(getRandomKey(20));
    trackEvent(TRACKING_EVENTS.LOG_OFF);
    resetAppCues();
    clearRedirectPath();
    clearUserProperties();
  }, [trackEvent, clearUserProperties]);

  const authContext = useMemo(() => {
    return {
      logout,
    };
  }, [logout]);

  useEffect(() => {
    const internalAuth = (assertion: string) => {
      signIn({
        grant_type: GrantTypes.ASSERTION,
        provider: Providers.LATANA,
        assertion,
      });
    };

    if (assertionToken) {
      logout();
      internalAuth(assertionToken);
      setAssertionToken(null);
    }
  }, [assertionToken, logout, signIn]);

  useEffect(() => {
    init(url.searchParams.get('entrance_source') ?? undefined);
    if (redirectPath && ![MAIN_ROUTES.ROOT, MAIN_ROUTES.LOGIN].includes(redirectPath)) {
      saveRedirectPath(redirectPath);
    }
  }, [init, redirectPath, url.searchParams]);

  const initializeRecoil = useCallback(
    ({ set }: MutableSnapshot) => {
      set(sessionKeyAtom, sessionKey);
    },
    [sessionKey],
  );

  return (
    <Provider instance={rollbarConfig}>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <RecoilRoot key={sessionKey} initializeState={initializeRecoil}>
          <AuthContext.Provider value={authContext}>
            <ErrorBoundary fallbackUI={() => <Navigate to='/login' />} errorMessage='App component boundary'>
              <Outlet context={{ onAuth, loggedIn } satisfies AuthContextOutletType} />
            </ErrorBoundary>
          </AuthContext.Provider>
        </RecoilRoot>
      </GoogleOAuthProvider>
    </Provider>
  );
};

export default React.memo(App);
