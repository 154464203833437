import React, { FC, useState, useRef, useEffect, forwardRef, memo } from 'react';
import {
  Container,
  SelectWrapper,
  Label,
  SubLabel,
  OptionsHeader,
  DropDownItemIconCheck,
  OptionLabelWrapper,
  FaqLink,
  LinkIcon,
  FaqLinkWrapper,
} from './styled';
import useOutsideClick from 'common/hooks/useOutsideClick';
import { RoleOption } from 'common/types/roles';
import { useTranslation } from 'react-i18next';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';

interface SelectProps {
  options: RoleOption[];
  value: string | number;
  name: string;
  ref?: React.Ref<HTMLSelectElement>;
  onSelect?: (value: string) => void;
  children?: React.ReactElement;
}

const Select: FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(({ options, value, name, onSelect, children }, ref) => {
  const selectRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(value || options?.[0]?.value);
  const { t } = useTranslation();

  useOutsideClick(selectRef, () => setOpen(false));

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onHandleChange = ({ value }: { value: string }) => {
    if (ref) setSelected(value);
    if (onSelect) return onSelect(value);
  };

  const { trackEvent } = useTracking();
  const onHandleFaqLinkClick = React.useCallback((value: string) => trackEvent(value), [trackEvent]);

  return (
    <Container isOpen={isOpen}>
      <SelectWrapper ref={ref} id={name} name={name} className='html-select'>
        {options?.map((item: RoleOption) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </SelectWrapper>
      <div
        ref={selectRef as React.MutableRefObject<HTMLDivElement>}
        onClick={() => setOpen(prev => !prev)}
        className='custom-select-wrapper'
      >
        <div className={`custom-select ${isOpen && 'open'}`}>
          <div className='custom-select__trigger'>
            <Label>{options?.find((item: RoleOption) => item.value === selected)?.label || 'Role'}</Label>
            <div className='arrow' />
          </div>
          <div className='custom-options'>
            <OptionsHeader>Roles</OptionsHeader>
            {options?.map((item: RoleOption) => (
              <div key={item.value} onClick={() => onHandleChange(item)} className='option-container'>
                <span className={`custom-option ${selected === item.value && 'selected'}`} data-value={item.value}>
                  <OptionLabelWrapper>
                    <Label>{item.label}</Label>
                    {selected === item.value && <DropDownItemIconCheck />}
                  </OptionLabelWrapper>
                  <SubLabel>{item.roleDescription}</SubLabel>
                </span>
              </div>
            ))}
            {children}
            <FaqLinkWrapper>
              <a
                href={t('faq.howToLink')}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onHandleFaqLinkClick(TRACKING_EVENTS.FAQ_MEMBERS_HOW_TO_LINK)}
              >
                <LinkIcon />
                <FaqLink variant='body2' color='regularGrey'>
                  {t('faq.howTo')}
                </FaqLink>
              </a>
              <a
                href={t('faq.userFaqLink')}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => onHandleFaqLinkClick(TRACKING_EVENTS.MEMBERS_USER_FAQ_LINK)}
              >
                <LinkIcon />
                <FaqLink variant='body2' color='regularGrey'>
                  {t('faq.userFaq')}
                </FaqLink>
              </a>
            </FaqLinkWrapper>
          </div>
        </div>
      </div>
    </Container>
  );
});

Select.displayName = 'Select';
export default memo(Select);
