import { NotificationAtom } from 'common/atoms/notifications';
import { alertBoxAtom, chartErrorAtom } from 'common/atoms/ui';
import React from 'react';
import { useRecoilState } from 'recoil';

export const useClearAllErrors = (): { clearErrors: () => void } => {
  const [, setNotification] = useRecoilState(NotificationAtom);
  const [, setAlertBox] = useRecoilState(alertBoxAtom);
  const [, setChartError] = useRecoilState(chartErrorAtom);

  const clearErrors = React.useCallback(() => {
    setAlertBox({
      show: false,
    });
    setNotification({
      show: false,
    });
    setChartError(null);
  }, [setAlertBox, setNotification, setChartError]);

  return React.useMemo(() => {
    return {
      clearErrors,
    };
  }, [clearErrors]);
};
