import * as React from 'react';
import { AxisProps, AxisTickProps } from '@nivo/axes';
import { displayDate } from 'utils/dates';
import styled from '@emotion/styled';
import { WavePayload } from 'common/types/common';
import { chartValueInMsOrKs, getTextSize } from 'utils/helpers';
import { theme } from 'common/theme';
import { concatEllipsis } from 'utils/helpers';

const ROTATED_CHAR_LIMIT = 14;
const TEXT_Y_POSITION = 10;

const BottomLabel = styled.text`
  font-size: ${({ theme }) => theme.text.variants.p.fontSize};
  font-weight: ${({ theme }) => theme.text.variants.p.fontWeight};
`;

const Group = styled.g`
  transition: 0.2s opacity linear;
  &[data-wave-pinned='true'] {
    cursor: pointer;
  }
`;

interface TickProps extends AxisTickProps<any> {
  waveMap: Record<number, WavePayload>;
  maxWidth: number;
  hasNumericValue?: boolean;
  hasGrouping?: boolean;
  showPopulationNumbers?: boolean;
  isBarLayout?: boolean;
}

// eslint-disable-next-line react/display-name
const BottomTick: React.FC<TickProps> = React.memo(
  ({
    waveMap,
    value,
    animatedProps,
    x,
    y,
    textAnchor,
    textBaseline,
    textX,
    textY,
    rotate,
    maxWidth,
    hasNumericValue = false,
    hasGrouping = false,
    showPopulationNumbers,
    isBarLayout,
  }) => {
    const labelRef = React.useRef<SVGTextElement>() as React.MutableRefObject<SVGTextElement>;
    const [opacity, setOpacity] = React.useState(1);
    const wave = React.useMemo(() => waveMap[value] || null, [waveMap, value]);
    const wavePinned = React.useMemo(() => wave !== null, [wave]);
    const axisValue = isBarLayout && showPopulationNumbers ? chartValueInMsOrKs(value, null) : value;

    const getOriginalText = (isPinned: boolean, waveDate: string | Date, labelValue: any, hasNumericValue: boolean) =>
      isPinned ? displayDate(waveDate, 'MMMM YYYY') : `${labelValue}${!hasNumericValue && isNaN(labelValue) ? '' : '%'}`;

    const getLabelText = (text: string) => (text?.split(': ')?.length > 1 ? text?.split(': ')[1] : text);

    //Formula for calculating how much chars can fit into width
    const getCharWidth = (maxWidth: number) => Math.ceil(((maxWidth - 20) * 1.91) / 12);

    const isTextClipped = React.useCallback(
      (maxWidth: number, textWidth: number) => {
        if (hasGrouping) return maxWidth > 0 ? maxWidth - 20 >= textWidth : false;
        return maxWidth > 0 ? maxWidth - 20 <= textWidth : false;
      },
      [hasGrouping],
    );

    const getClippedLabel = React.useCallback(
      (text: string, charNumToFitWidth: number) => {
        const charLimit = rotate ? ROTATED_CHAR_LIMIT : hasGrouping ? charNumToFitWidth : text.length;
        return text.length <= charLimit ? text : `${text.substring(0, charLimit)}...`;
      },
      [rotate, hasGrouping],
    );

    const dataLabel = React.useMemo(() => {
      const originalText = getOriginalText(
        wavePinned,
        wave?.date,
        axisValue,
        isBarLayout && showPopulationNumbers ? false : hasNumericValue,
      );
      const text = getLabelText(originalText);
      const [textWidth] = getTextSize(text, { fontSize: theme.text.variants.p.fontSize, fontFamily: theme.text.fontFamily });
      const charNumToFitWidth = getCharWidth(maxWidth);

      return [isTextClipped(maxWidth, textWidth) ? getClippedLabel(text, charNumToFitWidth) : text, originalText];
    }, [wavePinned, wave, maxWidth, isTextClipped, hasNumericValue, getClippedLabel, showPopulationNumbers, isBarLayout, axisValue]);

    const styles = React.useMemo(() => {
      return {
        opacity: opacity,
      };
    }, [opacity]);

    const labelTransformDegree = React.useMemo(() => {
      return `translate(${textX},${textY - TEXT_Y_POSITION})rotate(${rotate})`;
    }, [textX, textY, rotate]);

    const isTextRotated = React.useMemo(() => {
      return rotate !== 0;
    }, [rotate]);

    React.useEffect(() => {
      setOpacity(((animatedProps as any).opacity.animation.to as any) || 0);
    }, [animatedProps, value]);

    return (
      <Group data-wave-pinned={wavePinned} transform={`translate(${x},${y})`} style={styles}>
        <BottomLabel
          ref={labelRef}
          textAnchor={textAnchor}
          dominantBaseline={textBaseline}
          transform={labelTransformDegree}
          style={{
            fontSize: theme.text.variants.p.fontSize,
            fontWeight: theme.text.variants.p.fontWeight,
            fill: theme.colors.moreThanAWeek.toString(),
          }}
        >
          <title>{dataLabel[1]}</title>
          {isTextRotated ? concatEllipsis(dataLabel[0], ROTATED_CHAR_LIMIT) : dataLabel[0]}
        </BottomLabel>
      </Group>
    );
  },
);

interface IAxisBottom {
  maxWidth: number;
  waveMap: Record<number, WavePayload>;
  hasNumericValue?: boolean;
  isBarLayout?: boolean;
  hasGrouping?: boolean;
  rotateXLabels?: boolean;
  showPopulationNumbers?: boolean;
}

const AxisBottom = ({
  maxWidth,
  waveMap,
  hasNumericValue,
  hasGrouping,
  rotateXLabels,
  isBarLayout,
  showPopulationNumbers,
}: IAxisBottom) => {
  return {
    tickSize: 0,
    tickRotation: rotateXLabels ? -45 : 0,
    tickPadding: 24,
    renderTick: props => {
      return (
        <BottomTick
          maxWidth={maxWidth}
          waveMap={waveMap}
          hasNumericValue={hasNumericValue}
          showPopulationNumbers={showPopulationNumbers}
          hasGrouping={hasGrouping}
          isBarLayout={isBarLayout}
          {...props}
        />
      );
    },
  } as AxisProps;
};

export default AxisBottom;
