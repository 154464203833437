import React from 'react';
import styled from '@emotion/styled';
import { SwitchChartView } from 'icons';
import { SortOption } from 'common/types/chart';
import { LatanaText } from 'common/components/LatanaText';
import DropDownWithIcon, { DropdownAlign } from 'common/components/DropDownWithIcon';
import { ActionTypes, FormStates } from 'common/types/segments';
import { IconPosition, LabelButtonWithIcon } from 'common/styledComponents/buttons';
import { useTranslation } from 'react-i18next';
import { Study } from 'common/types/account';
import { SegmentTabNames, Segment } from 'common/types/segments';
import { DataTestId } from 'settings/constants';
import { useNavigate } from 'react-router-dom';
import { SEGMENTS_ROUTES } from 'settings/routes';
import { tooltipOnHover } from 'common/styledComponents/modals';

const SidebarContainer = styled.nav`
  min-width: 245px;
  max-width: 245px;
  padding: ${({ theme }) => theme.v2.space(3)};
  border-right: 1px solid ${({ theme }) => theme.colors.jupiter.toString()};
`;

const SidebarItem = styled.div<{ isSelected: boolean }>`
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
  cursor: pointer;
  padding: ${({ theme }) => theme.v2.space(0.5)};
  margin-bottom: ${({ theme }) => theme.v2.space(1)};
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  background-color: ${({ isSelected, theme }) => (isSelected ? `${theme.colors.gray100.toString()}` : 'transparent')};
  position: relative;

  &[data-hover] {
    ${tooltipOnHover('bottom')}
  }
`;

const SidebarDropdownWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.v2.space(2.5)};
`;

const SidebarButtonWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.v2.space(2.5)};
`;

interface SegmentSidebarProps {
  tabName?: string;
  segments: Segment[];
  formState: FormStates;
  defaultSegmentName?: string;
  studies: Study[] | undefined;
  currentSegment: Segment | null;
  onChange: <T>(value: T) => void;
  handleSegmentClick: (segment: Segment) => void;
  selectedStudy: string | SortOption | null;
  formStateDispatch: React.Dispatch<{ type: ActionTypes }>;
}

const SidebarDropdown: React.FC<{
  studyOptions: { value: string; label: string }[];
  selectedStudy: string | SortOption | null;
  onChange: <T>(value: T) => void;
}> = ({ studyOptions, selectedStudy, onChange }) => {
  const { t } = useTranslation();
  return (
    <SidebarDropdownWrapper>
      <DropDownWithIcon
        noHover
        withArrow
        withBorder
        withTopLabel
        renderInBody
        extraPadding
        showDefaultText
        onSelect={onChange}
        noActiveDefaultText
        options={studyOptions}
        align={DropdownAlign.LEFT}
        selectedValue={selectedStudy}
        toolbarIcon={SwitchChartView}
        testId={DataTestId.STUDY_DROPDOWN}
        label={t('segmentBuilder.selectStudy')}
        topLabel={t('segmentBuilder.studyLabel')}
        noLabel
      />
    </SidebarDropdownWrapper>
  );
};

const AddSegmentButton: React.FC<{ formStateDispatch: React.Dispatch<{ type: ActionTypes }> }> = ({ formStateDispatch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddSegmentClick = React.useCallback(() => {
    formStateDispatch({ type: ActionTypes.SET_CREATE });
    navigate(`/segments/${SEGMENTS_ROUTES.CUSTOM}/new`);
  }, [formStateDispatch, navigate]);

  return (
    <SidebarButtonWrapper>
      <LabelButtonWithIcon
        disabled={false}
        icon='plus-circle-fill'
        iconColorActive='latana500'
        iconColorInactive='latana500'
        iconPosition={IconPosition.RIGHT}
        label={t('segmentBuilder.addSegment')}
        data-testid={DataTestId.ADD_SEGMENT_BUTTON}
        onClick={handleAddSegmentClick}
      />
    </SidebarButtonWrapper>
  );
};

const SegmentSidebar: React.FC<SegmentSidebarProps> = ({
  handleSegmentClick,
  tabName,
  onChange,
  formState,
  studies = [],
  selectedStudy,
  segments = [],
  currentSegment,
  formStateDispatch,
  defaultSegmentName,
}): JSX.Element => {
  const isCreateMode = formState === FormStates.CREATE;
  const isLatanaSegmentsTab = tabName === SegmentTabNames.LATANA;
  const isShowDropdown = !isLatanaSegmentsTab && studies.length > 1;
  const isShowAddButton = !isLatanaSegmentsTab && selectedStudy;

  const studyOptions = React.useMemo(() => studies.map(({ uuid, name }) => ({ value: uuid, label: name })), [studies]);

  return (
    <SidebarContainer>
      {isShowDropdown && <SidebarDropdown studyOptions={studyOptions} selectedStudy={selectedStudy} onChange={onChange} />}
      {isShowAddButton && <AddSegmentButton formStateDispatch={formStateDispatch} />}
      {isCreateMode && (
        <SidebarItem isSelected={true} data-hover={defaultSegmentName}>
          <LatanaText variant='L1' color='gray500'>
            {defaultSegmentName}
          </LatanaText>
        </SidebarItem>
      )}
      {segments.map(segment => {
        const isSelected = !isCreateMode && segment.id === currentSegment?.id;
        return (
          <SidebarItem key={segment.id} onClick={() => handleSegmentClick(segment)} isSelected={isSelected} data-hover={segment.name}>
            <LatanaText variant='L1' color={isSelected ? 'gray900' : 'gray500'}>
              {segment.name}
            </LatanaText>
          </SidebarItem>
        );
      })}
    </SidebarContainer>
  );
};

export default React.memo(SegmentSidebar);
