import { atom } from 'recoil';
import { Member, UserRole } from 'common/types/roles';

export const RolesAtom = atom<UserRole[] | null>({
  key: 'Roles',
  default: null,
});

export const MembersAtom = atom<Member[] | null>({
  key: 'Members',
  default: null,
});
