import * as React from 'react';
import { LatanaText } from 'common/components/LatanaText';
import { MFHeader } from 'modules/charts/components/MarketFunnel/getMarketFunnelData';
import {
  TableHeaderSubtitle,
  TableHead,
  TableHeaderCell,
  TableHeadItem,
  TableHeadRow,
  TableHeaderSubtitleContainer,
} from 'views/MarketFunnel/styled';
import { SortController } from 'modules/charts/components/TableChart/styled';
import SortingControl from 'modules/charts/components/TableChart/SortingControl';
import { SortOption, SortType } from 'common/types/chart';

interface MarketFunnelHeaderProps {
  headers: MFHeader[];
  onSort: (key: string | null) => void;
  sorting: SortOption;
}

const MarketFunnelHeader: React.FC<MarketFunnelHeaderProps> = ({ headers, onSort, sorting }) => {
  const getSortingType = (key: string | null, sorting: SortOption) => (sorting.key === key ? sorting.type : SortType.DEFAULT);
  return (
    <TableHead>
      <TableHeadRow>
        <TableHeadItem />
        {headers.map(({ title, subtitles }) => (
          <TableHeadItem key={`header-item-${title}`}>
            <TableHeaderCell>
              <LatanaText variant='T2' color='gray500'>
                {title}
              </LatanaText>
              <TableHeaderSubtitle>
                {subtitles.map(({ title: subtitle, key }) => (
                  <TableHeaderSubtitleContainer key={key}>
                    <LatanaText variant='B2' color='gray500'>
                      {subtitle}
                    </LatanaText>
                    <SortController onClick={() => onSort(key)}>
                      <SortingControl sortType={getSortingType(key, sorting)} />
                    </SortController>
                  </TableHeaderSubtitleContainer>
                ))}
              </TableHeaderSubtitle>
            </TableHeaderCell>
          </TableHeadItem>
        ))}
      </TableHeadRow>
    </TableHead>
  );
};

export default MarketFunnelHeader;
