import { accountDetailsSelector, permissionsSelector } from 'common/atoms/account';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import React, { Fragment } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { SETTINGS_ROUTES } from 'settings/routes';
import { openNewTab } from 'utils/helpers';
import CSVExportModal from 'common/components/CSVExportModal';
import Icon from 'common/components/Icon';
import { BottomActionsGroup, BottomNavContainer, ButtonDivider, IconButtonContainer } from './styled';
import { NavButtonProps } from 'common/types/common';
import { DataTestId } from 'settings/constants';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_API_URL: API_URL = '' } = process.env;

const BottomNav = () => {
  const [csvModalOpen, setCSVModalOpen] = useState(false);
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  const { user } = useRecoilValue(accountDetailsSelector);
  const { internal: isInternalUser } = useRecoilValue(permissionsSelector);
  const navigate = useNavigate();

  const handleTrackEvent = useCallback(
    (eventName: string, screen?: string) => {
      trackEvent(eventName, {
        internal: isInternalUser,
        account: user.name,
        userId: user.uuid,
        screen,
      });
    },
    [isInternalUser, user, trackEvent],
  );

  const downloadCSVHandle = useCallback(() => {
    handleTrackEvent(TRACKING_EVENTS.DOWNLOAD_CSV);
    setCSVModalOpen(true);
  }, [setCSVModalOpen, handleTrackEvent]);

  const clickToFaqHandle = useCallback(() => {
    handleTrackEvent(TRACKING_EVENTS.CLICK_TO_FAQ);
    openNewTab(t('faq.faqUrl'));
  }, [t, handleTrackEvent]);

  const goToAdminHandle = useCallback(() => {
    handleTrackEvent(TRACKING_EVENTS.CLICK_TO_ADMIN);
    openNewTab(`${API_URL}/admin/customers`);
  }, [handleTrackEvent]);

  const goToSettings = useCallback(() => {
    navigate(`/settings/${SETTINGS_ROUTES.PROFILE}`, {
      state: { prevUrl: window.location.href },
    });
  }, [navigate]);

  const bottomItems: NavButtonProps[] = [
    {
      icon: 'dependency',
      label: t('nav.admin'),
      action: goToAdminHandle,
      disabled: !isInternalUser,
      testId: DataTestId.ADMIN_BUTTON,
    },
    {
      icon: 'download-all',
      label: t('nav.downloadCsv'),
      action: downloadCSVHandle,
    },
    {
      icon: 'question-circle',
      label: t('nav.faq'),
      action: clickToFaqHandle,
    },
    {
      icon: 'cog',
      label: t('nav.settings'),
      divider: true,
      action: goToSettings,
    },
  ];

  return (
    <>
      <BottomNavContainer>
        <BottomActionsGroup>
          {bottomItems.map(
            item =>
              !item.disabled && (
                <Fragment key={`BottomNav-${item.label}`}>
                  {item.divider && <ButtonDivider />}
                  <IconButtonContainer
                    role='button'
                    key={item.label}
                    data-hover={item.label}
                    onClick={item?.action}
                    data-testid={item.testId}
                  >
                    <Icon icon={item.icon} color='gray600' size={20} />
                  </IconButtonContainer>
                </Fragment>
              ),
          )}
        </BottomActionsGroup>
      </BottomNavContainer>
      <CSVExportModal onClose={() => setCSVModalOpen(false)} open={csvModalOpen} />
    </>
  );
};

export default React.memo(BottomNav);
