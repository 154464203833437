import * as React from 'react';
import { Text } from 'common/styledComponents/typography';
import { ChartNameContainer, NameInput } from 'modules/charts/components/Filters/styled';

interface ChartNameProps {
  isEditable: boolean;
  chartName: string;
  reg: () => void;
  onHandleFocus: () => void;
  onSubmit?: () => void;
}

const ChartName: React.FC<ChartNameProps> = ({ isEditable, chartName, reg, onSubmit, onHandleFocus }) => (
  <ChartNameContainer>
    {isEditable ? (
      <NameInput ref={reg} name='name' required variant='h4' placeholder={chartName} onFocus={onHandleFocus} onBlur={onSubmit} />
    ) : (
      <Text variant='h4'>{chartName}</Text>
    )}
  </ChartNameContainer>
);

export default React.memo(ChartName);
