import styled from '@emotion/styled';
import React from 'react';
import { coloursPalette } from 'theme/colours';
import { TextVariants } from 'theme/typography';

const RectangleLabel = styled.text`
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  font-size: ${({ theme }) => theme.v2.text.variants[TextVariants.T2].fontSize};
  font-weight: ${({ theme }) => theme.v2.text.variants[TextVariants.T2].fontWeight};
  line-height: ${({ theme }) => theme.v2.text.variants[TextVariants.T2].lineHeight};
  letter-spacing: ${({ theme }) => theme.v2.text.variants[TextVariants.T2].letterSpacing};
`;

export enum MATRIX_BG_RECTANGLE_POSITION {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

const GAP = 2;
const LABEL_OFFSET = 12;

interface Props {
  chartWidth: number;
  chartHeight: number;
  position: MATRIX_BG_RECTANGLE_POSITION;
  label: string;
  showLabel?: boolean;
}

const BG_COLOR_MAP = {
  [MATRIX_BG_RECTANGLE_POSITION.TOP_LEFT]: coloursPalette.gray50,
  [MATRIX_BG_RECTANGLE_POSITION.TOP_RIGHT]: coloursPalette.mint100,
  [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_LEFT]: coloursPalette.pink100,
  [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_RIGHT]: coloursPalette.gray50,
};

const TEXT_COLOR_MAP = {
  [MATRIX_BG_RECTANGLE_POSITION.TOP_LEFT]: coloursPalette.gray500,
  [MATRIX_BG_RECTANGLE_POSITION.TOP_RIGHT]: coloursPalette.mint800,
  [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_LEFT]: coloursPalette.pink800,
  [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_RIGHT]: coloursPalette.gray500,
};

const BackgroundRectangle = ({ chartWidth, chartHeight, position, label, showLabel = false }: Props) => {
  const radius = 10;
  const rectWidth = (chartWidth - GAP) / 2;
  const rectHeight = (chartHeight - GAP) / 2;

  if (chartHeight < 0 || chartWidth < 0) {
    return null;
  }

  const getRectangleCoords = (position: MATRIX_BG_RECTANGLE_POSITION) => {
    const positionCoords = {
      [MATRIX_BG_RECTANGLE_POSITION.TOP_LEFT]: { x: 0, y: 0 },
      [MATRIX_BG_RECTANGLE_POSITION.TOP_RIGHT]: { x: rectWidth + GAP, y: 0 },
      [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_LEFT]: { x: 0, y: rectHeight + GAP },
      [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_RIGHT]: { x: rectWidth + GAP, y: rectHeight + GAP },
    };

    // Return coordinates based on position
    return positionCoords[position] || positionCoords[MATRIX_BG_RECTANGLE_POSITION.TOP_LEFT];
  };

  const getPath = (x: number, y: number, roundCorner: MATRIX_BG_RECTANGLE_POSITION) => {
    const pathDataMap = {
      [MATRIX_BG_RECTANGLE_POSITION.TOP_LEFT]: `M${x + radius},${y} h${rectWidth - radius} v${rectHeight} h-${rectWidth} v-${
        rectHeight - radius
      } a${radius},${radius} 0 0,1 ${radius}-${radius} z`,
      [MATRIX_BG_RECTANGLE_POSITION.TOP_RIGHT]: `M${x},${y} h${rectWidth - radius} a${radius},${radius} 0 0,1 ${radius},${radius} v${
        rectHeight - radius
      } h-${rectWidth} v-${rectHeight} z`,
      [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_LEFT]: `M${x},${y} h${rectWidth} v${rectHeight} h-${
        rectWidth - radius
      } a${radius},${radius} 0 0,1 -${radius}-${radius} v-${rectHeight - radius} z`,
      [MATRIX_BG_RECTANGLE_POSITION.BOTTOM_RIGHT]: `M${x},${y} h${rectWidth} v${
        rectHeight - radius
      } a${radius},${radius} 0 0,1 -${radius},${radius} h-${rectWidth - radius} v-${rectHeight} z`,
    };

    // Return path data based on roundCorner
    return pathDataMap[roundCorner] || '';
  };

  const getTextPosition = (x: number, y: number, position: MATRIX_BG_RECTANGLE_POSITION) => {
    if (position === MATRIX_BG_RECTANGLE_POSITION.TOP_LEFT || position === MATRIX_BG_RECTANGLE_POSITION.TOP_RIGHT) {
      return {
        x: x + rectWidth / 2,
        y: y + LABEL_OFFSET + 10, // Adding 10 to align text vertically
      };
    } else {
      return {
        x: x + rectWidth / 2,
        y: y + rectHeight - LABEL_OFFSET, // Adjust for bottom position
      };
    }
  };

  const rectangleCoords = getRectangleCoords(position);

  return (
    <>
      <path d={getPath(rectangleCoords.x, rectangleCoords.y, position)} fill={BG_COLOR_MAP[position]} />
      {showLabel && (
        <RectangleLabel
          x={getTextPosition(rectangleCoords.x, rectangleCoords.y, position).x}
          y={getTextPosition(rectangleCoords.x, rectangleCoords.y, position).y}
          textAnchor='middle'
          fill={TEXT_COLOR_MAP[position]}
        >
          {label}
        </RectangleLabel>
      )}
    </>
  );
};

export default React.memo(BackgroundRectangle);
