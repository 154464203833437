import { ChartFields, ChartType } from 'common/types/chart';
import { Wave } from 'common/types/common';
import { FilterNames } from 'common/types/filters';
import { BaseFilterService } from '.';

interface WaveFilterService {
  lastWave?: Wave;
  lastWaves?: number[];
  setLastWave: (lastWave: Wave) => void;
  setLastWaves: (lastWave: number[]) => void;
  onShowFilter: (filterId: FilterNames) => void;
  onHandleSelectLastWave: () => void;
  isFilterDisabled: (filterId: FilterNames) => boolean;
}

export const WaveFilterService: WaveFilterService = {
  setLastWave(lastWave: Wave) {
    WaveFilterService.lastWave = lastWave;
  },
  setLastWaves(lastWaves: number[]) {
    WaveFilterService.lastWaves = lastWaves;
  },
  onShowFilter(filterId) {
    const shouldDisableWave =
      BaseFilterService.chartType === ChartType.MARKET_FUNNEL ||
      BaseFilterService.chartType === ChartType.BRAND_PERCEPTION ||
      BaseFilterService.chartType === ChartType.GROWTH_PERFORMANCE ||
      BaseFilterService.chartType === ChartType.MATRIX;
    // [Wave] tab should be temporary locked for these chart types.
    if (shouldDisableWave && filterId === FilterNames.WAVE) return;
  },
  onHandleSelectLastWave() {
    if (WaveFilterService?.lastWave?.id) {
      BaseFilterService?.setChartValue?.(ChartFields.WAVE, [WaveFilterService.lastWave.id]);
    }
  },
  isFilterDisabled(filterId) {
    const shouldDisableWave =
      BaseFilterService.chartType === ChartType.MARKET_FUNNEL ||
      BaseFilterService.chartType === ChartType.BRAND_PERCEPTION ||
      BaseFilterService.chartType === ChartType.GROWTH_PERFORMANCE ||
      BaseFilterService.chartType === ChartType.MATRIX;

    // [Wave] tab should be temporary locked for these chart types.
    if (shouldDisableWave && filterId === FilterNames.WAVE) return true;
    return false;
  },
};
