import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import { theme } from 'common/theme';

const ModalContainer = styled.div``;

const TopContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.padding * 3}px ${({ theme }) => theme.spacing.padding * 3}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGrey.toString()};
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CertanityBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderGrey.toString()};
  border-bottom: none;
  padding: ${({ theme }) => theme.spacing.padding * 3}px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12));
  border-radius: 8px 8px 0px 0px;
  width: 320px;
`;

const MoeValueBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MoeValuesBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const MoeIndicatorBox = styled.div`
  padding: ${({ theme }) => theme.spacing.padding}px ${({ theme }) => theme.spacing.padding * 2}px;
  margin: 0px ${({ theme }) => theme.spacing.padding * 2}px;
  background: ${({ theme, color }) => (color ? color : theme.colors.grass.toString())};
  color: ${({ theme }) => theme.colors.white.toString()};
  border-radius: 8px;
`;

const CertainityText = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.padding * 2}px;
  text-align: center;
`;

const BottomContainer = styled.div`
  display: flex;
  position: relative;
  width: 320px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.padding * 5}px 0px;
  flex-direction: column;
`;

const BottomFieldsWrapper = styled.div``;

const FieldItem = styled.div`
  float: left;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black25.toString()};
  font-size: ${({ theme }) => theme.text.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.text.variants.body2.fontWeight};

  span:after {
    content: ' ..................................................................................';
  }
`;

const ValueItem = styled.div`
  float: left;
  width: 160px;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.spacing.padding}px;
  color: ${({ theme }) => theme.colors.black.toString()};
  font-size: ${({ theme }) => theme.text.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.text.variants.body2.fontWeight};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: ${({ theme }) => theme.spacing.padding * 5}px;
  span {
    margin: ${theme.space(0, 1, 0, 0)};
  }
`;

export {
  ModalContainer,
  TopContainer,
  CertanityBox,
  MoeValueBox,
  MoeValuesBox,
  MoeIndicatorBox,
  CertainityText,
  BottomContainer,
  BottomFieldsWrapper,
  FieldItem,
  ValueItem,
  Title,
};
