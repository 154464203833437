export const FEATURES = {
  REFERENCE_AVERAGE: 'display_reference_average_in_charts',
  COMPETITORS: 'organisation_profile',
  OLD_SURVEY_BA_BRANCHING: 'old_survey_brand_awareness_branching',
  HIDE_POPULATION_NUMBERS: 'hide_population_numbers', // Specific for a Turo customer
  ENABLE_POPULATION_NUMBERS: 'enable_population_numbers',
  MATRIX_CHART: 'matrix_chart',
  SHOW_CHART_FILTERS_ON_DASHBOARD: 'show_chart_filters_on_dashboard',
};

export const RADAR_CHART_KPIS = {
  BRAND_ASSOCIATIONS: 'brand-associations',
  PURCHASE_DRIVER: 'purchase-drivers',
};
