import { ChartType } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';
import { BaseFilterService } from '.';

interface SegmentFilterService {
  onShowFilter: (filterId: FilterNames) => void;
  isFilterDisabled: (filterId: FilterNames, isPercentageAbsoluteSwitchEnabled: boolean | undefined) => boolean | undefined;
  onFilterApply?: () => void;
}

export const SegmentFilterService: SegmentFilterService = {
  onShowFilter(filterId) {
    const isSegmentFilterDisabled = BaseFilterService.chartType === ChartType.MARKET_SIZE && filterId === FilterNames.SEGMENT;

    if (isSegmentFilterDisabled) {
      return;
    }
  },
  isFilterDisabled(filterId, isPercentageAbsoluteSwitchEnabled) {
    const isMarketFunnel = BaseFilterService.chartType === ChartType.MARKET_FUNNEL;
    const isMarketSize = BaseFilterService.chartType === ChartType.MARKET_SIZE;
    const isSegmentFilter = filterId === FilterNames.SEGMENT;

    if (isSegmentFilter && (isMarketSize || (isMarketFunnel && !isPercentageAbsoluteSwitchEnabled))) {
      return true;
    }

    return false;
  },
};
