import { uiPreferencesSelector } from 'common/atoms/account';
import { useUpdateChartSort } from 'common/hooks/charts';
import { useStudySpecificSegment } from 'common/hooks/segments';
import { Text } from 'common/styledComponents/typography';
import {
  ChartHeaderNames,
  ChartNumbersType,
  GPChartCellsValues,
  GPChartData,
  GPHeader,
  GrowthPerformanceData,
  RawChartData,
  RawChartSchema,
  SortOption,
  SortType,
} from 'common/types/chart';
import { GPGreenArrowComponent, GPRedArrowComponent } from 'icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getChartData } from 'modules/charts/components/GrowthPerformance/getChartData';
import SortingControl from 'modules/charts/components/TableChart/SortingControl';
import {
  TableBodyCellContainer,
  TableDataItem,
  TableHeadItem,
  ValueNameContainer,
  BrandGroupPipe,
} from 'modules/charts/components/GrowthPerformance/styled';
import { ChartDetails, DataTestId } from 'settings/constants';
import { useGetPrimaryBrands } from 'common/hooks/brands';
import { useBrandCompetitorsByCountry } from 'common/hooks/brandGroups';
import { isAllBrandsAreCompetitors } from 'utils/chart';
import {
  TableHeadRow,
  TableChartContainer,
  Table,
  TableHead,
  TableBodyHeader,
  TableHeaderText,
  TableBodyHeaderInner,
  TableBody,
  TableBodyRow,
  TableArrowContainer,
  SortController,
  TableHeaderCell,
} from 'modules/charts/components/TableChart/styled';
import { useGetCountryIdMapByStudyId } from 'common/hooks/countries';
import { useGetWavesMap } from 'common/hooks/waves';
import { useGetStudySpecificRestrictions } from 'common/hooks/filters';
import { useAllKpisMap } from 'common/hooks/kpis';
import { useHidePopulationNumbersEnabled, useIsPopulationNumbersEnabled } from 'common/hooks/features';

interface Props {
  chartConf: RawChartSchema;
  chartData: RawChartData[] | undefined;
  brandAwarenessData?: RawChartData[];
  chartNumbersType: ChartNumbersType;
}

const GrowthPerformance: React.FC<Props> = ({ chartConf, chartData, brandAwarenessData, chartNumbersType }) => {
  const { study_uuid: studyUuid, current_sort: currentSort } = chartConf;

  const { t } = useTranslation();
  const waveMap = useGetWavesMap();
  const allFilters = useGetStudySpecificRestrictions(studyUuid);
  const segmentList = useStudySpecificSegment(studyUuid);
  const { brands, countries } = allFilters;
  const getCountryIdMap = useGetCountryIdMapByStudyId();
  const { decimalsEnabled } = useRecoilValue(uiPreferencesSelector);
  const primaryBrands = useGetPrimaryBrands();
  const kpisMap = useAllKpisMap(studyUuid);
  const hidePopulationNumbers = useHidePopulationNumbersEnabled(studyUuid);
  const isSegmentPopNumbersEnabled = useIsPopulationNumbersEnabled(studyUuid);

  const brandCompetitors = useBrandCompetitorsByCountry(chartConf.country_ids);
  const { updateChartCurrentSort } = useUpdateChartSort();

  const [chartSortingType, setChartSortingType] = React.useState<SortOption>(currentSort ?? { key: null, type: SortType.DESC });

  const handleChartSortingChange = React.useCallback(
    (key: string | null) => {
      let type = SortType.DESC;

      if (key === null) {
        return;
      }

      switch (chartSortingType.type) {
        case SortType.DEFAULT:
          type = SortType.DEFAULT;
          break;
        case SortType.DESC:
          type = SortType.ASC;
          break;
        default:
          type = SortType.DESC;
      }

      setChartSortingType({ key, type });
      updateChartCurrentSort(chartConf, { key, type });
    },
    [chartSortingType, setChartSortingType, chartConf, updateChartCurrentSort],
  );

  const sortChartData = React.useCallback(
    (chartData: Array<GPChartData>, columnKey: string) => {
      const key = columnKey.slice(-1); // can be 0, 1 or 2.
      return chartData.sort((a, b) => {
        if (!chartSortingType.key || chartSortingType.key === SortType.DEFAULT) {
          return 0;
        } else if (chartSortingType.type === SortType.DESC) {
          return getValue(b, key) - getValue(a, key);
        } else {
          return getValue(a, key) - getValue(b, key);
        }
      });

      function getValue(data: GPChartData | any, key: string) {
        const rowKey =
          chartNumbersType === ChartNumbersType.VALUES ? `${chartSortingType.key}_populationRaw` : `${chartSortingType.key}_raw`;
        return data.values[key]?.[rowKey];
      }
    },
    [chartSortingType.key, chartSortingType.type, chartNumbersType],
  );

  const newChartData: GrowthPerformanceData = React.useMemo(() => {
    const chartFilters = {
      segment: segmentList,
      country: countries,
      brand: brands,
      wave: waveMap,
      kpi: kpisMap,
    };

    return getChartData(
      chartData,
      chartConf,
      chartFilters,
      getCountryIdMap(studyUuid),
      decimalsEnabled,
      brandAwarenessData || [],
      t,
      isSegmentPopNumbersEnabled,
    );
  }, [
    chartData,
    brands,
    chartConf,
    countries,
    segmentList,
    t,
    waveMap,
    kpisMap,
    decimalsEnabled,
    getCountryIdMap,
    brandAwarenessData,
    studyUuid,
    isSegmentPopNumbersEnabled,
  ]);

  const sortedChartData = React.useMemo(() => {
    let chartDataCopy = newChartData.chartData.slice();

    if (chartSortingType.key && chartSortingType.type) {
      chartDataCopy = sortChartData(chartDataCopy, chartSortingType.key);
    }

    return { ...newChartData, chartData: chartDataCopy };
  }, [chartSortingType.key, chartSortingType.type, newChartData, sortChartData]);

  //Remove after rerun of the studies
  const showPopulationNumber = React.useMemo(() => {
    if (hidePopulationNumbers) {
      return false;
    } else {
      return true;
    }
  }, [hidePopulationNumbers]);

  const renderTableHeader = (headers: GPHeader[]) => {
    const getSortingType = (key: string, sorting: Array<SortOption>): SortType => {
      return sorting.find(item => item.key === key)?.type || SortType.DEFAULT;
    };
    return (
      <>
        <TableHeadRow data-testid={DataTestId.GP_TABLE_HEADER}>
          {headers?.map(({ key, title }) => (
            <TableHeadItem key={key}>
              <TableHeaderCell>
                <TableHeaderText variant='subheading2' color='regularGrey' fontWeight='400' uppercase={title === ChartDetails.KPI}>
                  <span onClick={() => handleChartSortingChange(key)} role='button'>
                    {title}
                  </span>
                </TableHeaderText>
                {key !== null && (
                  <SortController onClick={() => handleChartSortingChange(key)}>
                    <SortingControl sortType={getSortingType(key, [chartSortingType])} />
                  </SortController>
                )}
              </TableHeaderCell>
            </TableHeadItem>
          ))}
        </TableHeadRow>
      </>
    );
  };

  const renderArrow = (isDynamicPositive: boolean | null | undefined) => {
    return isDynamicPositive === null ? null : isDynamicPositive ? <GPGreenArrowComponent /> : <GPRedArrowComponent />;
  };

  const renderTableDataCells = (chartData: GPChartCellsValues[]) => {
    const isShowAbsoluteValues = showPopulationNumber && chartNumbersType === ChartNumbersType.VALUES;

    const isShowPercentageAndAbsValues = (valueAbs: string | null, headerName?: ChartHeaderNames | null): boolean | null => {
      const isValueAbsNotNull = valueAbs !== null;
      const isHeaderNameNotSegment = headerName !== ChartHeaderNames.SEGMENT_SIZE;
      const isChartNumbersTypeBoth = chartNumbersType === ChartNumbersType.BOTH;

      return isValueAbsNotNull && isHeaderNameNotSegment && isChartNumbersTypeBoth && showPopulationNumber;
    };

    return chartData?.map(({ isDynamicPositive, valueColor, value, valueAbs, headerName }: GPChartCellsValues, index: number) => (
      <TableDataItem key={index}>
        <TableBodyCellContainer>
          <TableArrowContainer>{renderArrow(isDynamicPositive)}</TableArrowContainer>
          <Text variant='subheading2' color={valueColor} fontWeight='400'>
            {isShowAbsoluteValues ? valueAbs : value}
          </Text>
          {valueAbs !== value && isShowPercentageAndAbsValues(valueAbs, headerName) && (
            <Text variant='subheading2' color='regularGrey' fontWeight='400'>
              {`(${valueAbs})`}
            </Text>
          )}
        </TableBodyCellContainer>
      </TableDataItem>
    ));
  };

  const renderTableBody = (chartData: GPChartData[]) => {
    return (
      <>
        {chartData.map(({ id, label, values }) => {
          const isPrimaryBrand = primaryBrands?.includes(id);
          const isCompetitorBrand = brandCompetitors?.includes(+id);
          const isAllBrandsCompetitors = isAllBrandsAreCompetitors(chartConf.brand_ids, primaryBrands, brandCompetitors);
          const isShowCompetitor = !isAllBrandsCompetitors && isCompetitorBrand;
          return (
            <TableBodyRow key={`gp-table-row-item-${id}`} isPrimaryBrand={!!isPrimaryBrand} isCompetitorBrand={isShowCompetitor}>
              <TableBodyHeader>
                <TableBodyHeaderInner>
                  {isPrimaryBrand && <BrandGroupPipe isPrimary={isPrimaryBrand} />}
                  {isShowCompetitor && <BrandGroupPipe />}
                  <ValueNameContainer>
                    <Text variant='subheading2' color='black' fontWeight='400'>
                      {label}
                    </Text>
                  </ValueNameContainer>
                </TableBodyHeaderInner>
              </TableBodyHeader>
              {renderTableDataCells(values)}
            </TableBodyRow>
          );
        })}
      </>
    );
  };

  return (
    <TableChartContainer>
      <Table>
        <TableHead>{renderTableHeader(sortedChartData.chartHeaders)}</TableHead>
        <TableBody data-testid={DataTestId.GP_TABLE}>{renderTableBody(sortedChartData.chartData)}</TableBody>
      </Table>
    </TableChartContainer>
  );
};

export default React.memo(GrowthPerformance);
