import React from 'react';
import { useTranslation } from 'react-i18next';
import { LatanaText } from 'common/components/LatanaText';
import { Button } from 'common/components/Form/Button';
import { DataTestId } from 'settings/constants';
import { NoResultBottom, NoResultContainer, NoResultText } from 'common/components/NoResult/styled';

interface Props {
  title?: string;
  subtitle?: string;
  actions?: { label: string; handleClick: () => void }[];
  image?: 'dashboard';
}

const NoResult = ({ title, subtitle, actions, image }: Props) => {
  const { t } = useTranslation();

  return (
    <NoResultContainer data-testid={DataTestId.EMPTY_STATE}>
      {image && <img src={require(`./images/${image}.png`).default} />}
      <NoResultText>
        <LatanaText variant='H2'>{title || t('tableList.noResultTitle')}</LatanaText>
        <LatanaText variant='B2' color='gray500'>
          {subtitle || t('tableList.noResultSubtitle')}
        </LatanaText>
      </NoResultText>
      <NoResultBottom>
        {actions?.map(({ label, handleClick }) => (
          <Button key={label} isButtonV2 onClick={handleClick} data-testid={DataTestId.NO_CONTENT_ACTION_BUTTON}>
            {label}
          </Button>
        ))}
      </NoResultBottom>
    </NoResultContainer>
  );
};

export default React.memo(NoResult);
