import { AlertBox } from 'common/types/notification';
import { atom } from 'recoil';

export const alertBoxAtom = atom<AlertBox | null>({
  key: 'alertBox',
  default: {
    id: '',
    show: false,
    message: null,
  },
});

export const chartErrorAtom = atom<string | null>({
  key: 'chartError',
  default: null,
});
