import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, LoadingButton } from 'common/components/Form/Button';
import { useChartViewForm } from 'common/contexts/ChartViewContext';
import { ButtonContainer } from 'modules/charts/components/Filters/styled';

interface ChartHeaderButtonsProps {
  canSubmit: boolean;
  chartUpdateLoading: boolean;
  onHandleReset: () => void;
  isChartValuesChanged: boolean;
  isFilterChanged: boolean;
}

const ChartHeaderButtons: React.FC<ChartHeaderButtonsProps> = ({
  canSubmit,
  chartUpdateLoading,
  onHandleReset,
  isChartValuesChanged,
  isFilterChanged,
}) => {
  const { t } = useTranslation();
  const { submitRef } = useChartViewForm();

  const isShowSaveButton = isFilterChanged || (canSubmit && isChartValuesChanged);

  return (
    <ButtonContainer>
      {isChartValuesChanged && (
        <Button pseudo color='black05' onClick={onHandleReset} type='button'>
          {t(`common.cancel`)}
        </Button>
      )}
      {isShowSaveButton && (
        <LoadingButton ref={submitRef} color='lightPurple' type='submit' loading={chartUpdateLoading}>
          {t(`common.save`)}
        </LoadingButton>
      )}
    </ButtonContainer>
  );
};

export default React.memo(ChartHeaderButtons);
