import { RawChart } from 'common/types/chart';
import * as React from 'react';

// will subscribe a function to trigger form submission
interface ChartViewContextProps {
  submitRef?: React.MutableRefObject<HTMLButtonElement | undefined>;
  chart?: RawChart;
}

export const ChartViewContext = React.createContext<ChartViewContextProps>({});

export const useChartViewForm = (): ChartViewContextProps => {
  return React.useContext(ChartViewContext);
};
