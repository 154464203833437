import React from 'react';
import styled from '@emotion/styled';
import { LatanaText } from 'common/components/LatanaText';
import Icon from 'common/components/Icon';
import { ICONS } from 'icons/iconsMap';

const TabButtonContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.v2.space(1, 1, 2, 1)};
  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    `
		border-bottom: 2px solid ${theme.v2.colours.gray900};
	`}
`;

const LabelText = styled(LatanaText)`
  margin-left: ${({ theme }) => theme.v2.space(1)};
`;

interface Props {
  label: string;
  onClick?: () => void;
  icon: keyof typeof ICONS | 'latana';
  active?: boolean;
}

const TabButton = ({ label, onClick, active = false, icon }: Props) => {
  return (
    <TabButtonContainer onClick={onClick} active={active}>
      {icon === 'latana' ? <img src={'/logo.svg'} height={16} width={16} /> : <Icon icon={icon} color='gray400' />}

      <LabelText variant='L1' color='gray500'>
        {label}
      </LabelText>
    </TabButtonContainer>
  );
};

export default React.memo(TabButton);
