import styled from '@emotion/styled';
import React from 'react';
import { ICONS } from 'icons/iconsMap';
import { Theme } from '@emotion/react';
import { theme } from 'theme';

const SVGElement = styled.svg`
  display: inline-block;
  vertical-align: middle;
`;

const PathElement = styled.path<{ color?: string }>`
  fill: ${({ color }) => (color ? color : 'initial')};
`;

interface Props {
  icon: keyof typeof ICONS;
  size?: number;
  color?: keyof Theme['v2']['colours'] | 'inherit';
}

const Icon = ({ size = 16, icon, color = 'gray500' }: Props) => {
  const colorValue = theme.colours[color as keyof Theme['v2']['colours']];

  return (
    <SVGElement xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 1024 1024'>
      <PathElement d={ICONS[`${icon as keyof typeof ICONS}`]} color={colorValue} />
    </SVGElement>
  );
};

export default React.memo(Icon);
