import * as React from 'react';
import TableList from 'common/components/TableList';
import { ListItemType } from 'common/types/charts';
import TableListItem from 'common/components/TableList/TableListItem';
import { CheckboxInput } from 'common/components/SelectPopup/styled';
import { LATANA_NAME, MODAL_LIST_HEIGHT } from 'settings/constants';
import { useGetDashboards } from 'common/queries/dashboards';
import { useChartDashboardsContext } from 'common/contexts/ChartsContext';
import { useChartInDashboard } from 'common/hooks/dashboards';
import { useRecoilValue } from 'recoil';
import { permissionsSelector } from 'common/atoms/account';

export const useChartModalContent = () => {
  const isChartInDashboard = useChartInDashboard();
  const { data: dashboardList } = useGetDashboards();
  const { internal: isInternalUser } = useRecoilValue(permissionsSelector);
  const { updateModalState, chartsModalState, selectedChart } = useChartDashboardsContext();

  const selectedChartUuid = selectedChart?.selectedChartId ?? '';

  const tableList = React.useMemo(
    () => (
      <TableList
        inModal
        items={dashboardList}
        type={ListItemType.DASHBOARDS}
        viewportOffset={MODAL_LIST_HEIGHT}
        renderListItem={({ listItemData, ...otherProps }) => {
          const { uuid: dashboardUuid, owner } = listItemData;
          const isItemDisabled = !isInternalUser && owner?.type === LATANA_NAME;
          return (
            <TableListItem {...otherProps} {...listItemData} onChange={() => updateModalState(listItemData)} isDisabled={isItemDisabled}>
              <CheckboxInput
                readOnly
                type='checkbox'
                name={dashboardUuid}
                checked={isChartInDashboard(chartsModalState, dashboardUuid, selectedChartUuid)}
              />
            </TableListItem>
          );
        }}
      />
    ),
    [isChartInDashboard, updateModalState, chartsModalState, selectedChartUuid, dashboardList, isInternalUser],
  );

  return tableList;
};
