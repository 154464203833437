import styled from '@emotion/styled';
import React from 'react';
import { Text } from 'common/styledComponents/typography';
import { CheckmarkIconWhite } from 'icons';

export const CheckboxListItemContainer = styled.div<{ toggleEnabled?: boolean }>`
  width: 100%;
  height: 48px;
  padding: ${({ theme }) => theme.space(1, 2)};
  background-color: ${({ theme }) => theme.colors.white.toString()};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.gray100.toString()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100.toString()};
`;

export const CheckboxCircle = styled.input<{ checked?: boolean }>`
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.white.toString()};
  border-radius: 50%;

  border: 1px solid ${({ theme }) => theme.colors.gray400.toString()};
  outline: none;
  cursor: pointer;

  &:checked {
    transition: background 0.2s ease-in-out;
    background: ${({ theme }) => theme.colors.lightPurple.toString()};
    color: ${({ theme }) => theme.colors.white.toString()};
    border: none;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(${CheckmarkIconWhite});
      background-size: 8px 8px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

interface Props {
  value: string;
  onCheck: (value: string, checked: boolean) => void;
  checked?: boolean;
  label: string;
  className?: string;
}

const CheckboxListItem: React.FC<Props> = ({ value, onCheck, checked, className, label }) => {
  const onCheckCallback = React.useCallback(() => {
    onCheck(value, !checked);
  }, [checked, onCheck, value]);

  return (
    <CheckboxListItemContainer className={className} onClick={onCheckCallback} toggleEnabled={checked}>
      <Text color='sooty' variant='body2' fontWeight='700'>
        {label}
      </Text>

      <CheckboxCircle value={value} checked={checked} type='checkbox' readOnly />
    </CheckboxListItemContainer>
  );
};

export default React.memo(CheckboxListItem);
