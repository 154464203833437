import { ChartType, ChartViews } from 'common/types/chart';
import { Card, CardGridPosition, CardTypes } from 'common/types/dashboard';
import { NEW } from 'settings/constants';

export const generateChartCard = (
  chartId: string,
  order: number,
  size: string,
  gridPosition: CardGridPosition | undefined | null,
  cardId?: string,
  title?: string,
  subtitle?: string,
): Card => {
  return {
    uuid: cardId ? cardId : `${NEW}-${order}`,
    sort_order: order,
    display_size: size,
    grid_position: gridPosition || null,
    components: {
      header: [
        {
          type: 'title',
          text: title || '',
          action: null,
        },
        {
          type: 'subtitle',
          text: subtitle || '',
          action: null,
        },
      ],
      body: [
        {
          type: 'chart',
          uuid: chartId,
        },
      ],
      footer: [],
    },
  };
};

export const getChartIdFromCard = (card: Card): string | null => {
  return (card?.components?.body?.find(component => component.type === 'chart') || {}).uuid || null;
};

export const getCardTitle = (card: Card): string => {
  return (card?.components?.header?.find(component => component.type === 'title') || {}).text || '';
};

export const getDefaultCardSizeByChartType = (chartType?: string): string => {
  if (!chartType) return 'M';

  const sizes = {
    [ChartType.BRAND_PERCEPTION]: 'XL',
    [ChartType.MARKET_FUNNEL]: 'XL',
    [ChartType.FUNNEL]: 'XL',
    [ChartType.HEATMAP]: 'XL',
    [ChartType.GROWTH_PERFORMANCE]: 'XL',
    [ChartType.RANKING]: 'M',
    [ChartType.RADAR]: 'M',
    [ChartType.GAUGE]: 'M',
    [ChartViews.BAR]: 'XL',
    [ChartViews.LINE]: 'XL',
    [ChartViews.HORIZONTAL]: 'M',
  };

  return sizes?.[chartType as keyof typeof sizes] || 'M';
};

export const getCardIdByChartId = (cards: Card[], chartId: string): string | undefined => {
  const foundCard = cards.find(card => card.components.body.some(component => component.type === 'chart' && component.uuid === chartId));

  return foundCard ? foundCard.uuid : undefined;
};

export const getCardGridPosition = (cards: Card[], chartId: string): CardGridPosition | undefined | null => {
  const foundCard = cards.find(card => card.components.body.some(component => component.uuid === chartId));

  return foundCard ? foundCard.grid_position : undefined;
};

export const filterDashboardCardsWithoutText = (cards: Card[]): Card[] => {
  if (!Array.isArray(cards)) {
    return [];
  }

  // Use filter to remove objects with type 'text' in components.body
  const filteredCards = cards.filter(card => {
    if (Array.isArray(card?.components?.body)) {
      // Check if any component in body has type 'text'
      return !card.components.body.some(component => component.type === CardTypes.TEXT);
    }
    return true; // Keep cards with missing or non-array body
  });

  return filteredCards;
};

export const getCardGridWidthBySize = (displaySize?: string): number => {
  switch (displaySize) {
    case 'S':
      return 2;
    case 'M':
      return 3;
    case 'L':
      return 4;
    case 'XL':
      return 6;
    default:
      return 3;
  }
};
