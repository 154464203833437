import React from 'react';
import { useRecoilState } from 'recoil';
import Portal, { PortalPlacement } from 'common/portal';
import Toast from 'common/components/ToastMessage/Toast';
import { ToastWrapper } from 'common/components/ToastMessage/styled';
import { ToastAtom } from 'common/atoms/notifications';
import { useAutoRemove } from 'common/components/ToastMessage/useAutoRemove';

interface Props {
  autoRemove?: boolean;
  duration?: number;
  placement?: PortalPlacement;
}

const ToastMessage = ({ autoRemove = true, duration = 5000, placement = { left: '100', bottom: '60' } }: Props): JSX.Element => {
  const [toasts, setToasts] = useRecoilState(ToastAtom);
  useAutoRemove({ toasts, setToasts, duration, autoRemove });

  const removeToast = (id: string) => {
    setToasts(toasts.filter(t => t.id !== id));
  };

  return (
    <Portal wrapperId='toast-portal' placement={placement}>
      <ToastWrapper>
        {toasts.map(({ message, status, id }) => (
          <Toast key={id} message={message} status={status} onClose={() => removeToast(id)} />
        ))}
      </ToastWrapper>
    </Portal>
  );
};

export default ToastMessage;
