import * as React from 'react';
import { API_URLS } from 'settings/api';
import { FetchOptions } from 'common/types/common';
import { Segment } from 'common/types/segments';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { fetchSelector } from 'common/atoms/common';
import { allSegmentsListAtom } from 'common/atoms/segments';
import { useQueryClient } from '@tanstack/react-query';
import { useRawSegments } from 'common/queries/segments';
import { useCallback } from 'react';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';

export const useUpdateSegmentList = (): {
  updateSegmentList: () => Promise<void>;
} => {
  const { refetch } = useRawSegments();
  const setSegments = useSetRecoilState(allSegmentsListAtom);

  const updateSegmentList = useCallback(async () => {
    const { data: allSegments } = await refetch();
    setSegments(allSegments);
  }, [refetch, setSegments]);

  return React.useMemo(() => ({ updateSegmentList }), [updateSegmentList]);
};

export const useSaveSegment = (): {
  saveSegment: (
    body: Record<string, unknown> | undefined,
    requestParams: { fetchMethod: <T>(url: string, body: FetchOptions['body']) => Promise<T>; url: string },
  ) => Promise<{ success: true; data: Segment | null } | { success: false; error: string; data: Segment | null } | null>;
  pending: boolean;
} => {
  const { updateSegmentList } = useUpdateSegmentList();
  const [pending, setPending] = React.useState(false);
  const queryClient = useQueryClient();

  const saveSegment = useRecoilCallback(
    () =>
      async (
        body: FetchOptions['body'],
        requestParams: { fetchMethod: <T>(url: string, body: FetchOptions['body']) => Promise<T>; url: string },
      ): Promise<{ success: true; data: Segment | null } | { success: false; error: string; data: Segment | null }> => {
        const { fetchMethod, url } = requestParams;

        try {
          await queryClient.invalidateQueries(['segments']);

          setPending(true);
          const newSegment = await fetchMethod<Segment>(url, body);
          await updateSegmentList();
          setPending(false);

          return { success: true, data: newSegment };
        } catch (e: unknown) {
          const { errors } = e as Error & { errors: Record<string, string> };
          const errorMessage = errors?.standard_characteristics?.[0] ?? 'Error saving segment';
          setPending(false);

          return {
            success: false,
            error: errorMessage,
            data: null,
          };
        }
      },
    [updateSegmentList],
  );

  return React.useMemo(() => {
    return {
      saveSegment,
      pending,
    };
  }, [saveSegment, pending]);
};

export const useDeleteSegment = (): {
  deleteSegment: (segmentId: number) => Promise<void>;
  loading: boolean;
} => {
  const { updateSegmentList } = useUpdateSegmentList();
  const queryClient = useQueryClient();
  const rollbar = useRollbar();
  const [loading, setLoading] = React.useState(false);

  const deleteSegment = useRecoilCallback(
    ({ snapshot }) =>
      async (segmentId: number) => {
        setLoading(true);

        // Recoil Snapshots only last for the duration of the callback they are provided to
        const release = snapshot.retain();
        try {
          await queryClient.invalidateQueries(['segments']);
          const { fetchDelete } = await snapshot.getPromise(fetchSelector);
          await fetchDelete(API_URLS.SEGMENT_UPDATE(segmentId));
          await updateSegmentList();
        } catch (e: unknown) {
          rollbar.error('useDeleteSegment error', e as LogArgument);
        } finally {
          release();
          setLoading(false);
        }
      },
    [updateSegmentList],
  );

  return React.useMemo(() => {
    return {
      deleteSegment,
      loading,
    };
  }, [deleteSegment, loading]);
};
