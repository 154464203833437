import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import Modal from 'common/components/Modal';
import { ActionButton, CancelButton } from 'common/styledComponents/buttons';
import { ModalHeader, BannerContainer } from 'common/styledComponents/modals';
import LabeledInput from 'common/components/LabeledInput';
import { theme } from 'common/theme';
import { ChartFormData, ChartType } from 'common/types/chart';

export const ModalIconButtonClose = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  float: right;
`;

export const IconClose = styled.i`
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  flex-basis: 24px;
  height: 24px;
  user-select: none;
  background-image: url(/icons/close.svg);
  width: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.mercury.toString()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury.toString()};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: auto;
  padding: ${theme.space(2, 4, 2, 0)};
`;

interface Props {
  onCancel: () => void;
  onConfirm: (chartData: ChartFormData) => Promise<void>;
  open: boolean;
  confirmButtonTitle?: string;
  chartFormData?: ChartFormData;
}

const ChartModal: React.FC<Props> = ({ open, onCancel, onConfirm, chartFormData }) => {
  const { t } = useTranslation();
  const [chartName, setChartName] = React.useState(chartFormData?.name || '');
  const isMarketSize = chartFormData?.chart_type === ChartType.MARKET_SIZE;
  const duplicateChartData = { ...chartFormData, name: chartName };

  const onChartDuplicateSubmit = () => {
    onConfirm(duplicateChartData as ChartFormData);
    onCancel();
  };

  return (
    <Modal isOpen={open} customWidth={564} handleClose={onCancel} showCloseButton={false}>
      <ModalHeader>
        <Text variant='h5' fontWeight='800' marginBottom={false} marginTop={false}>
          {t(`chart.chartWarningModal.${isMarketSize ? 'cantEdit' : 'duplicateChart'}`)}
        </Text>
        <ModalIconButtonClose onClick={onCancel}>
          <IconClose />
        </ModalIconButtonClose>
      </ModalHeader>
      <Content>
        <BannerContainer>
          <Text color='regularGrey' variant='body1'>
            {t(`chart.chartWarningModal.${isMarketSize ? 'marketSizeMessage' : 'message'}`)}
          </Text>
        </BannerContainer>
        {!isMarketSize && (
          <LabeledInput defaultValue={chartName} onInputChange={e => setChartName(e.target.value)} placeholder='Chart name' />
        )}
      </Content>
      {!isMarketSize && (
        <ButtonWrapper>
          <CancelButton onClick={onCancel} pseudo color='black05' type='button'>
            {t('common.cancel')}
          </CancelButton>
          <ActionButton onClick={onChartDuplicateSubmit} color='lightPurple' type='button' disabled={chartName.length < 1}>
            {t('chart.chartWarningModal.saveAsNewChart')}
          </ActionButton>
        </ButtonWrapper>
      )}
    </Modal>
  );
};

export default ChartModal;
