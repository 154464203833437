import React from 'react';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import { GaugeLegends } from 'common/types/chart';
import { GaugeSubheaderItem } from 'modules/charts/styledComponents';

interface Props {
  legends: GaugeLegends[];
}

const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${({ theme }) => theme.space(3)};
`;

const LegendItems = styled(GaugeSubheaderItem)<{
  children: React.ReactNode;
  key?: string;
  bgColor?: string;
  'data-testid'?: string;
  separator?: boolean;
}>`
  position: relative;
  align-items: center;
  p {
    margin: ${({ theme }) => theme.space(0, 1.5, 0, 2)};
    font-weight: ${({ theme }) => theme.text.variants.body1.fontWeight};
  }
  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 2px;
  }
`;

const GaugeLegend = ({ legends }: Props) => {
  return (
    <LegendContainer data-testid='gauge-legends'>
      {legends.map(({ name, color }) => (
        <LegendItems key={name} bgColor={color} data-testid={`gauge-legends-${name}`}>
          <Text color='black' variant='body1'>
            {name}
          </Text>
        </LegendItems>
      ))}
    </LegendContainer>
  );
};

export default React.memo(GaugeLegend);
