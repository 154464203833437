import styled from '@emotion/styled';
import React from 'react';
import { Theme } from '@emotion/react';
import { HTMLTag } from 'common/types/common';

export type textVariantsTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subheading1' | 'subheading2' | 'body1' | 'body2' | 'span';

const textVariants = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subheading1: 'h6',
  subheading2: 'h6',
  body1: 'p',
  body2: 'p',
  small: 'p',
  span: 'span',
};

interface TypographyProps {
  variant: textVariantsTypes;
  children: React.ReactNode;
  as: string;
  textColor?: keyof Theme['colors'] | 'inherit';
  fontWeight?: string;
  marginTop?: boolean;
  marginBottom?: boolean;
  title?: string;
}

const Typography = styled.div<TypographyProps>`
  ${({ theme, variant = 'body1', fontWeight, marginTop = true, marginBottom = true }) => `
    font-family: ${theme.text.fontFamily};
    line-height: ${theme.text.lineHeight};
    font-weight: ${fontWeight ? fontWeight : variant ? theme.text.variants[variant].fontWeight : theme.text.fontWeight};
    font-size: ${theme.text.variants[variant].fontSize};
    margin: ${theme.text.variants[variant].margin};
    ${!marginTop ? `margin-top: 0;` : ''}
    ${!marginBottom ? `margin-bottom: 0;` : ''}
    `}
  font-style: normal;
  &:empty {
    display: none;
  }
  color: ${({ theme, textColor }) =>
    (textColor ? theme.colors[textColor as keyof Theme['colors']] || textColor : theme.colors.black).toString()};
`;

export interface TextProps {
  variant?: textVariantsTypes;
  children: React.ReactNode;
  color?: keyof Theme['colors'] | 'inherit';
  className?: string;
  fontWeight?: string;
  marginTop?: boolean;
  marginBottom?: boolean;
  title?: string;
}

export const Text: React.FC<TextProps> = ({
  variant,
  children,
  color,
  className,
  fontWeight,
  marginTop = false,
  marginBottom = false,
  title,
}) => {
  const htmlTag = variant ? textVariants[variant] : textVariants.body1;

  return (
    <Typography
      as={htmlTag as HTMLTag}
      variant={variant || (textVariants.body1 as textVariantsTypes)}
      textColor={color}
      className={className}
      fontWeight={fontWeight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      title={title}
    >
      {children}
    </Typography>
  );
};
