import styled from '@emotion/styled';
import React, { useEffect, useMemo } from 'react';
import { coloursPalette } from 'theme/colours';
import { TextVariants } from 'theme/typography';

const AxisLabel = styled.text`
  font-size: ${({ theme }) => theme.v2.text.variants[TextVariants.L2].fontSize};
  font-weight: ${({ theme }) => theme.v2.text.variants[TextVariants.L2].fontWeight};
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  fill: ${coloursPalette.gray500};
`;

interface WrappedTextProps {
  x: number;
  y: number;
  maxWidth: number;
  text: string;
  fontSize?: number;
  setYAxisLabelHeight: React.Dispatch<React.SetStateAction<number>>;
}

const LINE_HEIGHT = 15;

const AxisLabelWrapped: React.FC<WrappedTextProps> = ({ x, y, maxWidth, text, fontSize = 12, setYAxisLabelHeight }) => {
  // Function to wrap text
  const wrapText = (text: string, maxWidth: number, context: CanvasRenderingContext2D) => {
    const words = text?.split(' ') || [];
    const lines: string[] = [];
    let line = '';

    words.forEach(word => {
      const testLine = line + (line ? ' ' : '') + word;
      const { width } = context.measureText(testLine);
      if (width > maxWidth) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    });
    lines.push(line);
    return lines;
  };

  // Create a canvas context to measure text width
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Unable to create canvas context');
  }
  context.font = `${fontSize}px Mulish`;

  const lines = wrapText(text, maxWidth, context);

  const getYPositionForSpan = (index: number) => {
    const fontSizeScale = 1.2;
    return index === 0 ? 0 : fontSize * fontSizeScale;
  };

  const labelHeight = useMemo(() => {
    return lines.length * LINE_HEIGHT;
  }, [lines.length]);

  useEffect(() => {
    setYAxisLabelHeight(labelHeight);
  }, [labelHeight, setYAxisLabelHeight]);

  return (
    <AxisLabel x={x} y={y - labelHeight / 2} fontSize={fontSize} textAnchor='middle' dominantBaseline='text-before-edge'>
      {lines.map((line, index) => (
        <tspan key={`MatrixAxisLabelWrapped-${index}`} x={x} dy={getYPositionForSpan(index)}>
          {line}
        </tspan>
      ))}
    </AxisLabel>
  );
};

export default React.memo(AxisLabelWrapped);
