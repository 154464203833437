import { Theme } from '@emotion/react';
import { theme } from 'common/theme';
import Color from 'common/theme/color';
import {
  ChartHeaderNames,
  GPChartCellsValues,
  GPChartData,
  GPHeader,
  GrowthPerformanceData,
  RawChartData,
  RawChartSchema,
} from 'common/types/chart';
import { Brand, Country, Wave } from 'common/types/common';
import { Segment } from 'common/types/segments';
import { FilterNames } from 'common/types/filters';
import { TFunction } from 'react-i18next';
import { AIDED_BRAND_AWARENESS, DefaultValues, PURCHASE_DRIVERS } from 'settings/constants';
import { roundValue } from 'utils/chart';
import { getRelevantFilters, sortChartValuesByWaveDate } from 'utils/charts';
import { calcAbsoluteValue, calcPercentageValue, chartValueInMsOrKs, chartValueInPercents } from 'utils/helpers';

interface GroupedByIndexData {
  id: number | string;
  values: Array<RawChartData>;
}

interface KpiObject {
  id: string;
  name: string;
}

export type GrowthPerformanceFilter = Brand | Country | Segment | KpiObject;

interface ChartFilters {
  segment: Segment[] | undefined;
  country: Country[];
  brand: Brand[];
  wave: Record<number, Wave>;
  kpi: Record<string, string>;
}

export const getChartData = (
  chartData: RawChartData[] | undefined,
  chartConf: RawChartSchema,
  chartFilters: ChartFilters,
  countryMap: Record<number, Country>,
  isDecimalsEmabled: boolean,
  brandAwarenessData: RawChartData[],
  translator: TFunction<'translation', undefined>,
  isSegmentPopNumbersEnabled: boolean,
): GrowthPerformanceData => {
  const { wave, ...otherFilters } = chartFilters;

  // mainIndexName must be of 'country', 'kpi', 'brand' or 'segment'
  const { first_dimension: mainIndexName } = chartConf.configuration;

  const remapedChartFilters = {
    ...otherFilters,
    kpi: remapChartKpi(otherFilters.kpi),
  } as Record<string, GrowthPerformanceFilter[]>;

  const chartHeaders = getChartHeader(mainIndexName, translator, isSegmentPopNumbersEnabled);

  // remove the first element which is the mainIndexName
  const headersWithoutMainIndex = chartHeaders.slice(1);

  return {
    chartHeaders,
    chartData: mapChartData(
      groupDataByIndexName(chartData as RawChartData[], mainIndexName),
      remapedChartFilters[mainIndexName],
      countryMap,
      wave,
      isDecimalsEmabled,
      brandAwarenessData,
      headersWithoutMainIndex,
    ),
  };
};

const remapChartKpi = (kpi: Record<string, string>): Array<KpiObject> => {
  return Object.entries(kpi).map(([id, name]) => ({ id, name }));
};

const getChartHeader = (
  mainIndex: string,
  translator: TFunction<'translation', undefined>,
  isSegmentPopNumbersEnabled: boolean,
): Array<GPHeader> => {
  const createHeaderCell = (title: string, key: string | null, headerName: ChartHeaderNames | null): GPHeader => ({
    title,
    key,
    headerName,
  });

  const isShowSegmentSize = mainIndex === FilterNames.SEGMENT && isSegmentPopNumbersEnabled;

  const headersList: Array<GPHeader> = [
    createHeaderCell(mainIndex, null, null),
    ...(isShowSegmentSize ? [createHeaderCell(translator('growthPerformance.segmentSize'), null, ChartHeaderNames.SEGMENT_SIZE)] : []),
    createHeaderCell(translator('growthPerformance.currentEstimate'), null, ChartHeaderNames.CURRENT_MONTH),
    createHeaderCell(translator('growthPerformance.fromOnemonthAgo'), null, ChartHeaderNames.ONE_MONTH),
    createHeaderCell(translator('growthPerformance.fromThreeMonthAgo'), null, ChartHeaderNames.THREE_MONTHS),
  ];

  // keys are added to the header cells to be used in chart sorting logic
  const headerCellsWithKeys = headersList.map((header, index) => ({
    ...header,
    key: header.title !== mainIndex ? `value_${index - 1}` : null,
  }));

  return headerCellsWithKeys;
};

export const groupDataByIndexName = (chartData: Array<RawChartData>, indexName: FilterNames): Array<GroupedByIndexData> => {
  const relevantFilters = getRelevantFilters(FilterNames.WAVE);

  if (!chartData.length || !relevantFilters.includes(indexName)) {
    return [];
  }

  const groupedByName = chartData.map((item: RawChartData) => ({
    id: item[indexName],
    values: getIndexValues(chartData, indexName, item),
  }));

  return removeIdenticalDataChunks(groupedByName);
};

const getIndexValues = (chartData: Array<RawChartData>, indexName: FilterNames, currentItem: RawChartData) => {
  return chartData.filter(item => item[indexName] === currentItem[indexName]);
};

export const removeIdenticalDataChunks = (groupedChunks: Array<GroupedByIndexData>): Array<GroupedByIndexData> => {
  // TODO: update react-scripts to 4.0.1.
  // Array<[unknown, unknown]> does't work because react-scripts has a direct dependency on the 2.xx range of
  // @typescript-eslint/parser and @typescript-eslint/eslint-plugin. That's why Array<any> been used.
  const objectToArray = groupedChunks?.map(dataChunk => [dataChunk.id, dataChunk]) as
    | readonly (readonly [unknown, unknown])[]
    | null
    | undefined;
  // since [Map] can't have identical keys, data chunks with the same IDs will be omitted.
  const arrayToMap = new Map(objectToArray);
  return [...arrayToMap.values()] as Array<GroupedByIndexData>;
};

const placeholder = {
  value: DefaultValues.UPCOMING,
  valueAbs: DefaultValues.UPCOMING,
  isDynamicPositive: null,
  valueColor: 'inherit' as keyof Theme['colors'],
};

const getCellColor = (isDynamicPositive: boolean | null): Color | keyof Theme['colors'] | 'inherit' => {
  if (isDynamicPositive === null) return theme.colors.black;
  return isDynamicPositive ? theme.colors.brazilianite : theme.colors.redOrange;
};

export const mapChartData = (
  chartData: Array<GroupedByIndexData>,
  filterData: Array<GrowthPerformanceFilter>,
  countriesMap: Record<number, Country>,
  waveMap: Record<number, Wave>,
  isDecimalsEmabled: boolean,
  brandAwarenessData: RawChartData[],
  headersList: Array<GPHeader>,
): Array<GPChartData> => {
  return chartData.map(({ id, values }) => ({
    id: id.toString(),
    label: getMainIndexName(id, filterData),
    values: getChartCellValues(values, countriesMap, waveMap, isDecimalsEmabled, brandAwarenessData, headersList),
  }));
};

export const getMainIndexName = (indexId: number | string, filterData: GrowthPerformanceFilter[]): string | undefined => {
  return filterData?.find(({ id }) => id === indexId)?.name;
};

export const getChartCellValues = (
  rawChartData: Array<RawChartData>,
  countriesMap: Record<number, Country>,
  waveMap: Record<number, Wave>,
  isDecimalsEmabled: boolean,
  brandAwarenessData: RawChartData[],
  headersList: Array<GPHeader>,
): Array<GPChartCellsValues> => {
  const chartDataSortedByWaveDate = sortChartValuesByWaveDate(rawChartData, waveMap);

  const waveAData = chartDataSortedByWaveDate[0];
  const waveAndPopA = getWaveAndPopulation(waveAData, brandAwarenessData, countriesMap);
  const waveAndPopB = getWaveAndPopulation(chartDataSortedByWaveDate[1], brandAwarenessData, countriesMap);
  const waveAndPopC = getWaveAndPopulation(chartDataSortedByWaveDate[3], brandAwarenessData, countriesMap);

  return (headersList || []).map(({ key, headerName }) => {
    switch (headerName) {
      case ChartHeaderNames.SEGMENT_SIZE:
        return getSegmentSize(waveAData, countriesMap, key as string, headerName);
      case ChartHeaderNames.CURRENT_MONTH:
        return calculateCurrentAwarenessEstimate(waveAndPopA, key as string, isDecimalsEmabled);
      case ChartHeaderNames.ONE_MONTH:
        return calculateWaveDifference(waveAndPopA.mean, waveAndPopB.mean, waveAndPopB.population, key as string);
      case ChartHeaderNames.THREE_MONTHS:
        return calculateWaveDifference(waveAndPopA.mean, waveAndPopC.mean, waveAndPopC.population, key as string);
      default:
        return placeholder;
    }
  });
};

export const getWaveAndPopulation = (
  waveData: RawChartData,
  brandAwarenessData: Array<RawChartData>,
  countriesMap: Record<number, Country>,
): Record<string, number> => {
  const { mean, kpi } = waveData || {};
  let population = calculateSegmentSize(waveData, countriesMap);

  const isNotAidedBrandAwarenessOrPurchaseDrivers =
    brandAwarenessData?.length > 0 && kpi !== AIDED_BRAND_AWARENESS && kpi !== PURCHASE_DRIVERS;

  if (isNotAidedBrandAwarenessOrPurchaseDrivers) {
    const brandAwareness = findBrandAwarenessDataByValues(waveData, brandAwarenessData);
    population = calcAbsoluteValue(brandAwareness?.mean ?? 0, population);
  }

  return {
    mean,
    population,
  };
};

const getSegmentSize = (
  waveData: RawChartData,
  countriesMap: Record<number, Country>,
  key: string,
  headerName: ChartHeaderNames,
): GPChartCellsValues => {
  const population = calculateSegmentSize(waveData, countriesMap);
  return createChartBodyCell(
    chartValueInMsOrKs(population, null),
    chartValueInMsOrKs(population, null),
    null,
    'inherit' as keyof Theme['colors'],
    key,
    population,
    null,
    population,
    headerName,
  );
};

export const calculateSegmentSize = (waveData: RawChartData, countriesMap: Record<number, Country>): number => {
  const countryPopulation = countriesMap?.[waveData?.country]?.population || 0;
  const segmentPopulationWeight = waveData?.segment_population_weight || 1;
  return countryPopulation * segmentPopulationWeight;
};

const calculateCurrentAwarenessEstimate = (
  waveAndPop: Record<string, number>,
  columnKey: string,
  isDecimalsEmabled: boolean,
): GPChartCellsValues => {
  const { mean, population } = waveAndPop;
  const percentageValue = calcPercentageValue(mean, isDecimalsEmabled);
  const absoluteValue = calcAbsoluteValue(mean, population);

  return createChartBodyCell(
    chartValueInPercents(percentageValue, null),
    chartValueInMsOrKs(absoluteValue, null),
    null,
    getCellColor(null) as keyof Theme['colors'] | 'inherit',
    columnKey,
    mean,
    roundValue(percentageValue, isDecimalsEmabled),
    absoluteValue,
  );
};

export const calculateWaveDifference = (
  latestValue: number,
  previousValue: number,
  population: number,
  columnKey: string,
): GPChartCellsValues => {
  if (!previousValue && previousValue !== 0) return placeholder;

  const monthsValuesDiff = latestValue - previousValue;
  const percentageValue = calcPercentageValue(monthsValuesDiff, true);
  const absoluteValue = calcAbsoluteValue(monthsValuesDiff, population);

  const isDynamicPositive = () => (percentageValue === 0 ? null : percentageValue > 0);

  return createChartBodyCell(
    chartValueInPercents(percentageValue, isDynamicPositive()),
    chartValueInMsOrKs(absoluteValue, isDynamicPositive()),
    isDynamicPositive(),
    getCellColor(isDynamicPositive()) as keyof Theme['colors'] | 'inherit',
    columnKey,
    monthsValuesDiff,
    roundValue(percentageValue, true),
    absoluteValue,
  );
};

const createChartBodyCell = (
  value: string,
  valueAbs: string | null,
  isDynamicPositive: boolean | null,
  valueColor: keyof Theme['colors'] | 'inherit',
  columnKey: string,
  rawValue: number,
  roundedValue: number | null,
  populationRaw: number | null = null,
  headerName?: ChartHeaderNames,
): GPChartCellsValues => ({
  value,
  valueAbs,
  isDynamicPositive,
  valueColor,
  [columnKey]: roundedValue,
  [`${columnKey}_raw`]: rawValue,
  [`${columnKey}_populationRaw`]: populationRaw,
  headerName,
});

export const findBrandAwarenessDataByValues = (values: RawChartData, brandAwarenessData: RawChartData[]): RawChartData | null => {
  if (!values || !Array.isArray(brandAwarenessData)) {
    return null;
  }

  return (
    brandAwarenessData.find(
      ({ wave, brand, segment, country }) =>
        wave === values.wave && brand === values.brand && segment === values.segment && country === values.country,
    ) || null
  );
};
