import styled from '@emotion/styled';
import React from 'react';

export const SwitchContainer = styled.div<{ toggleEnabled?: boolean }>`
  width: 36px;
  height: 20px;
  border-radius: 16px;
  background-color: ${({ theme, toggleEnabled }) =>
    toggleEnabled ? theme.colors.lightPurple.toString() : theme.colors.lightGrey.toString()};
  position: relative;
  cursor: pointer;
`;

export const SwitchCircle = styled.div<{ toggleEnabled?: boolean }>`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.white.toString()};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: ${({ toggleEnabled }) => (toggleEnabled ? '16px' : '2px')};
  transition: left 0.15s;
  z-index: 2;
`;

interface Props {
  onToggle: (enabled: boolean) => void;
  enabled?: boolean;
  className?: string;
}

const ToggleSwitch: React.FC<Props> = ({ onToggle, enabled, className }) => {
  const onToggleCallback = React.useCallback(() => {
    onToggle(!enabled);
  }, [enabled, onToggle]);

  return (
    <SwitchContainer className={className} onClick={onToggleCallback} toggleEnabled={enabled}>
      <SwitchCircle toggleEnabled={enabled} />
    </SwitchContainer>
  );
};

export default React.memo(ToggleSwitch);
