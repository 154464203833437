import { uiPreferencesSelector } from 'common/atoms/account';
import Notification from 'common/components/Notification';
import { ChartContainer } from 'common/styledComponents/containers';
import {
  ChartDataPoint,
  ChartNumbersType,
  ChartType,
  ChartViews,
  ConesSortingKeys,
  GeneratedChartData,
  MOEStatus,
  RawChart,
  RawChartData,
  RawChartSchema,
  SortOption,
  SortType,
} from 'common/types/chart';
import { useChartViewForm } from 'common/contexts/ChartViewContext';
import { useUpdateChartSort } from 'common/hooks/charts';
import { AllFiltersData, FilterNames, Filters } from 'common/types/filters';
import { TooltipContext, TooltipContextProps } from 'modules/charts/context/TooltipContext';
import useChart from 'modules/charts/hooks/useChart';
import useFullBar from 'modules/charts/hooks/useFullBar';
import { chartTheme } from 'modules/charts/theme';
import ChartToolbar from 'modules/charts/components/ChartRenderer/ChartToolbar';
import { getHeatmapChartData, getRadarChartData, mapAggregateChartData, prepareChartData } from 'modules/charts/utils/chartData';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { MIN_ITEMS_AMOUNT, SORT_KEY_SEGMENT_SIZE } from 'settings/constants';
import DataTable from 'modules/charts/components/DataTable';
import ChartLegend from 'modules/charts/components/ChartRenderer/ChartLegend';
import { useStudySpecificSegment } from 'common/hooks/segments';
import { brandsColorMapAtom } from 'common/atoms/brands';
import { sortDataByPrimaryBrands } from 'utils/brands';
import { useGetPrimaryBrands } from 'common/hooks/brands';
import { useChartDashboardsContext } from 'common/contexts/ChartsContext';
import {
  ArrowIcon,
  ChartContainerWrapper,
  ChartToolbarWrapper,
  RefreshButton,
  RefreshIcon,
  SegmentSizeContainer,
  TooltipContainer,
  Wrapper,
} from './styled';
import { useLegendResizeObserver } from 'modules/charts/hooks/useLegendResizeObserver';
import { useGetCountryIdMapByStudyId } from 'common/hooks/countries';
import { useGetWavesMap } from 'common/hooks/waves';
import { useGetStudySpecificRestrictions } from 'common/hooks/filters';
import { useAllKpisMap, useKpiTypeGroups } from 'common/hooks/kpis';
import { LatanaText } from 'common/components/LatanaText';
import { chartValueInMsOrKs, isNumberOrString } from 'utils/helpers';
import { useIsChartFiltersOnDashboardEnabled, useIsPopulationNumbersEnabled } from 'common/hooks/features';
import { useTranslation } from 'react-i18next';
import { updateDisabledGroups } from 'utils/charts';
import ChartFiltersBar from './ChartFiltersBar';
import { getNamesForAllFilterIds } from 'utils/filters';
import { exportChartDataToCSV } from 'utils/export';

interface Props {
  studyId: string;
  loadedChartData: RawChart;
  isChartCreate?: boolean;
  isFilterChanged?: boolean;
  hidden?: boolean;
  hideDataTable?: boolean;
  inPage?: boolean;
  isShowToolbar?: boolean;
  registerChartExportCSVFunction?: (chartExportFunc: () => void) => void;
}

const ChartRenderer: React.FC<Props> = ({
  studyId,
  loadedChartData,
  isChartCreate = false,
  isFilterChanged = false,
  hidden = false,
  hideDataTable = false,
  inPage = false,
  isShowToolbar = true,
  registerChartExportCSVFunction,
}) => {
  const primaryBrands = useGetPrimaryBrands();

  const [chartNumbersType, setChartNumbersType] = React.useState<ChartNumbersType>(() => {
    const defaultType =
      loadedChartData.chart?.configuration?.chart_value_representation ||
      (loadedChartData.chart?.chart_type === ChartType.GROWTH_PERFORMANCE ? ChartNumbersType.BOTH : ChartNumbersType.PERCENTAGE);

    return defaultType;
  });

  const [reverseMatrixAxis, setReverseMatrixAxis] = React.useState<boolean>(false);

  const [showMatrixChangeOverTime, setShowMatrixChangeOverTime] = React.useState<boolean>(
    loadedChartData?.chart?.configuration?.change_over_time || false,
  );

  const rawChartSchema = React.useMemo(() => {
    if (reverseMatrixAxis && loadedChartData?.chart?.kpi_option_identifiers) {
      return {
        ...loadedChartData.chart,
        kpi_option_identifiers: [...loadedChartData.chart.kpi_option_identifiers].reverse(),
      };
    }

    if (chartNumbersType !== loadedChartData?.chart?.configuration?.chart_value_representation) {
      return {
        ...loadedChartData.chart,
        configuration: {
          ...loadedChartData?.chart?.configuration,
          chart_value_representation: chartNumbersType,
        },
      };
    }
    return loadedChartData?.chart as RawChartSchema;
  }, [loadedChartData, chartNumbersType, reverseMatrixAxis]);

  const { t } = useTranslation();
  const chartData = loadedChartData?.data;
  const aggregateChartData = loadedChartData?.aggregate_data;
  const brandAwarenessData = loadedChartData.brand_awareness_data;
  const chartFirstDimension = rawChartSchema?.configuration?.first_dimension;
  const currentChartView = rawChartSchema?.current_view;
  const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;

  const containerRef = React.useRef<HTMLDivElement>();
  const [chartView, setChartView] = React.useState<ChartViews | null>(currentChartView || ChartViews.BAR);
  const [moeStatus, setMoeStatus] = React.useState<boolean>(false);
  const [selectedFirstDimension, setSelectedFirstDimension] = React.useState<FilterNames>(chartFirstDimension);
  const isLineView = chartView === ChartViews.LINE;

  const { setInitialModalState } = useChartDashboardsContext();

  React.useEffect(() => {
    setSelectedFirstDimension(chartFirstDimension);
  }, [chartFirstDimension]);

  React.useEffect(() => {
    setMoeStatus(rawChartSchema.margin_of_error_status === MOEStatus.ENABLED);
  }, [rawChartSchema]);

  React.useEffect(() => {
    setInitialModalState();
  }, [setInitialModalState]);

  const { submitRef } = useChartViewForm();
  const [showRightArrow, setShowRightArrow] = React.useState(chartView === ChartViews.BAR);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isChartScrolled, setIsChartScrolled] = React.useState(false);

  const chartHasChartView = rawChartSchema.chart_type === ChartType.COMPARISON || rawChartSchema.chart_type === ChartType.FUNNEL;
  const [chartSorting, setChartSorting] = React.useState<SortOption>(rawChartSchema?.current_sort ?? { key: null, type: SortType.DEFAULT });
  const [marketFunnelSorting, setMarketFunnelSorting] = React.useState<SortOption>({ key: ConesSortingKeys.INDEX, type: SortType.DEFAULT });

  const { updateChartCurrentSort } = useUpdateChartSort();
  const segmentPopulationFeatureEnabled = useIsPopulationNumbersEnabled(studyId);
  const showChartFiltersOnDashboard = useIsChartFiltersOnDashboardEnabled();
  const [disabledGroups, setDisabledGroups] = React.useState<string[]>([]);
  const [disabledChartKeys, setDisabledChartKeys] = React.useState<string[]>([]);
  const [disabledChartDataIds, setDisabledChartDataIds] = React.useState<(string | number)[]>([]);
  const allFilters = useGetStudySpecificRestrictions(studyId);
  const kpiMap = useAllKpisMap(studyId);
  const getCountryIdMap = useGetCountryIdMapByStudyId();
  const segmentList = useStudySpecificSegment(studyId);
  const kpisTypeGroups = useKpiTypeGroups(studyId);
  const waveMap = useGetWavesMap();
  const uiPreferences = useRecoilValue(uiPreferencesSelector);
  const brandsColorMap = useRecoilValue(brandsColorMapAtom);
  const showSegmentPopulation =
    segmentPopulationFeatureEnabled &&
    rawChartSchema.segment_ids.length === 1 &&
    rawChartSchema.country_ids.length === 1 &&
    rawChartSchema.chart_type !== ChartType.MARKET_SIZE &&
    rawChartSchema.chart_type !== ChartType.MARKET_FUNNEL &&
    rawChartSchema.chart_type !== ChartType.GAUGE;

  React.useEffect(() => {
    const newChartView = chartHasChartView ? currentChartView || ChartViews.BAR : null;
    setChartView(newChartView);
  }, [chartHasChartView, setChartView, currentChartView]);

  // will handle switch of dimensions and view and return the appropriate configuration
  const newRawChartSchema: RawChartSchema = React.useMemo(() => {
    let newConfiguration = rawChartSchema?.configuration;

    if (chartFirstDimension !== selectedFirstDimension) {
      const firstDimension = selectedFirstDimension;
      const secondDimension = chartFirstDimension;

      newConfiguration = {
        ...newConfiguration,
        first_dimension: firstDimension,
        second_dimension: secondDimension,
      };
    }

    return {
      ...rawChartSchema,
      current_view: chartHasChartView ? chartView : null,
      configuration: newConfiguration,
    };
  }, [chartView, selectedFirstDimension, rawChartSchema, chartHasChartView, chartFirstDimension]);

  const isRadarChart = React.useMemo(() => newRawChartSchema.chart_type === ChartType.RADAR, [newRawChartSchema]);
  const isHeatmap = React.useMemo(() => newRawChartSchema.chart_type === ChartType.HEATMAP, [newRawChartSchema]);
  const isGauge = React.useMemo(() => newRawChartSchema.chart_type === ChartType.GAUGE, [newRawChartSchema]);
  const isFunnel = React.useMemo(() => newRawChartSchema.chart_type === ChartType.FUNNEL, [newRawChartSchema]);
  const isComparisonChart = React.useMemo(() => newRawChartSchema.chart_type === ChartType.COMPARISON, [newRawChartSchema]);
  const isMarketSize = React.useMemo(() => newRawChartSchema.chart_type === ChartType.MARKET_SIZE, [newRawChartSchema]);
  const isGrowtPerformanceChart = React.useMemo(() => newRawChartSchema.chart_type === ChartType.GROWTH_PERFORMANCE, [newRawChartSchema]);
  const isRankingChart = React.useMemo(() => newRawChartSchema.chart_type === ChartType.RANKING, [newRawChartSchema]);
  const isBrandPerception = React.useMemo(() => newRawChartSchema.chart_type === ChartType.BRAND_PERCEPTION, [newRawChartSchema]);
  const isMarketFunnel = React.useMemo(() => newRawChartSchema.chart_type === ChartType.MARKET_FUNNEL, [newRawChartSchema]);
  const isMatrix = React.useMemo(() => newRawChartSchema.chart_type === ChartType.MATRIX, [newRawChartSchema]);

  const referenceAverageData = mapAggregateChartData(aggregateChartData);

  const rawChartData = React.useMemo(() => {
    if (isRadarChart && loadedChartData?.chart.display_reference_average) {
      return [...(chartData as RawChartData[]), ...referenceAverageData];
    } else {
      return chartData;
    }
  }, [isRadarChart, referenceAverageData, chartData, loadedChartData]);

  const countryMap = React.useMemo(() => {
    return getCountryIdMap(studyId);
  }, [studyId, getCountryIdMap]);

  const mergedChart = React.useMemo(() => {
    return { chart: newRawChartSchema, data: rawChartData };
  }, [newRawChartSchema, rawChartData]);

  const allChartFilters = React.useMemo(() => {
    return {
      ...allFilters,
      kpis: kpiMap,
      segments: segmentList,
    } as AllFiltersData;
  }, [allFilters, kpiMap, segmentList]);

  const generatedChartData = React.useMemo(() => {
    return prepareChartData(
      mergedChart as RawChart,
      allChartFilters,
      waveMap,
      countryMap,
      kpisTypeGroups.absolute as React.ReactText[],
      uiPreferences,
      brandsColorMap,
      primaryBrands,
      aggregateChartData,
      brandAwarenessData,
    );
  }, [
    mergedChart,
    waveMap,
    kpisTypeGroups,
    countryMap,
    uiPreferences,
    aggregateChartData,
    allChartFilters,
    brandsColorMap,
    primaryBrands,
    brandAwarenessData,
  ]);

  const setDisableState = (items: string[], chartData: Array<ChartDataPoint>): Array<ChartDataPoint> => {
    return chartData.map(item => {
      const disabled = items.includes(item.groupId.toString());
      return { ...item, disabled };
    });
  };

  const sortChartData = React.useCallback(
    (chartData: ChartDataPoint[]) => {
      const getChartItemValue = (item: ChartDataPoint, key: string, showPopNumbers: boolean): number => {
        if (key === SORT_KEY_SEGMENT_SIZE) {
          return item.value_0_segmentSize;
        }
        return showPopNumbers ? item[`${key}_populationNumberRaw` as 'value_0'] : item[`${key}` as 'value_0'];
      };

      const compareValues = (valueA: number, valueB: number, sortType: SortType | null): number => {
        if (sortType === SortType.DEFAULT) return 0;
        return sortType === SortType.ASC ? valueA - valueB : valueB - valueA;
      };

      return chartData.sort((a, b) => {
        const valueA = getChartItemValue(a, chartSorting.key as string, showPopulationNumbers);
        const valueB = getChartItemValue(b, chartSorting.key as string, showPopulationNumbers);
        return compareValues(valueA, valueB, chartSorting.type);
      });
    },
    [chartSorting, showPopulationNumbers],
  );

  const finalChartData: GeneratedChartData = React.useMemo(() => {
    let data = generatedChartData.data.slice();
    let keys = generatedChartData.keys.slice();

    if (disabledGroups.length) {
      data = setDisableState(disabledGroups, generatedChartData.data);
    }

    if (disabledChartKeys.length) {
      keys = generatedChartData.keys.filter(item => !disabledChartKeys.includes(item));
    }

    if (chartSorting.key && chartData && chartSorting.type && chartSorting.type !== SortType.DEFAULT) {
      data = sortChartData(data);
    }

    return { ...generatedChartData, data, keys };
  }, [generatedChartData, chartData, chartSorting.key, chartSorting.type, disabledGroups, sortChartData, disabledChartKeys]);

  const comparisonChartData = React.useMemo(() => {
    const comparisonDataPoints = [...finalChartData.data];
    // Only sort if grouped by brands
    if (selectedFirstDimension === FilterNames.BRAND && (!chartSorting.key || chartSorting.type === SortType.DEFAULT)) {
      comparisonDataPoints.sort((a, b) =>
        sortDataByPrimaryBrands(
          { id: a.groupId.toString(), name: a.groupLabel },
          { id: b.groupId.toString(), name: b.groupLabel },
          primaryBrands || [],
        ),
      );
    }
    return { ...finalChartData, data: comparisonDataPoints };
  }, [finalChartData, chartSorting, primaryBrands, selectedFirstDimension]);

  const heatmapChartData = React.useMemo(() => {
    return isHeatmap
      ? getHeatmapChartData(mergedChart as RawChart, allChartFilters, uiPreferences.decimalsEnabled, countryMap, aggregateChartData)
      : null;
  }, [mergedChart, allChartFilters, aggregateChartData, uiPreferences.decimalsEnabled, isHeatmap, countryMap]);

  const radarChartData = React.useMemo(() => {
    const filters = { ...allFilters, segmentList } as Filters;
    return getRadarChartData(finalChartData, filters, kpiMap);
  }, [finalChartData, allFilters, kpiMap, segmentList]);

  const toggleGroupCallback = React.useCallback(
    <T extends string>(itemKey: T) => {
      const updatedDisabledItems = updateDisabledGroups(disabledGroups, itemKey);
      const updatedItemsCount = updatedDisabledItems.length;

      const exceedsChartData = finalChartData && updatedItemsCount >= finalChartData?.data.length;
      if (exceedsChartData) return;

      // Check if radar chart requirements are met
      const minRadarReqsArentMet = isRadarChart && radarChartData && radarChartData.length - updatedItemsCount >= MIN_ITEMS_AMOUNT;
      if (minRadarReqsArentMet) return;

      setDisabledGroups(updatedDisabledItems);
    },
    [disabledGroups, finalChartData, isRadarChart, radarChartData],
  );

  const toggleChartKeyCallback = React.useCallback(
    <T extends string>(itemKey: T) => {
      let updatedDisabledKeys = [] as Array<string>;

      const getDisabledIds = (updatedDisabledKeys: Array<string>, chartData: ChartDataPoint[]): Array<string | number> => {
        return updatedDisabledKeys.reduce<(string | number)[]>((acc, key) => {
          const valueId = chartData?.[0][`${key}_secondDimension` as 'value_0_secondDimension'];
          if (isNumberOrString(valueId)) {
            acc.push(valueId);
          }
          return acc;
        }, []);
      };

      updatedDisabledKeys = updateDisabledGroups(disabledChartKeys, itemKey);

      if (generatedChartData && updatedDisabledKeys.length >= generatedChartData?.keys.length) {
        updatedDisabledKeys = [];
      }

      setDisabledChartKeys(updatedDisabledKeys);

      // Make an array of disabled ids instead of array of disabled keys
      const disabledIds = getDisabledIds(updatedDisabledKeys, generatedChartData.data);
      setDisabledChartDataIds(disabledIds);
    },
    [disabledChartKeys, generatedChartData],
  );

  const setSortCallback = React.useCallback(
    (key: string) => {
      let sortType = SortType.DEFAULT;

      switch (chartSorting.type) {
        case SortType.DEFAULT:
          sortType = SortType.DESC;
          break;
        case SortType.DESC:
          sortType = SortType.ASC;
          break;

        default:
          sortType = SortType.DEFAULT;
      }

      setChartSorting({ key, type: sortType });
      updateChartCurrentSort(newRawChartSchema, { key: key, type: sortType });
    },
    [chartSorting.type, newRawChartSchema, updateChartCurrentSort],
  );

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleScroll = React.useCallback(() => {
    if (containerRef.current) {
      const showWindowWidth = containerRef.current.scrollWidth - containerRef.current.clientWidth;
      const movedPoint =
        containerRef.current.scrollWidth -
        (containerRef.current.scrollLeft + (containerRef.current.scrollWidth - containerRef.current.clientWidth));
      if (containerRef.current.scrollLeft + 1 < showWindowWidth) {
        setShowLeftArrow(false);
        setShowRightArrow(true);
      } else {
        if (containerRef.current.scrollLeft > showWindowWidth) {
          setShowRightArrow(true);
        }
        if (
          (containerRef.current.clientWidth - movedPoint > showWindowWidth - 5 && containerRef.current.clientWidth >= showWindowWidth) ||
          movedPoint < 0
        ) {
          setShowRightArrow(false);
          setShowLeftArrow(true);
        }
      }
    }
  }, [containerRef]);

  const handleNav = React.useCallback(
    (direction: string) => {
      if (containerRef.current) {
        const showWindowWidth = containerRef.current.scrollWidth - containerRef.current.clientWidth;
        if (direction === 'left') {
          containerRef.current.scrollLeft -= showWindowWidth;
        } else {
          containerRef.current.scrollLeft += showWindowWidth;
        }
        setIsChartScrolled(true);
      }
    },
    [containerRef],
  );

  const onUpdate = () => {
    const buttonRef = submitRef?.current;
    if (buttonRef) {
      buttonRef.click();
    }
  };

  const getCountryPopulation = React.useMemo(() => {
    return chartData && chartData?.[0] ? countryMap?.[chartData[0]?.country as number]?.population : 0;
  }, [chartData, countryMap]);

  const renderedChart = useChart(
    newRawChartSchema,
    isFilterChanged,
    containerRef,
    finalChartData,
    width,
    inPage,
    heatmapChartData,
    chartTheme,
    radarChartData,
    chartData,
    allFilters.brands,
    getCountryPopulation,
    moeStatus,
    comparisonChartData,
    aggregateChartData,
    loadedChartData?.brand_awareness_data || [],
    chartNumbersType,
    disabledChartDataIds,
    marketFunnelSorting,
    showMatrixChangeOverTime,
  );

  const exportChartCSV = React.useCallback(() => {
    exportChartDataToCSV(finalChartData, loadedChartData.chart.name, segmentPopulationFeatureEnabled);
  }, [finalChartData, loadedChartData.chart.name, segmentPopulationFeatureEnabled]);

  // Register the export chart csv function
  React.useEffect(() => {
    if (registerChartExportCSVFunction) {
      registerChartExportCSVFunction(exportChartCSV); // Pass the function back to Parent
    }
  }, [exportChartCSV, registerChartExportCSVFunction]);

  const isChartHasNoData = !finalChartData.data.length;

  const notification = React.useMemo(() => {
    return isChartHasNoData && !isChartCreate && isFilterChanged ? 'empty' : undefined;
  }, [isFilterChanged, isChartHasNoData, isChartCreate]);

  const barCount = React.useMemo(() => {
    const firstElement = finalChartData.data[0];

    return finalChartData.data.length && newRawChartSchema.chart_type === ChartType.COMPARISON
      ? firstElement.count * finalChartData.data.length
      : 0;
  }, [finalChartData, newRawChartSchema]);

  const showFullBar = useFullBar(width, barCount, document);

  const displayDataTable =
    !isChartHasNoData &&
    !isHeatmap &&
    !isGauge &&
    !isMarketSize &&
    !hideDataTable &&
    !isGrowtPerformanceChart &&
    !isRankingChart &&
    !isBrandPerception &&
    !isMarketFunnel;

  const tooltipWrapperRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>; // container for ReactDOM.Portal
  const tooltipGroupRef = React.useRef<number | undefined>(); // current hovered bar group
  const tooltipScrollOffsetRef = React.useRef<number>(0); // chart scolled offset to adjust tooltip X position
  const tooltipContext: TooltipContextProps = React.useMemo(() => {
    return {
      container: null,
      tooltipGroupId: tooltipGroupRef,
      tooltipScrollOffset: tooltipScrollOffsetRef,
    };
  }, []);

  React.useEffect(() => {
    tooltipContext.container = tooltipWrapperRef.current;
  }, [tooltipContext, tooltipWrapperRef]);

  const handleChartScroll = (e: React.TouchEvent<HTMLDivElement>) => {
    tooltipScrollOffsetRef.current = e.currentTarget.scrollLeft;
  };

  const toggleAxisSwitch = React.useCallback(() => {
    setReverseMatrixAxis(!reverseMatrixAxis);
  }, [setReverseMatrixAxis, reverseMatrixAxis]);

  const NavigationArrow: React.FC<{ previous: boolean; chartView: string; direction: string }> = ({ previous, chartView, direction }) => {
    return (
      <>
        <ArrowIcon onClick={() => handleNav(direction)} chartView={chartView} previous={previous} />
      </>
    );
  };

  const segmentPopulationCount = React.useMemo(() => {
    if (showSegmentPopulation) {
      const weight = (rawChartData?.[0] as RawChartData)?.segment_population_weight || 1;
      const countryId = rawChartData?.[0]?.country;
      const countryPopulation = countryMap[countryId]?.population || 0;

      return chartValueInMsOrKs(countryPopulation * weight, null);
    } else {
      return null;
    }
  }, [countryMap, rawChartData, showSegmentPopulation]);

  const selectedSingleSegment = React.useMemo(() => {
    if (showSegmentPopulation) {
      return segmentList?.find(x => x.id === rawChartSchema?.segment_ids[0] || 0);
    } else {
      return null;
    }
  }, [showSegmentPopulation, segmentList, rawChartSchema]);

  const renderSegmentSizeLabel = React.useMemo(() => {
    return (
      <SegmentSizeContainer>
        {showSegmentPopulation && selectedSingleSegment && (
          <>
            <LatanaText variant='L1' color='gray500'>
              {selectedSingleSegment.general_population
                ? t('chart.adultsPopulation')
                : `${t('chart.segment')} ${selectedSingleSegment.name} ${t('chart.population')}`}
              : &nbsp;
            </LatanaText>
            <LatanaText variant='L1' color='gray900' htmlTag='span'>
              {segmentPopulationCount}
            </LatanaText>
          </>
        )}
      </SegmentSizeContainer>
    );
  }, [segmentPopulationCount, selectedSingleSegment, showSegmentPopulation, t]);

  const chartHasLegend = (isComparisonChart || isFunnel || isMatrix) && generatedChartData?.keys.length > 1;

  const expandChartContent = isHeatmap || isGrowtPerformanceChart;
  const isScrollableContent = inPage && (isGrowtPerformanceChart || isHeatmap || isRankingChart || isBrandPerception);

  const hideLegend = useLegendResizeObserver(`Legend-${newRawChartSchema.uuid}`);
  const showSegmentSizeLabel = !inPage && !isMatrix;

  return (
    <TooltipContext.Provider value={tooltipContext}>
      {isShowToolbar && (
        <ChartToolbarWrapper aria-hidden={hidden} data-inpage={inPage} data-right={!showSegmentSizeLabel}>
          {showSegmentSizeLabel && renderSegmentSizeLabel}
          <ChartToolbar
            inPage={inPage}
            ariaHidden={hidden}
            chartView={chartView}
            moeStatus={moeStatus}
            setChartView={setChartView}
            rawChart={newRawChartSchema}
            finalChartData={finalChartData}
            chartNumbersType={chartNumbersType}
            selectedFirstDimension={selectedFirstDimension}
            chartSortingProp={{ chartSorting, setChartSorting }}
            setSelectedFirstDimension={setSelectedFirstDimension}
            setChartNumbersType={setChartNumbersType}
            isChartScrolled={isChartScrolled}
            setShowRightArrow={setShowRightArrow}
            setMoeStatus={setMoeStatus}
            toggleAxisSwitch={toggleAxisSwitch}
            marketFunnelSorting={marketFunnelSorting}
            onMarketFunnelSort={setMarketFunnelSorting as <T>(sortingType: T) => void}
            showMatrixTrendOverTime={showMatrixChangeOverTime}
            setShowMatrixTrendOverTime={setShowMatrixChangeOverTime}
          />
        </ChartToolbarWrapper>
      )}
      {inPage && showChartFiltersOnDashboard && <ChartFiltersBar filters={getNamesForAllFilterIds(newRawChartSchema, allChartFilters)} />}
      <Wrapper aria-hidden={hidden} data-inside-page={inPage}>
        <TooltipContainer ref={tooltipWrapperRef} />
        <ChartContainerWrapper onScroll={handleScroll} data-inside-page={inPage} data-expand-content={expandChartContent}>
          {showLeftArrow && !showFullBar && chartView && <NavigationArrow previous={true} chartView={chartView} direction='left' />}
          {showRightArrow && !showFullBar && chartView && <NavigationArrow previous={false} chartView={chartView} direction='right' />}
          <ChartContainer
            onScroll={handleChartScroll}
            data-dirty={isFilterChanged}
            chartView={chartView}
            isHeatmap={isHeatmap}
            isGrowtPerformanceChart={isGrowtPerformanceChart}
            isRadarChart={isRadarChart}
            isComparison={isComparisonChart}
            isMarketFunnel={isMarketFunnel}
            ref={containerRef as React.MutableRefObject<HTMLDivElement>}
            data-has-scroll={!showFullBar}
            id='chart-container'
            data-inside-page={inPage}
            data-scrollable-content={isScrollableContent}
            inPage={inPage}
            data-has-legend={chartHasLegend && !hideLegend}
          >
            {renderedChart}
            {notification ? <Notification type={notification} /> : null}
            {isFilterChanged && (
              <RefreshButton onClick={onUpdate} bubble color='white' shadow type='submit'>
                <RefreshIcon />
              </RefreshButton>
            )}
          </ChartContainer>
          {chartHasLegend && (
            <ChartLegend
              chartView={chartView}
              id={rawChartSchema.uuid}
              hide={inPage && hideLegend}
              chartData={generatedChartData}
              disabledChartKeys={isLineView ? disabledGroups : disabledChartKeys}
              onToggleChartKey={isLineView ? toggleGroupCallback : toggleChartKeyCallback}
            />
          )}
        </ChartContainerWrapper>
        {displayDataTable && (
          <DataTable
            studyId={studyId}
            chartView={chartView}
            generatedChartData={isComparisonChart ? comparisonChartData : finalChartData}
            setSortHandle={setSortCallback}
            sort={chartSorting}
            toggleItem={toggleGroupCallback}
            moeStatus={moeStatus}
            chartNumbersType={chartNumbersType}
            disableClickActions={isMatrix}
          />
        )}
      </Wrapper>
    </TooltipContext.Provider>
  );
};

export default React.memo(ChartRenderer);
