import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { useRecoilValue } from 'recoil';
import { BrandGroups } from 'common/types/common';
import { useRollbar } from '@rollbar/react';
import { processError } from 'utils/helpers';

export const useGetBrandGroups = (): UseQueryResult<BrandGroups[], unknown> => {
  const { fetchGet } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();
  return useQuery(
    ['brandGroups'],
    async () => {
      try {
        return await fetchGet<BrandGroups[]>(API_URLS.BRAND_GROUPS);
      } catch (e) {
        processError(e, rollbar.error, 'useGetBrandGroups error');
      }
    },
    {
      staleTime: Infinity,
      placeholderData: [],
    },
  );
};
