import React from 'react';
import LineChart from 'modules/charts/components/LineChart';
import BarChart from 'modules/charts/components/BarChart';
import { ChartDataPoint, RawChartSchema, ChartType, ChartViews, ChartLayout, ChartNumbersType } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';

interface Props {
  data: ChartDataPoint[];
  keys: string[];
  indexBy: FilterNames;
  chartType: ChartType;
  secondDimension: FilterNames;
  containerRef: React.MutableRefObject<HTMLDivElement | undefined>;
  chart: RawChartSchema;
  groups: number;
  width: number;
  maxValue: number;
  isDirty: boolean;
  inPage?: boolean;
  maxMoE?: number;
  moeStatus: boolean;
  maxSegmentPopulationSize: number;
  chartNumbersType: ChartNumbersType;
}

const Comparison: React.FC<Props> = ({
  data,
  keys,
  indexBy,
  chartType,
  containerRef,
  groups,
  width,
  secondDimension,
  maxValue,
  chart,
  isDirty,
  inPage = false,
  maxMoE,
  moeStatus,
  maxSegmentPopulationSize,
  chartNumbersType,
}) => {
  const getChartLayout = React.useCallback((currentView: string) => {
    return currentView == ChartViews.BAR ? ChartLayout.VERTICAL : ChartLayout.HORIZONTAL;
  }, []);

  const comparisonChart = React.useMemo(() => {
    switch (chart.current_view) {
      case ChartViews.BAR:
      case ChartViews.HORIZONTAL: {
        return (
          <BarChart
            data={data}
            keys={keys}
            width={width}
            maxMoE={maxMoE}
            inPage={inPage}
            groups={groups}
            isDirty={isDirty}
            indexBy={indexBy}
            maxValue={maxValue}
            chartType={chartType}
            moeStatus={moeStatus}
            secondDimension={secondDimension}
            chartNumbersType={chartNumbersType}
            showAverage={chart.display_reference_average}
            chartLayout={getChartLayout(chart.current_view)}
            maxSegmentPopulationSize={maxSegmentPopulationSize}
          />
        );
      }
      case ChartViews.LINE:
        return (
          <LineChart
            keys={keys}
            data={data}
            chart={chart}
            moeStatus={moeStatus}
            containerRef={containerRef}
            chartNumbersType={chartNumbersType}
            maxSegmentPopulationSize={maxSegmentPopulationSize}
          />
        );
      default:
        return null;
    }
  }, [
    chart,
    chartType,
    containerRef,
    data,
    groups,
    indexBy,
    inPage,
    isDirty,
    keys,
    maxMoE,
    maxValue,
    moeStatus,
    secondDimension,
    width,
    maxSegmentPopulationSize,
    chartNumbersType,
    getChartLayout,
  ]);

  return <React.Fragment>{comparisonChart}</React.Fragment>;
};

export default Comparison;
