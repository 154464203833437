import React from 'react';
import { useGetDashboards } from 'common/queries/dashboards';
import { LATANA_NAME } from 'settings/constants';
import { useRecoilValue } from 'recoil';
import { permissionsSelector } from 'common/atoms/account';

export const useUserPermissions = (): boolean => {
  const { data: dashboardList } = useGetDashboards();
  const { internal: isInternalUser, chart_edit: isEditor } = useRecoilValue(permissionsSelector);

  const hasDashboards = React.useMemo(() => !!dashboardList?.length, [dashboardList]);

  const hasCustomerDashboards = React.useMemo(() => {
    return !!dashboardList?.some(({ owner }) => owner?.type !== LATANA_NAME);
  }, [dashboardList]);

  const hasCustomerAvailableDashboards = React.useMemo(
    () => hasCustomerDashboards && hasDashboards,
    [hasCustomerDashboards, hasDashboards],
  );

  return isEditor && (isInternalUser ? hasDashboards : hasCustomerAvailableDashboards);
};
