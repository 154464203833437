import * as React from 'react';

interface SegmentsModalContextProps {
  toggleSegmentsModal: (open: boolean, studyId?: string) => void;
  open: boolean;
  toggleNewSegment: (open: boolean) => void;
  isNewSegment: boolean;
}

export const SegmentsModalContext = React.createContext<SegmentsModalContextProps>({
  toggleSegmentsModal: () => null,
  open: false,
  toggleNewSegment: () => null,
  isNewSegment: false,
});

export const useSegmentsModal = (): SegmentsModalContextProps => {
  return React.useContext(SegmentsModalContext);
};
