import React from 'react';
import styled from '@emotion/styled';
import { LatanaText } from 'common/components/LatanaText';
import PillCheckbox from 'common/components/PillCheckbox';
import { CharacteristicOption, FormStates } from 'common/types/segments';
import { useTranslation } from 'react-i18next';

const CharacteristicsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.v2.space(5)};
  > div {
    margin-bottom: ${({ theme }) => theme.v2.space(2)};
  }
`;

const CharacteristicOptionsContainer = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.v2.space(-0.5)};
`;

const SegmentCharacteristicsSection: React.FC<{
  register: void;
  formState: FormStates;
  isAllSelected: boolean;
  characteristicName: string;
  onSegmentOptionChange: (
    field: string,
    characteristicValues: {
      name: string;
      value: string | [];
    },
  ) => void;
  isLatanaSegmentsTab: boolean;
  characteristicFieldName: string;
  characteristicOptions: CharacteristicOption[];
  fieldWatch: (field: string) => string | never;
}> = ({
  characteristicName,
  characteristicOptions,
  onSegmentOptionChange,
  characteristicFieldName,
  fieldWatch,
  isAllSelected,
  isLatanaSegmentsTab,
  formState,
}) => {
  const isInViewMode = formState === FormStates.VIEW;
  const isOptionsDisabled = isLatanaSegmentsTab || isInViewMode;
  const characteristicNameLowercase = characteristicName.toLowerCase();
  const { t } = useTranslation();

  const handleAllOptionChange = React.useCallback(() => {
    onSegmentOptionChange(characteristicFieldName, { name: characteristicNameLowercase, value: [] });
  }, [onSegmentOptionChange, characteristicFieldName, characteristicNameLowercase]);

  const handleOptionChange = React.useCallback(
    (key: string) => {
      onSegmentOptionChange(characteristicFieldName, { name: characteristicNameLowercase, value: key });
    },
    [onSegmentOptionChange, characteristicFieldName, characteristicNameLowercase],
  );

  const renderCheckboxes = React.useMemo(
    () =>
      characteristicOptions.map(({ key, name }, i) => {
        return (
          <PillCheckbox
            key={key}
            name={key}
            value={key}
            label={name}
            opaqueWhenDisabled
            disabled={isOptionsDisabled}
            onChange={() => handleOptionChange(key)}
            dataTestid={`${characteristicName}-option-${i}`}
            selected={!!fieldWatch(characteristicFieldName)?.includes(key)}
            disabledMessage={isOptionsDisabled && !isLatanaSegmentsTab ? t('segmentBuilder.buttonDisabled') : undefined}
          />
        );
      }),
    [
      characteristicOptions,
      characteristicFieldName,
      fieldWatch,
      handleOptionChange,
      isOptionsDisabled,
      characteristicName,
      t,
      isLatanaSegmentsTab,
    ],
  );

  return (
    <CharacteristicsContainer>
      <LatanaText color='gray900' variant='T2'>
        {characteristicName}
      </LatanaText>
      <CharacteristicOptionsContainer>
        <PillCheckbox
          name='all-button'
          opaqueWhenDisabled
          label='All responses'
          selected={isAllSelected}
          disabled={isOptionsDisabled}
          onChange={handleAllOptionChange}
          disabledMessage={isOptionsDisabled && !isLatanaSegmentsTab ? t('segmentBuilder.buttonDisabled') : undefined}
        />
        {renderCheckboxes}
      </CharacteristicOptionsContainer>
    </CharacteristicsContainer>
  );
};

export default SegmentCharacteristicsSection;
