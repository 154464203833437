import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Icon from 'common/components/Icon';
import { LatanaText } from 'common/components/LatanaText';
import { Segment } from 'common/types/segments';
import { InlineTextField } from 'common/components/Form/InlineTextField';
import { LabelButtonWithIcon } from 'common/styledComponents/buttons';
import { formatDate } from 'utils/helpers';
import { DataTestId } from 'settings/constants';
import { useForm } from 'react-hook-form';

const SegmentViewHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v2.space(5)};
`;

const WarningContainer = styled.div`
  display: flex;
  svg {
    margin-right: ${({ theme }) => theme.v2.space(1)};
  }
`;

const SegmentViewHeaderActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SegmentMetaInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserSegmentInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.v2.space(1.5)};
`;

interface SegmentViewHeaderProps {
  segment: Segment | null;
  isLatanaSegmentsTab: boolean;
  handleSegmentDelete: () => void;
  handleSegmentUpdate: () => void;
  handleSegmentEdit: () => void;
  handleCancelEdit: () => void;
  handleSegmentNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isInViewMode: boolean;
  isEditor: boolean;
  formRegister: ReturnType<typeof useForm>['register'];
}

const SegmentViewHeader: React.FC<SegmentViewHeaderProps> = ({
  segment,
  isLatanaSegmentsTab,
  handleSegmentDelete,
  handleSegmentUpdate,
  handleSegmentEdit,
  handleCancelEdit,
  isInViewMode,
  isEditor,
  handleSegmentNameChange,
  formRegister,
}): JSX.Element => {
  const { t } = useTranslation();

  const isNameFieldDisabled = isLatanaSegmentsTab || isInViewMode || !isEditor;

  return (
    <SegmentViewHeaderContainer>
      <InlineTextField
        name='name'
        variant='h4'
        autoComplete='off'
        className='segments-input'
        disabled={isNameFieldDisabled}
        ref={formRegister({ required: true })}
        onChange={handleSegmentNameChange}
        data-testid={DataTestId.SEGMENT_NAME}
      />
      {isLatanaSegmentsTab ? (
        <WarningContainer>
          <Icon icon='info' color='gray500' />
          <LatanaText color='gray500' variant='B3' fontWeight='500'>
            {t('segmentBuilder.genPopTooltip.description')}
          </LatanaText>
        </WarningContainer>
      ) : (
        <SegmentViewHeaderActionBar>
          <SegmentMetaInfo>
            <UserSegmentInfo>
              {isInViewMode && segment?.last_edited_by_name && (
                <LabelButtonWithIcon icon='avatar' label={segment?.last_edited_by_name || ''} readOnly />
              )}
              {isInViewMode && segment?.updated_at && (
                <LatanaText color='gray500' variant='L1'>
                  {segment.updated_at && formatDate(segment.updated_at, true)}
                </LatanaText>
              )}
            </UserSegmentInfo>
            {isInViewMode ? (
              <LabelButtonWithIcon
                icon='pencil'
                disabled={false}
                label={t('segmentBuilder.editSegment')}
                data-testid={DataTestId.EDIT_SEGMENT_BUTTON}
                onClick={handleSegmentEdit}
              />
            ) : (
              <LabelButtonWithIcon
                disabled={false}
                icon='pencil-cross'
                onClick={handleCancelEdit}
                label={t('segmentBuilder.cancelSegment')}
              />
            )}
            {isInViewMode ? (
              <LabelButtonWithIcon
                icon='trash-can'
                disabled={false}
                onClick={handleSegmentDelete}
                label={t('segmentBuilder.deleteSegment')}
                data-testid={DataTestId.DELETE_SEGMENT_BUTTON}
              />
            ) : (
              <LabelButtonWithIcon
                icon='save'
                disabled={false}
                onClick={handleSegmentUpdate}
                label={t('segmentBuilder.saveSegment')}
                data-testid={DataTestId.SAVE_SEGMENT_BUTTON}
              />
            )}
          </SegmentMetaInfo>
        </SegmentViewHeaderActionBar>
      )}
    </SegmentViewHeaderContainer>
  );
};

export default React.memo(SegmentViewHeader);
