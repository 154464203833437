import html2canvas from 'html2canvas';
import { toCanvas } from 'html-to-image';
import { allSettledPromise } from 'utils/helpers';
import { ChartType } from 'common/types/chart';

const CHART_WIDTH = 1248;
const EXPORTED_CHART_WIDTH = 2560;
const DEFAULT_SCALE = 2;
const CHART_FONT_URL = 'https://fonts.googleapis.com/css2?family=Mulish:wght@400;700;800&display=swap';
const MIN_SCALE_TO_FIT = 0.6;
const LARGE_DISPLAY_FACTOR = 1;
const CLONED_EL_EXTEND_HEIGHT = 50;

// Receive original element as well, because getComputedStyle wont work on cloned element
const inlineStyles = (target: HTMLElement, origElement: HTMLElement) => {
  // Receive cloned and original element, and copy css to cloned element from orig
  const selfCopyCss = (elt: HTMLElement, orig: HTMLElement) => {
    const computed = window.getComputedStyle(orig);

    const css = {} as Record<string, string>;
    for (let i = 0; i < computed.length; i++) {
      css[computed[i]] = computed.getPropertyValue(computed[i]);
    }

    for (const key in css) {
      (elt.style as any)[key] = css[key];
    }

    return css;
  };

  if (target && origElement) {
    selfCopyCss(target, origElement);
    const targetNestedElements = target.querySelectorAll('*'); // All cloned nested elements

    // Go trough each element in svg and copy css to cloned target element
    origElement.querySelectorAll('*').forEach((elt: Element, index) => {
      selfCopyCss(targetNestedElements[index] as HTMLElement, elt as HTMLElement);
    });
  }
};

const copyToCanvas = (target: HTMLElement, boxSize: DOMRect): Promise<HTMLCanvasElement> => {
  const svgSize = boxSize || target.getBoundingClientRect();
  const svgData = new XMLSerializer().serializeToString(target);

  const canvas = document.createElement('canvas');
  canvas.width = CHART_WIDTH * DEFAULT_SCALE;
  canvas.height = determineNewHeight(svgSize.height, svgSize.width, CHART_WIDTH * DEFAULT_SCALE);
  canvas.style.width = `${CHART_WIDTH * DEFAULT_SCALE}px`;
  canvas.style.height = `${determineNewHeight(svgSize.height, svgSize.width, CHART_WIDTH * DEFAULT_SCALE)}px`;

  const ctxt = canvas.getContext('2d');

  const img = document.createElement('img');

  img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData))));

  return new Promise(resolve => {
    img.onload = () => {
      ctxt &&
        ctxt.drawImage(
          img,
          0,
          0,
          img.width,
          img.height, // source rectangle
          0,
          0,
          canvas.width,
          canvas.height,
        );
      resolve(canvas);
    };
  });
};

const downloadImage = (file: string, name: string, format: string) => {
  const link = document.createElement('a');
  link.download = `${name}.${format}`;
  link.href = file;
  link.click();
};

/* This function is taking a chart type, and based on that it should give proper function for exporting that chart type */
export const elementToImage = async (chartType: string): Promise<void | HTMLCanvasElement> => {
  const isHTMLChart = [ChartType.GROWTH_PERFORMANCE, ChartType.RANKING, ChartType.BRAND_PERCEPTION].includes(chartType as ChartType); //Chart is consisted only of HTML elements, no svgs
  const isGaugeChart = [ChartType.MARKET_SIZE, ChartType.GAUGE].includes(chartType as ChartType); // Gauge kind of charts are consisted of HTML + SVG
  const chartContainerElement = document.querySelector(`#chart-container${isHTMLChart || isGaugeChart ? '' : ' svg'}`) as HTMLElement;

  if (isHTMLChart) {
    return exportHTMLChart(chartContainerElement);
  }

  if (isGaugeChart) {
    return await exportGaugeChart(chartContainerElement);
  }

  // Export standard SVG chart like comparison, heatmap, etc...
  return exportSVGChart(chartContainerElement);
};

/* 
  This function should export Filters component HTML to Image/Canvas element.
  We set up the width of the filters to CHART_WIDTH constant, and we scale it by scale factor.
  CHART_WIDTH should be the best width for rendering filters(best looking width for filters).
*/
const exportFiltersToImage = async (): Promise<HTMLCanvasElement | void> => {
  const elem = document.getElementById('filters-container');

  if (elem) {
    //Remove +more label from original element
    elem.querySelectorAll('[data-counter]').forEach(el => {
      el.setAttribute('hidden-counter', 'true');
    });
    return html2canvas(elem, {
      width: CHART_WIDTH,
      scale: DEFAULT_SCALE,
      onclone(d, clonedElement) {
        //Revert +more label on original element
        elem.querySelectorAll('[data-counter]').forEach(el => {
          el.removeAttribute('hidden-counter');
        });

        //Change the style of cloned element
        clonedElement.querySelectorAll('[data-counter]').forEach(el => {
          el.setAttribute('style', 'max-height: none;');
        });

        clonedElement.setAttribute('style', `width: ${CHART_WIDTH}px;`);
      },
    }).then(canvas => {
      return Promise.resolve(canvas);
    });
  }
};

/* 
  This is the main function for exporting chart to image. We create separate canvas elements for filters and chart itself, 
  so we then combine them in the single canvas/image. 
*/
export const exportChartToImage = (filename: string, format: string, chartType = ''): void => {
  const isGaugeChart = [ChartType.MARKET_SIZE, ChartType.GAUGE].includes(chartType as ChartType);

  const canvas = document.createElement('canvas');

  canvas.width = EXPORTED_CHART_WIDTH;
  canvas.style.width = `${EXPORTED_CHART_WIDTH}px`;

  const ctxt = canvas.getContext('2d');
  if (ctxt) {
    allSettledPromise([exportFiltersToImage(), elementToImage(chartType)]).then(([filtersImageResult, chartImageResult]) => {
      const [filtersImageData, chartImageData] = [filtersImageResult, chartImageResult].filter(
        res => res.status === 'fulfilled',
      ) as PromiseFulfilledResult<any>[];
      //Set exported chart height dynamically
      const EXPORTED_CHART_ALL_SPACING = 40;
      const height = filtersImageData?.value?.height + chartImageData?.value?.height + EXPORTED_CHART_ALL_SPACING;
      canvas.height = height;
      canvas.style.height = `${height}px`;

      //Set background as white
      ctxt.fillStyle = 'white';
      ctxt.fillRect(0, 0, canvas.width, canvas.height);

      //Draw filters and chart images
      const FILTERS_X_POSITION = 32;
      const FILTERS_Y_POSITION = 8;
      const CHART_X_POSITION = isGaugeChart ? 32 : 16;
      const CHART_Y_POSITION = filtersImageData?.value?.height + 32;

      ctxt.drawImage(filtersImageData?.value, FILTERS_X_POSITION, FILTERS_Y_POSITION);
      ctxt.drawImage(chartImageData?.value, CHART_X_POSITION, CHART_Y_POSITION);

      //Download exported chart
      const file = canvas.toDataURL();
      downloadImage(file, filename, format);
    });
  }
};

/* 
  This function is for creating Canvas/Image element from SVG based charts(comparison, heatmap).
  We scale the chart so it would fit to CHART_WIDTH * DEFAULT_SCALE exported chart width.
  Also this method is adding font and inline styles, so exported chart take proper fonts and stylings.
*/
const exportSVGChart = (elem: HTMLElement): Promise<void | HTMLCanvasElement> => {
  const svgSize = elem.getBoundingClientRect();
  const target = elem.cloneNode(true) as HTMLElement;
  const scaleToFit = (CHART_WIDTH * DEFAULT_SCALE) / svgSize.width;
  svgSize.height = svgSize.height + CLONED_EL_EXTEND_HEIGHT;
  target.setAttribute('height', `${svgSize.height + CLONED_EL_EXTEND_HEIGHT}`);

  target.setAttribute('style', `transform: scale(${scaleToFit})px;`);

  return addGoogleFontToSvg(target).then(async target => {
    //Set all the css styles inline
    if (!target) {
      return;
    }
    inlineStyles(target, elem);
    //Copy all html to a new canvas
    return await copyToCanvas(target, svgSize)
      .then(fileImage => Promise.resolve(fileImage))
      .catch(console.error);
  });
};

/* 
  This function is responsible for exporting Gauge Chart type only. 
  Gauge chart type is consisted of HTML + SVG elements. 
*/
const exportGaugeChart = async (elem: HTMLElement): Promise<HTMLCanvasElement> => {
  const svgSize = elem.getBoundingClientRect();
  const scaleToFit = CHART_WIDTH / svgSize.width;
  //scaleToFit is always greater than 0.6 on bigger monitor. `largeDisplayFactor` is to maintain the proper dispaly on bigger screen.
  const largeDisplayFactor = scaleToFit > MIN_SCALE_TO_FIT ? LARGE_DISPLAY_FACTOR : DEFAULT_SCALE;
  const canvas = await toCanvas(elem, {
    canvasHeight: svgSize.height * scaleToFit * largeDisplayFactor,
    canvasWidth: CHART_WIDTH * largeDisplayFactor,
  });
  return Promise.resolve(canvas);
};

/* 
  This function is responsible for exporting HTML charts only like Ranking and Growth performance.
  We set up the width of the chart to be the same as the Filters size(CHART_WIDTH), and then we scale it by scale factor(DEFAULT_SCALE).
*/
const exportHTMLChart = (elem: HTMLElement): Promise<HTMLCanvasElement> => {
  return html2canvas(elem, {
    width: CHART_WIDTH,
    scale: DEFAULT_SCALE,
    onclone(d, clonedElement) {
      clonedElement.setAttribute('style', `width: ${CHART_WIDTH}px; height: auto;`);
    },
  }).then(canvas => {
    return Promise.resolve(canvas);
  });
};

const addGoogleFontToSvg = (target: HTMLElement): Promise<HTMLElement | void> => {
  return GFontToDataURI(CHART_FONT_URL).then(cssRules => {
    if (cssRules) {
      const fontRules = cssRules.join('\n');

      let defs = target.querySelector('defs');
      if (!defs) {
        defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        target.append(defs);
      }

      const sheet = document.createElementNS('http://www.w3.org/2000/svg', 'style');
      sheet.setAttribute('type', 'text/css');
      defs?.append(sheet);
      sheet.textContent = fontRules;

      return Promise.resolve(target);
    }
  });
};

/*
  @Params : an url pointing to an embed Google Font stylesheet
  @Returns : a Promise, fulfiled with all the cssRules converted to dataURI as an Array
*/
const GFontToDataURI = (url: string) => {
  return fetch(url) // first fecth the embed stylesheet page
    .then(resp => resp.text()) // we only need the text of it
    .then(text => {
      // now we need to parse the CSSruleSets contained
      // but chrome doesn't support styleSheets in DOMParsed docs...
      const s = document.createElement('style');
      s.innerHTML = text;
      document.head.appendChild(s);
      const styleSheet = s.sheet;

      // this will help us to keep track of the rules and the original urls
      const FontRule = (rule: any) => {
        const src = rule.style.getPropertyValue('src') || rule.style.cssText.match(/url\(.*?\)/g)[0];
        if (!src) return null;
        const url = src.split('url(')[1].split(')')[0];
        return {
          rule: rule,
          src: src,
          url: url.replace(/"/g, ''),
        };
      };
      const fontRules = [],
        fontProms = [];

      // iterate through all the cssRules of the embedded doc
      // Edge doesn't make CSSRuleList enumerable...
      if (!styleSheet) {
        return;
      }

      for (let i = 0; i < styleSheet.cssRules.length || 0; i++) {
        const r = styleSheet.cssRules[i];
        const fR = FontRule(r);
        if (!fR) {
          continue;
        }
        fontRules.push(fR);
        fontProms.push(
          fetch(fR.url) // fetch the actual font-file (.woff)
            .then(resp => resp.blob())
            .then(blob => {
              return new Promise(resolve => {
                // we have to return it as a dataURI
                //   because for whatever reason,
                //   browser are afraid of blobURI in <img> too...
                const f = new FileReader();
                f.onload = () => resolve(f.result);
                f.readAsDataURL(blob);
              });
            })
            .then(dataURL => {
              // now that we have our dataURI version,
              //  we can replace the original URI with it
              //  and we return the full rule's cssText
              return fR.rule.cssText.replace(fR.url, dataURL);
            }),
        );
      }
      document.head.removeChild(s); // clean up
      return Promise.all(fontProms); // wait for all this has been done
    });
};

/* 
  This function is responsible for calculating the new height of the element/image based on the original element size and new desired width.
  It should always mantain the original aspect ratio.
*/
const determineNewHeight = (originalHeight: number, originalWidth: number, newWidth: number): number => {
  return (originalHeight / originalWidth) * newWidth;
};
