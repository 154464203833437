import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import PasswordResetForm from './PasswordResetForm';
import { ILoginFormData } from 'common/types/login';
import { clearToken, generateJWT } from 'utils/auth';
import { useGoogleAuth, useLogin } from 'common/hooks/login';
import { GrantTypes, ScreenType } from 'common/types/login';
import { usePasswordReset } from 'common/hooks/account';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'common/hooks/useOnAuthContext';

const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Cover = styled(FlexCenter)`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.darkPurple.toString()};
`;

const Container = styled(FlexCenter)`
  width: 320px;
`;

const Login = () => {
  const { t } = useTranslation();
  const { onAuth } = useAuthContext();
  const [error, setError] = React.useState<string>();
  const [resetMessage, setResetMessage] = React.useState<string>();
  const [view, setView] = React.useState<ScreenType>(ScreenType.LOGIN);
  const navigate = useNavigate();

  const signIn = useLogin(onAuth, setError);
  const passwordReset = usePasswordReset(setResetMessage, () => setError(t('login.errors.credentials')));
  const googleSignIn = useGoogleAuth(signIn, setError);

  React.useEffect(() => {
    clearToken();
    navigate('/login');
  }, [navigate]);

  const onChangeView = React.useCallback((view: ScreenType) => {
    setView(view);
    setResetMessage(undefined);
    setError(undefined);
  }, []);

  const handleSubmit = React.useCallback(
    ({ email = '', password = '' }: ILoginFormData) => {
      setError(undefined);
      const token = generateJWT(email, password);
      signIn({ grant_type: GrantTypes.PASSWORD, assertion: token });
    },
    [signIn],
  );

  return (
    <Cover>
      <Container>
        {view === ScreenType.LOGIN ? (
          <LoginForm setView={onChangeView} error={error} onSubmit={handleSubmit} onHandleAuth={googleSignIn} />
        ) : (
          <PasswordResetForm message={resetMessage} onSubmit={({ email }) => passwordReset(email)} setView={onChangeView} />
        )}
      </Container>
    </Cover>
  );
};

export default React.memo(Login);
