import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import CustomBar from 'modules/charts/components/CustomBar';
import { labelWidthsState, shouldRotateState } from 'common/atoms/charts';
import { getCurrentBarId } from 'modules/charts/components/CustomBar/utils';
import { BarItemProps } from '@nivo/bar';
import { ChartNumbersType, ChartType } from 'common/types/chart';

interface Props {
  barProps: BarItemProps;
  chartType: ChartType;
  moeStatus: boolean | undefined;
  isBarLayout: boolean;
  isNumericChart: boolean;
  isShowBarValue: boolean;
  visibleGroups: string[];
  chartNumbersType: ChartNumbersType;
}

const CustomBarWithLabelRotation: React.FC<Props> = ({
  barProps,
  chartType,
  moeStatus,
  isBarLayout,
  isNumericChart,
  isShowBarValue,
  visibleGroups,
  chartNumbersType,
}) => {
  const labelRef = React.useRef<SVGTextElement | null>(null);
  const setLabelWidths = useSetRecoilState(labelWidthsState);
  const shouldRotate = useRecoilValue(shouldRotateState(barProps.width));

  React.useEffect(() => {
    if (labelRef.current) {
      const { width: labelWidth } = labelRef.current.getBBox();
      setLabelWidths(prevWidths => [...prevWidths, labelWidth]);
    }
  }, [barProps.width, setLabelWidths, labelRef]);

  return (
    <CustomBar
      {...barProps}
      ref={labelRef}
      chartType={chartType}
      moeEnabled={moeStatus}
      isBarLayout={isBarLayout}
      showBarValue={isShowBarValue}
      visibleGroups={visibleGroups}
      numericChart={isNumericChart}
      shouldRotateLabel={shouldRotate}
      chartNumbersType={chartNumbersType}
      barId={getCurrentBarId(barProps.data.id as string)}
    />
  );
};

export default CustomBarWithLabelRotation;
