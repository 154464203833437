import * as React from 'react';
import styled from '@emotion/styled';

const DividerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white25.toString()};
`;

const DividerText = styled.span`
  position: absolute;
  transform: translate(50%, 50%);
  color: ${({ theme }) => theme.colors.white50.toString()};
  font-size: ${({ theme }) => theme.text.variants.body2.fontSize};
  padding: 0 ${({ theme }) => theme.spacing.unit}px;
  background-color: ${({ theme }) => theme.colors.darkPurple.toString()};
  right: 50%;
`;

const Divider: React.FC<{ text: string }> = ({ text }): React.ReactElement => (
  <DividerContainer>
    <DividerText>{text}</DividerText>
  </DividerContainer>
);

export default React.memo(Divider);
