import React, { useEffect } from 'react';
import SettingsSidebar from 'modules/settings/components/SettingsSidebar';
import { useRecoilValue } from 'recoil';
import { SETTINGS_ITEMS, SETTINGS_ROUTES } from 'settings/routes';
import { permissionsSelector } from 'common/atoms/account';
import { useTracking } from 'common/hooks/tracking';
import { SCREENS, TRACKING_EVENTS } from 'common/types/tracking';
import { clearRedirectPath, saveRedirectPath } from 'utils/auth';
import { Modal, Container, SegmentsDynamicContent, ContentWrapper, ContentInner } from './styled';
import { useGetWaveReports } from 'common/queries/waveReports';
import { useOutlet, useLocation } from 'react-router-dom';
import { useIsCompetitorsEnabled } from 'common/hooks/features';

type LocationState = {
  prevUrl?: string;
};

const Settings = () => {
  const { trackEvent } = useTracking();
  const { user_edit: isAdmin } = useRecoilValue(permissionsSelector);
  const isOrgProfileEnabled = useIsCompetitorsEnabled();
  const { data: waveReports } = useGetWaveReports();
  const reportsAvailable = !!waveReports?.length;
  const outlet = useOutlet();

  const { pathname, state: locationState } = useLocation() as {
    state: LocationState;
    pathname: string;
  };

  const sidebarMenuItems = React.useMemo(() => {
    let filteredMenuItems = [...SETTINGS_ITEMS];

    if (!reportsAvailable) {
      filteredMenuItems = filteredMenuItems.filter(({ id }) => id !== SETTINGS_ROUTES.WAVE_REPORTS);
    }

    if (!isAdmin) {
      filteredMenuItems = filteredMenuItems.filter(({ id }) => id !== SETTINGS_ROUTES.MEMBERS);
    }

    if (!isOrgProfileEnabled || !isAdmin) {
      filteredMenuItems = filteredMenuItems.filter(({ id }) => id !== SETTINGS_ROUTES.COMPETITORS);
    }

    return filteredMenuItems;
  }, [reportsAvailable, isAdmin, isOrgProfileEnabled]);

  const initialTab = React.useMemo(() => {
    const tabPath = pathname.split('/').pop();
    const currentTab = SETTINGS_ITEMS.find(({ id }) => id === tabPath) || SETTINGS_ITEMS[0];
    return currentTab.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentTab, setCurrentTab] = React.useState<string>(initialTab);

  const handleSelection = React.useCallback((itemId: string) => {
    setCurrentTab(itemId);
  }, []);

  React.useEffect(() => {
    if ((locationState as LocationState)?.prevUrl) {
      clearRedirectPath();
      saveRedirectPath((locationState as LocationState)?.prevUrl || '');
    }
  }, [locationState]);

  /** Track event for view page */
  useEffect(() => {
    trackEvent(TRACKING_EVENTS.VIEW_PAGE, {
      screen: SCREENS.SETTINGS,
      'settings-tab': currentTab,
    });
  }, [trackEvent, currentTab]);

  return (
    <Modal>
      <Container>
        <SegmentsDynamicContent>
          <SettingsSidebar items={sidebarMenuItems} onSelection={handleSelection} currentItem={currentTab} />
          <ContentWrapper>
            <ContentInner>{outlet}</ContentInner>
          </ContentWrapper>
        </SegmentsDynamicContent>
      </Container>
    </Modal>
  );
};

export default React.memo(Settings);
