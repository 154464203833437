import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import Modal from 'common/components/Modal';
import { Text } from 'common/styledComponents/typography';
import { LoadingButton } from 'common/components/Form/Button';
import { permissionsSelector } from 'common/atoms/account';
import { useSegmentsModal } from 'common/contexts/SegmentsModalContext';
import { DataTestId } from 'settings/constants';
import { Characteristic, Segment } from 'common/types/segments';
import { useTranslation } from 'react-i18next';
import { useGetCharacteristics } from 'common/queries/characteristics';
import SegmentModalCell from 'modules/charts/components/Filters/SegmentsModal/SegmentModalCell';
import { useNavigate } from 'react-router-dom';
import { LabelButtonWithIcon } from 'common/styledComponents/buttons';
import { SEGMENTS_ROUTES } from 'settings/routes';

const ModalContainer = styled.div``;

const TopContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.padding * 3}px ${({ theme }) => theme.spacing.padding * 3}px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
`;

const BottomContainer = styled.div`
  display: flex;
  position: relative;
  width: 320px;
  padding: ${({ theme }) => theme.spacing.padding * 4}px ${({ theme }) => theme.spacing.padding * 3}px;
`;

const Title = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.4rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.padding * 5}px;
  gap: ${({ theme }) => theme.v2.space(1)};
  span {
    margin: ${({ theme }) => theme.spacing.padding * 1}px;
    color: ${({ theme }) => theme.colors.lightPurple.toString()};
    cursor: pointer;
  }
`;

interface Props {
  studyId: string;
  showPanel: boolean;
  handleClose: () => void;
  segmentModalData: Segment | null | undefined;
  LatanaIconComponent?: JSX.Element;
}

const SegmentsModal: FC<Props> = ({ showPanel, handleClose, segmentModalData, studyId, LatanaIconComponent }) => {
  const { t } = useTranslation();
  const { toggleSegmentsModal } = useSegmentsModal();
  const { chart_edit: isEditor } = useRecoilValue(permissionsSelector);
  const { data: characteristicsData } = useGetCharacteristics(studyId);
  const standardCharacteristics = characteristicsData?.standard_characteristics;
  const navigate = useNavigate();

  const isLatanaSegment = !!segmentModalData?.readable_only;

  const editSegmentClick = React.useCallback(() => {
    handleClose();
    toggleSegmentsModal(true, studyId);
    navigate(`/segments/${SEGMENTS_ROUTES.CUSTOM}/${segmentModalData?.id}`);
  }, [segmentModalData, handleClose, toggleSegmentsModal, navigate, studyId]);

  const getStandardChars = React.useCallback((segmentChars: Characteristic[], standardChars?: Characteristic[]) => {
    return segmentChars?.length
      ? segmentChars
      : (standardChars || []).map(({ key, options }) => ({
          name: key,
          values: options.map(({ name }) => name),
        }));
  }, []);

  const modalContent = React.useMemo(() => {
    if (!segmentModalData) return null;

    const { standard_characteristics, extra_characteristics } = segmentModalData;
    const standardChars = getStandardChars(standard_characteristics, standardCharacteristics);

    return (
      <>
        {standardChars?.map(({ name, values }) => !!values?.length && <SegmentModalCell key={name} name={name} values={values} />)}
        {extra_characteristics?.map(({ name, values }) => !!values?.length && <SegmentModalCell key={name} name={name} values={values} />)}
      </>
    );
  }, [segmentModalData, getStandardChars, standardCharacteristics]);

  return (
    <Modal isOpen={showPanel} customWidth={640} handleClose={handleClose} showCloseButton={false}>
      <ModalContainer>
        <TopContainer>
          <Title>
            <Text variant='h4'> {segmentModalData ? `Persona: ${segmentModalData?.name}` : null}</Text>
            {isEditor && !isLatanaSegment && (
              <LabelButtonWithIcon
                icon='pencil'
                disabled={false}
                label={t('segmentBuilder.editSegment')}
                data-testid={DataTestId.EDIT_SEGMENT_BUTTON}
                onClick={editSegmentClick}
              />
            )}
            {isLatanaSegment && LatanaIconComponent}
          </Title>
          {modalContent}
        </TopContainer>
        <BottomContainer>
          <LoadingButton type={isEditor ? 'submit' : 'button'} color='lightPurple' loading={false} onClick={handleClose}>
            {t('common.gotIt')}
          </LoadingButton>
        </BottomContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SegmentsModal;
