import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { useRecoilValue } from 'recoil';
import { Segments } from 'common/types/segments';
import { useRollbar } from '@rollbar/react';
import { processError } from 'utils/helpers';

export const useRawSegments = (): UseQueryResult<Segments[], unknown> => {
  const rollbar = useRollbar();
  const { fetchGet } = useRecoilValue(fetchSelector);
  return useQuery(
    ['segments'],
    async () => {
      try {
        return await fetchGet<Segments[]>(API_URLS.SEGMENTS);
      } catch (e: unknown) {
        processError(e, rollbar.error, 'useRawSegments');
      }
    },
    {
      staleTime: Infinity,
      placeholderData: [] as Segments[],
    },
  );
};
