import { stringify } from 'querystring';
import { getRefreshToken, updateRequestHeaders, fetchRefreshToken } from 'utils/auth';
import { FetchOptions, IFetchMethods } from 'common/types/common';

// global lock to prevent multiple refresh token requests
let refreshPromise: null | Promise<void> = null;

const retryRequest = async <T>(fetchBase: () => Promise<T>, retried: boolean): Promise<T> => {
  // in case there is an ongoing refresh token request
  // will wait for it to be fulfilled be fore making the request
  if (refreshPromise) {
    await refreshPromise;
  }
  try {
    // make the request
    return await fetchBase();
  } catch (e: unknown) {
    console.error('Retry request error', e);
    // if it's a retry after token has been refreshed, fail request
    if (retried) {
      throw e;
    }
    const resp = e as Response;
    // check for response status
    const unauthorized = [401, 403].includes(resp.status);
    const refreshToken = getRefreshToken();
    if (unauthorized && refreshToken) {
      // set the promiseRef to prevent multiple request to refresh the token
      refreshPromise = fetchRefreshToken(refreshToken);
      // await for the access tokens to be refreshed
      await refreshPromise;
      // remove the reference
      refreshPromise = null;
      // retry the previously failed request
      return retryRequest(fetchBase, true);
    }
    throw e;
  }
};

const fetchBase = async <T>(url: string, { method, body, query, headers = {} }: FetchOptions): Promise<T> => {
  const URL = query ? `${url}?${stringify(query)}` : url;
  const reqBody = body ? JSON.stringify(body) : undefined;

  const resp = await fetch(URL, {
    method,
    body: reqBody,
    headers,
  });

  const { status, ok } = resp;
  const isJSON = resp.headers.get('Content-Type')?.includes('application/json');

  try {
    let data: T;
    if (isJSON) {
      data = await resp.json();
    } else {
      data = (await resp.text()) as unknown as T;
    }

    if (ok) {
      return data;
    } else {
      throw {
        ...data,
        status,
      };
    }
  } catch (e: unknown) {
    console.error('Fetch base error', e);
    if (ok) {
      return e as T;
    } else {
      throw e;
    }
  }
};

const authFetch = async <T>(url: string, { method, body, query, headers = {} }: FetchOptions) => {
  const makeRequest = async () => {
    const requestHeaders = await updateRequestHeaders(headers);
    return fetchBase<T>(url, { method, headers: requestHeaders, body, query });
  };

  return await retryRequest<T>(makeRequest, false);
};

export const fetchMethods = (): IFetchMethods => {
  return {
    fetchGet: <T>(url: string, query?: FetchOptions['query']) => authFetch<T>(url, { method: 'GET', query }),
    fetchPost: <T>(url: string, body?: FetchOptions['body'], options?: { query: FetchOptions['query'] }) =>
      authFetch<T>(url, {
        method: 'POST',
        body,
        query: options ? options.query : undefined,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    fetchPatch: <T>(url: string, body: FetchOptions['body'], options?: { query: FetchOptions['query'] }) =>
      authFetch<T>(url, {
        method: 'PATCH',
        body,
        query: options ? options.query : undefined,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    fetchPut: <T>(url: string, body: FetchOptions['body'], options?: { query: FetchOptions['query'] }) =>
      authFetch<T>(url, {
        method: 'PUT',
        body,
        query: options ? options.query : undefined,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    fetchDelete: <T>(url: string, query?: FetchOptions['query']) =>
      authFetch<T>(url, {
        method: 'DELETE',
        query,
      }),
  };
};
