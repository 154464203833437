import { AxisTickProps } from '@nivo/axes';
import { theme } from 'common/theme';
import React from 'react';
import { concatEllipsis } from 'utils/helpers';

const MAX_CHARACTERS = 14;

const ChartAxisTicks: React.FC<AxisTickProps<string>> = ({ textAnchor, rotate, value, textX, textY, x, y }) => {
  if (!value) return null;
  // Left axis labels will always be truncated, but the bottom axis labels will be truncated if labels are rotated.
  const isLeftAxis = x === 0;
  const label = isLeftAxis || rotate ? concatEllipsis(value, MAX_CHARACTERS) : value;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor={textAnchor}
        dominantBaseline='middle'
        transform={`translate(${textX},${textY}) rotate(${rotate})`}
        style={{
          fontSize: theme.text.variants.p.fontSize,
          fontWeight: theme.text.variants.p.fontWeight || 600,
          fill: theme.colors.regularGrey.toString(),
        }}
      >
        <title>{value}</title>
        {label}
      </text>
    </g>
  );
};

export default React.memo(ChartAxisTicks);
