import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartFormData, ChartType } from 'common/types/chart';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { ActionItems } from 'settings/constants';
import { useUserPermissions } from 'common/hooks/useUserPermissions';
import { SCREENS, TRACKING_EVENTS } from 'common/types/tracking';
import { useTracking } from 'common/hooks/tracking';

type UseMenuItemsProps = {
  isEditor: boolean;
  onRemove: () => void;
  onDownload: () => void;
  handleExportChartCSV: () => void;
  onInputSelect: (inputName: string) => void;
  handleDuplicateChart: () => void;
  isChartInMultipleDashboards: boolean;
  isInternalUser: boolean;
  isOwnerLatana: boolean;
  handleModalOpen: () => void;
  chartFormData: ChartFormData;
};

type UseMenuItem = (value: UseMenuItemsProps) => Array<SelectPopupItemProps>;

export const useMenuItems: UseMenuItem = ({
  isEditor,
  onRemove,
  onDownload,
  handleExportChartCSV,
  onInputSelect,
  handleDuplicateChart: onChartDuplicate,
  isChartInMultipleDashboards,
  isInternalUser,
  isOwnerLatana,
  handleModalOpen,
  chartFormData,
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const isEditable = !isOwnerLatana && isEditor;
  const shouldRemoveLastItem = isChartInMultipleDashboards && !isInternalUser;

  // this is temporary solution for brand perception chart
  const isBrandPerceptionChart = chartFormData.chart_type === ChartType.BRAND_PERCEPTION;

  const canAddChart = useUserPermissions();

  const { name: chartName, chart_type: chartType, description } = chartFormData;

  const onTrackEvent = useCallback(
    (eventName: string) => {
      trackEvent(eventName, {
        chartType,
        chartName,
        screen: SCREENS.CHART_DETAILS,
      });
    },
    [chartType, chartName, trackEvent],
  );

  return useMemo(() => {
    let items = [
      ...(isEditable
        ? [
            {
              label: t('actionMenu.rename'),
              value: ActionItems.RENAME,
              icon: 'pencil',
              action: () => {
                onTrackEvent(`${TRACKING_EVENTS.ACTION_MENU_ITEM_CLICK}rename`);
                onInputSelect('chartName');
              },
            },
          ]
        : []),
      ...(isEditor
        ? [
            {
              label: t('actionMenu.duplicate'),
              value: ActionItems.DUPLICATE,
              icon: 'duplicate',
              action: (eventName: string) => {
                onTrackEvent(eventName || `${TRACKING_EVENTS.ACTION_MENU_ITEM_CLICK}duplicate`);
                onChartDuplicate();
              },
            },
          ]
        : []),
      ...(isEditable
        ? [
            {
              label: t(`actionMenu.${description ? 'change' : 'add'}Description`),
              value: description ? ActionItems.CHANGE_DESCRIPTION : ActionItems.ADD_DESCRIPTION,
              icon: 'pencil',
              action: () => {
                onTrackEvent(`${TRACKING_EVENTS.ACTION_MENU_ITEM_CLICK}change-description`);
                onInputSelect('chartDescription');
              },
            },
          ]
        : []),
      ...(canAddChart
        ? [
            {
              label: t('actionMenu.addToDashboard'),
              value: ActionItems.ADD_TO_DASHBOARD,
              icon: 'apps',
              action: () => {
                onTrackEvent(`${TRACKING_EVENTS.ACTION_MENU_ITEM_CLICK}add-dashboard`);
                handleModalOpen();
              },
            },
          ]
        : []),
      {
        label: t('actionMenu.createChartImage'),
        value: ActionItems.CREATE_CHART_IMAGE,
        icon: 'photos',
        action: (eventName: string) => {
          onTrackEvent(eventName || `${TRACKING_EVENTS.ACTION_MENU_ITEM_CLICK}download-png`);
          onDownload();
        },
      },
      ...(!isBrandPerceptionChart
        ? [
            {
              label: t('actionMenu.downloadCSV'),
              value: ActionItems.DOWNLOAD_CSV,
              icon: 'download',
              action: (eventName: string) => {
                onTrackEvent(eventName || `${TRACKING_EVENTS.ACTION_MENU_ITEM_CLICK}download-csv`);
                handleExportChartCSV();
              },
            },
          ]
        : []),
      { label: '', value: ActionItems.SEPARATOR },
      ...(isEditable
        ? [
            {
              label: { name: t('actionMenu.delete'), color: { active: 'red500', disabled: 'red200' } },
              value: ActionItems.DELETE,
              icon: { name: 'trash-can', color: { active: 'red400', disabled: 'red200' } },
              blurb: t('actionMenu.disabledDeleteBlurb', { title: 'Chart' }),
              action: () => {
                onTrackEvent(`${TRACKING_EVENTS.ACTION_MENU_ITEM_CLICK}delete-chart`);
                onRemove();
              },
            },
          ]
        : []),
    ] as SelectPopupItemProps[];

    if (shouldRemoveLastItem) {
      items = items.slice(0, items.length - 1);
    }

    return items;
  }, [
    isEditor,
    onRemove,
    onDownload,
    handleExportChartCSV,
    t,
    onInputSelect,
    onChartDuplicate,
    isEditable,
    shouldRemoveLastItem,
    canAddChart,
    handleModalOpen,
    description,
    onTrackEvent,
    isBrandPerceptionChart,
  ]);
};
