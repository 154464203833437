import React from 'react';
import styled from '@emotion/styled';
import { CheckmarkIconWhite } from 'icons';
import { LegendItemStatus } from 'common/types/common';

export const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-right: ${({ theme }) => theme.space(1)};
  border-radius: 2px;

  &:hover {
    background: ${({ theme }) => theme.colors.black05.toString()};
  }
`;

export const LegendCheckbox = styled.div<{ checkboxState: string; color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin: ${({ theme }) => theme.space(0, 1, 0, 1)};
  background: ${({ color, checkboxState, theme }) =>
    checkboxState === LegendItemStatus.DEFAULT || checkboxState === LegendItemStatus.CHECKED
      ? color?.toString()
      : theme.colors.white.toString()};
  border: ${({ checkboxState, theme }) =>
    checkboxState === LegendItemStatus.UNCHECKED ? `1px solid ${theme.colors.borderGrey.toString()}` : 'none'};
  position: relative;

  ${({ checkboxState }) =>
    checkboxState === LegendItemStatus.CHECKED &&
    `&:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${CheckmarkIconWhite});
    background-size: 8px 8px;
    background-repeat: no-repeat;
    background-position: center;
  }`};
`;

export const LegendText = styled.div`
  font-weight: ${({ theme }) => theme.text.variants.p.fontWeight};
  font-size: ${({ theme }) => theme.text.variants.p.fontSize};
  line-height: 18px;
  color: ${({ theme }) => theme.colors.regularGrey.toString()};
`;

interface Props {
  label: string;
  itemKey: string;
  color: string;
  toggleItem: (itemKey: string) => void;
  status: keyof typeof LegendItemStatus;
}

const LegendItem = ({ label, color, toggleItem, itemKey, status }: Props) => {
  return (
    <LegendWrapper onClick={() => toggleItem(itemKey.toString())}>
      <LegendCheckbox color={color} checkboxState={status} />
      <LegendText>{label}</LegendText>
    </LegendWrapper>
  );
};

export default React.memo(LegendItem);
