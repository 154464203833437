import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/styledComponents/typography';
import styled from '@emotion/styled';
import { Button } from 'common/components/Form/Button';
import TextInput from 'common/components/TextInput';
import { GoogleLogoIcon } from 'icons';
import Divider from 'common/components/Divider';
import { ILoginFormData } from 'common/types/login';
import { ScreenType } from 'common/types/login';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.unit * 4}px 0;
`;

const ErrorContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.unit * -2}px 0 ${({ theme }) => theme.spacing.unit * 2}px;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResetBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const GoogleLogo = styled.img`
  margin: 0 ${({ theme }) => theme.spacing.unit * 2}px 0 0;
`;

const ButtonWithIcon = styled(Button)`
  display: flex;
  align-items: center;
`;

const GoogleAuthContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.unit * 6}px 0 ${({ theme }) => theme.spacing.unit * 2}px;
`;

interface ILoginFormProps {
  onSubmit: (data: ILoginFormData) => void;
  error?: string;
  setView: (view: ScreenType) => void;
  onHandleAuth: () => void;
}

const LoginForm: React.FC<ILoginFormProps> = ({ onSubmit, error, setView, onHandleAuth }) => {
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm<ILoginFormData>();
  const changeView = React.useCallback(() => setView(ScreenType.RESET), [setView]);

  return (
    <React.Fragment>
      <Text color='white' variant='h3'>
        {t('login.title')}
      </Text>
      <GoogleAuthContainer>
        <ButtonWithIcon blend align='left' stretch size='small' pill={false} inverted color='white' onClick={onHandleAuth}>
          <GoogleLogo alt={t(`login.googleAuth.alt`)} src={GoogleLogoIcon} />
          {t('login.googleAuth.loginTitle')}
        </ButtonWithIcon>
      </GoogleAuthContainer>
      <Divider text={t('login.withEmail')} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput name='email' ref={register} placeholder={t('login.email.placeholder')} />
        <TextInput type='password' name='password' ref={register} placeholder={t('login.password.placeholder')} />
        {error ? (
          <ErrorContainer>
            <Text color='wine' variant='body1'>
              {error}
            </Text>
          </ErrorContainer>
        ) : null}
        <FormActions>
          <Button color='lightPurple' type='submit'>
            {t('login.submit')}
          </Button>
          <ResetBtn onClick={changeView} type='button'>
            <Text color='white' variant='body1'>
              Forgot password
            </Text>
          </ResetBtn>
        </FormActions>
      </Form>
    </React.Fragment>
  );
};

export default React.memo(LoginForm);
