import { css } from '@emotion/react';
import { theme } from 'common/theme';

export const scrollbarStyles = () => css`
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.v2.colours.gray500};
    border-radius: ${theme.v2.borderRadius * 2}px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }
`;
