import { Theme } from '@emotion/react';
import { roundValue } from 'utils/chart';
import { RawChartData } from 'common/types/chart';
import { BrandPayload, Country, Wave } from 'common/types/common';
import { calcAbsoluteValue, calcPercentageValue, chartValueInMsOrKs, chartValueInPercents } from 'utils/helpers';
import { FilterNames } from 'common/types/filters';
import { DefaultValues } from 'settings/constants';
import { AIDED_BRAND_AWARENESS, BRAND_CONSIDERATION } from 'settings/constants';
import { getMonthsAgoCount } from 'utils/dates';

enum ColumnTitles {
  AWARENESS = 'Awareness',
  CONSIDERATION = 'Consideration',
  CONVERSION = 'Awareness - Consideration conversion',
}

enum Sentiment {
  POSITIVE = 'green700',
  NEUTRAL = 'gray500',
  NEGATIVE = 'red600',
}

export interface MFHeaderTitle {
  title: string | null;
  key: string | null;
}

export interface MFHeader {
  title: string | null;
  subtitles: Array<MFHeaderTitle>;
}

export interface MFChartData {
  index: number | string;
  values: MFChartValue[];
  segment: string | undefined;
}

export interface MFChartValue {
  previousWave: MFChartValueItem;
  pastWave: MFChartValueItem;
  [key: string]: MFChartValueItem;
}

interface MFChartValueItem {
  value: number | string | null;
  valueAbs: number | string | null;
  isDynamicPositive: boolean | null;
  valueColor: Sentiment;
  latestValue: number | null;
  population: number;
  [key: string]: string | boolean | null | keyof Theme['colors'] | number;
}

export interface MFChart {
  chartHeaders: Array<MFHeader>;
  chartData: Array<MFChartData>;
}

export const getChartData = ({
  chartData,
  groupedBy,
  waveMap,
  countryMap,
  brandList,
  segmentList,
  translator,
}: {
  chartData: RawChartData[];
  groupedBy: FilterNames;
  waveMap: Record<number, Wave>;
  countryMap: Record<number, Country>;
  brandList: BrandPayload[] | undefined;
  segmentList: { [x: number]: string }[] | undefined;
  translator: (key: string) => string;
}): MFChart => {
  const chartDataSortedByWave = getChartDataSortedByWaveDate(chartData, waveMap);
  const columnSubtitles = [translator('common.from1monthAgo'), `From ${getMonthsAgoCount(waveMap, chartDataSortedByWave)} months ago`];
  const chartHeaders = Object.values(ColumnTitles).map(title => createHeader(title, columnSubtitles));
  const chartColumnKeys = createChartColumnKeys(chartHeaders);
  return {
    chartHeaders,
    chartData: createChartData({
      chartDataSortedByWave,
      countryMap,
      brandList,
      chartColumnKeys,
      groupedBy,
      segmentList,
      translator,
    }),
  };
};

const createChartColumnKeys = (chartHeaders: MFHeader[]): Record<string, string[]> => {
  return chartHeaders.reduce((acc, { title, subtitles }) => ({ ...acc, [`${title}`]: subtitles.map(({ key }) => key) }), {});
};

const createHeader = (title: string, subtitles: Array<string>): MFHeader => ({
  title,
  subtitles: subtitles.map(subtitle => ({ title: subtitle, key: `${title}-${subtitle}` })),
});

export const createChartData = ({
  chartDataSortedByWave,
  countryMap,
  brandList,
  chartColumnKeys,
  groupedBy,
  segmentList,
  translator,
}: {
  chartDataSortedByWave: RawChartData[];
  countryMap: Record<number, Country>;
  brandList: BrandPayload[] | undefined;
  chartColumnKeys: Record<string, string[]>;
  groupedBy: FilterNames;
  segmentList: { [x: number]: string }[] | undefined;
  translator: (key: string) => string;
}) => {
  const mainFilterIdList = Array.from(new Set(chartDataSortedByWave.map(chart => chart[groupedBy]))) as number[];
  const popWeightToSegmentId = chartDataSortedByWave.reduce(
    (acc, { segment, segment_population_weight }) => ({ ...acc, [segment]: segment_population_weight }),
    {},
  ) as Record<number, number>;

  return mainFilterIdList.map(filterId => {
    const awarenessData = getKpiData(filterId, chartDataSortedByWave, AIDED_BRAND_AWARENESS, groupedBy);
    const considerationData = getKpiData(filterId, chartDataSortedByWave, BRAND_CONSIDERATION, groupedBy);
    const countryId = groupedBy === FilterNames.COUNTRY ? filterId : chartDataSortedByWave[0]?.country;
    const segmentId = groupedBy === FilterNames.SEGMENT ? filterId : chartDataSortedByWave[0]?.segment;

    const segmentWeight = popWeightToSegmentId[segmentId] ?? 0;
    const countryPopulation = countryMap[countryId]?.population ?? 0;
    const populationSize = countryPopulation * segmentWeight;

    return {
      index: getChartLabel(groupedBy, filterId, countryMap, mainFilterIdList, brandList, segmentList) ?? filterId,
      values: [
        calculateMonthlyValues(awarenessData, populationSize, chartColumnKeys[`${ColumnTitles.AWARENESS}`]),
        calculateMonthlyValues(
          getConsiderationDataBasedOnGlobal(considerationData, awarenessData),
          populationSize,
          chartColumnKeys[`${ColumnTitles.CONSIDERATION}`],
        ),
        calculateMonthlyValues(considerationData, populationSize, chartColumnKeys[`${ColumnTitles.CONVERSION}`], true),
      ],
      segment: groupedBy === FilterNames.SEGMENT ? translator('common.segmentSize') : getSegmentName(segmentId, segmentList),
    };
  });
};

const getChartLabel = (
  groupedBy: FilterNames,
  filterId: number,
  countryMap: Record<number, Country>,
  mainFilterIdList: number[],
  brandList: BrandPayload[] | undefined,
  segmentList: { [x: number]: string }[] | undefined,
) => {
  switch (groupedBy) {
    case FilterNames.COUNTRY:
      return countryMap[filterId].name;
    case FilterNames.BRAND:
      return mapBrandNameToId(mainFilterIdList, brandList as BrandPayload[])?.[filterId];
    case FilterNames.SEGMENT:
      return getSegmentName(filterId, segmentList);
    default:
      return filterId;
  }
};

const getSegmentName = (segmentId: number, segments: { [x: number]: string }[] | undefined): string => {
  if (!segments) return 'Unknown segment';
  return segments?.reduce((acc, item) => ({ ...acc, ...item }), {} as Record<number, string>)[segmentId];
};

const mapBrandNameToId = (chartBrandsId: number[], brands: BrandPayload[]): Record<number, string> => {
  const chartBrands = brands?.filter(({ id }) => chartBrandsId.includes(id));
  return chartBrands?.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
};

const getKpiData = (itemId: number, chartData: RawChartData[], kpiName: string, groupBy: FilterNames): Array<RawChartData> => {
  return chartData?.filter(item => item[groupBy] === itemId)?.filter(({ kpi }) => kpi === kpiName);
};

const getConsiderationDataBasedOnGlobal = (considerationData: RawChartData[], awarenessData: RawChartData[]) => {
  return considerationData.map((x, index) => {
    const awarenessMean = awarenessData?.[index]?.mean ?? 0;
    return { ...x, mean: x.mean * awarenessMean };
  });
};

const getCellColor = (isDynamicPositive: boolean | null) =>
  isDynamicPositive === null ? Sentiment.NEUTRAL : isDynamicPositive ? Sentiment.POSITIVE : Sentiment.NEGATIVE;

const isDynamicPositive = (value: number) => (value === 0 ? null : value > 0);

export const getConversionRate = (awareness: number, consideration: number) => consideration / awareness;

const calculateMonthlyValues = (kpiData: RawChartData[], population: number, columnKeys: string[], noAbs?: boolean): MFChartValue => {
  const latestWave = kpiData[0]?.mean;
  const prevWave = kpiData[1]?.mean;
  const pastWave = kpiData[kpiData.length - 1]?.mean;
  const showUpcomingLatest = kpiData.length < 2;
  const showUpcomingPast = kpiData.length < 3;
  return {
    previousWave: calculateWaveDifference(latestWave, showUpcomingLatest ? null : prevWave, population, columnKeys[0], noAbs),
    pastWave: calculateWaveDifference(latestWave, showUpcomingPast ? null : pastWave, population, columnKeys[1], noAbs),
  };
};

const calculateWaveDifference = (
  latestValue: number,
  previousValue: number | null,
  population: number,
  columnKey: string,
  noAbs = false,
): MFChartValueItem => {
  if (!previousValue && previousValue !== 0) {
    return { ...noDataPlaceholder, latestValue: roundValue(latestValue * 100, true), population };
  }

  const diff = latestValue - previousValue;
  const percentageValue = calcPercentageValue(diff, true);
  const absoluteValue = calcAbsoluteValue(diff, population);
  const dynamic = isDynamicPositive(diff);

  return {
    value: chartValueInPercents(percentageValue, dynamic),
    valueAbs: !noAbs ? chartValueInMsOrKs(absoluteValue, dynamic) : null,
    isDynamicPositive: dynamic,
    valueColor: getCellColor(dynamic) as Sentiment,
    [columnKey]: roundValue(percentageValue, true),
    [`${columnKey}_raw`]: diff,
    latestValue: roundValue(latestValue * 100, true),
    population: population,
  };
};

const getChartDataSortedByWaveDate = (data: RawChartData[], waveMap: Record<number, Wave>) =>
  [...data].sort((a, b) => {
    const firstWaveData = waveMap[b.wave]?.date?.getTime() ?? 0;
    const secondWaveDate = waveMap[a.wave]?.date?.getTime() ?? 0;
    return firstWaveData - secondWaveDate;
  });

const noDataPlaceholder = {
  value: DefaultValues.UPCOMING,
  valueAbs: null,
  isDynamicPositive: null,
  valueColor: Sentiment.NEUTRAL,
  latestValue: null,
  population: 0,
};

// TODO: We have another function with the same name in src/utils/dates.ts. Refactor to avoid confusion.
export const getLatestWaveDate = (chartData: RawChartData[], waveMap: Record<number, Wave>) => {
  const sortedWaveList = getChartDataSortedByWaveDate(chartData, waveMap);
  return waveMap?.[sortedWaveList?.[0]?.wave]?.date;
};
