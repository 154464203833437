import React, { useCallback } from 'react';
import { CardComponent, CardHeaderItem } from 'common/types/dashboard';
import { useFetchChart } from 'common/hooks/charts';
import {
  CardHeader,
  CardHeaderText,
  CardDescription,
  CardWrapper,
  CardRemoveIconWrapper,
  CardDragIconWrapper,
} from 'modules/dashboards/styledComponents/dashboard';
import { allSettledPromise } from 'utils/helpers';
import CardBody from 'modules/dashboards/components/CardBody';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { theme } from 'common/theme';
import { useDashboardContext } from 'common/contexts/DashboardContext';
import Icon from 'common/components/Icon';
import { ChartType } from 'common/types/chart';
import { markdownParser } from 'utils/markdown';
import { useIsChartFiltersOnDashboardEnabled } from 'common/hooks/features';

interface HeaderLabels {
  title: CardHeaderItem;
  subtitle: CardHeaderItem | undefined;
}

interface Props {
  cardComponents: CardComponent;
  displaySize: string | null;
  uuid: string | undefined;
  editMode?: boolean;
  style?: React.CSSProperties;
  className?: string | undefined;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

const CardContainer = React.forwardRef<HTMLDivElement, Props>(
  (
    { style, className, onMouseDown, onMouseUp, onTouchEnd, children, cardComponents, displaySize, editMode, uuid }: Props,
    ref,
  ): JSX.Element | null => {
    const cardHeaderRef = React.useRef<HTMLDivElement>(null);
    const { chart, fetchChart } = useFetchChart();
    const { trackEvent } = useTracking();
    const { removeCardFromDashboard } = useDashboardContext();
    const showChartFiltersOnDashboard = useIsChartFiltersOnDashboardEnabled();

    const chartHeader = React.useMemo(() => {
      return cardComponents.header.reduce((labels, headerItem) => {
        labels[headerItem.type as 'title'] = headerItem;
        return labels;
      }, {} as HeaderLabels);
    }, [cardComponents]);

    const chartName = chart?.chart.name || chartHeader.title.text;

    const trackClickEvent = (): void => {
      !chart && trackEvent(TRACKING_EVENTS.CLICK_INSIGHT_CARD);
    };

    const trimHeaderText = React.useCallback((): void => {
      const REGULAR_WIDTH = 6;
      const COMPARISON_WIDTH = 24;
      const cardHeaderText = cardHeaderRef.current?.querySelector(`.cardHeaderText`) as HTMLElement;

      if (cardHeaderText) {
        const width = chart?.chart.chart_type === ChartType.COMPARISON ? COMPARISON_WIDTH : REGULAR_WIDTH;
        const cardHeaderWidth = `calc(100% - ${theme.space(width)})`;
        cardHeaderText.style.width = cardHeaderWidth;
      }
    }, [chart, cardHeaderRef]);

    const setHeaderTextWidth = (): void => {
      const cardHeaderText = cardHeaderRef.current?.querySelector(`.cardHeaderText`) as HTMLElement;
      if (cardHeaderText) {
        cardHeaderText.style.width = `100%`;
      }
    };

    React.useEffect(() => {
      const fetchChartData = async () => {
        try {
          const chartUuids = cardComponents.body.reduce((uuidList, bodyItem) => {
            if (bodyItem.uuid) uuidList.push(bodyItem.uuid);
            return uuidList;
          }, [] as string[]);
          await allSettledPromise(chartUuids.map(uuid => fetchChart(uuid)));
        } catch (e: unknown) {
          console.error('Not able to fetch charts in CardContainer', e);
        }
      };
      fetchChartData();
    }, [cardComponents, fetchChart]);

    const handleDeleteChart = useCallback(() => {
      removeCardFromDashboard?.(chart?.chart.uuid, uuid);
      trackEvent(TRACKING_EVENTS.CLICK_DELETE_CHART_FROM_DASHBOARD, {
        chartName: chart?.chart.name,
      });
    }, [removeCardFromDashboard, trackEvent, chart, uuid]);

    return (
      <CardWrapper
        data-card-size={displaySize}
        role='button'
        onClick={trackClickEvent}
        onMouseEnter={trimHeaderText}
        onMouseLeave={setHeaderTextWidth}
        style={{ ...style }}
        className={className}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      >
        <CardHeader ref={cardHeaderRef}>
          {editMode && (
            <CardDragIconWrapper>
              <Icon icon='drag' />
            </CardDragIconWrapper>
          )}
          <CardHeaderText className='cardHeaderText' variant='body2' fontWeight='800' color='black' title={chartName} editMode={editMode}>
            <span dangerouslySetInnerHTML={{ __html: markdownParser(chartName) }}></span>
          </CardHeaderText>

          {editMode && (
            <CardRemoveIconWrapper onClick={handleDeleteChart}>
              <Icon icon='minus-fill' />
            </CardRemoveIconWrapper>
          )}
          {!showChartFiltersOnDashboard && (
            <CardDescription>
              <span
                dangerouslySetInnerHTML={{ __html: markdownParser(chartHeader?.subtitle?.text || chart?.chart.description || '') }}
              ></span>
            </CardDescription>
          )}
        </CardHeader>
        <CardBody chart={chart} content={cardComponents} editMode={editMode} />
        {children} {/* Make sure to include children to add resizable handle */}
      </CardWrapper>
    );
  },
);

CardContainer.displayName = 'CardContainer';

export default CardContainer;
