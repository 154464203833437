import { useCallback } from 'react';
import { Owner } from 'common/types/common';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { ActionItems, LATANA_NAME } from 'settings/constants';

export const useActionMenu = (isInternalUser: boolean) => {
  const updatedActionMenu = useCallback(
    (actionMenu: SelectPopupItemProps[], itemOwner?: Owner) => {
      const isChartDeleteDisabled = itemOwner?.type === LATANA_NAME && !isInternalUser;
      return actionMenu?.map(action => ({
        ...action,
        ...(action.value === ActionItems.DELETE && { disable: isChartDeleteDisabled }),
      }));
    },
    [isInternalUser],
  );

  return updatedActionMenu;
};
