import React from 'react';
import { LatanaText } from 'common/components/LatanaText';
import { GPGreenArrowComponent, GPRedArrowComponent } from 'icons';
import { BpChartData, NetSentiment } from 'common/types/chart';
import {
  BpCell,
  BpBodyRow,
  BpBodyItem,
  BpSentimentContainer,
  SVGWrapper,
  TooltipContentWrapper,
  BpLegendItem,
  BpLegendIndicator,
  hexToRGBA,
} from 'modules/charts/components/BrandPerception/styled';
import { AVERAGE } from 'settings/constants';
import { parseSentiment } from 'modules/charts/components/BrandPerception/utils/calculateSentiments';
import Tooltip from 'common/components/Tooltip';
import { legendData } from 'views/BrandPerception';
import { coloursPalette } from 'theme/colours';
import { useTranslation } from 'react-i18next';

export interface BpRowChartData extends BpChartData {
  isShowPopulationNumbers: boolean;
}

interface SentimentProps {
  positive: NetSentiment;
  neutral: NetSentiment;
  negative: NetSentiment;
}

const TableRowCell: React.FC<{ sentimentValue: NetSentiment }> = ({ sentimentValue }) => (
  <BpBodyItem>
    <BpCell>
      <LatanaText color='gray900' variant='B2'>
        {parseSentiment(sentimentValue, true)}
      </LatanaText>
    </BpCell>
  </BpBodyItem>
);

const NumericTableContent: React.FC<SentimentProps> = ({ positive, neutral, negative }) => (
  <React.Fragment>
    <TableRowCell sentimentValue={positive} />
    <TableRowCell sentimentValue={neutral} />
    <TableRowCell sentimentValue={negative} />
  </React.Fragment>
);

const TooltipContent: React.FC<{ sentimentHash: Partial<NetSentiment> }> = ({ sentimentHash }) => (
  <TooltipContentWrapper>
    {legendData.map(({ color, key }) => (
      <BpLegendItem key={`legend-item-${key}`}>
        <BpLegendIndicator key={color} color={color} />
        <LatanaText color='gray0' variant='B3'>
          {key}
        </LatanaText>
        <LatanaText color='gray0' variant='B3'>
          {sentimentHash[key.toLowerCase() as keyof NetSentiment]}%
        </LatanaText>
      </BpLegendItem>
    ))}
  </TooltipContentWrapper>
);

const PercentageTableContent: React.FC<SentimentProps> = ({ positive, neutral, negative }) => {
  const sentimentTable = {
    positive: positive.value || 0,
    neutral: neutral.value || 0,
    negative: negative.value || 0,
  };
  return (
    <BpBodyItem>
      <Tooltip renderInBody content={<TooltipContent sentimentHash={sentimentTable} />}>
        <SVGWrapper>
          <svg width='100%' height='8' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0' width={`${sentimentTable.positive}%`} height='8' fill={hexToRGBA(coloursPalette.green700, 0.5)} rx='4' ry='4' />
            <rect
              x={`${sentimentTable.positive}%`}
              width={`${sentimentTable.neutral}%`}
              height='8'
              fill={hexToRGBA(coloursPalette.gray200, 0.5)}
            />
            <rect
              x={`${sentimentTable.positive + sentimentTable.neutral}%`}
              width={`${sentimentTable.negative}%`}
              height='8'
              fill={hexToRGBA(coloursPalette.red600, 0.5)}
              rx='4'
              ry='4'
            />
          </svg>
        </SVGWrapper>
      </Tooltip>
    </BpBodyItem>
  );
};

const BrandPerceptionRow: React.FC<BpRowChartData> = ({
  index,
  positive,
  neutral,
  negative,
  netSentiment,
  from1MonthAgo,
  fromNMonthAgo,
  isShowPopulationNumbers,
}) => {
  const { t } = useTranslation();

  const renderArrow = (value: number | null): JSX.Element | null => {
    if (!value) return null;
    return value > 0 ? <GPGreenArrowComponent /> : <GPRedArrowComponent />;
  };

  return (
    <BpBodyRow isAverage={index === AVERAGE}>
      <BpBodyItem>
        <LatanaText color='gray900' variant='T3'>
          {index}
        </LatanaText>
      </BpBodyItem>
      {isShowPopulationNumbers ? (
        <NumericTableContent positive={positive} neutral={neutral} negative={negative} />
      ) : (
        <PercentageTableContent positive={positive} neutral={neutral} negative={negative} />
      )}
      <BpBodyItem>
        <BpCell>
          <Tooltip
            content={
              <TooltipContentWrapper>
                <LatanaText color='gray0' variant='B3'>
                  {t('brandPerception.tooltipText')
                    .split('|')
                    .map((text, index) => (
                      <LatanaText color='gray0' variant='B3' key={index}>
                        {text}
                        <br />
                      </LatanaText>
                    ))}
                </LatanaText>
              </TooltipContentWrapper>
            }
          >
            <BpSentimentContainer color={netSentiment.state as string}>
              <LatanaText color='gray900' variant='T3'>
                {parseSentiment(netSentiment, false)}
              </LatanaText>
            </BpSentimentContainer>
          </Tooltip>
        </BpCell>
      </BpBodyItem>
      <BpBodyItem>
        <BpCell>
          {renderArrow(from1MonthAgo.value)}
          <Tooltip content={from1MonthAgo.value ? <TooltipContent sentimentHash={from1MonthAgo} /> : null}>
            <LatanaText color='gray900' variant='B2'>
              {parseSentiment(from1MonthAgo, false)}
            </LatanaText>
          </Tooltip>
        </BpCell>
      </BpBodyItem>
      <BpBodyItem>
        <BpCell>
          {renderArrow(fromNMonthAgo?.value)}
          <Tooltip content={fromNMonthAgo?.value ? <TooltipContent sentimentHash={fromNMonthAgo} /> : null}>
            <LatanaText color='gray900' variant='B2'>
              {parseSentiment(fromNMonthAgo, false)}
            </LatanaText>
          </Tooltip>
        </BpCell>
      </BpBodyItem>
    </BpBodyRow>
  );
};

export default BrandPerceptionRow;
