import React from 'react';
import { GraphicsContainer } from '@nivo/bar';
import { useTooltip } from '@nivo/tooltip';
import { ChartNumbersType, ComparisonBar, TBarTooltip } from 'common/types/chart';
import { useTooltipContext } from 'modules/charts/context/TooltipContext';
import ChartTooltip from '../ChartTooltip';
import TooltipRenderer from '../ChartTooltip/TooltipRenderer';
import { getAverageColor } from 'modules/charts/utils/colors';
import { useAverage } from 'modules/charts/hooks/useAverage';

interface Props {
  bar: ComparisonBar;
  groupItems?: ComparisonBar[];
  groupIndex?: number | undefined;
  stroke?: string;
  moeEnabled?: boolean;
  locationOnAxes?: {
    x1?: number;
    x2?: number;
    y1?: number;
    y2?: number;
  };
  barId: number;
  chartNumbersType: ChartNumbersType;
}

const ReferenceLayer = ({
  bar,
  groupIndex,
  stroke = getAverageColor(),
  moeEnabled = false,
  locationOnAxes,
  barId,
  chartNumbersType,
}: Props) => {
  const { tooltipGroupId } = useTooltipContext();
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const averageValue = bar?.data?.data?.[`value_${barId}_averageValue` as 'value_0_averageValue'] || 0;
  const averagePopulationValue = bar?.data?.data?.[`value_${barId}_averagePopulationValue` as 'value_0_averagePopulationValue'] || null;
  const averageCalculatedFrom = useAverage(barId, bar?.data?.data?.tooltip as TBarTooltip, groupIndex);

  const handleTooltipEnter = React.useCallback(() => {
    if (tooltipGroupId) {
      tooltipGroupId.current = bar.data?.index;
    }
  }, [tooltipGroupId, bar.data]);

  const handleTooltipLeave = React.useCallback(() => {
    if (tooltipGroupId) {
      tooltipGroupId.current = undefined;

      setTimeout(() => {
        if (tooltipGroupId.current === undefined) {
          hideTooltip();
        }
      }, 0);
    }
  }, [tooltipGroupId, hideTooltip]);

  const renderTooltip = React.useMemo(() => {
    return (
      <TooltipRenderer onMouseLeave={handleTooltipLeave} onMouseEnter={handleTooltipEnter} x={bar.x} y={Number(locationOnAxes?.y1)}>
        <ChartTooltip
          funnel={bar?.data?.data[`funnel_${bar.data.id}_tooltip` as 'funnel_0_tooltip']}
          tooltip={bar.data.data.tooltip}
          moeEnabled={moeEnabled}
          averageValue={averageValue}
          averageCalculatedFrom={averageCalculatedFrom}
          averagePopulationValue={averagePopulationValue}
          chartNumbersType={chartNumbersType}
        />
      </TooltipRenderer>
    );
  }, [
    handleTooltipLeave,
    handleTooltipEnter,
    bar,
    averageCalculatedFrom,
    averageValue,
    moeEnabled,
    locationOnAxes,
    chartNumbersType,
    averagePopulationValue,
  ]);

  const handleMouseEnter = (e: React.MouseEvent<GraphicsContainer, MouseEvent>) => {
    if (tooltipGroupId && bar.data) tooltipGroupId.current = bar.data.index;
    showTooltipFromEvent(renderTooltip, e);
  };

  const handleMouseLeave = () => {
    if (tooltipGroupId) {
      tooltipGroupId.current = undefined; // remove hovered tooltip
      hideTooltip();
    }
  };

  return (
    <line
      key={`averageGroupLine-${groupIndex || barId}`}
      stroke={stroke}
      strokeWidth='2'
      strokeLinecap='round'
      {...locationOnAxes}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default React.memo(ReferenceLayer);
