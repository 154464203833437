import { useInfiniteQuery } from '@tanstack/react-query';
import { chartPaginationPayload } from 'common/atoms/charts';
import { fetchSelector } from 'common/atoms/common';
import { RawChartSchema } from 'common/types/chart';
import { PaginatedCharts, ChartPayload } from 'common/types/charts';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { API_URLS } from 'settings/api';

export const useGetPaginatedCharts = () => {
  const { fetchPost } = useRecoilValue(fetchSelector);
  const filterCriteria = useRecoilValue(chartPaginationPayload);

  const fetchPaginatedCharts = async (fiterCriteria: ChartPayload, page: number) => {
    return await fetchPost<PaginatedCharts>(API_URLS.BETA_CHARTS, { ...fiterCriteria, page });
  };

  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['paginatedCharts', filterCriteria],
    queryFn: ({ pageParam }) => fetchPaginatedCharts(filterCriteria, pageParam || 1),
    getNextPageParam: ({ pagination }) => pagination?.next_page || undefined,
    refetchOnWindowFocus: false,
  });

  const paginatedChartList = React.useMemo(() => {
    if (!data || !data.pages) return [];
    return data.pages.reduce((acc: RawChartSchema[], page) => [...acc, ...page.records], []);
  }, [data]);

  return { paginatedChartList, ...rest };
};
