import styled from '@emotion/styled';
import { LatanaText } from 'common/components/LatanaText';

export const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ToastContainer = styled.div`
  width: 346px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.v2.colours.gray0};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.17);
  display: flex;
  flex-direction: column;
`;

export const ToastBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 8px;
`;

export const ToastContent = styled.div`
  display: flex;
  gap: 16px;
`;

export const ToastContentText = styled(LatanaText)`
  word-break: break-word;
`;

export const ToastContentIcon = styled.div`
  min-width: 16px;
`;

export const CloseButton = styled.div`
  min-width: 16px;
  cursor: pointer;
`;
