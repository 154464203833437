import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { Theme } from '@emotion/react';
import * as React from 'react';
import Spinner from '../Spinner';
import Color from 'common/theme/color';
import { DataTestId } from 'settings/constants';

export interface ButtonProps {
  color?: ButtonColors;
  pill?: boolean;
  pseudo?: boolean;
  shadow?: boolean;
  size?: 'small' | 'medium';
  inverted?: boolean;
  stretch?: boolean;
  align?: 'left' | 'center' | 'right';
  blend?: boolean;
  hoverColor?: string;
  bubble?: boolean;
  square?: boolean;
  opacityHover?: boolean;
  isButtonV2?: boolean;
}

interface LoadingButtonProps extends ButtonProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  color: ButtonColors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
}

type ButtonColors = Extract<
  keyof Theme['colors'],
  'lightPurple' | 'black05' | 'black' | 'turqoise' | 'lightTurqoise' | 'wine' | 'white' | 'gray100' | 'indigo50'
>;

interface ColorShade {
  text: {
    static: Color | string;
    hover: Color | string;
    active: Color | string;
    pseudo: Color | string;
  };
  bg: {
    static: Color | string;
    hover: Color | string;
    active: Color | string;
    blend: Color | string;
  };
  disabled?: string;
}

const colorShades: Record<ButtonColors, ColorShade> = {
  lightPurple: {
    text: {
      static: theme.colors.white,
      active: theme.colors.white,
      hover: theme.colors.white,
      pseudo: theme.colors.lightPurple,
    },
    bg: {
      static: theme.colors.lightPurple,
      hover: theme.colors.lightPurple,
      active: theme.colors.lightPurple,
      blend: theme.colors.lightPurple2,
    },
    disabled: '0.25',
  },
  black05: {
    text: {
      static: theme.colors.black,
      active: theme.colors.white,
      hover: theme.colors.black,
      pseudo: theme.colors.black,
    },
    bg: {
      static: theme.colors.black05,
      hover: theme.colors.black10,
      active: theme.colors.black,
      blend: theme.colors.black,
    },
  },
  black: {
    text: {
      static: theme.colors.white,
      active: theme.colors.white,
      hover: theme.colors.white,
      pseudo: theme.colors.black,
    },
    bg: {
      static: theme.colors.black05,
      hover: theme.colors.black,
      active: theme.colors.black,
      blend: theme.colors.black,
    },
  },
  turqoise: {
    text: {
      static: theme.colors.white,
      active: theme.colors.white,
      hover: theme.colors.white,
      pseudo: theme.colors.turqoise,
    },
    bg: {
      static: theme.colors.turqoise,
      hover: new Color('#048f88'),
      active: theme.colors.turqoise,
      blend: theme.colors.turqoise,
    },
  },
  lightTurqoise: {
    text: {
      static: theme.colors.white,
      active: theme.colors.turqoise,
      hover: theme.colors.white,
      pseudo: theme.colors.turqoise,
    },
    bg: {
      static: theme.colors.turqoise,
      hover: new Color('#048f88'),
      active: theme.colors.lightTurqoise,
      blend: theme.colors.turqoise,
    },
  },
  wine: {
    text: {
      static: theme.colors.white,
      active: theme.colors.white,
      hover: theme.colors.white,
      pseudo: theme.colors.wine,
    },
    bg: {
      static: theme.colors.wine,
      hover: theme.colors.wine,
      active: theme.colors.wine,
      blend: theme.colors.wine,
    },
  },
  white: {
    text: {
      static: theme.colors.black,
      active: theme.colors.black,
      hover: theme.colors.black,
      pseudo: theme.colors.wine,
    },
    bg: {
      static: theme.colors.white,
      hover: theme.colors.white,
      active: theme.colors.white,
      blend: theme.colors.white,
    },
  },
  gray100: {
    text: {
      static: theme.v2.colours.gray900,
      active: theme.v2.colours.gray900,
      hover: theme.v2.colours.gray900,
      pseudo: theme.v2.colours.gray900,
    },
    bg: {
      static: theme.v2.colours.gray100,
      hover: theme.v2.colours.gray100,
      active: theme.v2.colours.gray100,
      blend: theme.v2.colours.gray100,
    },
  },
  indigo50: {
    text: {
      static: new Color(102, 102, 102, 1),
      active: new Color(102, 102, 102, 1),
      hover: new Color(102, 102, 102, 1),
      pseudo: new Color(102, 102, 102, 1),
    },
    bg: {
      static: theme.v2.colours.indigo50,
      hover: theme.v2.colours.indigo50,
      active: theme.v2.colours.indigo50,
      blend: theme.v2.colours.indigo50,
    },
  },
};
const shadowStyles = `
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
`;

const pseudoStyles = `
  background: none;
  color: var( --text-pseudo-color );
`;

const invertedStyles = `
  background: transparent;
  border: 1px solid var( --bg-static-color );
  color: var( --bg-static-color );
`;

const blendStyles = `
  border-color: ${theme.colors.white25.toString()};
  color: #fff;
  &:hover {
    background: var( --bg-blend-color );
  }
`;

const bubbleStyles = `
  padding: ${theme.space(2)};
  min-width: 0;
  min-height: 0;
  width: auto;
  height: auto;
  line-height: 0;
`;

const squareStyles = `
  height: 34px;
  border-radius: 3px;
  padding: ${theme.space(1, 2, 1, 2)};
  --size: none;
`;

const opacityHoverStyles = `
  &:hover:not(:disabled):not([aria-selected="true"]){
    background: none;
    opacity: 0.75;
    color: var( --text-pseudo-color );
  }
`;

const buttonV2Style = `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.v2.space(1.25, 3)};
`;

const btnSizes = {
  small: '3.6rem',
  medium: '4.8rem',
};

export const Button = styled.button<ButtonProps>`
  ${({
    color = 'lightPurple',
    theme,
    size = 'medium',
    pill = true,
    shadow = false,
    pseudo = false,
    inverted = false,
    stretch = false,
    align = 'center',
    blend = false, // white transparent border, for purple bgs
    hoverColor = '',
    bubble = false,
    square = false,
    opacityHover = false,
    isButtonV2 = false,
  }) => `
      ${shadow ? shadowStyles : ''}
      --size: ${isButtonV2 ? '2rem' : btnSizes[size]};
      --bg-static-color: ${colorShades[color].bg.static.toString()};
      --bg-hover-color: ${colorShades[color].bg.hover.toString()};
      --bg-blend-color: ${colorShades[color].bg.blend.toString()};
      --bg-hover-color: ${hoverColor ? hoverColor : colorShades[color].bg.hover.toString()};
      --bg-active-color: ${colorShades[color].bg.active.toString()};
      --text-static-color: ${colorShades[color].text.static.toString()};
      --text-hover-color: ${colorShades[color].text.hover.toString()};
      --text-active-color: ${colorShades[color].text.active.toString()};
      --text-pseudo-color: ${colorShades[color].text.pseudo.toString()};
      --disabled-opacity: ${colorShades[color].disabled ?? '0.72'};
			font-size: 1.4rem;
			font-family: ${theme.text.fontFamily};
      border-radius: ${pill ? 'var( --size )' : '8px'};
      min-width: ${window ? theme.spacing.unit * 12 : 0}px;
      padding: 0 ${theme.spacing.unit * 2}px;
      line-height: var( --size );
      color: var( --text-static-color );
      border: none;
      font-weight: 600;
      background: var( --bg-static-color );
      text-align: ${align};
      cursor: pointer;
      white-space: nowrap;
      ${pseudo ? pseudoStyles : ''}
      &:hover:not(:disabled):not([aria-selected="true"]){
        background: var( --bg-hover-color );
        color: var( --text-hover-color );
      }
      &[aria-selected='true']{
        background: var( --bg-active-color );
        color: var( --text-active-color );
      }
      ${inverted ? invertedStyles : ''}
      ${blend ? blendStyles : ''}
      ${bubble ? bubbleStyles : ''}
      ${square ? squareStyles : ''}
      ${opacityHover ? opacityHoverStyles : ''}
      width: ${stretch ? '100%' : 'auto'};
      &:disabled {
        opacity: var( --disabled-opacity );
        cursor: default;
      }
      &:focus {
        outline: none;
      }
      ${isButtonV2 ? buttonV2Style : ''}
  `}
`;
// Remove data-test-id only if it is not being used in Appcues.
export const LoadingButton: React.FC<LoadingButtonProps> = React.forwardRef<HTMLInputElement, LoadingButtonProps>(
  ({ children, isButtonV2 = false, loading, color = 'lightPurple', ...other }, ref) => {
    return (
      <Button isButtonV2={isButtonV2} data-testid={DataTestId.SAVE_BUTTON} ref={ref} color={color} {...other}>
        {loading ? <Spinner size='extraSmall' color={colorShades[color].text.static.toString() as keyof Theme['colors']} /> : children}
      </Button>
    );
  },
);

LoadingButton.displayName = 'LoadingButton';
