import React from 'react';
import { CardBodyItem, CardComponent, CardTypes } from 'common/types/dashboard';
import InsightRenderer from 'modules/dashboards/components/InsightRenderer';
import { RawChart } from 'common/types/chart';
import ChartRenderer from 'modules/charts/components/ChartRenderer';
import Spinner from 'common/components/Spinner';
import { LoadingContainer } from 'common/styledComponents/containers';

interface Props {
  chart: RawChart | undefined;
  content: CardComponent;
  editMode?: boolean;
}

const CardBody = ({ chart, content, editMode }: Props): JSX.Element | null => {
  const cardInsights = content.body.reduce((texts, bodyItem) => {
    if (bodyItem.type === CardTypes.TEXT && bodyItem.text) texts.push(bodyItem.text);
    return texts;
  }, [] as string[]);

  const renderBody = (body: CardBodyItem[]) => {
    switch (body[0].type) {
      case CardTypes.CHART:
        return chart ? (
          <ChartRenderer
            studyId={chart.chart.study_uuid}
            loadedChartData={chart}
            isShowToolbar={!editMode}
            hideDataTable={true}
            inPage={true}
          />
        ) : (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        );
      case CardTypes.TEXT:
        return <InsightRenderer insights={cardInsights} />;
      default:
        return null;
    }
  };

  return renderBody(content.body);
};

export default CardBody;
