import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { Button as DefaultButton } from 'common/components/Form/Button';
import { Text } from 'common/styledComponents/typography';
import { IconButtonTooltipWrapper } from 'common/components/Tooltip/styled';

const ModalContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  max-height: 100%;
  overflow: hidden;
`;

const ExpandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out, margin 0.2s ease-in-out;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  margin: 0;
  opacity: 0;
  &[aria-hidden='true'] {
    ${ModalContainer} {
      display: none;
    }
    pointer-events: none;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.space(2, 3)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury.toString()};
`;

const ModalContent = styled.div`
  flex-grow: 1;
  max-height: 100%;
  overflow: auto;
  padding: ${theme.space(2, 3)};
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.space(1.5, 3)};
  border-top: 1px solid rgba(235, 235, 235, 1);
  &:empty {
    display: none;
  }
`;

const KPITypeSwitcherWrapper = styled.div`
  height: 36px;
  display: flex;
`;

const KPITypeSwitcherLeft = styled.div`
  height: 36px;
  width: 110px;

  background: ${({ theme }) => theme.colors.white.toString()};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey.toString()};
  box-sizing: border-box;
  border-radius: 26px 0px 0px 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  border-right: none;

  &[data-active='true'] {
    background: ${({ theme }) => theme.colors.black.toString()};
    color: ${({ theme }) => theme.colors.white.toString()};
  }
`;

const KPITypeSwitcherRight = styled(KPITypeSwitcherLeft)`
  border-radius: 0px 26px 26px 0px;
  border-left: none;
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey.toString()};
`;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(245, 244, 255, 1);
  padding: 15px 25px;
  span {
    cursor: pointer;
  }
`;

const ActionButton = styled(DefaultButton)`
  border-radius: 3px;
  height: 36px;
  min-width: 77px;
  padding: 8px 16px;
  line-height: 0;
  margin: ${theme.space(0, 0, 0, 1)};
  font-weight: 400;
  &:disabled {
    color: rgba(207, 205, 230, 1);
    cursor: default;
  }
`;

const CancelButton = styled(ActionButton)`
  background-color: transparent;
  border: 1px solid rgba(226, 226, 226, 1);
  margin: ${theme.space(0, 1, 0, 0)};
  &:hover:not(:disabled):not([aria-selected='true']) {
    background: transparent;
    color: black;
  }
`;

const AddSegmentButton = styled(ActionButton)`
  background-color: transparent;
  color: rgba(165, 115, 255, 1);
  border: 1px solid rgba(210, 185, 255, 1);
  display: flex;
  align-items: center;
  img {
    margin: ${theme.space(0, 1, 0, 0)};
  }
  &:hover:not(:disabled):not([aria-selected='true']) {
    background: transparent;
    color: rgba(165, 115, 255, 1);
  }
`;

const ShowMoreButton = styled(ActionButton)`
  margin: 0;
`;

const Container = styled.section`
  &[aria-hidden='true'] {
    max-height: 0px;
    overflow: hidden;
  }
`;

const OptionsContainer = styled.div<{
  columns: number;
  rows: number;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &[aria-hidden='true'] {
    display: none;
  }
  &[data-all='true'] {
    max-height: calc(${({ rows }) => rows} * 48px);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${theme.space(3, 0, 0, 0)};
`;

export const AllButtonContainer = styled.div`
  display: flex;
`;

const GroupTitle = styled(Text)`
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 16px;
  min-height: 46px;
  max-width: 240px;
  font-size: 14px;
  padding: ${theme.space(0, 2)};
  color: ${({ theme }) => theme.v2.colours.gray500};
  break-inside: avoid-column;
  overflow: hidden;
  &:empty {
    display: none;
  }

  .tooltip-content {
    margin-left: ${({ theme }) => theme.v2.space(1)};
  }
`;

const GroupTitleWrapper = styled.div<{ horizontal: boolean }>`
  ${({ horizontal, theme }) =>
    horizontal &&
    `
  flex-basis: 100%;
  
  ${GroupTitle} {
    overflow: visible;
  }

  ${IconButtonTooltipWrapper} {
    height: auto;
    max-width: 244px;
  }

  h6 {
    padding-left: ${theme.v2.space(1)};
  }
  `}
`;

const GroupTitleInner = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ColumnContainer = styled.div<{ horizontal: boolean }>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  :not(:last-child) {
    margin: ${theme.space(0, 2, 0, 0)};
  }

  ${({ horizontal }) =>
    horizontal &&
    `
  flex-wrap: wrap;
  flex-basis: 100%;
  position: relative;
  `}
`;

const Space = styled.div<{
  size: number;
}>`
  width: 60px;
  height: ${({ size }) => size * 46}px;
`;

const ReferenceToggleContainer = styled.div`
  display: flex;

  > p {
    margin-left: ${theme.space(1)};
  }
`;

const MissingABrandWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.v2.space(3)};
  top: ${({ theme }) => theme.v2.space(1)};
  display: flex;
  align-items: center;

  .tooltip-children {
    gap: ${({ theme }) => theme.v2.space(1)};
    align-items: center;
  }

  ${IconButtonTooltipWrapper} {
    height: auto;
    max-width: 244px;
    font-size: 12px;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export {
  ModalContainer,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ExpandingContainer,
  KPITypeSwitcherWrapper,
  KPITypeSwitcherLeft,
  KPITypeSwitcherRight,
  BannerContainer,
  ActionButton,
  CancelButton,
  AddSegmentButton,
  Container,
  OptionsContainer,
  ButtonContainer,
  ShowMoreButton,
  GroupTitle,
  GroupTitleInner,
  ColumnContainer,
  Space,
  ReferenceToggleContainer,
  GroupTitleWrapper,
  MissingABrandWrapper,
  SpinnerContainer,
};
