import { useMemo } from 'react';
import { FilterOption } from 'common/types/filters';
import { KPIOptionType } from 'common/types/common';

export const useFiltersOptions = (options: FilterOption[] | undefined) => {
  return useMemo(() => {
    return options?.reduce((acc: any, option: any) => {
      if (option.type === KPIOptionType.GROUP) {
        option.options.forEach((option: any) => {
          const { value, filterLabel, label, color } = option;
          acc[value] = {
            label: filterLabel ?? label,
            value,
            color,
          };
        });
      } else {
        const { value, filterLabel, label, color } = option;
        acc[value] = {
          label: filterLabel ?? label,
          value,
          color,
        };
      }
      return acc;
    }, {});
  }, [options]);
};
