import React from 'react';
import { useGetAllBrands } from 'common/queries/brands';
import { useGetAllCountries } from 'common/queries/countries';
import { BpIndexMap } from 'common/types/chart';
import { useRawSegments } from 'common/queries/segments';

export const useIndexMap = (): BpIndexMap => {
  const { data: brandsList, isLoading: isBrandsLoading } = useGetAllBrands();
  const { data: segmentsList, isLoading: isSegmentsLoading } = useRawSegments();
  const { data: countriesList, isLoading: isCountriesLoading } = useGetAllCountries();

  const countries = React.useMemo(() => {
    if (isCountriesLoading || !countriesList) return [];
    return countriesList?.flatMap(({ countries }) => countries.map(({ id, name }) => ({ [id]: name }))) ?? [];
  }, [countriesList, isCountriesLoading]);

  const brands = React.useMemo(() => {
    if (!brandsList) return [];
    const brandsById = brandsList.map(({ id, name }) => ({ [id]: name }));
    return brandsById;
  }, [brandsList]);

  const segments = React.useMemo(() => {
    if (!segmentsList) return [];
    return segmentsList?.flatMap(({ segments }) => segments.map(({ id, name }) => ({ [id]: name }))) ?? [];
  }, [segmentsList]);

  const isLoading = React.useMemo(
    () => isBrandsLoading || isSegmentsLoading || isCountriesLoading,
    [isBrandsLoading, isSegmentsLoading, isCountriesLoading],
  );

  return React.useMemo(
    () => ({ country: countries, brand: brands, segment: segments, isLoading }),
    [countries, brands, segments, isLoading],
  );
};
