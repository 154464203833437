import { SortType } from 'common/types/chart';
import * as React from 'react';
import { SortingControlContainer } from '../GrowthPerformance/styled';

const SVGArrowUp = ({ color }: { color: string }) => (
  <svg width='8' height='8' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 6 4'>
    <path
      fill={color}
      d='M.035 3.612C.1 3.845.3 4 .5 4h5c.2 0 .367-.155.467-.388.066-.195.033-.466-.134-.622L3.468.233a.632.632 0 0 0-.5-.233.683.683 0 0 0-.5.233L.1 2.99a.798.798 0 0 0-.066.622Z'
    />
  </svg>
);

const SVGArrowDown = ({ color }: { color: string }) => (
  <svg width='8' height='8' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 6 4'>
    <path
      fill={color}
      d='M5.965.388C5.9.155 5.7 0 5.5 0h-5c-.2 0-.367.155-.467.388-.066.195-.033.466.134.622l2.366 2.757c.133.155.3.233.5.233a.683.683 0 0 0 .5-.233L5.9 1.01a.798.798 0 0 0 .066-.622Z'
    />
  </svg>
);

const sortConfig: SortConfig = {
  default: {
    up: '#CBCBCB',
    down: '#CBCBCB',
  },
  ascending: {
    up: '#8C8C8C',
    down: '#CBCBCB',
  },
  descending: {
    up: '#CBCBCB',
    down: '#8C8C8C',
  },
};

interface ArrowStates {
  up: string;
  down: string;
}

interface SortConfig {
  default: ArrowStates;
  ascending: ArrowStates;
  descending: ArrowStates;
}

const SortingControl: React.FC<{ sortType: SortType | null }> = ({ sortType }) => {
  return (
    <SortingControlContainer>
      <SVGArrowUp color={sortConfig[sortType as keyof SortConfig].up} />
      <SVGArrowDown color={sortConfig[sortType as keyof SortConfig].down} />
    </SortingControlContainer>
  );
};

export default React.memo(SortingControl);
