import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { LogArgument } from 'rollbar';
import { useRollbar } from '@rollbar/react';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { useGetBrandGroups } from 'common/queries/brandGroups';
import { BrandGroups, BrandGroupType } from 'common/types/common';
import { getFilteredChartCountryCodes, getBrandIds } from 'utils/brands';
import { useQueryClient } from '@tanstack/react-query';
import { useGetCountriesMap } from 'common/hooks/countries';

type CompetitorsForm = Record<string, Array<number>>;
type UpdateCompetitors = {
  requestState: boolean;
  updateCreateBrandGroup: (formData: CompetitorsForm, brandGroupID?: string) => void;
};

export const useUpdateCreateBrandGroup = (): UpdateCompetitors => {
  const rollbar = useRollbar();
  const [requestState, setRequestState] = React.useState(false);
  const { fetchPut, fetchPost } = useRecoilValue(fetchSelector);
  const queryClient = useQueryClient();

  /** If brandGroupID is undefined or missing, trigger POST request instead of put */
  const updateCreateBrandGroup = async (formData: CompetitorsForm, brandGroupID?: string) => {
    try {
      setRequestState(true);
      await (brandGroupID ? fetchPut : fetchPost)<CompetitorsForm>(API_URLS.UPDATE_BRAND_GROUPS(brandGroupID ?? ''), {
        brand_ids_by_country: formData,
        ...(!brandGroupID && { group_type: 'competitors' }), // Hardcode competitors for now, because right now we can only add competitors brands, primary group type will come predefined
      });
      await queryClient.invalidateQueries(['brandGroups']);
      setRequestState(false);
    } catch (e: unknown) {
      setRequestState(false);
      rollbar.error('Update brand_groups error:', e as LogArgument);
    }
  };

  return { requestState, updateCreateBrandGroup };
};

export const useGetCompetitorsGroup = (): BrandGroups | undefined => {
  const { data: brandsGroup } = useGetBrandGroups();

  return useMemo(() => {
    return brandsGroup?.find(({ group_type }) => group_type === BrandGroupType.COMPETITORS);
  }, [brandsGroup]);
};

export const useBrandCompetitorsByCountry = (countryIds: Array<number>): Array<number> => {
  const competitorGroup = useGetCompetitorsGroup();
  const countriesMap = useGetCountriesMap();

  const chartCountryCodes = useMemo(() => {
    return getFilteredChartCountryCodes(countriesMap, countryIds);
  }, [countryIds, countriesMap]);

  const brandIds = useMemo(() => {
    return getBrandIds(competitorGroup, chartCountryCodes);
  }, [chartCountryCodes, competitorGroup]);

  return brandIds;
};
