import { ChartType } from 'common/types/chart';
import { Country, WavePayload, Wave, Brand, KPI } from 'common/types/common';
import { SegmentGroups, Segment } from 'common/types/segments';

export type FetchFilters = {
  filters: FiltersState | undefined;
  loading: boolean;
  fetchFilters: (filters: Record<string, number[]>) => Promise<FiltersState | undefined>;
};

export interface FiltersPayload {
  waves: WavePayload[];
  countries: Country[];
  brands: Brand[];
}

export interface Filters {
  waves: Wave[];
  countries: Country[];
  brands: Brand[];
  segmentList: Segment[];
}

export interface IFilterOption {
  value: number | string;
  label: string;
  // will be used for selected values in filter tabs, since there can be multiple options
  // with the same name, for example month names
  filterLabel?: string;
  type: 'option';
  // control option order
  optionOrder?: number;
  readOnly?: boolean;
}

export interface IFilterOptionGroup {
  label: string;
  options: IFilterOption[];
  type: 'group';
  infoMessage?: string;
}

export type FilterOption = IFilterOptionGroup | IFilterOption;

export interface IFilter {
  label: string;
  id: FilterNames;
  optionsOrder?: (string | number)[];
  options: FilterOption[] | undefined;
}

export interface AllFiltersData extends Filters {
  kpis: Record<string, string>;
  segments: Segment[];
}

export enum FilterNames {
  COUNTRY = 'country',
  WAVE = 'wave',
  KPI = 'kpi',
  BRAND = 'brand',
  SEGMENT = 'segment',
}

export interface FiltersState {
  waves: Wave[];
  brands: Brand[];
  countries: Country[];
}

export interface FiltersOptions {
  availableFilters: FiltersPayload | undefined;
  brands: Brand[];
  segmentGroups: SegmentGroups[];
  kpis: KPI[];
  chartType: ChartType;
  selectedKPIFormulas: Record<string, string> | null;
}

export interface IFilterColumnOption extends IFilterOption {
  type: 'option';
  group?: IFilterOptionGroup;
}

interface IFilterColumnSpace {
  type: 'space';
  size: number;
}

export interface IFilterGroupOption {
  group: IFilterOptionGroup;
  label: string;
  type: string;
  value: number;
}

export type FilterColumnOption = IFilterOptionGroup | IFilterColumnOption | IFilterColumnSpace;

// [0] - all waves
// [1] - most recent
// [n] - specific amount of waves
export enum DynamicFilters {
  ALL_WAVES,
  LAST_WAVE,
}

export interface Restrictions extends FiltersPayload {
  study_uuid: string;
}

export type ActiveFilter = 'wave' | 'brand' | 'country';

export enum FilterOptionType {
  GROUP = 'group',
  OPTION = 'option',
  SPACE = 'space',
}

export interface RestrictionConfig {
  name: string;
  values: number[];
}

export interface OptionsValues {
  country: number[];
  wave: number[];
  kpi: (string | number)[];
  segment: number[];
  brand: number[];
}
export interface Options {
  countries: Country[];
  brands: Brand[];
  waves: Wave[];
  segments: Segment[];
  kpis: KPI[];
  selectedKPIFormulas: Record<string, string> | null;
}
