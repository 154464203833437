import React, { FC } from 'react';
import { Button } from 'common/components/Form/Button';
import Modal from 'common/components/Modal';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/styledComponents/typography';
import { ButtonWrapper, Content } from 'common/styledComponents/modals';

interface modalProps {
  showPanel: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  isCreate: boolean;
}

const CancelChartModal: FC<modalProps> = ({ showPanel, handleCancel, handleConfirm, isCreate }) => {
  const { t } = useTranslation();

  const { titleKey, headingKey, subheadingKey } = React.useMemo(() => {
    const key = isCreate ? 'create' : 'update';
    return {
      titleKey: `chart.cancelModal.${key}.title`,
      headingKey: `chart.cancelModal.${key}.heading`,
      subheadingKey: `chart.cancelModal.${key}.subheading`,
    };
  }, [isCreate]);

  return (
    <Modal isOpen={showPanel} title={t(titleKey)} customWidth={564} handleClose={handleCancel} showCloseButton={false}>
      <Content>
        <Text variant='h5'>
          {t(headingKey)}
          <br />
          {t(subheadingKey)}
        </Text>
      </Content>
      <ButtonWrapper width='100%' justify='center'>
        <Button type='button' color='black05' onClick={handleConfirm}>
          {t('common.yes')}
        </Button>
        <Button type='button' color='black05' onClick={handleCancel}>
          {t('common.no')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default React.memo(CancelChartModal);
