import React, { ForwardedRef } from 'react';

const DEFAULT_BAR_Y_VALUE = 16;
const BAR_VALUE_CALC_COEF = 10;
const BAR_VALUE_OFFSET = -8;

interface BarValueProps {
  barWidth: number;
  barValue: number;
  moeHeight: number;
  yAxisValue: number;
  xAxisValue: number;
  moeStatus?: boolean;
  isNumericChart: boolean;
  shouldRotateLabel: boolean;
  populationValue: string | null;
  showPopulationNumbers: boolean;
  ref: ForwardedRef<SVGTextElement | null>;
}

const BarValue: React.FC<BarValueProps> = React.forwardRef(
  (
    {
      shouldRotateLabel,
      barValue,
      yAxisValue = DEFAULT_BAR_Y_VALUE,
      xAxisValue,
      populationValue,
      isNumericChart,
      showPopulationNumbers,
      moeHeight,
      moeStatus,
    },
    ref,
  ) => {
    const numericValue = React.useMemo(() => {
      return Math.round(barValue * BAR_VALUE_CALC_COEF) / BAR_VALUE_CALC_COEF;
    }, [barValue]);

    const getAxisValue = (value: number) => value + BAR_VALUE_OFFSET;

    const barValueTransform = React.useMemo(() => {
      return shouldRotateLabel ? `rotate(-90, ${getAxisValue(xAxisValue)}, ${getAxisValue(yAxisValue)})` : '';
    }, [shouldRotateLabel, xAxisValue, yAxisValue]);

    const [xAxisPosition, yAxisPosition] = React.useMemo(() => {
      const newXAxis = moeStatus ? (shouldRotateLabel ? xAxisValue + moeHeight : xAxisValue) : xAxisValue;
      const newYAxis = moeStatus ? (shouldRotateLabel ? yAxisValue : yAxisValue - moeHeight) : yAxisValue;
      return [newXAxis, newYAxis];
    }, [xAxisValue, yAxisValue, moeStatus, moeHeight, shouldRotateLabel]);

    const displayValue = React.useMemo(() => {
      if (showPopulationNumbers && populationValue) {
        return populationValue;
      }
      return `${numericValue}${!isNumericChart && !showPopulationNumbers ? '%' : ''}`;
    }, [showPopulationNumbers, populationValue, numericValue, isNumericChart]);

    return (
      <text
        ref={ref}
        y={yAxisPosition}
        x={xAxisPosition}
        textAnchor='middle'
        dominantBaseline='middle'
        transform={barValueTransform}
        style={{ fontSize: '13px', fontWeight: 900, fill: 'rgba(0, 0, 0, 1)', fontFamily: 'Mulish' }}
      >
        {displayValue}
      </text>
    );
  },
);

BarValue.displayName = 'BarValue';

export default BarValue;
