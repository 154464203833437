import React from 'react';
import styled from '@emotion/styled';
import SegmentView from 'modules/segments/components/SegmentView';
import SegmentSidebar from 'modules/segments/components/SegmentSidebar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Study } from 'common/types/account';
import { ActionTypes, FormStates, SegmentTabNames, Segment, Segments } from 'common/types/segments';
import { findSegmentById, getDefaultSegmentName } from 'utils/segments';
import { SEGMENTS_ROUTES } from 'settings/routes';
import { useSegmentsOutletContext } from 'modules/segments/hooks/context';

const SegmentsContainer = styled.div<{ isEditMode: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  transition: box-shadow 0.3s ease;
  border-radius: ${({ theme }) => theme.v2.borderRadius * 2}px;
  border: 1px solid ${({ theme }) => theme.colors.jupiter.toString()};
  box-shadow: ${({ isEditMode }) => (isEditMode ? '0px 4px 16px 0px rgba(0, 0, 0, 0.17)' : 'none')};
`;

interface SegmentsContentProps {
  studies?: Study[] | undefined;
  tabSegments: Segments[] | undefined;
}

const formReducer = (_state: FormStates, action: { type: ActionTypes }): FormStates => {
  switch (action.type) {
    case ActionTypes.SET_READ:
      return FormStates.VIEW;
    case ActionTypes.SET_WRITE:
      return FormStates.EDIT;
    case ActionTypes.SET_CREATE:
      return FormStates.CREATE;
    case ActionTypes.SET_DELETE:
      return FormStates.DELETE;
    default:
      return FormStates.VIEW;
  }
};

const SegmentsContent: React.FC<SegmentsContentProps> = ({ tabSegments, studies }): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const segmentTabName = params?.['*']?.includes('/') ? params?.['*'].split('/')[0] : params['*'];
  const segmentIdParam = params?.segmentId;
  const createNewSegment = params?.segmentId === 'new';

  const studyUuid = state?.studyId; // studyUuid propagated from study selection modal
  const [formState, dispatch] = React.useReducer(formReducer, FormStates.VIEW);
  const { setPreviousUrlPath, setStudyIdRedirect } = useSegmentsOutletContext();

  const isInEditMode = formState === FormStates.EDIT;
  const inInCreateMode = formState === FormStates.CREATE;
  const isLatanaSegmentsTab = segmentTabName === SegmentTabNames.LATANA;

  // if user came from study selection modal, then the selected study should be the one from the modal
  // otherwise, the selected study is a study selected from the sidebar dropdown
  const [selectedStudy, setSelectedStudy] = React.useState<string | null>(
    studyUuid ? studyUuid : studies?.length === 1 ? studies[0].uuid : null,
  );

  const segmentsList = React.useMemo(() => tabSegments?.[0]?.segments ?? null, [tabSegments]);

  const firstSegmentInTheList = React.useMemo(() => segmentsList?.[0] ?? null, [segmentsList]);

  const [studySegments, setStudySegments] = React.useState<Segment[] | null | undefined>(
    isLatanaSegmentsTab || selectedStudy ? segmentsList : null,
  );

  const [currentSegment, setCurrentSegment] = React.useState<Segment | null>(() => {
    return isLatanaSegmentsTab || selectedStudy ? firstSegmentInTheList : null;
  });

  const defaultSegmentName = React.useMemo(() => getDefaultSegmentName(studySegments ?? []), [studySegments]);

  const handleSegmentChange = React.useCallback(
    (segment: Segment | null) => {
      setCurrentSegment(segment);
      dispatch({ type: ActionTypes.SET_READ });
      navigate(`/segments/${segment?.readable_only ? SEGMENTS_ROUTES.LATANA : SEGMENTS_ROUTES.CUSTOM}/${segment?.id ?? ''}`);
    },
    [navigate],
  );

  React.useEffect(() => {
    if (isLatanaSegmentsTab) {
      handleSegmentChange(firstSegmentInTheList);
    }
  }, [isLatanaSegmentsTab, firstSegmentInTheList, handleSegmentChange]);

  const updateStudySegments = React.useCallback(
    (studyId, segmentSelected: Segment | null, isNew = false) => {
      const serializedSegments = isLatanaSegmentsTab
        ? segmentsList
        : (tabSegments || []).find(({ study_uuid }) => study_uuid === studyId)?.segments;
      setStudySegments(serializedSegments);

      if (!isNew) {
        handleSegmentChange(segmentSelected ? segmentSelected : serializedSegments?.[0] ?? null);
      }
    },
    [tabSegments, handleSegmentChange, isLatanaSegmentsTab, segmentsList],
  );

  /* When land to /segments/custom without an id, set default to first one*/
  React.useEffect(() => {
    if (!isLatanaSegmentsTab && !segmentIdParam && studyUuid) {
      updateStudySegments(studyUuid, null);
    }
  }, [updateStudySegments, isLatanaSegmentsTab, segmentIdParam, studyUuid]);

  React.useEffect(() => {
    if (studyUuid && createNewSegment) {
      updateStudySegments(studyUuid, null, true);
      setSelectedStudy(studyUuid);
      dispatch({ type: ActionTypes.SET_CREATE });
      navigate(`/segments/${SEGMENTS_ROUTES.CUSTOM}/new`, { replace: true });
    }
  }, [studyUuid, createNewSegment, dispatch, updateStudySegments, navigate]);

  /* When you open the segments page with passed segmentId, this is executed */
  React.useEffect(() => {
    if (segmentIdParam && !createNewSegment) {
      const segment = findSegmentById(tabSegments, Number(segmentIdParam));
      updateStudySegments(segment?.study_uuid, segment);
      setSelectedStudy(segment?.study_uuid ?? null);
    }
  }, [segmentIdParam, updateStudySegments, tabSegments, createNewSegment]);

  const handleStudyChange = React.useCallback(
    (studyId: unknown) => {
      setSelectedStudy(studyId as string);
      updateStudySegments(studyId, null);
    },
    [updateStudySegments],
  );

  /*  */
  React.useEffect(() => {
    if (state?.prevUrl) {
      setPreviousUrlPath(state?.prevUrl);
      setStudyIdRedirect(state?.studyId);
    }
  }, [setPreviousUrlPath, setStudyIdRedirect, state]);

  return (
    <SegmentsContainer isEditMode={isInEditMode || inInCreateMode}>
      <SegmentSidebar
        studies={studies}
        formState={formState}
        tabName={segmentTabName}
        handleSegmentClick={handleSegmentChange}
        formStateDispatch={dispatch}
        selectedStudy={selectedStudy}
        segments={studySegments ?? []}
        currentSegment={currentSegment}
        defaultSegmentName={defaultSegmentName}
        onChange={handleStudyChange}
      />
      <SegmentView
        study={selectedStudy}
        formState={formState}
        segment={currentSegment}
        tabName={segmentTabName}
        formStateDispatch={dispatch}
        defaultSegmentName={defaultSegmentName}
      />
    </SegmentsContainer>
  );
};

export default SegmentsContent;
