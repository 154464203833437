import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { useRecoilValue } from 'recoil';
import { WaveReport, WaveSummary } from 'common/types/common';
import React from 'react';
import { dateWithoutTimezone } from 'utils/dates';
import getRollbarConfig from 'rollbarConfig';
import { LogArgument } from 'rollbar';

const sortReportsByPeriod = (reports: WaveReport[]) => {
  return reports.sort((a, b) => {
    const aPeriod = typeof a.period === 'string' ? new Date(a.period) : a.period;
    const bPeriod = typeof b.period === 'string' ? new Date(b.period) : b.period;
    return bPeriod.getTime() - aPeriod.getTime();
  });
};

export const useGetWaveReports = (): UseQueryResult<WaveReport[], unknown> => {
  const { fetchGet } = useRecoilValue(fetchSelector);
  const rollbar = getRollbarConfig();
  return useQuery(
    ['waveReports'],
    async () => {
      try {
        const waveSummaries = await fetchGet<WaveSummary[]>(API_URLS.WAVE_SUMMARIES);
        return waveSummaries.flatMap(({ wave_summaries }) => wave_summaries);
      } catch (e: unknown) {
        rollbar.info('useGetWaveReports: GET wave_summaries failed', e as LogArgument);
      }
    },
    {
      staleTime: Infinity,
      placeholderData: [] as WaveReport[],
      select: React.useCallback((reports: WaveReport[] = []) => {
        return sortReportsByPeriod(reports).map(report => {
          const { period, link_to_pdf } = report;
          return {
            ...report,
            period: dateWithoutTimezone(new Date(period)),
            link_to_pdf: link_to_pdf.indexOf('://') === -1 ? 'https://' + link_to_pdf : link_to_pdf,
          } as WaveReport;
        });
      }, []),
    },
  );
};
