import * as React from 'react';
import { AppContainer, MainContent } from './styled';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { alertBoxAtom } from 'common/atoms/ui';
import AlertMessage from 'common/components/AlertMessage';
import CSVExportModal from 'common/components/CSVExportModal';
import { accountDetailsSelector } from 'common/atoms/account';
import { MoeConfidenceLevelsAtom } from 'common/atoms/moe';
import { useStudyContext } from 'common/contexts/StudyContext';
import ReportModal from 'modules/settings/components/WaveReports/ReportModal';
import StudySelectionModal from 'common/components/StudySelectionModal';
import ToastMessage from 'common/components/ToastMessage';
import SidebarNav from 'common/components/SidebarNav';
import { useGetWaveReports } from 'common/queries/waveReports';
import { useDashboardContext } from 'common/contexts/DashboardContext';
import DashboardChartsModal from 'common/components/Modal/DashboardChartsModal';
import { openNewTab } from 'utils/helpers';
import { useDashboardModalContent } from 'common/components/Layout/useDashboardModalContent';
import DashboardsModal from 'common/components/Modal/DashboardsModal';
import { useChartDashboardsContext } from 'common/contexts/ChartsContext';
import { useChartModalContent } from 'common/components/Layout/useChartModalContent';
import { useLocation } from 'react-router-dom';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [csvModalOpen, setCSVModalOpen] = React.useState(false);
  const [alertBox] = useRecoilState(alertBoxAtom);
  const { data: waveReports } = useGetWaveReports();
  const dashboardRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();

  const accountDetails = useRecoilValueLoadable(accountDetailsSelector);
  const setMoeConfidenceLevels = useSetRecoilState(MoeConfidenceLevelsAtom);

  React.useEffect(() => {
    if (accountDetails.state === 'hasValue' && accountDetails.contents?.preferences?.confidence_levels) {
      setMoeConfidenceLevels(accountDetails.contents?.preferences?.confidence_levels);
    }
  }, [accountDetails.state, accountDetails.contents, setMoeConfidenceLevels]);

  const renderDashboardModalContent = useDashboardModalContent();
  const chartModalContent = useChartModalContent();

  const { onToggleDashboardModal, isDashboardModalOpen } = useDashboardContext();
  const { isSelectionModalOpen } = useStudyContext();
  const { isModalOpen: isChartDashboardModalOpen, setModalOpen: togglelChartDashboardsModal } = useChartDashboardsContext();

  const newWaveReport = React.useMemo(() => {
    return waveReports?.find(({ is_new }) => is_new);
  }, [waveReports]);

  /** To scroll dashboard page up after redirect from dashboards list  **/
  React.useEffect(() => {
    const scrollToTop = () => {
      if (dashboardRef?.current) {
        dashboardRef?.current?.scrollTo(0, 0);
      }
    };
    scrollToTop();
  }, [location]);

  const handleOpenChart = React.useCallback((uuid: string) => {
    openNewTab(`/chart/${uuid}`);
  }, []);

  return (
    <AppContainer data-has-alert={alertBox?.show}>
      <AlertMessage />
      <SidebarNav />
      <CSVExportModal onClose={() => setCSVModalOpen(false)} open={csvModalOpen} />
      <MainContent ref={dashboardRef}>{children}</MainContent>
      {newWaveReport && <ReportModal report={newWaveReport} />}
      {isSelectionModalOpen && <StudySelectionModal isOpen={isSelectionModalOpen} />}
      <DashboardChartsModal
        isOpen={isDashboardModalOpen}
        toggleDashboardModal={onToggleDashboardModal}
        renderContent={modalContentProps => renderDashboardModalContent({ handleOpenChart, ...modalContentProps })}
      />
      <DashboardsModal
        isOpen={isChartDashboardModalOpen}
        toggleDashboardModal={togglelChartDashboardsModal}
        renderContent={chartModalContent}
      />
      <ToastMessage />
    </AppContainer>
  );
};

export default React.memo(Layout);
