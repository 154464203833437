import React from 'react';
import { useFloating, useInteractions, flip, shift, useClick, useDismiss, autoUpdate, Placement } from '@floating-ui/react';

interface Props {
  popupPlacement?: Placement;
  isDefaultOpen?: boolean;
}

const useFloatingMenu = ({ popupPlacement = 'bottom-end', isDefaultOpen = false }: Props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(isDefaultOpen);

  const { refs, floatingStyles, context } = useFloating({
    placement: popupPlacement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [flip(), shift()], // Takes care of automatic positioning
    whileElementsMounted: autoUpdate, // Keep popup attched to the referenced element
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return { refs, floatingStyles, getReferenceProps, getFloatingProps, isOpen, setIsOpen };
};

export default useFloatingMenu;
