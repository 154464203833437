import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TextVariants } from 'theme/typography';
import { LatanaText } from 'common/components/LatanaText';
import { representValuesInKsOrMs } from 'views/Gauge/utils';

const PercentageText = styled.text`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.v2.text.variants[TextVariants.L2].fontWeight};
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  fill: #666;
  letter-spacing: 0.24px;
  line-height: 16px;
`;

const Funnel = styled.polygon<{ color: string }>`
  stroke-linejoin: round;
  stroke-width: 2;
  fill: ${({ color }) => `${color}`};
  stroke: ${({ color }) => `${color}`};
`;

const Line = styled.line<{ color?: string }>`
  stroke-width: 2;
  stroke-linecap: round;
`;

const Bubble = styled.polygon`
  fill: white;
  stroke: white;
  stroke-linejoin: round;
  stroke-width: 2;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.4));
`;

const FunnelChartSvg = styled.svg`
  margin-bottom: 24px;
`;

const Label = styled(LatanaText)`
  margin-left: -40px;
  text-align: center;
`;

const FunnelContainer = styled.div`
  flex: 1;
  min-width: 0;
  max-width: 33%;
  position: relative;
  min-height: 280px;
  margin-left: ${({ theme }) => theme.v2.space(8)};
`;

interface FunnelData {
  percentage: number;
  color: string;
  population?: number;
}

interface Props {
  label: string;
  segment: FunnelData;
  awareness: FunnelData;
  consideration: FunnelData;
}

interface Point {
  x: number;
  y: number;
}

const FunnelElement = ({ label, segment, awareness, consideration }: Props): JSX.Element => {
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [parentHeight, setParentHeight] = useState<number>(0);
  const funnelContainerRef = React.useRef<HTMLDivElement>();
  const SVG_HEIGHT = parentHeight - 40;
  const SVG_WIDTH = parentWidth - 40;
  const awarenessTopValue = (segment.percentage - awareness.percentage) / 100;
  const considerationConversion = (consideration.percentage * awareness.percentage) / 100;
  const awarenessBottomValue = (segment.percentage - considerationConversion) / 100;
  const awarenessGlobalPopulation = (consideration.percentage / 100) * awareness.percentage;
  const bubbleShouldGoLeft = awarenessBottomValue * SVG_HEIGHT - awarenessTopValue * SVG_HEIGHT < 30;

  function getXForY(pointA: Point, pointB: Point, yValue: number) {
    const { x: x1, y: y1 } = pointA;
    const { x: x2, y: y2 } = pointB;

    // Calculate the slope (m)
    const slope = (y2 - y1) / (x2 - x1);

    // Calculate x for the given y using the rearranged equation
    const xValue = (yValue - y1) / slope + x1;

    return xValue;
  }

  function getXForYLeft(yValue: number) {
    const pointA = { x: 4, y: 0 };
    const pointB = { x: 28, y: SVG_HEIGHT };
    return getXForY(pointA, pointB, yValue);
  }

  function getXForYRight(yValue: number) {
    const pointA = { x: SVG_WIDTH, y: 0 };
    const pointB = { x: SVG_WIDTH - 24, y: SVG_HEIGHT };
    return getXForY(pointA, pointB, yValue);
  }

  const renderBubble = (yPosition: number, percentage: number, left = false) => {
    const rightEdgeCoordinate = getXForYRight(yPosition);
    const leftEdgeCoordinate = getXForYLeft(yPosition);
    const bubbleWidth = 42;
    const bubbleHeight = 26;
    const bubbleArrowHeight = 8;
    const centerPosition = !left ? rightEdgeCoordinate : leftEdgeCoordinate;
    return (
      <>
        <Bubble
          points={`${centerPosition - bubbleWidth / 2},${yPosition - bubbleHeight} ${centerPosition + bubbleWidth / 2},${
            yPosition - bubbleHeight
          } ${centerPosition + bubbleWidth / 2},${yPosition - bubbleArrowHeight} ${centerPosition},${yPosition} ${
            centerPosition - bubbleWidth / 2
          },${yPosition - bubbleArrowHeight}`}
        ></Bubble>
        <PercentageText x={centerPosition} y={yPosition - 12} textAnchor='middle'>
          {`${percentage}%`}
        </PercentageText>
      </>
    );
  };

  const renderPopulationNumber = (yPosition: number, population: string) => {
    const rightEdgeCoordinate = getXForYRight(yPosition);
    return (
      <>
        <line x1={rightEdgeCoordinate + 2} y1={yPosition} x2={rightEdgeCoordinate + 24} y2={yPosition} stroke='#afafaf' strokeWidth={0.5} />
        <PercentageText x={rightEdgeCoordinate + 24 + 2} y={yPosition} textAnchor='start' dominantBaseline={'middle'}>
          {`${population}`}
        </PercentageText>
      </>
    );
  };

  const renderGenPopFunnel = () => {
    const bottomYValue = awarenessTopValue * SVG_HEIGHT - 3;
    return (
      <>
        <Funnel
          points={`4,0 ${SVG_WIDTH},0 ${getXForYRight(bottomYValue)},${bottomYValue} ${getXForYLeft(bottomYValue)},${bottomYValue}`}
          color={segment.color}
        ></Funnel>
        {renderPopulationNumber(awarenessTopValue, representValuesInKsOrMs(segment?.population || 0))}
      </>
    );
  };

  const renderAwarenessFunnel = () => {
    const topYValue = awarenessTopValue * SVG_HEIGHT;
    const bottomYValue = awarenessBottomValue * SVG_HEIGHT;
    return (
      <>
        <Funnel
          color={awareness.color}
          points={`${getXForYLeft(topYValue)},${topYValue} ${getXForYRight(topYValue)},${topYValue} ${getXForYRight(
            bottomYValue,
          )},${bottomYValue} ${getXForYLeft(bottomYValue)},${bottomYValue}`}
        ></Funnel>
        {renderPopulationNumber(topYValue, representValuesInKsOrMs((awareness.percentage / 100) * (segment?.population || 0)))}
      </>
    );
  };

  const renderConsiderationFunnel = () => {
    const topYValue = awarenessBottomValue * SVG_HEIGHT + 3;
    return (
      <>
        <Funnel
          points={`${getXForYLeft(topYValue)},${topYValue} ${getXForYRight(topYValue)},${topYValue} ${
            SVG_WIDTH - 24
          },${SVG_HEIGHT} 28,${SVG_HEIGHT}`}
          color={consideration.color}
        ></Funnel>
        {renderPopulationNumber(topYValue, representValuesInKsOrMs((awarenessGlobalPopulation / 100) * (segment?.population || 0)))}
      </>
    );
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(event => {
      setParentWidth(event[0].contentRect.width);
      setParentHeight(event[0].contentRect.height);
    });

    resizeObserver.observe(funnelContainerRef.current as Element);
    return () => resizeObserver.disconnect();
  });

  return (
    <>
      <FunnelContainer id='market-funnel' ref={funnelContainerRef as React.MutableRefObject<HTMLDivElement>}>
        <FunnelChartSvg height={SVG_HEIGHT} width={SVG_WIDTH}>
          {renderGenPopFunnel()}
          {renderAwarenessFunnel()}
          {renderConsiderationFunnel()}

          <Line x1='0' y1='0' x2={24} y2={SVG_HEIGHT} stroke={segment.color} />

          {renderBubble(awarenessTopValue * SVG_HEIGHT, awareness.percentage, bubbleShouldGoLeft)}
          {renderBubble(awarenessBottomValue * SVG_HEIGHT, consideration.percentage)}
        </FunnelChartSvg>
        <Label color='gray500' variant='B3'>
          {label}
        </Label>
      </FunnelContainer>
    </>
  );
};

export default FunnelElement;
