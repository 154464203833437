import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import TabButton from 'common/components/TabButton';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SEGMENTS_ROUTES } from 'settings/routes';
import { DataTestId } from 'settings/constants';
import { BackButton } from 'common/styledComponents/buttons';
import { SegmentContextType } from 'common/types/segments';
import { useTracking } from 'common/hooks/tracking';
import { SCREENS, TRACKING_EVENTS } from 'common/types/tracking';

const SegmentsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabsButtonsWrapper = styled.nav`
  display: flex;

  > div {
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.v2.space(1, 2, 0, 3)};
    margin-right: ${({ theme }) => theme.v2.space(2)};
    cursor: pointer;

    border-right: 1px solid ${({ theme }) => theme.v2.colours.gray400};
  }
`;

const TabContentWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.v2.space(4)};
`;

const Segments = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [previousUrlPath, setPreviousUrlPath] = React.useState<string | null>(null);
  const [studyIdRedirect, setStudyIdRedirect] = React.useState<string | null>(null);
  const { trackEvent } = useTracking();

  const onBackButtonHandle = () => {
    if (previousUrlPath) {
      setPreviousUrlPath(null);
      setStudyIdRedirect(null);
      navigate(previousUrlPath, { replace: true, state: { prevUrl: currentPath, studyId: studyIdRedirect } });
    }
  };

  useEffect(() => {
    trackEvent(TRACKING_EVENTS.VIEW_PAGE, {
      screen: SCREENS.SEGMENTS,
    });
  }, [trackEvent]);

  return (
    <SegmentsPageContainer>
      <TabsButtonsWrapper>
        {previousUrlPath && <BackButton onClick={onBackButtonHandle} />}
        <Link to={`/segments/${SEGMENTS_ROUTES.LATANA}`}>
          <TabButton
            icon='latana'
            label='Latana Segments'
            active={currentPath === '/segments' || currentPath.startsWith(`/segments/${SEGMENTS_ROUTES.LATANA}`)}
          />
        </Link>

        <Link to={`/segments/${SEGMENTS_ROUTES.CUSTOM}`} data-testid={DataTestId.CUSTOM_SEGMENTS_TAB}>
          <TabButton
            icon='user-group-plus'
            label='Custom Segments'
            active={currentPath.startsWith(`/segments/${SEGMENTS_ROUTES.CUSTOM}`)}
          />
        </Link>
      </TabsButtonsWrapper>
      <TabContentWrapper>
        <Outlet context={{ setPreviousUrlPath, setStudyIdRedirect } satisfies SegmentContextType} />{' '}
        {/* Renders the child route components */}
      </TabContentWrapper>
    </SegmentsPageContainer>
  );
};

export default Segments;
