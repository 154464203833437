import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useRollbar } from '@rollbar/react';
import { FormProvider, useForm } from 'react-hook-form';
import { IFilter, DynamicFilters, ActiveFilter, FilterNames } from 'common/types/filters';
import { useFetchFilters, useGetStudySpecificRestrictions } from 'common/hooks/filters';
import {
  generateFiltersData,
  getOptionsIds,
  getValidationSchema,
  filterChartFormData,
  getIntermediateChangedMap,
  getChartBrandAndSegmentNames,
} from 'utils/filters';
import { useTracking } from 'common/hooks/tracking';
import { SCREENS, TRACKING_EVENTS } from 'common/types/tracking';
import { yupResolver } from '@hookform/resolvers/yup';
import { Text } from 'common/styledComponents/typography';
import { chartErrorAtom } from 'common/atoms/ui';
import { kpiFormulasSelector } from 'common/atoms/kpis';
import { permissionsSelector } from 'common/atoms/account';
import { exportChartToImage } from 'modules/charts/utils/export';
import { ChartDetails, DataTestId, CREATE_CHART_HASH, AVERAGE_VALUE, UNDEFINED_TYPE, LATANA_NAME } from 'settings/constants';
import { ChartFormData, ChartType } from 'common/types/chart';
import ChartViewHeader from 'modules/charts/components/Filters/ChartViewHeader';
import FilterModal from 'modules/charts/components/Filters/FilterModal';
import FilterOptionsList from 'modules/charts/components/Filters/FilterOptions';
import { FilterContainer, Container, Form } from './styled';
import {
  useVisibleKPIs,
  useAvailableFiltersOptions,
  useMenuItems,
  useDirty,
  useEnabledFilters,
  useFiltersList,
} from 'modules/charts/components/Filters/hooks';
import { isNotUndefined } from 'utils/helpers';
import { useGetDashboards } from 'common/queries/dashboards';
import { BaseFilterService } from 'modules/charts/components/Filters/services';
import { useInitFilterServices } from 'modules/charts/components/Filters/hooks/useInitFilterServices';
import { useGroupedSegments, useSegmentsList } from 'common/hooks/segments';
import { LogArgument } from 'rollbar';
import { useKpis } from 'common/hooks/kpis';
import { useChartDashboardsContext } from 'common/contexts/ChartsContext';
import { ChartValue } from 'common/types/charts';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMultipleValueFields } from 'modules/charts/utils/chartData';
import { useChartInDashboards } from './hooks/useChartInDashboards';

type UpdateFunction = (data: ChartFormData) => void;

interface Props {
  chartFormData: ChartFormData;
  onChartUpdate: (data: ChartFormData) => void;
  onFilterChanged: (changed: boolean) => void;
  chartUpdateLoading: boolean;
  chartSaveAsLoading: boolean;
  activeFilter?: FilterNames;
  setActiveFilter: (filter: FilterNames | undefined) => void;
  onChartDuplicate: (data: ChartFormData) => void;
  toggleDeleteModal: () => void;
  onShowWarningModal: () => void;
  handleChartDataCSVExport: () => void;
}

const Filters: React.FC<Props> = ({
  chartFormData,
  onChartUpdate,
  onFilterChanged,
  activeFilter,
  setActiveFilter,
  onChartDuplicate,
  chartUpdateLoading,
  toggleDeleteModal,
  onShowWarningModal,
  handleChartDataCSVExport,
}) => {
  const rollbar = useRollbar();
  const location = useLocation();
  const { trackEvent } = useTracking();
  const formRef = React.useRef<HTMLFormElement>() as React.MutableRefObject<HTMLFormElement>;

  const { chart_type: chartType, uuid: chartUuid, study_uuid: studyUuid, dynamic_waves: dynamicWaves } = chartFormData;

  const [skipFirstPost, setSkipFirstPost] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [dynamicFilter, setDynamicFilter] = React.useState<DynamicFilters | null>(dynamicWaves);
  const [intermediateChangedMap, setIntermediateChangedMap] = React.useState<Record<string, boolean>>({});

  const kpis = useKpis(studyUuid);
  const segments = useSegmentsList(studyUuid);
  const chartErrorMsg = useRecoilValue(chartErrorAtom);
  const segmentGroups = useGroupedSegments(studyUuid);
  const selectedKPIFormulas = useRecoilValue(kpiFormulasSelector);
  const allFilters = useGetStudySpecificRestrictions(studyUuid);
  const { chart_edit: isEditor, internal: isInternalUser } = useRecoilValue(permissionsSelector);
  const navigate = useNavigate();

  const { data: dashboardsList } = useGetDashboards();
  const isChartInMultipleDashboards = useChartInDashboards(dashboardsList, chartUuid);

  // will fetch and store, available filter options for the current selection
  const { fetchFilters, filters: availableFilters, loading: availableFiltersLoading } = useFetchFilters(studyUuid);

  const isCreate = typeof chartUuid === UNDEFINED_TYPE;

  const visibleKPIs = useVisibleKPIs(chartType, kpis);

  const validationSchema = React.useMemo(() => {
    return getValidationSchema(chartType);
  }, [chartType]);

  const filtersOptions = React.useMemo(
    () => ({
      availableFilters: allFilters,
      brands: availableFilters?.brands || allFilters?.brands,
      segmentGroups: segmentGroups || [],
      kpis: visibleKPIs || [],
      chartType,
      selectedKPIFormulas,
    }),
    [allFilters, segmentGroups, visibleKPIs, chartType, selectedKPIFormulas, availableFilters],
  );

  const computedAllFilters = React.useMemo(() => {
    return generateFiltersData(filtersOptions);
  }, [filtersOptions]);

  const possibleOptions = React.useMemo(() => {
    const options = { ...allFilters, segments, kpis: visibleKPIs, selectedKPIFormulas };
    return getOptionsIds(options);
  }, [allFilters, segments, visibleKPIs, selectedKPIFormulas]);

  // ensure that all selected values are available, only update
  const computedChartFormData = React.useMemo(() => {
    if (isCreate) return chartFormData;
    return { ...chartFormData, ...filterChartFormData(chartFormData, possibleOptions) };
  }, [possibleOptions, chartFormData, isCreate]);

  const isOwnerLatana = React.useMemo(() => {
    return computedChartFormData.owner?.type === LATANA_NAME;
  }, [computedChartFormData]);

  // handles filter updates
  const [intermediateValues, setIntermediateValues] = React.useState(computedChartFormData);

  React.useEffect(() => {
    setIntermediateValues(computedChartFormData);
  }, [computedChartFormData]);

  const formMethods = useForm<ChartFormData>({
    defaultValues: computedChartFormData,
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const { register, reset, handleSubmit, getValues, setValue, errors, trigger, watch } = formMethods;

  const setChartValue = React.useCallback(
    (chartField: string, values: ChartValue) => {
      // watch for changes
      setIntermediateChangedMap(changedMap => getIntermediateChangedMap(changedMap, chartField, values, intermediateValues));
      setValue(chartField, values);
      trigger();
    },
    [setValue, trigger, intermediateValues],
  );

  React.useEffect(() => {
    trigger();
  }, [trigger]);

  const [canSubmit, enabledFilters] = useEnabledFilters(errors, activeFilter, chartType);
  useInitFilterServices(setChartValue, getValues(), chartType, studyUuid, activeFilter);

  const handleFilterApply = React.useCallback(() => {
    BaseFilterService.onFilterApply();
    setIntermediateValues(getValues());
    setIntermediateChangedMap({});
    setActiveFilter(undefined);
  }, [getValues, setActiveFilter]);

  const isNextFilterTabEnabled = React.useMemo(() => {
    return activeFilter && !errors[activeFilter];
    // errors seems to be a mutable object, only the references change
    // eslint-disable-next-line
  }, [activeFilter, errors.wave, errors.country, errors.kpi, errors.brand, errors.segment]);

  const handleReset = React.useCallback(() => {
    reset(computedChartFormData);
    setIntermediateValues(computedChartFormData);
    setIntermediateChangedMap({});
  }, [reset, computedChartFormData]);

  React.useEffect(() => {
    // reset filters state if user didn't come from segments modal
    // OR before user is going to create a new chart
    if (!location?.state?.prevUrl?.startsWith('/segments/') || location.hash === CREATE_CHART_HASH) {
      handleReset();
    }
  }, [handleReset, location]);

  // create a Record of available options per filter
  const options = React.useMemo(() => {
    return {
      availableFilters: allFilters,
      brands: availableFilters?.brands || allFilters?.brands,
      segmentGroups,
      kpis,
      chartType,
      selectedKPIFormulas,
    };
  }, [availableFilters, segmentGroups, kpis, chartType, selectedKPIFormulas, allFilters]);

  const availableFiltersOptions = useAvailableFiltersOptions(options);

  const isFilterTypeCountry = BaseFilterService.isFilterType(FilterNames.COUNTRY);

  // [TODO] this should be rewritten.
  const getFilters = React.useCallback(
    async (otherFilters: Record<string, number[]>) => {
      try {
        if (isFilterTypeCountry && isCreate && !skipFirstPost) {
          await Promise.resolve(allFilters);
        } else {
          await fetchFilters(otherFilters);
          setSkipFirstPost(true);
        }
      } catch (e: unknown) {
        rollbar.error('getFilters error', e as LogArgument);
      }
    },
    [isFilterTypeCountry, isCreate, skipFirstPost, allFilters, fetchFilters, rollbar],
  );

  React.useEffect(() => {
    /* Use restrictions endpoint only for fetching brands */
    if (activeFilter === FilterNames.BRAND) {
      const { wave, brand, country } = getValues();

      if (wave === undefined && brand === undefined && country === undefined) {
        return;
      }
      // [brand] is being filtered to prevent [AVERAGE] value from being send to the BE.
      const { [activeFilter as ActiveFilter]: _ignoreFilter, ...otherFilters } = {
        wave,
        brand: brand?.filter(brandName => brandName !== AVERAGE_VALUE),
        country,
      };

      getFilters(otherFilters);
    }
  }, [activeFilter, getFilters, getValues]);

  // find active filter by id
  const activeFilterDetails = React.useMemo(() => {
    return activeFilter && computedAllFilters.find(({ id }) => id === activeFilter);
  }, [activeFilter, computedAllFilters]);

  const setNextFilterState = React.useCallback(
    (activeTabIndex: number) => () => {
      const nextTabIndex = BaseFilterService.getNextFilterTabIndex();
      BaseFilterService.onNextFilterState();
      const nextFilterId = computedAllFilters[activeTabIndex + nextTabIndex].id;
      setActiveFilter(nextFilterId);
    },
    [setActiveFilter, computedAllFilters],
  );

  const onHandleNextFilterChange = React.useMemo(() => {
    const activeIndex = computedAllFilters.indexOf(activeFilterDetails as IFilter);
    return activeIndex > -1 && activeIndex < computedAllFilters.length - 1 ? setNextFilterState(activeIndex) : undefined;
  }, [activeFilterDetails, computedAllFilters, setNextFilterState]);

  const newName: ChartValue = watch(ChartDetails.NAME);
  const newDescription: ChartValue = watch(ChartDetails.DESCRIPTION);

  const [dirty, isFilterChanged] = useDirty(computedChartFormData, intermediateValues, newName, newDescription);

  React.useEffect(() => {
    setChartValue(ChartDetails.NAME, newName);
  }, [newName, setChartValue]);

  const intermediateChanged = React.useMemo(() => {
    return Object.entries(intermediateChangedMap).some(([_, changed]) => changed);
  }, [intermediateChangedMap]);

  const showFilter = React.useCallback(
    (filter: IFilter) => {
      // Check if should show warning modal if user is not allow to edit this filter
      if (BaseFilterService.shouldShowWarningModal(isChartInMultipleDashboards, isInternalUser, isOwnerLatana)) {
        return onShowWarningModal();
      }

      BaseFilterService.onShowFilter(filter);
      // Wave filter is disabled for Growth Performance & Brand Perception charts
      // KPI filter is disabled for Brand Perception chart
      if (BaseFilterService.isFilterDisabled(filter)) return;
      setActiveFilter(filter.id);
      trackEvent(TRACKING_EVENTS.CLICK_EDIT_CHART_FILTERS, { filterType: filter.label });
    },
    [isChartInMultipleDashboards, isInternalUser, onShowWarningModal, setActiveFilter, trackEvent, isOwnerLatana],
  );

  const onUpdateFiltersClose = React.useCallback(() => {
    if (intermediateChanged || isCreate) {
      setShowCancelModal(true);
    } else {
      setActiveFilter(undefined);
    }
    setChartValue(ChartDetails.DYNAMIC_WAVES, dynamicWaves);
    setDynamicFilter(dynamicWaves);
  }, [intermediateChanged, setActiveFilter, isCreate, setDynamicFilter, dynamicWaves, setChartValue]);

  const handleCancelChart = React.useCallback(() => {
    if (isCreate && isNotUndefined(studyUuid)) {
      navigate('/');
    } else {
      reset(intermediateValues);
      setIntermediateChangedMap({});
      setActiveFilter(undefined);
      setShowCancelModal(false);
    }
  }, [intermediateValues, reset, setActiveFilter, isCreate, studyUuid, setShowCancelModal, navigate]);

  React.useEffect(() => {
    if (!isCreate) onFilterChanged(isFilterChanged);
  }, [isFilterChanged, isCreate, onFilterChanged]);

  const downloadChartHandle = React.useCallback(() => {
    const { name, chart_type } = computedChartFormData;
    exportChartToImage(name, 'png', chart_type);
    trackEvent(TRACKING_EVENTS.EXPORT_CHART, { chartType: chart_type, chartName: name, screen: SCREENS.CHART_DETAILS });
  }, [computedChartFormData, trackEvent]);

  const onInputSelect = (inputName: string) => {
    if (isChartInMultipleDashboards && isInternalUser) {
      return onShowWarningModal();
    }
    const input = document.getElementById(inputName) as HTMLInputElement;
    input?.select();
  };

  /**
   * Handles the transformation of chart data for Matrix charts.
   * It updates the data by setting `secondDimensionItems` and
   * selecting all available options for the second dimension.
   *
   * @param {ChartFormData} data - The form data for the chart.
   * @param {Function} updateFunction - The function to call with the updated data.
   * @returns The result of the update function with modified data.
   */
  const handleMatrixChartData = (data: ChartFormData, updateFunction: UpdateFunction) => {
    // Retrieve multiple value fields, excluding KPI and WAVE
    const multipleFields = (getMultipleValueFields(data) || []).filter(x => x !== FilterNames.KPI && x !== FilterNames.WAVE);

    // Determine the second dimension filter, defaulting to BRAND if none found
    const secondDimensionFilter = multipleFields.length > 0 ? multipleFields[0] : FilterNames.BRAND;

    // Return the result of the update function with modified data
    return updateFunction({
      ...data,
      secondDimensionItems: data[secondDimensionFilter as FilterNames] as number[],
      [secondDimensionFilter as FilterNames]: possibleOptions[secondDimensionFilter as FilterNames],
    });
  };

  /**
   * Handles the submission of the chart form.
   * If the chart type is Matrix, it processes the data accordingly;
   * otherwise, it updates the chart with the original data.
   *
   * @param {ChartFormData} data - The form data for the chart.
   * @returns The result of the chart update.
   */
  const onChartSubmitHandle = (data: ChartFormData) => {
    if (chartType === ChartType.MATRIX) {
      return handleMatrixChartData(data, onChartUpdate);
    }
    return onChartUpdate(data);
  };

  /* Consider refactoring this */
  const onChartDuplicateHandle = (data: ChartFormData) => {
    /* 
      If it is Matrix chart, we want to store filter selection as second_dimension_items, 
      and instead select all available options for that dimension. 
      Because for Matrix chart we want to fetch all data, but show only what is selected 
    */
    if (chartType === ChartType.MATRIX) {
      const multipleFields = (getMultipleValueFields(data) || []).filter(x => x !== FilterNames.KPI && x !== FilterNames.WAVE);
      const secondDimensionFilter = multipleFields.length > 0 ? multipleFields[0] : FilterNames.BRAND;

      return onChartDuplicate({
        ...data,
        secondDimensionItems: data[secondDimensionFilter as FilterNames] as number[],
        [secondDimensionFilter as FilterNames]: possibleOptions[secondDimensionFilter as FilterNames],
      });
    }
    return onChartDuplicate(data);
  };

  const onChartFormSubmit = handleSubmit(onChartSubmitHandle);
  const handleDuplicateChart = handleSubmit(onChartDuplicateHandle);

  const { setSelectedChart, setModalOpen } = useChartDashboardsContext();

  const handleModalOpen = React.useCallback(() => {
    if (!chartUuid) return;

    setModalOpen(true);
    setSelectedChart(chartUuid, chartType);
  }, [setModalOpen, setSelectedChart, chartUuid, chartType]);

  const menuItems = useMenuItems({
    isEditor,
    onRemove: toggleDeleteModal,
    onDownload: downloadChartHandle,
    handleExportChartCSV: handleChartDataCSVExport,
    onInputSelect,
    handleDuplicateChart,
    isChartInMultipleDashboards,
    isInternalUser,
    isOwnerLatana,
    handleModalOpen,
    chartFormData: computedChartFormData,
  });

  const onHandleSelectAll = (filterName: FilterNames, values: Array<string | number>) => {
    const allChartValues = getValues();
    const currentFieldValues: Array<string | number> = allChartValues[filterName];
    const newValues = currentFieldValues.concat(values);
    setChartValue(filterName, [...new Set(newValues)]);
  };

  const filtersOptionsList = useFiltersList(computedAllFilters, chartType);

  const onHandleFocus = () => {
    const isChartEditable = isChartInMultipleDashboards && !isInternalUser;
    if (!isChartEditable) return;

    const activeElement = document.activeElement as HTMLInputElement;
    if (activeElement) {
      activeElement.disabled = true;
      onShowWarningModal();
    }
  };

  const onHandleDynoFilterSelect = (dynamicFilter: DynamicFilters, status = false) => {
    setChartValue(ChartDetails.DYNAMIC_WAVES, !status ? dynamicFilter : null);
    setDynamicFilter(!status ? dynamicFilter : null);
  };

  /** Track event for filters, return selected brand and segments */
  React.useEffect(() => {
    if (chartFormData && filtersOptions) {
      const { brandNames, segmentNames } = getChartBrandAndSegmentNames(chartFormData, filtersOptions);
      trackEvent(TRACKING_EVENTS.CHART_FILTERS, {
        chartId: chartFormData.uuid,
        chartName: chartFormData?.name,
        chartBrands: brandNames,
        segmentNames: segmentNames,
      });
    }
  }, [trackEvent, chartFormData, filtersOptions]);

  return (
    <FormProvider {...formMethods}>
      <Form data-testid='form' ref={formRef} onSubmit={onChartFormSubmit} autoComplete='off' aria-expanded={Boolean(activeFilter)}>
        <ChartViewHeader
          reg={register}
          dirty={dirty}
          isCreate={isCreate}
          isEditor={isEditor}
          isInternalUser={isInternalUser}
          chartName={computedChartFormData.name}
          isFilterChanged={isFilterChanged}
          isChartValuesChanged={intermediateChanged}
          chartDesc={computedChartFormData.description}
          chartOwner={computedChartFormData.owner?.type}
          chartType={computedChartFormData.chart_type}
          menuItems={menuItems}
          showCancelModal={showCancelModal}
          handleCancelMoldalClose={() => setShowCancelModal(false)}
          handleCancelChart={handleCancelChart}
          onSubmit={onChartFormSubmit}
          canSubmit={canSubmit}
          handleReset={handleReset}
          chartUpdateLoading={chartUpdateLoading}
          onHandleInputFocus={onHandleFocus}
        />
        {chartErrorMsg && (
          <Text color='wine' variant='subheading1'>
            {chartErrorMsg}
          </Text>
        )}
        <Container data-testid={DataTestId.FILTERS_CONTAINER}>
          <FilterContainer id='filters-container'>
            {filtersOptionsList.map(filter => (
              <FilterOptionsList
                key={`filter_option_${filter.id}`}
                filter={filter}
                onHandleFilterClick={showFilter}
                active={activeFilter === filter.id}
                enabled={(enabledFilters as Record<FilterNames, boolean>)[filter.id]}
                isCreate={isCreate}
                chartFormData={chartFormData}
              />
            ))}
          </FilterContainer>
          <FilterModal
            isChartValuesChanged={intermediateChanged}
            isCreate={isCreate}
            activeFilter={activeFilter}
            filters={computedAllFilters}
            availableOptions={availableFiltersOptions}
            availableFiltersLoading={availableFiltersLoading}
            onNext={onHandleNextFilterChange}
            nextEnabled={isNextFilterTabEnabled}
            canSubmit={canSubmit}
            setChartValue={setChartValue}
            onFilterCancel={onUpdateFiltersClose}
            onFilterApply={handleFilterApply}
            chartType={chartType}
            values={getValues()}
            dynamicFilter={dynamicFilter}
            onHandleSelectAll={onHandleSelectAll}
            onHandleDynoFilterSelect={onHandleDynoFilterSelect}
          />
        </Container>
      </Form>
    </FormProvider>
  );
};

export default React.memo(Filters);
