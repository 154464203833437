import { useGetAllKpis } from 'common/queries/kpis';
import { KPI, KPIGroups } from 'common/types/common';
import { useCallback, useMemo } from 'react';
import { createKpiMap, groupKpisByType, sortKPIs } from 'utils/kpi';

export const useKpis = (studyId: string): KPI[] => {
  const { data: kpis } = useGetAllKpis();

  return useMemo(() => {
    const studySpecificKpi = kpis?.find(kpi => kpi.study_uuid === studyId);
    return sortKPIs(studySpecificKpi?.kpis || []);
  }, [kpis, studyId]);
};

export const useKpisList = (): ((studyId: string) => KPI[]) => {
  const { data: kpis } = useGetAllKpis();

  const getKpiList = useCallback(
    (studyId: string) => {
      const studySpecificKpi = kpis?.find(kpi => kpi.study_uuid === studyId);
      return sortKPIs(studySpecificKpi?.kpis || []);
    },
    [kpis],
  );

  return getKpiList;
};

export const useAllKpisMap = (studyId: string): Record<string, string> => {
  const kpis = useKpis(studyId);

  return useMemo(() => {
    return createKpiMap(kpis);
  }, [kpis]);
};

export const useKpiTypeGroups = (studyId: string): KPIGroups => {
  const kpis = useKpis(studyId);

  return useMemo(() => {
    return groupKpisByType(kpis);
  }, [kpis]);
};
