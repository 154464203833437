import React from 'react';
import { useGetAllBrands } from 'common/queries/brands';
import { useGetAllCountries } from 'common/queries/countries';
import { useRawSegments } from 'common/queries/segments';

export interface DataIndexMap {
  country: Record<number, string>;
  brand: Record<number, string>;
  segment: Record<number, string>;
  isLoading: boolean;
}

export const useDataIndexMap = (): DataIndexMap => {
  const { data: brandsList, isLoading: isBrandsLoading } = useGetAllBrands();
  const { data: segmentsList, isLoading: isSegmentsLoading } = useRawSegments();
  const { data: countriesList, isLoading: isCountriesLoading } = useGetAllCountries();

  const countries = React.useMemo(() => {
    if (isCountriesLoading || !countriesList) return {};
    return countriesList
      .map(({ countries }) =>
        countries.reduce((map, { id, name }) => {
          map[id] = name;
          return map;
        }, {} as Record<number, string>),
      )
      .reduce((acc, cur) => Object.assign(acc, cur), {} as Record<number, string>);
  }, [countriesList, isCountriesLoading]);

  const brands = React.useMemo(() => {
    if (!brandsList) return {};
    return brandsList.reduce((map, { id, name }) => {
      map[id] = name;
      return map;
    }, {} as Record<number, string>);
  }, [brandsList]);

  const segments = React.useMemo(() => {
    if (!segmentsList) return {} as Record<number, string>;
    const segmentMaps = segmentsList.map(({ segments }) =>
      segments.reduce((map, { id, name }) => {
        map[id] = name;
        return map;
      }, {} as Record<number, string>),
    );
    return segmentMaps.reduce((acc, cur) => Object.assign(acc, cur), {} as Record<number, string>);
  }, [segmentsList]);

  const isLoading = React.useMemo(
    () => isBrandsLoading || isSegmentsLoading || isCountriesLoading,
    [isBrandsLoading, isSegmentsLoading, isCountriesLoading],
  );

  return React.useMemo(
    () => ({ country: countries, brand: brands, segment: segments, isLoading }),
    [countries, brands, segments, isLoading],
  );
};
