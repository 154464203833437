import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';

type TableBodyRowProps = { isPrimaryBrand: boolean | undefined; isCompetitorBrand: boolean | undefined };

const TableChartContainer = styled.div``;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`;

const TableHead = styled.thead`
  border: 1px solid ${({ theme }) => theme.v2.colours.gray100};
  border-right: none;
  border-left: none;
`;

const TableHeadRow = styled.tr``;

const TableHeadItem = styled.td`
  padding: ${({ theme }) => theme.space(2.5, 0, 1, 2)};
  > div {
    justify-content: ${({ align }) => (align === 'left' ? 'flex-start' : align === 'center' ? 'center' : 'flex-end')};
  }
  &:not(:nth-of-type(2)) span {
    cursor: pointer;
  }
`;

const TableBodyHeader = styled.th``;

const TableHeaderText = styled(Text)<{ uppercase?: boolean }>`
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'capitalize')};
`;

const TableBodyHeaderInner = styled.div`
  display: flex;
  min-height: 40px;
  align-items: stretch;
  justify-content: flex-start;
`;

const TableDataItem = styled.td`
  position: relative;
`;

const TableBody = styled.tbody``;

const TableBodyRow = styled.tr<TableBodyRowProps>`
  background-color: ${({ isPrimaryBrand, isCompetitorBrand, theme }) =>
    isPrimaryBrand ? theme.colors.furiousFuchsia.toString() : isCompetitorBrand ? theme.colors.cottonBall.toString() : 'none'};
  border-bottom: 1px solid ${({ theme }) => theme.v2.colours.gray100};
  &:hover {
    > th,
    td {
      background: ${({ theme }) => theme.colors.cascadingWhite.toString()};
    }
  }

  &[data-disabled-item='true'] {
    opacity: 0.3;
  }
`;

const TableBodyCellContainer = styled.div<{ align: string }>`
  display: flex;
  justify-content: ${({ align }) => (align === 'left' ? 'flex-start' : align === 'center' ? 'center' : 'flex-end')};
  align-items: center;
  min-height: 40px;
  &:first-of-type {
    padding-left: ${({ theme }) => theme.space(2)};
  }
`;

const TableArrowContainer = styled.div`
  display: flex;
`;

const ValueNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-basis: calc(100% - 60px);
  max-width: calc(100% - 60px);
  align-items: center;
  padding: ${({ theme }) => theme.space(1, 2)};
`;

const TableHeaderCell = styled.div`
  display: flex;
  align-items: center;
`;

const SortController = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const BrandGroupPipe = styled.span<{ isPrimary?: boolean }>`
  width: 4px;
  background-color: ${({ isPrimary, theme }) => (isPrimary ? theme.colors.blueSparkle.toString() : theme.colors.turquoisePanic.toString())};
  position: absolute;
  height: 100%;
  left: 0;
`;

export {
  TableChartContainer,
  Table,
  TableHead,
  TableHeadRow,
  TableHeadItem,
  TableBody,
  TableBodyHeader,
  TableBodyHeaderInner,
  TableDataItem,
  TableBodyRow,
  TableBodyCellContainer,
  ValueNameContainer,
  TableHeaderCell,
  TableHeaderText,
  TableArrowContainer,
  SortController,
  BrandGroupPipe,
};
