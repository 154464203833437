import { atom } from 'recoil';
import { Notification, ToastPayload } from 'common/types/notification';

export const NotificationAtom = atom<Notification>({
  key: 'notification',
  default: {
    id: '',
    show: false,
    type: 'empty',
  },
});

export const ToastAtom = atom<ToastPayload[]>({
  key: 'toastAtom',
  default: [],
});
