import React from 'react';
import { PointTooltip, ResponsiveLine, Layer } from '@nivo/line';
import { ChartDataPoint, ChartNumbersType, RawChartSchema } from 'common/types/chart';
import { LineAxisLeft, LineAxisRight, lineChartTheme } from './config';
import styled from '@emotion/styled';
import MoeArea from '../MoeArea';
import AxisBottom from '../AxisBottom';
import { getMinAndMaxForLineChart } from './utils';
import LineChartTooltip from './LineChartTooltip';
import { useGetLineData, usePrepareLineData } from 'modules/charts/components/LineChart/hooks';
import { useGetPrimaryBrands } from 'common/hooks/brands';
import { DataTestId, UNDEFINED_TYPE } from 'settings/constants';
import { useGetWavesMap } from 'common/hooks/waves';
import { useIsCompetitorsEnabled } from 'common/hooks/features';

const Container = styled.div`
  opacity: 1;
  transition: 0.2s opacity ease-in;
  /* Take the remaining space inside chart container */
  flex-grow: 1;
  min-height: 0;
  transform: translate3d(0, 0, 0);

  /* Because flex-grow will take remaining space, but when rotated labels, height is not calculated properly, so we need to add extra margin to preserve space */
  &[data-rotated-labels='true'] {
    margin-bottom: ${({ theme }) => theme.space(4)};
  }
  &[aria-busy='true'] {
    opacity: 0;
  }
`;

export interface LineChartDataPoint {
  y: number | null;
  yRaw: number | null;
  x: number;
  color: string;
  moe: number;
  label: string;
  numericValue: boolean;
  populationNumberRaw: number;
  groupLabel: string;
}

interface Props {
  data: ChartDataPoint[];
  keys: string[];
  chart: RawChartSchema;
  inPage?: boolean;
  moeStatus: boolean;
  maxSegmentPopulationSize: number;
  chartNumbersType: ChartNumbersType;
  containerRef: React.MutableRefObject<HTMLDivElement | undefined>; // needed for tooltip
}

const LineChart: React.FC<Props> = ({ data, keys, containerRef, moeStatus, chart, chartNumbersType, maxSegmentPopulationSize }) => {
  const waveMap = useGetWavesMap();
  const [maxWidth, setMaxWidth] = React.useState(0);
  const [labelsCollide, setLabelsCollide] = React.useState<boolean>();
  const primaryBrands = useGetPrimaryBrands();
  // Remove this after feature is ready
  const orgProfileEnabled = useIsCompetitorsEnabled(chart.study_uuid);
  const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;
  const lineData = usePrepareLineData(data, keys, showPopulationNumbers);
  const [currentSelectedLine, setCurrentSelectedLine] = React.useState<string | undefined>(); // Current selected line in dashboard view
  const [disabledGroups] = React.useState<string[]>([]);

  const isNumericChart = React.useMemo(() => data?.[0]?.['value_0_numeric'], [data]);

  // adjust tooltip x position
  React.useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const onResize = () => {
        const { width } = container.getBoundingClientRect();
        setMaxWidth(width);
      };
      document.addEventListener('resize', onResize);
      onResize();
      return () => {
        document.removeEventListener('resize', onResize);
      };
    }
  }, [containerRef, lineData]);

  /* Render line chart tooltip */
  const renderTooltip: PointTooltip = React.useCallback(
    ({ point }) => {
      return <LineChartTooltip point={point} moeEnabled={moeStatus} maxWidth={maxWidth} chartNumbersType={chartNumbersType} />;
    },
    [maxWidth, moeStatus, chartNumbersType],
  );

  const checkLabelCollision = React.useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const textGroup = container?.querySelectorAll('svg > g > g');
      //2nd index will always have bottom labels in spite of how many line appears
      const labels = Array.from(textGroup)[2]?.querySelectorAll('g > text');
      const colides = Array.from(labels || []).some((elem, index, elems) => {
        const nextElem = elems[index + 1];
        if (nextElem) {
          const { right } = elem.getBoundingClientRect();
          const { left } = nextElem.getBoundingClientRect();

          return left <= right;
        }
        return false;
      });
      setLabelsCollide(colides);
    }
  }, [containerRef]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    const scheduleCalculate = () => {
      clearTimeout(timeout);
      timeout = setTimeout(checkLabelCollision, 300);
    };
    window.addEventListener('resize', scheduleCalculate);
    const initialTimer = setTimeout(checkLabelCollision, 300);
    return () => {
      window.removeEventListener('resize', scheduleCalculate);
      clearTimeout(initialTimer);
    };
  }, [checkLabelCollision]);

  const busy = typeof labelsCollide === UNDEFINED_TYPE;

  const layersList = React.useMemo(() => {
    const layers = ['grid', 'markers', 'lines', 'axes', 'points', 'mesh'] as Layer[];
    if (moeStatus) {
      layers.push(MoeArea as Layer);
    }
    return layers as Layer[];
  }, [moeStatus]);

  const RenderLeftAxis = React.useMemo(() => {
    const isPopNumbersOrNumeric = showPopulationNumbers || isNumericChart;
    return LineAxisLeft(isPopNumbersOrNumeric, showPopulationNumbers);
  }, [isNumericChart, showPopulationNumbers]);

  const RenderBottomAxis = React.useMemo(() => {
    return AxisBottom({ maxWidth: labelsCollide ? 150 : 0, waveMap, rotateXLabels: labelsCollide });
  }, [labelsCollide, waveMap]);

  /* Select primary brand as default for line chart dashboard view */
  React.useEffect(() => {
    if (primaryBrands?.length) {
      // Search in data by primary brand name, because it can be multiple primary brands in future, use first one always
      setCurrentSelectedLine(
        data.find(x => (orgProfileEnabled ? x.groupId.toString() : x.groupLabel === primaryBrands[0]))?.groupId?.toString(),
      );
    }
  }, [primaryBrands, setCurrentSelectedLine, data, orgProfileEnabled]);

  const finalLineData = useGetLineData({
    inDashboard: false,
    keys,
    data,
    currentSelectedLine,
    countryIds: chart.country_ids,
    orgProfileEnabled,
    showPopulationNumbers,
  });

  const filteredFinalData = finalLineData.filter(item => !disabledGroups.includes(item.id.toString()));

  const [maxValue, minValue] = React.useMemo(() => {
    return getMinAndMaxForLineChart(filteredFinalData, moeStatus);
  }, [filteredFinalData, moeStatus]);

  return (
    <>
      <Container aria-busy={busy} id='line-chart-container' data-rotated-labels={labelsCollide} data-testid={DataTestId.LINE_CHART}>
        <ResponsiveLine
          data={filteredFinalData}
          margin={{ top: 16, right: 64, bottom: 48, left: 112 }}
          xScale={{ type: 'point' }}
          yScale={{
            max: showPopulationNumbers ? maxSegmentPopulationSize : maxValue,
            type: 'linear',
            min: showPopulationNumbers ? 0 : minValue,
          }}
          theme={lineChartTheme}
          colors={line => line.color}
          enableGridX={false}
          pointSize={10}
          pointColor={{ from: 'color', modifiers: [] }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          tooltip={renderTooltip}
          layers={layersList}
          axisTop={null}
          axisRight={LineAxisRight}
          axisBottom={RenderBottomAxis as any}
          axisLeft={RenderLeftAxis as any}
          useMesh={true}
          animate={true}
        />
      </Container>
    </>
  );
};

export default React.memo(LineChart);
