import { Theme } from '@emotion/react';
import { CssMixBlendMode } from '@nivo/core';
import Color from 'common/theme/color';
import { DynamicFilters, FilterNames } from 'common/types/filters';
import { Owner } from 'common/types/common';

export type AverageBrand = 'Average';

export enum MOEStatus {
  NOT_PERMITTED,
  DISABLED,
  ENABLED,
}

export enum ChartFields {
  COUNTRY = 'country',
  WAVE = 'wave',
  BRAND = 'brand',
  SEGMENT = 'segment',
  KPI = 'kpi',
  MEAN = 'mean',
  MARGIN_OF_ERROR = 'margin_of_error',
}

export enum ChartViews {
  LINE = 'line',
  BAR = 'bar',
  HORIZONTAL = 'horizontal',
}

export enum ChartNumbersType {
  PERCENTAGE = 'percentage',
  VALUES = 'values',
  BOTH = 'both',
}

export enum ChartLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum ChartType {
  FUNNEL = 'funnel',
  COMPARISON = 'comparison',
  HEATMAP = 'heatmap',
  RADAR = 'radar',
  GAUGE = 'gauge',
  MARKET_SIZE = 'market_size',
  GROWTH_PERFORMANCE = 'growth_performance',
  RANKING = 'ranking',
  BRAND_PERCEPTION = 'brand_perception',
  MARKET_FUNNEL = 'market_funnel',
  MATRIX = 'matrix',
}

export type ChartFilterId = number | string;

export interface SelectedSort {
  key: string | null;
  type: SortType;
}

export interface ChartConfiguration {
  first_dimension: FilterNames;
  second_dimension: FilterNames;
  y_label: ChartFields;
  x_label: ChartFields;
  chart_value_representation: ChartNumbersType;
  second_dimension_items?: number[];
  change_over_time?: boolean;
}

export interface RawChartSchema {
  uuid: string | undefined;
  name: string;
  description: string | null;
  chart_type: ChartType;
  configuration: ChartConfiguration;
  wave_ids: number[];
  brand_ids: number[];
  segment_ids: number[];
  country_ids: number[];
  kpi_option_identifiers: string[];
  current_view: ChartViews | null;
  current_sort?: SelectedSort;
  display_reference_average: boolean;
  display_additional_brand_awareness: boolean;
  dynamic_waves: DynamicFilters | null;
  margin_of_error_status?: MOEStatus;
  study_uuid: string;
  updated_at: string;
  owner: Owner | null;
  tags: Array<string>;
}

export interface RawChartData {
  country: number;
  wave: number;
  brand: number | AverageBrand;
  segment: number;
  kpi: string;
  mean: number;
  margin_of_error?: number;
  segment_population_weight?: number;
  high?: number;
  low?: number;
  unavailable?: boolean;
  positive?: number;
  neutral?: number;
  negative?: number;
}

export interface ChartDataPointTooltipItem {
  label: string;
  color?: string;
  value: number;
  numericValue: boolean;
  moeValue?: number;
  unavailable: boolean;
  labelColor?: unknown;
  evolutionValue?: number;
  highlight?: boolean;
  populationValue: string;
  moePopulationValue?: string;
}

export interface ChartDataPointTooltip {
  title: string;
  subtitle?: string;
  items: ChartDataPointTooltipItem[];
  segments?: string[];
  kpis?: string[];
  groupBy?: string;
}

export interface FunnelTooltiop {
  kpiLabel: string;
  value: number;
  refValue: number | null;
  margin: number | null;
  absolute: number | null;
}

export interface ChartValueFilterLabels {
  brand: string;
  kpi: string;
  country: string;
  wave: string;
  segment: string;
}
export interface ChartDataPoint {
  count: number;
  tooltip: ChartDataPointTooltip;
  delta: number | null;
  deltaPopulation: string;
  value_0: number;
  value_0_raw: number;
  value_0_numeric: boolean;
  value_0_height: number;
  value_0_max_height: number;
  value_0_bar_fill: number;
  value_0_bar_label: string | null;
  value_0_funnel_fill: number;
  value_0_moe: number;
  value_0_moe_height: number;
  value_0_moe_raw: number;
  value_0_unavailable: boolean;
  value_0_label: string;
  value_0_label_value: string | Date;
  value_0_color: string;
  value_0_label_color: string;
  value_0_wave_id: number;
  value_0_wave_label: string;
  funnel_0_tooltip: FunnelTooltiop | null;
  value_0_secondDimension: string | number;
  value_0_firstDimension: string | number;
  value_0_disabled?: boolean;
  value_0_populationNumberValue: string;
  value_0_populationNumberRaw: number;
  value_0_segmentPopulationNumber: number;
  value_0_segmentSize: number;
  value_0_filterLabels: ChartValueFilterLabels;
  aidedIndex: number;
  referenceValue: number | null;
  color: string;
  disabled: boolean;
  groupId: string;
  secondDimension?: string;
  groupLabel: string;
}

export interface GeneratedChartData {
  data: ChartDataPoint[];
  keys: string[];
  indexBy: FilterNames;
  secondDimension: FilterNames;
  chartType: ChartType;
  groups: number;
  maxValue: number;
  minValue: number | null;
  maxMoE?: number;
  maxSegmentPopulationSize: number;
}

export type ChartDataKey = keyof ChartDataPoint;

export interface ReferenceBrand {
  id: number;
  name: string;
}

export interface RawReferenceAverageData {
  country: number;
  wave: number;
  segment: number;
  kpi: string;
  mean: number;
  reference_brands: ReferenceBrand[];
}

export interface RawChart {
  chart: RawChartSchema;
  data: RawChartData[];
  aggregate_data?: RawReferenceAverageData[];
  brand_awareness_data?: RawChartData[];
}

export enum SortType {
  ASC = 'ascending',
  DESC = 'descending',
  DEFAULT = 'default',
}

export interface SortOption {
  key: string | null | ConesSortingKeys;
  type: SortType | null;
}

export interface CurrentChartState {
  id: string | null | undefined;
  chartType: ChartType | undefined;
}

export interface RadarChartData {
  kpi: number;
  [key: string]: number;
}

/**
 * Note: wave, kpi, brand, segments are multivalue fields and are handled by checkboxes
 * and checkboxes are values
 */

export interface ChartFormData {
  uuid?: string;
  name: string;
  description: string | null;
  chart_type: ChartType;
  country: number[];
  wave: number[];
  kpi: Array<string>;
  brand: number[];
  segment: Array<number>;
  firstDimension: FilterNames | null;
  secondDimension: FilterNames | null; // this cannot be always computed, will be set before submit
  current_view: ChartViews | null;
  display_reference_average?: boolean;
  display_additional_brand_awareness: boolean;
  chartNumbersType: ChartNumbersType;
  dynamic_waves: DynamicFilters | null;
  study_uuid: string;
  owner: Owner | null;
  secondDimensionItems?: number[]; // This is needed for Matrix chart, to store selected items in filter
}

export interface GaugeLegends {
  name: string;
  color: string;
}

export interface ComparisonBar {
  key: number;
  x: number;
  y: number;
  height: number;
  width: number;
  data: {
    id: string;
    index: number;
    data: {
      funnel_0_tooltip: FunnelTooltiop;
      value_0_averageValue: number;
      value_0_averagePopulationValue: string;
      tooltip: ChartDataPointTooltip;
    };
  };
}
export interface GPChartCellsValues {
  value: string;
  valueAbs: string | null;
  isDynamicPositive: boolean | null;
  valueColor: keyof Theme['colors'] | 'inherit';
  [key: string]: number | string | boolean | null | undefined | Color;
  headerName?: ChartHeaderNames | null;
}
export interface GPHeader {
  key: null | string;
  title: string | null;
  align?: 'left' | 'center' | 'right';
  width?: number;
  headerName?: ChartHeaderNames | null;
}

export interface GPChartData {
  label: string | undefined;
  id: string;
  values: GPChartCellsValues[];
}

export interface GrowthPerformanceData {
  chartData: Array<GPChartData>;
  chartHeaders: Array<GPHeader>;
}

export type AverageLayerProps = {
  bars: ComparisonBar[];
  yScale: (value: number) => number | undefined;
  xScale: (value: number) => number | undefined;
};

export type TAverageLayerGroup = ({ bars, yScale, xScale }: AverageLayerProps) => JSX.Element[];
export type TAverageLayerSingleLine = ({ bars, yScale, xScale }: AverageLayerProps) => JSX.Element;

export type TBarTooltip = {
  title: string;
  items: ChartDataPointTooltipItem[];
  segments: string[];
  kpis: string[];
  groupBy: string;
  subtitle?: string;
};

interface Legend {
  anchor: 'bottom' | 'left' | 'right' | 'top' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left' | 'center';
  direction: 'column' | 'row';
  effects: Array<{
    on: 'hover';
    style: {
      itemTextColor: string;
    };
  }>;
  itemHeight: number;
  itemTextColor: string;
  itemWidth: number;
  symbolShape: 'circle' | 'diamond' | 'square' | 'triangle';
  symbolSize: number;
  translateX: number;
  translateY: number;
}

export interface Params {
  blendMode: CssMixBlendMode | undefined;
  borderColor: string;
  borderWidth: number;
  dotBorderWidth: number;
  dotColor: string | number;
  dotSize: number;
  fillOpacity: number;
  gridShape: 'circular' | 'linear';
  indexBy: string;
  labelOffset: number;
  legends: Array<Legend>;
  margin: { top: number; right: number; bottom: number; left: number };
  motionConfig: string;
}

export interface NetSentiment {
  value: number | null;
  state?: string;
  population?: string;
  positive?: number | null;
  neutral?: number | null;
  negative?: number | null;
}

export interface Sentiments {
  positive: NetSentiment;
  neutral: NetSentiment;
  negative: NetSentiment;
  netSentiment: NetSentiment;
  from1MonthAgo: NetSentiment;
  fromNMonthAgo: NetSentiment;
}

export interface BpChartData {
  index: string;
  positive: NetSentiment;
  neutral: NetSentiment;
  negative: NetSentiment;
  netSentiment: NetSentiment;
  from1MonthAgo: NetSentiment;
  fromNMonthAgo: NetSentiment;
}

export type BpColumnKey = 'positive' | 'neutral' | 'negative' | 'netSentiment' | 'from1MonthAgo' | 'fromNMonthAgo';
export interface BpHeaderTitle {
  title: string;
  key: BpColumnKey;
  tooltip: string;
}

export interface BpIndexMap {
  country: Array<{ [key: number]: string }>;
  brand: Array<{ [key: number]: string }>;
  segment: Array<{ [key: number]: string }>;
  isLoading: boolean;
}

export enum ChartHeaderNames {
  SEGMENT_SIZE = 'segment',
  CURRENT_MONTH = 'currentEstimate',
  ONE_MONTH = 'oneMonth',
  THREE_MONTHS = 'threeMonths',
}

export enum ConesSortingKeys {
  INDEX = 'index',
  POPULATION = 'population',
  AWARENESS = 'Awareness-From 1 month ago',
  CONSIDERATION = 'Consideration-From 1 month ago',
}
