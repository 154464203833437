import styled from '@emotion/styled';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import useOutsideClick from 'common/hooks/useOutsideClick';
import { SortOption, SortType } from 'common/types/chart';
import { Theme } from '@emotion/react';
import { LatanaText } from '../LatanaText';
import Icon from '../Icon';
import { Text } from 'common/styledComponents/typography';
import { scrollbarStyles } from 'common/styledComponents/mixins';

export const SelectedItem = styled.div<{ active?: boolean; noHover?: boolean; noActiveDefaultText?: boolean; extraPadding?: boolean }>`
  color: ${({ theme, active, noActiveDefaultText }) =>
    active ? theme.colors.lightPurple.toString() : noActiveDefaultText ? theme.v2.colours.gray900 : theme.colors.regularGrey.toString()};
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.space(0.5)};
  height: 28px;
  min-width: 28px;
  padding: ${({ theme, extraPadding }) => (extraPadding ? theme.space(1) : '0')};
  ${({ noHover }) =>
    !noHover &&
    `
    &:hover {
      background: ${({ theme, active }: { theme: Theme; active?: boolean }) =>
        active ? theme.colors.lightPurple.alpha(0.1).toString() : theme.colors.regularGrey.alpha(0.1).toString()};
    }
  `}
`;

const DropDownContainer = styled.div<{ withBorder: boolean; active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.v2.colours.gray0};
  color: ${({ theme }) => theme.v2.colours.gray900};
  cursor: pointer;

  ${({ withBorder, theme, active }) =>
    withBorder &&
    `
  border: 1px solid ${active ? theme.v2.colours.latana100 : theme.v2.colours.gray200};
  border-radius: 4px;
  `}
`;

const DropDownListWrapper = styled.div<{ align: string | number }>`
  position: absolute;
  background: ${({ theme }) => theme.colors.white.toString()};
  top: 0px;
  left: ${({ align }) => (align === 'left' ? 0 : 'initial')};
  right: ${({ align }) => (align === 'right' ? 0 : 'initial')};
  z-index: 99;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding-top: ${({ theme }) => theme.v2.space(1.5)};
  padding-bottom: ${({ theme }) => theme.v2.space(1.5)};
  padding-left: ${({ theme }) => theme.v2.space(0.5)};
  padding-right: ${({ theme }) => theme.v2.space(0.5)};
  max-width: 600px;
  width: auto;
  gap: 4px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 60vh;

  // Apply the scrollbar styles
  ${scrollbarStyles()}
`;

const DropDownListItem = styled.div`
  color: ${({ theme }) => theme.v2.colours.gray900};
  padding-top: ${({ theme }) => theme.v2.space(0.5)};
  padding-bottom: ${({ theme }) => theme.v2.space(0.5)};
  padding-left: ${({ theme }) => theme.v2.space(1.5)};
  padding-right: ${({ theme }) => theme.v2.space(1.5)};
  cursor: pointer;
  user-select: none;
  height: 28px;
  min-height: 28px;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: ${({ theme }) => theme.colors.black05.toString()};
  }

  &[aria-disabled='true'] {
    opacity: 0.2;

    &:hover {
      background: none;
    }
  }
`;

const Label = styled(Text)`
  padding: ${({ theme }) => theme.v2.space(0, 1, 1, 1)};
  text-wrap: nowrap;
`;

export const IconItem = styled.div<IconItemProps>`
  background: url('${({ icon }) => icon}') no-repeat center center;
  border: none;
  width: 100%;
  height: 100%;
  min-height: 28px;
  min-width: 28px;
  cursor: pointer;
  filter: invert(60%) sepia(0%) saturate(29%) hue-rotate(186deg) brightness(91%) contrast(102%);
  margin-left: ${({ theme }) => theme.v2.space(1)};

  ${({ withLabel }) =>
    withLabel &&
    `
		filter: invert(44%) sepia(49%) saturate(6042%) hue-rotate(242deg) brightness(98%) contrast(107%);
	`}

  ${({ active }) =>
    active &&
    `
		filter: invert(32%) sepia(71%) saturate(3252%) hue-rotate(245deg) brightness(103%) contrast(101%);
	`}
`;

const LabelWrapper = styled.div`
  margin-bottom: 12px;
`;

const SelectedItemInnerContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 195px;
  margin-right: ${({ theme }) => theme.v2.space(1)};
`;

const Container = styled.div`
  max-width: 195px;
`;

const RadioButtonCircle = styled.div<{ selected: boolean }>`
  width: 16px;
  height: 16px;
  min-width: 16px;
  border: 1px solid ${({ theme, selected }) => (selected ? theme.v2.colours.latana600 : theme.v2.colours.gray600)};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.v2.space(1)};
  position: relative;
  background-color: ${({ theme, selected }) => (selected ? theme.v2.colours.latana600 : 'transparent')};
  transition: all 0.3s ease;

  /* Create a small white circle in the middle when selected */
  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  }
`;

const ListItemLabelWrapper = styled.div`
  flex-grow: 1;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface DropDownItemProps<T> {
  value: T;
  label: string;
  icon?: string;
  disabled?: boolean;
}

interface IconItemProps {
  icon: string;
  active?: boolean;
  withLabel?: boolean;
}

export enum DropdownAlign {
  LEFT = 'left',
  RIGHT = 'right',
}

interface DropdownWithIconProps<T> {
  label: string;
  testId?: string;
  topLabel?: string;
  noHover?: boolean;
  withArrow?: boolean;
  toolbarIcon: string;
  align: DropdownAlign;
  defaultText?: string;
  withBorder?: boolean;
  inDashboard?: boolean;
  withTopLabel?: boolean;
  renderInBody?: boolean;
  extraPadding?: boolean;
  showDefaultText?: boolean;
  showIconAndLabel?: boolean;
  noActiveDefaultText?: boolean;
  options: DropDownItemProps<T>[];
  onSelect: <T>(value: T) => void;
  selectedValue: string | SortOption | null;
  noLabel?: boolean;
}

const DropDownWithIcon = <T,>({
  label,
  testId,
  options,
  topLabel,
  onSelect,
  toolbarIcon,
  selectedValue,
  noHover = false,
  defaultText = '',
  withArrow = false,
  withBorder = false,
  inDashboard = false,
  renderInBody = false,
  withTopLabel = false,
  extraPadding = false,
  showDefaultText = false,
  showIconAndLabel = false,
  noActiveDefaultText = false,
  align = DropdownAlign.LEFT,
  noLabel = false,
}: DropdownWithIconProps<T>) => {
  const [elementStyles, setElementStyles] = useState({});
  const [showDropDown, setShowDropDown] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    const containerElement = contentRef.current;

    if (containerElement && showDropDown && renderInBody) {
      const { top: topPosition, right, left: leftPosition } = containerElement.getBoundingClientRect();
      const rightPosition = window.innerWidth - right;

      setElementStyles({
        top: `${topPosition + 34}px`,
        right: align === DropdownAlign.RIGHT ? `${rightPosition}px` : 'initial',
        left: align === DropdownAlign.LEFT ? `${leftPosition}px` : 'initial',
      });
    }
  }, [showDropDown, align, renderInBody]);

  useOutsideClick(contentRef, () => setShowDropDown(false));

  const onOptionSelect = React.useCallback(
    (value: unknown) => () => {
      onSelect(value);
    },
    [onSelect],
  );

  const isObjectWithKey = (value: unknown): value is { key: string; type: string } => {
    return typeof value === 'object' && value !== null && ('key' in value || 'type' in value);
  };

  const selectedOption = React.useMemo(() => {
    if (!selectedValue || !options) return null;

    return options.find(({ value }) => {
      if (isObjectWithKey(value) && isObjectWithKey(selectedValue)) {
        return value.key === selectedValue.key;
      }

      return value === selectedValue;
    });
  }, [options, selectedValue]);

  const renderOptions = React.useMemo(() => {
    return (
      <DropDownListWrapper align={align} style={elementStyles}>
        {!noLabel && (
          <Label variant='body2' color='black25'>
            {label}
          </Label>
        )}
        {options.map(({ value, disabled, icon, label: optionLabel }) => (
          <DropDownListItem
            aria-disabled={disabled}
            key={`dropdown-item-${value}-${optionLabel}`}
            onMouseDown={disabled ? undefined : onOptionSelect(value)}
          >
            <RadioButtonCircle selected={selectedValue === value} />
            <ListItemLabelWrapper>{optionLabel}</ListItemLabelWrapper>
            {icon && (
              <SelectedItem>
                <IconItem icon={icon} />
              </SelectedItem>
            )}
          </DropDownListItem>
        ))}
      </DropDownListWrapper>
    );
  }, [options, selectedValue, align, label, onOptionSelect, elementStyles, noLabel]);

  const renderOptionsPortal = React.useMemo(() => {
    return createPortal(renderOptions, document.body);
  }, [renderOptions]);

  const renderWithIconAndLabel = React.useMemo(() => {
    const isSortTypeDefault = selectedOption?.value === SortType.DEFAULT;
    const itemTitle = (isObjectWithKey(selectedOption?.value) ? selectedOption?.value.type : selectedOption?.value) as string;
    return (
      <React.Fragment>
        {isSortTypeDefault && <SelectedItem>{inDashboard ? <IconItem icon={toolbarIcon} /> : defaultText}</SelectedItem>}
        {selectedOption?.icon && !isSortTypeDefault && (
          <SelectedItem active={true} title={itemTitle}>
            {inDashboard ? (
              <IconItem icon={toolbarIcon} withLabel />
            ) : (
              <React.Fragment>
                <IconItem icon={selectedOption.icon} withLabel /> {selectedOption?.label}
              </React.Fragment>
            )}
          </SelectedItem>
        )}
      </React.Fragment>
    );
  }, [defaultText, inDashboard, selectedOption, toolbarIcon]);

  const renderBasic = React.useMemo(() => {
    const { icon: selectedIcon, label: selectedLabel } = selectedOption || {};

    const isShownAsActive = noActiveDefaultText ? !!selectedLabel : true;

    const renderIconItem = (icon: string, withLabel = true) => <IconItem icon={icon} withLabel={withLabel} />;
    const renderLabel = () => (showDefaultText && !selectedLabel ? label : selectedLabel);

    return (
      <SelectedItem active={isShownAsActive} noHover={noHover} noActiveDefaultText={noActiveDefaultText} extraPadding={extraPadding}>
        <SelectedItemInnerContainer>
          {inDashboard ? renderIconItem(toolbarIcon) : selectedIcon ? renderIconItem(selectedIcon) : renderLabel()}
        </SelectedItemInnerContainer>
        {withArrow && <Icon icon='arrow-chevron-down' color={selectedLabel ? 'latana500' : 'gray400'} />}
      </SelectedItem>
    );
  }, [inDashboard, label, selectedOption, showDefaultText, toolbarIcon, noActiveDefaultText, noHover, extraPadding, withArrow]);

  return (
    <Container>
      {withTopLabel && (
        <LabelWrapper>
          <LatanaText variant='L1' color='gray900'>
            {topLabel}
          </LatanaText>
        </LabelWrapper>
      )}
      <DropDownContainer
        ref={contentRef}
        data-testid={testId}
        onClick={() => setShowDropDown(!showDropDown)}
        withBorder={withBorder}
        active={!!selectedOption}
      >
        {showIconAndLabel ? renderWithIconAndLabel : renderBasic}
        {showDropDown && (renderInBody ? renderOptionsPortal : renderOptions)}
      </DropDownContainer>
    </Container>
  );
};

export default React.memo(DropDownWithIcon);
