import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'common/components/Form/Button';
import ModalV2 from 'common/components/Modal/ModalV2';
import { ButtonWrapper, Content } from 'common/components/Modal/styled';
import { LatanaText } from 'common/components/LatanaText';
import LabeledInput from 'common/components/LabeledInput';

interface Props {
  open: boolean;
  isLoading?: boolean;
  title: string;
  confirmButtonTitle?: string;
  children?: React.ReactNode;
  onCancel: () => void;
  onConfirm: (title: string) => void;
}

const TitleModal: React.FC<Props> = ({ open, isLoading = false, onCancel, onConfirm, title, confirmButtonTitle }) => {
  const { t } = useTranslation();
  const [titleName, setTitleName] = React.useState('');

  const handleConfirm = () => {
    onConfirm(titleName);
  };

  return (
    <ModalV2 modalId='title-modal' isOpen={open} title={title} onClose={onCancel} clickOutsideClose={!isLoading}>
      <Content>
        <LatanaText variant='L1' color='gray900'>
          {t('dashboard.addTitleBeforeSaving')}
        </LatanaText>
        <LabeledInput placeholder={t('dashboard.enterTitle')} onInputChange={e => setTitleName(e.target.value)} defaultValue={titleName} />
        <ButtonWrapper>
          <LoadingButton
            disabled={!titleName.length}
            isButtonV2={true}
            loading={isLoading}
            type='button'
            color='lightPurple'
            onClick={handleConfirm}
          >
            {confirmButtonTitle ? confirmButtonTitle : t('common.save')}
          </LoadingButton>
        </ButtonWrapper>
      </Content>
    </ModalV2>
  );
};

export default React.memo(TitleModal);
