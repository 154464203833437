import styled from '@emotion/styled';
import { scrollbarStyles } from 'common/styledComponents/mixins';

export const Content = styled.div`
  background-color: ${({ theme }) => theme.v2.colours.gray0};
  padding: ${({ theme }) => theme.v2.space(2, 2.5)};
  border-radius: ${({ theme }) => theme.v2.borderRadius * 2}px;
  &[data-show-studies='true'] {
    max-height: 418px;
    overflow: auto;

    // Apply the scrollbar styles
    ${scrollbarStyles()}
  }
`;

export const ContentItem = styled.div`
  padding: ${({ theme }) => theme.v2.space(1.75, 1)};
  display: flex;
  &:hover {
    border-radius: ${({ theme }) => theme.v2.borderRadius}px;
    background-color: ${({ theme }) => theme.v2.colours.gray100};
    cursor: pointer;
  }
`;

export const PillButtonWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.v2.space(1)};
  margin-bottom: ${({ theme }) => theme.v2.space(1.25)};
  border-bottom: 1px solid ${({ theme }) => theme.v2.colours.gray100};
`;

export const IconWrapper = styled.div`
  min-width: ${({ theme }) => theme.v2.space(2)};
  height: initial;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartTypeItem = styled(ContentItem)`
  padding: ${({ theme }) => theme.v2.space(1)};
`;

export const ChartTypeIcon = styled(IconWrapper)`
  background-color: ${({ theme }) => theme.v2.colours.gray50};
  border-radius: 50%;
  min-width: 32px;
  height: 32px;
`;

export const ChartTypeLabel = styled.div`
  margin-left: ${({ theme }) => theme.v2.space(1.5)};
`;

export const NoChartText = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.v2.space(4, 0)};
`;
