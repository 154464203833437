import React, { FC } from 'react';
import styled from '@emotion/styled';
import { CustomVariableValues } from 'common/types/segments';

const BottomFieldsWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.padding * 1}px 0px;
`;

const FieldItem = styled.div`
  float: left;
  width: 280px;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.black25.toString()};
  font-size: ${({ theme }) => theme.text.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.text.variants.body2.fontWeight};

  span {
    :after {
      content: ' ..................................................................................';
    }
  }
`;

const ValueItem = styled.div`
  float: left;
  width: 205px;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.spacing.padding}px;
  color: ${({ theme }) => theme.colors.black.toString()};
  font-size: ${({ theme }) => theme.text.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.text.variants.body2.fontWeight};
`;

const SegmentTitle = styled.span`
  > :first-letter {
    text-transform: uppercase;
  }
`;

const SegmentModalCell: FC<{ name: string; values: string[] | CustomVariableValues[] | undefined }> = ({ name, values }) => {
  const normalizeTitle = (title: string) => {
    if (!title) return '';
    return title.charAt(0).toUpperCase() + title.replace(/[^A-Z0-9]/gi, ' ').slice(1);
  };

  const normalizeValue = (value: string[] | undefined) => {
    return value
      ?.map((x: string) => {
        if (!x) return '';
        return x.charAt(0).toUpperCase() + x.slice(1);
      })
      .join(', ');
  };

  return (
    <BottomFieldsWrapper>
      <FieldItem>
        <SegmentTitle>{normalizeTitle(name)}:</SegmentTitle>
      </FieldItem>
      <ValueItem>{normalizeValue(values)}</ValueItem>
    </BottomFieldsWrapper>
  );
};

export default SegmentModalCell;
