import * as React from 'react';
import { ErrorBoundary } from '@rollbar/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Spinner from 'common/components/Spinner';
import Notification from 'common/components/Notification';
import { getChartCreateType } from 'common/hooks/useChartParams';
import { LoadingContainer, ChartContentWrapper } from 'common/styledComponents/containers';
import ChartView from 'views/ChartView';
import { currentStudyAtom } from 'common/atoms/account';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFetchChart, useGetNewChartData } from 'common/hooks/charts';
import { currentChartAtom } from 'common/atoms/charts';
import { useGetAllRestrictions } from 'common/queries/filters';

const fallback: JSX.Element = (
  <LoadingContainer>
    <Spinner />
  </LoadingContainer>
);

const ChartViewLoader = () => {
  const { chartId } = useParams();
  const { search, state } = useLocation();
  const [, setCurrentStudy] = useRecoilState(currentStudyAtom);
  const studyId = state?.studyId;
  const { loading, fetchChart } = useFetchChart();
  const chart = useRecoilValue(currentChartAtom);
  const { getNewChartData } = useGetNewChartData(studyId);
  const { isFetching: loadingRestrictions } = useGetAllRestrictions();

  useEffect(() => {
    if (studyId) {
      setCurrentStudy({ studyId });
    }
  }, [setCurrentStudy, studyId]);

  useEffect(() => {
    if (chartId && chart?.chart.uuid !== chartId) {
      fetchChart(chartId);
    }
  }, [fetchChart, chartId, chart]);

  const chartData = React.useMemo(() => {
    return chartId && chart ? chart : getNewChartData(studyId, getChartCreateType(search));
  }, [chart, chartId, getNewChartData, search, studyId]);

  if (loading || loadingRestrictions) return fallback;

  return (
    <ErrorBoundary fallbackUI={() => <Notification type='serverError' />} errorMessage='Chart view error'>
      <React.Suspense fallback={fallback}>
        <ChartContentWrapper>
          <ChartView chartId={chartId} chart={chartData} />
        </ChartContentWrapper>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default React.memo(ChartViewLoader);
