import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'common/components/Icon';
import { LatanaText } from 'common/components/LatanaText';
import { SortType } from 'common/types/chart';
import { TableListHeaderContainer, TableListTitleControl, TableListTitleWrapper } from 'common/components/TableList/styled';
import { SortingKey } from 'common/types/common';
import { TableListHeaderProps } from 'common/types/charts';

const TableListHeader: React.FC<TableListHeaderProps> = ({ onColumnSort, sortingKey, sortingType }) => {
  const { t } = useTranslation();

  const chartColumns = [
    { key: SortingKey.NAME, label: t('tableList.title') },
    { key: SortingKey.OWNER, label: t('tableList.owner') },
    { key: SortingKey.UPDATED_AT, label: t('tableList.updatedAt') },
  ];

  return (
    <TableListHeaderContainer>
      {chartColumns.map(column => (
        <TableListTitleWrapper key={column.key}>
          <TableListTitleControl onClick={() => onColumnSort(column.key)}>
            <LatanaText variant='L1' color='gray500'>
              {column.label}
            </LatanaText>
            {sortingKey === column.key && sortingType !== SortType.DEFAULT ? (
              <Icon icon={sortingType === SortType.ASC ? 'arrow-up' : 'arrow-down'} />
            ) : null}
          </TableListTitleControl>
        </TableListTitleWrapper>
      ))}
    </TableListHeaderContainer>
  );
};

export default React.memo(TableListHeader);
