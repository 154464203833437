import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'common/components/Icon';
import { ICONS } from 'icons/iconsMap';
import { LatanaText } from 'common/components/LatanaText';
import { formatDate } from 'utils/helpers';
import { ListItem, ListItemDetails, ListItemDetailsContainer, ListItemOwnerCell } from './styled';
import { Owner } from 'common/types/common';
import { useCallback } from 'react';
import { LatanaIconComponent } from 'icons';
import { TableListItemProps } from 'common/types/common';
import { DataTestId, LATANA_NAME } from 'settings/constants';
import { ActionMenuProps } from 'common/components/ActionMenu';
import { ChartType } from 'common/types/chart';
import { markdownParser } from 'utils/markdown';

const chartTypeMap: Record<string, keyof typeof ICONS> = {
  radar: 'new',
  funnel: 'funnel',
  heatmap: 'heatmap',
  ['growth_performance']: 'spreadsheet',
  ['ranking']: 'ranking',
  ['comparison-bar']: 'column-chart',
  ['comparison-line']: 'line-chart',
  ['comparison-horizontal']: 'bar-chart',
  gauge: 'score-chart',
  ['market_size']: 'market-size',
  ['brand_perception']: 'spreadsheet',
  ['market_funnel']: 'funnel',
  matrix: 'scatter-chart',
};

interface TableListItemComponentProps extends TableListItemProps {
  onClick: (uuid: string, study_uuid: string) => void;
  style: React.CSSProperties;
  children?: React.ReactNode;
  renderActionMenu?: (props: ActionMenuProps) => JSX.Element;
  onChange?: (itemUuid: string) => void;
  isDisabled?: boolean;
}

const TableListItem = ({
  name,
  chart_type,
  updated_at,
  owner,
  current_view,
  uuid,
  study_uuid = '',
  style,
  onClick,
  children,
  renderActionMenu,
  onChange,
  isDisabled = false,
}: TableListItemComponentProps) => {
  const { t } = useTranslation();
  const [isListItemHovered, setListItemHovered] = React.useState(false);
  const [isActionButtonHovered, setActionButtonHovered] = React.useState(false);

  const getOwner = useCallback(
    (owner?: Owner | null) => {
      return owner?.name ? (
        owner?.name
      ) : owner?.type === LATANA_NAME ? (
        <ListItemOwnerCell>
          <LatanaText variant='B2' color='gray500'>
            {t('tableList.createdBy')}
          </LatanaText>
          <LatanaIconComponent />
        </ListItemOwnerCell>
      ) : null;
    },
    [t],
  );

  const getListItemIcon = useCallback((chartType: string, chartView?: string | null) => {
    const chartHasChartView = chartType === ChartType.COMPARISON || chartType === ChartType.FUNNEL;
    const chartIcon = chartTypeMap[chartView && chartHasChartView ? `comparison-${chartView}` : chartType];
    return <Icon icon={chartIcon} color='gray400' />;
  }, []);

  const handleItemClick = React.useCallback(
    (itemUuid: string, studyUuid: string) => {
      if (onChange) {
        return onChange(itemUuid);
      }

      onClick(itemUuid, studyUuid);
    },
    [onClick, onChange],
  );

  return (
    <ListItem
      style={style}
      isDisabled={isDisabled}
      data-testid={DataTestId.LIST_ITEM}
      onMouseEnter={() => setListItemHovered(true)}
      onMouseLeave={() => setListItemHovered(false)}
      isListItemHovered={isListItemHovered}
      isActionButtonHovered={isActionButtonHovered}
    >
      <ListItemDetailsContainer onClick={() => handleItemClick(uuid, study_uuid)}>
        {children}
        <ListItemDetails>
          {chart_type && getListItemIcon(chart_type, current_view)}
          <LatanaText variant='L1' color='gray900'>
            <span dangerouslySetInnerHTML={{ __html: markdownParser(name) }}></span>
          </LatanaText>
        </ListItemDetails>
        <ListItemDetails>
          <LatanaText variant='B2' color='gray500'>
            {getOwner(owner)}
          </LatanaText>
        </ListItemDetails>
        <ListItemDetails>
          <LatanaText variant='B2' color='gray500'>
            {updated_at && formatDate(updated_at)}
          </LatanaText>
          {renderActionMenu &&
            renderActionMenu({
              isHovered: isActionButtonHovered,
              setSelfHovered: setActionButtonHovered,
              setWrapperHovered: setListItemHovered,
            })}
        </ListItemDetails>
      </ListItemDetailsContainer>
    </ListItem>
  );
};

export default React.memo(TableListItem);
