import React from 'react';
import { Text } from 'common/styledComponents/typography';
import { roundValue } from 'utils/chart';
import { ArrowDownRedComponent, ArrowUpGreenComponent } from 'icons';
import { TooltipItem, LabelContainer, ValueContainer, SVGContainer, MoeContainer, Percentage, EvolutionContainer } from './styled';
import { ChartDataPointTooltipItem, ChartNumbersType } from 'common/types/chart';
import { isNotUndefined } from 'utils/helpers';
import { DefaultValues } from 'settings/constants';

const SVGCircle = ({ color }: { color: string }) => (
  <SVGContainer>
    <svg height='14' width='14'>
      <circle cx='7' cy='7' r='4' stroke='black' strokeWidth='1' fill={color} />
    </svg>
  </SVGContainer>
);

const PERCENTAGE = '%';

interface Props {
  tooltip: ChartDataPointTooltipItem;
  decimalsEnabled: boolean;
  moeEnabled: boolean | undefined;
  chartNumbersType: ChartNumbersType;
}

export const GeneralTooltip: React.FC<Props> = React.memo(({ tooltip, decimalsEnabled, moeEnabled, chartNumbersType }) => {
  const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;

  return (
    <TooltipItem highlight={tooltip.highlight}>
      <LabelContainer>
        {tooltip.labelColor ? <SVGCircle color={tooltip.labelColor as string} /> : null}
        <Text variant='span' color='white'>
          {tooltip.label}
        </Text>
      </LabelContainer>
      <ValueContainer>
        {tooltip.unavailable ? (
          <span>{DefaultValues.NA}</span>
        ) : (
          <>
            {showPopulationNumbers ? tooltip.populationValue : roundValue(tooltip.value, decimalsEnabled)}
            {!tooltip.numericValue && !showPopulationNumbers && <Percentage>{PERCENTAGE}</Percentage>}
            {moeEnabled && tooltip.moeValue && isNotUndefined(tooltip.moeValue) && (
              <MoeContainer>
                &plusmn;{showPopulationNumbers ? tooltip.moePopulationValue : roundValue(tooltip.moeValue, decimalsEnabled)}
              </MoeContainer>
            )}
          </>
        )}
        {tooltip.evolutionValue && (
          <EvolutionContainer>
            <>
              {tooltip.evolutionValue < 0 ? <ArrowDownRedComponent /> : <ArrowUpGreenComponent />}
              <Text variant='body2' color={tooltip.evolutionValue < 0 ? 'wine' : 'grass'}>
                {roundValue(tooltip.evolutionValue, decimalsEnabled)}
                {!tooltip.numericValue && <Percentage>{PERCENTAGE}</Percentage>}
              </Text>
            </>
          </EvolutionContainer>
        )}
      </ValueContainer>
    </TooltipItem>
  );
});

GeneralTooltip.displayName = 'GeneralTooltip';
