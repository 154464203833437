import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import Icon from 'common/components/Icon';
import { LatanaText } from 'common/components/LatanaText';
import { FilterNames } from 'common/types/filters';
import { ICONS } from 'icons/iconsMap';
import { createPortal } from 'react-dom';

const ChartFiltersBarContainer = styled.div`
  height: 16px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.v2.space(1.5)};

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 30px;
    background: linear-gradient(to right, transparent, ${({ theme }) => theme.v2.colours.gray0});
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.2s ease;
  }
`;

const ExpandedContent = styled.div`
  position: fixed;
  background: ${({ theme }) => theme.v2.colours.gray0};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.v2.space(1)};
  border-radius: 4px;
  z-index: 1000;
  white-space: nowrap;
  animation: fadeIn 0.2s ease;
  display: flex;
  margin-top: ${({ theme }) => theme.v2.space(-1)};
  margin-left: ${({ theme }) => theme.v2.space(-1)};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ChartFiltersInnerContainer = styled.div`
  display: flex;
  min-width: 0;
`;

const FilterItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.v2.space(1.5)};

  > svg {
    margin-right: ${({ theme }) => theme.v2.space(0.5)};
  }

  > div {
    white-space: nowrap;
    flex-shrink: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface Props {
  filters: {
    countries: string[];
    waves: string[];
    kpis: string[];
    brands: string[];
    segments: string[];
  };
}

const filterLabels = {
  [FilterNames.COUNTRY]: {
    singular: 'country',
    plural: 'countries',
  },
  [FilterNames.WAVE]: {
    singular: 'month',
    plural: 'months',
  },
  [FilterNames.KPI]: {
    singular: 'KPI',
    plural: 'KPIs',
  },
  [FilterNames.BRAND]: {
    singular: 'brand',
    plural: 'brands',
  },
  [FilterNames.SEGMENT]: {
    singular: 'segment',
    plural: 'segments',
  },
};

const filterPropertyMap = {
  [FilterNames.COUNTRY]: 'countries',
  [FilterNames.WAVE]: 'waves',
  [FilterNames.KPI]: 'kpis',
  [FilterNames.BRAND]: 'brands',
  [FilterNames.SEGMENT]: 'segments',
};

const getIconForFilter = (filterName: FilterNames) => {
  const iconMap = {
    [FilterNames.COUNTRY]: 'globe',
    [FilterNames.WAVE]: 'calendar',
    [FilterNames.KPI]: 'dashboard',
    [FilterNames.BRAND]: 'tag',
    [FilterNames.SEGMENT]: 'user-group',
  };

  return iconMap[filterName] as keyof typeof ICONS;
};

const getLabelForFilter = (filterName: FilterNames, values: string[]) => {
  if (!values.length) return '';
  if (values.length === 1) return values[0];

  const label = filterLabels[filterName];
  return `${values.length} ${label.plural}`;
};

const FilterItem = ({ filterName, values }: { filterName: FilterNames; values: string[] }) => {
  return (
    <FilterItemWrapper>
      <Icon icon={getIconForFilter(filterName)} color='gray500' size={16} />
      <LatanaText variant='B3' color='gray500'>
        {getLabelForFilter(filterName, values)}
      </LatanaText>
    </FilterItemWrapper>
  );
};

const ChartFiltersBar = ({ filters }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [expandedPosition, setExpandedPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setExpandedPosition({
        top: rect.top,
        left: rect.left,
      });
    }
    setIsHovered(true);
  };

  const getFilterValue = (filterName: FilterNames): string[] => filters[filterPropertyMap[filterName] as keyof typeof filters] ?? [];

  return (
    <ChartFiltersBarContainer ref={containerRef} onMouseEnter={handleMouseEnter} onMouseLeave={() => setIsHovered(false)}>
      <ChartFiltersInnerContainer>
        {Object.values(FilterNames).map(filterName => (
          <FilterItem filterName={filterName} values={getFilterValue(filterName)} key={`ChartFiltersBarItem-${filterName}`} />
        ))}
      </ChartFiltersInnerContainer>

      {isHovered &&
        createPortal(
          <ExpandedContent
            style={{
              top: `${expandedPosition.top}px`,
              left: `${expandedPosition.left}px`,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {Object.values(FilterNames).map(filterName => (
              <FilterItem filterName={filterName} values={getFilterValue(filterName)} key={`ChartFiltersBarItemExpanded-${filterName}`} />
            ))}
          </ExpandedContent>,
          document.body,
        )}
    </ChartFiltersBarContainer>
  );
};

export default React.memo(ChartFiltersBar);
