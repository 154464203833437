import React, { FC, memo } from 'react';
import { Text } from 'common/styledComponents/typography';
import { roundValue } from 'utils/chart';
import { TooltipItem, LabelContainer, ValueContainer, SVGContainer, MoeContainer, Percentage, ReferenceTitle, TextPills } from './styled';
import { useTranslation } from 'react-i18next';
import { getAverageColor } from 'modules/charts/utils/colors';
import { ChartNumbersType } from 'common/types/chart';

const SVGCircle = ({ color }: { color: string }) => (
  <SVGContainer>
    <svg height='14' width='14'>
      <circle cx='7' cy='7' r='4' stroke='black' strokeWidth='1' fill={color} />
    </svg>
  </SVGContainer>
);

interface Props {
  averageValue: number;
  decimalsEnabled: boolean;
  moeEnabled: boolean | undefined;
  averageCalculatedFrom?: Record<string, string>;
  averagePopulationValue: string | null;
  chartNumbersType: ChartNumbersType;
}

export const AverageTooltipBlock: FC<Props> = memo(
  ({ averageValue, decimalsEnabled, moeEnabled, averageCalculatedFrom, averagePopulationValue, chartNumbersType }) => {
    const { t } = useTranslation();
    const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;

    return (
      <React.Fragment>
        <TooltipItem>
          <LabelContainer>
            <SVGCircle color={getAverageColor()} />
            <Text variant='span' color='white'>
              {t('chart.tooltip.average')}
            </Text>
          </LabelContainer>
          <ValueContainer>
            {showPopulationNumbers && averagePopulationValue ? averagePopulationValue : roundValue(averageValue, decimalsEnabled)}
            {!showPopulationNumbers && <Percentage>%</Percentage>}
            {moeEnabled && <MoeContainer style={{ visibility: 'hidden' }}>&plusmn;{0}</MoeContainer>}
          </ValueContainer>
        </TooltipItem>
        <ReferenceTitle>
          <Text variant='span' color='white50' fontWeight='600'>
            {t('chart.tooltip.averageCalcFrom')}
          </Text>
        </ReferenceTitle>
        <TooltipItem>
          <TextPills variant='span' color='white'>
            {t('chart.tooltip.allSelectableBrands')}
          </TextPills>
        </TooltipItem>
        <TooltipItem>
          <TextPills variant='span' color='white'>
            {averageCalculatedFrom?.segment}
          </TextPills>
        </TooltipItem>
        <TooltipItem>
          <TextPills variant='span' color='white'>
            {averageCalculatedFrom?.kpi}
          </TextPills>
        </TooltipItem>
      </React.Fragment>
    );
  },
);

AverageTooltipBlock.displayName = 'AverageTooltipBlock';
