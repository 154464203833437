import * as React from 'react';
import { Text } from 'common/styledComponents/typography';
import { ChartDataKey, ChartNumbersType, ChartType, ChartViews, GeneratedChartData, SortOption, SortType } from 'common/types/chart';
import { ColorCircle } from 'modules/charts/styledComponents';
import { useTranslation } from 'react-i18next';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { useRecoilValue } from 'recoil';
import { MoeConfidenceLevelsAtom } from 'common/atoms/moe';
import { calculateMoeConfidence, MOE_LEVELS } from 'utils/moe';
import { uiPreferencesSelector } from 'common/atoms/account';
import { displayValue, roundValue } from 'utils/chart';
import { DataTestId, SORT_KEY_SEGMENT_SIZE } from 'settings/constants';
import { FilterNames } from 'common/types/filters';
import {
  DataTableWrapper,
  TableContainer,
  TableHeaderItem,
  TableBodyHeader,
  TableBodyHeaderInner,
  TableDataItem,
  TableBodyRow,
  ValueNameContainer,
  SortIconUp,
  SortIconDown,
  PercentageText,
  ItemText,
  MoeAsteriskWrapper,
} from './styled';
import { MoeModalDataProps } from 'common/types/common';
import Color from 'common/theme/color';
import MoeModal from 'modules/charts/components/MoeModal';
import { chartValueInMsOrKs } from 'utils/helpers';
import { useIsPopulationNumbersEnabled } from 'common/hooks/features';

interface Props {
  studyId: string;
  chartView: ChartViews | null;
  generatedChartData: GeneratedChartData;
  setSortHandle: (key: string) => void;
  sort: SortOption;
  toggleItem: (itemId: string) => void;
  moeStatus: boolean;
  chartNumbersType: ChartNumbersType;
  disableClickActions: boolean;
}

enum TableSymbols {
  UNAVAILABLE = '─',
  PERCENT = '%',
  ASTERISK = '*',
  PLUS = '+',
}

const SORT_KEY_DELTA = 'delta';

interface TableHeaderCellProps {
  label: string;
  isColorCircle: boolean;
  isSortable: boolean;
  circleColor?: Color & string;
  onSort: () => void;
  sortKey: string | null;
  sortType: string | null;
  keyName: string;
  dataTestId?: string;
}

const DataTable: React.FC<Props> = ({
  studyId,
  chartView,
  generatedChartData,
  setSortHandle,
  sort,
  toggleItem,
  moeStatus,
  chartNumbersType,
  disableClickActions = false,
}) => {
  const { keys: chartKeys, secondDimension, data: chartData, indexBy, chartType } = generatedChartData;
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [showMoeModal, setShowMoeModal] = React.useState(false);
  const [moeModalData, setMoeModalData] = React.useState<MoeModalDataProps | null>(null);

  const isGroupedByBrand = indexBy === FilterNames.BRAND;
  const isGroupedBySegment = indexBy === FilterNames.SEGMENT;

  const isChartViewLine = chartView === ChartViews.LINE;
  const showPopulationNumbers = chartNumbersType === ChartNumbersType.VALUES;

  const moeConfidenceLevels = useRecoilValue(MoeConfidenceLevelsAtom);
  const { decimalsEnabled } = useRecoilValue(uiPreferencesSelector);

  const isSegmentPopNumbersEnabled = useIsPopulationNumbersEnabled(studyId);
  const isShowSegmentSize = isSegmentPopNumbersEnabled && isGroupedBySegment;

  const isRadar = React.useMemo(() => {
    return chartType === ChartType.RADAR;
  }, [chartType]);

  const onShowMoeModal = React.useCallback(
    (moeModalData: MoeModalDataProps) => (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
      if (!moeStatus) return;
      event.stopPropagation();
      setMoeModalData(moeModalData);
      setShowMoeModal(true);
    },
    [setShowMoeModal, moeStatus],
  );

  const setSort = React.useCallback(
    (key: string) => () => {
      setSortHandle(key);
      trackEvent(TRACKING_EVENTS.SORT_TABLE);
    },
    [setSortHandle, trackEvent],
  );

  const toggleItemHandle = React.useCallback(
    (itemId: string) => () => {
      toggleItem(itemId);
      trackEvent(TRACKING_EVENTS.TOGGLE_VISIBILITY_TABLE_ROW);
    },
    [toggleItem, trackEvent],
  );

  const isShowDelta = React.useMemo(() => {
    return secondDimension === FilterNames.WAVE && chartKeys.length > 1;
  }, [secondDimension, chartKeys.length]);

  const renderDeltaColumn = React.useCallback(
    (delta: number | null, numericValue: boolean, deltaPopulation) => {
      const unavailable = delta === null;
      delta = roundValue(delta ?? 0, decimalsEnabled);
      const color = delta > 0 ? 'turqoise' : delta < 0 ? 'wine' : 'black';

      return (
        <>
          <TableDataItem>
            {unavailable ? (
              <PercentageText title='No data' variant='L1' color='gray300'>
                {TableSymbols.UNAVAILABLE}
              </PercentageText>
            ) : (
              <>
                <Text variant='body2' color={color}>
                  {delta > 0 && TableSymbols.PLUS}
                  {showPopulationNumbers ? deltaPopulation : displayValue(delta, decimalsEnabled)}
                </Text>
                {!numericValue && !showPopulationNumbers && (
                  <PercentageText variant='L1' color='gray300'>
                    {TableSymbols.PERCENT}
                  </PercentageText>
                )}
              </>
            )}
          </TableDataItem>
        </>
      );
    },
    [decimalsEnabled, showPopulationNumbers],
  );

  const SortIcon = ({ type }: { type: string | null }): JSX.Element | null => {
    if (type === SortType.ASC) return <SortIconUp />;
    if (type === SortType.DESC) return <SortIconDown />;
    return null;
  };

  const TableHeaderCell = React.useCallback(
    ({ label, isColorCircle, isSortable, circleColor, onSort, sortKey, sortType, keyName, dataTestId }: TableHeaderCellProps) => (
      <TableHeaderItem onClick={isSortable ? onSort : undefined} data-testid={dataTestId} data-disabled-actions={!isSortable}>
        {isColorCircle && <ColorCircle color={circleColor} style={{ marginRight: '6px' }} />}
        <Text variant='h6' color='black' fontWeight='700'>
          {label}
        </Text>
        {sortKey === keyName && <SortIcon type={sortType} />}
      </TableHeaderItem>
    ),
    [],
  );

  const renderHeader = React.useMemo(() => {
    const isShowColorCircle = !isGroupedByBrand || !isRadar || !isChartViewLine;
    const isSortable = chartData?.length > 1 && !disableClickActions;

    return (
      <React.Fragment>
        {chartKeys.map(keyName => {
          const itemKey = `${keyName}_label` as 'value_0_label';
          const label = chartData[0][itemKey];
          const circleColor = chartData[0][`${keyName}_label_color` as 'value_0_label_color'] as Color & string;
          return (
            <React.Fragment key={keyName}>
              {isShowSegmentSize && (
                <TableHeaderCell
                  dataTestId={DataTestId.SEGMENT_SIZE_COLUMN}
                  label={t('common.segmentSize')}
                  isSortable={isSortable}
                  isColorCircle={false}
                  keyName={SORT_KEY_SEGMENT_SIZE}
                  onSort={setSort(SORT_KEY_SEGMENT_SIZE)}
                  sortKey={sort.key}
                  sortType={sort.type}
                />
              )}
              <TableHeaderCell
                label={label}
                isSortable={isSortable}
                isColorCircle={isShowColorCircle}
                circleColor={circleColor}
                onSort={setSort(keyName)}
                keyName={keyName}
                sortKey={sort.key}
                sortType={sort.type}
              />
            </React.Fragment>
          );
        })}
        {isShowDelta && (
          <TableHeaderCell
            isSortable={true}
            sortKey={sort.key}
            sortType={sort.type}
            isColorCircle={false}
            label={t('chart.delta')}
            keyName={SORT_KEY_DELTA}
            onSort={setSort(SORT_KEY_DELTA)}
          />
        )}
      </React.Fragment>
    );
  }, [
    t,
    chartData,
    chartKeys,
    sort.key,
    sort.type,
    setSort,
    isShowDelta,
    isRadar,
    isGroupedByBrand,
    isChartViewLine,
    isShowSegmentSize,
    TableHeaderCell,
    disableClickActions,
  ]);

  const renderBody = React.useMemo(() => {
    return (
      <>
        {chartData.map(item => (
          <TableBodyRow
            key={item.groupId}
            onClick={!disableClickActions ? toggleItemHandle(item.groupId as string) : undefined}
            data-disabled-item={item.disabled}
            data-testid={item.groupId}
          >
            <TableBodyHeader data-disabled-actions={disableClickActions}>
              <TableBodyHeaderInner>
                <ValueNameContainer>
                  {(isChartViewLine || isGroupedByBrand) && (
                    <ColorCircle color={item.color as Color & string} style={{ marginRight: '8px' }} />
                  )}
                  <ItemText variant='body2' color='black'>
                    {item.groupLabel}
                  </ItemText>
                </ValueNameContainer>
              </TableBodyHeaderInner>
            </TableBodyHeader>
            {chartKeys.map(keyName => {
              const value = roundValue(item[keyName as ChartDataKey] as number, decimalsEnabled);
              const popNumberValue = item[`${keyName}_populationNumberValue` as ChartDataKey];
              const moeValue = item[`${keyName}_moe` as ChartDataKey] as number;
              const numericValue = item[`${keyName}_numeric` as ChartDataKey];
              const unavailable = item[`${keyName}_unavailable` as ChartDataKey];
              const exists = typeof value === 'number' && !unavailable;
              const moeConfidence = calculateMoeConfidence(moeValue, moeConfidenceLevels?.high, moeConfidenceLevels?.medium);
              const showConfidenceAsterisk = moeStatus && moeConfidence && moeConfidence.confidenceLevel !== MOE_LEVELS.HIGH;
              const firstDimensionId = item[`${keyName}_firstDimension` as ChartDataKey] as number;
              const secondDimensionId = item[`${keyName}_secondDimension` as ChartDataKey] as string;
              const populationValue = item[`${keyName}_populationNumberRaw` as ChartDataKey] as number;
              const segmentSize = chartValueInMsOrKs(item[`${keyName}_segmentSize` as ChartDataKey] as number, null);
              return (
                <React.Fragment key={keyName}>
                  {isShowSegmentSize && (
                    <TableDataItem>
                      <Text variant='body2' color='black'>
                        {segmentSize}
                      </Text>
                    </TableDataItem>
                  )}
                  <TableDataItem
                    isClickable={moeStatus && !disableClickActions}
                    onClick={
                      exists && !disableClickActions ? onShowMoeModal({ firstDimensionId, secondDimensionId, populationValue }) : undefined
                    }
                  >
                    {exists ? (
                      <React.Fragment>
                        <Text variant='body2' color='black'>
                          {showPopulationNumbers ? popNumberValue : displayValue(value, decimalsEnabled)}
                        </Text>
                        {!numericValue && !showPopulationNumbers && (
                          <PercentageText variant='L1' color='gray300'>
                            {TableSymbols.PERCENT}
                          </PercentageText>
                        )}
                        {showConfidenceAsterisk && (
                          <MoeAsteriskWrapper color={moeConfidence?.color}>
                            <Text variant='body2' color='inherit'>
                              {TableSymbols.ASTERISK}
                            </Text>
                          </MoeAsteriskWrapper>
                        )}
                      </React.Fragment>
                    ) : (
                      <PercentageText title='No data' variant='L1' color='gray300'>
                        {TableSymbols.UNAVAILABLE}
                      </PercentageText>
                    )}
                  </TableDataItem>
                </React.Fragment>
              );
            })}
            {isShowDelta && renderDeltaColumn(item.delta, item[`value_0_numeric`], item.deltaPopulation)}
          </TableBodyRow>
        ))}
      </>
    );
  }, [
    chartData,
    chartKeys,
    toggleItemHandle,
    isShowDelta,
    renderDeltaColumn,
    moeConfidenceLevels,
    moeStatus,
    onShowMoeModal,
    decimalsEnabled,
    isGroupedByBrand,
    showPopulationNumbers,
    isChartViewLine,
    isShowSegmentSize,
    disableClickActions,
  ]);

  return (
    <DataTableWrapper>
      <TableContainer data-testid={DataTestId.DATA_TABLE}>
        <thead>
          <tr>
            <TableHeaderItem />
            {/** Render table header */}
            {renderHeader}
          </tr>
        </thead>
        <tbody>
          {/** Render table rows */}
          {renderBody}
        </tbody>
      </TableContainer>
      {moeStatus && (
        <MoeModal
          showPanel={showMoeModal}
          handleClose={() => setShowMoeModal(false)}
          moeModalData={moeModalData}
          studyId={studyId}
          showPopulationNumbers={showPopulationNumbers}
        />
      )}
    </DataTableWrapper>
  );
};

export default DataTable;
