import styled from '@emotion/styled';
import { theme } from 'common/theme';
import React from 'react';
import { GAUGE_COLORS } from 'settings/constants';

export const CircleBackground = styled.circle`
  stroke: ${theme.colors.black05.toString()};
  fill: none;
`;

export const CircleProgress = styled.circle<{ shade: string }>`
  stroke: ${({ shade }) => shade};
  fill: none;
`;

interface Props {
  sqSize: number;
  gapUnit: number;
  percentage: number;
}

const GAP = 4; // constant gap between two rings.
const STROKE_WIDTH = 14;

const GaugeChart = ({ sqSize, percentage, gapUnit }: Props) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - STROKE_WIDTH) / 2 + (STROKE_WIDTH + GAP) * gapUnit;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <>
      <CircleBackground cx={sqSize / 2} cy={sqSize / 2} r={radius} strokeWidth={`${STROKE_WIDTH}px`} />
      <CircleProgress
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        shade={GAUGE_COLORS[gapUnit]}
        strokeWidth={`${STROKE_WIDTH}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{ strokeDasharray: dashArray, strokeDashoffset: dashOffset }}
      />
    </>
  );
};

export default React.memo(GaugeChart);
