import Modal from 'common/components/Modal';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components/Form/Button';
import { ButtonWrapper, Content } from 'common/styledComponents/modals';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  open: boolean;
  children: React.ReactNode;
  confirmButtonTitle?: string;
}

const DeleteItemModal: React.FC<Props> = ({ open, onCancel, onConfirm, title, children, confirmButtonTitle }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={open} title={title} customWidth={564} handleClose={onCancel} showCloseButton={false}>
      <Content>{children}</Content>
      <ButtonWrapper width='60%' justify='space-between'>
        <Button type='button' color='wine' onClick={onConfirm}>
          {confirmButtonTitle ? confirmButtonTitle : t('common.delete')}
        </Button>
        <Button type='button' pseudo color='black05' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default React.memo(DeleteItemModal);
