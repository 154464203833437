import { useGetFeatures } from 'common/queries/features';
import { FeatureGates } from 'common/types/account';
import { useCallback, useMemo } from 'react';
import { useKpisList } from 'common/hooks/kpis';
import { BRAND_PERCEPTION_KPI, MARKET_FUNNEL_KPIS, MARKET_SIZE_KPI } from 'settings/constants';
import { FEATURES, RADAR_CHART_KPIS } from 'common/atoms/constants';
import { createKpiMap } from 'utils/kpi';

const mergeFeatureGates = (featureGates: FeatureGates, studyId?: string): string[] => {
  if (!studyId) return featureGates.customer || [];
  const studyFeatureGates = featureGates.studies[studyId] || [];
  const customerFeatureGates = featureGates.customer || [];
  return [...studyFeatureGates, ...customerFeatureGates];
};

export const useGetAllFeaturesGates = (studyId?: string): string[] => {
  const { data: featuresGates } = useGetFeatures();

  return useMemo(() => {
    return featuresGates ? mergeFeatureGates(featuresGates, studyId) : [];
  }, [featuresGates, studyId]);
};

export const useIsMarketSizeChartsEnabled = (): ((studyId: string) => boolean) => {
  const getKpiList = useKpisList();

  const isMarketSizeChartsEnabled = useCallback(
    (studyId: string) => {
      const marketSizeKpi = getKpiList(studyId)?.find(({ kpi_identifier }) => kpi_identifier === MARKET_SIZE_KPI);
      return !!marketSizeKpi;
    },
    [getKpiList],
  );

  return isMarketSizeChartsEnabled;
};

export const useIsRadarChartsEnabled = (): ((studyId: string) => boolean) => {
  const getKpiList = useKpisList();

  const isRadarChartsEnabled = useCallback(
    (studyId: string) => {
      const radarChartKPIs = getKpiList(studyId)?.find(({ kpi_identifier }) => Object.values(RADAR_CHART_KPIS).includes(kpi_identifier));
      return !!radarChartKPIs;
    },
    [getKpiList],
  );

  return isRadarChartsEnabled;
};

export const useIsReferenceAverageEnabled = (studyId?: string): boolean => {
  const features = useGetAllFeaturesGates(studyId);

  return useMemo(() => {
    return features.includes(FEATURES.REFERENCE_AVERAGE);
  }, [features]);
};

export const useIsBrandPerceptionChartsEnabled = (): ((studyId: string) => boolean) => {
  const getKpiList = useKpisList();

  const isBrandPerceptionChartsEnabled = useCallback(
    (studyId: string) => {
      const brandPerceptionKpi = getKpiList(studyId).find(({ kpi_identifier }) => kpi_identifier === BRAND_PERCEPTION_KPI);
      return !!brandPerceptionKpi;
    },
    [getKpiList],
  );

  return isBrandPerceptionChartsEnabled;
};

export const useIsMarketFunnelChartsEnabled = (): ((studyId: string) => boolean) => {
  const getKpiList = useKpisList();

  const isMarketFunnelChartsEnabled = useCallback(
    (studyId: string) => {
      const marketFunnelKpisExist = MARKET_FUNNEL_KPIS.every(kpi => getKpiList(studyId)?.some(x => x.kpi_identifier === kpi));
      return marketFunnelKpisExist;
    },
    [getKpiList],
  );

  return isMarketFunnelChartsEnabled;
};

export const useIsCompetitorsEnabled = (studyId?: string): boolean => {
  const features = useGetAllFeaturesGates(studyId);

  return useMemo(() => {
    return features.includes(FEATURES.COMPETITORS);
  }, [features]);
};

export const useIsOldSurveyBrandAwarenessEnabled = (studyId: string): boolean => {
  const features = useGetAllFeaturesGates(studyId);

  return useMemo(() => {
    return features.includes(FEATURES.OLD_SURVEY_BA_BRANCHING);
  }, [features]);
};

export const useHidePopulationNumbersEnabled = (studyId: string): boolean => {
  const features = useGetAllFeaturesGates(studyId);

  return useMemo(() => {
    return features.includes(FEATURES.HIDE_POPULATION_NUMBERS);
  }, [features]);
};

export const useIsPopulationNumbersEnabled = (studyId?: string): boolean => {
  const features = useGetAllFeaturesGates(studyId);

  return useMemo(() => {
    return features.includes(FEATURES.ENABLE_POPULATION_NUMBERS);
  }, [features]);
};

export const useIsMatrixChartsEnabled = (): ((studyId: string) => boolean) => {
  const getKpiList = useKpisList();
  const features = useGetAllFeaturesGates();

  const isMatrixChartsEnabled = useCallback(
    (studyId: string) => {
      const kpiMap = createKpiMap(getKpiList(studyId));
      const kpiIds = Object.keys(kpiMap);

      /* Check if there are more than 1 kpi and at least one kpi does not start with "brand-perception", 
      because we merge multiple brand-perception into single net sentiment */
      const hasNonBrandConsiderationKey = kpiIds.length > 1 && kpiIds.some(key => !key.startsWith(BRAND_PERCEPTION_KPI));

      return features.includes(FEATURES.MATRIX_CHART) && hasNonBrandConsiderationKey;
    },
    [getKpiList, features],
  );

  return isMatrixChartsEnabled;
};

export const useIsChartFiltersOnDashboardEnabled = (studyId?: string): boolean => {
  const features = useGetAllFeaturesGates(studyId);

  return useMemo(() => {
    return features.includes(FEATURES.SHOW_CHART_FILTERS_ON_DASHBOARD);
  }, [features]);
};
