import { useCallback, useMemo } from 'react';
import { Country } from 'common/types/common';
import { useGetAllCountries } from 'common/queries/countries';
import { uniqueListItemsByIdentifier } from 'utils/helpers';

export const useGetCountriesMap = (): Record<string, Country[]> => {
  const { data: countriesList } = useGetAllCountries();

  return useMemo(() => {
    if (!countriesList) return {}; // Return an empty object if countriesList is falsy

    return countriesList.reduce((map, data) => {
      map[data.study_uuid as string] = data.countries;
      return map;
    }, {} as Record<string, Country[]>); // Initialize with proper type
  }, [countriesList]);
};

export const useGetAllCountriesUniqueList = (): Country[] => {
  const contriesMap = useGetCountriesMap();

  return useMemo(() => {
    if (!contriesMap) return [];

    const allCountries = Object.values(contriesMap).flatMap(countries => countries);
    return uniqueListItemsByIdentifier(allCountries, 'code');
  }, [contriesMap]);
};

export const useGetCountriesByStudyId = (): ((studyId: string) => Country[]) => {
  const countriesMap = useGetCountriesMap();

  const getCountriesByStudyId = useCallback(
    (studyId: string) => {
      if (!countriesMap || !studyId) return [];
      return countriesMap[studyId];
    },
    [countriesMap],
  );

  return getCountriesByStudyId;
};

export const useGetCountryIdMapByStudyId = (): ((studyId: string) => Record<number, Country>) => {
  const getCountriesByStudyId = useGetCountriesByStudyId();

  const getCountryIdMapByStudyId = useCallback(
    (studyId: string) => {
      const countryStudyIdList = getCountriesByStudyId(studyId);

      return countryStudyIdList?.reduce((acc, country) => {
        acc[country.id] = country;
        return acc;
      }, {} as Record<number, Country>);
    },
    [getCountriesByStudyId],
  );

  return getCountryIdMapByStudyId;
};
