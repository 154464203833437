import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FilterColumnOption, FilterNames, IFilterGroupOption, IFilterOption, FilterOptionType } from 'common/types/filters';
import { NO_BRAND } from 'settings/constants';
import { permissionsSelector } from 'common/atoms/account';
import FilterModalOption from 'modules/charts/components/Filters/FilterModal/FilterModalOption';
import PillCheckbox from 'common/components/PillCheckbox';
import { ChartFormData } from 'common/types/chart';
import { DynamicWaves } from 'settings/constants';
import { isAllSelected } from 'utils/filters';
import { allWavesStateAtom } from 'common/atoms/waves';
import { KPIFilterService } from 'modules/charts/components/Filters/services/kpi';
import { GroupTitle, GroupTitleInner, ColumnContainer, Space, GroupTitleWrapper } from './styled';
import { useIsCompetitorsEnabled } from 'common/hooks/features';
import { withTooltip } from 'common/components/Tooltip/withTooltip';
import Icon from 'common/components/Icon';
import { Fragment } from 'react';

interface Props {
  items: FilterColumnOption[];
  selectedMap: Record<string, boolean>;
  availableMap: Record<string, boolean>;
  isSegmentSection: boolean;
  onOptionToggle: (newValues: number | string) => void;
  disabledUnselect: boolean;
  name: FilterNames;
  onHandleSelectAll: (filterId: FilterNames, values: (string | number)[]) => void;
  onHandleDeselectAll: (filterId: FilterNames) => void;
  values: ChartFormData;
  singleSelection?: boolean;
  isAllOptionsLimit?: boolean;
  onHandleSelectCompetitorBrands: () => void;
  isDirectCompetitorsAvailable: boolean;
}

// eslint-disable-next-line react/display-name
const FilterModalColumn: React.FC<Props> = ({
  items,
  selectedMap,
  onOptionToggle,
  disabledUnselect,
  availableMap,
  name,
  isSegmentSection,
  onHandleSelectAll,
  onHandleDeselectAll,
  singleSelection,
  values,
  isAllOptionsLimit,
  onHandleSelectCompetitorBrands,
  isDirectCompetitorsAvailable,
}) => {
  const { chart_edit: isEditor } = useRecoilValue(permissionsSelector);
  const [allWavesState] = useRecoilState(allWavesStateAtom);
  const isGlobalKPI = KPIFilterService.isGlobalKPI;
  const isOrgProfileEnabled = useIsCompetitorsEnabled(values.study_uuid);
  const isBrandFilter = name === FilterNames.BRAND;
  const isWaveFilter = name === FilterNames.WAVE;
  const stackHorizontal = isBrandFilter;

  const isOnlyOption = React.useMemo(() => {
    return Object.keys(availableMap)?.length <= 1;
  }, [availableMap]);

  const getAllSelectedOptions = (options: IFilterOption[], isGlobalKPI: boolean, availableMap: Record<string, boolean>) => {
    return options.flatMap(({ value, label }) => {
      if (label === NO_BRAND) {
        return isGlobalKPI ? value : [];
      } else {
        return availableMap[value] ? value : [];
      }
    });
  };

  const getCurrentOptions = (items: FilterColumnOption[], label: string) => {
    const foundItem = items.find(({ group }: Record<string, any>) => group?.label === label) as IFilterGroupOption;
    return foundItem?.group?.options;
  };

  const isMoreThanOneOption = React.useCallback((items: FilterColumnOption[], itemLabel: string) => {
    return getCurrentOptions(items, itemLabel)?.length > 1;
  }, []);

  const selectAllOptionsHandle = (
    label: string,
    items: FilterColumnOption[],
    isGlobalKPI: boolean,
    availableMap: Record<string, boolean>,
  ) => {
    const currentOptions = getCurrentOptions(items, label);
    const allSelectedOptions = getAllSelectedOptions(currentOptions, isGlobalKPI, availableMap);
    onHandleSelectAll(name, allSelectedOptions);
  };

  const isAllOptionSelected = (
    label: string,
    items: FilterColumnOption[],
    isGlobalKPI: boolean,
    availableMap: Record<string, boolean>,
  ): boolean => {
    const currentOptions = getCurrentOptions(items, label);
    const allSelectedOptions = getAllSelectedOptions(currentOptions, isGlobalKPI, availableMap);
    return isAllSelected(allSelectedOptions, values[name]);
  };

  const isAllOptionDisabled = (itemLabel: string) => {
    return isAllOptionSelected(itemLabel, items, isGlobalKPI, availableMap) || (isAllOptionsLimit && allWavesState);
  };

  const isOptionSelected = React.useCallback((selectedMap: Record<string, boolean>, optionValue: string | number) => {
    return !!selectedMap[optionValue];
  }, []);

  const isDirectCompetitorsEnabled = React.useCallback(
    (items: FilterColumnOption[], itemsLabel: string) => {
      return !!(
        isBrandFilter &&
        !singleSelection &&
        isMoreThanOneOption(items, itemsLabel) &&
        !isOnlyOption &&
        isOrgProfileEnabled &&
        isDirectCompetitorsAvailable
      );
    },
    [isMoreThanOneOption, isOnlyOption, isOrgProfileEnabled, singleSelection, isDirectCompetitorsAvailable, isBrandFilter],
  );

  const handleSelectDeselectToggle = (
    label: string,
    items: FilterColumnOption[],
    isGlobalKPI: boolean,
    availableMap: Record<string, boolean>,
  ) => {
    return isAllOptionDisabled(label) ? onHandleDeselectAll(name) : selectAllOptionsHandle(label, items, isGlobalKPI, availableMap);
  };

  return (
    <ColumnContainer horizontal={stackHorizontal}>
      {items.map((item, index) => {
        switch (item.type) {
          case FilterOptionType.GROUP: {
            return (
              <Fragment key={item.label}>
                <GroupTitleWrapper key={item.label} horizontal={stackHorizontal}>
                  <GroupTitle title={item.label} color='black25' variant='h6'>
                    <GroupTitleInner>{item.label}</GroupTitleInner>
                    {item.infoMessage && withTooltip(<Icon icon='info' color='gray600' />, item.infoMessage)}
                  </GroupTitle>
                </GroupTitleWrapper>
                {!isWaveFilter && !singleSelection && isMoreThanOneOption(items, item.label) && !isOnlyOption && (
                  <PillCheckbox
                    name={`${name}`}
                    selected={false}
                    type={DynamicWaves.SELECT_ALL_BUTTON}
                    label={isAllOptionDisabled(item.label) ? 'Deselect All' : 'Select All'}
                    onChange={() => handleSelectDeselectToggle(item.label, items, isGlobalKPI, availableMap)}
                  />
                )}
                {isDirectCompetitorsEnabled(items, item.label) && (
                  <PillCheckbox
                    name={`${name}.competitors`}
                    label='Direct Competitors'
                    selected={false}
                    type={DynamicWaves.SELECT_ALL_BUTTON}
                    disabled={false}
                    onChange={() => onHandleSelectCompetitorBrands()}
                  />
                )}
              </Fragment>
            );
          }
          case FilterOptionType.OPTION: {
            const selected = isOptionSelected(selectedMap, item.value);
            const unselectDisabled = disabledUnselect && selected;
            const isNoBrand = item.label === NO_BRAND;
            const disabled = !(availableMap[item.value] || false) || (!isGlobalKPI && isNoBrand);
            const readOnly = item.readOnly || unselectDisabled || !isEditor || (isGlobalKPI && isNoBrand);
            return !disabled ? (
              <FilterModalOption
                key={`${name}.${index}`}
                selected={selected}
                onChange={readOnly ? undefined : onOptionToggle}
                disabled={disabled}
                name={`${name}.${index}`}
                label={item.label}
                value={item.value}
                isSegmentSection={isSegmentSection}
                disabledUnselect={readOnly}
                studyId={values.study_uuid}
              />
            ) : null;
          }
          case FilterOptionType.SPACE: {
            return <Space key={index} size={item.size} />;
          }
          default:
            return null;
        }
      })}
    </ColumnContainer>
  );
};

export default React.memo(FilterModalColumn);
