import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { useRecoilValue } from 'recoil';
import { DashboardDetails, DashboardPayload } from 'common/types/dashboard';
import { LogArgument } from 'rollbar';
import { useShowToast } from 'common/hooks/notification';
import { useTranslation } from 'react-i18next';
import { ToastContent } from 'common/types/notification';
import getRollbarConfig from 'rollbarConfig';
import { useNavigate } from 'react-router-dom';

export interface DashboardMutationFnOptions {
  toast?: ToastContent;
  shouldNavigate?: boolean;
}

export const useGetDashboards = (): UseQueryResult<DashboardDetails[], unknown> => {
  const { fetchGet } = useRecoilValue(fetchSelector);
  return useQuery(
    ['dashboards'],
    async () => {
      try {
        return await fetchGet<DashboardDetails[]>(API_URLS.DASHBOARDS);
      } catch (e: unknown) {
        const rollbar = getRollbarConfig();
        rollbar.error('useGetDashboards error', e as LogArgument);
      }
    },
    {
      staleTime: Infinity,
      placeholderData: [],
    },
  );
};

export const useDeleteDashboard = () => {
  const { fetchDelete } = useRecoilValue(fetchSelector);
  const queryClient = useQueryClient();
  const showToast = useShowToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteDashboard = async ({ id, options }: { id: string; options?: DashboardMutationFnOptions }) => {
    try {
      await fetchDelete(`${API_URLS.DASHBOARDS}/${id}`);
      await queryClient.invalidateQueries(['dashboards']);
      showToast(options?.toast);

      if (options?.shouldNavigate) {
        navigate(`/dashboards`);
      }
    } catch (error) {
      const rollbar = getRollbarConfig();
      showToast({ message: t('toast.genericError'), status: 'error' });
      rollbar.error('Delete dashboard error', error as LogArgument);
    }
  };

  return useMutation({
    mutationKey: ['deleteDashboard'],
    mutationFn: deleteDashboard,
  });
};

export const useCreateDashboard = () => {
  const { fetchPost } = useRecoilValue(fetchSelector);
  const queryClient = useQueryClient();
  const showToast = useShowToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createDashboard = async ({ payload, options }: { payload: DashboardPayload; options?: DashboardMutationFnOptions }) => {
    try {
      const dashboard: DashboardDetails = await fetchPost(API_URLS.DASHBOARDS, payload);
      await queryClient.invalidateQueries(['dashboards']);
      showToast(options?.toast);

      if (options?.shouldNavigate) {
        navigate(`/dashboard/${dashboard?.uuid}/`);
      }
    } catch (error) {
      const rollbar = getRollbarConfig();
      showToast({ message: t('toast.genericError'), status: 'error' });
      if ((error as { status: number })?.status === 422) {
        rollbar.error('Create dashboard error', error as LogArgument);
      } else {
        rollbar.info('Something went wrong with Create Dashboard API');
      }
    }
  };

  return useMutation({
    mutationKey: ['createDashboard'],
    mutationFn: createDashboard,
  });
};

export const useUpdateDashboard = () => {
  const { fetchPut } = useRecoilValue(fetchSelector);
  const queryClient = useQueryClient();
  const showToast = useShowToast();
  const { t } = useTranslation();

  const updateDashboard = async ({
    dashboardId,
    payload,
    options,
  }: {
    dashboardId: string;
    payload: DashboardPayload;
    options?: DashboardMutationFnOptions;
  }) => {
    try {
      await fetchPut(API_URLS.DASHBOARD(dashboardId), payload);
      await queryClient.invalidateQueries(['dashboards']);
      showToast(options?.toast);
    } catch (error) {
      const rollbar = getRollbarConfig();
      showToast({ message: t('toast.genericError'), status: 'error' });
      if ((error as { status: number })?.status === 422) {
        rollbar.error('Update dashboard error', error as LogArgument);
      } else {
        rollbar.info('Something went wrong with Update Dashboard API');
      }
    }
  };

  return useMutation({
    mutationKey: ['updateDashboard'],
    mutationFn: updateDashboard,
  });
};

export const useDuplicateDashboard = () => {
  const { fetchPost } = useRecoilValue(fetchSelector);
  const queryClient = useQueryClient();
  const showToast = useShowToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const duplicateDashboard = async ({ dashboardId, options }: { dashboardId: string; options?: DashboardMutationFnOptions }) => {
    try {
      const dashboard: DashboardDetails = await fetchPost(API_URLS.DASHBOARD_DUPLICATE(dashboardId));
      await queryClient.invalidateQueries(['dashboards']);
      showToast(options?.toast);

      if (options?.shouldNavigate) {
        navigate(`/dashboard/${dashboard?.uuid}/`);
      }
    } catch (error) {
      const rollbar = getRollbarConfig();
      showToast({ message: t('toast.genericError'), status: 'error' });
      if ((error as { status: number })?.status === 422) {
        rollbar.error('Duplicate dashboard error', error as LogArgument);
      } else {
        rollbar.info('Something went wrong with Duplicate Dashboard API');
      }
    }
  };

  return useMutation({
    mutationKey: ['duplicateDashboard'],
    mutationFn: duplicateDashboard,
  });
};
