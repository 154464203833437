import React from 'react';
import styled from '@emotion/styled';
import { useRollbar } from '@rollbar/react';
import { useRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { theme } from 'common/theme';
import SettingsHeader from 'modules/settings/components/SettingsHeader';
import { Button } from 'common/components/Form/Button';
import TextInput from 'common/components/TextInput';
import MembersList from 'modules/settings/components/Members/MembersList';
import Select from 'common/components/Select';
import { RoleOption, UserInviteForm, UserData } from 'common/types/roles';
import { MembersAtom, RolesAtom } from 'common/atoms/roles';
import { useMembers, useInviteUser, useRoles } from 'common/hooks/roles';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/styledComponents/typography';
import DeleteItemModal from 'common/components/DeleteItemModal';
import { useRetrieveUserRole, useUpdateUserRole } from 'common/hooks/roles';

const Form = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.space(1)};
`;

const DropDownWrapper = styled.div`
  margin: ${theme.space(0, 6, 0, 4)};
`;

const MessageText = styled(Text)`
  margin-left: ${theme.space(1)};
`;

interface StatusMessage {
  message: string;
  type: 'error' | 'warning' | 'success';
}

const Members = (): JSX.Element => {
  const [userData, setUserData] = React.useState({ id: 0, role_id: '', email: '' });
  const rollbar = useRollbar();
  const [modalState, setModalState] = React.useState(false);

  const [statusMessage, setStatusMessage] = React.useState<StatusMessage | null>(null);
  const { register, handleSubmit, reset, setValue } = useForm<UserInviteForm>({});

  const { fetchRoles } = useRoles();
  const { fetchMembers } = useMembers();
  const inviteUser = useInviteUser();
  const { t } = useTranslation();

  const members = useRecoilState(MembersAtom);
  const roles = useRecoilState(RolesAtom);

  const updateUserRole = useUpdateUserRole();
  const retrieveUserRole = useRetrieveUserRole();

  const onSubmit = (invitedUserData: { email: string; role_id: string }) => {
    inviteUser(invitedUserData)
      .then(() => {
        setStatusMessage({
          message: 'The invitation has been sent.',
          type: 'success',
        });
        fetchMembers();
      })
      .catch(e => {
        rollbar.error('Invite user error', e);
        setStatusMessage({
          message: e?.error?.[0] || 'Unknown error during members submit',
          type: 'error',
        });
      });
    reset();
  };

  React.useEffect(() => {
    fetchRoles();
    fetchMembers();
  }, [fetchMembers, fetchRoles]);

  const handleModalStateChange = React.useCallback(() => {
    setModalState(!modalState);
  }, [modalState]);

  const handleAttemptToUpdateUserRole = React.useCallback(
    (userData: UserData) => {
      handleModalStateChange();
      setUserData(userData);
    },
    [handleModalStateChange],
  );

  const handleUpdateUserRole = React.useCallback(() => {
    if (userData.role_id) updateUserRole(userData);
    else retrieveUserRole(userData.id);

    handleModalStateChange();
  }, [updateUserRole, handleModalStateChange, retrieveUserRole, userData]);

  return (
    <div>
      <SettingsHeader title={t('members.title')} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          ref={register}
          autoFocus
          placeholder={t('members.inviteUser')}
          variant='gray'
          name='email'
          type='email'
          required
          marginBottom={false}
        />
        <DropDownWrapper>
          {roles[0] && (
            <Select
              ref={register}
              name='role_id'
              options={roles[0] as RoleOption[]}
              value={roles?.[0]?.[0].value || ''}
              onSelect={value => setValue('role_id', value)}
            />
          )}
        </DropDownWrapper>
        <Button type='submit' color='lightPurple'>
          {t('members.invite')}
        </Button>
      </Form>
      {statusMessage && (
        <MessageText color={statusMessage?.type === 'error' ? 'wine' : 'turqoise'} variant='body2'>
          {statusMessage?.message}
        </MessageText>
      )}
      <MembersList members={members[0]} onHandleUpdateUserRole={handleAttemptToUpdateUserRole} />
      <DeleteItemModal
        confirmButtonTitle={userData.role_id ? t('common.update') : t('common.remove')}
        title={userData.role_id ? t('members.reassignUserRoleModal.title') : t('members.removeUserModal.title')}
        open={modalState}
        onCancel={handleModalStateChange}
        onConfirm={handleUpdateUserRole}
      >
        <Text variant='h5'>
          {`${userData.role_id ? t('members.reassignUserRoleModal.heading') : t('members.removeUserModal.heading')} ${userData.email}?`}
          <br />
          {t('members.reassignUserRoleModal.subheading')}
        </Text>
      </DeleteItemModal>
    </div>
  );
};

export default React.memo(Members);
