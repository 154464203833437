import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { scrollbarStyles } from 'common/styledComponents/mixins';

export const ModalTitle = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--gs-7_black);
  border-bottom: 1px solid var(--gs-4);
  margin-top: 24px;
  text-align: center;
  font-family: Mulish;
  &[data-align='left'] {
    text-align: left;
  }
  &[data-modal-v2='true'] {
    font-size: ${({ theme }) => theme.v2.text.variants.H3.fontSize};
    font-weight: ${({ theme }) => theme.v2.text.variants.H3.fontWeight};
    line-height: ${({ theme }) => theme.v2.text.variants.H3.lineHeight};
    letter-spacing: ${({ theme }) => theme.v2.text.variants.H3.letterSpacing};
    margin-top: 0;
    border-bottom: none;
    padding: ${({ theme }) => theme.v2.space(1.5, 0)};
  }
`;

export const ModalSubtitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1.63;
  color: #a0a0a0;
`;

export const ModalIconButtonClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
`;

export const ModalOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255, 0.8);
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  &[data-ready='true'][aria-hidden='true'] {
    visibility: hidden;
    animation: 0.2s ${fadeOut} ease-out forwards;
  }
  &[data-ready='true'][aria-hidden='false'] {
    visibility: visible;
    animation: 0.2s ${fadeIn} ease-in forwards;
  }
`;

export const ModalInner = styled.div<{
  width: number;
  spacing: number[];
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.25);
  width: ${({ width }) => `${width}px`};
  font-family: Mulish;
  padding: ${({ theme, spacing }) => theme.space(...spacing)};
  max-height: 80vh;
  z-index: 1;
  position: relative;
  &[data-sticky-header='true'] {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  &[data-modal-v2='true'] {
    padding: ${({ theme }) => theme.v2.space(1)};
    background: ${({ theme }) => theme.v2.colours.indigo50};
    border-radius: ${({ theme }) => theme.v2.borderRadius * 2}px;
    max-height: initial;
  }
`;

export const IconClose = styled.i`
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  flex-basis: 16px;
  height: 16px;
  user-select: none;
  background-image: url(/icons/close.svg);
  width: 16px;
`;

export const Inner = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;

  &[data-sticky='true'] {
    flex-shrink: 1;
    max-height: 100%;
    overflow: auto;
  }

  // Apply the scrollbar styles
  ${scrollbarStyles()}

  &[data-modal-v2='true'] {
    width: 100%;
  }
`;

export const BottomContainer = styled.div`
  padding: ${({ theme }) => theme.space(4, 0, 0)};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.v2.colours.gray0};
  padding: ${({ theme }) => theme.v2.space(2, 2.5)};
  border-radius: ${({ theme }) => theme.v2.borderRadius * 2}px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 8px;
`;

// Components for ModalV2
export const ModalContainer = styled.div<{
  width: number;
  spacing: number[];
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.v2.colours.indigo50};
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.25);
  width: ${({ width }) => `${width}px`};
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  padding: ${({ theme }) => theme.v2.space(1)};
  max-height: 80vh;
  z-index: 1;
  position: relative;
  border-radius: ${({ theme }) => theme.v2.borderRadius * 2}px;
  max-height: initial;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.v2.space(2)};
  width: 100%;
`;

export const ModalHeading = styled.div`
  width: 100%;
  font-weight: ${({ theme }) => theme.v2.text.variants.H3.fontWeight};
  font-size: ${({ theme }) => theme.v2.text.variants.H3.fontSize};
  line-height: ${({ theme }) => theme.v2.text.variants.H3.lineHeight};
  color: ${({ theme }) => theme.colors.black.toString()};
  font-family: ${({ theme }) => theme.v2.text.fontFamily};
  text-align: center;
`;

export const ModalContent = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  width: 100%;

  // Apply the scrollbar styles
  ${scrollbarStyles()}
`;

export const CloseButtonContainer = styled.div`
  cursor: pointer;
`;

// Dashboard modal components
export const DashboardChartsModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.v2.space(2)};
  > :nth-of-type(1) {
    margin-right: ${({ theme }) => theme.v2.space(1)};
  }
`;

export const DashboardChartsModalContent = styled.div`
  width: 100%;
  background-color: white;
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  padding: ${({ theme }) => theme.v2.space(2)};
  height: 464px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
