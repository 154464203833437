import * as React from 'react';

interface AuthContextProps {
  logout: () => void;
}

const AuthContext = React.createContext<AuthContextProps>({
  logout: () => null,
});

export const useAuthContext = (): AuthContextProps => React.useContext(AuthContext);

export default AuthContext;
