import React from 'react';
import { useTranslation } from 'react-i18next';
import { Point } from '@nivo/line';
import { LineChartDataPoint } from '.';
import ChartTooltip from '../ChartTooltip';
import { ChartDataPointTooltip, ChartNumbersType } from 'common/types/chart';
import Color from 'common/theme/color';
import TooltipRenderer from '../ChartTooltip/TooltipRenderer';
import { chartValueInMsOrKs } from 'utils/helpers';

interface Props {
  point: Point;
  maxWidth: number;
  moeEnabled: boolean;
  chartNumbersType: ChartNumbersType;
}

const LineChartTooltip = ({ point, maxWidth, moeEnabled, chartNumbersType }: Props) => {
  const { t } = useTranslation();

  point.x = Math.max(point.x, 50);
  point.x = Math.min(point.x, maxWidth - 200);
  const moeValue = (point.data as unknown as LineChartDataPoint).moe;
  const populationNumberRaw = (point.data as unknown as LineChartDataPoint).populationNumberRaw;
  const tooltip = {
    title: (point.data as unknown as LineChartDataPoint).groupLabel as string,
    subtitle: (point.data as unknown as LineChartDataPoint).label as string,
    color: point.color as unknown as Color,
    items: [
      ...(moeValue
        ? [
            {
              label: t('lineChart.upperEstimate'),
              value: (point.data.y as number) + moeValue,
              unavailable: false,
              numericValue: (point.data as unknown as LineChartDataPoint).numericValue,
              populationValue: chartValueInMsOrKs(populationNumberRaw * (1 + moeValue / 100), null),
            },
          ]
        : []),
      {
        label: t('lineChart.meanEstimate'),
        value: point.data.y as number,
        unavailable: false,
        numericValue: (point.data as unknown as LineChartDataPoint).numericValue,
        highlight: true,
        populationValue: chartValueInMsOrKs(populationNumberRaw, null),
      },
      ...(moeValue
        ? [
            {
              label: t('lineChart.lowerEstimate'),
              value: (point.data.y as number) - moeValue,
              unavailable: false,
              numericValue: (point.data as unknown as LineChartDataPoint).numericValue,
              populationValue: chartValueInMsOrKs(populationNumberRaw * (1 - moeValue / 100), null),
            },
          ]
        : []),
    ],
  } as ChartDataPointTooltip;

  return (
    <>
      <TooltipRenderer x={point.x + 100} y={Number(point.y)}>
        <ChartTooltip tooltip={tooltip} moeEnabled={moeEnabled} chartNumbersType={chartNumbersType} averagePopulationValue={null} />
      </TooltipRenderer>
    </>
  );
};

export default React.memo(LineChartTooltip);
