import { Theme } from '@emotion/react';
import Color from './color';
import { theme as themev2 } from 'theme/index';

enum TextVariants {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  Body1 = 'body1',
  Body2 = 'body2',
  Subheading1 = 'subheading1',
  Subheading2 = 'subheading2',
  P = 'p',
  Small = 'small',
  xSmall = 'xsmall',
  Span = 'span',
}

const primaryColors = {
  lightPurple: new Color(143, 80, 255),
  lightPurple1: new Color(143, 80, 255, 0.1),
  lightPurple2: new Color(143, 80, 255, 0.2),
  lightPurple3: new Color(71, 27, 141),
  darkPurple: new Color(52, 13, 112),
  darkPurple1: new Color(88, 56, 141, 1),
  turqoise: new Color(5, 193, 183),
  lightTurqoise: new Color(5, 193, 183, 0.1),
};

const secondaryColors = {
  wine: new Color(226, 21, 74),
  orange: new Color(255, 103, 57),
  lemon: new Color(255, 199, 100),
  atomicTangerine: new Color(255, 153, 102),
  grass: new Color(124, 224, 133),
  emerald: new Color(83, 219, 218),
  sky: new Color(22, 150, 240),
  ocean: new Color(57, 92, 255),
  peacock: new Color(78, 32, 208),
  plum: new Color(141, 7, 243),
  candy: new Color(204, 80, 182),
  turqoise: new Color(5, 193, 183),
  turqoise01: new Color(5, 193, 183, 0.1),
  lavender: new Color(230, 228, 255, 1),
  lavender75: new Color(230, 228, 255, 0.75),
  moreThanAWeek: new Color(140, 140, 140, 1),
  mercury: new Color(235, 235, 235, 1),
  portage: new Color(153, 102, 255),
  sooty: new Color(20, 20, 20),
  synthetic: new Color(203, 203, 203),
  foggy: new Color(207, 205, 230, 1),
  jupiter: new Color(226, 226, 226, 1),
  cascadingWhite: new Color(246, 246, 246),
  snobyShore: new Color(220, 120, 48),
  redOrange: new Color(255, 59, 48),
  brazilianite: new Color(52, 199, 89),
  furiousFuchsia: new Color(230, 242, 255, 1),
  blueSparkle: new Color(0, 122, 255, 1),
  purpleIllusionist: new Color(165, 115, 255, 1),
  cottonBall: new Color(243, 249, 255, 1),
  turquoisePanic: new Color(51, 210, 203, 1),
  persimmon: new Color('#F15700'),
  indigo50: new Color('#EEEEFB'),
};

const whiteShades = {
  white: new Color(255, 255, 255),
  white01: new Color(255, 255, 255, 0.1),
  white25: new Color(255, 255, 255, 0.25),
  white50: new Color(255, 255, 255, 0.5),
};

const blackShades = {
  black: new Color(0, 0, 0),
  black05: new Color(0, 0, 0, 0.05),
  black10: new Color(0, 0, 0, 0.1),
  black25: new Color(0, 0, 0, 0.25),
  black50: new Color(0, 0, 0, 0.5),
  black75: new Color(0, 0, 0, 0.75),
};

// IMP!! Please do not add any Grey shades. They are reserved for NO_BRAND. [CX-663]
const greyShades = {
  lightGrey: new Color('#EBEBEB'),
  borderGrey: new Color('#E9E8E8'),
  chartGrey: new Color('#BBBBBB'),
  regularGrey: new Color('#8C8C8C'),
  liquoriceGrey: new Color('#545454'),
  mortarGrey: new Color(158, 158, 158, 1),
  sonicSilver: new Color(117, 117, 117, 1),
  onyx: new Color(55, 55, 55, 1),
  gray100: new Color(238, 238, 238, 1),
  gray400: new Color(175, 175, 175, 1),
};

const eventColors = {
  turquoise: new Color('#23ABAA'),
  green: new Color('#42BB4D'),
  brown: new Color('#C38618'),
  red: new Color('#D63C0D'),
  wine: new Color('#A0042C'),
};

//Replace this when new theme is implemented
const chartColors = {
  blue500: new Color(0, 122, 255, 1),
  orange400: new Color(255, 170, 51, 1),
  mint400: new Color(51, 210, 203, 1),
  indigo500: new Color(88, 86, 214, 1),
  pink400: new Color(255, 87, 119, 1),
  purple500: new Color(175, 82, 222, 1),
  green400: new Color(93, 210, 122, 1),
  yellow500: new Color(255, 204, 0, 1),
  gold500: new Color(162, 132, 94, 1),
  purple700: new Color(122, 57, 155, 1),
  cyan500: new Color(50, 173, 230, 1),
  mint700: new Color(0, 139, 133, 1),
  pink700: new Color(179, 31, 59, 1),
  red400: new Color(255, 98, 89, 1),
  pink900: new Color(76, 13, 25, 1),
  purple300: new Color(207, 151, 235, 1),
  indigo700: new Color(62, 60, 150, 1),
  green700: new Color(36, 139, 62, 1),
  orange600: new Color(241, 120, 0, 1),
  purple800: new Color(88, 41, 111, 1),
};

const SpaceUnit = 8;

const spacingBase = (num: number) => `${SpaceUnit * num}px`;
const space = (...args: number[]) => args.map(spacingBase).join(' ');

const shadowBorderStyles = (color: Color, size: number, index: number): string => {
  switch (index) {
    // top
    case 0:
      return `inset 0 -${size}px 0 0 ${color.toString()}`;
    // right
    case 1:
      return `inset -${size}px 0 0 0 ${color.toString()}`;
    // bottom
    case 2:
      return `inset 0 ${size}px 0 0 ${color.toString()}`;
    // left
    case 3:
      return `inset ${size}px 0 0 0 ${color.toString()}`;
    default: {
      return '';
    }
  }
};

const shadowBorder = (color: Color, borders: number[]) => {
  return borders.map((size, index) => shadowBorderStyles(color, size, index)).join(', ');
};

export const theme: Theme = {
  colors: {
    ...primaryColors,
    ...secondaryColors,
    ...whiteShades,
    ...blackShades,
    ...greyShades,
  },
  eventColors,
  spacing: {
    padding: 8,
    unit: SpaceUnit,
  },
  shadowBorder,
  space,
  text: {
    fontFamily: 'Mulish',
    lineHeight: 1.333,
    lineHeight2: '1rem',
    lineHeight3: '2rem',
    fontWeight: 400,
    variants: {
      [TextVariants.H1]: {
        fontSize: '5.892rem',
        fontWeight: 400,
        margin: '4.8rem 0 2.2rem',
      },
      [TextVariants.H2]: {
        fontSize: '4.420rem',
        fontWeight: 400,
        margin: '4.8rem 0 2.2rem',
      },
      [TextVariants.H3]: {
        fontSize: '3.316rem',
        fontWeight: 600,
        margin: '4.8rem 0 2.2rem',
      },
      [TextVariants.H4]: {
        fontSize: '2.488rem',
        fontWeight: 700,
        margin: '4.8rem 0 2.2rem',
      },
      [TextVariants.H5]: {
        fontSize: '1.866rem',
        fontWeight: 400,
        margin: '4.8rem 0 2.2rem',
      },
      [TextVariants.H6]: {
        fontSize: '1.4rem',
        fontWeight: 400,
        margin: '0 0 1.6rem',
      },
      [TextVariants.Body1]: {
        fontSize: '1.4rem',
        fontWeight: 400,
        margin: '0 0 1.6rem',
      },
      [TextVariants.Body2]: {
        fontSize: '1.4rem',
        fontWeight: 600,
        margin: '0 0 1.6rem',
      },
      [TextVariants.Subheading1]: {
        fontSize: '1.4rem',
        fontWeight: 600,
        margin: '0 0 1.6rem',
      },
      [TextVariants.Subheading2]: {
        fontSize: '1.4rem',
        fontWeight: 400,
        margin: '0 0 1.6rem',
      },
      [TextVariants.P]: {
        fontSize: '1.2rem',
        fontWeight: 600,
        margin: 'inherit',
      },
      [TextVariants.Small]: {
        fontSize: '1.050rem',
        fontWeight: 400,
        margin: '0 0 1.6rem',
      },
      [TextVariants.xSmall]: {
        fontSize: '0.75rem',
        fontWeight: 600,
        margin: 'inherit',
      },
      [TextVariants.Span]: {
        fontSize: 'inherit',
        margin: 'inherit',
      },
    },
  },
  chart: {
    colorPalette: Object.values(chartColors),
    average: new Color('#F15700'),
  },
  sidebar: {
    width: 248,
    stickyTop: 57,
  },
  v2: themev2, // Since we can have single theme, add it here, but remove it later once everything is done
};
