import { atom, selector, noWait } from 'recoil';
import { AccountDetails, FeatureGates, UIPreferences, UserDetailsResponse, UserPermissions } from 'common/types/account';
import { API_URLS } from 'settings/api';
import { fetchSelector } from 'common/atoms/common';
import { prefillUIPreferences } from 'utils/account';
import getRollbarConfig from 'rollbarConfig';
import { LogArgument } from 'rollbar';
import { Role } from 'common/types/account';
import { GUEST } from 'settings/constants';

const defaultAccountDetails = {
  functionalities: [],
  user: {
    name: '',
    uuid: '',
    email: '',
    permissions: {
      internal: false,
      chart_edit: false,
      chart_folders_edit: false,
      segment_edit: false,
      user_show: false,
      user_edit: false,
      view_unpublished_waves: false,
    },
    role: {
      id: '',
      name: '',
    },
    brand_funnel_edit: false,
    notify_about_data_emails: false,
  },
  active_study_uuid: '',
  preferences: {
    new_design: false,
    confidence_levels: null,
    kpis: null,
    ui_preferences: {
      decimalsEnabled: false,
      calculateDeltas: '' as 'last-two',
      showBarValue: false,
    },
  },
  features: {
    customer: [],
    studies: {},
  },
  infos: [],
  customer: {
    name: '',
    tier_name: '',
    id: 0,
  },
};

export const accountDetailsAtom = atom<AccountDetails | null>({
  key: 'accountDetails',
  default: null,
});

// Note: This selector will not return a promise in case
// the account details are present in accountDetailsAtom,
// this is used mainly to prevent React.Suspense on updating user details
// or preferences
export const accountDetailsSelector = selector<AccountDetails>({
  key: 'AccountDetailsSelector',
  get: async ({ get }) => {
    try {
      const { fetchGet } = get(fetchSelector);
      const accountDetails = get(accountDetailsAtom);

      if (accountDetails) {
        return accountDetails;
      }

      const userDetails = await fetchGet<UserDetailsResponse>(API_URLS.PROFILE);
      const features = await fetchGet<FeatureGates>(API_URLS.FEATURES);

      return {
        ...userDetails,
        preferences: {
          ...userDetails.preferences,
          ui_preferences: prefillUIPreferences({
            ...userDetails.preferences.ui_preferences,
          }),
        },
        features,
      };
    } catch (e: unknown) {
      const rollbar = getRollbarConfig();
      rollbar.error('AccountDetailsSelector error', e as LogArgument);
      return defaultAccountDetails;
    }
  },
  set: ({ set }, accountDetails) => {
    set(accountDetailsAtom, accountDetails);
  },
});

export const accountFunctionalitiesSelector = selector({
  key: 'AccountFunctionalities',
  get: ({ get }) => {
    const { state: accountDetailsState, contents: accountDetails } = get(noWait(accountDetailsSelector));

    if (accountDetailsState !== 'hasValue') {
      return {
        advancedCharacteristics: false,
        internal: false,
        funnelKpis: false,
      };
    }
    const advancedCharacteristics = (accountDetails as AccountDetails)?.functionalities.find(
      ({ type, enabled }) => type === 'advanced_characteristics' && enabled,
    );
    const funnelKpis = (accountDetails as AccountDetails)?.functionalities.find(({ type, enabled }) => type === 'funnel_kpis' && enabled);
    return {
      advancedCharacteristics: Boolean(advancedCharacteristics),
      internal: Boolean((accountDetails as AccountDetails).user.permissions.internal),
      funnelKpis: Boolean(funnelKpis),
    };
  },
});

// Note: used as object instead of string, to invalidate the recoil store
// by passing a new object reference
export const currentStudyAtom = atom<{ studyId: string | undefined }>({
  key: 'CurrentStudy',
  default: {
    studyId: undefined,
  },
});

export const sessionKeyAtom = atom<string | undefined>({
  key: 'SessionKey',
  default: undefined,
});

export const uiPreferencesSelector = selector<UIPreferences>({
  key: 'UIPreferences',
  get: ({ get }) => {
    const {
      preferences: { ui_preferences },
    } = get(accountDetailsSelector);

    return ui_preferences;
  },
});

export const permissionsSelector = selector<UserPermissions>({
  key: 'Permissions',
  get: ({ get }) => {
    const {
      user: { permissions },
    } = get(accountDetailsSelector);
    return permissions;
  },
});

export const roleSelector = selector<Role>({
  key: 'roleSelector',
  get: ({ get }) => {
    const {
      user: { role },
    } = get(accountDetailsSelector);
    return role;
  },
});

export const isGuestSelector = selector<boolean>({
  key: 'isGuestSelector',
  get: ({ get }) => {
    const {
      user: { role },
    } = get(accountDetailsSelector);
    return role.name.toLowerCase() === GUEST;
  },
});
