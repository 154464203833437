import { WaveReport } from 'common/types/common';
import Modal from 'common/components/Modal';
import { Text } from 'common/styledComponents/typography';
import * as React from 'react';
import ReportsLogo from 'icons/reports.svg';
import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { Button } from 'common/components/Form/Button';
import { displayDate } from 'utils/dates';
import { useViewReports } from 'modules/settings/hooks/reports';
import { useTranslation } from 'react-i18next';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { Link } from 'react-router-dom';

const ReportsArt = styled.img`
  display: block;
  margin: 0 auto ${theme.space(6)};
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.space(4)};
`;

const Title = styled(Text)`
  margin: ${theme.space(0, 0, 2.75)};
`;

const Description = styled(Text)`
  width: 420px;
  max-width: 100%;
  opacity: 0.75;
  line-height: 1.8rem;
`;

const LinkWrapper = styled.span`
  color: ${theme.colors.turqoise.toString()};
`;

interface Props {
  report: WaveReport;
}

const ReportModal: React.FC<Props> = ({ report: { link_to_pdf, period } }) => {
  const [open, setOpen] = React.useState(true);
  const { viewReports } = useViewReports();
  const { t } = useTranslation();
  const monthText = displayDate(period, 'MMMM');
  const { trackEvent } = useTracking();

  const onDownload = () => {
    trackEvent(TRACKING_EVENTS.DOWNLOAD_WAVE_SUMMARY_MODAL);
  };

  const handleClose = React.useCallback(() => {
    setOpen(false);
    viewReports();
  }, [viewReports]);

  return (
    <Modal customWidth={640} clickOutsideClose={false} handleClose={handleClose} isOpen={open} showCloseButton={false}>
      <ReportsArt alt='Reports' src={ReportsLogo} />
      <Title variant='h4'>
        {t('reports.modal.title', {
          month: monthText,
        })}
      </Title>
      <Description variant='body2' color='black75'>
        {t(`reports.modal.p1`, {
          month: monthText,
        })}
        <br />
        {t(`reports.modal.p2`)}
        <LinkWrapper>
          {' '}
          <Link onClick={handleClose} to={`/settings/wave-reports`}>
            {t(`reports.modal.p3`)}
          </Link>
        </LinkWrapper>
        {t(`reports.modal.p4`)}
        <br />
        {t(`reports.modal.p5`)}
      </Description>
      <Bottom>
        <a href={link_to_pdf} target='_blank' rel='noopener noreferrer' onClick={onDownload}>
          <Button onClick={handleClose} color='turqoise'>
            {t(`reports.modal.download`)}
          </Button>
        </a>
        <Button onClick={handleClose} color='black05' pseudo>
          {t(`reports.modal.cancel`)}
        </Button>
      </Bottom>
    </Modal>
  );
};

export default React.memo(ReportModal);
