import React from 'react';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';

const TooltipContainer = styled.article`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.black.toString()};
  color: ${({ theme }) => theme.colors.white.toString()};
  padding: ${({ theme }) => theme.space(2)};
  border-radius: ${({ theme }) => theme.space(1)};
  min-width: 380px;
  max-width: 390px;
  max-height: 100%;
  overflow: hidden;
`;

const TooltipRow = styled.section<{ hasBg?: boolean }>`
  font-weight: ${({ theme }) => theme.text.variants.body2.fontWeight};
  font-size: 1.4rem;
  line-height: 1.8rem;
  display: flex;
  justify-content: space-between;
  background: ${({ hasBg, theme }) => (hasBg ? theme.colors.onyx.toString() : 'none')};

  > p {
    font-size: ${({ theme }) => theme.text.variants.p.fontSize};
  }
`;

const Title = styled(Text)`
  line-height: 20px;
`;

interface Props {
  value: number | string | null;
  xAxis: { key: string; value: string };
  yAxis: { key: string; value: string };
}

const Tooltip = ({ value, xAxis, yAxis }: Props): JSX.Element => {
  return (
    <TooltipContainer data-testid={`heatmapTooltip-${xAxis.key} - ${yAxis.key}`}>
      <Title color='white' variant='body2' marginBottom>
        {yAxis.value}
      </Title>
      <TooltipRow>
        <Text color='white' variant='body2'>
          {xAxis.value}
        </Text>
        <Text color='white' variant='body2'>
          {value}
        </Text>
      </TooltipRow>
    </TooltipContainer>
  );
};

export default Tooltip;
