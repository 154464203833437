export default class Color {
  r: number;
  g: number;
  b: number;
  a: number;
  constructor(colorR: string | number, colorG?: number, colorB?: number, alpha?: number) {
    // hex
    if (colorR.toString().startsWith('#')) {
      const hex = colorR.toString();
      const red = hex.slice(1, 3);
      const green = hex.slice(3, 5);
      const blue = hex.slice(5, 7);
      this.r = parseInt(`0x${red}`);
      this.g = parseInt(`0x${green}`);
      this.b = parseInt(`0x${blue}`);
      this.a = alpha || 1;
      // rgb
    } else if (colorR.toString().startsWith('rgb')) {
      const [r, g, b, a = 1] = colorR
        .toString()
        .split('(')[1]
        .split(')')[0]
        .split(',')
        .slice(0, 4)
        .map(num => num.trim());

      this.r = parseInt(r);
      this.g = parseInt(g);
      this.b = parseInt(b);
      this.a = typeof a === 'string' ? parseFloat(a) : 1;
    } else {
      this.r = colorR as number;
      this.g = colorG as number;
      this.b = colorB as number;
      this.a = alpha || 1;
    }
  }

  alpha(a: number): Color {
    return new Color(this.r, this.g, this.b, a);
  }

  toString(): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }
}
