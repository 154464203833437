import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { ChartFilterId, ChartType, RawChartSchema } from 'common/types/chart';
import { Brand, Country, KPI, Wave, WavePayload, KPIGroups } from 'common/types/common';
import {
  AllFiltersData,
  FilterNames,
  FiltersOptions,
  FiltersPayload,
  IFilter,
  IFilterOption,
  IFilterOptionGroup,
  Options,
  OptionsValues,
  RestrictionConfig,
  Restrictions,
} from 'common/types/filters';
import { getColorForData } from 'modules/charts/utils/colors';
import { mapWave } from 'utils/wave';
import { arrayEquals, deepValue, uniqueListItemsByIdentifier } from 'utils/helpers';
import { ChartFormData } from 'common/types/chart';
import { FiltersState } from 'common/types/filters';
import {
  AIDED_BRAND_AWARENESS,
  BRAND_PERCEPTION_KPI,
  FilterLabels,
  Identifier,
  NO_BRAND,
  UNAIDED_BRAND_AWARENESS,
} from 'settings/constants';
import { sortBrandsListByPrimaryBrands } from 'utils/brands';
import { SegmentGroups } from 'common/types/segments';
import { displayDate } from './dates';

const mapCountryOptions = ({ id: value, name: label }: Country): IFilterOption => {
  return {
    value,
    label,
    type: 'option',
  };
};

const mapBrandOption = ({ id: value, name: label }: Brand): IFilterOption => {
  return {
    value,
    label,
    type: 'option',
  };
};

const mapBrand = (brand: Brand): Brand => ({
  ...brand,
  name: !brand.global ? brand.name : NO_BRAND,
});

export const mapBrandByGlobalization = (data: FiltersPayload): FiltersState => {
  const globalBrands = data?.brands?.filter(({ global }) => global);
  const nonGlobalBrands = data?.brands?.filter(({ global }) => !global);

  return {
    ...data,
    brands: nonGlobalBrands?.concat?.(globalBrands)?.map(mapBrand),
    waves: data.waves?.map(mapWave),
    countries: data.countries?.map((country, i) => ({ ...country, color: getColorForData(i) })),
  };
};

const READONLY_FUNNEL_KPIS = [AIDED_BRAND_AWARENESS, UNAIDED_BRAND_AWARENESS];

const mapKpiOption =
  (chartType: ChartType, selectedKPIFormulas: Record<string, string> | null) =>
  (
    acc: (IFilterOption | IFilterOptionGroup)[],
    { kpi_identifier: value, kpi_label: label, question_options, kpi_formulas }: KPI,
  ): (IFilterOption | IFilterOptionGroup)[] => {
    const isFunnel = chartType === ChartType.FUNNEL;
    const isMatrix = chartType === ChartType.MATRIX;

    // This is used for Matrix chart, because for Matrix chart we want to show Brand Perception as single option, and not positive, neutral and negative
    const skipQuestionOptions = isMatrix && value === BRAND_PERCEPTION_KPI;

    if (question_options && !skipQuestionOptions) {
      const options = question_options.map(({ option_identifier: value, option_label: label }) => ({
        value,
        label,
        type: 'option' as const,
      }));

      if (kpi_formulas) {
        const findFormula = kpi_formulas.find(x => x.kpi_identifier === selectedKPIFormulas?.[value]) || kpi_formulas[0];

        options.push({
          value: findFormula.kpi_identifier,
          label: findFormula.kpi_label,
          type: 'option' as const,
        });
      }

      return acc.concat({
        label,
        options: options,
        type: 'group' as const,
      });
    }

    acc.push({
      value,
      label,
      type: 'option',
      readOnly: isFunnel && READONLY_FUNNEL_KPIS.includes(value),
    });
    return acc;
  };

const groupWavesToOptions = (waves: Wave[] | undefined): (IFilterOption | IFilterOptionGroup)[] => {
  waves = waves?.sort((waveA, waveB) => (new Date(waveA.date) > new Date(waveB.date) ? -1 : 1));
  let order = 0;
  const years = new Set(waves?.map(({ date }) => new Date(date).getFullYear().toString()));
  const yearsMap = Array.from(years).reduce((acc, year) => {
    acc.set(year, {
      label: year,
      options: [],
      type: 'group',
    });

    return acc;
  }, new Map<string, IFilterOptionGroup>());

  waves?.forEach(({ date, id: value }) => {
    const year = date.getFullYear().toString();
    const month = date.toLocaleDateString('en-US', {
      month: 'long',
    });
    yearsMap.get(year)?.options.push({
      label: month,
      filterLabel: `${month} ${year}`,
      value,
      type: 'option',
      optionOrder: order++,
    });
  });

  return Array.from(yearsMap.values());
};

const groupBrandsToAvailableAndPartiallyAvailable = (brands: Brand[] | undefined): (IFilterOption | IFilterOptionGroup)[] => {
  if (!brands || brands.length === 0) {
    // Return a single group if brands are undefined or empty
    return [
      {
        label: 'No Brands Available',
        options: [],
        type: 'group',
      },
    ];
  }

  // Separate brands into two groups based on available_for_all_waves
  const availableBrands = brands.filter(brand => brand.wave_availability?.available_for_all_waves);
  const partiallyAvailableBrands = brands.filter(brand => !brand.wave_availability?.available_for_all_waves);

  // Create groups based on availability
  const groups: IFilterOptionGroup[] = [];

  if (availableBrands.length > 0) {
    groups.push({
      label: 'Available brands',
      options: availableBrands.map(mapBrandOption),
      type: 'group',
    });
  }

  if (partiallyAvailableBrands.length > 0) {
    groups.push({
      label: 'Partially available',
      options: partiallyAvailableBrands.map(mapBrandOption),
      type: 'group',
      infoMessage: 'The below brands have data available for only a portion of the selected filter configuration',
    });
  }

  // If both groups are empty, return a single group indicating no brands are available
  if (groups.length === 0) {
    return [
      {
        label: 'No Brands Available',
        options: [],
        type: 'group',
      },
    ];
  }

  return groups;
};

const generateWavesOrder = (waves: Wave[] | undefined) => {
  return waves?.sort((waveA, waveB) => (new Date(waveA.date) > new Date(waveB.date) ? -1 : 1)).map(({ id }) => id);
};

const mapSegmentGroupOptions = ({ name: label, segments }: SegmentGroups): IFilterOptionGroup => ({
  label,
  type: 'group',
  options: segments.map(({ id: value, name: label }) => ({
    label,
    value,
    type: 'option',
  })),
});

export const generateFiltersData = (options: FiltersOptions): IFilter[] => {
  const { availableFilters, brands, segmentGroups, kpis, chartType, selectedKPIFormulas } = options;
  const waves = availableFilters?.waves.map(mapWave);
  const includeGlobal = kpis.some(kpi => kpi.global === true);
  const brandsList = !includeGlobal ? brands.filter(brand => brand.global !== true) : brands;

  const filters = [
    {
      label: FilterLabels.Geography,
      id: FilterNames.COUNTRY,
      options: [
        {
          label: 'Countries',
          options: availableFilters?.countries.map(mapCountryOptions),
          type: 'group',
        },
      ] as IFilterOptionGroup[],
    },
    {
      label: FilterLabels.TimePeriod,
      id: FilterNames.WAVE,
      optionsOrder: generateWavesOrder(waves),
      options: groupWavesToOptions(waves),
    },
    {
      label: FilterLabels.KPI,
      id: FilterNames.KPI,
      options: kpis.reduce(mapKpiOption(chartType, selectedKPIFormulas), []),
    },
    {
      label: FilterLabels.Brand,
      id: FilterNames.BRAND,
      options: groupBrandsToAvailableAndPartiallyAvailable(brandsList),
    },
    {
      label: FilterLabels.Segment,
      id: FilterNames.SEGMENT,
      options: segmentGroups.map(mapSegmentGroupOptions),
    },
  ];

  return filters;
};

const getKPIids = (kpis: KPI[], selectedKPIFormulas: Record<string, string> | null): (string | number)[] => {
  return (kpis || []).reduce((acc, { question_options, kpi_formulas, kpi_identifier }) => {
    if (question_options) {
      question_options.forEach(({ option_identifier }) => {
        acc = [...acc, option_identifier];
      });

      if (kpi_formulas) {
        const kpiFormula = selectedKPIFormulas?.[kpi_identifier] || kpi_formulas[0].kpi_identifier;
        acc = [...acc, kpiFormula];
      }
    } else {
      acc = [...acc, kpi_identifier];
    }

    return acc;
  }, [] as (string | number)[]);
};

export const getOptionsIds = ({ countries, brands, waves, segments, kpis, selectedKPIFormulas }: Options): OptionsValues => {
  const country = (countries || []).map(({ id }) => id);
  const brand = (brands || []).map(({ id }) => id);
  const wave = (waves || []).map(({ id }) => id);
  const segment = (segments || []).map(({ id }) => id);
  const kpi = getKPIids(kpis, selectedKPIFormulas);
  return { country, wave, kpi, segment, brand };
};

export const getValidationSchema = (chartType: ChartType): yup.ObjectSchema<ObjectShape> => {
  const shapeObject = {
    name: yup.string(),
    country: yup.array().min(1).required(),
    wave: yup.array().min(1).required(),
    kpi: yup.array().min(1).required(),
    brand: yup.array().min(1).required(),
    segment: yup.array().min(1).required(),
  };

  if (chartType === ChartType.RADAR) {
    shapeObject['kpi'] = yup.array().min(3).required();
  }

  if (chartType === ChartType.GAUGE) {
    shapeObject['country'] = yup.array().max(1).required();
    shapeObject['wave'] = yup.array().max(1).required();
    shapeObject['brand'] = yup.array().max(1).required();
    shapeObject['kpi'] = yup.array().max(1).required();
  }

  if (chartType === ChartType.HEATMAP) {
    shapeObject['wave'] = yup.array().max(1).required();
  }

  if (chartType === ChartType.RANKING) {
    shapeObject['wave'] = yup.array().max(1).required();
    shapeObject['kpi'] = yup.array().max(1).required();
  }

  if (chartType === ChartType.MATRIX) {
    shapeObject['kpi'] = yup.array().length(2).required();
  }

  if (chartType === ChartType.MARKET_SIZE) {
    shapeObject['country'] = yup.array().min(1).required();
    shapeObject['wave'] = yup.array().min(1).required();
  }

  if (chartType === ChartType.MARKET_FUNNEL) {
    shapeObject['country'] = yup.array().min(1).max(6).required();
    shapeObject['brand'] = yup.array().min(1).max(6).required();
    shapeObject['segment'] = yup.array().min(1).max(6).required();
  }

  return yup.object().shape(shapeObject);
};

const filterChartFilters = (
  key: string,
  chartFilter: (string | number)[] | undefined,
  options: OptionsValues,
): Array<string | number> | undefined => {
  return (
    chartFilter && chartFilter.filter((filterName: string | number) => options[key as keyof OptionsValues].includes(filterName as number))
  );
};

export const filterChartFormData = (
  data: ChartFormData,
  posibleOptions: OptionsValues,
): Map<string, Array<string | number> | undefined> => {
  const filterData = new Map();
  const { country, wave, segment, kpi, brand } = data;
  const chartFilters = new Map(Object.entries({ country, wave, segment, kpi, brand }));
  const filtersNames = Object.keys({ country, wave, segment, kpi, brand });

  for (const key of filtersNames) {
    filterData.set(key, filterChartFilters(key, chartFilters.get(key), posibleOptions));
  }

  return filterData;
};

export const getIntermediateChangedMap = (
  intermediateChangedMap: Record<string, boolean>,
  filterId: string,
  values: unknown,
  intermediateValues: ChartFormData,
): Record<string, boolean> => {
  const prevValues = deepValue(intermediateValues, filterId);
  const prevDiffs = intermediateChangedMap[filterId];
  const differs = Array.isArray(values) ? !arrayEquals(prevValues, values) : prevValues !== values;
  return prevDiffs !== differs ? { ...intermediateChangedMap, [filterId]: differs } : intermediateChangedMap;
};

export const getFilteredWaves = (
  chartDataWave: (string | number)[],
  filtersOptionsWave: (string | number)[] | undefined,
): Array<string | number> => {
  return chartDataWave.filter(value => filtersOptionsWave?.includes(value));
};

export const composeRestrictions = (previousValue: FiltersPayload, currentValue: FiltersPayload): FiltersPayload => {
  const filters = {} as Record<string, (Brand | WavePayload | Country)[]>;
  Object.keys(currentValue).forEach(key => {
    filters[key] = uniqueListItemsByIdentifier(
      [...previousValue[key as keyof FiltersPayload], ...currentValue[key as keyof FiltersPayload]],
      Identifier.ID,
    );
  });
  return filters as unknown as FiltersPayload;
};

export const isAllSelected = (allOptions: (string | number)[] | undefined, selectedOptions: (string | number)[] | undefined): boolean => {
  if (!allOptions?.length || !selectedOptions?.length) return false;
  return allOptions?.every((option: string | number) => selectedOptions?.indexOf(option) !== -1);
};

export const getFiltersConfig = (payload: Record<string, number[]>): Array<RestrictionConfig> => {
  const nonEmptyFilters = Object.entries(payload).filter(([, values]) => values && values.length);
  return nonEmptyFilters.reduce((acc: Array<RestrictionConfig>, curr: [string, number[]]) => {
    const [name, values] = curr;
    acc = [...acc, { name, values }];
    return acc;
  }, []);
};

export const getSortedValues = (optionsOrder: (string | number)[] | undefined, values: (string | number)[]): Array<string | number> => {
  if (!optionsOrder) return values;
  return values.sort((valA, valB) => {
    const indexA = optionsOrder.indexOf(valA);
    const indexB = optionsOrder.indexOf(valB);
    return indexB - indexA;
  });
};

const filterSelectedKPI = (selectedOptions: Array<ChartFilterId>, value: ChartFilterId, kpiGroups: KPIGroups) => {
  let kpisToDeselect: Array<ChartFilterId> = [];
  kpiGroups.absolute.includes(value as string) ? (kpisToDeselect = kpiGroups.percentage) : (kpisToDeselect = kpiGroups.absolute);
  return selectedOptions.filter((item: ChartFilterId) => !kpisToDeselect.includes(item));
};

export const handleOptionSelection = (
  chartValues: Array<ChartFilterId>,
  newValue: ChartFilterId,
  isKPIFilter: boolean,
  kpiTypesGroups: KPIGroups,
): Array<ChartFilterId> => {
  let selectedOptions: Array<ChartFilterId> = [];
  const selected = chartValues.includes(newValue);

  selectedOptions = selected ? chartValues.filter((value: ChartFilterId) => newValue !== value) : [...chartValues, newValue];

  if (isKPIFilter && !selected) {
    selectedOptions = filterSelectedKPI(selectedOptions, newValue, kpiTypesGroups);
  }

  return selectedOptions;
};

export const sortRestrictionsBrandsByPrimaryBrands = (restrictions: Restrictions[], primaryBrands: string[]): Restrictions[] => {
  return restrictions.map(filter => {
    return {
      ...filter,
      brands: sortBrandsListByPrimaryBrands(filter.brands, primaryBrands),
    };
  });
};

export const getChartBrandAndSegmentNames = (
  chartFormData: ChartFormData,
  filtersOptions: FiltersOptions,
): { brandNames: string[]; segmentNames: string[] } => {
  const brandNames: string[] = [];
  const segmentNames: string[] = [];

  // Get brand names
  chartFormData.brand.forEach(brandId => {
    const brand = filtersOptions.brands.find(b => b.id === brandId);
    if (brand) {
      brandNames.push(brand.name);
    }
  });

  // Get segment names
  chartFormData.segment.forEach(segmentId => {
    for (const segmentGroup of filtersOptions.segmentGroups) {
      const segment = segmentGroup.segments.find(s => s.id === segmentId);
      if (segment) {
        segmentNames.push(segment.name);
        break; // Found the segment, no need to search further
      }
    }
  });

  return { brandNames, segmentNames };
};

export const getNamesForAllFilterIds = (
  chartSchema: RawChartSchema,
  chartFilters: AllFiltersData,
): {
  countries: string[];
  waves: string[];
  kpis: string[];
  brands: string[];
  segments: string[];
} => {
  const { country_ids, wave_ids, kpi_option_identifiers, brand_ids, segment_ids } = chartSchema;

  const findName = (ids: number[], items: { id: number; name: string }[] | undefined) =>
    ids.map(id => items?.find(item => item.id === id)?.name || '');

  const findDate = (ids: number[], waves: Wave[] | undefined) => ids.map(id => waves?.find(wave => wave.id === id)?.date || '');

  return {
    countries: findName(country_ids, chartFilters.countries),
    waves: findDate(wave_ids, chartFilters.waves).map(date => displayDate(date, 'MMMM YYYY')),
    kpis: kpi_option_identifiers.map(id => chartFilters.kpis[id] || ''),
    brands: findName(brand_ids, chartFilters.brands),
    segments: findName(segment_ids, chartFilters.segments),
  };
};
