import { useMemo } from 'react';

const MIN_WIDTH = 375;
const MAX_BAR_COUNT = 70;
const MIN_BAR_COUNT = 28;

const useFullBar = (width: number | undefined, barCount: number, document: Document): boolean => {
  return useMemo(() => {
    return (
      (width &&
        Math.ceil(document.documentElement.clientWidth / barCount) >= MAX_BAR_COUNT &&
        (document.documentElement.clientWidth - MIN_WIDTH) / barCount >= MIN_BAR_COUNT) ||
      (document.documentElement.clientWidth >= barCount * MIN_BAR_COUNT &&
        (document.documentElement.clientWidth - MIN_WIDTH) / barCount >= MIN_BAR_COUNT)
    );
  }, [barCount, width, document]);
};

export default useFullBar;
