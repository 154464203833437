import React, { FC, useEffect, useState } from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import { Theme } from '@nivo/core';
import { RadarChartData, Params } from 'common/types/chart';
import { RadarGridLabel } from './RadarGridLabel';
import { roundValue } from 'utils/chart';
import { useVisibleRadarData } from 'common/hooks/charts';
import { uiPreferencesSelector } from 'common/atoms/account';
import { useRecoilValue } from 'recoil';

export interface RadarChartProps {
  data: RadarChartData[];
  colors: Array<string | undefined>;
  theme: Theme;
  maxValue: number;
  params: Params;
  inPage: boolean;
  containerRef?: React.MutableRefObject<HTMLDivElement | undefined>;
}

const MIN_WIDTH_FOR_LEGEND = 500;

const RadarChart: FC<RadarChartProps> = ({ data, colors, theme, maxValue, params, inPage = false, containerRef }) => {
  const visibleData = useVisibleRadarData(data);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { kpi, color, disabled, ...keys } = data[0] || {};
  const radarChartKeys = Object.keys(keys);

  const [showLegend, setShowLegend] = useState(false);
  const { decimalsEnabled } = useRecoilValue(uiPreferencesSelector);

  // Show legend in page only when it has enough width
  useEffect(() => {
    if (inPage) {
      const ro = new ResizeObserver(entries => {
        const { width } = entries[0].contentRect;
        setShowLegend(width > MIN_WIDTH_FOR_LEGEND);
      });
      ro.observe(containerRef?.current as Element);
      return () => ro.disconnect();
    }
  }, [containerRef, inPage]);

  return (
    <ResponsiveRadar
      fillOpacity={params.fillOpacity}
      maxValue={maxValue}
      gridShape={params.gridShape}
      data={visibleData}
      keys={radarChartKeys}
      indexBy={params.indexBy}
      valueFormat={value => `${roundValue(value, decimalsEnabled)} %`}
      margin={{ ...params.margin, left: !showLegend ? params.margin.left - 40 : params.margin.left }}
      borderColor={{ from: params.borderColor }}
      borderWidth={params.borderWidth}
      dotSize={params.dotSize}
      dotColor={{ from: params.dotSize as unknown as string }}
      gridLabel={props => <RadarGridLabel {...{ ...props, maxValue }} />}
      gridLabelOffset={params.labelOffset}
      dotBorderWidth={params.dotBorderWidth}
      colors={colors as Array<string>}
      blendMode={params.blendMode}
      motionConfig={params.motionConfig}
      theme={theme}
      legends={!inPage || showLegend ? params.legends : undefined}
    />
  );
};

export default RadarChart;
