import React from 'react';
import { SelectedDashboards } from 'common/contexts/ChartsContext';
import { UseChartInDashboard } from 'common/types/dashboard';
import { getDashboardCards } from 'utils/dashboards';

export const useChartInDashboard = (): UseChartInDashboard => {
  return React.useCallback((modalState: SelectedDashboards[], dashboardId: string, chartId: string): boolean => {
    const currentDashboardCards = getDashboardCards(modalState, dashboardId);
    const charts = currentDashboardCards?.flatMap(({ components }) => components).flatMap(({ body }) => body.map(({ uuid }) => uuid));
    return !!charts?.includes(chartId);
  }, []);
};
