import * as React from 'react';
import { Text } from 'common/styledComponents/typography';
import { MenuItem } from 'common/styledComponents/list';
import { SettingsItem } from 'common/types/settings';

interface Props {
  item: SettingsItem;
  onClick: (itemId: string) => void;
  active: boolean;
}

const SidebarItem: React.FC<Props> = ({ item, onClick, active }) => {
  const handleClick = React.useCallback(() => {
    onClick(item.id);
  }, [item, onClick]);

  return (
    <MenuItem data-selected={active} onClick={handleClick}>
      <Text variant='subheading1' color='black'>
        {item.name}
      </Text>
    </MenuItem>
  );
};

export default React.memo(SidebarItem);
