import React from 'react';
import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { useTranslation } from 'react-i18next';
import SettingsHeader from 'modules/settings/components/SettingsHeader';
import { Text } from 'common/styledComponents/typography';
import Radio from 'common/components/Form/Radio';
import { useForm } from 'react-hook-form';
import Toggle from 'common/components/Form/Toggle';
import { useRecoilValue } from 'recoil';
import { uiPreferencesSelector } from 'common/atoms/account';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { useTracking } from 'common/hooks/tracking';
import { usePreferences } from 'modules/settings/hooks/appearance';

const AppearanceItem = styled.div`
  display: flex;
  padding: ${theme.space(2.25)};
  border: 1px solid ${theme.colors.borderGrey.toString()};
  min-height: 120px;
  width: 420px;
  max-width: 100%;
  border-radius: 8px;
  margin: ${theme.space(0, 0, 2.5)};
  &[aria-hidden='true'] {
    display: none;
  }
`;

const AppearanceItemDetails = styled.div`
  padding: ${theme.space(0, 2.5, 0, 0)};
  flex-base: calc(100% - 36px);
`;

const Title = styled(Text)`
  margin: ${theme.space(0, 0, 1)};
`;

const AppearanceItemAction = styled.div`
  width: 36px;
`;

const Label = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  line-height: 2.4rem;
`;

const Options = styled.div`
  margin: ${theme.space(2.25, 0, 0)};
`;

const OptionsItem = styled.div`
  margin: ${theme.space(0, 0, 1)};
`;

interface FormData {
  decimalsEnabled: boolean;
  calculateDeltas: 'last-two' | 'first-last';
  showBarValue: boolean;
}

const Appearance = () => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const savePreferences = usePreferences();

  const UIPreferences = useRecoilValue(uiPreferencesSelector);

  const initialData = React.useMemo<FormData>(() => {
    return {
      decimalsEnabled: UIPreferences.decimalsEnabled,
      calculateDeltas: UIPreferences.calculateDeltas,
      showBarValue: UIPreferences.showBarValue,
    };
  }, [UIPreferences]);

  const { register, getValues, reset } = useForm({
    defaultValues: initialData,
  });

  React.useEffect(() => {
    reset(initialData);
  }, [reset, initialData]);

  const handleChange = React.useCallback(() => {
    savePreferences(getValues());
  }, [getValues, savePreferences]);

  const sendEvent = React.useCallback(
    (name: string) => () => {
      trackEvent(name);
    },
    [trackEvent],
  );

  return (
    <div>
      <SettingsHeader title='Appearance' />
      <form onChange={handleChange}>
        <AppearanceItem>
          <AppearanceItemDetails>
            <Title fontWeight='600' variant='h5'>
              {t(`appearance.decimals.title`)}
            </Title>
            <Text variant='body1'>{t(`appearance.decimals.p`)}</Text>
          </AppearanceItemDetails>
          <AppearanceItemAction>
            <Toggle name='decimalsEnabled' ref={register} onClick={sendEvent(TRACKING_EVENTS.APPEARANCE_DECIMALS_TOGGLE)} />
          </AppearanceItemAction>
        </AppearanceItem>
        <AppearanceItem>
          <AppearanceItemDetails>
            <Title fontWeight='600' variant='h5'>
              {t(`appearance.showBarValue.title`)}
            </Title>
            <Text variant='body1'>{t(`appearance.showBarValue.p`)}</Text>
          </AppearanceItemDetails>
          <AppearanceItemAction>
            <Toggle name='showBarValue' ref={register} onClick={sendEvent(TRACKING_EVENTS.APPEARANCE_BAR_VALUE_TOGGLE)} />
          </AppearanceItemAction>
        </AppearanceItem>
        <AppearanceItem aria-hidden>
          <AppearanceItemDetails>
            <Title fontWeight='600' variant='h5'>
              {t(`appearance.deltas.title`)}
            </Title>
            <Text variant='body1'>{t(`appearance.deltas.p`)}</Text>
            <Options>
              <OptionsItem>
                <Label>
                  <Radio name='calculateDeltas' value='first-last' ref={register} />
                  {t(`appearance.deltas.opt1`)}
                </Label>
              </OptionsItem>
              <OptionsItem>
                <Label>
                  <Radio name='calculateDeltas' value='last-tow' ref={register} />
                  {t(`appearance.deltas.opt2`)}
                </Label>
              </OptionsItem>
            </Options>
          </AppearanceItemDetails>
        </AppearanceItem>
      </form>
    </div>
  );
};

export default React.memo(Appearance);
