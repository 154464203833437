import styled from '@emotion/styled';
import { NotificationContainer } from 'common/components/Notification';
import logo from 'icons/logo.svg';
import { ChartViews } from 'common/types/chart';
import { scrollbarStyles } from './mixins';

interface BarChartContainerProps {
  showFullBar: boolean;
  barCount: number;
  isDirty: boolean;
  inPage?: boolean;
}

export const BarChartContainer = styled.div<BarChartContainerProps>`
  height: ${({ inPage }) => (inPage ? '360px' : '350px')};
  ${props => `
    width: ${props.showFullBar ? '' : props.barCount * 24 + props.barCount * 5.5 + 'px'};
    ${props.isDirty ? 'pointer-events: none;' : ''}
  `}
  svg > g > g > line:first-of-type {
    stroke: ${({ theme }) => theme.colors.gray400.toString()};
  }
`;

interface ChartContainerProps {
  dynamicHeightInChartView?: boolean;
  isGrowtPerformanceChart: boolean;
  isHeatmap: boolean;
  chartView: string | null;
  inPage: boolean;
  isRadarChart: boolean;
  isComparison: boolean;
  isMarketFunnel: boolean;
}

export const ChartContainer = styled.div<ChartContainerProps>`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  min-width: 0;
  min-height: 400px;
  color: ${({ theme }) => theme.colors.black.toString()};
  position: relative;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  svg {
    overflow: visible;
    display: block;
  }
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &[data-dirty='true'] {
    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
  &[data-rotated-labels='true'] {
    height: 450px;
  }
  &[data-inside-page='true'] {
    /* Put height to 100% to take whole height of parent */
    height: 100%;
    /* Restrict min height to 280px, which is container(400px) without header and all spacing */
    min-height: 280px;
    margin-top: 0px;
    /* If there is a legend inside bar chart, reduce the height for the legend bar height */
    &[data-has-legend='true'] {
      height: calc(100% - 48px);

      #line-chart-container {
        height: calc(100% - 40px);

        &[data-rotated-labels='true'] {
          height: calc(100% - 92px);
          margin-bottom: 40px;
        }
      }
    }
    #bar-chart-container {
      height: 100%;
    }
    /* If there is a rotated label for bar chart, reduce height so rotated labels can fit */
    #bar-chart-container {
      &[data-rotated-labels='true'] {
        height: calc(100% - 56px);
      }
    }
  }
  ${({ isHeatmap, theme }) =>
    !isHeatmap
      ? `
    ${NotificationContainer} {
      margin-top: ${() => `-${theme.space(5)}`};
    }
  `
      : null}

  ${({ isMarketFunnel }) =>
    isMarketFunnel
      ? `
      height: initial;
      `
      : null}
  &[data-has-scroll='true'] {
    padding-left: ${({ theme, inPage }) => theme.space(inPage ? 0 : 2)};
    overflow-y: ${({ chartView }) => (chartView === ChartViews.BAR ? 'auto' : 'none')};
  }
  &[data-scrollable-content='true'] {
    overflow: auto;
    padding: ${({ theme }) => theme.space(0, 1, 4, 0)};

    // Apply the scrollbar styles
    ${scrollbarStyles()}
  }
  &[data-expand-content='true'] {
    height: auto;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingLogo = styled.div`
  width: 48px;
  height: 48px;
  background: url(${logo}) no-repeat center center;
  background-size: cover;
`;

export const ChartContentWrapper = styled.div``;

export const DefaultTooltipContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space(1)};
  height: 24px;
  background: ${({ theme }) => theme.colors.sooty.toString()};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
`;
