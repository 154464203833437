import React from 'react';
import styled from '@emotion/styled';
import SelectPopup from 'common/components/SelectPopup';
import { ICONS } from 'icons/iconsMap';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import PillButton from 'common/components/PillButton';
import useFloatingMenu from 'common/hooks/useFloatingMenu';

/**
 * EXAMPLE OF USAGE OF THIS COMPONENT
 *
 * <DropdownPillMenu
      id='markets'
      label={'Markets'}
      items={[
        { label: 'Germany', value: 'germany', icon: 'alert' },
        { label: 'Spain', value: 'spain', icon: 'annotate' },
      ]}
      icon='globe'
      onSelect={options => console.log(options)}
      multiselect
    />
 */

const DropdownContainer = styled.div`
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.v2.colours.gray200};
  }
`;

interface Props {
  id: string;
  label: string;
  items: SelectPopupItemProps[];
  // Return a list of selected values
  onSelect: (options: Array<string>) => void;
  icon?: keyof typeof ICONS;
  removable?: boolean;
  onRemove?: (id: string) => void;
  showArrow?: boolean;
  multiselect?: boolean;
  isInitialyOpen?: boolean;
  removeSelectedItem?: boolean;
  isLoading?: boolean;
}

const DropdownPillMenu = ({
  id,
  label,
  items,
  onSelect,
  icon,
  removable,
  onRemove,
  showArrow = true,
  multiselect,
  isInitialyOpen = false,
  removeSelectedItem = false,
  isLoading,
}: Props) => {
  const { isOpen, setIsOpen, refs, floatingStyles, getFloatingProps, getReferenceProps } = useFloatingMenu({
    popupPlacement: 'bottom-start',
    isDefaultOpen: isInitialyOpen,
  });

  return (
    <DropdownContainer data-testid={id}>
      <PillButton
        id={id}
        label={label}
        removable={removable}
        icon={icon}
        showArrow={showArrow}
        getReferenceProps={getReferenceProps}
        setReference={refs.setReference}
        onRemove={onRemove}
      />
      <SelectPopup
        items={items}
        onSelect={onSelect}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        multiselect={multiselect}
        setFloating={refs.setFloating}
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        removeSelectedItem={removeSelectedItem}
        isLoading={isLoading}
      />
    </DropdownContainer>
  );
};

export default React.memo(DropdownPillMenu);
