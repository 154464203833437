import * as React from 'react';
import { LatanaText } from 'common/components/LatanaText';
import { GPGreenArrowComponent, GPRedArrowComponent } from 'icons';
import { MFChartData } from 'modules/charts/components/MarketFunnel/getMarketFunnelData';
import {
  TableArrowContainer,
  TableBody,
  TableBodyCellContainer,
  TableBodyHeader,
  TableBodyRow,
  TableCell,
  TableDataItem,
  ValueNameContainer,
} from '../../../../views/MarketFunnel/styled';

interface MarketFunnelBodyProps {
  data: MFChartData[];
}

const MarketFunnelBody: React.FC<MarketFunnelBodyProps> = ({ data }) => {
  const renderArrow = (isDynamicPositive: boolean | null | undefined) => {
    if (isDynamicPositive === null) return null;
    return isDynamicPositive ? <GPGreenArrowComponent /> : <GPRedArrowComponent />;
  };
  return (
    <TableBody>
      {data.map(({ index, values }) => (
        <TableBodyRow key={`market-funnel-${index}`}>
          <TableBodyHeader>
            <ValueNameContainer>
              <LatanaText variant='B2' color='gray500'>
                {index}
              </LatanaText>
            </ValueNameContainer>
          </TableBodyHeader>
          {values.map(({ previousWave, pastWave }, index) => {
            if (!previousWave || !pastWave) return null;
            return (
              <TableDataItem key={index}>
                <TableCell>
                  <TableBodyCellContainer>
                    {previousWave?.isDynamicPositive !== null && (
                      <TableArrowContainer>{renderArrow(previousWave?.isDynamicPositive)}</TableArrowContainer>
                    )}
                    <LatanaText variant='B2' color={previousWave.valueColor}>
                      {previousWave.value}
                    </LatanaText>
                    {previousWave.valueAbs !== null && (
                      <LatanaText variant='B2' color='gray500'>
                        {`(${previousWave.valueAbs})`}
                      </LatanaText>
                    )}
                  </TableBodyCellContainer>
                  <TableBodyCellContainer>
                    {pastWave?.isDynamicPositive !== null && (
                      <TableArrowContainer>{renderArrow(pastWave?.isDynamicPositive)}</TableArrowContainer>
                    )}
                    <LatanaText variant='B2' color={pastWave.valueColor}>
                      {pastWave.value}
                    </LatanaText>
                    {pastWave.valueAbs !== null && (
                      <LatanaText variant='B2' color='gray500'>
                        {`(${pastWave.valueAbs})`}
                      </LatanaText>
                    )}
                  </TableBodyCellContainer>
                </TableCell>
              </TableDataItem>
            );
          })}
        </TableBodyRow>
      ))}
    </TableBody>
  );
};

export default MarketFunnelBody;
