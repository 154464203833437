// display short names of kpi for funnel
const KPIShortNames = {
  'aided-brand-awareness': 'Aided',
  'unaided-brand-awareness': 'Unaided',
  'brand-usage': 'Usage',
  'brand-preference': 'Preference',
  'brand-consideration': 'Consideration',
};

export const getKpiShortName = (kpiName: string): string => KPIShortNames[kpiName as unknown as keyof typeof KPIShortNames];

export const getCurrentBarId = (id: string) => {
  const match = id.match(/value_([0-9]+)/);
  return match ? parseInt(match[1], 10) : 0;
};
