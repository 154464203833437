import * as React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFilter, IFilterOptionGroup, FilterNames, DynamicFilters } from 'common/types/filters';
import FilterModalColumn from 'modules/charts/components/Filters/FilterModal/FilterModalColumn';
import { KPIColumns } from 'modules/charts/components/Filters/FilterModal/utils';
import { ChartFields, ChartFormData, ChartType, ChartFilterId } from 'common/types/chart';
import { DEFAULT_ROWS, COLUMN_GAP, COLUMN_WIDTH, DEFAULT_CONTAINER_WIDTH } from 'settings/constants';
import {
  AllButtonContainer,
  ButtonContainer,
  Container,
  MissingABrandWrapper,
  OptionsContainer,
  ShowMoreButton,
  SpinnerContainer,
} from 'modules/charts/components/Filters/FilterModal/styled';
import PillCheckbox from 'common/components/PillCheckbox';
import { ChartDetails } from 'settings/constants';
import { useDynamicFilters } from 'modules/charts/components/Filters/hooks';
import { isAllSelected, getSortedValues, handleOptionSelection } from 'utils/filters';
import { allWavesStateAtom } from 'common/atoms/waves';
import { WaveFilterService } from '../services/wave';
import { useIsChartType } from 'common/hooks/useIsChartType';
import { useBrandCompetitorsByCountry } from 'common/hooks/brandGroups';
import { ChartValue } from 'common/types/charts';
import { useKpiTypeGroups } from 'common/hooks/kpis';
import Spinner from 'common/components/Spinner';
import Icon from 'common/components/Icon';
import { LatanaText } from 'common/components/LatanaText';
import { withTooltip } from 'common/components/Tooltip/withTooltip';

interface Props {
  filter: IFilter;
  active: boolean;
  availableOptions: (string | number)[];
  setChartValue: (chartField: string, values: ChartValue) => void;
  optionsLoading: boolean;
  isLimitExceeded: boolean;
  displayAddSegment: boolean;
  onHandleSelectAll: (filterId: FilterNames, values: (string | number)[]) => void;
  chartData: ChartFormData;
  onHandleDynoFilters: (dynamicFilter: DynamicFilters, status?: boolean) => void;
  dynamicFilter: DynamicFilters | null;
  allAvailableOptions: Record<string, (string | number)[] | undefined>;
  isAllOptionsLimit: boolean;
}

const NO_LIMIT = 100;

const FilterModalSection: React.FC<Props> = ({
  filter,
  active,
  availableOptions,
  setChartValue,
  optionsLoading,
  isLimitExceeded,
  displayAddSegment,
  onHandleSelectAll,
  chartData,
  onHandleDynoFilters,
  dynamicFilter,
  allAvailableOptions,
  isAllOptionsLimit,
}) => {
  const { t } = useTranslation();
  const filterOptions = filter.options;
  // number of fitting columns
  const [columns, setColumns] = React.useState(0);
  const [showAll, setShowAll] = React.useState(false);
  const colContainerRef = React.useRef<HTMLDivElement>();
  const [, setComputed] = React.useState(false);
  const { register, watch } = useFormContext();
  const kpisTypeGroups = useKpiTypeGroups(chartData.study_uuid);
  const setAllWavesState = useSetRecoilState(allWavesStateAtom);
  const [allWavesState] = useRecoilState(allWavesStateAtom);
  const selectedValues = watch(filter.id);

  const { country, chart_type: chartType, dynamic_waves: dynamicWaves } = chartData;

  const competitorsBrands = useBrandCompetitorsByCountry(country);

  const { isChartType } = useIsChartType(chartType);

  const isKPIFilter = filter.id === FilterNames.KPI;
  const isWaveFilter = filter.id === FilterNames.WAVE;
  const isBrandFilter = filter.id === FilterNames.BRAND;
  const isCountryFilter = filter.id === FilterNames.COUNTRY;

  const selectedMap: Record<string, boolean> = React.useMemo(() => {
    return (selectedValues || []).reduce((acc: Record<string, boolean>, value: string) => {
      acc[value] = true;
      return acc;
    }, {});
  }, [selectedValues]);

  const availableMap = React.useMemo(() => {
    return availableOptions.reduce((acc, value) => {
      acc[value] = true;
      return acc;
    }, {} as Record<string, true>);
  }, [availableOptions]);

  React.useEffect(() => {
    ['secondDimension', filter.id].forEach(name => register({ name }));
  });

  // ideally there should be ${DEFAULT_ROWS} rows, in case there are more than
  // ${DEFAULT_ROWS} rows, slice the content into available columns
  const calculateColumns = React.useCallback(() => {
    const container = colContainerRef.current;
    if (container) {
      const { width } = container.getBoundingClientRect();
      // added [1064] as a default container width
      // because [getBoundingClientRect()] returns 0 at first render
      let cols = Math.floor((width | DEFAULT_CONTAINER_WIDTH) / COLUMN_WIDTH);
      cols = cols * COLUMN_WIDTH + (cols - 1) * COLUMN_GAP > width ? cols - 1 : cols;
      setColumns(cols);
      setComputed(true);
    }
  }, []);

  React.useEffect(() => {
    if (active) {
      calculateColumns();
      window.addEventListener('resize', calculateColumns);
      return () => {
        window.removeEventListener('resize', calculateColumns);
      };
    }
  }, [calculateColumns, active]);

  const displayColumns = React.useMemo(() => {
    const isBrandFilter = filter.id === FilterNames.BRAND;
    const optionsCount = filterOptions?.reduce((acc, option) => {
      return acc + ((option as IFilterOptionGroup).options?.length || 1);
    }, 0);

    const rows = showAll ? Math.max(Math.ceil((optionsCount || 0) / columns), DEFAULT_ROWS) : DEFAULT_ROWS;
    return new KPIColumns(isBrandFilter ? NO_LIMIT : columns, isBrandFilter ? NO_LIMIT : rows, filterOptions, showAll, filter.label);
  }, [columns, filterOptions, showAll, filter.label, filter.id]);

  // max number of rows in columns
  const rowCount = React.useMemo(() => {
    const rows = displayColumns.columns.map(column => column.size);
    return Math.max(...rows);
  }, [displayColumns]);

  // filters should always have at least one selected value
  const isDeselectDisabled = React.useMemo(() => {
    return selectedValues.length < 2;
  }, [selectedValues]);

  const displayShowMore = React.useMemo(() => {
    return !showAll && displayColumns.maxedOut;
  }, [showAll, displayColumns]);

  const isAllWavesSelected = React.useCallback(() => {
    return isAllSelected(allAvailableOptions?.[ChartFields.WAVE], watch(ChartFields.WAVE)) && dynamicWaves === DynamicFilters.ALL_WAVES;
  }, [allAvailableOptions, dynamicWaves, watch]);

  React.useEffect(() => {
    if (isWaveFilter && active) {
      setAllWavesState(isAllWavesSelected());
    }
  }, [isWaveFilter, setAllWavesState, active, isAllWavesSelected]);

  // If a wave option is clicked, set dynamic_waves to null except for GP chart
  const setDynamicWavesToNull = React.useCallback(
    (selectionCount: number) => {
      if (filter.id === FilterNames.WAVE && !isChartType(ChartType.GROWTH_PERFORMANCE)) {
        const allAvailableOptions = (filter.options || ([] as IFilterOptionGroup[])).flatMap(el => (el as IFilterOptionGroup).options);
        const hasAllWaveSelected = allAvailableOptions.length === selectionCount;
        !hasAllWaveSelected && onHandleDynoFilters(DynamicFilters.ALL_WAVES, !hasAllWaveSelected);
      }
    },
    [filter, onHandleDynoFilters, isChartType],
  );

  const handleSelection = React.useCallback(
    (chartField: string, chartValue: Array<ChartFilterId>) => {
      setChartValue(chartField, getSortedValues(filter.optionsOrder, chartValue));
      setDynamicWavesToNull(chartValue.length);
    },
    [filter.optionsOrder, setChartValue, setDynamicWavesToNull],
  );

  const isSingleSelection = React.useMemo(() => {
    return isLimitExceeded || isChartType(ChartType.GAUGE) || isChartType(ChartType.MARKET_SIZE);
  }, [isLimitExceeded, isChartType]);

  const isRadioButton: boolean = React.useMemo(() => {
    return isSingleSelection || (isAllOptionsLimit && allWavesState);
  }, [isSingleSelection, isAllOptionsLimit, allWavesState]);

  const isAverageDeselect: boolean = React.useMemo(() => {
    return (isChartType(ChartType.HEATMAP) || isChartType(ChartType.RADAR)) && isBrandFilter;
  }, [isChartType, isBrandFilter]);

  const onOptionChange = React.useCallback(
    newValue => {
      const selectedOptions: Array<ChartFilterId> = [];

      if (isRadioButton) {
        selectedOptions.push(newValue);
        isAverageDeselect && setChartValue(ChartDetails.DISPLAY_REFERENCE_AVERAGE, false);
      } else {
        selectedOptions.push(...handleOptionSelection(selectedValues, newValue, isKPIFilter, kpisTypeGroups));
      }
      handleSelection(filter.id, selectedOptions);
    },
    [handleSelection, setChartValue, filter.id, isRadioButton, isAverageDeselect, selectedValues, isKPIFilter, kpisTypeGroups],
  );

  // in case previously selected options are no longer available
  // we should unselect them
  React.useLayoutEffect(() => {
    // check if available options are loaded
    if (active && !optionsLoading) {
      // get options that are no longer available
      const disabledSelectedValues = selectedValues.filter((value: ChartFilterId) => !availableMap[value]);
      if (disabledSelectedValues.length) {
        const newOptions = selectedValues.filter((value: ChartFilterId) => !disabledSelectedValues.includes(value));
        if (newOptions.length) {
          handleSelection(filter.id, newOptions);
        }
      }
    }
  }, [availableMap, selectedValues, active, optionsLoading, filterOptions, handleSelection, filter.id]);

  const isLastWaveSelected = React.useMemo(() => {
    return dynamicWaves === DynamicFilters.LAST_WAVE;
  }, [dynamicWaves]);

  const dynamicFiltersList = useDynamicFilters(
    () => onHandleSelectAll(filter.id as FilterNames, [...availableOptions]),
    () => onHandleDeselectAll(filter.id as FilterNames),
    onHandleDynoFilters,
    WaveFilterService.onHandleSelectLastWave,
    allWavesState,
    isLastWaveSelected,
    dynamicFilter,
    chartType,
    isSingleSelection,
  );

  const availableCompetitors = React.useMemo(() => {
    return competitorsBrands.filter(id => availableMap[id] === true).map(Number);
  }, [competitorsBrands, availableMap]);

  const onHandleSelectCompetitorBrands = React.useCallback(() => {
    setChartValue(FilterNames.BRAND, availableCompetitors);
  }, [setChartValue, availableCompetitors]);

  const onHandleDeselectAll = React.useCallback(
    (filterName: FilterNames) => {
      setChartValue(filterName, []);
    },
    [setChartValue],
  );

  const isShowDynamicFilters = isWaveFilter && availableOptions.length >= 1;

  const renderFilterModalColumns = () => {
    return displayColumns.columns.map(({ items }, index) => (
      <FilterModalColumn
        disabledUnselect={isDeselectDisabled}
        key={`${displayColumns.filterLabel}-${index}`}
        selectedMap={selectedMap}
        onOptionToggle={onOptionChange}
        name={filter.id}
        isSegmentSection={displayAddSegment}
        availableMap={availableMap}
        items={items}
        onHandleSelectAll={onHandleSelectAll}
        onHandleDeselectAll={onHandleDeselectAll}
        values={chartData}
        singleSelection={isSingleSelection}
        isAllOptionsLimit={isAllOptionsLimit}
        isDirectCompetitorsAvailable={!!availableCompetitors.length}
        onHandleSelectCompetitorBrands={onHandleSelectCompetitorBrands}
      />
    ));
  };

  return (
    <Container ref={colContainerRef as React.MutableRefObject<HTMLDivElement>} aria-hidden={!active}>
      {(isBrandFilter || isWaveFilter || isCountryFilter) && optionsLoading ? (
        <SpinnerContainer>
          <Spinner size='extraSmall' />
        </SpinnerContainer>
      ) : (
        <>
          <AllButtonContainer>
            {isShowDynamicFilters &&
              dynamicFiltersList.map(filterProps => (
                <PillCheckbox key={filterProps.label} name={ChartDetails.DYNAMIC_WAVES} {...filterProps} />
              ))}
          </AllButtonContainer>
          <OptionsContainer rows={rowCount} columns={displayColumns.columns.length} data-all={showAll}>
            {isBrandFilter && (
              <MissingABrandWrapper>
                {withTooltip(
                  <>
                    <LatanaText variant='L1' color='gray500'>
                      {t('filters.missingBrand')}
                    </LatanaText>
                    <Icon icon='info' color='gray600' />
                  </>,
                  t('filters.missingBrandMessage'),
                )}
              </MissingABrandWrapper>
            )}
            {renderFilterModalColumns()}
          </OptionsContainer>
          {displayShowMore && (
            <ButtonContainer>
              <ShowMoreButton type='button' onClick={() => setShowAll(true)} color='lightPurple'>
                {t('filters.showMore')}
              </ShowMoreButton>
            </ButtonContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default React.memo(FilterModalSection);
