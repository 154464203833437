import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from 'common/components/Form/Button';
import ModalV2 from 'common/components/Modal/ModalV2';
import { Content, ButtonWrapper } from './styled';

interface Props {
  open: boolean;
  isLoading?: boolean;
  title: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  children: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  type?: 'warning' | 'confirm';
}

const ConfirmationModal: React.FC<Props> = ({
  open,
  isLoading = false,
  onCancel,
  onConfirm,
  title,
  children,
  confirmButtonTitle,
  cancelButtonTitle,
  type = 'warning',
}) => {
  const { t } = useTranslation();

  return (
    <ModalV2 modalId='confirmation-modal' isOpen={open} title={title} onClose={onCancel} clickOutsideClose={!isLoading}>
      <Content>
        {children}
        <ButtonWrapper>
          <LoadingButton disabled={isLoading} isButtonV2={true} type='button' color='gray100' onClick={onCancel}>
            {cancelButtonTitle ? cancelButtonTitle : t('common.cancel')}
          </LoadingButton>
          <LoadingButton
            disabled={isLoading}
            isButtonV2={true}
            loading={isLoading}
            type='button'
            color={type === 'warning' ? 'wine' : 'lightPurple'}
            onClick={onConfirm}
          >
            {confirmButtonTitle ? confirmButtonTitle : t('common.delete')}
          </LoadingButton>
        </ButtonWrapper>
      </Content>
    </ModalV2>
  );
};

export default React.memo(ConfirmationModal);
