import { useMemo } from 'react';
import { generateFiltersData } from 'utils/filters';
import { FiltersOptions, IFilterOption, IFilterOptionGroup } from 'common/types/filters';

export const useAvailableFiltersOptions = (options: FiltersOptions): Record<string, (string | number)[] | undefined> => {
  return useMemo(() => {
    if (options.availableFilters) {
      return generateFiltersData(options).reduce((acc, { id, options }) => {
        acc[id] = options?.reduce((acc, option) => {
          const isGroup = Array.isArray((option as IFilterOptionGroup).options);
          if (isGroup) {
            const group = option as IFilterOptionGroup;
            acc = acc?.concat(group.options.map(({ value }) => value));
          } else {
            option = option as IFilterOption;
            acc?.push(option.value);
          }

          return acc;
        }, [] as (string | number)[] | undefined);
        return acc;
      }, {} as Record<string, (string | number)[] | undefined>);
    } else {
      return {};
    }
  }, [options]);
};
