import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { API_URLS } from 'settings/api';
import { fetchSelector } from 'common/atoms/common';
import { CSVExportStatus } from 'common/types/common';
import { useRollbar } from '@rollbar/react';
import { useCallback } from 'react';
import { useShowToast } from 'common/hooks/notification';
import { LogArgument } from 'rollbar';

type CSVStudyExport = {
  exportCSV: (email: string, studyIds: string[]) => void;
  csvExportStatus: CSVExportStatus | undefined;
  setCSVExportStatus: (status: CSVExportStatus) => void;
};

export const useCSVStudyExport = (): CSVStudyExport => {
  const [csvExportStatus, setCSVExportStatus] = React.useState<CSVExportStatus>();
  const rollbar = useRollbar();
  const showToast = useShowToast();
  const { fetchPost } = useRecoilValue(fetchSelector);

  const exportCSV = useCallback(
    (email: string, studyIds: string[]) => {
      setCSVExportStatus(CSVExportStatus.LOADING);

      try {
        fetchPost(API_URLS.EXPORT_CSV, {
          recipient_email: encodeURI(email),
          study_ids: studyIds,
        })
          .then(() => {
            setCSVExportStatus(CSVExportStatus.SUCCESS);
          })
          .catch((e: unknown) => {
            if ((e as { status: number })?.status === 422) {
              showToast({
                status: 'error',
                message:
                  "We're sorry, but CSV extraction is currently under maintenance. If you need assistance urgently, please contact our customer success team.",
              });
            }
          });
      } catch (e: unknown) {
        rollbar.error('useCSVStudyExport error', e as LogArgument);
      }
    },
    [fetchPost, rollbar, showToast],
  );

  return { exportCSV, csvExportStatus, setCSVExportStatus };
};
