import React from 'react';
import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { Text } from 'common/styledComponents/typography';
import { useTranslation } from 'react-i18next';
import ReportItem from 'modules/settings/components/WaveReports/ReportItem';
import { useGetWaveReports } from 'common/queries/waveReports';
import { DataTestId } from 'settings/constants';

const Header = styled.div`
  padding: ${theme.space(0, 0, 2.5)};
  margin: ${theme.space(0, 0, 5.5)};
  border-bottom: 2px solid ${theme.colors.black.toString()};
`;

const ReportsContainer = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid ${theme.colors.borderGrey.toString()};
`;

const WaveReports = () => {
  const { t } = useTranslation();
  const { data: reports } = useGetWaveReports();

  return (
    <div>
      <Header>
        <Text variant='h4' color='black'>
          {t('reports.title')}
        </Text>
      </Header>
      <ReportsContainer data-testid={DataTestId.WAVE_REPORTS}>
        {reports?.map(report => (
          <ReportItem key={report.id} report={report} />
        ))}
      </ReportsContainer>
    </div>
  );
};

export default React.memo(WaveReports);
