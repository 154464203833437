import React from 'react';
import { ComputedSerie, CustomLayerProps } from '@nivo/line';

const MoeArea: React.FC<CustomLayerProps> = ({ series, yScale }: CustomLayerProps) => {
  const generateAreaPoints = (serie: ComputedSerie) => {
    const areaPoints = serie.data.reduce((acc: any, pointData, index) => {
      const moeLow = pointData?.data?.y ? (yScale as any)(((pointData?.data?.y as number) || 0) - pointData.data.moe) : 0;
      const moeHigh = pointData?.data?.y ? (yScale as any)(((pointData?.data?.y as number) || 0) + pointData.data.moe) : 0;
      const low = `${pointData.position.x},${moeLow}`;
      const high = `${pointData.position.x},${moeHigh}`;

      acc.splice(index, 0, low);
      acc.splice(acc.length - 1 - index, 0, high);

      return acc;
    }, []);
    return areaPoints;
  };

  return (
    <>
      {series.map(serie => {
        const areaPoints = generateAreaPoints(serie);

        return (
          <polygon
            style={{ pointerEvents: 'none' }}
            key={`MoeArea-${serie.id}`}
            points={areaPoints.join(' ')}
            fill={serie.color}
            fillOpacity={0.1}
            strokeWidth={2}
          />
        );
      })}
    </>
  );
};

export default MoeArea;
