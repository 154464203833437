import * as React from 'react';
import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { Button } from '../Form/Button';
import { Text } from 'common/styledComponents/typography';
import TextInput from '../TextInput';
import { accountDetailsSelector } from 'common/atoms/account';
import { useRecoilValueLoadable } from 'recoil';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useCSVStudyExport } from './useCSVStudyExport';
import { CSVExportStatus } from 'common/types/common';
import CheckboxListItem from 'common/components/CheckboxListItem';
import { useEffect, useState } from 'react';
import Modal from 'common/components/Modal';
import { useGetAllStudies } from 'common/queries/studies';
import { scrollbarStyles } from 'common/styledComponents/mixins';

interface FormData {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().required(),
});

const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const P = styled(Text)`
  margin: ${({ theme }) => theme.space(2.5, 0, 1.5, 0)};
  opacity: 0.75;
`;

const Form = styled.form`
  margin: ${theme.space(4, 0, 0)};
  &[aria-busy='true'] {
    opacity: 0.72;
    pointer-events: none;
  }
`;

const SelectAllWrapper = styled.div`
  margin-bottom: ${theme.space(1.5)};
  margin-top: ${theme.space(2.5)};
  cursor: pointer;
  opacity: 0.75;
`;

const FooterActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.space(4)};
  border-top: 1px solid ${({ theme }) => theme.colors.gray100.toString()};
  justify-content: flex-end;
  padding-top: ${theme.space(1.5)};
`;

const CheckboxListGroupWrapper = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  padding-right: ${theme.space(0.5)};

  // Apply the scrollbar styles
  ${scrollbarStyles()}
`;

interface StudyCheckboxItem {
  label: string;
  value: string;
  checked: boolean;
}

interface Props {
  onClose: () => void;
  open: boolean;
}

const CSVExportModal: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const accoundDetails = useRecoilValueLoadable(accountDetailsSelector);
  const { data: studiesList } = useGetAllStudies();

  const { exportCSV, csvExportStatus, setCSVExportStatus } = useCSVStudyExport();

  const [allStudiesCheckboxList, setAllStudiesCheckboxList] = useState<StudyCheckboxItem[]>([]);

  useEffect(() => {
    setAllStudiesCheckboxList((studiesList || [])?.map(study => ({ label: study.name, value: study.uuid, checked: true })));
  }, [studiesList]);

  const handleClose = React.useCallback(() => {
    onClose();
    setTimeout(() => setCSVExportStatus(CSVExportStatus.PENDING), 400);
  }, [onClose, setCSVExportStatus]);

  const initialValues = React.useMemo(() => {
    if (accoundDetails.state !== 'hasValue') {
      return {
        email: '',
      };
    } else {
      return {
        email: accoundDetails.contents.user.email,
      };
    }
  }, [accoundDetails.state, accoundDetails.contents]);

  const { register, reset, handleSubmit, errors } = useForm<FormData>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const onStudyCheckboxToogle = (value: string, checked: boolean) => {
    const newSelectedStudyState = allStudiesCheckboxList.map(selectedStudy => {
      if (selectedStudy.value === value) {
        return { ...selectedStudy, checked };
      }

      return selectedStudy;
    });
    setAllStudiesCheckboxList(newSelectedStudyState);
  };

  const selectedStudiesForExport = React.useMemo(() => {
    return allStudiesCheckboxList.flatMap(study => (study.checked ? [study.value] : []));
  }, [allStudiesCheckboxList]);

  const handleSelectDeselectAll = () => {
    const newSelectedStudyState = allStudiesCheckboxList.map(selectedStudy => ({
      ...selectedStudy,
      checked: selectedStudiesForExport.length === 0, // Select all only if none of the study are selected
    }));
    setAllStudiesCheckboxList(newSelectedStudyState);
  };

  const exportStudiesToCsv = (email: string) => {
    const studiesListToExport = allStudiesCheckboxList.flatMap(study => (study.checked ? [study.value] : []));
    exportCSV(email, studiesListToExport);
  };

  return (
    <Modal
      isOpen={open}
      title={t('csvExport.exportCSV')}
      titleAlign='left'
      handleClose={onClose}
      showCloseButton={false}
      clickOutsideClose={true}
      customSpacing={[3, 4, 2, 4]}
      customWidth={600}
    >
      <Container>
        {csvExportStatus === CSVExportStatus.SUCCESS ? (
          <>
            <P variant='h5'>{t('csvExport.success')}</P>
            <FooterActionsWrapper>
              <Button onClick={handleClose} type='button' pseudo opacityHover>
                {t('common.gotIt')}
              </Button>
            </FooterActionsWrapper>
          </>
        ) : (
          <>
            <SelectAllWrapper onClick={handleSelectDeselectAll}>
              <Text variant='body2' color='lightPurple'>
                {
                  /* 
                    Show “Select all” if 0 items are selected
                    Show “Deselect all” if >0 items are selected
                  */
                  selectedStudiesForExport.length === 0 ? t('csvExport.selectAll') : t('csvExport.deselectAll')
                }
              </Text>
            </SelectAllWrapper>
            <CheckboxListGroupWrapper>
              {allStudiesCheckboxList.map(study => (
                <CheckboxListItem
                  key={`DownloadCheckbox-${study.value}`}
                  value={study.value}
                  label={study.label}
                  checked={study.checked}
                  onCheck={onStudyCheckboxToogle}
                />
              ))}
            </CheckboxListGroupWrapper>

            <P variant='body2' color='sooty'>
              {t('csvExport.desc')}
            </P>

            <Form
              aria-busy={csvExportStatus === CSVExportStatus.LOADING}
              onSubmit={handleSubmit(formData => exportStudiesToCsv(formData.email))}
            >
              <TextInput
                ref={register({
                  required: true,
                })}
                placeholder='Email'
                type='email'
                marginBottom={false}
                variant='smaller'
                name='email'
              />
              <FooterActionsWrapper>
                <Button onClick={handleClose} type='button' pseudo opacityHover>
                  {t('common.cancel')}
                </Button>
                <Button disabled={Boolean(errors.email) || selectedStudiesForExport.length === 0} type='submit' pill={false} square>
                  {t('csvExport.submit')}
                </Button>
              </FooterActionsWrapper>
            </Form>
          </>
        )}
      </Container>
    </Modal>
  );
};

export default React.memo(CSVExportModal);
