import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import { ChartToolbarContainer } from 'modules/charts/components/ChartRenderer/styled';

export const DashboardCardsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin: auto;
`;

export const CardHeaderText = styled(Text)<{ editMode?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.sooty.toString()};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.space(1)};
  margin-left: ${({ theme, editMode }) => (editMode ? theme.space(1) : theme.space(0))};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space(0.5)};
`;

export const CardDescription = styled.div`
  font-weight: ${({ theme }) => theme.text.variants.body1.fontWeight};
  font-size: ${({ theme }) => theme.text.variants.body1.fontSize};
  color: ${({ theme }) => theme.colors.regularGrey.toString()};
  line-height: ${({ theme }) => theme.text.lineHeight3};
  overflow-wrap: anywhere;
`;

export const CardWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white.toString()};
  padding: ${({ theme }) => theme.space(2, 3, 2)};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  margin: 0;
  height: 450px;
  flex-direction: column;
  flex-basis: calc(50% - 16px);
  width: calc(50% - 16px);
  position: relative;
  display: flex;

  &[data-expand-content='true'] {
    height: auto;
    max-height: initial;
    min-height: 400px;
  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    ${ChartToolbarContainer} {
      opacity: 1;
    }
  }
`;

export const CardRemoveIconWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.v2.space(2)};
  cursor: pointer;
  padding: ${({ theme }) => theme.v2.space(0.5)};
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;

  &:hover {
    background: ${({ theme }) => theme.v2.colours.gray100};
  }
`;

export const CardDragIconWrapper = styled.span`
  cursor: pointer;
  position: absolute;
  left: ${({ theme }) => theme.v2.space(1)};
`;
