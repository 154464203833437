import * as React from 'react';
import { Text } from 'common/styledComponents/typography';
import { DynamicWaves } from 'settings/constants';
import { Label, CustomInput, Button } from './styled';

interface PillCheckboxProps {
  type?: string;
  onChange: () => void;
  disabled?: boolean;
  label: string;
  selected: boolean;
  disabledUnselect?: boolean;
  isAriaDisabled?: boolean;
  name?: string;
  withModalToggle?: boolean;
  value?: number | string;
  ref?: React.Ref<HTMLInputElement>;
  opaqueWhenDisabled?: boolean;
  dataTestid?: string;
  disabledMessage?: string;
  renderChild?: (value: string | number | undefined) => JSX.Element;
}

interface CustomButtonProps {
  title: string;
  dataAll: boolean;
  children: Element | undefined | JSX.Element | null;
  onChange: () => void;
  dataTestId?: string;
}

const CustomButton = ({ title, dataAll, children, onChange, dataTestId }: CustomButtonProps) => (
  <Button title={title} data-all={dataAll} onChange={onChange} data-testid={dataTestId}>
    <Text variant='body1' marginBottom={false} marginTop={false} color='inherit'>
      {title}
    </Text>
    {children}
  </Button>
);

const PillCheckbox: React.FC<PillCheckboxProps> = React.forwardRef<HTMLInputElement, PillCheckboxProps>(
  (
    {
      disabledUnselect,
      isAriaDisabled,
      selected,
      disabled,
      name,
      onChange,
      label,
      value,
      type,
      renderChild,
      opaqueWhenDisabled = false,
      dataTestid,
      disabledMessage,
    },
    ref,
  ) => {
    const wrapperTestId = type === DynamicWaves.SELECT_ALL_BUTTON ? `options-${name}.all` : `options-${disabled}-${name}`;
    const isAllButton = Object.values(DynamicWaves).includes(type as DynamicWaves);

    return (
      <Label
        aria-readonly={disabledUnselect}
        aria-disabled={isAriaDisabled}
        data-testid={wrapperTestId}
        data-hover={disabled && disabledMessage ? disabledMessage : undefined}
      >
        <CustomInput
          ref={ref}
          type='checkbox'
          checked={selected}
          disabled={disabled}
          onChange={onChange}
          name={name || `${name}.selected`}
          data-testid={`customInput-${name}`}
          opaqueWhenDisabled={opaqueWhenDisabled}
        />
        <CustomButton title={label} dataAll={isAllButton} onChange={onChange} dataTestId={dataTestid}>
          {renderChild?.(value)}
        </CustomButton>
      </Label>
    );
  },
);

PillCheckbox.displayName = 'PillCheckbox';

export default PillCheckbox;
