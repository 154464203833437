import React from 'react';
import { LatanaText } from 'common/components/LatanaText';
import { BpColumnKey, SortType } from 'common/types/chart';
import { BpHeadItem, BpCell } from 'modules/charts/components/BrandPerception/styled';
import { SortController } from 'modules/charts/components/TableChart/styled';
import SortingControl from 'modules/charts/components/TableChart/SortingControl';
import { withTooltip } from 'common/components/Tooltip/withTooltip';

interface BpTableHeadItemProps {
  title: string;
  tooltip: string;
  sortType: SortType | null;
  columnKey: BpColumnKey;
  setSortType: (columnKey: BpColumnKey) => void;
}

const BpTableHeadItem: React.FC<BpTableHeadItemProps> = ({ columnKey, title, setSortType, sortType, tooltip }) => (
  <BpHeadItem>
    <BpCell>
      {withTooltip(
        <LatanaText color='gray500' variant='B2'>
          {title}
        </LatanaText>,
        tooltip,
      )}
      {columnKey !== null && (
        <SortController onClick={() => setSortType(columnKey)}>
          <SortingControl sortType={sortType} />
        </SortController>
      )}
    </BpCell>
  </BpHeadItem>
);

export default BpTableHeadItem;
