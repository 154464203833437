import { KPI, Brand } from 'common/types/common';
import { ChartFields } from 'common/types/chart';

export const isGlobalKPI = (kpis: KPI[], kpi: (string | number)[] | undefined): boolean => {
  return kpis.some(kpiItem => {
    if (!kpiItem.global) return false;

    const options = [
      ...(kpiItem?.question_options?.map(({ option_identifier }) => option_identifier) || []),
      ...(kpiItem?.kpi_formulas?.map(({ kpi_identifier }) => kpi_identifier) || []),
    ];

    return kpi?.includes(kpiItem.kpi_identifier) || kpi?.some(e => options?.includes(e as string));
  });
};

export const handleGlobalKPISelection = (
  globalMetaBrand: Brand | undefined,
  kpi: (string | number)[],
  isGlobalKPI: boolean,
  setChartValue: (field: string, value: (string | number)[]) => void,
  brand: (string | number)[],
): boolean | undefined => {
  if (!globalMetaBrand || !kpi?.length || !setChartValue) return false;

  if (isGlobalKPI) {
    setChartValue(ChartFields.BRAND, [globalMetaBrand.id]);
  } else {
    setChartValue(
      ChartFields.BRAND,
      brand.filter(brandId => globalMetaBrand.id !== brandId),
    );
  }
};
