import React, { memo } from 'react';
import { ModalIconButtonClose, ModalInner, ModalOverlay, ModalSubtitle, ModalTitle, Inner, BottomContainer } from './styled';
import Icon from 'common/components/Icon';
import { DataTestId } from 'settings/constants';

interface Props {
  isOpen: boolean;
  title?: string;
  titleAlign?: 'left' | 'center';
  subtitle?: string;
  handleClose: () => void;
  children: React.ReactNode;
  customWidth?: number;
  customSpacing?: number[];
  showCloseButton: boolean;
  stickyHeader?: boolean;
  bottomContent?: React.ReactNode;
  clickOutsideClose?: boolean;
  isModalV2?: boolean;
}

const Modal: React.FC<Props> = ({
  isOpen,
  title,
  titleAlign = 'center',
  subtitle,
  handleClose,
  children,
  customWidth = 792,
  customSpacing = [2],
  showCloseButton,
  stickyHeader = false,
  bottomContent = null,
  clickOutsideClose = false,
  isModalV2 = false,
}) => {
  const [ready, setReady] = React.useState(false);
  const innerRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (isOpen) setReady(true);
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen && clickOutsideClose) {
      const inner = innerRef?.current;
      const handleOutsideClick = (e: MouseEvent) => {
        const inside = inner?.contains(e.target as HTMLElement);
        if (inside) return;
        handleClose();
      };
      document.addEventListener('click', handleOutsideClick, true);
      return () => {
        document.removeEventListener('click', handleOutsideClick, true);
      };
    }
  }, [isOpen, clickOutsideClose, handleClose, innerRef]);

  return (
    <ModalOverlay data-ready={ready} aria-hidden={!isOpen}>
      {isOpen && (
        <ModalInner
          data-testid={DataTestId.MODAL_INNER}
          ref={innerRef as React.LegacyRef<HTMLDivElement> | undefined}
          spacing={customSpacing}
          width={customWidth}
          data-sticky-header={stickyHeader}
          data-modal-v2={isModalV2}
        >
          <>
            {showCloseButton && (
              <ModalIconButtonClose onClick={handleClose}>
                <Icon icon='x' />
              </ModalIconButtonClose>
            )}
            {title && (
              <ModalTitle data-modal-v2={isModalV2} data-align={titleAlign} data-testid='modal-title'>
                {title}
                {subtitle && <ModalSubtitle data-testid='modal-subtitle'>{subtitle}</ModalSubtitle>}
              </ModalTitle>
            )}
          </>
          <Inner data-sticky={stickyHeader} data-modal-v2={isModalV2}>
            {children}
          </Inner>
          {bottomContent && <BottomContainer>{bottomContent}</BottomContainer>}
        </ModalInner>
      )}
    </ModalOverlay>
  );
};

export default memo(Modal);
