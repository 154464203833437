import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { useRecoilValue } from 'recoil';
import { useRollbar } from '@rollbar/react';
import { processError } from 'utils/helpers';

export const useTags = (): UseQueryResult<string[], unknown> => {
  const rollbar = useRollbar();
  const { fetchGet } = useRecoilValue(fetchSelector);
  return useQuery(
    ['tags'],
    async () => {
      try {
        return await fetchGet<string[]>(API_URLS.TAGS);
      } catch (e) {
        processError(e, rollbar.error, 'Failed to fetch tags');
      }
    },
    {
      staleTime: Infinity,
      placeholderData: [],
    },
  );
};
