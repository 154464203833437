import React, { ReactNode } from 'react';

interface HistoryContextProviderProps {
  children: ReactNode;
  value?: HistoryContextProps;
}

interface HistoryContextProps {
  previousPath: string | null;
  pushToHistory: (path: string | null) => void;
}

const HistoryContext = React.createContext<HistoryContextProps>({
  previousPath: '',
  pushToHistory: () => null,
});

export const useNavigationContext = () => {
  return React.useContext(HistoryContext);
};

export const HistoryContextProvider: React.FC<HistoryContextProviderProps> = ({ children }) => {
  const [history, setHistory] = React.useState<string | null>(null);

  const pushToHistory = React.useCallback(
    (path: string | null) => {
      setHistory(path);
    },
    [setHistory],
  );

  return <HistoryContext.Provider value={{ previousPath: history, pushToHistory }}>{children}</HistoryContext.Provider>;
};
