import * as React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';
import { useAuthContext } from 'common/hooks/useOnAuthContext';
import Main from 'views/Main';

const AuthHandle = () => {
  const { loggedIn } = useAuthContext();
  const outlet = useOutlet();

  if (!loggedIn) {
    return <Navigate to='/login' />;
  }

  return <Main>{outlet}</Main>;
};

export default React.memo(AuthHandle);
