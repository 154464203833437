import React from 'react';
import styled from '@emotion/styled';
import SelectPopup from 'common/components/SelectPopup';
import { ICONS } from 'icons/iconsMap';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { Placement } from '@floating-ui/react';
import PillButton from 'common/components/PillButton';
import useFloatingMenu from 'common/hooks/useFloatingMenu';

const DropdownContainer = styled.div<{ isHovered?: boolean; onClick?: () => void }>`
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ theme, isHovered }) => theme.v2.colours[isHovered ? 'gray200' : 'gray0']};
  }
`;

export interface ActionMenuProps {
  id?: string;
  label?: string;
  isDefaultOpen?: boolean;
  stopPropagation?: boolean;
  isHovered?: boolean;
  items?: SelectPopupItemProps[];
  icon?: keyof typeof ICONS;
  popupPlacement?: Placement;
  onSelect?: (options: Array<string>) => void;
  setSelfHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  setWrapperHovered?: React.Dispatch<React.SetStateAction<boolean>>;
  onClick?: () => void;
}

const ActionMenu = ({
  id = '',
  label,
  isDefaultOpen = false,
  stopPropagation = false,
  isHovered,
  items = [],
  icon,
  popupPlacement = 'bottom-end',
  onSelect = () => {},
  setSelfHovered,
  setWrapperHovered,
  onClick,
}: ActionMenuProps) => {
  const { isOpen, setIsOpen, refs, floatingStyles, getFloatingProps, getReferenceProps } = useFloatingMenu({
    popupPlacement,
    isDefaultOpen,
  });

  /**
   * handleMouseLeave and handleMouseOver are making sure that
   * there should only one hover effect either on row or action button.
   */
  const handleMouseLeave = () => {
    setSelfHovered && setSelfHovered(false);
    setWrapperHovered && setWrapperHovered(true);
  };

  const handleMouseOver = () => {
    setSelfHovered && setSelfHovered(true);
    setWrapperHovered && setWrapperHovered(false);
  };

  return (
    <DropdownContainer
      data-testid={id}
      isHovered={isHovered}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      onClick={onClick}
    >
      <PillButton
        id={id}
        label={label}
        icon={icon}
        getReferenceProps={getReferenceProps}
        setReference={refs.setReference}
        stopPropagation={stopPropagation}
      />
      <SelectPopup
        items={items}
        onSelect={onSelect}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setFloating={refs.setFloating}
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        removeSelectedItem
      />
    </DropdownContainer>
  );
};

export default React.memo(ActionMenu);
