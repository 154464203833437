import React, { FC } from 'react';
import { GridLabelProps } from '@nivo/radar';
import { theme } from 'common/theme';
import { RADAR_MINMAX_ANGLE } from 'settings/constants';

interface RadarGridLabelProps extends GridLabelProps {
  maxValue: number;
}

const gridStyles = {
  common: {
    color: theme.colors.sonicSilver.toString(),
    fontWeight: theme.text.variants.subheading1.fontWeight,
    fontSize: theme.space(1.5),
    fontFamily: theme.text.fontFamily,
  },
};

export const RadarGridLabel: FC<RadarGridLabelProps> = ({ anchor, x, y, id, angle, maxValue }) => {
  return (
    <text>
      <tspan textAnchor={anchor} x={x} y={y} style={{ ...gridStyles.common, color: theme.colors.mortarGrey.toString() }}>
        {id}
      </tspan>
      {angle === RADAR_MINMAX_ANGLE && (
        <>
          <tspan x={x - 8} y={y + 30} style={gridStyles.common}>
            {maxValue}
          </tspan>
          <tspan x={x - 5} y={y + 180} style={gridStyles.common}>
            {0}
          </tspan>
        </>
      )}
    </text>
  );
};
