import * as React from 'react';
import { useCallback } from 'react';

export interface SelectedItemStateProps {
  chartUuid: string;
  chartType?: string;
  title?: string;
  description?: string;
  size?: string;
  uuid?: string;
}
interface DashboardContextProps {
  onAddNewDashboard: () => void;
  onToggleDashboardModal: () => void;
  isDashboardModalOpen: boolean;
  dashboardState: Array<SelectedItemStateProps>;
  updateDashboardState: (newState: SelectedItemStateProps[]) => void;
  removeCardFromDashboard?: (chartUuid: string | null | undefined, uuid?: string) => void;
}

interface DashboardContextProviderProps {
  children: JSX.Element;
  context: DashboardContextProps;
}

export const DashboardContext = React.createContext<DashboardContextProps>({
  onAddNewDashboard: () => null,
  onToggleDashboardModal: () => null,
  isDashboardModalOpen: false,
  dashboardState: [],
  updateDashboardState: () => null,
});

export const DashboardContextProvider: React.FC<DashboardContextProviderProps> = ({ children, context }) => {
  const [dashboardState, setDashboardState] = React.useState<SelectedItemStateProps[]>([]);

  const updateDashboardState = useCallback((newState: SelectedItemStateProps[]) => {
    setDashboardState(newState);
  }, []);

  const removeCardFromDashboard = useCallback(
    (chartUuid: string | null | undefined, uuid?: string) => {
      if (uuid || chartUuid) {
        setDashboardState(dashboardState.filter(x => (uuid ? x.uuid !== uuid : x.chartUuid !== chartUuid)));
      }
    },
    [dashboardState],
  );

  return (
    <DashboardContext.Provider
      value={{
        ...context,
        dashboardState,
        updateDashboardState,
        removeCardFromDashboard,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = (): DashboardContextProps => {
  return React.useContext(DashboardContext);
};
