import React from 'react';
import styled from '@emotion/styled';
import { BackButton } from 'common/styledComponents/buttons';
import { Text } from 'common/styledComponents/typography';
import { ListMenu } from 'common/styledComponents/list';
import { useTranslation } from 'react-i18next';
import { SettingsItem } from 'common/types/settings';
import SidebarItem from 'modules/settings/components/SidebarItem';
import { useAuthContext } from 'common/contexts/AuthContext';
import { clearRedirectPath } from 'utils/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.unit * 3}px 0 0;
`;

const Container = styled.div`
  display: block;
  flex-basis: ${({ theme }) => theme.sidebar.width}px;
  min-width: ${({ theme }) => theme.sidebar.width}px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 28px;
  position: relative;
`;

const HeadingContainer = styled.div`
  margin: 0 0 ${({ theme }) => theme.spacing.unit * 5}px;
  h3 {
    font-size: 33px;
    line-height: 44px;
  }
`;

const LogoutButton = styled.div`
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.unit * 6}px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.wine.toString()};
  }
`;

interface Props {
  onSelection: (itemId: string) => void;
  currentItem?: string | undefined;
  items: SettingsItem[];
}

const SettingsSidebar: React.FC<Props> = ({ onSelection, items, currentItem }) => {
  const { t } = useTranslation();
  const { logout } = useAuthContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const onBackButtonHandle = () => {
    // If we came from other page to profile(prevUrl is existing), go one page back in nav history
    if ((state as Record<string, string>)?.prevUrl) {
      navigate(-1);
    } else {
      // If profile page is opened from redirect URL, page history is empty, so on back we should go to root and clear redirect URL
      clearRedirectPath();
      navigate('/charts', { replace: true });
    }
  };

  return (
    <Container>
      <BackButton onClick={onBackButtonHandle} />
      <HeadingContainer>
        <Text variant='h3'>{t('settings.settings')}</Text>
      </HeadingContainer>
      <ProfileContainer>
        <ListMenu>
          {items.map(item => {
            return (
              <Link to={item.id} key={item.id} replace>
                <SidebarItem key={item.id} active={item.id === currentItem} onClick={onSelection} item={item} />
              </Link>
            );
          })}
        </ListMenu>
      </ProfileContainer>
      <LogoutButton onClick={logout}>
        <Text variant='body2' color='wine'>
          {t('profile.logout')}
        </Text>
      </LogoutButton>
    </Container>
  );
};

export default React.memo(SettingsSidebar);
