import * as React from 'react';
import styled from '@emotion/styled';
import PasswordForm from './PasswordForm';
import { generateJWT } from 'utils/auth';
import { useLogin } from 'common/hooks/login';
import { GrantTypes } from 'common/types/login';
import { useInvite } from 'common/hooks/account';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'common/hooks/useOnAuthContext';

const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.darkPurple.toString()};
`;

const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Invite = () => {
  const { token } = useParams();
  const { onAuth } = useAuthContext();
  const [error, setError] = React.useState<string>();
  const signIn = useLogin(onAuth, setError);

  const handleLogin = React.useCallback(
    (email: string, password: string) => {
      const token = generateJWT(email, password);
      signIn({ grant_type: GrantTypes.PASSWORD, assertion: token });
    },
    [signIn],
  );

  const { setNewPassword, message, busy } = useInvite(token, handleLogin, setError);

  return (
    <Cover>
      <Container>
        <PasswordForm busy={busy} error={error} message={message} onSubmit={setNewPassword} />
      </Container>
    </Cover>
  );
};

export default React.memo(Invite);
