import React from 'react';
import { AxisProps } from '@nivo/axes';
import { theme } from 'common/theme';
import { displayDate } from 'utils/dates';
import { WavePayload } from 'common/types/common';
import { chartValueInMsOrKs, concatEllipsis } from 'utils/helpers';

const CHAR_LIMIT_Y_AXIS = 22;

const getDataLabel = (
  value: number,
  numeric: boolean,
  waveMap: Record<number, WavePayload>,
  showPopulationNumbers: boolean,
  isBarLayout: boolean,
) => {
  const waveDate: string | Date = waveMap[value]?.date;
  const axisValue = showPopulationNumbers && !isBarLayout ? chartValueInMsOrKs(value, null) : value;
  const label = waveDate
    ? displayDate(waveDate, 'MMMM YYYY')
    : `${axisValue}${showPopulationNumbers || (!numeric && isNaN(value)) ? '' : '%'}`;
  const labelWithoutGroup = label.split(': ').length > 1 ? label.split(': ')[1] : label;
  return concatEllipsis(labelWithoutGroup, CHAR_LIMIT_Y_AXIS);
};

export const DefaultAxisLeft = (
  numeric: boolean,
  waveMap: Record<number, WavePayload>,
  showPopulationNumbers: boolean,
  isBarLayout: boolean,
): AxisProps<any> =>
  ({
    tickSize: 0,
    tickPadding: theme.spacing.unit,
    renderTick: ({ value, textX, textY, textAnchor, rotate, x, y }) => {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            textAnchor={textAnchor}
            dominantBaseline='middle'
            transform={`translate(${textX},${textY}) rotate(${rotate})`}
            style={{
              fontSize: theme.text.variants.p.fontSize,
              fontWeight: theme.text.variants.p.fontWeight,
              fill: theme.colors.moreThanAWeek.toString(),
            }}
          >
            <title>{value}</title>
            {getDataLabel(value, numeric, waveMap, showPopulationNumbers, isBarLayout)}
          </text>
        </g>
      );
    },
  } as AxisProps);
