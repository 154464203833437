import * as React from 'react';
import { globalHistory } from '@reach/router';
import { ErrorBoundary } from '@rollbar/react';
import { useClearAllErrors } from 'common/hooks/errors';
import Notification from 'common/components/Notification';
import Layout from 'common/components/Layout';
import { DashboardContextProvider } from 'common/contexts/DashboardContext';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { accountDetailsSelector, currentStudyAtom } from 'common/atoms/account';
import { StudyContext } from 'common/contexts/StudyContext';
import { useTracking } from 'common/hooks/tracking';
import { CREATE_CHART_HASH, CREATE_NEW, CREATE_SEGMENT_HASH } from 'settings/constants';
import { useAppcues } from 'common/hooks/useAppcues';
import { useGetAllRestrictions } from 'common/queries/filters';
import { brandsColorMapAtom } from 'common/atoms/brands';
import { generateBrandsColorMap } from 'utils/brands';
import { useRawSegments } from 'common/queries/segments';
import { ChartDashboardsContextProvider } from 'common/contexts/ChartsContext';
import { HistoryContextProvider } from 'common/contexts/HistoryContext';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'common/hooks/useOnAuthContext';
import { useGetAllCountries } from 'common/queries/countries';
import { useGetAllKpis } from 'common/queries/kpis';
import { segmentsColorsMapAtom } from 'common/atoms/segments';
import { generateSegmentsColorsMap } from 'utils/segments';
import { useGetAllBrands } from 'common/queries/brands';

const RECOIL_HAS_VALUE = 'hasValue';

interface Props {
  children: React.ReactNode;
}

const MainView = ({ children }: Props) => {
  const { loggedIn } = useAuthContext();
  const location = useLocation();
  const [{ studyId: currentStudy }] = useRecoilState(currentStudyAtom);
  const { data: segments, isLoading: isSegmentsLoading } = useRawSegments();
  const accountDetails = useRecoilValueLoadable(accountDetailsSelector);

  const { data: allBrandsList } = useGetAllBrands();
  const navigate = useNavigate();
  useGetAllRestrictions();
  useGetAllKpis();
  useGetAllCountries();

  useAppcues(accountDetails?.contents, currentStudy);

  const { state: accountDetailsState, contents: accountDetailsContent } = accountDetails;

  const setBrandsColorsMap = useSetRecoilState(brandsColorMapAtom);
  const setSegmentsColorsMap = useSetRecoilState(segmentsColorsMapAtom);

  const { updateUserProperties } = useTracking();
  const { clearErrors } = useClearAllErrors();

  const [isDashboardModalOpen, toggleDashboardModal] = React.useState(false);

  React.useEffect(() => {
    if (isSegmentsLoading) return;
    setSegmentsColorsMap(generateSegmentsColorsMap(segments));
  }, [setSegmentsColorsMap, segments, isSegmentsLoading]);

  React.useEffect(() => {
    if (accountDetailsState === RECOIL_HAS_VALUE) {
      updateUserProperties(accountDetailsContent);
    }
  }, [accountDetailsState, accountDetailsContent, updateUserProperties]);

  React.useEffect(() => {
    if (allBrandsList) {
      // Generate unique brands color map
      setBrandsColorsMap(generateBrandsColorMap(allBrandsList));
    }
  }, [allBrandsList, setBrandsColorsMap]);

  // Remove alert box whenever page is changed
  React.useEffect(() => {
    return globalHistory.listen(() => {
      clearErrors();
    });
  }, [clearErrors]);

  const studyContext = React.useMemo(
    () => ({
      isSelectionModalOpen: [CREATE_SEGMENT_HASH, CREATE_CHART_HASH].includes(location?.hash as string),
      toggleStudySelectionModal: (state: boolean, hash = CREATE_CHART_HASH) => navigate(state ? hash : '#'),
    }),
    [location, navigate],
  );

  const dashboardContext = React.useMemo(
    () => ({
      dashboardState: [],
      updateDashboardState: () => null,
      isDashboardModalOpen,
      onAddNewDashboard: () => navigate(`dashboard/${CREATE_NEW}`),
      onToggleDashboardModal: () => toggleDashboardModal(prevState => !prevState),
    }),
    [isDashboardModalOpen, toggleDashboardModal, navigate],
  );

  if (!loggedIn) {
    return <Navigate to='/login' />;
  }

  return (
    <StudyContext.Provider value={studyContext}>
      <DashboardContextProvider context={dashboardContext}>
        <ChartDashboardsContextProvider>
          <HistoryContextProvider>
            <Layout>
              <ErrorBoundary fallbackUI={() => <Notification />} errorMessage='Dashboard view error'>
                {children}
              </ErrorBoundary>
            </Layout>
          </HistoryContextProvider>
        </ChartDashboardsContextProvider>
      </DashboardContextProvider>
    </StudyContext.Provider>
  );
};

export default React.memo(MainView);
