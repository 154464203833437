import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes, Theme } from '@emotion/react';

interface Props {
  color?: keyof Theme['colors'] | 'inherit';
  size?: 'extraSmall' | 'small' | 'medium';
}

const spinnerBorder = keyframes`
	to { transform: rotate(360deg); }
`;

const spinnerSizes = {
  extraSmall: '2.4rem',
  small: '3.6rem',
  medium: '4.8rem',
};

const SpinnerElement = styled.div<Props>`
  display: inline-block;
  width: ${({ size = 'medium' }) => spinnerSizes[size]};
  height: ${({ size = 'medium' }) => spinnerSizes[size]};
  vertical-align: text-bottom;
  border: ${({ size = 'medium' }) => `${size === 'extraSmall' ? 3 : 4}px solid currentColor`};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${spinnerBorder} 0.75s linear infinite;
  color: ${({ theme, color }) => (color ? theme.colors[color as keyof Theme['colors']] || color : theme.colors.lightPurple).toString()};
`;

const Spinner: React.FC<Props> = ({ color, size = 'medium' }) => {
  return <SpinnerElement color={color} size={size}></SpinnerElement>;
};

export default React.memo(Spinner);
