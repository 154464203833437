import React from 'react';
import { Serie } from '@nivo/line';
import { ChartDataPoint } from 'common/types/chart';
import { LineChartDataPoint } from 'modules/charts/components/LineChart';
import { useGetPrimaryBrands } from 'common/hooks/brands';
import { useBrandCompetitorsByCountry } from 'common/hooks/brandGroups';

const getUniqueLineData = (data: Serie[]): Serie[] => {
  return data.reduce((uniqueLines, line) => {
    const found = uniqueLines.find(({ id }) => id === line.id);
    if (!found) uniqueLines.push(line as Serie);
    return uniqueLines;
  }, [] as Serie[]);
};

const calculateCompetitorBrands = ({
  primaryBrands,
  competitorBrands,
  lineData,
  currentSelectedLine,
}: {
  primaryBrands: string[];
  competitorBrands: number[];
  lineData: Serie[];
  currentSelectedLine: string | undefined;
}): Serie[] => {
  const primaryBrandLineData = lineData.filter(({ id }) => primaryBrands.includes(id as string));
  const competitorBrandsLineData = lineData.filter(({ id }) => competitorBrands?.includes(+id));
  const selectedLineData = lineData.filter(({ id }) => id === currentSelectedLine);
  let linesToReturn: Serie[] = [];
  if (currentSelectedLine) {
    linesToReturn = [...primaryBrandLineData, ...selectedLineData, ...competitorBrandsLineData];
  } else {
    linesToReturn = [lineData[0], ...competitorBrandsLineData];
  }
  return getUniqueLineData(linesToReturn);
};

const preparedLineDataPoints = (dataItem: ChartDataPoint, keys: string[], showPopulationNumbers: boolean): LineChartDataPoint[] => {
  return keys.reduce((dataPoints, key) => {
    const waveId = dataItem[`${key}_wave_id` as 'value_0_wave_id'];
    const label = dataItem[`${key}_label` as 'value_0_label'];
    const unavailable = dataItem[`${key}_unavailable` as 'value_0_unavailable'];

    if (!unavailable) {
      dataPoints.push({
        y: showPopulationNumbers ? dataItem[`${key}_populationNumberRaw` as 'value_0_populationNumberRaw'] : dataItem[key as 'value_0'],
        yRaw: dataItem[`${key}_raw` as 'value_0_raw'] * 100,
        x: waveId,
        label: label,
        color: dataItem[`${key}_color` as 'value_0_color'],
        moe: dataItem[`${key}_moe` as 'value_0_moe'],
        numericValue: dataItem[`${key}_numeric` as 'value_0_numeric'],
        groupLabel: dataItem.groupLabel,
        populationNumberRaw: dataItem[`${key}_populationNumberRaw` as 'value_0_populationNumberRaw'],
      });
    }
    return dataPoints;
  }, [] as LineChartDataPoint[]);
};

export const usePrepareLineData = (data: ChartDataPoint[], keys: string[], showPopulationNumbers: boolean): Serie[] => {
  const visibleData = data.filter(({ disabled }) => !disabled);
  const lineData = React.useMemo(
    () =>
      visibleData.map(dataItem => ({
        id: `${dataItem.groupId}`,
        color: dataItem?.color,
        data: preparedLineDataPoints(dataItem, keys, showPopulationNumbers),
      })),
    [visibleData, keys, showPopulationNumbers],
  );
  return lineData;
};

export const useGetLineData = ({
  inDashboard = false,
  data,
  keys,
  currentSelectedLine,
  countryIds,
  orgProfileEnabled,
  showPopulationNumbers,
}: {
  inDashboard: boolean | undefined;
  data: ChartDataPoint[];
  keys: string[];
  currentSelectedLine: string | undefined;
  countryIds: number[];
  orgProfileEnabled: boolean;
  showPopulationNumbers: boolean;
}): Serie[] => {
  const primaryBrands = useGetPrimaryBrands();
  const lineData = usePrepareLineData(data, keys, showPopulationNumbers);
  const competitorBrands = useBrandCompetitorsByCountry(countryIds);

  if (inDashboard) {
    const selectedLineData = lineData.filter(({ id }) => id === currentSelectedLine);
    // Return competitors brands along selected brand for dashboard view
    if (orgProfileEnabled) {
      return calculateCompetitorBrands({ primaryBrands, competitorBrands, lineData, currentSelectedLine });
    }
    return currentSelectedLine ? selectedLineData : [lineData[0]];
  }
  return lineData;
};
