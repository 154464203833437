import { useTranslation } from 'react-i18next';
import { IGoogleAuthProps, GrantTypes, Providers } from 'common/types/login';
import { API_URLS } from 'settings/api';
import { useTracking } from './tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { OverridableTokenClientConfig, useGoogleLogin } from '@react-oauth/google';

const loginLogger = (provider: string | undefined, tracker: (eventName: string, params?: unknown) => void): void => {
  switch (provider) {
    case Providers.GOOGLE:
      tracker(TRACKING_EVENTS.LOGGED_IN_WITH_GOOGLE);
      break;
    case Providers.LATANA:
      tracker(TRACKING_EVENTS.LOGGED_IN_FROM_ADMIN_PANEL);
      break;
    default:
      break;
  }
};

export const useGoogleAuth = (
  onHandleSubmit: (payload: IGoogleAuthProps) => void,
  onHandleError: (text: string) => void,
): ((overrideConfig?: OverridableTokenClientConfig | undefined) => void) => {
  const { t } = useTranslation();
  return useGoogleLogin({
    onSuccess: ({ access_token }): void => {
      onHandleSubmit({ grant_type: GrantTypes.ASSERTION, provider: Providers.GOOGLE, assertion: access_token });
    },
    onError: () => onHandleError(t('login.errors.authFailed')),
  });
};

export const useLogin = (
  onHandleAuth: (authToken: string, expires: Date, refreshToken: string) => void,
  onHandleError: (text: string) => void,
): ((payload: IGoogleAuthProps) => Promise<void>) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();

  const userLogin = async (payload: IGoogleAuthProps) => {
    const result = await fetch(API_URLS.TOKEN, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${payload.assertion}`,
        'Content-Type': 'application/json',
      },
    });

    if (result.ok) {
      loginLogger(payload.provider, trackEvent);
      const { access_token, expires_in, created_at, refresh_token } = await result.json();
      const expires = new Date(created_at * 1000);
      expires.setSeconds(expires.getSeconds() + expires_in);
      return onHandleAuth(access_token, expires, refresh_token);
    } else {
      const { error } = await result.json();
      onHandleError(t(`login.errors.${error}`));
    }
  };

  return userLogin;
};
