import { useEffect, useState } from 'react';

export const useLegendResizeObserver = (elementId: string, heightThreshold = 40): boolean => {
  const [hideLegend, setHideLegend] = useState<boolean>(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const contentRect = entries[0].contentRect;
      setHideLegend(contentRect.height > heightThreshold);
    });

    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      resizeObserver.observe(targetElement);
    }

    return () => resizeObserver.disconnect();
  }, [elementId, heightThreshold]);

  return hideLegend;
};
