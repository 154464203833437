import { BpChartData, BpIndexMap, RawChartData, RawReferenceAverageData, ReferenceBrand } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';
import { AVERAGE, AVERAGE_VALUE } from 'settings/constants';
import { calculateSentiments } from 'modules/charts/components/BrandPerception/utils/calculateSentiments';
import { Country, Wave } from 'common/types/common';

enum BRAND_PERCEPTION_KPI {
  'brand-perception_good' = 'positive',
  'brand-perception_not-sure' = 'neutral',
  'brand-perception_bad' = 'negative',
}

export const mapKpiToSentiment = (chartData: RawChartData[]) =>
  chartData.map(waveChunk => {
    if (!Array.isArray(waveChunk)) return [];
    const mappedData: { [key: string]: RawChartData } = {};

    waveChunk.forEach(currentItem => {
      const sentiment = BRAND_PERCEPTION_KPI[currentItem.kpi as keyof typeof BRAND_PERCEPTION_KPI];
      const existingItem = mappedData[currentItem.wave];

      if (existingItem) {
        existingItem[sentiment] = currentItem.mean; // Update the sentiment value
      } else {
        mappedData[currentItem.wave] = { ...currentItem, [sentiment]: currentItem.mean };
      }
    });

    return Object.values(mappedData);
  });

const getIndexName = (indexId: number, indexMap: BpIndexMap, mainIndex: FilterNames): string => {
  if (!indexMap || !mainIndex) return 'No name';
  if (indexId === AVERAGE_VALUE) return AVERAGE;
  const indexName = (indexMap[mainIndex as keyof BpIndexMap] as Array<string>)?.find(item => item[indexId])?.[indexId];
  return indexName ?? 'No name';
};

export const composeChartData = (
  chartData: RawChartData[],
  mainIndex: FilterNames,
  mainIndexIdList: number[],
  indexMap: BpIndexMap,
  waveMap: Record<number, Wave>,
  countryMap: Record<number, Country>,
  brandAwarenessData: RawChartData[],
) => {
  const extractIndexData = (indexId: number) => {
    const mappedData = mapKpiToSentiment(chartData);
    return mappedData.find(item => item?.[0]?.[mainIndex] === indexId) || [];
  };

  return [...mainIndexIdList, AVERAGE_VALUE].map(
    (indexId: number): BpChartData => ({
      index: getIndexName(indexId, indexMap, mainIndex),
      ...calculateSentiments(extractIndexData(indexId), waveMap, countryMap, brandAwarenessData),
    }),
  );
};

export const calculateAverageMeans = (averageData: RawReferenceAverageData[]) => {
  const waveGroups: Record<number, RawReferenceAverageData[]> = {};
  averageData.forEach(entry => {
    if (!waveGroups[entry.wave]) {
      waveGroups[entry.wave] = [];
    }
    waveGroups[entry.wave].push(entry);
  });

  const averagedData: RawReferenceAverageData[] = [];
  Object.keys(waveGroups).forEach(waveKey => {
    const waveData = waveGroups[parseInt(waveKey)];
    const kpiSums: Record<string, number> = {};
    const kpiCounts: Record<string, number> = {};
    let country = 0;
    let segment = 0;
    let referenceBrands: ReferenceBrand[] = [];

    waveData.forEach(entry => {
      segment = entry.segment;
      referenceBrands = entry.reference_brands;
      country = entry.country;

      if (!kpiSums[entry.kpi]) {
        kpiSums[entry.kpi] = 0;
        kpiCounts[entry.kpi] = 0;
      }

      kpiSums[entry.kpi] += entry.mean;
      kpiCounts[entry.kpi]++;
    });

    Object.keys(kpiSums).forEach(kpi => {
      averagedData.push({
        wave: parseInt(waveKey),
        segment,
        country,
        kpi,
        mean: kpiSums[kpi] / kpiCounts[kpi],
        reference_brands: referenceBrands,
      });
    });
  });

  return averagedData;
};
