import React from 'react';
import { useRecoilValue } from 'recoil';
import { ChartDataPointTooltip, ChartNumbersType, FunnelTooltiop } from 'common/types/chart';
import { uiPreferencesSelector } from 'common/atoms/account';
import { TooltipContainer, Title, TooltipItemsContainer, Subtitle } from './styled';
import { FunnelTooltip } from './FunnelTooltip';
import { GeneralTooltip } from './GeneralTooltip';
import { AverageTooltipBlock } from './AverageTooltipBlock';

interface Props {
  tooltip: ChartDataPointTooltip;
  moeEnabled: boolean | undefined;
  brandGrouped?: boolean;
  funnel?: FunnelTooltiop | null;
  averageValue?: number;
  averageCalculatedFrom?: Record<string, string>;
  averagePopulationValue: string | null;
  chartNumbersType: ChartNumbersType;
}

const ChartTooltip: React.FC<Props> = ({
  tooltip: { title, subtitle, items },
  funnel,
  moeEnabled,
  averageValue,
  averageCalculatedFrom,
  averagePopulationValue,
  chartNumbersType,
}) => {
  const { decimalsEnabled } = useRecoilValue(uiPreferencesSelector);
  return (
    <TooltipContainer>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <TooltipItemsContainer>
        {funnel ? (
          <FunnelTooltip tooltip={funnel} moeEnabled={moeEnabled} decimalsEnabled={decimalsEnabled} />
        ) : (
          <React.Fragment>
            {(items || []).map((tooltip, index) => (
              <GeneralTooltip
                key={index}
                tooltip={tooltip}
                decimalsEnabled={decimalsEnabled}
                moeEnabled={moeEnabled}
                chartNumbersType={chartNumbersType}
              />
            ))}
            {!!averageValue && (
              <AverageTooltipBlock
                averageValue={averageValue}
                decimalsEnabled={decimalsEnabled}
                moeEnabled={moeEnabled}
                averageCalculatedFrom={averageCalculatedFrom}
                averagePopulationValue={averagePopulationValue}
                chartNumbersType={chartNumbersType}
              />
            )}
          </React.Fragment>
        )}
      </TooltipItemsContainer>
    </TooltipContainer>
  );
};

export default React.memo(ChartTooltip);
