import styled from '@emotion/styled';
import { tooltipOnHover } from 'common/styledComponents/modals';

export const SidebarContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.v2.space(1, 1, 3, 1)};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: ${({ theme }) => theme.v2.colours.indigo50};
`;

export const TopNavContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TopActionsGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.v2.space(1)};
  background: ${({ theme }) => theme.v2.colours.indigo25};
  border-radius: 100px;
  margin-top: ${({ theme }) => theme.v2.space(2)};
  gap: ${({ theme }) => theme.v2.space(1)};
`;

export const Logo = styled.img`
  height: 48px;
`;

export const IconButtonContainer = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.v2.colours.indigo100};
  }

  &[data-add-button='true'] {
    background: ${({ theme }) => theme.v2.colours.indigo400};

    &:hover {
      background: ${({ theme }) => theme.v2.colours.indigo500};
    }
  }

  &[data-active='true'] {
    background: ${({ theme }) => theme.v2.colours.indigo200};

    > svg > path {
      fill: ${({ theme }) => theme.v2.colours.indigo800};
    }
  }
  ${tooltipOnHover()}
`;

export const BottomNavContainer = styled.div``;

export const BottomActionsGroup = styled.div``;

export const ButtonDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.v2.colours.indigo100};
  margin: ${({ theme }) => theme.v2.space(1, 0)};
`;
