import { atom } from 'recoil';
import { SegmentColorMapType, Segments } from 'common/types/segments';

export const allSegmentsListAtom = atom<Segments[] | undefined>({
  key: 'AllSegmentsList',
  default: undefined,
});

export const segmentsColorsMapAtom = atom<SegmentColorMapType | null>({
  key: 'segmentsColorMap',
  default: null,
});
