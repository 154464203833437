import React from 'react';
import { RawChartSchema, RawChartData } from 'common/types/chart';
import styled from '@emotion/styled';
import MatrixChartComponent from 'modules/charts/components/MatrixChart';
import { useRecoilValue } from 'recoil';
import { brandsColorMapAtom } from 'common/atoms/brands';
import { useAllKpisMap } from 'common/hooks/kpis';
import { calculateMatrixAxisBounds, combineKpiData } from 'modules/charts/components/MatrixChart/utils';
import { useDataIndexMap } from 'modules/charts/components/MatrixChart/hooks/useIndexMap';
import { Fallback } from 'common/styledComponents/loading';
import { BRAND_PERCEPTION_KPI, BRAND_PERCEPTION_KPI_LABEL } from 'settings/constants';
import { useGetWavesOrderedByDate } from 'common/hooks/waves';

const MatrixChartViewContainer = styled.div`
  height: 100%;
`;

interface Props {
  chartConf: RawChartSchema;
  chartData?: RawChartData[];
  inPage: boolean;
  disabledChartDataIds: (string | number)[];
  showMatrixChangeOverTime: boolean;
}

const MatrixChartView = ({ chartConf, chartData, disabledChartDataIds, showMatrixChangeOverTime }: Props) => {
  const secondDimension = chartConf.configuration.second_dimension;
  const indexDataMap = useDataIndexMap();
  const kpiMap = useAllKpisMap(chartConf.study_uuid);
  const brandsColorMap = useRecoilValue(brandsColorMapAtom);
  const orderedAllWaves = useGetWavesOrderedByDate(chartConf.study_uuid);
  const xAxisKpi = chartConf.kpi_option_identifiers[0];
  const yAxisKpi = chartConf.kpi_option_identifiers[1];
  const secondDimensionItems = chartConf?.configuration?.second_dimension_items || [];

  if (indexDataMap.isLoading) return <Fallback />;

  const orderedWaves = [...chartConf?.wave_ids].sort((a, b) => orderedAllWaves.indexOf(a) - orderedAllWaves.indexOf(b));

  const datapoints = combineKpiData(
    chartData,
    xAxisKpi,
    yAxisKpi,
    secondDimension,
    indexDataMap,
    brandsColorMap,
    disabledChartDataIds,
    orderedWaves,
  );

  const axisBounds = calculateMatrixAxisBounds(datapoints);
  const visibleDatapoints =
    secondDimensionItems.length > 0 ? datapoints.filter(x => secondDimensionItems?.includes(x.id) && !x.hidden) : datapoints;

  return (
    <MatrixChartViewContainer>
      <MatrixChartComponent
        yAxis={{
          min: axisBounds.yMin,
          max: axisBounds.yMax,
          label: yAxisKpi === BRAND_PERCEPTION_KPI ? BRAND_PERCEPTION_KPI_LABEL : kpiMap[yAxisKpi],
          percentage: true,
        }}
        xAxis={{
          min: axisBounds.xMin,
          max: axisBounds.xMax,
          label: xAxisKpi === BRAND_PERCEPTION_KPI ? BRAND_PERCEPTION_KPI_LABEL : kpiMap[xAxisKpi],
          percentage: true,
        }}
        datapoints={visibleDatapoints}
        showMatrixChangeOverTime={showMatrixChangeOverTime}
        chartUuid={chartConf?.uuid}
      />
    </MatrixChartViewContainer>
  );
};

export default React.memo(MatrixChartView);
