import React from 'react';

interface Dimension {
  defaultHeight?: number;
  defaultWidth?: number;
  heightOffset?: number;
  widthOffset?: number;
}

export const useGetViewportSize = (dimension = {} as Dimension) => {
  const { defaultHeight = 0, defaultWidth = 0, widthOffset = 0, heightOffset = 0 } = dimension;
  const [viewportSize, setViewportSize] = React.useState({ viewportWidth: defaultWidth, viewportHeight: defaultHeight });

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setViewportSize({ viewportWidth: window.innerWidth - widthOffset, viewportHeight: window.innerHeight - heightOffset });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [widthOffset, heightOffset]);

  return viewportSize;
};
