import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/styledComponents/typography';
import { useRecoilState } from 'recoil';
import { alertBoxAtom } from 'common/atoms/ui';

const AlertBoxWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.wine.toString()};
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white.toString()};
  z-index: 10;
`;

const AlertMessage: FC = () => {
  const { t } = useTranslation();
  const [alertBox] = useRecoilState(alertBoxAtom);

  if (!alertBox?.show) {
    return null;
  }

  return (
    <AlertBoxWrapper>
      <Text color='white' variant='body2'>
        {alertBox.message ? alertBox.message : t('notifications.errorMessages.generic')}
      </Text>
    </AlertBoxWrapper>
  );
};

export default AlertMessage;
