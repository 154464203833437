import React, { useState, useCallback, useEffect } from 'react';
import { LoadingWrapper, SelectPopupContainer } from './styled';
import SelectItem from 'common/components/SelectPopup/SelectItem';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import Spinner from 'common/components/Spinner';

interface Props {
  items: SelectPopupItemProps[];
  isOpen: boolean;
  onSelect: (value: Array<string>) => void; // Return a list of selected values
  'data-testid'?: string;
  multiselect?: boolean;
  // Props for Floating UI Library
  setFloating: (node: HTMLElement | null) => void;
  floatingStyles: React.CSSProperties;
  getFloatingProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removeSelectedItem?: boolean;
  isLoading?: boolean;
}

const SelectPopup = ({
  items,
  onSelect,
  'data-testid': dataTestid,
  setFloating,
  isOpen,
  floatingStyles,
  multiselect,
  getFloatingProps,
  setIsOpen,
  removeSelectedItem,
  isLoading = false,
}: Props) => {
  const [options, setOptions] = useState<SelectPopupItemProps[]>(items?.map(x => ({ ...x })));

  useEffect(() => {
    if (removeSelectedItem) {
      setOptions(items);
    }
  }, [items, setOptions, removeSelectedItem]);

  const filterSelectedOptions = (options: SelectPopupItemProps[]) => {
    return options.reduce((checkedValues: (string | number)[], option) => {
      if (option.checked) {
        checkedValues.push(option.value);
      }
      return checkedValues;
    }, []);
  };

  const onSelectHandle = useCallback(
    (value: string, checked: boolean) => {
      // If it is single select, just return single value and close popup
      if (!multiselect) {
        onSelect([value]);
        setIsOpen(false);
        return;
      }
      const newOptionsState = options.map(item => (item.value === value ? { ...item, checked } : item));
      setOptions(newOptionsState);
      onSelect(filterSelectedOptions(newOptionsState) as Array<string>);
    },
    [onSelect, options, multiselect, setIsOpen],
  );

  return (
    <>
      {isOpen && (
        <SelectPopupContainer ref={setFloating} style={floatingStyles} data-testid={dataTestid} {...getFloatingProps()}>
          {isLoading && (
            <LoadingWrapper>
              <Spinner size='extraSmall' />
            </LoadingWrapper>
          )}
          {!isLoading &&
            Array.isArray(options) &&
            options?.map(({ value, label, icon, checked, blurb, disable, action }, i) => (
              <SelectItem
                key={`select-item-${value}`}
                label={label}
                value={value as string}
                onSelect={onSelectHandle}
                isCheckbox={multiselect}
                icon={icon}
                checked={checked}
                isDisabled={disable}
                blurb={blurb}
                action={action}
                index={i}
                itemCount={options.length}
              />
            ))}
        </SelectPopupContainer>
      )}
    </>
  );
};

export default React.memo(SelectPopup);
