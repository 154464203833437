import { useGetAllBrands } from 'common/queries/brands';
import { BrandPayload } from 'common/types/common';
import { useMemo } from 'react';

export const useGetPrimaryBrands = (): string[] => {
  const { data: allBrands } = useGetAllBrands();

  return useMemo(() => {
    return (allBrands || []).reduce((primaryIds: string[], obj: BrandPayload) => {
      if (obj.primary) {
        primaryIds.push(obj.id.toString());
      }
      return primaryIds;
    }, []);
  }, [allBrands]);
};
