import styled from '@emotion/styled';
import { GraphicsContainer } from '@nivo/bar';
import { useTooltip } from '@nivo/tooltip';
import { theme } from 'common/theme';
import { useTooltipContext } from 'modules/charts/context/TooltipContext';
import { heatmapTheme } from 'modules/charts/theme';
import * as React from 'react';
import { getNumericValue } from 'utils/helpers';
import TooltipRenderer from '../ChartTooltip/TooltipRenderer';
import Tooltip from './Tooltip';
import { ChartNumbersType } from 'common/types/chart';

const CellContainer = styled.g`
  &:hover {
    rect {
      fill: ${heatmapTheme.colorScale[heatmapTheme.colorScale.length - 1]};
    }

    text {
      fill: ${heatmapTheme.textColor} !important;
    }
  }
`;

export const MIN_CELL_HEIGHT = 18;

interface CellData {
  value: number;
  id: string;
  xKey: string;
  yKey: string;
}

interface Props {
  isTextOverflow: boolean;
  data: CellData;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  opacity: number;
  borderWidth: number;
  borderColor: string;
  textColor: string;
  onHover: (data: CellData, e: React.MouseEvent<GraphicsContainer, MouseEvent>) => void;
  onLeave: (data: CellData, e: React.MouseEvent<GraphicsContainer, MouseEvent>) => void;
  xLabel: string;
  yLabel: string;
  populationValue: string | null;
  chartNumbersType: ChartNumbersType;
}

const CustomCell: React.FC<Props> = ({
  isTextOverflow,
  data,
  x,
  y,
  width,
  height,
  color,
  opacity,
  borderWidth,
  borderColor,
  textColor,
  onHover,
  onLeave,
  xLabel,
  yLabel,
  populationValue,
  chartNumbersType,
}) => {
  const { value, xKey, yKey } = data;
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const { tooltipGroupId } = useTooltipContext();

  const handleTooltipEnter = React.useCallback(() => {
    if (tooltipGroupId) {
      tooltipGroupId.current = data.id;
    }
  }, [data.id, tooltipGroupId]);

  const handleTooltipLeave = React.useCallback(() => {
    if (tooltipGroupId) {
      tooltipGroupId.current = undefined;
      // debouce mouse leave
      setTimeout(() => {
        if (tooltipGroupId.current === undefined) {
          hideTooltip();
        }
      }, 0);
    }
  }, [tooltipGroupId, hideTooltip]);

  const heatmapTooltip = React.useMemo(() => {
    return (
      <TooltipRenderer onMouseEnter={handleTooltipEnter} onMouseLeave={handleTooltipLeave} x={x} y={y}>
        <Tooltip
          value={chartNumbersType === ChartNumbersType.VALUES ? populationValue : `${getNumericValue(value)}%`}
          xAxis={{ key: xLabel, value: xKey }}
          yAxis={{ key: yLabel, value: yKey }}
        />
      </TooltipRenderer>
    );
  }, [handleTooltipEnter, handleTooltipLeave, x, y, value, xKey, yKey, xLabel, yLabel, chartNumbersType, populationValue]);

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent<GraphicsContainer, MouseEvent>) => {
      if (tooltipGroupId) tooltipGroupId.current = data.id;
      onHover(data, e);
      showTooltipFromEvent(heatmapTooltip, e);
    },
    [data, heatmapTooltip, onHover, showTooltipFromEvent, tooltipGroupId],
  );

  const handleMouseLeave = React.useCallback(
    (e: React.MouseEvent<GraphicsContainer, MouseEvent>) => {
      onLeave(data, e);
      if (tooltipGroupId) {
        tooltipGroupId.current = undefined; // remove hovered tooltip
        hideTooltip();
      }
    },
    [onLeave, data, hideTooltip, tooltipGroupId],
  );

  const handleTooltip = React.useCallback(
    (e: React.MouseEvent<GraphicsContainer, MouseEvent>) => showTooltipFromEvent(heatmapTooltip, e),
    [showTooltipFromEvent, heatmapTooltip],
  );

  const calculatedValue = React.useMemo(() => (!isTextOverflow ? `${getNumericValue(value)}%` : ''), [value, isTextOverflow]);
  const textColorUpdated = heatmapTheme.colorScale.slice(0, 2).includes(color) ? theme.colors.regularGrey.toString() : textColor;
  const cellHeight = React.useMemo(() => (height < MIN_CELL_HEIGHT ? MIN_CELL_HEIGHT : height), [height]);

  return (
    <CellContainer
      transform={`translate(${x}, ${y})`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleTooltip}
    >
      <rect
        x={-Math.round(width / 2)}
        y={-Math.round(cellHeight / 2)}
        width={width}
        height={cellHeight}
        fill={color}
        fillOpacity={opacity}
        strokeWidth={borderWidth}
        stroke={borderColor}
      ></rect>
      <text
        dominantBaseline='central'
        textAnchor='middle'
        style={{ fill: textColorUpdated, fontSize: 12, fontWeight: 600, cursor: 'default' }}
      >
        {chartNumbersType === ChartNumbersType.VALUES ? populationValue : calculatedValue}
      </text>
    </CellContainer>
  );
};

export default React.memo(CustomCell);
