import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Color from 'common/theme/color';
import { useFiltersOptions } from 'modules/charts/components/Filters/hooks';
import { FilterNames, IFilter, IFilterOptionGroup } from 'common/types/filters';
import { ChartFormData, ChartType, ChartViews } from 'common/types/chart';
import { FilterLabel, FilterOptions, FilterOption, Container, ColorIndicator, InfoButtonWrapper } from './styled';
import { getColorForData } from 'modules/charts/utils/colors';
import { useRecoilValue } from 'recoil';
import { DataTestId, FilterLabels } from 'settings/constants';
import { normalizeTitle } from 'utils/helpers';
import { brandsColorMapAtom } from 'common/atoms/brands';
import { sortDisplayOptions } from 'modules/charts/components/Filters/utils/displayOptions';
import { LatanaText } from 'common/components/LatanaText';
import { InfoBtn } from 'common/styledComponents/buttons';
import KpiQuestionModal from './KpiQuestionModal';
import { segmentsColorsMapAtom } from 'common/atoms/segments';

interface FilterOptionsProps {
  filter: IFilter;
  onHandleFilterClick: (filter: IFilter) => void;
  active: boolean;
  enabled: boolean;
  isCreate: boolean;
  chartFormData: ChartFormData;
}
interface MappedOption {
  label: string;
  value: string;
  color?: Color;
}

const FilterOptionsList: React.FC<FilterOptionsProps> = ({ filter, onHandleFilterClick, active, enabled, isCreate, chartFormData }) => {
  const [showKpiQuestionModal, toggleKpiQuestionModal] = React.useState(false);
  const { t } = useTranslation();
  const { watch } = useFormContext<ChartFormData>();
  const containerRef = React.useRef<HTMLDivElement>();
  const [hiddenCount, setHiddenCount] = React.useState(0);

  const values = watch(filter.id) as (string | number)[];

  const firstDimension = chartFormData.firstDimension;
  const secondDimension = chartFormData.secondDimension;
  const chartType = chartFormData.chart_type;
  const currentView = chartFormData.current_view;
  const studyId = chartFormData.study_uuid;

  const brandsColorMap = useRecoilValue(brandsColorMapAtom);
  const segmentsColorsMap = useRecoilValue(segmentsColorsMapAtom);

  const isLineView = React.useMemo(() => {
    return currentView === ChartViews.LINE;
  }, [currentView]);

  const getHiddenOptionsAmount = (optionsMap: Map<Element, boolean>): number => [...optionsMap.values()].filter(hidden => hidden).length;

  const countHiddenOptions = React.useCallback((entries: Array<IntersectionObserverEntry>) => {
    const visibilityMap = new Map<Element, boolean>();
    entries.forEach(entry => visibilityMap.set(entry.target, !entry.intersectionRatio));
    const hiddenOptionsAmount = getHiddenOptionsAmount(visibilityMap);
    setHiddenCount(hiddenOptionsAmount);
  }, []);

  React.useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const observer = new IntersectionObserver(entries => countHiddenOptions(entries), { threshold: 1, root: container });
      container.childNodes.forEach(node => observer.observe(node as Element));
      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, [values, countHiddenOptions]);

  const optionsMap = useFiltersOptions(filter.options);

  const displayOptions = React.useMemo(() => {
    const uniqueValues = Array.from(new Set(values));
    const options = Object.keys(optionsMap)
      .filter(value => uniqueValues.includes(value) || uniqueValues.includes(Number(value)))
      .map(value => optionsMap[value])
      .filter(Boolean);

    if (filter.id !== FilterNames.BRAND || !filter.options) {
      return options;
    }

    // If current filter is brand, sort chosen brand values in form in the same order as it is in filter.options
    const brandOptions = (filter.options as IFilterOptionGroup[])[0]?.options;
    return sortDisplayOptions(options, brandOptions);
  }, [optionsMap, values, filter]);

  const isValueMissing = React.useMemo(() => {
    return !isCreate && !displayOptions?.length;
  }, [displayOptions, isCreate]);

  const getOptionColor = React.useCallback(
    (id: string, index: number, label: string): false | string => {
      const isFirstDimension = firstDimension === id;
      const isSecondDimension = secondDimension === id;

      const isGroupedByBrand = firstDimension === FilterNames.BRAND;
      const isGroupedBySegment = firstDimension === FilterNames.SEGMENT;

      const isSecondDimensionBrand = secondDimension === FilterNames.BRAND;

      // Market funnel has only first dimension, so we should color only first dimension
      if (chartType === ChartType.MARKET_FUNNEL) {
        if (!isFirstDimension) return false;

        if (isGroupedBySegment) {
          return segmentsColorsMap?.[label] || false;
        }

        if (isGroupedByBrand) {
          return brandsColorMap?.[label] || false;
        }

        return getColorForData(index);
      }

      // If brand is second dimension and current filter is brand, return brand color
      if (isSecondDimensionBrand && isSecondDimension) {
        return brandsColorMap?.[label] || false;
      }

      // If brand is first dimension and current filter is brand, return brand color
      if (isGroupedByBrand && isFirstDimension) {
        return brandsColorMap?.[label] || false;
      }

      // If brand filter is first or second dimension, don't show color indicator for other filters
      if (isSecondDimensionBrand || isGroupedByBrand) return false;

      if (isLineView) {
        return isFirstDimension ? getColorForData(index) : false;
      }

      return isSecondDimension ? getColorForData(index) : false;
    },
    [firstDimension, isLineView, secondDimension, brandsColorMap, chartType, segmentsColorsMap],
  );

  const highlightedKpiOptions = React.useMemo(() => (filter.id === FilterNames.KPI ? (values as string[]) : []), [values, filter.id]);

  const handleFilterClick = (e: React.SyntheticEvent) => {
    const offsetParent = (e.target as HTMLDivElement).offsetParent as HTMLDivElement;
    if (offsetParent?.dataset?.['testid'] === DataTestId.MODAL_INNER) return;
    onHandleFilterClick(filter);
  };

  const handInfoBtnClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    toggleKpiQuestionModal(true);
  };

  return (
    <Container
      data-testid={DataTestId[`FILTER_${normalizeTitle(filter.label).toUpperCase()}` as 'FILTER_GEOGRAPHY']}
      aria-invalid={isValueMissing}
      aria-disabled={!enabled}
      aria-selected={active}
      key={filter.id}
      onClick={handleFilterClick}
    >
      <FilterLabel>
        <LatanaText variant='L1' color='gray900'>
          {filter.label}
        </LatanaText>
        {filter.label === FilterLabels.KPI && (
          <InfoButtonWrapper data-hover='KPI questions'>
            <InfoBtn onClick={handInfoBtnClick} margin={[0, 0, 0, 1]} />
          </InfoButtonWrapper>
        )}
      </FilterLabel>
      <FilterOptions data-counter={hiddenCount && `+${hiddenCount} more`} ref={containerRef as React.MutableRefObject<HTMLDivElement>}>
        {isValueMissing ? (
          <FilterOption color='wine' fontWeight='600' variant='body1'>
            {t('filters.missingValue')}
          </FilterOption>
        ) : (
          displayOptions?.map((option: MappedOption, index: number) => {
            const displayColor = getOptionColor(filter.id, index, option?.label);
            return (
              <FilterOption fontWeight='600' variant='body1' key={`list_option_${index}`}>
                {displayColor ? <ColorIndicator color={getOptionColor(filter.id, index, option?.label) as Color & string} /> : null}
                {option?.label}
              </FilterOption>
            );
          })
        )}
      </FilterOptions>
      {showKpiQuestionModal && (
        <KpiQuestionModal
          isOpen={showKpiQuestionModal}
          kpiList={highlightedKpiOptions}
          onClose={() => toggleKpiQuestionModal(false)}
          studyId={studyId}
        />
      )}
    </Container>
  );
};

export default React.memo(FilterOptionsList);
