import { CssMixBlendMode } from '@nivo/core';
import { theme } from 'common/theme';
import { Params } from 'common/types/chart';

export const chartTheme = {
  background: theme.colors.white.toString(),
  textColor: theme.colors.black.toString(),
  fontFamily: theme.text.fontFamily,
  labelFontSize: 12,
  fontSize: 14,
  fontWeight: 600,
  height: 400,
  heightPageRotatedLabels: 300,
  heightPage: 350,
  axis: {
    domain: {
      line: {
        strokeWidth: 0,
      },
    },
    ticks: {
      line: {
        stroke: theme.colors.lightGrey.toString(),
        strokeWidth: 1,
      },
      text: {
        fontSize: 12,
        fontWeight: 600,
      },
    },
    legend: {
      text: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    left: {
      padding: theme.spacing.unit * 3,
    },
    bottom: {
      padding: theme.spacing.unit * 2,
    },
  },
  grid: {
    line: {
      stroke: theme.colors.gray100.toString(),
      strokeWidth: 1,
    },
  },
  tooltip: {
    container: {
      background: theme.colors.black.toString(),
      padding: theme.spacing.unit * 2,
      borderRadius: '8px',
      color: theme.colors.white.toString(),
    },
  },
};

export const radarChartParams = (inPage: boolean): Params => {
  return {
    legends: [
      {
        anchor: 'top-left' as 'bottom' | 'left' | 'right' | 'top' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left' | 'center',
        direction: 'column' as 'column' | 'row',
        translateX: inPage ? -80 : -40,
        translateY: inPage ? -50 : -70,
        itemWidth: 80,
        itemHeight: 20,
        itemTextColor: '#999',
        symbolSize: inPage ? 6 : 12,
        symbolShape: 'square' as 'circle' | 'diamond' | 'square' | 'triangle',
        effects: [
          {
            on: 'hover' as const,
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ],
    margin: inPage ? { top: 48, right: 80, bottom: 48, left: 80 } : { top: 70, right: 80, bottom: 40, left: 80 },
    labelOffset: inPage ? 16 : 36,
    fillOpacity: 0.3,
    gridShape: 'circular' as (string & 'circular') | 'linear',
    indexBy: 'kpi',
    borderColor: 'color',
    borderWidth: 1,
    dotSize: 7,
    dotColor: 'color',
    dotBorderWidth: 1,
    blendMode: 'darken' as CssMixBlendMode,
    motionConfig: 'wobbly',
  };
};

export const heatmapTheme = {
  background: theme.colors.white.toString(),
  textColor: theme.colors.white.toString(),
  fontFamily: theme.text.fontFamily,
  labelFontSize: 12,
  fontSize: 12,
  fontWeight: 600,
  height: 400,
  axis: {
    domain: {
      line: {
        stroke: theme.colors.lightGrey.toString(),
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: theme.colors.lightGrey.toString(),
        strokeWidth: 1,
      },
      text: {
        fontSize: 12,
        fontWeight: 600,
        fill: theme.colors.regularGrey.toString(),
      },
    },
    legend: {
      text: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    left: {
      padding: theme.spacing.unit * 3,
    },
    bottom: {
      padding: theme.spacing.unit * 2,
    },
  },
  grid: {
    line: {
      stroke: theme.colors.lightGrey.toString(),
      strokeWidth: 1,
    },
  },
  tooltip: {
    container: {
      background: theme.colors.black.toString(),
      color: theme.colors.white.toString(),
      fontSize: '18px',
      borderRadius: '8px',
      padding: '18px',
    },
    basic: {
      display: 'flex',
      alignItems: 'center',
    },
    table: {},
    tableCell: {
      padding: '3px 5px',
    },
  },
  colorScale: [
    'hsl(211, 100%, 95%)',
    'hsl(212, 100%, 90%)',
    'hsl(211, 100%, 80%)',
    'hsl(211, 100%, 70%)',
    'hsl(211, 100%, 60%)',
    'hsl(211, 100%, 50%)',
    'hsl(211, 100%, 45%)',
  ],
};
