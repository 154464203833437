import React from 'react';
import { CheckboxInput, IconWrapper, SelectItemContainer, SelectItemSeparator } from './styled';
import { LatanaText } from 'common/components/LatanaText';
import Icon from 'common/components/Icon';
import Tooltip from 'common/components/Tooltip';
import { BlurbPopup } from 'common/styledComponents/modals';
import { SelectPopupItemLabelState, SelectPopupItemIconState } from 'common/types/selectMenu';
import { ActionItems } from 'settings/constants';

interface Props {
  value: string;
  label: SelectPopupItemLabelState | string;
  onSelect: (value: string, checked: boolean) => void;
  checked?: boolean;
  className?: string;
  icon?: SelectPopupItemIconState | string;
  isCheckbox?: boolean;
  isDisabled?: boolean;
  blurb?: string;
  action?: (eventName?: string) => void;
  index: number;
  itemCount: number;
}

const SelectItem = ({
  label,
  value,
  checked = false,
  onSelect,
  className,
  icon,
  isCheckbox,
  isDisabled = false,
  blurb,
  action,
  index,
  itemCount,
}: Props) => {
  const onCheckCallback = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      if (!isDisabled) {
        action && action(); // Execute callbacks
        onSelect(value, isCheckbox ? !checked : true); // If it is single select, just return true
      }
    },
    [checked, onSelect, value, isCheckbox, isDisabled, action],
  );

  // Separator should not render at the top and at the bottom of the list
  if (value === ActionItems.SEPARATOR) {
    return [0, itemCount - 1].includes(index) ? <></> : <SelectItemSeparator />;
  }

  return (
    <Tooltip renderInBody enabled={isDisabled} leftOffset={120} content={<BlurbPopup content={blurb} />}>
      <SelectItemContainer
        className={className}
        isLastItem={index === itemCount - 1}
        onClick={onCheckCallback}
        data-is-disabled={isDisabled}
      >
        {isCheckbox && <CheckboxInput value={value} type='checkbox' checked={checked} readOnly />}
        {icon && !isCheckbox && (
          <IconWrapper>
            <Icon
              icon={(icon as SelectPopupItemIconState)?.name || icon}
              color={
                isDisabled
                  ? (icon as SelectPopupItemIconState)?.color?.disabled
                  : (icon as SelectPopupItemIconState)?.color?.active || 'gray500'
              }
            />
          </IconWrapper>
        )}
        <LatanaText
          variant='L1'
          color={
            isDisabled
              ? (label as SelectPopupItemLabelState)?.color?.disabled
              : (label as SelectPopupItemLabelState)?.color?.active || 'gray500'
          }
        >
          {(label as SelectPopupItemLabelState)?.name || label}
        </LatanaText>
      </SelectItemContainer>
    </Tooltip>
  );
};

export default React.memo(SelectItem);
