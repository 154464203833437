import { useRollbar } from '@rollbar/react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { CountryPayload } from 'common/types/common';
import { useRecoilValue } from 'recoil';
import { LogArgument } from 'rollbar';
import { API_URLS } from 'settings/api';

export const useGetAllCountries = (): UseQueryResult<Array<CountryPayload>, unknown> => {
  const { fetchGet } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();
  return useQuery(
    ['allCountries'],
    async () => {
      try {
        return await fetchGet<CountryPayload>(API_URLS.COUNTRIES);
      } catch (e: unknown) {
        rollbar.info('useGetAllCountries error', e as LogArgument);
      }
    },
    {
      staleTime: Infinity,
    },
  );
};
