import { ChartType } from 'common/types/chart';
import { FilterNames } from 'common/types/filters';
import { BaseFilterService } from '.';

interface BrandFilterService {
  onShowFilter: (filterId: FilterNames) => void;
  isFilterDisabled: (filterId: FilterNames) => boolean;
  onFilterApply?: () => void;
}

export const BrandFilterService: BrandFilterService = {
  onShowFilter(filterId) {
    const shouldDisableBrandSelection = BaseFilterService.chartType === ChartType.MARKET_SIZE;
    // [Brand] tab should be temporary locked for these chart types.
    if (shouldDisableBrandSelection && filterId === FilterNames.BRAND) return;
  },
  isFilterDisabled(filterId) {
    // [Brand] tab should be temporary locked for these chart types.
    if (BaseFilterService.chartType === ChartType.MARKET_SIZE && filterId === FilterNames.BRAND) return true;
    return false;
  },
};
