import styled from '@emotion/styled';
import { useTooltipContext } from 'modules/charts/context/TooltipContext';
import * as React from 'react';
import ReactDOM from 'react-dom';

const Container = styled.div<Position>`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 3;
  left: ${props => props.x + 60}px;
  top: ${props => props.y}px;
  transform: translate(-50%, -100%);
  max-height: 480px;
  overflow: hidden;
`;

interface Position {
  x: number;
  y: number;
}

interface Props extends Position {
  children: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

// component will render the tooltip inside a React.Portal
// to avoid overflow inside the scrollable container used for charts
const TooltipRenderer: React.FC<Props> = ({ children, x, y, onMouseEnter, onMouseLeave }) => {
  const containerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  // check if tooltip overflows on top of window
  React.useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return undefined;
    }

    const containerRect = container.getBoundingClientRect();

    if (containerRect.y < 0) {
      container.style.top = `${y + Math.abs(containerRect.y)}px`;
    }
    return () => {
      if (container) {
        container.style.top = '';
      }
    };
  }, [y, containerRef]);

  const handleMouseLeave = React.useCallback(() => {
    onMouseLeave && onMouseLeave();
  }, [onMouseLeave]);

  const handleMouseEnter = React.useCallback(() => {
    onMouseEnter && onMouseEnter();
  }, [onMouseEnter]);

  const { container, tooltipScrollOffset } = useTooltipContext();

  const scrollOffset = tooltipScrollOffset?.current || 0; // chart scrolled offset
  const containedWidth = Math.max((container?.clientWidth || 0) - 210, 0);
  const useX = Math.max(Math.min(x - scrollOffset, containedWidth), 140); // bound X position by chart offset and width

  return ReactDOM.createPortal(
    <Container ref={containerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} y={y} x={useX}>
      {children}
    </Container>,
    container as HTMLDivElement,
  );
};

export default React.memo(TooltipRenderer);
