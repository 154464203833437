export enum CustomVariableValues {
  Yes = 'yes',
  No = 'no',
}

export enum SegmentTabNames {
  LATANA = 'latana',
  CUSTOM = 'custom',
}

export enum FormStates {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
  DELETE = 'delete',
}

export enum ActionTypes {
  SET_READ = 'SET_READ',
  SET_WRITE = 'SET_WRITE',
  SET_CREATE = 'SET_CREATE',
  SET_DELETE = 'SET_DELETE',
}

export type SegmentContextType = {
  setPreviousUrlPath: React.Dispatch<React.SetStateAction<string | null>>;
  setStudyIdRedirect: React.Dispatch<React.SetStateAction<string | null>>;
};

export interface Segment {
  id: number;
  name: string;
  standard_characteristics: Characteristic[];
  extra_characteristics: ExtraCharacteristic[];
  created_at: string;
  updated_at: string | null;
  country_code?: string | null;
  last_edited_by_name: string | null;
  color?: string;
  study_uuid: string;
  readable_only?: boolean;
  general_population?: boolean;
}

export interface Segments {
  study_uuid: string;
  segments: Segment[];
}

export interface SegmentGroups {
  name: string;
  segments: Segment[];
}

export interface CharacteristicOption {
  key: string;
  name: string;
  country_codes: Array<string>;
  global?: boolean;
}

export interface CharacteristicRange {
  available_from: string;
  available_to: string;
}

export interface Characteristic {
  key: string;
  name: string;
  options: CharacteristicOption[];
  enabled: boolean;
  date_ranges?: CharacteristicRange[];
  values?: CustomVariableValues[];
}

export interface ExtraCharacteristic extends Characteristic {
  date_ranges: {
    available_from: string;
    available_to: string;
  }[];
}

export interface Characteristics {
  standard_characteristics: Characteristic[];
  extra_characteristics: ExtraCharacteristic[];
}

export type SegmentColorMapType = Record<string, string>;
