import * as React from 'react';
import { API_URLS } from 'settings/api';
import { UpdatePassword, UpdateUserRequestData, UserDetailsResponse, UserInvite } from 'common/types/account';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { accountDetailsAtom, accountDetailsSelector } from 'common/atoms/account';
import { fetchSelector } from 'common/atoms/common';
import { clearToken, getAuthToken, getRefreshToken, fetchRefreshToken } from 'utils/auth';
import { NewPasswordFormData } from 'views/Invite/PasswordForm';
import { useTranslation } from 'react-i18next';
import { useTracking } from './tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { Dispatch, SetStateAction } from 'react';
import { AcceptInvitationResponse } from 'common/types/login';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';

export const useRefetchAccountDetails = (): { refetchAccountDetails: (newUserData?: UserDetailsResponse | undefined) => Promise<void> } => {
  const rollbar = useRollbar();
  const refetchAccountDetails = useRecoilCallback(({ set, snapshot }) => async (newUserData?: UserDetailsResponse) => {
    const { features } = await snapshot.getPromise(accountDetailsSelector);
    const { fetchGet } = await snapshot.getPromise(fetchSelector);

    if (newUserData) {
      set(accountDetailsAtom, {
        ...newUserData,
        features,
      });
    } else {
      try {
        const accountDetails = await fetchGet<UserDetailsResponse>(API_URLS.PROFILE);
        set(accountDetailsAtom, {
          ...accountDetails,
          features,
        });
      } catch (e: unknown) {
        rollbar.error('useRefetchAccountDetails error', e as LogArgument);
      }
    }
  });

  return React.useMemo(() => {
    return {
      refetchAccountDetails,
    };
  }, [refetchAccountDetails]);
};

export const useUpdateAccountDetails = (): {
  loading: boolean;
  updateAccountDetails: (accountDetails: UpdateUserRequestData) => Promise<void>;
} => {
  // Note: might need a error state as well.
  const rollbar = useRollbar();
  const [loading, setLoading] = React.useState(false);
  const { refetchAccountDetails } = useRefetchAccountDetails();

  const updateAccountDetails = useRecoilCallback(
    ({ snapshot }) =>
      async (accountDetails: UpdateUserRequestData) => {
        const { fetchPatch } = await snapshot.getPromise(fetchSelector);
        const { name } = accountDetails;
        setLoading(true);

        try {
          const data = await fetchPatch<UserDetailsResponse>(API_URLS.PROFILE, {
            profile: { name },
          });
          refetchAccountDetails(data);
        } catch (e: unknown) {
          rollbar.error('useUpdateAccountDetails error', e as LogArgument);
        } finally {
          setLoading(false);
        }
      },
    [refetchAccountDetails],
  );

  return React.useMemo(
    () => ({
      loading,
      updateAccountDetails,
    }),
    [loading, updateAccountDetails],
  );
};

export const useUpdateEmailNotificationSettings = (): {
  updateEmailNotification: (emailNotification: boolean) => Promise<void>;
} => {
  const { refetchAccountDetails } = useRefetchAccountDetails();
  const rollbar = useRollbar();
  const updateEmailNotification = useRecoilCallback(
    ({ snapshot }) =>
      async (emailNotification: boolean) => {
        const { fetchPatch } = await snapshot.getPromise(fetchSelector);

        try {
          const data = await fetchPatch<UserDetailsResponse>(API_URLS.PROFILE, {
            profile: {
              notify_about_data_emails: emailNotification,
            },
          });
          refetchAccountDetails(data);
        } catch (e: unknown) {
          rollbar.error('Failed to update email notification', e as LogArgument);
        }
      },
    [refetchAccountDetails],
  );

  return React.useMemo(
    () => ({
      updateEmailNotification,
    }),
    [updateEmailNotification],
  );
};

export const useValidateToken = (): boolean => {
  return React.useMemo(() => {
    const token = getAuthToken();
    const refreshToken = getRefreshToken();
    // in case token is expired but there is a valid refreshToken,
    // trigger Suspense while token is refreshed
    if (token === undefined && refreshToken) {
      throw new Promise<void>(resolve => {
        fetchRefreshToken(refreshToken)
          .then(resolve)
          .catch(() => {
            clearToken();
            resolve();
          });
      });
    }

    return Boolean(token);
  }, []);
};

export const useNewPassword = (token: string | undefined): UpdatePassword => {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string>();
  const [message, setMessage] = React.useState<string>();
  const [busy, setBusy] = React.useState<boolean>(false);

  const updatePassword = React.useCallback(
    ({ password }: NewPasswordFormData) => {
      setError(undefined);
      setBusy(true);
      setMessage(undefined);

      fetch(API_URLS.PASSWORD_UPDATE(token), {
        method: 'PUT',
        body: JSON.stringify({
          user: {
            password,
            password_confirmation: password,
          },
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resp => {
          if (resp.ok) {
            return resp.json();
          } else {
            return Promise.reject();
          }
        })
        .then(() => {
          setMessage(t('login.success.newPassword'));
          setBusy(false);
        })
        .catch(() => {
          setError(t('login.errors.newPassword'));
          setBusy(false);
        });
    },
    [token, t],
  );

  return { updatePassword, error, message, busy };
};

export const usePasswordReset = (
  onResetMessage?: Dispatch<SetStateAction<string | undefined>>,
  onSetError?: () => void,
): ((email: string) => Promise<void>) => {
  const rollbar = useRollbar();
  const { trackEvent } = useTracking();
  const { fetchPost } = useRecoilValue(fetchSelector);

  const resetPassword = React.useCallback(
    async (email: string) => {
      try {
        const result = (await fetchPost(API_URLS.PASSWORD_RESET, { email })) as Record<string, string>;
        onResetMessage ? onResetMessage(result.notice) : alert(result.notice);
        trackEvent(TRACKING_EVENTS.RESET_PASSWORD);
      } catch (error: unknown) {
        rollbar.error('usePasswordReset error', error as LogArgument);
        onSetError && onSetError();
      }
    },
    [fetchPost, onResetMessage, onSetError, trackEvent, rollbar],
  );

  return resetPassword;
};

export const useInvite = (
  token: string | undefined,
  onHandleUserLogin: (email: string, password: string) => void,
  onSetError: React.Dispatch<React.SetStateAction<string | undefined>>,
): UserInvite => {
  const { t } = useTranslation();
  const [message, setMessage] = React.useState<string>();
  const [busy, setBusy] = React.useState<boolean>(false);
  const { fetchPost } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();

  const setNewPassword = React.useCallback(
    async ({ password }: { password: string }) => {
      onSetError(undefined);
      setBusy(true);
      setMessage(undefined);

      try {
        const response = await fetchPost<AcceptInvitationResponse>(API_URLS.ACCEPT_INVITE, { password, invitation_token: token });
        onHandleUserLogin(response.email, password);
        setBusy(false);
      } catch (e: unknown) {
        const error = e as Error & { errors: string };
        onSetError(error?.errors ? error.errors : t('login.errors.newPassword'));
        setBusy(false);
        rollbar.error('useInvite error', e as LogArgument);
      }
    },
    [token, t, fetchPost, onHandleUserLogin, onSetError, rollbar],
  );

  return { setNewPassword, message, busy };
};
