import styled from '@emotion/styled';
import { theme } from 'common/theme';
import * as React from 'react';

const Container = styled.div`
  display: inline-block;
  margin: ${theme.space(0, 1, 0, 0)};
  width: 18px;
  height: 18px;
`;

const FakeRadio = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${theme.colors.borderGrey.toString()};
  border-radius: 50%;
  position: relative;
`;

const HiddenInput = styled.input`
  display: none;
  &:checked + ${FakeRadio} {
    background: ${theme.colors.lightPurple.toString()};
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background: #fff;
      border-radius: 50%;
    }
  }
`;

interface Props {
  name: string;
  value: string;
  checked?: boolean;
  ref?: React.ForwardedRef<unknown>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line react/display-name
const Radio: React.FC<Props> = React.forwardRef((props, ref) => {
  return (
    <Container>
      <HiddenInput type='radio' {...props} ref={ref as React.LegacyRef<HTMLInputElement> | undefined} />
      <FakeRadio />
    </Container>
  );
});

export default React.memo(Radio);
