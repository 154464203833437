import React from 'react';
import { useTranslation } from 'react-i18next';
import { FunnelTooltiop } from 'common/types/chart';
import { displayValue, roundValue } from 'utils/chart';
import { TooltipItem, LabelContainer, ValueContainer, MoeContainer } from './styled';
import { isNotUndefined } from 'utils/helpers';
import { KPILabel } from 'common/types/common';
import { UNDEFINED_TYPE } from 'settings/constants';

interface Props {
  tooltip: FunnelTooltiop;
  decimalsEnabled: boolean;
  moeEnabled: boolean | undefined;
}

const PERCENTAGE = '%';

export const FunnelTooltip: React.FC<Props> = React.memo(({ tooltip, decimalsEnabled, moeEnabled }) => {
  const { t } = useTranslation();
  return (
    <>
      <TooltipItem>
        <LabelContainer>
          {tooltip.kpiLabel === KPILabel.AIDED_BRAND_AWARENESS ? (
            <>
              <>{`${tooltip.kpiLabel}`}</>&nbsp;
              {`(${t('chart.tooltip.absoluteValue')})`}
            </>
          ) : (
            tooltip.kpiLabel
          )}
        </LabelContainer>
        <ValueContainer>
          {displayValue(roundValue(tooltip.value * 100, decimalsEnabled), decimalsEnabled)} <span>{PERCENTAGE}</span>
          {moeEnabled && isNotUndefined(tooltip.margin) && (
            <MoeContainer>&plusmn;{roundValue(tooltip.margin ?? 0, decimalsEnabled)}</MoeContainer>
          )}
        </ValueContainer>
      </TooltipItem>
      {tooltip.refValue !== null ? (
        <>
          {tooltip.absolute ? (
            <TooltipItem>
              <LabelContainer>{t('chart.tooltip.absoluteValue')}</LabelContainer>
              <ValueContainer>
                {displayValue(roundValue(tooltip.absolute * 100, decimalsEnabled), decimalsEnabled)} <span>{PERCENTAGE}</span>
                {moeEnabled && typeof tooltip.margin !== UNDEFINED_TYPE ? <MoeContainer /> : null}
              </ValueContainer>
            </TooltipItem>
          ) : null}
        </>
      ) : null}
    </>
  );
});

FunnelTooltip.displayName = 'FunnelTooltip';
