import React from 'react';
import { Text } from 'common/styledComponents/typography';
import { ArrowBack, ArrowBackBlack, QuestionBlack, QuestionWhite } from 'icons';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button as DefaultButton } from 'common/components/Form/Button';
import { theme } from 'common/theme';
import { ICONS } from 'icons/iconsMap';
import Icon from 'common/components/Icon';
import { LatanaText } from 'common/components/LatanaText';
import { tooltipOnHover } from 'common/styledComponents/modals';
import { useNavigate } from 'react-router-dom';
import { Theme } from '@emotion/react';

interface BackButtonProps {
  backText?: string;
  invert?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const BackButtonContainer = styled.div`
  position: relative;
`;

export const BackButtonText = styled(Text)`
  cursor: pointer;
  margin: ${({ theme }) => theme.space(0, 0, 2)};
  display: inline-block;
`;

const Button = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`;

export const ArrowBackIcon = styled.div<{ invert: boolean | undefined }>`
  background: url('${props => (props.invert ? ArrowBack : ArrowBackBlack)}') no-repeat center center;
  border: none;
  width: 18px;
  height: 12px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  margin-left: ${({ theme }) => -theme.spacing.unit * 3}px;
  position: absolute;
  margin-top: 2px;
`;

export const InfoBtn = styled.div<{ margin?: number[] }>`
  background: url('${QuestionWhite}') no-repeat center center;
  border: none;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  background-size: contain;
  margin: ${({ theme, margin }) => theme.v2.space(...(margin ? margin : [0, 0, 0.25, 1]))};

  &:hover {
    background: url('${QuestionBlack}') no-repeat center center;
    background-size: contain;
  }
`;

export const BackButton: React.FC<BackButtonProps> = ({ backText, invert, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onBack = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      } else {
        navigate(-1);
      }
    },
    [onClick, navigate],
  );

  return (
    <BackButtonContainer>
      <Button onClick={onBack}>
        <ArrowBackIcon invert={invert} />
        <BackButtonText variant='body2' color={invert ? 'white' : 'black'} marginBottom={false}>
          {backText ? backText : t('common.back')}
        </BackButtonText>
      </Button>
    </BackButtonContainer>
  );
};

export const ActionButton = styled(DefaultButton)`
  margin: ${theme.space(0, 0, 0, 1)};
  padding: ${({ theme }) => theme.space(1, 2)};
  border-radius: ${({ theme }) => theme.space(0.5)};
  height: ${({ theme }) => theme.space(4.5)};
  min-width: 77px;
  line-height: 0;
  font-weight: ${({ theme }) => theme.text.variants.small.fontWeight};
  &:disabled {
    color: ${({ theme }) => theme.colors.foggy.toString()};
    cursor: default;
  }
`;

export const CancelButton = styled(ActionButton)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.jupiter.toString()};
  margin: ${theme.space(0, 1, 0, 0)};
  &:hover:not(:disabled):not([aria-selected='true']) {
    background: transparent;
    color: ${({ theme }) => theme.colors.black.toString()};
  }
`;

export const LabelButtonWithIconContainer = styled.div<{ iconPosition?: IconPosition }>`
  display: inline-flex;
  padding: ${({ theme }) => theme.v2.space(1)};
  align-items: center;
  border-radius: ${({ theme }) => theme.v2.borderRadius}px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.v2.colours.gray100};
  }

  > svg {
    ${({ theme, iconPosition }) => iconPosition === IconPosition.LEFT && `margin-right: ${theme.v2.space(1)};`}
    ${({ theme, iconPosition }) => iconPosition === IconPosition.RIGHT && `margin-left: ${theme.v2.space(1)};`}
  }

  &[aria-disabled='true'] {
    &:hover {
      background: none;
    }
  }

  &[aria-readonly='true'] {
    &:hover {
      background: none;
    }

    cursor: default;
  }

  &[data-hover] {
    ${tooltipOnHover('bottom')}
  }
`;

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

interface LabelButtonWithIconProps {
  label: string;
  disabled?: boolean;
  readOnly?: boolean;
  'data-hover'?: string;
  'data-testid'?: string;
  icon: keyof typeof ICONS;
  iconPosition?: IconPosition;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  textColorActive?: keyof Theme['v2']['colours'] | 'inherit';
  textColorInactive?: keyof Theme['v2']['colours'] | 'inherit';
  iconColorActive?: keyof Theme['v2']['colours'] | 'inherit';
  iconColorInactive?: keyof Theme['v2']['colours'] | 'inherit';
}

export const LabelButtonWithIcon = ({
  label,
  icon,
  onClick,
  disabled,
  readOnly,
  'data-testid': dataTestid,
  'data-hover': dataHover,
  iconPosition = IconPosition.LEFT,
  iconColorActive = 'gray500',
  iconColorInactive = 'gray400',
  textColorActive = 'gray900',
  textColorInactive = 'gray400',
}: LabelButtonWithIconProps) => {
  return (
    <LabelButtonWithIconContainer
      role='button'
      aria-disabled={disabled}
      aria-readonly={readOnly}
      data-testid={dataTestid}
      iconPosition={iconPosition}
      onClick={onClick ? onClick : undefined}
      {...(dataHover && { 'data-hover': dataHover })}
    >
      {iconPosition === IconPosition.LEFT && <Icon icon={icon} color={disabled ? iconColorInactive : iconColorActive} size={14} />}
      <LatanaText variant='L1' color={disabled ? textColorInactive : textColorActive}>
        {label}
      </LatanaText>
      {iconPosition === IconPosition.RIGHT && <Icon icon={icon} color={disabled ? iconColorInactive : iconColorActive} size={14} />}
    </LabelButtonWithIconContainer>
  );
};

export const IconButtonContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  ${tooltipOnHover('bottom')}
`;
