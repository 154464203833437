export const markdownParser = (text: string) => {
  const escapedText = text
    .replace(/&nbsp;/g, ' ')
    .replace(/&/g, '&amp;') // replace & with &amp;
    .replace(/</g, '&lt;') // replace < with &lt;
    .replace(/>/g, '&gt;'); // replace > with &gt;

  const toHTML = escapedText
    .replace(/\*\*(.*?[^\s])\*\*/gim, '<b>$1</b>') // bold text, allowing spaces
    .replace(/\*(.*?[^\s])\*/gim, '<i>$1</i>') // italic text, allowing spaces
    .replace(/<u>(.*?)<\/u>/gim, '<u>$1</u>') // underline text, excluding <u> and </u> tags
    .replace(/&lt;u&gt;/g, '<u>') // reverse &lt;u&gt; to <u>
    .replace(/&lt;\/u&gt;/g, '</u>'); // reverse &lt;/u&gt; to </u>

  return toHTML.trim();
};
