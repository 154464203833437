import * as React from 'react';
import { useRecoilState } from 'recoil';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from 'common/styledComponents/typography';
import { DynamicFilters, FilterNames, IFilter } from 'common/types/filters';
import { ChartFormData, ChartType } from 'common/types/chart';
import { FILTER_NAMES, ChartDetails, AVERAGE_VALUE } from 'settings/constants';
import FilterModalHeader from 'modules/charts/components/Filters/FilterModal/FilterModalHeader';
import FilterModalSection from 'modules/charts/components/Filters/FilterModal/FilterModalSection';
import FilterModalFooter from 'modules/charts/components/Filters/FilterModal/FilterModalFooter';
import { ModalContainer, ModalContent, ExpandingContainer, BannerContainer } from 'modules/charts/components/Filters/FilterModal/styled';
import { NewCloseIcon } from 'icons';
import { allWavesStateAtom } from 'common/atoms/waves';
import { useIsChartType } from 'common/hooks/useIsChartType';
import { BaseFilterService } from '../services';
import { ChartValue } from 'common/types/charts';
import { useIsReferenceAverageEnabled } from 'common/hooks/features';

interface Props {
  activeFilter: FilterNames | undefined;
  filters: IFilter[];
  availableOptions: Record<string, (string | number)[] | undefined>;
  onNext: React.MouseEventHandler<HTMLButtonElement> | undefined | void;
  nextEnabled?: boolean;
  setChartValue: (chartField: string, values: ChartValue) => void;
  availableFiltersLoading: boolean;
  isCreate: boolean;
  canSubmit: boolean;
  isChartValuesChanged: boolean;
  onFilterCancel: () => void;
  onFilterApply: () => void;
  chartType: ChartType | string;
  onHandleSelectAll: (filterId: FilterNames, values: (string | number)[]) => void;
  values: ChartFormData;
  dynamicFilter: DynamicFilters | null;
  onHandleDynoFilterSelect: (dynamicFilter: DynamicFilters) => void;
}

enum ContainerOpacity {
  Opaque = '1',
  Transparent = '0',
}

enum OptionsLimit {
  ONE = 1,
  TWO = 2,
}

type FormFields = Record<string, (number | string)[]>;

const TEMP_MARKET_FUNNEL_OPTIONS_LIMIT = 6;

const FilterModal: React.FC<Props> = ({
  activeFilter,
  onNext,
  nextEnabled,
  filters,
  availableOptions,
  setChartValue,
  availableFiltersLoading,
  isCreate,
  canSubmit,
  isChartValuesChanged,
  onFilterCancel,
  onFilterApply,
  chartType,
  onHandleSelectAll,
  values,
  dynamicFilter,
  onHandleDynoFilterSelect,
}) => {
  const { t } = useTranslation();
  const filterModalRef = React.useRef<HTMLDivElement>();
  const { register, watch } = useFormContext<ChartFormData>();
  const [optionsLimitBannerState, setOptionsLimitBannerState] = React.useState<boolean>(true);
  const [mfLimitWarning, setMFLimitWarning] = React.useState<boolean>(true);
  const isAverageFeatureEnabled = useIsReferenceAverageEnabled(values.study_uuid);
  const [allWavesState] = useRecoilState(allWavesStateAtom);
  const { isChartType } = useIsChartType(chartType);

  const isAverageToggleEnabled = !!values.display_reference_average;

  const setContainerOpacity = React.useCallback(() => {
    if (!filterModalRef.current) return;
    const opacity = activeFilter ? ContainerOpacity.Opaque : ContainerOpacity.Transparent;
    filterModalRef.current.style.opacity = opacity;
  }, [activeFilter]);

  React.useEffect(() => {
    setContainerOpacity();
  }, [activeFilter, setContainerOpacity]);

  const activeFilterDetails = React.useMemo(() => {
    return activeFilter && filters.find(({ id }) => id === activeFilter);
  }, [filters, activeFilter]);

  React.useEffect(() => {
    Object.values(ChartDetails).forEach(name => register({ name }));
  });

  const getAvailableOptions = React.useCallback((filter: IFilter) => {
    return BaseFilterService.filterModal.getAvailableOptions(filter);
  }, []);

  const isLastFilter = BaseFilterService.isFilterType(FilterNames.SEGMENT);

  const watchFields = watch([...FILTER_NAMES]);

  const calculateOptionsLimit = React.useCallback(
    (fields: FormFields, limit: number) => {
      if (!Object.keys(fields).length) return false;

      const multiValueFilters = [];
      for (const field in fields) {
        if ((fields as unknown as FilterNames)[field as unknown as number].length > 1) {
          multiValueFilters.push(field);
        }
      }

      return !(multiValueFilters.length < limit || multiValueFilters.includes(activeFilter as string));
    },
    [activeFilter],
  );

  const isLimitExceeded: boolean = React.useMemo(() => {
    if (isChartType(ChartType.GROWTH_PERFORMANCE)) {
      const { wave: _ignoredField, ...otherFields } = watchFields;
      return calculateOptionsLimit(otherFields, OptionsLimit.ONE);
    } else if (isChartType(ChartType.RANKING)) {
      return calculateOptionsLimit(watchFields, OptionsLimit.ONE);
    } else if (isChartType(ChartType.BRAND_PERCEPTION) || isChartType(ChartType.MARKET_FUNNEL)) {
      const { wave: _ignoredWaves, kpi: _ignoredKPIs, ...otherFields } = watchFields;
      return calculateOptionsLimit(otherFields, OptionsLimit.ONE);
    } else if (isChartType(ChartType.MATRIX)) {
      const { wave: _ignoredField, ...otherFields } = watchFields;
      return calculateOptionsLimit(otherFields, OptionsLimit.TWO);
    } else {
      return calculateOptionsLimit(watchFields, OptionsLimit.TWO);
    }
  }, [calculateOptionsLimit, isChartType, watchFields]);

  const isAllOptionsLimit: boolean = React.useMemo(() => {
    // ignore [wave] & [kpi] for [isAllOptionsLimit] calculation.
    if (isChartType(ChartType.BRAND_PERCEPTION) || isChartType(ChartType.MARKET_FUNNEL)) {
      const { wave: _ignoredWaves, kpi: _ignoredKPIs, ...otherFields } = watchFields;
      return calculateOptionsLimit(otherFields, OptionsLimit.ONE);
    }

    // ignore [wave] for [isAllOptionsLimit] calculation.
    const { wave: _ignoredField, ...otherFields } = watchFields;
    return calculateOptionsLimit(otherFields, OptionsLimit.ONE);
  }, [calculateOptionsLimit, watchFields, isChartType]);

  const displayAddSegment = BaseFilterService.isFilterType(FilterNames.SEGMENT);

  const isShowAverageToggle =
    isAverageFeatureEnabled &&
    BaseFilterService.isFilterType(FilterNames.BRAND) &&
    !isChartType(ChartType.GROWTH_PERFORMANCE) &&
    !isChartType(ChartType.BRAND_PERCEPTION) &&
    !isChartType(ChartType.MARKET_FUNNEL) &&
    !isChartType(ChartType.RANKING) &&
    !isChartType(ChartType.MATRIX) &&
    !isChartType(ChartType.FUNNEL);

  const getBrandValues = React.useCallback(
    (isOptionsLimitExceeded: boolean, isAllOptionsLimit: boolean, allWavesState: boolean, brands: Array<string | number>) => {
      return isOptionsLimitExceeded || (isAllOptionsLimit && allWavesState) ? [AVERAGE_VALUE] : [...brands, AVERAGE_VALUE];
    },
    [],
  );

  const handleAverageChange = React.useCallback(() => {
    setChartValue(ChartDetails.DISPLAY_REFERENCE_AVERAGE, !isAverageToggleEnabled);

    if ((isChartType(ChartType.RADAR) || isChartType(ChartType.HEATMAP)) && !isAverageToggleEnabled) {
      const brands = getBrandValues(isLimitExceeded, isAllOptionsLimit, allWavesState, watchFields.brand);
      setChartValue(ChartDetails.BRAND, brands);
    } else if ((isChartType(ChartType.RADAR) || isChartType(ChartType.HEATMAP)) && isAverageToggleEnabled) {
      const brands = watchFields.brand.filter(brand => brand !== AVERAGE_VALUE);
      setChartValue(ChartDetails.BRAND, brands);
    }
  }, [
    setChartValue,
    isAverageToggleEnabled,
    isChartType,
    getBrandValues,
    isLimitExceeded,
    isAllOptionsLimit,
    allWavesState,
    watchFields.brand,
  ]);

  /**
    https://latana.atlassian.net/jira/software/c/projects/APP/boards/28?selectedIssue=APP-430
    GP & BP charts have pre-selected filters (Time Period and KPIs accordingly) which are not user-changeable
    those filters should not be considered for the options limit. Therefore, the options limit that we must show
    is the same as for the Ranking chart - 1 multi-value filter per chart.
   */
  const optionLimitsStrings = React.useMemo(() => {
    switch (chartType) {
      case ChartType.BRAND_PERCEPTION:
      case ChartType.GROWTH_PERFORMANCE:
      case ChartType.RANKING:
        return { amount: 'one', section: 'section' };
      default:
        return { amount: 'two', section: 'sections' };
    }
  }, [chartType]);

  const hasActiveOptions = activeFilter && availableOptions[activeFilter]?.length;
  const numberOfActiveOptions = hasActiveOptions ?? 0;
  const isSingleOptionFilter = numberOfActiveOptions === 1;
  const shouldShowBanner = optionsLimitBannerState && isLimitExceeded && !isSingleOptionFilter;
  const showKpiMatrixMessage = chartType === ChartType.MATRIX && activeFilter === FilterNames.KPI;
  const bannerMessage = showKpiMatrixMessage ? t('filters.matrixKpiLimit') : t('filters.limitExceeded', { ...optionLimitsStrings });

  const shouldShowMarketFunnelLimitBanner =
    numberOfActiveOptions > TEMP_MARKET_FUNNEL_OPTIONS_LIMIT && mfLimitWarning && isChartType(ChartType.MARKET_FUNNEL) && !isLimitExceeded;

  return (
    <ExpandingContainer aria-hidden={!activeFilter} ref={filterModalRef as React.MutableRefObject<HTMLDivElement>}>
      <ModalContainer>
        <FilterModalHeader
          isCreate={isCreate}
          activeFilterDetails={activeFilterDetails}
          onHandleFiltersClose={onFilterCancel}
          faqURL={t(`faq.${activeFilter || FilterNames.COUNTRY}`)}
        />
        {(showKpiMatrixMessage || shouldShowBanner) && (
          <BannerContainer>
            <Text variant='body1'>{bannerMessage}</Text>
            <span onClick={() => setOptionsLimitBannerState(false)}>
              <img alt={t(`common.cancel`)} src={NewCloseIcon} />
            </span>
          </BannerContainer>
        )}
        {shouldShowMarketFunnelLimitBanner && (
          <BannerContainer>
            <Text variant='body1'>{t('marketFunnel.limitExceeded')}</Text>
            <span onClick={() => setMFLimitWarning(false)}>
              <img alt={t(`common.cancel`)} src={NewCloseIcon} />
            </span>
          </BannerContainer>
        )}
        <ModalContent>
          {filters.map(filter => (
            <FilterModalSection
              key={filter.id}
              availableOptions={availableOptions[filter.id] || []}
              optionsLoading={availableFiltersLoading}
              filter={getAvailableOptions(filter)}
              active={filter.id === activeFilter}
              setChartValue={setChartValue}
              isLimitExceeded={isLimitExceeded}
              displayAddSegment={displayAddSegment}
              onHandleSelectAll={onHandleSelectAll}
              chartData={values}
              dynamicFilter={dynamicFilter}
              onHandleDynoFilters={onHandleDynoFilterSelect}
              allAvailableOptions={availableOptions}
              isAllOptionsLimit={isAllOptionsLimit}
            />
          ))}
        </ModalContent>
        <FilterModalFooter
          displayAddSegment={displayAddSegment}
          isCreate={isCreate}
          canSubmit={canSubmit}
          lastFilter={isLastFilter}
          onNext={onNext}
          nextEnabled={nextEnabled}
          onFilterApply={onFilterApply}
          onFilterCancel={onFilterCancel}
          isShowAverageToggle={isShowAverageToggle}
          isChartValuesChanged={isChartValuesChanged}
          isAverageToggleEnabled={isAverageToggleEnabled}
          onReferenceValueToggle={handleAverageChange}
        />
      </ModalContainer>
    </ExpandingContainer>
  );
};

export default React.memo(FilterModal);
