import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import { ArrowUp } from 'icons';
import { theme } from 'common/theme';
import { LatanaText } from 'common/components/LatanaText';
import { scrollbarStyles } from 'common/styledComponents/mixins';

const DataTableWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.unit * 5}px;
  overflow: auto;
  position: relative;
  max-width: 100%;
  padding-bottom: ${({ theme }) => theme.space(0.25)};

  // Apply the scrollbar styles
  ${scrollbarStyles()}
`;

const TableContainer = styled.table`
  position: relative;
  border-collapse: collapse;
  width: 100%;
`;

const TableHeaderItem = styled.th`
  position: sticky;
  top: 0;
  max-width: 200px;
  height: 56px;
  min-width: 100px;
  box-shadow: ${theme.shadowBorder(theme.colors.lightGrey, [0.5, 0.5, 0.5, 0.5])};

  &:first-of-type {
    left: 0;
    min-width: 300px;
    max-width: 420px;
    background: ${({ theme }) => theme.colors.white.toString()};
    z-index: 1;
  }

  * {
    display: inline-block;
    cursor: pointer;
  }

  &[data-disabled-actions='true'] {
    * {
      cursor: default;
    }
  }
`;

const TableBodyHeader = styled.th`
  position: sticky;
  left: 0;
  box-shadow: ${theme.shadowBorder(theme.colors.lightGrey, [0.5, 0.5, 0.5, 0.5])};
  min-width: 300px;
  max-width: 420px;
  text-align: left;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.white.toString()};

  &[data-disabled-actions='true'] {
    cursor: default;
  }
`;

const TableBodyHeaderInner = styled.div`
  display: flex;
  min-height: 48px;
  align-items: stretch;
  justify-content: flex-start;
`;

const TableDataItem = styled.td<{ isClickable?: boolean }>`
  max-width: 200px;
  min-height: 48px;
  min-width: 100px;
  text-align: center;
  box-shadow: ${theme.shadowBorder(theme.colors.lightGrey, [0.5, 0.5, 0.5, 0.5])};
  box-sizing: border-box;
  padding: 0;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};

  p {
    display: inline-block;

    &:first-of-type {
      margin-right: 4px;
    }
  }
`;

const TableBodyRow = styled.tr`
  background: rgb(255, 255, 255);
  &:hover {
    > th,
    td {
      background: #f3f3f3;
    }
  }

  &[data-disabled-item='true'] {
    opacity: 0.3;
  }
`;

const ValueNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-basis: calc(100% - 60px);
  max-width: calc(100% - 60px);
  align-items: center;
  padding: ${theme.space(1, 2)};
  box-shadow: ${theme.shadowBorder(theme.colors.lightGrey, [0, 0, 0, 0.5])};
`;

const SortIconUp = styled.div`
  background: url('${ArrowUp}') no-repeat center center;
  border: none;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.unit}px;
`;

const SortIconDown = styled(SortIconUp)`
  transform: rotate(180deg);
`;

const PercentageText = styled(LatanaText)`
  display: inline-block;
`;

const ItemText = styled(Text)`
  word-break: break-word;
`;

const MoeAsteriskWrapper = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.unit / 2}px;
  color: ${({ color }) => color};
`;

export {
  DataTableWrapper,
  TableContainer,
  TableHeaderItem,
  TableBodyHeader,
  TableBodyHeaderInner,
  TableDataItem,
  TableBodyRow,
  ValueNameContainer,
  SortIconUp,
  SortIconDown,
  PercentageText,
  ItemText,
  MoeAsteriskWrapper,
};
