import { useEffect, MutableRefObject } from 'react';

const useOutsideClick = (
  ref: MutableRefObject<HTMLInputElement> | MutableRefObject<HTMLDivElement | undefined>,
  callback: (event: MouseEvent) => void,
): void => {
  const listener = (event: MouseEvent) => {
    if (!ref?.current || ref?.current.contains(event.target as Node)) return;
    callback(event);
  };

  useEffect(() => {
    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
    // eslint-disable-next-line
  }, []);
};

export default useOutsideClick;
