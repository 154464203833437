import { SelectedDashboards } from 'common/contexts/ChartsContext';
import { TableListItemProps } from 'common/types/common';
import { Card, CardGridPosition, DashboardDetails } from 'common/types/dashboard';
import { isNumber } from './helpers';
import { getCardGridWidthBySize, getDefaultCardSizeByChartType } from 'modules/dashboards/utils/card';

const NUM_OF_COLS_IN_ROW = 6;

export const getDashboardCards = (dashboards: SelectedDashboards[], dashboardId: string): Card[] | undefined => {
  return dashboards?.find(({ uuid }) => uuid === dashboardId)?.cards;
};

export const getDashboardWithoutChart = (state: SelectedDashboards[], dashboard: TableListItemProps, chartId: string) => {
  const currentDashboardCards = getDashboardCards(state, dashboard.uuid);
  const chartCards = currentDashboardCards?.filter(card => card.components.body.every(item => item.uuid !== chartId));
  return { ...dashboard, cards: chartCards };
};

export const addNewChartToDashboard = (dashboard: TableListItemProps, selectedId: string, chartType: string) => {
  const cardSize = getDefaultCardSizeByChartType(chartType);
  const newCard = {
    components: {
      header: [],
      body: [
        {
          type: 'chart',
          uuid: selectedId,
        },
      ],
      footer: [],
    },
    grid_position: { width: getCardGridWidthBySize(cardSize) },
    display_size: cardSize,
    sort_order: 1,
    uuid: '',
  };

  return {
    ...dashboard,
    cards: dashboard.cards?.concat(newCard),
  };
};

export const calculateDashboardCardsLayout = (
  cards: { id: string; gridPosition: CardGridPosition; pushTop: boolean; pushBottom: boolean; defaultWidth: string }[],
): ReactGridLayout.Layout[] => {
  if (cards.length === 0) {
    return [];
  }
  const result = [];
  const pushTopCards: { id: string; gridPosition: CardGridPosition; pushTop: boolean; pushBottom: boolean; defaultWidth: string }[] = [];
  const bottomCards: { id: string; gridPosition: CardGridPosition; pushTop: boolean; pushBottom: boolean; defaultWidth: string }[] = [];
  let pushTopWidth = 0;

  cards.forEach(card => {
    if (card.pushTop) {
      pushTopCards.push(card);
      pushTopWidth += card?.gridPosition?.width || 3;
    }
    if (card.pushBottom) {
      bottomCards.push(card);
    }
  });

  const pushTopRowsCount = Math.ceil(pushTopWidth / NUM_OF_COLS_IN_ROW);
  const restCards = cards.filter(x => x.pushBottom === false && x.pushTop === false);

  pushTopCards.forEach(item => {
    result.push({
      i: item.id,
      x: 0,
      y: 0,
      w: item?.gridPosition?.width || getCardGridWidthBySize(item.defaultWidth),
      h: item?.gridPosition?.height || 1,
      minW: 2,
    } as ReactGridLayout.Layout);
  });

  for (const item of restCards) {
    result.push({
      i: item.id,
      x: isNumber(item?.gridPosition?.x) ? item?.gridPosition?.x : 0,
      y: isNumber(item?.gridPosition?.y) ? pushTopRowsCount + (item?.gridPosition?.y || 0) : pushTopRowsCount,
      w: item?.gridPosition?.width || getCardGridWidthBySize(item.defaultWidth),
      h: item?.gridPosition?.height || 1,
      minW: 2,
    } as ReactGridLayout.Layout);
  }

  const lastCardRowYValue = result.reduce((maxY, currentItem) => Math.max(maxY, currentItem.y), result[0]?.y || 0);

  for (const item of bottomCards) {
    result.push({
      i: item.id,
      x: 0,
      y: lastCardRowYValue + 1,
      w: item?.gridPosition?.width || getCardGridWidthBySize(item.defaultWidth),
      h: item?.gridPosition?.height || 1,
      minW: 2,
    } as ReactGridLayout.Layout);
  }

  return result;
};

export const setCardsBasedOnLayout = (layout: ReactGridLayout.Layout[], editedCards: Card[]): Card[] => {
  return editedCards.map(card => {
    const position = layout.find(item => item.i === card.uuid);
    if (position) {
      return {
        ...card,
        grid_position: {
          x: position.x,
          y: position.y,
          width: position.w,
          height: position.h,
          minWidth: position.minW || 2,
        },
      };
    }
    return card;
  });
};

export const getCardGridPositionFromLayout = (uuid: string | undefined, layout: ReactGridLayout.Layout[]) => {
  if (!uuid) {
    return {};
  }

  const foundCard = layout.find(x => x.i === uuid);

  return foundCard ? { x: foundCard.x, y: foundCard.y, width: foundCard.w, height: foundCard.h } : undefined;
};

export const extractChartIdsFromDashboardData = (data: DashboardDetails | undefined): string[] => {
  if (!data || !data.cards) {
    return []; // Return an empty array if data or cards is undefined
  }

  return data.cards
    .flatMap(card => card.components.body)
    .filter(component => component.type === 'chart' && component.uuid != null) // Ensure uuid is not null or undefined
    .map(component => component.uuid as string); // Safely assert uuid is string
};
