import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import Color from 'common/theme/color';

export const ColorCircle = styled.div<{
  color: Color | undefined;
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ color }) => color?.toString()};
`;

export const GaugeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const PopulationPanel = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${({ theme }) => theme.space(1)};
`;

export const AveragePanel = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${({ theme }) => theme.space(3)};
`;

export const GaugeSubheaderValue = styled(Text)<{
  children: React.ReactNode;
  color: string;
  variant: string;
  fontWeight: string;
  textColor?: string;
}>`
  padding-left: ${({ theme }) => theme.space(0.5)};
  color: ${({ textColor }) => (textColor ? textColor : null)};
`;

export const GaugeChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const GaugeSubheaderItem = styled.div<{
  children: Element | React.ReactNode;
  key?: string;
  bgColor?: string;
  'data-testid'?: string;
  separator?: boolean;
}>`
  display: flex;
  ${({ separator, theme }) =>
    separator &&
    `&:before {
    content: '•';
    font-size: 12px;
    font-weight: 600;
		color: ${theme.colors.regularGrey.toString()};
    margin-right: ${theme.space(1)};
  }`}
`;
