import * as React from 'react';
import { LegendsContainer } from './styled';
import { LegendItemStatus } from 'common/types/common';
import LegendItem from 'modules/charts/components/LegendItem';
import { ChartType, ChartViews, GeneratedChartData } from 'common/types/chart';

interface ChartLegend {
  id?: string;
  hide: boolean;
  chartView: ChartViews | null;
  chartData: GeneratedChartData;
  disabledChartKeys: Array<string>;
  onToggleChartKey: <T extends string>(itemKey: T) => void;
}

const ChartLegend: React.FC<ChartLegend> = ({ id = '', disabledChartKeys, hide, onToggleChartKey, chartView, chartData }) => {
  const { keys, data, chartType } = chartData;

  const isLineView = React.useMemo(() => chartView === ChartViews.LINE, [chartView]);
  const isMatrixChart = React.useMemo(() => chartType === ChartType.MATRIX, [chartType]);
  const isComparisonChart = React.useMemo(() => chartType === ChartType.COMPARISON, [chartType]);

  const shouldRenderBarOrMatrixChartLegend = React.useMemo(() => {
    return (isComparisonChart && !isLineView) || isMatrixChart;
  }, [isComparisonChart, isLineView, isMatrixChart]);

  const getLegendStatus = React.useCallback(
    <T extends string>(key: T): LegendItemStatus => {
      if (disabledChartKeys.length === 0) return LegendItemStatus.DEFAULT;
      return disabledChartKeys.includes(key.toString()) ? LegendItemStatus.UNCHECKED : LegendItemStatus.CHECKED;
    },
    [disabledChartKeys],
  );

  const renderLegendItem = ({
    itemKey,
    itemLabel,
    itemColor,
    itemStatus,
    onToggleItem,
  }: {
    itemKey: string;
    itemLabel: string;
    itemColor: string;
    itemStatus: LegendItemStatus;
    onToggleItem: <T extends string>(itemKey: T) => void;
  }) => {
    return (
      <React.Fragment key={`legendItem_${itemKey}`}>
        <LegendItem itemKey={itemKey} label={itemLabel} color={itemColor} status={itemStatus} toggleItem={onToggleItem} />
      </React.Fragment>
    );
  };

  const renderBarOrMatrixChartLegend = () => {
    return (keys || []).map(key => {
      const dataObj = data?.[0];
      return renderLegendItem({
        itemKey: key,
        onToggleItem: onToggleChartKey,
        itemStatus: getLegendStatus(key),
        itemLabel: dataObj?.[`${key}_label` as 'value_0_label'],
        itemColor: dataObj?.[`${key}_color` as 'value_0_color'],
      });
    });
  };

  const renderDefaultLegend = () => {
    return data.map(item => {
      return renderLegendItem({
        itemKey: item.groupId,
        itemColor: item.color,
        itemLabel: item.groupLabel,
        onToggleItem: onToggleChartKey,
        itemStatus: getLegendStatus(item.groupId),
      });
    });
  };

  return (
    <LegendsContainer id={`Legend-${id}`} hide={hide}>
      {shouldRenderBarOrMatrixChartLegend ? renderBarOrMatrixChartLegend() : renderDefaultLegend()}
    </LegendsContainer>
  );
};

export default ChartLegend;
