import React from 'react';
import { createPortal } from 'react-dom';
export interface PortalPlacement {
  left?: string;
  right?: string;
  bottom?: string;
  top?: string;
}

interface Props {
  children: React.ReactNode;
  wrapperId?: string;
  placement?: PortalPlacement;
}

const createWrapperAndAppendToBody = (wrapperId: string, placement?: PortalPlacement) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  if (placement) {
    wrapperElement.style.position = 'fixed';
    wrapperElement.style.zIndex = '99';
    Object.keys(placement).forEach(key => {
      wrapperElement.style[key as keyof PortalPlacement] = `${placement[key as keyof PortalPlacement]}px`;
    });
  }
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

const Portal = ({ children, wrapperId = 'modal-portal', placement }: Props): React.ReactPortal | null => {
  const [wrapperElement, setWrapperElement] = React.useState<HTMLDivElement | null>(null);

  React.useLayoutEffect(() => {
    let systemCreated = false;
    let element = document.getElementById(wrapperId) as HTMLDivElement;
    // if element is undefined then create one and append it to body
    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId, placement) as HTMLDivElement;
    }
    setWrapperElement(element);

    return () => {
      // delete the element
      if (systemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId, placement]);

  if (wrapperElement === null) return null;
  return createPortal(children, wrapperElement);
};

export default Portal;
