import React, { MutableRefObject } from 'react';
import { Theme } from '@nivo/core';
import Gauge from 'views/Gauge';
import Heatmap from 'views/Heatmap';
import RadarChart from 'views/RadarChart';
import Comparison from 'views/Comparison';
import RankingChart from 'views/RankingChart';
import GrowthPerformance from 'views/GrowthPerformance';
import BrandPerception from 'views/BrandPerception';
import BarChart from 'modules/charts/components/BarChart';
import { radarChartParams } from 'modules/charts/theme';
import { getRadarChartColors, getRadarChartMaxValue } from 'modules/charts/utils/chartData';
import { Brand } from 'common/types/common';
import { HeatmapData } from 'common/types/heatmap';
import {
  ChartNumbersType,
  ChartType,
  GeneratedChartData,
  RadarChartData,
  RawChartData,
  RawChartSchema,
  RawReferenceAverageData,
  SortOption,
} from 'common/types/chart';
import MarketFunnel from 'views/MarketFunnel';
import MatrixChart from 'views/MatrixChart';

const useChart = (
  rawChartSchema: RawChartSchema,
  dirty: boolean,
  containerRef: MutableRefObject<HTMLDivElement | undefined>,
  finalChartData: GeneratedChartData,
  width: number,
  inPage: boolean,
  heatmapChartData: HeatmapData | null,
  chartTheme: Theme,
  radarChartData: RadarChartData[],
  rawChartData: RawChartData[] | undefined,
  brands: Brand[],
  marketSizePopulation: number | undefined,
  moeStatus: boolean,
  comparisonChartData: GeneratedChartData,
  aggregateChartData: RawReferenceAverageData[] | undefined,
  brandAwarenessData: RawChartData[],
  chartNumbersType: ChartNumbersType,
  disabledChartDataIds: (string | number)[],
  marketFunnelSorting: SortOption,
  showMatrixChangeOverTime: boolean,
): JSX.Element | null => {
  return React.useMemo(() => {
    switch (rawChartSchema.chart_type) {
      case ChartType.COMPARISON:
        return (
          <Comparison
            {...comparisonChartData}
            isDirty={dirty}
            chart={rawChartSchema}
            containerRef={containerRef}
            width={width}
            inPage={inPage}
            moeStatus={moeStatus}
            chartNumbersType={chartNumbersType}
          />
        );

      case ChartType.HEATMAP:
        return heatmapChartData ? (
          <Heatmap data={heatmapChartData} inPage={inPage} containerRef={containerRef} chartNumbersType={chartNumbersType} />
        ) : null;

      case ChartType.FUNNEL:
        return (
          <BarChart
            width={width}
            isDirty={dirty}
            {...finalChartData}
            inPage={inPage}
            moeStatus={moeStatus}
            chartNumbersType={chartNumbersType}
          />
        );

      case ChartType.RADAR:
        return (
          <RadarChart
            theme={chartTheme}
            data={radarChartData}
            colors={getRadarChartColors(finalChartData)}
            maxValue={getRadarChartMaxValue(radarChartData)}
            params={radarChartParams(inPage)}
            containerRef={containerRef}
            inPage={inPage}
          />
        );

      case ChartType.GAUGE:
        return <Gauge data={rawChartData} brands={brands} chartConf={rawChartSchema} aggregatedData={aggregateChartData} inPage={inPage} />;
      case ChartType.MARKET_SIZE:
        return (
          <Gauge
            data={rawChartData}
            brands={brands}
            chartConf={rawChartSchema}
            aggregatedData={aggregateChartData}
            inPage={inPage}
            population={marketSizePopulation}
            brandAwarenessData={brandAwarenessData}
          />
        );
      case ChartType.GROWTH_PERFORMANCE:
        return (
          <GrowthPerformance
            chartConf={rawChartSchema}
            chartData={rawChartData}
            brandAwarenessData={brandAwarenessData}
            chartNumbersType={chartNumbersType}
          />
        );
      case ChartType.RANKING:
        return <RankingChart chart={rawChartSchema} {...finalChartData} chartNumbersType={chartNumbersType} />;
      case ChartType.BRAND_PERCEPTION:
        return (
          <BrandPerception
            chartConf={rawChartSchema}
            chartData={rawChartData}
            averageChartData={aggregateChartData}
            brandAwarenessData={brandAwarenessData}
            chartNumbersType={chartNumbersType}
          />
        );
      case ChartType.MARKET_FUNNEL:
        return <MarketFunnel chartConf={rawChartSchema} chartData={rawChartData} inPage={inPage} conesSortingData={marketFunnelSorting} />;
      case ChartType.MATRIX:
        return (
          <MatrixChart
            chartConf={rawChartSchema}
            chartData={rawChartData}
            inPage={inPage}
            disabledChartDataIds={disabledChartDataIds}
            showMatrixChangeOverTime={showMatrixChangeOverTime}
          />
        );
      default:
        return null;
    }
  }, [
    rawChartSchema,
    rawChartData,
    finalChartData,
    width,
    dirty,
    radarChartData,
    inPage,
    heatmapChartData,
    aggregateChartData,
    brands,
    chartTheme,
    containerRef,
    marketSizePopulation,
    moeStatus,
    comparisonChartData,
    brandAwarenessData,
    chartNumbersType,
    disabledChartDataIds,
    marketFunnelSorting,
    showMatrixChangeOverTime,
  ]);
};

export default useChart;
