import React, { Ref } from 'react';
import { Value } from '@nivo/bar';
import { roundedRectangle } from 'modules/charts/utils/svg';
import BarValue from 'modules/charts/components/BarValue';

interface RoundedRectangleProps {
  barValue: number;
  barColor: string;
  barWidth: number;
  barHeight: number;
  moeHeight: number;
  population: string;
  yAxisValue: number;
  xAxisValue: number;
  dataGroupId: Value;
  moeStatus?: boolean;
  cornerRadius: number;
  isNumericChart: boolean;
  isShowBarValue: boolean;
  calculatedHeight: number;
  shouldRotateLabel: boolean;
  isShowPopulationNumbers: boolean;
  ref: Ref<SVGTextElement> | undefined;
}

const RoundedRectangle: React.FC<RoundedRectangleProps> = React.forwardRef(
  (
    {
      barValue,
      barColor,
      barWidth,
      barHeight,
      moeHeight,
      moeStatus,
      population,
      yAxisValue,
      xAxisValue,
      dataGroupId,
      cornerRadius,
      isNumericChart,
      isShowBarValue,
      calculatedHeight,
      shouldRotateLabel,
      isShowPopulationNumbers,
    },
    ref,
  ) => {
    return (
      <g>
        <path
          opacity='0.85'
          fill={barColor}
          data-group={dataGroupId}
          d={roundedRectangle(0, calculatedHeight, barWidth, barHeight, cornerRadius, cornerRadius, 0, 0)}
        />
        {isShowBarValue && (
          <BarValue
            ref={ref}
            barValue={barValue}
            barWidth={barWidth}
            moeHeight={moeHeight}
            moeStatus={moeStatus}
            xAxisValue={xAxisValue}
            yAxisValue={yAxisValue}
            populationValue={population}
            isNumericChart={isNumericChart}
            shouldRotateLabel={shouldRotateLabel}
            showPopulationNumbers={isShowPopulationNumbers}
          />
        )}
      </g>
    );
  },
);

RoundedRectangle.displayName = 'RoundedRectangle';

export default RoundedRectangle;
