import * as React from 'react';
import { Text } from 'common/styledComponents/typography';
import styled from '@emotion/styled';
import TextInput from 'common/components/TextInput';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components/Form/Button';
import { isValidEmail } from 'utils/helpers';
import { ScreenType } from 'common/types/login';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.unit * 3}px 0;
  width: 320px;
`;

const ResetLink = styled.a`
  color: inherit;
  margin: 0 ${({ theme }) => theme.spacing.unit}px;
`;

const ResetBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.unit * -2}px 0 ${({ theme }) => theme.spacing.unit * 2}px;
`;

export interface ResetPasswordFormData {
  email: string;
}

interface Props {
  setView: (view: ScreenType) => void;
  onSubmit: (data: ResetPasswordFormData) => void;
  message?: string;
  error?: string;
}

const PasswordResetForm: React.FC<Props> = ({ setView, onSubmit, error, message }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<ResetPasswordFormData>({});
  const onBackClick = React.useCallback(() => setView(ScreenType.LOGIN), [setView]);
  const [validEmail, setValidEmail] = React.useState(false);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValidEmail(isValidEmail(e.currentTarget.value));
  };
  return (
    <React.Fragment>
      <Text color='white' variant='h3'>
        Reset password
      </Text>
      <br />
      <Text color='white' variant='h5'>
        We’ll send you a link to change your password. If you still need help, contact us here
        <ResetLink href='mailto:help@latana.com'>help@latana.com</ResetLink>
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput label={t('login.email.placeholder')} name='email' ref={register} onChange={handleTextChange} />
        <ErrorContainer>
          {error ? (
            <Text color='wine' variant='body1'>
              {error}
            </Text>
          ) : null}
          {message ? (
            <Text color='turqoise' variant='body1'>
              {message}
            </Text>
          ) : null}
        </ErrorContainer>
        <FormActions>
          <Button disabled={!validEmail} type='submit' color='turqoise'>
            Reset password
          </Button>
          <ResetBtn onClick={onBackClick} type='button'>
            <Text color='white' variant='body1'>
              Back to login
            </Text>
          </ResetBtn>
        </FormActions>
      </Form>
    </React.Fragment>
  );
};

export default React.memo(PasswordResetForm);
