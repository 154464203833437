import styled from '@emotion/styled';

const TableHeadItem = styled.td`
  padding: ${({ theme }) => theme.space(2, 0)};
  > div {
    justify-content: flex-end;
  }
  &:first-of-type {
    padding-left: 16px;
    > div {
      justify-content: flex-start;
    }
  }
  &:not(:first-of-type) span {
    cursor: pointer;
  }
`;

const TableDataItem = styled.td``;

const TableBodyCellContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  > h6 {
    margin-left: 4px;
  }
`;

const ValueNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-basis: calc(100% - 16px);
  max-width: calc(100% - 16px);
  align-items: center;
  padding: ${({ theme }) => theme.space(1, 2)};
  > h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SortingControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.space(1)};
`;

const SVGContainer = styled.div`
  width: 8px;
  height: 8px;
`;

const BrandGroupPipe = styled.span<{ isPrimary?: boolean }>`
  width: 4px;
  background-color: ${({ isPrimary, theme }) => (isPrimary ? theme.colors.blueSparkle.toString() : theme.colors.turquoisePanic.toString())};
`;

export { TableHeadItem, TableDataItem, TableBodyCellContainer, ValueNameContainer, SortingControlContainer, SVGContainer, BrandGroupPipe };
