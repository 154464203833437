import styled from '@emotion/styled';

const TableListContainer = styled.div`
  width: 100%;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li<{ isListItemHovered?: boolean; isActionButtonHovered?: boolean; isDisabled: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  padding: ${({ theme }) => theme.v2.space(2, 1.5)};
  border-bottom: ${({ theme }) => `1px solid ${theme.v2.colours.gray100}`};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ theme, isListItemHovered, isActionButtonHovered }) =>
      isListItemHovered && theme.v2.colours[!isActionButtonHovered ? 'gray100' : 'gray0']};
  }
  > div {
    &:first-of-type {
      flex: 2;
    }
    &:nth-of-type(2) {
      flex: 1;
    }
    &:last-of-type {
      flex: 1;
      justify-content: space-between;
    }
  }
`;

const TableListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: ${({ theme }) => theme.v2.space(1)};
  border-bottom: ${({ theme }) => `1px solid ${theme.v2.colours.gray100}`};
  > div {
    &:first-of-type {
      flex: 2;
    }
    &:nth-of-type(2) {
      flex: 1;
    }
    &:last-of-type {
      flex: 1;
    }
  }
`;

const ListItemDetails = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: ${({ theme }) => theme.v2.space(1)};
  }
  &:first-of-type {
    flex: 2;
    padding-left: ${({ theme }) => theme.v2.space(0.6)};
  }
  &:nth-of-type(2) {
    flex: 1;
    padding-left: ${({ theme }) => theme.v2.space(2.7)};
  }
  &:last-of-type {
    flex: 1;
    padding-left: ${({ theme }) => theme.v2.space(1.5)};
    justify-content: space-between;
  }
`;

const TableListTitleWrapper = styled.div`
  display: flex;
`;

const TableListTitleControl = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: ${({ theme }) => theme.v2.space(1)};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.v2.colours.gray100};
  }
  > svg {
    margin-left: ${({ theme }) => theme.v2.space(1)};
  }
`;

const ListItemOwnerCell = styled.div`
  display: flex;
  > svg {
    margin-left: ${({ theme }) => theme.v2.space(1)};
  }
`;

const ListItemSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.v2.space(2)};
`;

const ListItemDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 65px;
`;

export {
  TableListContainer,
  List,
  ListItem,
  TableListHeaderContainer,
  ListItemDetails,
  TableListTitleWrapper,
  TableListTitleControl,
  ListItemOwnerCell,
  ListItemSpinner,
  ListItemDetailsContainer,
};
