import { useCallback, useMemo } from 'react';
import { isNotUndefined } from 'utils/helpers';
import { Segment, SegmentGroups } from 'common/types/segments';
import { getAllCountriesSegment, getGroupedSegments, getIdFromUrl, groupSegmentsByStudyID, mapSegmentsColor } from 'utils/segments';
import { useRawSegments } from 'common/queries/segments';
import { useGetCountriesByStudyId } from 'common/hooks/countries';

export const useSegmentsList = (studyId: string): Segment[] => {
  const { data: segments } = useRawSegments();

  return useMemo(() => {
    if (!isNotUndefined(studyId) || !segments?.length) return [];

    const segmentsGroupedByStudyId = groupSegmentsByStudyID(segments, studyId);
    return mapSegmentsColor(segmentsGroupedByStudyId);
  }, [segments, studyId]);
};

export const useGroupedSegments = (currentStudy: string): Array<SegmentGroups> => {
  const studyId = getIdFromUrl() || currentStudy;
  const getCountriesById = useGetCountriesByStudyId();
  const segments = useSegmentsList(currentStudy);

  return useMemo(() => {
    const combinedSegments = [...getAllCountriesSegment(segments), ...getGroupedSegments(getCountriesById(studyId), segments)];
    const nonEmptySegments = combinedSegments.filter(({ segments }) => segments?.length);
    return nonEmptySegments;
  }, [studyId, segments, getCountriesById]);
};

export const useStudySpecificSegment = (studyId: string): Segment[] | undefined => {
  const { data: segments } = useRawSegments();

  return useMemo(() => {
    return segments?.find(({ study_uuid }) => study_uuid === studyId)?.segments;
  }, [segments, studyId]);
};

export const useSegments = (): {
  getSegmentsByStudyId: (studyId: string) => Segment[];
} => {
  const { data: segments } = useRawSegments();

  const getSegmentsByStudyId = useCallback(
    (studyId: string) => {
      if (!isNotUndefined(studyId) || !segments?.length) return [];

      const segmentsGroupedByStudyID = groupSegmentsByStudyID(segments, studyId);
      return mapSegmentsColor(segmentsGroupedByStudyID);
    },
    [segments],
  );

  return useMemo(() => {
    return {
      getSegmentsByStudyId,
    };
  }, [getSegmentsByStudyId]);
};
