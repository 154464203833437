import * as React from 'react';
import styled from '@emotion/styled';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space(4, 0, 2, 0)};
  width: 100%;
`;

const EditInputField = styled.input`
  color: ${({ theme }) => theme.v2.colours.gray900};
  padding: 0;
  padding-left: ${({ theme }) => theme.v2.space(1)};
  font-size: ${({ theme }) => theme.v2.text.variants.B1.fontSize};
  font-weight: ${({ theme }) => theme.v2.text.variants.B1.fontWeight};
  line-height: ${({ theme }) => theme.v2.text.variants.B1.lineHeight};
  letter-spacing: ${({ theme }) => theme.v2.text.variants.B1.letterSpacing};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.v2.colours.gray200};
  font-family: ${({ theme }) => theme.v2.text.fontFamily};

  &:focus {
    outline: none;
  }

  &:placeholder {
    color: ${({ theme }) => theme.v2.colours.gray400};
    opacity: 1; /* Firefox */
  }
`;

interface ILabeledInputProps {
  defaultValue: string;
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
}

const LabeledInput: React.FC<ILabeledInputProps> = ({ placeholder, defaultValue, onInputChange }) => (
  <InputContainer>
    <EditInputField placeholder={placeholder} defaultValue={defaultValue} onChange={onInputChange} />
  </InputContainer>
);

export default LabeledInput;
