import { useEffect, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { ToastPayload } from 'common/types/notification';

export const useAutoRemove = ({
  toasts,
  setToasts,
  duration,
  autoRemove,
}: {
  toasts: ToastPayload[];
  setToasts: SetterOrUpdater<ToastPayload[]>;
  duration: number;
  autoRemove: boolean;
}): void => {
  const [removing, setRemoving] = useState('');

  useEffect(() => {
    if (removing) {
      setToasts(prev => prev.filter(toast => toast.id !== removing));
    }
  }, [removing, setToasts]);

  useEffect(() => {
    if (toasts.length && autoRemove) {
      const id = toasts[toasts.length - 1].id;
      setTimeout(() => setRemoving(id), duration);
    }
  }, [toasts, duration, autoRemove]);
};
