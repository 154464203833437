import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicWaves } from 'settings/constants';
import { DynamicFilters } from 'common/types/filters';
import { ChartType } from 'common/types/chart';

type DynamicFiltersList = Array<{
  label: string;
  onChange: () => void;
  type: DynamicWaves;
  selected: boolean;
}>;

export const useDynamicFilters = (
  onHandleSelectAll: () => void,
  onHandleDeselectAll: () => void,
  onHandleDynoFilters: (dynamicFilter: DynamicFilters, status?: boolean) => void,
  onHandleSelectLastWave: () => void,
  isAllSelected: boolean,
  isLastWaveSelected: boolean,
  dynamicFilter: DynamicFilters | null,
  chartType = '',
  singleSelection = false,
): DynamicFiltersList => {
  const { t } = useTranslation();
  const isOptionSelected = useCallback((filterName: DynamicFilters) => filterName === dynamicFilter, [dynamicFilter]);
  const isMostRecentSelected = isOptionSelected(DynamicFilters.LAST_WAVE) && isLastWaveSelected;
  const isAllTimeSelected = isOptionSelected(DynamicFilters.ALL_WAVES) && isAllSelected;

  const isAllTimeAvailable =
    chartType === ChartType.GROWTH_PERFORMANCE ||
    (![ChartType.HEATMAP, ChartType.MARKET_SIZE, ChartType.GAUGE].includes(chartType as ChartType) && !singleSelection);

  const isMostRecentAvailable = chartType !== ChartType.BRAND_PERCEPTION && chartType !== ChartType.MARKET_FUNNEL;

  return useMemo(() => {
    const dynamicFilters = [];
    if (isAllTimeAvailable) {
      dynamicFilters.push({
        label: isAllTimeSelected ? t('filters.deselectAll') : t('filters.selectAll'),
        onChange: () => {
          isAllTimeSelected ? onHandleDeselectAll() : onHandleSelectAll();
          onHandleDynoFilters(DynamicFilters.ALL_WAVES, isAllTimeSelected);
        },
        type: DynamicWaves.SELECT_ALL_BUTTON,
        selected: false,
      });
    }
    if (isMostRecentAvailable) {
      dynamicFilters.push({
        label: t('dynamicFilters.mostRecent'),
        onChange: () => {
          isMostRecentSelected ? onHandleDeselectAll() : onHandleSelectLastWave();
          onHandleDynoFilters(DynamicFilters.LAST_WAVE, isMostRecentSelected);
        },
        type: DynamicWaves.MOST_RECENT,
        selected: isMostRecentSelected,
      });
    }
    return dynamicFilters;
  }, [
    onHandleSelectAll,
    onHandleDeselectAll,
    t,
    onHandleDynoFilters,
    onHandleSelectLastWave,
    isAllTimeSelected,
    isMostRecentSelected,
    isAllTimeAvailable,
    isMostRecentAvailable,
  ]);
};
