import { uiPreferencesSelector } from 'common/atoms/account';
import { Text } from 'common/styledComponents/typography';
import { ChartType, GaugeLegends, RawChartData, RawChartSchema, RawReferenceAverageData } from 'common/types/chart';
import { Brand } from 'common/types/common';
import GaugeChart from 'modules/charts/components/GaugeChart';
import GaugeLegend from 'modules/charts/components/GaugeChart/GaugeLegend';
import GaugeText from 'modules/charts/components/GaugeChart/GaugeText';
import {
  PopulationPanel,
  GaugeSubheaderItem,
  GaugeSubheaderValue,
  GaugeWrapper,
  AveragePanel,
  GaugeChartWrapper,
} from 'modules/charts/styledComponents';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AIDED_BRAND_AWARENESS, GAUGE_COLORS, PURCHASE_DRIVERS } from 'settings/constants';
import { roundValue } from 'utils/chart';
import { representValuesInKsOrMs } from './utils';
import { useAllKpisMap } from 'common/hooks/kpis';
import { useHidePopulationNumbersEnabled } from 'common/hooks/features';

interface Props {
  data: RawChartData[] | undefined;
  brands: Brand[];
  aggregatedData: RawReferenceAverageData[] | undefined;
  inPage: boolean;
  population?: number;
  chartConf: RawChartSchema;
  brandAwarenessData?: RawChartData[];
}

const GAUGE_CHART_L = 230;
const GAUGE_CHART_M = 215;
const GAUGE_CHART_S = 190;

const Gauge = ({ data, brands = [], aggregatedData = [], inPage = false, population = 0, chartConf, brandAwarenessData = [] }: Props) => {
  const { decimalsEnabled } = useRecoilValue(uiPreferencesSelector);
  const kpiMap = useAllKpisMap(chartConf.study_uuid);
  const { t } = useTranslation();
  const isMarketSize = chartConf.chart_type === ChartType.MARKET_SIZE;
  const hidePopulationNumbers = useHidePopulationNumbersEnabled(chartConf.study_uuid);
  const isBasedOnBrandAwareness =
    isMarketSize && brandAwarenessData?.length > 0 && data?.[0].kpi !== AIDED_BRAND_AWARENESS && data?.[0].kpi !== PURCHASE_DRIVERS;

  const percentages = React.useMemo(() => {
    const meanValue = isBasedOnBrandAwareness ? (data?.[0]?.mean || 0) * brandAwarenessData[0].mean : data?.[0]?.mean || 0;
    const chartPercentage = roundValue(meanValue * 100 || 0, decimalsEnabled);
    const benchmarkPercentages = aggregatedData.map(({ mean }) => roundValue((mean || 0) * 100, decimalsEnabled));
    return [chartPercentage, ...benchmarkPercentages];
  }, [data, aggregatedData, decimalsEnabled, brandAwarenessData, isBasedOnBrandAwareness]);

  const renderPopulationData = React.useCallback(() => {
    return (
      <PopulationPanel>
        <GaugeSubheaderItem>
          <Text color='lightPurple' variant='body2'>
            {t('population.adult')}
          </Text>
          <GaugeSubheaderValue color='lightPurple' variant='span' fontWeight='700'>
            {representValuesInKsOrMs(population)}
          </GaugeSubheaderValue>
        </GaugeSubheaderItem>
        <GaugeSubheaderItem separator={true}>
          <Text color='lightPurple' variant='body2'>
            {t('population.target')}
          </Text>
          <GaugeSubheaderValue color='lightPurple' variant='span' textColor={GAUGE_COLORS[0]} fontWeight='700'>
            {`${percentages[0]}%`}
          </GaugeSubheaderValue>
        </GaugeSubheaderItem>
      </PopulationPanel>
    );
  }, [percentages, population, t]);

  const renderSectorAverage = React.useCallback(() => {
    return (
      <AveragePanel>
        <GaugeSubheaderItem>
          <Text color='persimmon' variant='body1' fontWeight='700'>
            {t('gauge.average')}
          </Text>
          <GaugeSubheaderValue color='persimmon' variant='span' fontWeight='700'>
            {roundValue(percentages[1], decimalsEnabled)}%
          </GaugeSubheaderValue>
        </GaugeSubheaderItem>
      </AveragePanel>
    );
  }, [percentages, decimalsEnabled, t]);

  const kpiName = React.useMemo(() => {
    return data && data[0] ? kpiMap[data[0].kpi] : '';
  }, [data, kpiMap]);

  const chartLegends = React.useMemo(() => {
    const legends = [] as GaugeLegends[];
    if (data?.length) {
      const selectedBrand = brands?.find(brand => data[0].brand === brand.id);
      legends.push({ name: selectedBrand?.name || '', color: GAUGE_COLORS[0] });
    }
    if (aggregatedData?.length) {
      legends.push({
        name: 'Brand benchmark',
        color: GAUGE_COLORS[1],
      });
    }
    return legends;
  }, [brands, aggregatedData, data]);

  const chartSize = React.useMemo(() => {
    return inPage ? (population && percentages.length > 1 ? GAUGE_CHART_S : GAUGE_CHART_M) : GAUGE_CHART_L;
  }, [inPage, population, percentages.length]);

  return data && data.length > 0 ? (
    <GaugeWrapper>
      {!hidePopulationNumbers && population > 0 && renderPopulationData()}
      {percentages.length > 1 && renderSectorAverage()}
      <GaugeChartWrapper>
        <svg width={chartSize} height={chartSize} viewBox={`0 0 ${chartSize} ${chartSize}`}>
          {percentages.map((percentage, i) => (
            <GaugeChart key={`${percentage}-${i}`} percentage={percentage} sqSize={chartSize} gapUnit={i} />
          ))}
          <GaugeText
            text={!hidePopulationNumbers && population ? t('marketSize.title') : kpiName}
            percentage={isBasedOnBrandAwareness ? data?.[0].mean * brandAwarenessData[0].mean : percentages[0]}
            sqSize={chartSize}
            population={hidePopulationNumbers ? undefined : population}
          />
        </svg>
      </GaugeChartWrapper>
      {!inPage && <GaugeLegend legends={chartLegends} />}
    </GaugeWrapper>
  ) : null;
};

export default React.memo(Gauge);
