import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useDeleteDashboard, useDuplicateDashboard, useGetDashboards } from 'common/queries/dashboards';
import TableList from 'common/components/TableList';
import { useEffect, useMemo } from 'react';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import { isGuestSelector, permissionsSelector } from 'common/atoms/account';
import { useRecoilValue } from 'recoil';
import { DashboardDetails } from 'common/types/dashboard';
import ConfirmationModal from 'common/components/Modal/ConfirmationModal';
import { SortingCriteria, SortingKey, TableListItemProps, TableRowItemProps } from 'common/types/common';
import { LatanaText } from 'common/components/LatanaText';
import { SortType } from 'common/types/chart';
import { DataTestId, ActionItems } from 'settings/constants';
import { ListItemType, TableListHeaderProps } from 'common/types/charts';
import TableListHeader from 'common/components/TableList/TableListHeader';
import TableListItem from 'common/components/TableList/TableListItem';
import ActionMenu, { ActionMenuProps } from 'common/components/ActionMenu';
import { useActionMenu } from 'common/hooks/useActionMenu';
import { useTracking } from 'common/hooks/tracking';
import { SCREENS, TRACKING_EVENTS } from 'common/types/tracking';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.v2.colours.gray0};
`;

const ConfirmationTextWrapper = styled.div`
  margin: ${({ theme }) => theme.v2.space(2.75, 0)};
  text-align: center;
`;

const Dashboards = () => {
  const { t } = useTranslation();
  const [showDeleteModal, toggleDeleteModal] = React.useState(false);
  const [selectedDashboard, setSelectedDashboard] = React.useState<DashboardDetails | null>(null);
  const [sortingCriteria, setSortingCriteria] = React.useState<SortingCriteria>({
    key: SortingKey.UPDATED_AT,
    type: SortType.DESC,
  });
  const isGuest = useRecoilValue(isGuestSelector);
  const { internal: isInternalUser } = useRecoilValue(permissionsSelector);
  const { data: dashboards, isLoading: isDataLoading } = useGetDashboards();
  const { mutate: deleteDashboard, isLoading: isDeleteLoading } = useDeleteDashboard();
  const { mutate: duplicateDashboard, isLoading: isDuplicateLoading } = useDuplicateDashboard();
  const { trackEvent } = useTracking();

  const updatedActionMenu = useActionMenu(isInternalUser);

  const showActionMenu = !isGuest;

  const dashboardListActions = [
    { label: t('actionMenu.open', { title: 'dashboard' }), value: ActionItems.OPEN, icon: 'expand' },
    { label: t('actionMenu.duplicate'), value: ActionItems.DUPLICATE, icon: 'duplicate' },
    { label: '', value: ActionItems.SEPARATOR },
    {
      label: { name: t('actionMenu.delete'), color: { active: 'red500', disabled: 'red200' } },
      value: ActionItems.DELETE,
      icon: { name: 'trash-can', color: { active: 'red400', disabled: 'red200' } },
      blurb: t('actionMenu.disabledDeleteBlurb', { title: 'Dashboard' }),
    },
  ] as SelectPopupItemProps[];

  const dashboardTableItems = useMemo(
    () =>
      dashboards?.map(
        x =>
          ({
            name: x.name,
            uuid: x.uuid,
            updated_at: x.updated_at,
            owner: x.owner,
            cards: x.cards,
          } as TableListItemProps),
      ),
    [dashboards],
  );

  const sortedItemsList = React.useMemo(() => {
    if (!dashboardTableItems) return [];

    return dashboardTableItems.slice().sort((itemA, itemB) => {
      const sortingKey = sortingCriteria.key;
      const sortingType = sortingCriteria.type;

      const getItemValue = (item: TableListItemProps): string => {
        return sortingKey === SortingKey.OWNER ? item[sortingKey]?.name || '' : item[sortingKey];
      };

      const valueA = getItemValue(itemA);
      const valueB = getItemValue(itemB);

      switch (sortingType) {
        case SortType.DEFAULT:
          return 0;
        case SortType.DESC:
          return valueA?.localeCompare(valueB);
        default:
          return valueB?.localeCompare(valueA);
      }
    });
  }, [sortingCriteria, dashboardTableItems]);

  const handleDuplicateDashboard = React.useCallback(
    (dashboardData: TableListItemProps) => {
      duplicateDashboard({
        dashboardId: dashboardData.uuid,
        options: {
          toast: { message: `${dashboardData.name} has been duplicated`, status: 'success' },
        },
      });
      trackEvent(TRACKING_EVENTS.DUPLICATE_DASHBOARD_CLICK, {
        dashboardId: dashboardData.uuid,
      });
    },
    [duplicateDashboard, trackEvent],
  );

  const handleConfirmDelete = React.useCallback(() => {
    deleteDashboard({
      id: selectedDashboard?.uuid as string,
      options: { toast: { message: `${selectedDashboard?.name} has been deleted`, status: 'success' } },
    });
    toggleDeleteModal(false);
  }, [selectedDashboard, deleteDashboard]);

  const handleDeleteDashboard = React.useCallback(
    (dashboardData: TableListItemProps) => {
      setSelectedDashboard(dashboardData as DashboardDetails);
      toggleDeleteModal(true);
    },
    [toggleDeleteModal],
  );

  useEffect(() => {
    trackEvent(TRACKING_EVENTS.VIEW_PAGE, {
      screen: SCREENS.DASHBOARDS_INDEX,
    });
  }, [trackEvent]);

  return (
    <Container>
      <LatanaText color='gray900' variant='H2'>
        {t('views.dashboards')}
      </LatanaText>
      <TableList
        type={ListItemType.DASHBOARDS}
        items={sortedItemsList}
        onHandleListSort={setSortingCriteria}
        onItemDuplicate={handleDuplicateDashboard}
        onItemDelete={handleDeleteDashboard}
        isDataLoading={isDuplicateLoading || isDataLoading}
        viewportOffset={140}
        renderHeader={(headerProps: TableListHeaderProps) => <TableListHeader {...headerProps} />}
        renderListItem={({ listItemData, onSelect, ...otherProps }: TableRowItemProps) => {
          return (
            <TableListItem
              {...otherProps}
              {...listItemData}
              renderActionMenu={
                showActionMenu
                  ? (props: ActionMenuProps) => (
                      <ActionMenu
                        {...props}
                        id={DataTestId.LIST_ACTION}
                        icon='more'
                        stopPropagation
                        items={updatedActionMenu(dashboardListActions, listItemData?.owner)}
                        onSelect={options => onSelect(options, listItemData)}
                      />
                    )
                  : undefined
              }
            />
          );
        }}
      />
      {/* TODO: Move confirmation modal to <Layout /> */}
      <ConfirmationModal
        isLoading={isDeleteLoading}
        title={t('chart.deleteChart')}
        open={showDeleteModal}
        onCancel={() => toggleDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      >
        <ConfirmationTextWrapper>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.headingv2', { title: selectedDashboard?.name || '' })}
          </LatanaText>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.subheadingv2')}
          </LatanaText>
        </ConfirmationTextWrapper>
      </ConfirmationModal>
    </Container>
  );
};

export default Dashboards;
