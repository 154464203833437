export const TRACKING_EVENTS = {
  VIEW_PAGE: 'view-page',
  ADD_CHART: 'click-to-add-chart',
  SAVE_CHART: 'save-chart',
  EXPORT_CHART: 'export-chart',
  DELETED_CHART: 'deleted-chart',
  SORT_TABLE: 'sort-table',
  TOGGLE_VISIBILITY_TABLE_ROW: 'toggle-visibility-table-rows',
  ADD_SEGMENT: 'click-to-add-segment',
  SAVED_SEGMENT: 'saved-segment',
  DELETED_SEGMENT: 'deleted-segment',
  RESET_PASSWORD: 'reset-password',
  DOWNLOAD_CSV: 'download-csv',
  CLICK_EDIT_CHART_FILTERS: 'click-to-edit-chart-filters',
  DOWNLOAD_WAVE_SUMMARY_SETTINGS: 'download-wave-summary-from-settings',
  DOWNLOAD_WAVE_SUMMARY_MODAL: 'download-wave-summary-from-modal',
  APPEARANCE_OPTION_OPENED: 'appearance-option-opened',
  APPEARANCE_DECIMALS_TOGGLE: 'appearance-decimals-toggle',
  APPEARANCE_BAR_VALUE_TOGGLE: 'appearance-bar-value-toggle',
  APPEARANCE_MOE_TOGGLE: 'appearance-moe-toggle',
  STUDY_SELECT_OPEN: 'study-select-open',
  STUDY_CHANGED: 'study-changed',
  LOGGED_IN_WITH_GOOGLE: 'logged-in-with-google',
  ADD_FOLDER: 'add-folder',
  RENAME_FOLDER: 'rename-folder',
  DELETE_FOLDER: 'delete-folder',
  REORDER_FOLDER: 'reorder-folder',
  REORDER_CHART: 'reorder-chart',
  MOVE_CHART_TO_FOLDER: 'move-chart-to-folder',
  LOG_OFF: 'log-off',
  EDUCATION_LINK: 'education-link',
  INCOME_LINK: 'income-link',
  LOCATION_LINK: 'location-link',
  KPI_LINK: 'kpi-link',
  FAQ_ALL_CHARTS_LINK: 'faq-all-charts-link',
  FAQ_KPI_LINK: 'faq-kpi-link',
  FAQ_FOLDERS_LINK: 'faq-folders-link',
  LOGGED_IN_FROM_ADMIN_PANEL: 'logged-in-from-admin-panel',
  FAQ_MEMBERS_HOW_TO_LINK: 'faq-members-how-to-link',
  MEMBERS_USER_FAQ_LINK: 'members-user-faq-link',
  SETTINGS_USER_PERMISSION_FAQ_LINK: 'settings-user-permission-faq-link',
  EMAIL_NOTIFICATION_TOGGLE: 'update-notification-preferences',
  ENTRANCE_SOURCE: 'entrance-source',
  CLICK_INSIGHT_CARD: 'click-insight-card',
  CLICK_TO_FAQ: 'click-to-faq',
  CLICK_TO_ADMIN: 'click-to-admin',
  SAVE_COMPETITORS: 'save-competitors',
  CLICK_SHORTCUT_DUPLICATE: 'click-chart-shortcut-duplicate',
  CLICK_SHORTCUT_PNG: 'click-chart-shortcut-create-chart-image',
  CLICK_SHORTCUT_CSV: 'click-chart-shortcut-download-csv',
  ACTION_MENU_CLICK: 'click-chart-actions-menu',
  ACTION_MENU_ITEM_CLICK: 'chart-actions-menu-',
  NAV_BAR_ADD_ITEM_CLICK: 'nav-actions-menu-add-',
  CLICK_SAVE_DASHBOARD: 'click-save-dashboard',
  CLICK_EDIT_DASHBOARD: 'click-edit-dashboard',
  CLICK_DELETE_CHART_FROM_DASHBOARD: 'click-delete-chart-from-dashboard',
  ADD_CHARTS_TO_DASHBOARD_STEP_1_MAIN: 'click-add-charts-to-dashboard-step-1-main-CTA',
  ADD_CHARTS_TO_DASHBOARD_STEP_1_SECONDARY: 'click-add-charts-to-dashboard-step-1-secondary-CTA',
  ADD_CHARTS_TO_DASHBOARD_STEP_2_ADD: 'click-add-charts-to-dashboard-step-2-add',
  ADD_CHARTS_TO_DASHBOARD_STEP_2_CANCEL: 'click-add-charts-to-dashboard-step-2-cancel',
  DUPLICATE_DASHBOARD_CLICK: 'click-duplicate-dashboard',
  CHART_FILTERS: 'chart-filters',
};

export const SCREENS = {
  SETTINGS: 'settings',
  SEGMENTS: 'segments',
  COMPETITORS: 'competitors',
  CHART_DETAILS: 'chart-details',
  CHARTS_INDEX: 'charts-index',
  DASHBOARDS_INDEX: 'dashboards-index',
};
