import * as React from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { SortType } from 'common/types/chart';
import { TableListContainer, ListItemSpinner } from 'common/components/TableList/styled';
import { SortingCriteria, SortingKey, TableListItemProps, TableRowItemProps } from 'common/types/common';
import Spinner from 'common/components/Spinner';
import { FetchNextPage, ListItemType, TableListHeaderProps } from 'common/types/charts';
import { useGetViewportSize } from 'common/hooks/useGetViewportSize';
import { ActionItems } from 'settings/constants';
import { useChartDashboardsContext } from 'common/contexts/ChartsContext';
import { useNavigate } from 'react-router-dom';

interface TableListProps {
  type: ListItemType;
  renderListItem: (listItemProps: TableRowItemProps) => JSX.Element;
  onHandleListSort?: ({ key, type }: SortingCriteria) => void;
  items?: Array<TableListItemProps>;
  isDataLoading?: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  //Adjust the height of the list with respect to space taken by Chart title, table header and gloabal padding
  viewportOffset?: number;
  fetchNextpage?: FetchNextPage;
  onItemDuplicate?: (item: TableListItemProps) => void;
  onItemDelete?: (item: TableListItemProps) => void;
  renderHeader?: (headerProps: TableListHeaderProps) => unknown;
  inModal?: boolean;
}

const ROW_HEIGHT = 65;

const TableSpinner: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <ListItemSpinner style={style}>
    <Spinner size='extraSmall' />
  </ListItemSpinner>
);

const TableList = ({
  items = [],
  type,
  onItemDuplicate,
  isDataLoading = false,
  onItemDelete,
  onHandleListSort,
  viewportOffset = 186,
  hasNextPage,
  isFetchingNextPage,
  fetchNextpage,
  renderHeader,
  renderListItem,
  inModal,
}: TableListProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { viewportHeight } = useGetViewportSize({ heightOffset: viewportOffset });
  const { setModalOpen, setSelectedChart } = useChartDashboardsContext();
  const navigate = useNavigate();

  const [sortingCriteria, setSortingCriteria] = React.useState<SortingCriteria>({
    key: SortingKey.UPDATED_AT,
    type: SortType.DESC,
  });

  const onHandleClick = React.useCallback(
    (chartId: string) => {
      const url = type === ListItemType.DASHBOARDS ? '/dashboard' : '/chart';
      navigate(`${url}/${chartId}`);
    },
    [type, navigate],
  );

  const onHandleSort = React.useCallback(
    (columnName: SortingKey) => {
      if (!columnName) return;

      let sortingType;

      switch (sortingCriteria?.type) {
        case SortType.ASC:
          sortingType = SortType.DEFAULT;
          break;
        case SortType.DESC:
          sortingType = SortType.ASC;
          break;
        default:
          sortingType = SortType.DESC;
      }

      const newCriteria = { key: columnName, type: sortingType };

      setSortingCriteria(newCriteria);
      onHandleListSort && onHandleListSort(newCriteria);
    },
    [setSortingCriteria, sortingCriteria, onHandleListSort],
  );

  const handleActionSelect = React.useCallback(
    (actions: string[], item: TableListItemProps) => {
      switch (actions[0]) {
        case ActionItems.OPEN:
          onHandleClick(item.uuid);
          break;
        case ActionItems.DUPLICATE:
          onItemDuplicate && onItemDuplicate(item);
          break;
        case ActionItems.DELETE:
          onItemDelete && onItemDelete(item);
          break;
        case ActionItems.ADD_TO_DASHBOARD:
          setModalOpen(true);
          setSelectedChart(item.uuid, item.chart_type || '');
          break;
        default:
          break;
      }
    },
    [onHandleClick, onItemDuplicate, onItemDelete, setModalOpen, setSelectedChart],
  );

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < (items?.length || 0);
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? (items?.length || 0) + 1 : items?.length;
  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = (isFetchingNextPage ? () => {} : fetchNextpage) as unknown as (_1: number, _2: number) => void | Promise<void>;

  return (
    <TableListContainer ref={containerRef}>
      {renderHeader && renderHeader({ onColumnSort: onHandleSort, sortingKey: sortingCriteria.key, sortingType: sortingCriteria.type })}
      {isDataLoading ? (
        <TableSpinner />
      ) : (
        <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={itemCount}>
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              height={inModal ? viewportOffset : viewportHeight}
              width='100%'
              ref={ref}
              itemCount={itemCount}
              itemSize={ROW_HEIGHT}
              onItemsRendered={onItemsRendered}
            >
              {({ index, style }) => {
                if (!isItemLoaded(index)) return <TableSpinner style={style} />;

                return renderListItem({
                  listItemData: items?.[index],
                  style,
                  onClick: onHandleClick,
                  onSelect: handleActionSelect,
                });
              }}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </TableListContainer>
  );
};

export default React.memo(TableList);
