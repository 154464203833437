import styled from '@emotion/styled';
import { theme } from 'common/theme';
import React from 'react';

interface Props {
  percentage: number;
}
const ProgressContainer = styled.div`
  background-color: ${theme.colors.lightGrey.toString()};
  height: 6px;
  width: 100px;
  margin-right: ${theme.space(1)};
`;

const ProgressElement = styled.div<{ percentage: number }>`
  height: 6px;
  width: ${({ percentage }) => percentage}%;
  background-color: ${theme.chart.colorPalette[0].toString()};
`;

const ProgressBar = ({ percentage }: Props) => {
  return (
    <ProgressContainer>
      <ProgressElement percentage={percentage}></ProgressElement>
    </ProgressContainer>
  );
};

export default React.memo(ProgressBar);
