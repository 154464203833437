import { Country } from 'common/types/common';
import { Segment, SegmentGroups, Segments } from 'common/types/segments';
import { getColorForData } from 'modules/charts/utils/colors';

export const mapSegmentsColor = (segments: Segment[]): Array<Segment> => {
  if (!segments?.length) return segments;
  return segments.map((segment, i) => ({ ...segment, color: getColorForData(i) }));
};

export const groupSegmentsByStudyID = (allSegments: Segments[], studyID: string | undefined): Array<Segment> => {
  const filteredByStudyID = allSegments?.filter(({ study_uuid }) => study_uuid === studyID)?.[0];
  return filteredByStudyID?.segments?.map((segment: Segment) => {
    return {
      ...segment,
      study_uuid: filteredByStudyID?.study_uuid,
      updated_at: segment.updated_at ? segment.updated_at.split(' ')[0] : null,
    };
  });
};

export const getAllCountriesSegment = (segments: Segment[]): Array<SegmentGroups> => {
  if (!segments) return [];
  return [
    {
      name: 'All countries',
      segments: segments?.filter(({ country_code }) => !country_code),
    },
  ];
};

export const getGroupedSegments = (countries: Country[], segments: Segment[]): Array<SegmentGroups> => {
  if (!countries?.length || !segments?.length) return [];
  return countries.map(({ code, name }) => {
    return {
      name,
      segments: segments?.filter(({ country_code }) => code === country_code),
    };
  });
};

export const getIdFromUrl = (section = 'study', path = window.location.pathname): string => {
  if (path.includes(section)) {
    const match = path.match('/' + section + '/([^/?]+)');
    return match?.[1] || '';
  }
  return '';
};

export const generateSegmentsColorsMap = (segments: Segments[] | undefined): Record<string, string> => {
  if (!segments?.length) return {};
  const segmentsColorsMap = {} as Record<string, string>;
  const segmentsNames = segments?.flatMap(({ segments }) => segments.map(({ name }) => name));
  segmentsNames?.forEach((name, index) => {
    segmentsColorsMap[name] = getColorForData(index);
  });

  return segmentsColorsMap;
};

export const getDefaultSegmentName = (studySegments: Segment[] | null) => {
  const getUntitledSegmentsCount = (segments: Segment[] | null, prefix: string) => {
    if (!segments) return 0;
    return segments.filter(({ name }) => name.startsWith(prefix)).length;
  };

  const untitledPrefix = 'Untitled segment';
  const untitledSegmentsCount = getUntitledSegmentsCount(studySegments, untitledPrefix);
  const nextSegmentNumber = (untitledSegmentsCount + 1).toString().padStart(2, '0');
  return `${untitledPrefix} ${nextSegmentNumber}`;
};

export const serializeCharacteristics = (characteristics: Record<string, string[]>) => {
  return Object.keys(characteristics)
    .map(key => ({
      name: key,
      values: characteristics[key],
    }))
    .filter(characteristic => characteristic.values && characteristic.values.length > 0);
};

export const findSegmentById = (tabSegments: Segments[] | undefined, id: number) => {
  if (!tabSegments) return null;

  for (const study of tabSegments) {
    const segment = study.segments.find(segment => segment.id === id);
    if (segment) {
      return segment;
    }
  }
  return null;
};
