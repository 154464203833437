import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { theme } from 'common/theme';
import { ThemeProvider } from '@emotion/react';
import LoadingFallback from 'common/components/LoadingFallback';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate, redirect } from 'react-router-dom';
import { MAIN_ROUTES, SEGMENTS_ROUTES, SETTINGS_ROUTES } from 'settings/routes';
import ChartViewLoader from 'views/ChartView/loader';
import Charts from 'views/Charts';
import Dashboards from 'views/Dashboards';
import Invite from 'views/Invite';
import Login from 'views/Login';
import NewPassword from 'views/NewPassword';
import Settings from 'views/Settings';
import Dashboard from 'views/Dashboard';
import Competitors from 'modules/settings/components/Competitors';
import ProfilePage from 'modules/settings/components/ProfilePage';
import WaveReports from 'modules/settings/components/WaveReports';
import Appearance from 'modules/settings/components/Appearance';
import Members from 'modules/settings/components/Members';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthHandle from 'common/components/AuthHandle';
import Segments from 'views/Segments';
import LatanaSegmentsTab from 'modules/segments/components/LatanaSegmentsTab';
import CustomSegmentsTab from 'modules/segments/components/CustomSegmentsTab';

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path={MAIN_ROUTES.ROOT} element={<AuthHandle />}>
        <Route index element={<Navigate to={MAIN_ROUTES.DASHBOARDS} />} />
        <Route index path={MAIN_ROUTES.DASHBOARDS} element={<Dashboards />} />
        <Route path={MAIN_ROUTES.CHARTS} element={<Charts />} />
        <Route path={MAIN_ROUTES.CHART_VIEW} element={<ChartViewLoader />} />
        <Route path={MAIN_ROUTES.CHART_CREATE} element={<ChartViewLoader />} />
        <Route path={MAIN_ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={MAIN_ROUTES.SETTINGS} element={<Settings />}>
          <Route path={SETTINGS_ROUTES.COMPETITORS} element={<Competitors />} />
          <Route path={SETTINGS_ROUTES.PROFILE} element={<ProfilePage />} />
          <Route path={SETTINGS_ROUTES.WAVE_REPORTS} element={<WaveReports />} />
          <Route path={SETTINGS_ROUTES.APPEARANCE} element={<Appearance />} />
          <Route path={SETTINGS_ROUTES.MEMBERS} element={<Members />} />
        </Route>
        <Route path={MAIN_ROUTES.SEGMENTS} element={<Segments />}>
          <Route index element={<LatanaSegmentsTab />} />
          <Route path={SEGMENTS_ROUTES.LATANA_WITH_PARAM} element={<LatanaSegmentsTab />} />
          <Route path={SEGMENTS_ROUTES.CUSTOM_WITH_PARAM} element={<CustomSegmentsTab />} />
        </Route>
      </Route>
      {/* Redirect old chart endpoint with study to the new one */}
      <Route
        path={MAIN_ROUTES.OLD_CHART_VIEW}
        loader={({ params }) => {
          const { chartId } = params;
          return redirect(`/chart/${chartId}`);
        }}
      />

      <Route path={MAIN_ROUTES.NEW_PASSWORD} element={<NewPassword />} />
      <Route path={MAIN_ROUTES.INVITE} element={<Invite />} />
      <Route index path={MAIN_ROUTES.LOGIN} element={<Login />} />
      <Route path={MAIN_ROUTES.INTERNAL_LOGIN} element={<Navigate to={MAIN_ROUTES.ROOT} replace />} />
    </Route>,
  ),
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<LoadingFallback />}>
          <RouterProvider router={router} />
        </Suspense>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
