import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchSelector } from 'common/atoms/common';
import { API_URLS } from 'settings/api';
import { useRecoilValue } from 'recoil';
import { Characteristics } from 'common/types/segments';
import { useRollbar } from '@rollbar/react';
import { LogArgument } from 'rollbar';

export const useGetCharacteristics = (studyId: string): UseQueryResult<Characteristics, unknown> => {
  const { fetchGet } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();

  return useQuery(
    ['characteristics', studyId],
    async () => {
      try {
        return await fetchGet<Characteristics>(API_URLS.SEGMENTS_CHARACTERISTICS(studyId));
      } catch (e: unknown) {
        const error = e as Error & { status?: number };

        if (error?.status === 404) {
          return [];
        }

        rollbar.error('Failed to fetch characteristics.', e as LogArgument);
        return [];
      }
    },
    {
      staleTime: Infinity,
      placeholderData: null,
    },
  );
};
