import * as React from 'react';
import { DataTestId, NO_BRAND } from 'settings/constants';
import { Segment } from 'common/types/segments';
import SegmentsModal from 'modules/charts/components/Filters/SegmentsModal';
import PillCheckbox from 'common/components/PillCheckbox';
import { useSegmentsList } from 'common/hooks/segments';
import { SVGWrapper, SegmentsAsteriskWrapper } from 'common/components/PillCheckbox/styled';
import { LatanaIconComponent } from 'icons';
import Tooltip from 'common/components/Tooltip';
import { BlurbPopup } from 'common/styledComponents/modals';
import { useTranslation } from 'react-i18next';
import Icon from 'common/components/Icon';

interface Props {
  value: string | number;
  label: string;
  // option is not available in the filter context
  disabled?: boolean;
  name: string;
  // will be true when only one value is set for the respective filter
  disabledUnselect?: boolean;
  selected: boolean;
  onChange?: (value: string | number) => void | undefined;
  isSegmentSection: boolean;
  studyId: string;
}
interface SquareButtonProps {
  onHandleClick: React.MouseEventHandler<HTMLDivElement>;
  segment?: Segment;
}

// eslint-disable-next-line react/display-name
const FilterModalOption: React.FC<Props> = ({
  label,
  value,
  name,
  disabled,
  disabledUnselect = false,
  selected,
  onChange,
  isSegmentSection,
  studyId,
}) => {
  const { t } = useTranslation();
  const segments = useSegmentsList(studyId);
  const [showSegmentModal, setShowSegmentModal] = React.useState(false);
  const [selectedSegment, setSelectedSegment] = React.useState<Segment | undefined | null>(null);

  const isAriaDisabled = label === NO_BRAND || isSegmentSection;

  const handleChange = React.useCallback(() => {
    onChange?.(value);
  }, [onChange, value]);

  const getSegment = React.useCallback((segmentId: number) => segments?.find(({ id }) => id === segmentId), [segments]);

  const showInfo = React.useCallback(
    (e: React.MouseEvent, filterId?: string | number) => {
      e.stopPropagation();
      e.preventDefault();

      const segment = getSegment(filterId as number);

      if (segment) {
        setSelectedSegment(segment);
        setShowSegmentModal(true);
      }
    },
    [setSelectedSegment, setShowSegmentModal, getSegment],
  );

  const LatanaSegmentInfoButton = () => (
    <Tooltip
      renderInBody
      leftOffset={130}
      content={
        <BlurbPopup width='195px' title={t('segmentBuilder.genPopTooltip.title')} content={t('segmentBuilder.genPopTooltip.description')} />
      }
    >
      <SVGWrapper>
        <LatanaIconComponent />
      </SVGWrapper>
    </Tooltip>
  );

  const SegmentInfoButton = ({ onHandleClick, segment }: SquareButtonProps) => {
    const isSegmentByLatana = !!segment?.readable_only;
    const innerComponent = isSegmentByLatana ? <LatanaSegmentInfoButton /> : <Icon icon='user-group' color='latana800' size={16} />;
    return (
      <SegmentsAsteriskWrapper id={DataTestId.SEGMENT_INFO_HOVER} onClick={onHandleClick}>
        {segment ? innerComponent : null}
      </SegmentsAsteriskWrapper>
    );
  };

  return (
    <>
      <PillCheckbox
        name={name}
        label={label}
        disabled={disabled}
        selected={selected}
        onChange={handleChange}
        withModalToggle={isSegmentSection}
        isAriaDisabled={isAriaDisabled}
        disabledUnselect={disabledUnselect}
        value={value}
        renderChild={(value: string | number | undefined) => (
          <SegmentInfoButton onHandleClick={e => showInfo(e, value)} segment={getSegment(value as number)} />
        )}
      />
      {showSegmentModal && (
        <SegmentsModal
          studyId={studyId}
          showPanel={showSegmentModal}
          handleClose={() => setShowSegmentModal(false)}
          segmentModalData={selectedSegment}
          LatanaIconComponent={<LatanaSegmentInfoButton />}
        />
      )}
    </>
  );
};

export default FilterModalOption;
