import styled from '@emotion/styled';
import { globalHistory } from '@reach/router';
import { Card, CardTypes, DashboardPayload } from 'common/types/dashboard';
import React, { Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'common/theme';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { useTracking } from 'common/hooks/tracking';
import { useTranslation } from 'react-i18next';
import {
  DashboardMutationFnOptions,
  useCreateDashboard,
  useDeleteDashboard,
  useDuplicateDashboard,
  useGetDashboards,
  useUpdateDashboard,
} from 'common/queries/dashboards';
import CardContainer from 'modules/dashboards/components/CardContainer';
import NoResult from 'common/components/NoResult';
import { CREATE_NEW, DataTestId, LATANA_NAME, ActionItems, NEW } from 'settings/constants';
import { LabelButtonWithIcon } from 'common/styledComponents/buttons';
import InlineEditInput from 'common/components/InlineEditInput';
import { useRecoilValue } from 'recoil';
import { SelectedItemStateProps, useDashboardContext } from 'common/contexts/DashboardContext';
import {
  generateChartCard,
  getCardGridPosition,
  getCardIdByChartId,
  getCardTitle,
  getChartIdFromCard,
  getDefaultCardSizeByChartType,
} from 'modules/dashboards/utils/card';
import { useShowToast } from 'common/hooks/notification';
import { isGuestSelector, permissionsSelector } from 'common/atoms/account';
import ActionMenu from 'common/components/ActionMenu';
import { SelectPopupItemProps } from 'common/types/selectMenu';
import ConfirmationModal from 'common/components/Modal/ConfirmationModal';
import { LatanaText } from 'common/components/LatanaText';
import { WidthProvider, Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './gridStylesOverride.css';
import {
  calculateDashboardCardsLayout,
  extractChartIdsFromDashboardData,
  getCardGridPositionFromLayout,
  setCardsBasedOnLayout,
} from 'utils/dashboards';
import { isNumber } from 'utils/helpers';
import TitleModal from 'modules/dashboards/components/TitleModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const DashboardWrapper = styled.div`
  width: 100%;
`;

const DashboardTitleWrapper = styled.div`
  padding: ${theme.space(0, 1, 1, 0)};
  padding-top: 0;
  margin-bottom: ${theme.space(3)};
  display: flex;
  justify-content: space-between;
`;

const DashboardHeaderActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.space(1)};
`;

const ConfirmationTextWrapper = styled.div`
  margin: ${({ theme }) => theme.v2.space(2.75, 0)};
  text-align: center;
`;

const Dashboard = () => {
  const { dashboardId } = useParams();
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const isCreate = dashboardId === CREATE_NEW;
  const [editMode, setEditMode] = useState(isCreate);
  const [dashboardName, setDashboardName] = useState('');
  const dashboardNameInputRef = React.useRef<HTMLInputElement>(null);
  const { onToggleDashboardModal, dashboardState, updateDashboardState } = useDashboardContext();
  const [editedCards, setEditedCards] = useState<Card[] | undefined>(undefined);
  const { mutate: updateDashboard } = useUpdateDashboard();
  const { mutate: createDashboard } = useCreateDashboard();
  const { mutate: duplicateDashboard } = useDuplicateDashboard();
  const showToast = useShowToast();
  const isGuest = useRecoilValue(isGuestSelector);
  const { internal: isInternalUser } = useRecoilValue(permissionsSelector);
  const { data: dashboardList } = useGetDashboards();
  const { mutate: deleteDashboard, isLoading: isDeleteLoading } = useDeleteDashboard();
  const [showDeleteModal, toggleDeleteModal] = React.useState(false);
  const [showEmptyTitleModal, toggleEmptyTitleModal] = React.useState(false);
  const curLocation = useLocation();
  const [layout, setLayout] = useState<ReactGridLayout.Layout[]>([]);
  const [mdLayout, setMdLayout] = useState<ReactGridLayout.Layout[]>([]);
  const navigate = useNavigate();

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  React.useEffect(() => {
    if (isCreate) {
      dashboardNameInputRef.current?.focus();
    }
  }, [isCreate]);

  const dashboardData = React.useMemo(() => {
    return dashboardList?.find(x => x.uuid === dashboardId);
  }, [dashboardList, dashboardId]);

  const defaultAllowedCards = React.useMemo(() => {
    return dashboardData?.cards.filter(({ components }) =>
      components.body.every(({ type }) => Object.values(CardTypes).includes(type as CardTypes)),
    );
  }, [dashboardData]);

  // Get only text cards, becase they can't be edited/added right now
  const defaultTextCards = React.useMemo(() => {
    return dashboardData?.cards.filter(({ components }) => components.body.every(({ type }) => type === CardTypes.TEXT)) || [];
  }, [dashboardData]);

  useEffect(() => {
    if (dashboardData?.name) {
      setDashboardName(dashboardData?.name);
    }
  }, [dashboardData]);

  useEffect(() => {
    const updatedCards =
      (defaultAllowedCards || []).map(
        x => ({ chartUuid: getChartIdFromCard(x), title: getCardTitle(x), size: x.display_size, uuid: x.uuid } as SelectedItemStateProps),
      ) || [];
    updatedCards && updateDashboardState(updatedCards);
  }, [defaultAllowedCards, updateDashboardState]);

  useEffect(() => {
    const cards: Card[] = [];

    dashboardState.forEach((selectedCard, index) => {
      if (selectedCard.chartUuid) {
        cards.push(
          generateChartCard(
            selectedCard.chartUuid,
            index + 1,
            selectedCard.size || getDefaultCardSizeByChartType(selectedCard.chartType),
            getCardGridPosition(defaultAllowedCards || [], selectedCard.chartUuid),
            getCardIdByChartId(defaultAllowedCards || [], selectedCard.chartUuid),
            selectedCard.title,
          ),
        );
      } else {
        const findTextCard = defaultTextCards.find(x => x.uuid === selectedCard.uuid);
        if (findTextCard) {
          cards.push(findTextCard);
        }
      }
    });

    setEditedCards(cards);
  }, [dashboardState, defaultAllowedCards, defaultTextCards]);

  /** Track event for view page */
  React.useEffect(() => {
    if (dashboardData) {
      trackEvent(TRACKING_EVENTS.VIEW_PAGE, {
        dashboardId: dashboardData?.uuid,
        dashboardChartIds: extractChartIdsFromDashboardData(dashboardData),
      });
    }
  }, [trackEvent, dashboardData]);

  const handleEditSave = useCallback(
    (title?: string) => {
      const dashboardTitle = title || dashboardName;
      // If name is empty
      if (!dashboardTitle.length) {
        toggleEmptyTitleModal(true);
        return;
      }

      const payload = {
        payload: {
          name: dashboardTitle,
          description: '',
          cards:
            editedCards?.map(card => ({
              card_uuid: card.uuid && !card.uuid.startsWith(NEW) ? card.uuid : undefined,
              grid_position: card?.grid_position ? card.grid_position : getCardGridPositionFromLayout(card.uuid, layout),
              sort_order: card.sort_order,
              display_size: card.display_size || 'M',
              content_uuid: card.components.body[0].uuid || '',
              type: card.components.body[0].type,
            })) || [],
        } as DashboardPayload,
        options: {
          toast: { message: `${dashboardTitle} has been saved successfully.`, status: 'success' },
          shouldNavigate: true,
        } as DashboardMutationFnOptions,
      };

      // If dashboard exist, update it
      if (dashboardId && !isCreate) {
        updateDashboard({ ...payload, dashboardId });
      } else {
        // Create new dashboard
        createDashboard(payload);
      }
    },
    [createDashboard, updateDashboard, dashboardId, isCreate, dashboardName, editedCards, layout],
  );

  const ownerLabel = dashboardData?.owner?.name
    ? `${dashboardData.owner?.name}`
    : dashboardData?.owner?.type === LATANA_NAME
    ? 'Latana'
    : null;

  const ownedByLatana = ownerLabel?.toLowerCase() === LATANA_NAME;
  const disableEdit = isGuest || (ownedByLatana && !isInternalUser);

  useEffect(() => {
    !isInternalUser &&
      ownedByLatana &&
      showToast({
        message: 'Dashboards owned by Latana cannot be modified or deleted. Please, use Duplicate action if you would like to make edits.',
        status: 'info',
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownedByLatana, isInternalUser]);

  const handleEditSaveButtonClick = useCallback(() => {
    if (disableEdit) {
      return;
    }

    if (editMode) {
      handleEditSave();
      trackEvent(TRACKING_EVENTS.CLICK_SAVE_DASHBOARD, {
        dashboardId: dashboardId,
      });
    } else {
      showToast({
        message: `You are editing the Dashboard that’s owned by ${ownerLabel}. Any changes you make will be permanently applied after saving the Dashboard.`,
        status: 'warning',
      });
      trackEvent(TRACKING_EVENTS.CLICK_EDIT_DASHBOARD, {
        dashboardId: dashboardId,
      });
    }
    setEditMode(!editMode);
  }, [editMode, handleEditSave, ownerLabel, showToast, disableEdit, dashboardId, trackEvent]);

  const dashboardListActions = useMemo(
    () =>
      [
        { label: t('actionMenu.duplicate'), value: ActionItems.DUPLICATE, icon: 'duplicate' },
        {
          label: { name: t('actionMenu.delete'), color: { active: 'red500', disabled: 'red200' } },
          value: ActionItems.DELETE,
          icon: { name: 'trash-can', color: { active: 'red400', disabled: 'red200' } },
          blurb: t('actionMenu.disabledDeleteBlurb', { title: 'Dashboard' }),
          disable: disableEdit,
        },
      ] as SelectPopupItemProps[],
    [disableEdit, t],
  );

  const handleDashboardDuplication = useCallback(() => {
    if (dashboardId && !isCreate) {
      duplicateDashboard({
        dashboardId: dashboardId,
        options: {
          toast: { message: `${dashboardData?.name} has been duplicated`, status: 'success' },
          shouldNavigate: true,
        },
      });
      trackEvent(TRACKING_EVENTS.DUPLICATE_DASHBOARD_CLICK, {
        dashboardId: dashboardId,
      });
    }
  }, [duplicateDashboard, isCreate, dashboardData, dashboardId, trackEvent]);

  const handleConfirmDelete = useCallback(() => {
    deleteDashboard({
      id: dashboardId as string,
      options: { toast: { message: `${dashboardData?.name} has been deleted`, status: 'success' }, shouldNavigate: true },
    });
    toggleDeleteModal(false);
  }, [deleteDashboard, dashboardData?.name, dashboardId]);

  const handleConfirmTitleModal = useCallback(
    (title: string) => {
      setDashboardName(title);
      handleEditSave(title);
      toggleEmptyTitleModal(false);
    },
    [handleEditSave],
  );

  const handleCloseTitleModal = useCallback(() => {
    toggleEmptyTitleModal(false);
    setEditMode(true);
  }, []);

  const handleDeleteDashboard = useCallback(() => {
    toggleDeleteModal(true);
  }, [toggleDeleteModal]);

  const handleActionSelect = useCallback(
    (actions: string[]) => {
      switch (actions[0]) {
        case ActionItems.DUPLICATE:
          handleDashboardDuplication();
          break;
        case ActionItems.DELETE:
          handleDeleteDashboard();
          break;
        default:
          break;
      }
    },
    [handleDashboardDuplication, handleDeleteDashboard],
  );

  const handleAddCharts = useCallback(() => {
    onToggleDashboardModal();
    setEditMode(true);
    trackEvent(TRACKING_EVENTS.ADD_CHARTS_TO_DASHBOARD_STEP_1_MAIN, {
      dashboardId: dashboardId,
    });
  }, [onToggleDashboardModal, trackEvent, dashboardId]);

  const handleAddChartsTop = useCallback(() => {
    onToggleDashboardModal();
    trackEvent(TRACKING_EVENTS.ADD_CHARTS_TO_DASHBOARD_STEP_1_SECONDARY, {
      dashboardId: dashboardId,
    });
  }, [onToggleDashboardModal, trackEvent, dashboardId]);

  //** HANDLE IF USER TRIES TO LEAVE THE PAGE */
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (editMode) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [editMode]);

  /** CURRENT WORKAROUND TO SHOW ALERT WHEN SWITCHING TO ANOTHER PAGE, REIMPLEMENT THIS AFTER SWITCH TO REACT ROUTER  */
  useEffect(() => {
    return globalHistory.listen(async ({ action, location }) => {
      if ((action === 'PUSH' && !location.pathname.includes('/dashboard/')) || action === 'POP') {
        if (
          editMode &&
          !window.confirm(
            'You have unsaved changes on this page. Leaving now without saving will result in the loss of these changes. Do you want to proceed without saving the dashboard?',
          )
        ) {
          navigate(`${curLocation.pathname}`);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, navigate]);

  const getCardsLayout = useCallback(() => {
    return calculateDashboardCardsLayout(
      editedCards?.map((x, index) => ({
        id: x.uuid || index.toString(),
        gridPosition: x.grid_position || {},
        pushTop: x.components.body[0].type === CardTypes.TEXT && !isNumber(x?.grid_position?.x),
        pushBottom: x.components.body[0].type !== CardTypes.TEXT && !isNumber(x?.grid_position?.y),
        defaultWidth: x.display_size || 'M',
      })) || [],
    );
  }, [editedCards]);

  useEffect(() => {
    setLayout(getCardsLayout());

    setMdLayout(getCardsLayout());
  }, [getCardsLayout]);

  return (
    <>
      <DashboardWrapper>
        <DashboardTitleWrapper>
          <InlineEditInput
            value={dashboardName}
            setValue={setDashboardName}
            ref={dashboardNameInputRef as Ref<HTMLInputElement> | undefined}
            readOnly={disableEdit || !editMode}
          />
          <DashboardHeaderActionsWrapper>
            {!editMode && ownerLabel !== null && <LabelButtonWithIcon icon='avatar' label={`Owner: ${ownerLabel}`} readOnly />}
            {editMode && (
              <LabelButtonWithIcon
                icon='plus-circle'
                label={t('dashboard.addCharts')}
                onClick={handleAddChartsTop}
                data-testid={DataTestId.DASHBOARD_HEADER_ADD_CHART}
              />
            )}
            <LabelButtonWithIcon
              icon={disableEdit ? 'pencil-cross' : editMode ? 'save' : 'pencil'}
              label={editMode ? t('dashboard.saveDashboard') : t('dashboard.editDashboard')}
              onClick={handleEditSaveButtonClick}
              disabled={disableEdit}
              data-testid={DataTestId.DASHBOARD_EDIT_SAVE_BUTTON}
              data-hover={disableEdit ? t('dashboard.cantModify') : undefined}
            />
            {!isCreate && !isGuest && (
              <ActionMenu
                id={DataTestId.LIST_ACTION}
                icon='more'
                items={dashboardListActions}
                onSelect={handleActionSelect}
                stopPropagation
              />
            )}
          </DashboardHeaderActionsWrapper>
        </DashboardTitleWrapper>
        {editedCards?.length ? (
          <ResponsiveReactGridLayout
            className='layout'
            layouts={{ lg: layout, md: mdLayout }}
            rowHeight={450}
            breakpoints={{ lg: 1200, md: 1136 }}
            cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
            compactType={'vertical'}
            resizeHandles={['se']}
            isResizable={editMode}
            isDraggable={editMode}
            isBounded={true}
            onDragStop={layout => {
              setEditedCards(setCardsBasedOnLayout(layout, editedCards));
            }}
            onResizeStop={layout => {
              setEditedCards(setCardsBasedOnLayout(layout, editedCards));
            }}
          >
            {editedCards.map(({ uuid, display_size, components }, index) => (
              <CardContainer key={uuid || index} cardComponents={components} displaySize={display_size} editMode={editMode} uuid={uuid} />
            ))}
          </ResponsiveReactGridLayout>
        ) : (
          <>
            {isCreate || (dashboardData && editedCards && editedCards.length === 0) ? (
              <NoResult
                title={t('dashboard.emptyTitle')}
                subtitle={t('dashboard.emptySubtitle')}
                actions={disableEdit ? undefined : [{ label: t('dashboard.addCharts'), handleClick: handleAddCharts }]}
                image='dashboard'
              />
            ) : null}
          </>
        )}
      </DashboardWrapper>
      {/* TODO: Try something common for Charts and dashboards */}
      <ConfirmationModal
        isLoading={isDeleteLoading}
        title={t('chart.deleteChart')}
        open={showDeleteModal}
        onCancel={() => toggleDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      >
        <ConfirmationTextWrapper>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.headingv2', { title: dashboardData?.name || '' })}
          </LatanaText>
          <LatanaText variant='L1' color='gray900'>
            {t('segmentBuilder.deleteModal.subheadingv2')}
          </LatanaText>
        </ConfirmationTextWrapper>
      </ConfirmationModal>
      <TitleModal
        open={showEmptyTitleModal}
        onCancel={handleCloseTitleModal}
        onConfirm={handleConfirmTitleModal}
        title={t('dashboard.titleMissing')}
        confirmButtonTitle={t('dashboard.saveDashboard')}
      />
    </>
  );
};

export default React.memo(Dashboard);
