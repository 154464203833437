import { Serie } from '@nivo/line';
import { ChartDataPoint } from 'common/types/chart';

export const getGroupIdWithHighestValueInLastWave = (data: ChartDataPoint[], keys: string[]): string => {
  const lastKey = keys[keys.length - 1];
  const maxItem = data.reduce((prev, current) => (prev[lastKey as 'value_0'] > current[lastKey as 'value_0'] ? prev : current));
  return maxItem.groupId?.toString();
};

export const getMinAndMaxForLineChart = (lineData: Serie[], moeStatus: boolean): number[] => {
  const values = lineData.reduce((acc, line) => {
    line?.data?.forEach(item => {
      if (item.y) {
        // If MOE is not enabled, just take the y value
        if (!moeStatus) {
          acc.push(item.y as number);
        } else {
          // MOE is enabled, take min and max value
          acc.push(+item.y + item.moe);
          acc.push(+item.y - item.moe);
        }
      }
    });
    return acc;
  }, [] as number[]);

  const maxValue = Math.max.apply(null, values) + 10;
  const minValue = Math.min.apply(null, values) - 10;

  return [Math.min(Math.round(maxValue / 10) * 10, 100), Math.max(Math.round(minValue / 10) * 10, 0)];
};
