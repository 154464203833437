import * as React from 'react';
import {
  ChartViews,
  RawChart,
  RawChartSchema,
  RadarChartData,
  SelectedSort,
  MOEStatus,
  ChartFields,
  ChartFormData,
  ChartType,
} from 'common/types/chart';
import { API_URLS } from 'settings/api';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTracking } from 'common/hooks/tracking';
import { TRACKING_EVENTS } from 'common/types/tracking';
import { FilterNames } from 'common/types/filters';
import { alertBoxAtom, chartErrorAtom } from 'common/atoms/ui';
import { ToastAtom } from 'common/atoms/notifications';
import { fetchSelector } from 'common/atoms/common';
import { adjustChartConfig, mapChartPayload, prefillChartData, setCurrentView, isChartWithConfig, generateNewChart } from 'utils/chart';
import { getChartParams, setChartDimensions, getMultipleValueFields } from 'modules/charts/utils/chartData';
import { FILTER_NAMES } from 'settings/constants';
import { FetchOptions } from 'common/types/common';
import { isNotUndefined, processError, uuidGenerator } from 'utils/helpers';
import { useRollbar } from '@rollbar/react';
import { ToastPayload } from 'common/types/notification';
import { LogArgument } from 'rollbar';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useGetSortedRestrictions } from './filters';
import { useCallback, useMemo } from 'react';
import { useKpis } from './kpis';
import { mapWave } from 'utils/wave';
import { useIsOldSurveyBrandAwarenessEnabled, useIsPopulationNumbersEnabled } from './features';
import { currentChartAtom } from 'common/atoms/charts';
import { adjustMatrixData } from 'modules/charts/components/MatrixChart/utils';
import { useSegmentsList } from './segments';

export const useDuplicateChart = (): {
  loading: boolean;
  duplicateChart: (args_0: ChartFormData, args_1: string | undefined) => Promise<RawChartSchema | undefined>;
} => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const rollbar = useRollbar();
  const queryClient = useQueryClient();
  const duplicateChart = useRecoilCallback(
    ({ snapshot, set }) =>
      async (chartData: ChartFormData, studyId: string | undefined) => {
        setLoading(true);
        const { fetchPost } = await snapshot.getPromise(fetchSelector);
        if (!chartData.description) chartData.description = null;
        const {
          name,
          description,
          current_view,
          chart_type,
          display_reference_average,
          dynamic_waves,
          display_additional_brand_awareness,
          chartNumbersType,
          secondDimensionItems,
        } = chartData;

        const chartParams = getChartParams(chartData, chart_type);
        const chartWithConfig = isChartWithConfig(chart_type);
        const { firstDimension, secondDimension } = setChartDimensions(chartData, FILTER_NAMES, getMultipleValueFields);

        const chartPayload = {
          name,
          description,
          chart_type,
          study_uuid: studyId,
          dynamic_waves,
          display_reference_average: !!display_reference_average,
          display_additional_brand_awareness: !!display_additional_brand_awareness,
          ...(chartWithConfig && {
            current_view: setCurrentView(current_view, [firstDimension, secondDimension]),
            configuration: {
              first_dimension: firstDimension,
              second_dimension: secondDimension,
              y_label: ChartFields.MEAN,
              x_label: ChartFields.BRAND,
              chart_value_representation: chartNumbersType,
              ...(secondDimensionItems && { second_dimension_items: secondDimensionItems }), // Used only for Matrix chart
            },
          }),
          ...chartParams,
        } as RawChartSchema;
        try {
          const newChart = await fetchPost<RawChartSchema>(API_URLS.CHARTS, chartPayload);
          await queryClient.invalidateQueries(['paginatedCharts']);
          setLoading(false);

          set(ToastAtom, toasts => [
            ...toasts,
            { id: uuidGenerator(), message: t('toast.duplicated', { title: 'Chart' }), status: 'success' } as ToastPayload,
          ]);
          return newChart;
        } catch (e: unknown) {
          setLoading(false);
          set(ToastAtom, toasts => [
            ...toasts,
            {
              id: uuidGenerator(),
              message: t('toast.genericError'),
              status: 'error',
            } as ToastPayload,
          ]);
          rollbar.info('Chart duplication failed', e as LogArgument);
        }
      },
    [],
  );

  return React.useMemo(
    () => ({
      loading,
      duplicateChart,
    }),
    [loading, duplicateChart],
  );
};

export const useSaveChart = (): {
  loading: boolean;
  saveChart: (
    args_0: ChartFormData,
    args_1: {
      url: string;
      fetchMethod: <T>(url: string, body: FetchOptions['body']) => Promise<T>;
    },
  ) => Promise<RawChartSchema | undefined>;
} => {
  const [loading, setLoading] = React.useState(false);
  const rollbar = useRollbar();

  const saveChart = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        chart: ChartFormData,
        { url, fetchMethod }: { url: string; fetchMethod: <T>(url: string, body: FetchOptions['body']) => Promise<T> },
      ) => {
        const errorKey = 'saveChart';
        const alertBox = await snapshot.getPromise(alertBoxAtom);
        const {
          name,
          description,
          current_view,
          chart_type,
          display_reference_average,
          dynamic_waves,
          study_uuid,
          display_additional_brand_awareness,
          chartNumbersType,
          secondDimensionItems,
        } = chart;

        const chartParams = getChartParams(chart, chart_type);
        const chartWithConfig = isChartWithConfig(chart_type);
        const { firstDimension, secondDimension } = setChartDimensions(chart, FILTER_NAMES, getMultipleValueFields);

        setLoading(true);

        const chartPayload = {
          name,
          description,
          chart_type,
          study_uuid: study_uuid,
          dynamic_waves,
          display_reference_average: !!display_reference_average,
          display_additional_brand_awareness: !!display_additional_brand_awareness,
          ...(chartWithConfig && {
            current_view: setCurrentView(current_view, [firstDimension, secondDimension]),
            configuration: {
              first_dimension: firstDimension,
              second_dimension: secondDimension,
              y_label: ChartFields.MEAN,
              x_label: ChartFields.BRAND,
              chart_value_representation: chartNumbersType,
              ...(secondDimensionItems && { second_dimension_items: secondDimensionItems }), // Used only for Matrix chart
            },
          }),
          ...chartParams,
        } as RawChartSchema;

        try {
          let data = await fetchMethod<RawChartSchema>(url, chartPayload);

          set(chartErrorAtom, null);

          if (alertBox?.id === errorKey) set(alertBoxAtom, { id: '', show: false });

          if (data.description === null) data = { ...data, description: '' };

          return data;
        } catch (e: unknown) {
          processError(e, rollbar.error, 'Save chart error');

          const error = e as Error & { errors?: Record<string, string>; status?: number };
          const errors = error.errors;

          if (errors?.['name'] || errors?.['description']) {
            const errorName = errors?.['name']?.[0] || '';
            const errorDescription = errors?.['description']?.[0] || '';
            const errorMessage = errorName.concat(errorDescription);
            set(chartErrorAtom, errorMessage);
          } else {
            set(alertBoxAtom, { id: errorKey, show: true });
          }

          rollbar.error(`useSaveChart error ${error.status}`, error);
        } finally {
          setLoading(false);
        }
      },
    [],
  );

  return React.useMemo(
    () => ({
      loading,
      saveChart,
    }),
    [loading, saveChart],
  );
};

export const useDeleteChart = (): {
  loading: boolean;
  deleteChart: (chartId: string, options?: { inIndexPage?: boolean; chartName?: string; chartStudyId: string }) => Promise<void>;
} => {
  const [loading, setLoading] = React.useState(false);
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  const rollbar = useRollbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteChart = useRecoilCallback(
    ({ snapshot, set }) =>
      async (chartId: string, options?: { inIndexPage?: boolean; chartName?: string; chartStudyId: string }) => {
        const { fetchDelete } = await snapshot.getPromise(fetchSelector);

        setLoading(true);

        try {
          await fetchDelete(API_URLS.CHART(chartId));
          await queryClient.refetchQueries({ queryKey: ['paginatedCharts'], type: 'active' });
          trackEvent(TRACKING_EVENTS.DELETED_CHART);

          navigate('/charts');

          set(ToastAtom, toasts => [
            ...toasts,
            {
              id: uuidGenerator(),
              message: t('toast.deleted', { title: options?.chartName || 'Chart' }),
              status: 'success',
            } as ToastPayload,
          ]);
        } catch (e) {
          let toastMessage = t('toast.genericError');
          const error = e as Error & { errors: string };
          if (error?.errors) {
            rollbar.info('Delete chart error', error.errors);
            toastMessage = error.errors;
          } else {
            rollbar.error('Delete chart error', error);
          }
          set(ToastAtom, toasts => [
            ...toasts,
            {
              id: uuidGenerator(),
              message: toastMessage,
              status: 'error',
            } as ToastPayload,
          ]);
        } finally {
          setLoading(false);
        }
      },
    [trackEvent, navigate],
  );

  return React.useMemo(() => {
    return {
      loading,
      deleteChart,
    };
  }, [loading, deleteChart]);
};

export const useUpdateChartView = (): {
  updateChartCurrentView: (
    chartData: RawChartSchema,
    view: ChartViews,
    dimension: FilterNames,
    studyId: string,
  ) => Promise<Error | undefined>;
} => {
  const { fetchPut } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();

  const updateChartCurrentView = React.useCallback(
    async (chartData: RawChartSchema, view: ChartViews, dimension: FilterNames) => {
      if (!chartData.uuid) {
        return new Error('Chart ID is missing in updateChartCurrentView');
      }

      let configuration = chartData.configuration;

      if (chartData && chartData.configuration?.first_dimension !== dimension) {
        const firstDimension = dimension;
        const secondDimension = chartData.configuration?.first_dimension;

        configuration = {
          ...configuration,
          first_dimension: firstDimension,
          second_dimension: secondDimension,
        };
      }

      const url = API_URLS.CHART(chartData.uuid);

      try {
        await fetchPut<RawChartSchema>(url, { ...chartData, current_view: view, configuration });
      } catch (e: unknown) {
        rollbar.error('Update chart view error', e as LogArgument);
      }
    },
    [fetchPut, rollbar],
  );

  return React.useMemo(
    () => ({
      updateChartCurrentView,
    }),
    [updateChartCurrentView],
  );
};

export const useFetchChart = (): {
  chart: RawChart | undefined;
  loading: boolean;
  fetchChart: (chartId: string) => Promise<Error | undefined>;
} => {
  const [chart, setChart] = React.useState<RawChart>();
  const [loading, setLoading] = React.useState(false);

  const { fetchGet } = useRecoilValue(fetchSelector);
  const setChartAtomState = useSetRecoilState(currentChartAtom);
  const rollbar = useRollbar();

  const fetchChart = React.useCallback(
    async (chartId: string) => {
      try {
        if (!chartId) {
          return new Error('Chart ID is missing in useFetchChart');
        }
        setLoading(true);
        let chartData = await fetchGet<RawChart>(`${API_URLS.CHART(chartId)}`);
        chartData.data = Array.isArray(chartData.data) ? chartData.data : [];

        let updatedChart = { ...chartData };

        // For Matrix chart we want to combine Brand Perception Good and Bad into single KPI option
        if (chartData.chart.chart_type === ChartType.MATRIX) {
          updatedChart = adjustMatrixData(chartData);
        }
        const newConfig = adjustChartConfig(updatedChart.chart);
        const newChart = { ...updatedChart.chart, configuration: newConfig };
        const newChartData = { ...updatedChart, chart: newChart };

        chartData = mapChartPayload(prefillChartData(newChartData));
        setChart(chartData);
        setChartAtomState(chartData);
      } catch (e: unknown) {
        const error = e as Error & { status: number };
        rollbar.error('Fetch chart error', error);
      } finally {
        setLoading(false);
      }
    },
    [fetchGet, rollbar, setChartAtomState],
  );

  return React.useMemo(() => {
    return {
      chart,
      loading: loading,
      fetchChart,
    };
  }, [chart, fetchChart, loading]);
};

export const useFetchChartCSV = (): ((chartId: string) => Promise<unknown>) => {
  const { fetchPost } = useRecoilValue(fetchSelector);
  const rollbar = useRollbar();

  const fetchChartCSV = async (chartId: string) => {
    try {
      if (!isNotUndefined(chartId)) {
        throw new Error('Chart ID is missing in useFetchChartCSV');
      }

      const result = await fetchPost(API_URLS.EXPORT_CHART_CSV(chartId));
      return result;
    } catch (e: unknown) {
      const error = e as Error & { status: number };
      rollbar.error('Fetch chart CSV error', error);
    }
  };

  return fetchChartCSV;
};

export const useVisibleRadarData = (data: RadarChartData[]): RadarChartData[] => {
  const visibleData = React.useMemo(
    () =>
      data
        .filter(item => !item.disabled)
        .reduce((memo, current) => {
          const updatedData = {} as RadarChartData;
          Object.keys(current).forEach(key => {
            if (key !== 'disabled') {
              updatedData[key] = current[key];
            }
          });
          memo.push(updatedData);
          return memo;
        }, [] as RadarChartData[]),
    [data],
  );
  return visibleData;
};

export const useUpdateChartSort = (): {
  updateChartCurrentSort: (chartData: RawChartSchema | undefined, currentSort: SelectedSort) => Promise<void>;
} => {
  const rollbar = useRollbar();
  const { fetchPut } = useRecoilValue(fetchSelector);
  const updateChartCurrentSort = React.useCallback(
    async (chartData: RawChartSchema | undefined, currentSort: SelectedSort) => {
      if (!chartData?.uuid) {
        throw new Error('Chart ID is missing in useUpdateChartSort');
      }

      try {
        await fetchPut<RawChartSchema>(API_URLS.CHART(chartData.uuid), { ...chartData, current_sort: currentSort });
      } catch (e: unknown) {
        rollbar.info('Update chart sort', e as LogArgument);
      }
    },
    [fetchPut, rollbar],
  );

  return React.useMemo(
    () => ({
      updateChartCurrentSort,
    }),
    [updateChartCurrentSort],
  );
};

export const useUpdateMoeStatus = (): {
  updateChartMoeStatus: (chartData: RawChartSchema, moeStatus: MOEStatus) => Promise<void>;
} => {
  const rollbar = useRollbar();
  const { fetchPatch } = useRecoilValue(fetchSelector);

  const updateChartMoeStatus = React.useCallback(
    async (chartData: RawChartSchema, moeStatus: MOEStatus) => {
      if (!chartData.uuid) {
        throw new Error('Chart ID is missing in useUpdateMoeStatus');
      }

      try {
        await fetchPatch<RawChartSchema>(API_URLS.CHART(chartData.uuid), { margin_of_error_status: moeStatus });
      } catch (e: unknown) {
        processError(e, rollbar.error, 'Update chart MOE status error');
      }
    },
    [fetchPatch, rollbar],
  );

  return React.useMemo(
    () => ({
      updateChartMoeStatus,
    }),
    [updateChartMoeStatus],
  );
};

export const useGetNewChartData = (
  studyId: string,
): {
  getNewChartData: (studyId: string, chartType: ChartType) => RawChart;
} => {
  const kpis = useKpis(studyId);
  const segments = useSegmentsList(studyId);
  const allRestrictions = useGetSortedRestrictions();
  const isOldSurveyBrandAwarenessEnabled = useIsOldSurveyBrandAwarenessEnabled(studyId);
  const isPercentageAbsoluteSwitchEnabled = useIsPopulationNumbersEnabled(studyId);

  const getNewChartData = useCallback(
    (studyId: string, chartType: ChartType) => {
      const restrictions = allRestrictions.find(x => x.study_uuid === studyId);
      const waves = restrictions?.waves.map(mapWave) || [];
      const brands = restrictions?.brands || [];
      return generateNewChart(
        studyId,
        chartType,
        kpis,
        waves,
        brands,
        segments,
        isOldSurveyBrandAwarenessEnabled,
        isPercentageAbsoluteSwitchEnabled,
      );
    },
    [allRestrictions, kpis, isOldSurveyBrandAwarenessEnabled, segments, isPercentageAbsoluteSwitchEnabled],
  );

  return useMemo(() => {
    return {
      getNewChartData,
    };
  }, [getNewChartData]);
};

export const useUpdateChartValueRepresentation = (): {
  updateChartValueRepresentation: (chartData: RawChartSchema | undefined, chartValueRepresentation: string) => Promise<void>;
} => {
  const rollbar = useRollbar();
  const { fetchPut } = useRecoilValue(fetchSelector);

  const updateChartValueRepresentation = React.useCallback(
    async (chartData: RawChartSchema | undefined, chartValueRepresentation: string) => {
      if (!chartData?.uuid) {
        throw new Error('Chart ID is missing in useUpdateChartValueRepresentation');
      }

      try {
        await fetchPut<RawChartSchema>(API_URLS.CHART(chartData.uuid), {
          ...chartData,
          configuration: { ...chartData.configuration, chart_value_representation: chartValueRepresentation },
        });
      } catch (e: unknown) {
        rollbar.info('Update chart value representation', e as LogArgument);
      }
    },
    [fetchPut, rollbar],
  );

  return React.useMemo(
    () => ({
      updateChartValueRepresentation,
    }),
    [updateChartValueRepresentation],
  );
};

export const useUpdateMatrixChartChangeOverTime = (): {
  updateMatrixChangeOverTime: (chartData: RawChartSchema | undefined, changeOverTime: boolean) => Promise<void>;
} => {
  const rollbar = useRollbar();
  const { fetchPut } = useRecoilValue(fetchSelector);

  const updateMatrixChangeOverTime = React.useCallback(
    async (chartData: RawChartSchema | undefined, changeOverTime: boolean) => {
      if (!chartData?.uuid) {
        throw new Error('Chart ID is missing in useUpdateMatrixChartChangeOverTime');
      }

      try {
        // Destructure current_view from chartData and create a new object
        const { current_view: _current_view, ...restChartData } = chartData;

        // Construct the payload without current_view if it is null or undefined
        const payload = {
          ...restChartData,
          configuration: { ...restChartData.configuration, change_over_time: changeOverTime },
        };

        // Send the request
        await fetchPut<RawChartSchema>(API_URLS.CHART(chartData.uuid), payload);
      } catch (e: unknown) {
        rollbar.info('Update matrix chart change over time', e as LogArgument);
      }
    },
    [fetchPut, rollbar],
  );

  return React.useMemo(
    () => ({
      updateMatrixChangeOverTime,
    }),
    [updateMatrixChangeOverTime],
  );
};
