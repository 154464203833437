import styled from '@emotion/styled';
import { tooltipOnHover } from 'common/styledComponents/modals';
import { Text } from 'common/styledComponents/typography';
import { theme } from 'common/theme';
import Color from 'common/theme/color';

const FilterLabel = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FilterOptions = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.space(0, -1)};
  overflow: hidden;
  position: relative;
  max-height: 56px;
  &:not([data-counter='0']):after {
    content: attr(data-counter);
    display: block;
    color: ${theme.colors.turqoise.toString()};
    position: absolute;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: ${theme.space(0, 0, 0, 2)};
    position: absolute;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) ${theme.space(1.5)});
    bottom: 0;
    right: 0;
  }

  &[hidden-counter='true']:after {
    visibility: hidden;
  }
`;

const FilterOption = styled(Text)`
  display: inline-block;
  padding: ${({ theme }) => theme.space(1, 1, 0)};
  white-space: nowrap;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  font-size: ${({ theme }) => theme.text.variants.body2.fontSize};
`;

const Container = styled.li`
  display: block;
  padding: 10px 0px;
  border: 1px solid #ebebeb;
  min-width: 165px;
  max-width: 440px;
  color: black;
  font-weight: 600;
  font-size: 14px;
  border-left: none;
  position: relative;
  cursor: pointer;
  min-height: 80px;
  flex-grow: 0.1;
  padding: ${({ theme }) => theme.space(2)};
  &:first-of-type {
    border-left: 1px solid #ebebeb;
  }
  label {
    word-wrap: break-word;
    opacity: 0.25;
  }
  &[aria-disabled='true'] {
    cursor: default;
    pointer-events: none;
  }
  &[aria-selected='true'] {
    cursor: default;
    &:after {
      content: '';
      height: 2px;
      background: black;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &[aria-invalid='true'] {
    border-color: ${theme.colors.wine.toString()};
    border-left: 1px solid ${theme.colors.wine.toString()};
    &[aria-selected='true']:after {
      background: ${theme.colors.wine.toString()};
    }
  }
  &[aria-invalid='true'] + &[aria-invalid='true'] {
    border-left: none;
  }
`;

const ColorIndicator = styled.span<{
  color: Color;
}>`
  display: inline-block;
  width: ${theme.space(1)};
  height: ${theme.space(1)};
  margin: ${theme.space(0, 1, 0, 0)};
  border-radius: 50%;
  background: ${props => props.color?.toString()};
`;

const ModalContainer = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: text;
`;

const QuestionWrapper = styled.div``;

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OptionWrapper = styled.div`
  border-radius: ${theme.v2.borderRadius * 7.5}px;
  border: 2px solid ${theme.v2.colours.gray300};
  padding: ${theme.v2.space(0, 0.75)};
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.v2.space(1)};
  margin-top: ${theme.v2.space(1)};
`;

const InfoButtonWrapper = styled.div`
  position: relative;
  ${tooltipOnHover()}
`;

export {
  FilterLabel,
  FilterOptions,
  FilterOption,
  Container,
  ColorIndicator,
  ModalContainer,
  QuestionWrapper,
  OptionWrapper,
  OptionsContainer,
  BottomContainer,
  InfoButtonWrapper,
};
