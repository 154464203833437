import React from 'react';
import styled from '@emotion/styled';
import { Text } from 'common/styledComponents/typography';
import { theme } from 'common/theme';

interface Props {
  name: string;
  label?: string;
  ref?: React.Ref<HTMLInputElement>;
  type?: 'text' | 'password' | 'email';
  disabled?: boolean;
  marginBottom?: boolean;
  variant?: 'default' | 'gray' | 'clear' | 'smaller';
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  autoFocus?: boolean;
  value?: string;
  maxLength?: number;
}

interface InputProps {
  marginBottom?: boolean;
}

const InputField = styled.input<InputProps>`
  height: 48px;
  width: 320px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white01.toString()};
  border: none;
  padding: ${({ theme }) => theme.spacing.unit * 2}px;
  color: ${({ theme }) => theme.colors.white.toString()};
  margin-bottom: ${({ theme, marginBottom }) => (marginBottom ? `${theme.spacing.unit * 4}px` : '0')};

  &:focus {
    outline: none;
  }

  &[data-variant='gray'] {
    background: ${theme.colors.black05.toString()};
    color: ${theme.colors.black.toString()};

    &::placeholder {
      color: ${({ theme }) => theme.colors.black50.toString()};
    }
  }

  &[data-variant='smaller'] {
    height: 30px;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.colors.jupiter.toString()};
    width: 100%;
    background: ${theme.colors.cascadingWhite.toString()};
    color: ${theme.colors.black.toString()};

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray400.toString()};
    }
  }

  &[data-variant='clear'] {
    background: none;
    margin: 0;
    padding: 0px ${({ theme }) => theme.spacing.unit * 2}px;
    height: 36px;
    width: auto;
    color: ${({ theme }) => theme.colors.black.toString()};
    line-height: ${({ theme }) => theme.text.lineHeight};
    font-size: ${({ theme }) => theme.text.variants.body1.fontSize};
    font-weight: ${({ theme }) => theme.text.variants.body1.fontWeight};

    border-radius: 8px;

    &[type='password'] {
      letter-spacing: 0.6em;
      font-weight: 600;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.black25.toString()};
    }

    &:enabled {
      &:hover {
        background: ${theme.colors.black05.toString()};
      }

      &:focus {
        background: ${theme.colors.black05.toString()};
      }
    }
  }

  &::placeholder {
    font-family: ${({ theme }) => theme.text.fontFamily};
    font-weight: ${({ theme }) => theme.text.variants.body2.fontWeight};
    color: ${({ theme }) => theme.colors.white.toString()};
  }
`;

const TextInput: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      autoFocus,
      label,
      placeholder,
      name,
      type = 'text',
      required,
      marginBottom = true,
      disabled = false,
      variant = 'default',
      onChange,
      value,
      maxLength,
    },
    ref,
  ) => {
    return (
      <>
        {variant !== 'clear' && label && (
          <Text variant='body2' color={variant === 'gray' ? 'black' : 'white'} marginBottom>
            {label}
          </Text>
        )}
        <InputField
          autoFocus={autoFocus}
          placeholder={placeholder}
          data-variant={variant}
          type={type}
          name={name}
          ref={ref}
          marginBottom={marginBottom}
          disabled={disabled}
          onChange={onChange}
          required={required}
          value={value}
          {...(maxLength ? { maxLength: maxLength } : {})}
        />
      </>
    );
  },
);

TextInput.displayName = 'TextInput';
export default React.memo(TextInput);
