import React from 'react';
import { TableBodyCellContainer, TableDataItem } from './styled';

interface Props {
  children: React.ReactNode;
  align: 'left' | 'center' | 'right';
}

const TableDataCell = ({ children, align }: Props) => {
  return (
    <>
      <TableDataItem>
        <TableBodyCellContainer align={align}>{children}</TableBodyCellContainer>
      </TableDataItem>
    </>
  );
};

export default React.memo(TableDataCell);
