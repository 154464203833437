import styled from '@emotion/styled';
import { scrollbarStyles } from 'common/styledComponents/mixins';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white.toString()};
  height: 100vh;
  max-height: 100vh;
  opacity: 1;
  z-index: 20;
  transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
  visibility: visible;
  padding: ${({ theme }) => theme.spacing.unit * 7}px 0 0;
  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  margin: ${({ theme }) => `0px 0px ${theme.spacing.unit * 10}px ${theme.sidebar.width - 16}px`};
`;

const SegmentsDynamicContent = styled.div`
  display: flex;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  max-height: 100%;
  overflow: auto;
  width: 720px;
  max-width: 720px;
  margin: ${({ theme }) => theme.space(0, 0, 0, 8)};

  // Apply the scrollbar styles
  ${scrollbarStyles()}
`;

const ContentInner = styled.div`
  padding: ${({ theme }) => theme.space(0, 8, 0, 0)};
`;

export { Modal, Container, SegmentsDynamicContent, ContentWrapper, ContentInner };
