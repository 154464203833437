import styled from '@emotion/styled';
import React from 'react';
import { coloursPalette, greyPalette } from 'theme/colours';

const OuterCircle = styled.circle``;

interface Props {
  index: number;
  cx: number;
  cy: number;
  innerFill: string;
  isHovered?: boolean;
  chartUuid?: string;
}

const CircleElement = ({ cx, cy, innerFill, isHovered, index, chartUuid }: Props) => {
  // Fixed diameter and radius
  const diameter = 24;
  const radius = diameter / 2;

  const outerRadius = radius;
  const innerRadius = radius / 2; // Inner radius can be adjusted as needed

  // Fixed shadow properties
  const shadowOffsetX = 0;
  const shadowOffsetY = 2;
  const shadowBlur = 4;

  // Styles for the outer and inner circles
  const outerCircleStyle = {
    fill: greyPalette.gray0,
    stroke: isHovered ? coloursPalette.gray50 : 'none',
    strokeWidth: 1,
    transform: isHovered ? 'scale(1.2)' : 'scale(1)',
    transformOrigin: `${cx}px ${cy}px`,
    transition: 'transform 0.3s ease',
  };

  return (
    <g id={`MatrixCircle-${chartUuid}-${index}`}>
      {/* Define the drop shadow filter */}
      <defs>
        <filter id='drop-shadow' x='-50%' y='-50%' width='200%' height='200%'>
          <feDropShadow dx={shadowOffsetX} dy={shadowOffsetY} stdDeviation={shadowBlur} />
        </filter>
      </defs>

      {/* Outer circle with drop shadow */}
      <OuterCircle
        cx={cx}
        cy={cy}
        r={outerRadius}
        fill='white'
        stroke='none'
        strokeWidth='1'
        filter='url(#drop-shadow)'
        style={outerCircleStyle}
      />

      {/* Inner circle */}
      <circle cx={cx} cy={cy} r={innerRadius} fill={innerFill} stroke='none' strokeWidth='1' />
    </g>
  );
};

export default React.memo(CircleElement);
