import * as React from 'react';
import styled from '@emotion/styled';
import NewPasswordFom from './NewPasswordForm';
import { useNewPassword } from 'common/hooks/account';
import { useParams } from 'react-router-dom';

const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.darkPurple.toString()};
`;

const Container = styled.div`
  width: 320px;
`;

export interface LoginFormData {
  email: string;
  password: string;
}

const Login = () => {
  const { token } = useParams();
  const { updatePassword, error, message, busy } = useNewPassword(token);
  return (
    <Cover>
      <Container>
        <NewPasswordFom busy={busy} error={error} message={message} onSubmit={updatePassword} />
      </Container>
    </Cover>
  );
};

export default React.memo(Login);
