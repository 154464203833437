import { LoadingButton } from 'common/components/Form/Button';
import { LatanaText } from 'common/components/LatanaText';
import Modal from 'common/components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BottomContainer, ModalContainer, OptionWrapper, OptionsContainer, QuestionWrapper } from './styled';
import { useKpis } from 'common/hooks/kpis';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  kpiList: string[];
  studyId: string;
}

const KpiQuestionModal: React.FC<Props> = ({ isOpen, onClose, kpiList, studyId }) => {
  const { t } = useTranslation();
  const allKpis = useKpis(studyId);

  // Figure out what to show in kpi question modal during chart creation and update phase.
  const selectedKpis = React.useMemo(() => {
    return allKpis
      .filter(kpi => {
        // chart creation: show all kpis in the modal
        // chart update: filter kpis on the basis of highlighted kpi options
        if (!kpiList.length) return true;
        return kpiList.some(kpiLabel => kpiLabel.split('_')[0] === kpi.kpi_identifier);
      })
      .map(kpi => {
        return {
          ...kpi,
          question_options: kpi.question_options?.filter(option => {
            // chart creation: show all the options under kpi group
            // chart update: filter options on the basis of highlighted kpi options
            if (!kpiList.length) return true;
            return kpiList.some(selectedOption => selectedOption === option.option_identifier);
          }),
        };
      });
  }, [allKpis, kpiList]);

  return (
    <Modal clickOutsideClose isOpen={isOpen} showCloseButton={false} handleClose={onClose} customWidth={560}>
      <ModalContainer>
        {selectedKpis.map(({ kpi_label, question_text, question_options }) => (
          <QuestionWrapper key={kpi_label}>
            <LatanaText variant='T3' color='gray900'>
              {kpi_label}
            </LatanaText>
            <LatanaText variant='B2' color='gray900'>
              {question_text}
            </LatanaText>
            <OptionsContainer>
              {question_options?.map(({ option_label }) => (
                <OptionWrapper key={option_label}>
                  <LatanaText variant='L2'>{option_label}</LatanaText>
                </OptionWrapper>
              ))}
            </OptionsContainer>
          </QuestionWrapper>
        ))}
      </ModalContainer>
      <BottomContainer>
        <LoadingButton color='turqoise' loading={false} onClick={onClose}>
          {t('common.gotIt')}
        </LoadingButton>
      </BottomContainer>
    </Modal>
  );
};

export default React.memo(KpiQuestionModal);
