import React, { useMemo } from 'react';
import { API_URLS } from 'settings/api';
import { useRecoilCallback } from 'recoil';
import { mapBrandByGlobalization, getFiltersConfig, sortRestrictionsBrandsByPrimaryBrands, composeRestrictions } from 'utils/filters';
import { fetchSelector } from 'common/atoms/common';
import { FetchFilters, FiltersPayload, FiltersState, Restrictions } from 'common/types/filters';
import { useRollbar } from '@rollbar/react';
import { sortBrandsArrayByOtherBrandsOrder } from 'utils/brands';
import { useGetAllRestrictions } from 'common/queries/filters';
import { useGetPrimaryBrands } from './brands';
import { processError } from 'utils/helpers';

export const useFetchFilters = (studyId: string): FetchFilters => {
  // Note: might need a error state as well.
  const [filters, setFilters] = React.useState<FiltersState>();
  const [loading, setLoading] = React.useState(false);
  const initialFilters = useGetStudySpecificRestrictions(studyId);
  const rollbar = useRollbar();

  const fetchFilters = useRecoilCallback(
    ({ snapshot }) =>
      async (filters: Record<string, number[]>) => {
        setLoading(true);
        setFilters(undefined);
        const { fetchPost } = await snapshot.getPromise(fetchSelector);

        try {
          const configuration = getFiltersConfig(filters);
          const { waves, countries, brands } = await fetchPost<FiltersPayload>(API_URLS.RESTRICTIONS, {
            study_uuid: studyId,
            configuration,
          });

          const result = mapBrandByGlobalization({
            // Brands from initialFilters are already sorted in right order, sort new brands from restrictions by this old brands order
            brands: brands.length ? sortBrandsArrayByOtherBrandsOrder(brands, initialFilters.brands) : initialFilters.brands,
            countries: countries.length ? countries : initialFilters.countries,
            waves: waves.length ? waves : initialFilters.waves,
          });

          setFilters(result);
          return result;
        } catch (e: unknown) {
          processError(e, rollbar.error, 'Error while fetching filters');
        } finally {
          setLoading(false);
        }
      },
    [initialFilters, setFilters],
  );

  return React.useMemo(
    () => ({
      filters,
      loading,
      fetchFilters,
    }),
    [filters, loading, fetchFilters],
  );
};

const removeStudyIdFromRestrictions = (restrictions: Restrictions | undefined): FiltersPayload => {
  if (!restrictions) return { brands: [], countries: [], waves: [] };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { study_uuid: _, ...trimmedRestrictions } = restrictions;
  return trimmedRestrictions;
};

export const useGetSortedRestrictions = (): Restrictions[] => {
  const { data: restrictions } = useGetAllRestrictions();
  const primaryBrands = useGetPrimaryBrands();

  return useMemo(() => {
    // Sort filters by primary brands
    return sortRestrictionsBrandsByPrimaryBrands(restrictions || [], primaryBrands);
  }, [restrictions, primaryBrands]);
};

export const useGetStudySpecificRestrictions = (studyId: string): FiltersState => {
  const allRestrictions = useGetSortedRestrictions();

  return useMemo(() => {
    const studyRestrictions = allRestrictions?.find(({ study_uuid }) => study_uuid === studyId);
    return mapBrandByGlobalization(removeStudyIdFromRestrictions(studyRestrictions));
  }, [allRestrictions, studyId]);
};

export const useGetAllFilters = (): FiltersState => {
  const allRestrictions = useGetSortedRestrictions();

  return useMemo(() => {
    const restritctionsWithoutStudyId = allRestrictions?.map(removeStudyIdFromRestrictions);
    const restrictions = restritctionsWithoutStudyId?.reduce(composeRestrictions, { brands: [], waves: [], countries: [] });
    return mapBrandByGlobalization(restrictions);
  }, [allRestrictions]);
};
