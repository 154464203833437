import React, { memo, FC } from 'react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import { theme } from 'common/theme';
import { MembersListProps } from 'common/types/roles';
import { accountDetailsSelector } from 'common/atoms/account';
import MembersListItem from 'modules/settings/components/Members/MembersListItem';

const Container = styled.div`
  margin-top: ${theme.space(5.5)};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MembersList: FC<MembersListProps> = ({ members, onHandleUpdateUserRole }) => {
  const accountDetails = useRecoilValue(accountDetailsSelector);
  return (
    <Container>
      <List>
        {members?.map(member => (
          <MembersListItem
            {...member}
            key={member.id}
            onHandleUpdate={onHandleUpdateUserRole}
            currentUser={accountDetails?.user?.email === member.email}
          />
        ))}
      </List>
    </Container>
  );
};

export default memo(MembersList);
