import { UIPreferences } from 'common/types/account';
import {
  ChartDataKey,
  ChartDataPoint,
  ChartDataPointTooltip,
  ChartFields,
  ChartFormData,
  ChartLayout,
  ChartType,
  ChartViews,
  ComparisonBar,
  GeneratedChartData,
  MOEStatus,
  RadarChartData,
  RawChart,
  RawChartData,
  RawReferenceAverageData,
  ReferenceBrand,
} from 'common/types/chart';
import { BrandColorMapType, Country, Wave } from 'common/types/common';
import { AllFiltersData, FilterNames, Filters } from 'common/types/filters';
import { HeatmapData, HeatmapItemData } from 'common/types/heatmap';
import {
  AIDED_BRAND_AWARENESS,
  AVERAGE,
  AVERAGE_VALUE,
  BRAND_PERCEPTION_BAD,
  BRAND_PERCEPTION_GOOD,
  BRAND_PERCEPTION_KPI,
  BRAND_PERCEPTION_KPI_LABEL,
  FILTER_NAMES,
  NO_BRAND,
  NON_DERIVED_KPIS,
} from 'settings/constants';
import { roundValue, sortChartData } from 'utils/chart';
import { displayDate } from 'utils/dates';
import { getColorForData, getItemColor } from 'modules/charts/utils/colors';
import { Color } from 'html2canvas/dist/types/css/types/color';
import { chartValueInMsOrKs } from 'utils/helpers';
import { findBrandAwarenessDataByValues } from 'modules/charts/components/GrowthPerformance/getChartData';

const packFilters = (filters: AllFiltersData, type: FilterNames): Record<string | number, string> | Record<string | number, Date> => {
  switch (type) {
    case FilterNames.BRAND:
      return (filters.brands || []).reduce((acc, { name, id }) => {
        acc[id] = name;
        return acc;
      }, {} as Record<number | string, string>);
    case FilterNames.WAVE:
      return (filters.waves || []).reduce((acc, { date, id }) => {
        acc[id] = date;
        return acc;
      }, {} as Record<number | string, Date>);
    case FilterNames.COUNTRY:
      return (filters.countries || []).reduce((acc, { name, id }) => {
        acc[id] = name;
        return acc;
      }, {} as Record<number | string, string>);
    case FilterNames.KPI:
      return filters.kpis;
    case FilterNames.SEGMENT:
      return (filters.segments || []).reduce((acc, { name, id }) => {
        acc[id] = name;
        return acc;
      }, {} as Record<number | string, string>);
    default:
      return {};
  }
};

/* Prepare data so it can be used for creating chart */
export const prepareChartData = (
  chartPreiview: RawChart,
  allFilters: AllFiltersData,
  waveMap: Record<number, Wave>,
  countryMap: Record<number, Country>,
  numericKpis: React.ReactText[],
  { calculateDeltas, decimalsEnabled }: UIPreferences,
  brandsColorMap: BrandColorMapType | null,
  primaryBrands: string[] | null,
  aggregateData: RawReferenceAverageData[] | undefined,
  brandAwarenessData: RawChartData[] | undefined,
): GeneratedChartData => {
  const {
    chart: { chart_type, configuration, current_view, country_ids, margin_of_error_status, wave_ids },
    data,
  } = chartPreiview;
  const chartKeys: string[] = []; // What properties in flatten data we should show on chart
  const preparedDataMap: Map<number | string, ChartDataPoint> = new Map(); // Flatten data that is used for showing chart
  const valueField = configuration?.y_label || ChartFields.MEAN; // What field we use for showing actual value

  const isRadarChart = chart_type === ChartType.RADAR;
  const isMatrixChart = chart_type === ChartType.MATRIX;
  const isComparisonChart = chart_type === ChartType.COMPARISON;

  const isLineView = current_view === ChartViews.LINE;
  const isMoeEnabled = margin_of_error_status === MOEStatus.ENABLED;

  let groupBy = configuration?.first_dimension; // Field that we group by
  let chartSecondDimension = configuration?.second_dimension;
  const chartFirstDimension = configuration?.first_dimension;

  const isGroupedByBrand = groupBy === FilterNames.BRAND;
  const isGroupedByWave = groupBy === FilterNames.WAVE;

  if (isLineView && isGroupedByWave) {
    [groupBy, chartSecondDimension] = [chartSecondDimension, groupBy];
  }

  const referenceValue = 0;
  const showDelta = chartSecondDimension === FilterNames.WAVE; // Add delta only if it is compared by wave
  const chartCountryCodes = country_ids?.map(countryId => countryMap?.[countryId as number]?.code);

  // To keep track of the current group index
  const groups: Array<string | number> = [];

  const maxMoE = getMaxMoe(data);
  const maxValue = getMaxValue(data, isMoeEnabled);
  const groupPack = packFilters(allFilters, groupBy);

  // This condition is for radar chart table data
  const itemsLabelsPack = isRadarChart
    ? { ...packFilters(allFilters, chartSecondDimension), [`${AVERAGE}`]: AVERAGE }
    : packFilters(allFilters, chartSecondDimension);

  const rawChartData = getProcessedChartData({
    data,
    isComparisonChart,
    isGroupedByBrand,
    groupPack,
    itemsLabelsPack,
    primaryBrands,
    groupBy,
    chartSecondDimension,
    waves: allFilters.waves,
    isMatrixChart,
    secondDimensionItems: configuration.second_dimension_items,
    waveIds: wave_ids,
  });

  let maxSegmentPopulationSize = 0;

  // Group data by groupBy field and make it flat so chart library can show it
  rawChartData?.forEach(dataItem => {
    const groupByValue = dataItem[groupBy]; // Actual value of field that we group by
    let currentItem = preparedDataMap.get(groupByValue); // We store items in associative array(object)

    // Check if group value is added to groups
    if (!groups.includes(groupByValue)) {
      groups.push(groupByValue);
    }

    const formatItemLabel = (labelValue: string | Date) => {
      return labelValue instanceof Date ? displayDate(labelValue, 'MMMM YYYY') : labelValue;
    };

    const firstDimensionValue = dataItem[chartFirstDimension];
    const secondDimensionValue = dataItem[chartSecondDimension];

    const itemLabelValue = itemsLabelsPack[secondDimensionValue];
    const groupLabelValue = groupByValue === BRAND_PERCEPTION_KPI ? BRAND_PERCEPTION_KPI_LABEL : groupPack[groupByValue];

    const groupLabel = formatItemLabel(groupLabelValue);
    const itemLabel = formatItemLabel(itemLabelValue);

    const groupIndex = groups.indexOf(groupByValue); // Get group value index, used for getting a color for the group
    const currentItemValue = dataItem[valueField] as number; // Actual value of what we show in chart
    const isNumericKPI = numericKpis.includes(dataItem[FilterNames.KPI]);

    const waveId = dataItem.wave;
    const countryId = dataItem.country;
    const moeRaw = dataItem.margin_of_error;
    const segmentWeight = dataItem.segment_population_weight;
    const unavailable = dataItem.unavailable || false;

    const currentValue = roundValue(currentItemValue * 100, decimalsEnabled);
    const moeValue = roundValue((dataItem.margin_of_error as number) * 100, decimalsEnabled);
    const segmentPopulationNumber = (countryMap?.[countryId]?.population || 0) * (segmentWeight || 1);
    const populationNumberRaw = getPopulationForItem(dataItem, brandAwarenessData || [], segmentPopulationNumber, currentItemValue);
    const populationNumberValue = chartValueInMsOrKs(populationNumberRaw, null);

    const moePopulationRaw = getPopulationForItem(
      dataItem,
      brandAwarenessData || [],
      segmentPopulationNumber,
      dataItem?.margin_of_error || 0,
    );

    const moePopulationValue = chartValueInMsOrKs(moePopulationRaw, null);

    maxSegmentPopulationSize = calculateMaxPopulationSize(populationNumberRaw, moePopulationRaw, maxSegmentPopulationSize);

    const averageDataForItem = aggregateData ? (getAverageDataForItem(dataItem, aggregateData, decimalsEnabled) || 0) / 100 : 0;
    const averagePopulationValue = aggregateData
      ? chartValueInMsOrKs(getPopulationForItem(dataItem, brandAwarenessData || [], segmentPopulationNumber, averageDataForItem), null)
      : null;

    // If the data for current item is already grouped, add it as a new value
    const waveObject = waveMap[waveId as number];
    const isAided = dataItem.kpi === AIDED_BRAND_AWARENESS;

    if (!currentItem) {
      const groupByLabel = (isGroupedByWave || isLineView) && waveObject ? waveId : groupLabel;

      // Color of the group should take brand color if it is grouped by brand
      const groupColor = getGroupColor(isGroupedByBrand, brandsColorMap, groupLabel, groupIndex);

      const segmentsInTooltip = allFilters['segments']
        ?.filter(segment => aggregateData?.some(data => data.segment === segment.id))
        ?.map(segment => segment.name);

      const kpisInTooltip = Object.keys(allFilters['kpis'])
        .filter(kpi => aggregateData?.some(data => data.kpi === kpi))
        .map(kpi => allFilters['kpis'][kpi]);

      const tooltipCountryName = allFilters['countries']?.find(country => country.code === chartCountryCodes[0])?.name || '';

      currentItem = {
        [groupBy]: groupByLabel ?? groupByValue,
        groupLabel: groupLabel,
        groupId: groupByValue,
        aidedIndex: -1,
        count: 0,
        disabled: false,
        referenceValue: referenceValue ? referenceValue * 100 : null,
        delta: null,
        color: groupColor, // Color to use in chart legend & chart table
        tooltip: {
          items: [],
          title: groupLabel,
          color: groupColor,
          kpis: kpisInTooltip,
          segments: segmentsInTooltip,
          country: tooltipCountryName,
          groupBy,
        } as ChartDataPointTooltip,
      } as unknown as ChartDataPoint;
    }

    const currentIndex = currentItem.count;

    if (isAided) {
      currentItem.aidedIndex = currentIndex;
    }

    const itemColor = getItemColor(itemLabel, currentIndex, chartSecondDimension as string, brandsColorMap, getColorForData);

    const deltaReferenceIndex = calculateDeltas === 'first-last' ? 'value_0' : `value_${currentItem.count - 1}`;
    const delta =
      showDelta && currentValue && !currentItem[`${deltaReferenceIndex}_unavailable` as 'value_0_unavailable'] && !unavailable
        ? roundValue(currentValue - currentItem[deltaReferenceIndex as 'value_0'], decimalsEnabled)
        : null;

    const deltaPopulation = chartValueInMsOrKs(
      getPopulationForItem(dataItem, brandAwarenessData || [], segmentPopulationNumber, (delta || 0) / 100),
      null,
    );

    const valueKey = `value_${currentItem.count}` as 'value_0';

    if (!chartKeys.includes(valueKey)) {
      chartKeys.push(valueKey);
    }

    currentItem.tooltip.items.push({
      label: itemLabel,
      value: currentValue,
      populationValue: populationNumberValue,
      numericValue: isNumericKPI,
      moeValue: moeValue,
      moePopulationValue: moePopulationValue,
      color: itemColor,
      labelColor: itemColor,
      unavailable,
    });

    const barFill = 1;
    const moeHeight = (dataItem.margin_of_error as number) / currentItemValue;
    const funnelFill = 0;

    const valueFilterLabels = {
      kpi: allFilters?.kpis?.[dataItem.kpi],
      brand: allFilters?.brands?.find(x => x.id === dataItem.brand)?.name || '',
      country: allFilters?.countries?.find(x => x.id === dataItem.country)?.name || '',
      wave: waveObject ? displayDate(waveObject.date, 'MMMM YYYY') : '',
      segment: allFilters?.segments?.find(x => x.id === dataItem.segment)?.name || '',
    };

    preparedDataMap.set(groupByValue, {
      ...currentItem,
      [valueKey]: currentValue,
      [`${valueKey}_numeric`]: isNumericKPI,
      [`${valueKey}_max_height` as 'value_0_max_height']: currentValue,
      [`${valueKey}_bar_label`]: null,
      [`${valueKey}_bar_fill` as 'value_0_bar_fill']: barFill,
      [`${valueKey}_funnel_fill` as 'value_0_funnel_fill']: funnelFill,
      [`${valueKey}_height` as 'value_0_height']: 0,
      [`${valueKey}_raw` as 'value_0_raw']: currentItemValue,
      [`${valueKey}_moe` as 'value_0_moe']: moeValue,
      [`${valueKey}_moe_height` as 'value_0_moe_height']: moeHeight,
      [`${valueKey}_moe_raw` as 'value_0_moe_raw']: moeRaw || 0,
      [`${valueKey}_unavailable` as 'value_0_unavailable']: unavailable,
      [`${valueKey}_label` as 'value_0_label']: itemLabel,
      [`${valueKey}_label_value` as 'value_0_label_value']: itemLabelValue,
      [`${valueKey}_wave_id` as 'value_0_wave_id']: waveId as number,
      [`${valueKey}_wave_label` as 'value_0_wave_label']: waveObject ? displayDate(waveObject.date, 'MMMM YYYY') : '',
      [`${valueKey}_color`]: itemColor,
      [`${valueKey}_label_color`]: itemColor,
      [`funnel_${currentItem.count}_tooltip` as 'funnel_0_tooltip']: null,
      [`${valueKey}_secondDimension`]: secondDimensionValue,
      [`${valueKey}_firstDimension`]: firstDimensionValue,
      [`${valueKey}_averageValue`]: aggregateData ? getAverageDataForItem(dataItem, aggregateData, decimalsEnabled) : null,
      [`${valueKey}_averagePopulationValue`]: averagePopulationValue,
      [`${valueKey}_populationNumberValue`]: populationNumberValue,
      [`${valueKey}_populationNumberRaw`]: populationNumberRaw,
      [`${valueKey}_segmentPopulationNumber`]: segmentPopulationNumber,
      [`${valueKey}_segmentSize`]: segmentPopulationNumber,
      [`${valueKey}_filterLabels`]: valueFilterLabels,
      delta: delta,
      deltaPopulation: deltaPopulation,
      count: currentItem.count + 1,
    });
  });

  const chartData = Array.from(preparedDataMap.values());
  const minValue = null;

  return {
    data: chartData,
    keys: chartKeys,
    indexBy: groupBy,
    secondDimension: chartSecondDimension,
    chartType: chart_type,
    groups: Array.from(preparedDataMap.keys()).length,
    maxValue,
    minValue,
    maxMoE,
    maxSegmentPopulationSize,
  };
};

const calculateMaxPopulationSize = (rawPopulationNum: number, rawPopulationMoe: number, maxSegmentPopulation: number): number => {
  const totalPopulation = rawPopulationNum + rawPopulationMoe;
  return totalPopulation > maxSegmentPopulation ? totalPopulation : maxSegmentPopulation;
};

const getMaxValue = (rawChartData: RawChartData[], isMoeEnabled: boolean): number => {
  return rawChartData?.reduce((acc, dataPoint) => {
    const value = dataPoint.high
      ? dataPoint.high
      : dataPoint.mean + (dataPoint.margin_of_error as number) * (Boolean(isMoeEnabled) as unknown as number);
    return value > acc ? value : acc;
  }, 0);
};

const getMaxMoe = (rawChartData: RawChartData[]): number => {
  return rawChartData?.reduce((acc, dataPoint) => {
    const value = dataPoint.margin_of_error as number;
    return value > acc ? value : acc;
  }, 0);
};

const getProcessedChartData = ({
  data,
  isComparisonChart,
  isGroupedByBrand,
  groupPack,
  itemsLabelsPack,
  primaryBrands,
  groupBy,
  chartSecondDimension,
  waves,
  isMatrixChart,
  secondDimensionItems,
  waveIds,
}: {
  data: RawChartData[];
  isComparisonChart: boolean;
  isGroupedByBrand: boolean;
  groupPack: Record<string | number, string> | Record<string | number, Date>;
  itemsLabelsPack:
    | Record<string | number, string>
    | {
        [x: string]: 'Average' | Date;
      };
  primaryBrands: string[] | null;
  groupBy: FilterNames;
  chartSecondDimension: FilterNames;
  waves: Wave[];
  isMatrixChart: boolean;
  secondDimensionItems: number[] | undefined;
  waveIds: number[];
}): RawChartData[] => {
  if (isComparisonChart) {
    return sortChartData({
      data,
      filterPack: isGroupedByBrand ? groupPack : itemsLabelsPack,
      primaryBrands,
      groupBy,
      secondDimension: chartSecondDimension,
      waves,
    });
  }

  if (isMatrixChart) {
    return filterMatrixDataBySelectedItems(data, chartSecondDimension, secondDimensionItems || [], waveIds);
  }

  return data;
};

const getGroupColor = (
  isGroupedByBrand: boolean,
  brandsColorMap: BrandColorMapType | null,
  groupLabel: string,
  groupIndex: number,
): string => {
  if (!brandsColorMap) {
    return getColorForData(groupIndex);
  }

  if (isGroupedByBrand && brandsColorMap[groupLabel]) {
    return brandsColorMap[groupLabel];
  }

  return getColorForData(groupIndex);
};

const parseKpiName = (kpiFullName: string): string => kpiFullName?.split(':')[1];

export const getNameById = (
  type: FilterNames,
  itemId: string | number,
  allFilters: AllFiltersData,
  hasNoAggregateData?: boolean,
): string | Date | undefined => {
  switch (type) {
    case FilterNames.BRAND:
      // eslint-disable-next-line no-case-declarations
      const hasOnlyNoBrand = allFilters?.brands?.length === 1 && allFilters?.brands[0]?.name === NO_BRAND;
      // NO_BRAND label should not appear on axes if only NO_BRAND is selected in filter
      if (hasOnlyNoBrand && hasNoAggregateData) return '';
      return allFilters['brands']?.find(({ id }) => itemId === id)?.name || AVERAGE;
    case FilterNames.WAVE:
      // eslint-disable-next-line no-case-declarations
      const waveFound = allFilters?.waves?.find(({ id }) => itemId === id);
      // eslint-disable-next-line no-case-declarations
      const date = waveFound?.date as Date;
      if (!date) return '';
      return date;
    case FilterNames.COUNTRY:
      return allFilters['countries']?.find(({ id }) => itemId === id)?.name;
    case FilterNames.KPI:
      return parseKpiName(allFilters['kpis'][itemId]) || allFilters['kpis'][itemId];

    case FilterNames.SEGMENT:
      return allFilters['segments']?.find(({ id }) => itemId === id)?.name;
    default:
      break;
  }
};

type KPIkeys = {
  key: string | number;
  disabled: boolean;
};

const getKPIKeys = (chartData: ChartDataPoint[]): Array<KPIkeys> => {
  return chartData.map(({ value_0_firstDimension, disabled }) => ({ key: value_0_firstDimension, disabled }));
};

const getDate = (date: Date | undefined): string => {
  const year = date?.getFullYear().toString();
  const month = date?.toLocaleDateString('en-US', { month: 'long' });
  return `${year} ${month}`;
};

const nameMapToId = <T extends { id: string | number; date?: Date }>(item: T, key: string): { [x: string]: string | T[keyof T] } => {
  const value = key === 'date' ? getDate(item[key]) : item[key as keyof T];
  return { [item.id]: value };
};

const getKpiType = (value: number, { brands, countries, waves, segmentList }: Filters) => {
  const dict = {
    ...Object.assign({}, ...(brands.map(brand => nameMapToId(brand, 'name')) || [])),
    ...Object.assign({}, ...(countries.map(country => nameMapToId(country, 'name')) || [])),
    ...Object.assign({}, ...(waves.map(wave => nameMapToId(wave, 'date')) || [])),
    ...Object.assign({}, ...(segmentList?.map(segment => nameMapToId(segment, 'name')) || [])),
    [`${AVERAGE}`]: AVERAGE,
  };

  return dict[value];
};

type RadarChartMap = {
  [key: string]: string;
  kpi: string;
};

const radarChartDataMap = (chartData: Array<ChartDataPoint>, chartKeys: Array<string>, filters: Filters): Array<RadarChartMap> => {
  const map: Array<{ kpi: string; [key: string]: string }> = [];
  chartKeys.forEach(chartKey => {
    chartData.forEach(dataChunk => {
      if (dataChunk[`${chartKey}_secondDimension` as ChartDataKey]) {
        const kpiType = getKpiType(dataChunk[`${chartKey}_secondDimension` as ChartDataKey] as Color & string, filters);
        map.push({
          kpi: dataChunk[`${chartKey}_firstDimension` as ChartDataKey] as string,
          [kpiType]: dataChunk[`${chartKey}` as ChartDataKey] as string,
        });
      }
    });
  });
  return map;
};

export const getRadarChartData = (
  finalData: GeneratedChartData,
  filters: Filters,
  kpiMap: Record<string, string>,
): Array<RadarChartData> => {
  const kpiKeys = getKPIKeys(finalData.data);
  const dataMap = radarChartDataMap(finalData.data, finalData.keys, filters);
  return kpiKeys.map(({ key, disabled }) => {
    const groupedByKPI = dataMap.reduce((acc: { [key: string]: string | boolean }[], curr) => {
      if (key === curr.kpi) {
        acc = [...acc, { ...curr, kpi: parseKpiName(kpiMap[key]), disabled }];
      }
      return acc;
    }, []);
    return Object.assign({}, ...groupedByKPI);
  });
};

export const getRadarChartColors = (chartData: GeneratedChartData): (string | undefined)[] => {
  const colors: Array<string | undefined> = chartData.keys.map(key =>
    chartData.data[0]?.[`${key}_label_color` as 'value_0_label_color']?.toString(),
  );
  return !colors.includes(undefined) ? colors : chartData.data.map(({ color }) => color.toString());
};

export const getRadarChartMaxValue = (data: RadarChartData[]): number => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const values = data.map(({ kpi, ...rest }) => Object.values(rest)).flat();
  const maxValue = values.sort((a, b) => b - a)[0];
  return Math.ceil(maxValue);
};

type ChartParameters = {
  wave_ids: number[];
  brand_ids: number[];
  segment_ids: (string | number)[];
  country_ids: number[];
  kpi_option_identifiers: (string | number)[];
};

export const getChartParams = ({ wave, country, brand, segment, kpi }: ChartFormData, chartType: ChartType): ChartParameters => {
  const isMatrix = chartType === ChartType.MATRIX;

  /* For Matrix chart we show Brand Perception as single KPI Option but we want to fetch both positive and negative brand perception to calculate net sentiment*/
  const kpis = isMatrix
    ? kpi.flatMap(item => (item === BRAND_PERCEPTION_KPI ? [BRAND_PERCEPTION_BAD, BRAND_PERCEPTION_GOOD] : [item]))
    : kpi;
  return {
    wave_ids: wave,
    // [brand] is being filtered to prevent [0 (AVERAGE_VALUE)] value from being sent to the BE.
    brand_ids: brand?.filter((id: number | string) => id !== AVERAGE_VALUE),
    segment_ids: segment,
    country_ids: country,
    kpi_option_identifiers: kpis,
  };
};

export const getMultipleValueFields = (chartData: ChartFormData): Array<FilterNames> => {
  const multipleValueFields = [] as FilterNames[];

  FILTER_NAMES.forEach(filterName => {
    if (chartData[filterName]?.length > 1) multipleValueFields.push(filterName);
  });

  return multipleValueFields;
};

// Notes:
// - If there are no multiple selections: put Time on X
// - If there is 1 multiple selection: This group is on X
// - If there are two multiple selections: Time by default (if it exists)
// - Hierarchy if two multiple selections: Time, Brand, KPI, Geography, Segment

type ChartDimensions = {
  firstDimension: FilterNames;
  secondDimension: FilterNames | null;
};

export const setChartDimensions = (
  chart: ChartFormData,
  filtersNames: FilterNames[],
  getMultipleValueFields: (chart: ChartFormData) => FilterNames[],
): ChartDimensions => {
  let { firstDimension, secondDimension } = chart;
  const multipleValueFields = getMultipleValueFields(chart);
  const isRadar = (() => chart.chart_type === ChartType.RADAR)();
  const isGrowthPerformance = (() => chart.chart_type === ChartType.GROWTH_PERFORMANCE)();
  const isBrandPerception = (() => chart.chart_type === ChartType.BRAND_PERCEPTION)();
  const isMarketFunnel = (() => chart.chart_type === ChartType.MARKET_FUNNEL)();
  const isLineView = (() => chart.current_view === ChartViews.LINE)();
  const isMatrixChart = (() => chart.chart_type === ChartType.MATRIX)();

  if (isRadar) {
    const omitKPIArr = multipleValueFields.filter(name => name !== FilterNames.KPI);
    firstDimension = FilterNames.KPI;
    secondDimension = omitKPIArr.length ? omitKPIArr[0] : FilterNames.BRAND;
  } else if (isBrandPerception || isMarketFunnel) {
    const omitKPIArr = multipleValueFields.filter(name => name !== FilterNames.KPI && name !== FilterNames.WAVE);
    firstDimension = omitKPIArr.length ? omitKPIArr[0] : FilterNames.BRAND;
    secondDimension = firstDimension === FilterNames.BRAND ? FilterNames.COUNTRY : FilterNames.BRAND;
  } else if (isGrowthPerformance) {
    const omitWaveArr = multipleValueFields.filter(fieldName => fieldName !== FilterNames.WAVE);
    firstDimension = omitWaveArr.length ? omitWaveArr[0] : FilterNames.BRAND;
    secondDimension = null;
  } else if (isMatrixChart) {
    const omitKPIArr = multipleValueFields.filter(name => name !== FilterNames.KPI && name !== FilterNames.WAVE);
    firstDimension = FilterNames.KPI;
    secondDimension = omitKPIArr.length ? omitKPIArr[0] : FilterNames.BRAND;
  } else if (isLineView) {
    firstDimension = multipleValueFields.find(value => value !== FilterNames.WAVE) || filtersNames[0];
    secondDimension = FilterNames.WAVE;
  } else if (!multipleValueFields.length) {
    firstDimension = filtersNames[0];
    secondDimension = filtersNames[1];
  } else if (multipleValueFields.length === 1) {
    firstDimension = multipleValueFields[0];
    secondDimension = firstDimension === FilterNames.KPI ? FilterNames.BRAND : FilterNames.KPI;
  } else {
    firstDimension = multipleValueFields[0];
    secondDimension = multipleValueFields[1];
  }

  return { firstDimension, secondDimension };
};

type AggregateChartData = {
  country: number;
  wave: number;
  segment: number;
  kpi: string;
  mean: number;
  reference_brands: Array<ReferenceBrand>;
  brand: 'Average';
};

// added [brand: AVERAGE] to make it behave like an ordinary brand.
export const mapAggregateChartData = (chartData: RawReferenceAverageData[] | undefined): Array<AggregateChartData> => {
  return (chartData || []).map((dataChunk: RawReferenceAverageData) => {
    return { brand: AVERAGE, ...dataChunk };
  });
};

export const getHeatmapChartData = (
  finalData: RawChart,
  filters: AllFiltersData,
  decimalsEnabled: boolean,
  countryMap: Record<number, Country>,
  aggregateChartData?: RawReferenceAverageData[],
): HeatmapData => {
  const { BRAND, KPI, COUNTRY, SEGMENT } = FilterNames;
  const countryValues = finalData?.chart?.country_ids;
  const kpiValues = finalData?.chart?.kpi_option_identifiers;
  const segmentValues = finalData?.chart?.segment_ids;
  let brandValues = finalData?.chart?.brand_ids;
  let yLabel = BRAND;
  let xLabel = COUNTRY;
  let data: RawChartData[] = [...(finalData?.data || [])];

  if (finalData.chart.display_reference_average) {
    // added [0 / AVERAGE_VALUE] to brandValues to make [AVERAGE] brand reference behave like an ordinary brand.
    brandValues = [...brandValues, AVERAGE_VALUE];
    const referenceAverageData = mapAggregateChartData(aggregateChartData);
    // merging reference average data to main chart data to visualise it in heatmap diagram.
    data = data.concat(referenceAverageData);
  }
  // Order should be maintained BRAND > KPI > COUNTRY > SEGMENT
  const filterValues: { name: string; value: string[] | number[] }[] = [
    { name: BRAND, value: brandValues },
    { name: KPI, value: kpiValues },
    { name: COUNTRY, value: countryValues },
    { name: SEGMENT, value: segmentValues },
  ];

  const multiValueFields = filterValues.reduce((fields, current) => {
    if (current.value?.length > 1) fields.push(current.name as FilterNames);
    return fields;
  }, [] as FilterNames[]);

  /**
   * This comment describes how the labels for HEATMAP will be determined based on chart data.
   *
   * If two multiValue fields are present, the labels will follow below order:
   * - yLabel: BRAND > KPI > COUNTRY > SEGMENT
   * - xLabel: KPI > COUNTRY > SEGMENT
   *
   * If one multiValue field is present, the labels will be:
   * - yLabel: BRAND
   * - xLabel: that multiValue field
   *
   * If no multiValue fields are present, the labels will be:
   * - yLabel: BRAND
   * - xLabel: COUNTRY
   */
  if (multiValueFields.length === 1 && multiValueFields[0] !== BRAND) {
    xLabel = multiValueFields[0];
  }

  if (multiValueFields.length > 1) {
    yLabel = multiValueFields[0];
    xLabel = multiValueFields[1];
  }

  const heatmapData = {} as Record<string, HeatmapItemData>;
  const keys = [] as string[];

  data?.forEach(item => {
    const yLabelValueId = item[yLabel as keyof RawChartData];
    const xLabelValueId = item[xLabel as keyof RawChartData];
    const yLabelValue = getNameById(yLabel, yLabelValueId as string | number, filters, !aggregateChartData?.length);
    const xLabelValue = getNameById(xLabel, xLabelValueId as string | number, filters, !aggregateChartData?.length);
    const segmentWeight = item?.segment_population_weight || 1;
    const countryPopulation = countryMap?.[item.country]?.population || 0;

    if (!heatmapData[yLabelValue as string]) {
      const newItem = {
        [yLabel]: yLabelValue,
        [xLabelValue as keyof HeatmapItemData]: roundValue(item.mean * 100, decimalsEnabled),
        [`${[xLabelValue as keyof HeatmapItemData]}_population`]: chartValueInMsOrKs(countryPopulation * segmentWeight * item.mean, null),
      } as HeatmapItemData;

      keys.indexOf(xLabelValue as string) === -1 && keys.push(xLabelValue as string);

      heatmapData[yLabelValue as string] = newItem;
    } else {
      heatmapData[yLabelValue as string][xLabelValue as keyof HeatmapItemData] = roundValue(item.mean * 100, decimalsEnabled);
      (heatmapData[yLabelValue as string][`${xLabelValue as keyof HeatmapItemData}_population`] = chartValueInMsOrKs(
        countryPopulation * segmentWeight * item.mean,
        null,
      )),
        keys.indexOf(xLabelValue as string) === -1 && keys.push(xLabelValue as string);
    }
  });

  return {
    data: Object.keys(heatmapData).map(key => heatmapData[key]),
    keys: keys,
    xLabel: xLabel,
    yLabel: yLabel,
  } as HeatmapData;
};

const getAverageDataForItem = (
  dataItem: RawChartData,
  aggregateData: RawReferenceAverageData[],
  decimalsEnabled: boolean,
): number | null => {
  const avgData = aggregateData.find(
    item =>
      item.country === dataItem.country && item.kpi === dataItem.kpi && item.segment === dataItem.segment && item.wave === dataItem.wave,
  );

  return avgData ? roundValue(avgData.mean * 100, decimalsEnabled) : null;
};

interface IAxesLocation {
  bar: ComparisonBar;
  yScale: (value: number) => number | undefined;
  xScale: (value: number) => number | undefined;
  chartLayout?: string;
  groupItems?: ComparisonBar[];
  singleLine?: boolean; // Single line across whole chart
}

export const referenceAxesLocation = ({
  bar,
  yScale,
  xScale,
  chartLayout,
  groupItems,
  singleLine = false,
}: IAxesLocation): Record<string, number | undefined> => {
  const barId = bar?.data?.id;
  const averageValue = bar?.data?.data?.[`${barId}_averageValue` as 'value_0_averageValue'] || 0;
  const SPACING = 1; //Add spacing on the average line at the end(line should exceed bars by 2px)

  switch (chartLayout) {
    case ChartLayout.VERTICAL:
      return {
        x1: bar.x - SPACING,
        x2: (groupItems ? groupItems?.[groupItems.length - 1].x : bar.x) + bar.width + SPACING,
        y1: yScale(averageValue),
        y2: yScale(averageValue),
      };
    case ChartLayout.HORIZONTAL:
      return {
        x1: xScale(averageValue),
        x2: xScale(averageValue),
        /* In case with want to show single line across whole chart calculation is different */
        y1: singleLine && groupItems ? groupItems?.[groupItems.length - 1].y - SPACING : bar.y + bar.height + SPACING,
        y2: singleLine && groupItems ? bar.y + bar.height + SPACING : bar.y - SPACING,
      };
    default:
      return {};
  }
};

export const getPopulationForItem = (
  dataItem: RawChartData,
  brandAwarenessData: RawChartData[],
  segmentPopulationNumber: number,
  currentItemValue: number,
): number => {
  if ((brandAwarenessData || [])?.length > 0 && !NON_DERIVED_KPIS.includes(dataItem.kpi)) {
    const populationNumberRaw = segmentPopulationNumber * currentItemValue;
    const brandAwarenessItem = findBrandAwarenessDataByValues(dataItem, brandAwarenessData);
    return (brandAwarenessItem?.mean || 0) * populationNumberRaw;
  } else {
    return segmentPopulationNumber * currentItemValue;
  }
};

const filterMatrixDataBySelectedItems = (
  data: RawChartData[],
  secondDimension: FilterNames,
  secondDimensionItems: number[],
  waveIds: number[],
): RawChartData[] => {
  const currentWaveId = waveIds.slice(-1)[0];

  if (secondDimensionItems.length > 0) {
    // Filter data to include only items matching the selected second dimension items and the latest wave ID
    return data.filter(item => secondDimensionItems.includes(item[secondDimension] as number) && item.wave === currentWaveId);
  } else {
    // If no second dimension items are selected, return the original data
    return data;
  }
};
